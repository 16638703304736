import { useContext, useEffect, useState } from 'react';
import {
  Card,
  Col,
  CardBody,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Button,
  InputGroup,
  Modal,
  CardHeader,
} from 'reactstrap';

import { CircularProgress } from '@material-ui/core';
import api from 'services/api';
import Proposta from 'models/Proposta';
import { PropostaContext } from 'views/pages/Proposta';
import { FaMap } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { ProdutoProposta } from 'models/ProdutoProposta';

interface ILocalObra {
  proposta: Proposta;
  propostaVersaoId: number;
  produtoProposta: ProdutoProposta;
}

declare let window: any;

window.google.load('visualization', '1', {
  packages: ['columnchart', 'corechart', 'table'],
});

export default function PosicaoGeografica({
  proposta,
  propostaVersaoId,
  produtoProposta,
}: ILocalObra): JSX.Element {
  const { bloquearLocal } = useContext(PropostaContext);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [posicaoGeodesica, setPosicaoGeodesica] = useState('');
  const [posicaoGeodesicaMapa, setPosicaoGeodesicaMapa] = useState('');
  const [formModalState, setFormModalState] = useState(false);
  const [globalMap, setGlobalMap] = useState<any>();
  const [updatingGeodesico, setUpdatingGeodesico] = useState(false);
  const [posicaoGeodesicaEndereco, setPosicaoGeodesicaEndereco] = useState('');

  async function carregaLocalObra() {
    if (proposta.id) {
      try {
        setCarregandoDados(true);
        const response = await api.get(
          `/proposta/${propostaVersaoId}/local-obra`,
        );

        if (response.data.posicaoGeodesica) {
          setPosicaoGeodesica(response.data.posicaoGeodesica);
        } else if (response.data.posicaoCentroPivo) {
          setPosicaoGeodesica(response.data.posicaoCentroPivo);
        }
      } finally {
        setCarregandoDados(false);
      }
    }
  }

  useEffect(() => {
    carregaLocalObra();
  }, []);

  async function handleUpdateGeodesico(posicao: string) {
    try {
      setUpdatingGeodesico(true);
      await api.put(`/proposta/versao/${propostaVersaoId}/geodesico`, {
        posicaoGeodesica: posicao,
      });
      toast.success('Dados geodésicos salvos');
    } finally {
      setUpdatingGeodesico(false);
    }
  }

  async function handleModalMapaSetPosicao() {
    setPosicaoGeodesica(posicaoGeodesicaMapa ?? '');
    setFormModalState(!formModalState);
    handleUpdateGeodesico(posicaoGeodesicaMapa);
  }

  async function handleOpenModalMapa() {
    const posicaoExistente = '';
    setPosicaoGeodesicaMapa(posicaoGeodesica ?? posicaoExistente);
    setFormModalState(!formModalState);
  }

  async function gereMap(mapx: any) {
    // Ferramenta
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          // google.maps.drawing.OverlayType.CIRCLE,
          // google.maps.drawing.OverlayType.POLYGON,
        ],
      },
    });

    let marker: any = null;

    function placeMarker(location: any) {
      if (marker) {
        marker.setPosition(location);
      } else {
        marker = new google.maps.Marker({
          position: location,
          map: mapx,
        });
      }
    }

    if (posicaoGeodesica) {
      const posicao = new google.maps.LatLng({
        lat: Number(posicaoGeodesica.split(',')[0]),
        lng: Number(posicaoGeodesica.split(',')[1]),
      });

      placeMarker(posicao);
    }

    google.maps.event.addListener(mapx, 'click', (event: any) => {
      placeMarker(event.latLng);
      setPosicaoGeodesicaMapa(`${event.latLng.lat()},${event.latLng.lng()}`);
    });

    drawingManager.setMap(mapx);

    mapx.setCenter({ lat: -22, lng: -48 });
    mapx.setZoom(5);
  }

  function initMap(): void {
    if (window.google) {
      const map = new window.google.maps.Map(
        document.getElementById('mapId') as HTMLElement,
        {
          zoom: 8,
          location: { lat: -16, lng: -48 },
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER,
          },
          scaleControl: true,
          fullscreenControl: false,
        },
      );

      gereMap(map);
      setGlobalMap(map);
    }
  }

  useEffect(() => {
    if (document.getElementById('mapId')) {
      initMap();
    }
  }, [formModalState]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
        onOpened={() => {
          if (document.getElementById('mapId')) {
            initMap();
          }
        }}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="">
              <Row>
                <Col sm="12">
                  <h3>Indique no mapa a localização da proposta</h3>
                </Col>
              </Row>
              <Row style={{ width: '100%' }} className="mt-2">
                <Col sm="12">
                  <div id="mapId" style={{ height: '500px', fontSize: '20' }} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="5" className="ml-5">
                  <Button
                    className="btn-icon btn-2 flex"
                    color="default"
                    type="button"
                    style={{ width: '100%' }}
                    outline
                    onClick={() => setFormModalState(!formModalState)}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm="5">
                  <Button
                    className="btn-icon btn-2"
                    color="primary"
                    style={{ width: '100%' }}
                    type="button"
                    onClick={() => {
                      handleModalMapaSetPosicao();
                    }}
                  >
                    Atualizar posição
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>
      <Card className="bg-secondary shadow mt-1 mb-1">
        <CardBody>
          <Form>
            <div className="pl-lg-3">
              <Row>
                <Col lg="6" xs="6">
                  <FormGroup>
                    <Label className="form-control-label">
                      Posição Geográfica:
                    </Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        type="text"
                        value={posicaoGeodesica}
                        onChange={text =>
                          setPosicaoGeodesica(text.target.value)
                        }
                      />
                      <Button
                        className="float-right"
                        color="default"
                        title="Definir no mapa a posição geodésica"
                        onClick={handleOpenModalMapa}
                      >
                        <FaMap />
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6" xs="6">
                  <Button
                    className="float-right mt-4"
                    color="default"
                    disabled={updatingGeodesico}
                    onClick={() => {
                      handleUpdateGeodesico(posicaoGeodesica);
                    }}
                  >
                    {updatingGeodesico ? 'Salvando...' : 'Salvar'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
