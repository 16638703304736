import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaEdit,
  FaInfoCircle,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import formatCurrency from 'utils/formatCurrency';

import api from 'services/api';

import { arredondaDecimais } from 'utils/arredondaDecimais';

import GenericModal from 'components/GenericModal';
import { Tooltip } from '@material-ui/core';
import CustoTempoModel from '../../../models/ConfiguradorCustoTempo';

const ConfiguradorCustoTempo: React.FC = () => {
  const [custoTempoLista, setCustoTempoLista] = useState(
    [] as CustoTempoModel[],
  );
  // eslint-disable-next-line prettier/prettier
  const [custoTempoEditing, setCustoTempoEditing] = useState({} as CustoTempoModel);

  const [formModalState, setFormModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowsModal, setRowsModal] = useState<string[]>([]);
  const [modalTitulo, setModalTitulo] = useState('');
  const [modalStatusVisivel, setModalStatusVisivel] = useState(false);

  async function listaCustoTempo() {
    const response = await api.get('/integracao/custo-tempo');

    setCustoTempoLista(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaCustoTempo();
  }, []);

  async function handleDelete(custoTempoId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/integracao/custo-tempo/${custoTempoId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaCustoTempo();
      }
    });
  }
  async function handleInsert() {
    setCustoTempoEditing({} as CustoTempoModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemCustoTempo: CustoTempoModel) {
    setCustoTempoEditing(itemCustoTempo);

    setFormModalState(!formModalState);
  }

  async function handleSaveCustoTempo() {
    // eslint-disable-next-line prettier/prettier
    const { valor,} = custoTempoEditing;

    if (!valor) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    try {
      if (custoTempoEditing.seq) {
        await api.put(`/integracao/custo-tempo/${custoTempoEditing.seq}`, {
          seq: custoTempoEditing.seq,
          descricao: custoTempoEditing.descricao,
          unidadeMedida: custoTempoEditing.unidadeMedida,
          valor: custoTempoEditing.valor,
          valorDescritivoIni: custoTempoEditing.valorDescritivoIni,
          valorDescritivoFim: custoTempoEditing.valorDescritivoFim,
          texto: custoTempoEditing.texto,
        });
      }
      /* else {
    await api.post(`/integracao/custo-tempo/`, {
      descricao: custoTempoEditing.descricao,
      unidadeMedida: custoTempoEditing.unidadeMedida,
      valor: custoTempoEditing.valor,
      valorDescritivoIni: custoTempoEditing.valorDescritivoIni,
      valorDescritivoFim: custoTempoEditing.valorDescritivoFim,
    });
    } */
      toast.success('Registro concluído');
    } finally {
      setFormModalState(false);
      setCustoTempoEditing({} as CustoTempoModel);
      listaCustoTempo();
    }
  }

  async function mostrarModalInformativa(itemCusto?: CustoTempoModel) {
    try {
      setLoading(true);

      if (itemCusto) {
        const rows: any[] = [];

        rows.push(`DE: ${itemCusto?.valorDescritivoIni}`);
        rows.push(`ATÉ: ${itemCusto?.valorDescritivoFim}`);
        rows.push(`UN: ${itemCusto?.unidadeMedida}`);
        rows.push(`Valor: ${itemCusto?.valor}`);

        setRowsModal(rows);
        setModalTitulo(` ${itemCusto?.texto}`);
        setModalStatusVisivel(true);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/*
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
  */}
        <GenericModal
          title={modalTitulo}
          rows={rowsModal}
          modalState={modalStatusVisivel}
          toggle={() => setModalStatusVisivel(false)}
        />

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{custoTempoEditing.descricao}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Descrição</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={custoTempoEditing.descricao}
                            type="text"
                            disabled
                            onChange={text =>
                              setCustoTempoEditing({
                                ...custoTempoEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Texto alternativo </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Texto alternativo"
                            value={custoTempoEditing.texto}
                            type="text"
                            onChange={text =>
                              setCustoTempoEditing({
                                ...custoTempoEditing,
                                texto: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Un</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Unidade de medida"
                            value={custoTempoEditing.unidadeMedida}
                            type="text"
                            disabled
                            onChange={text =>
                              setCustoTempoEditing({
                                ...custoTempoEditing,
                                unidadeMedida: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Valor *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Valor"
                            value={custoTempoEditing.valor}
                            type="number"
                            onChange={text =>
                              setCustoTempoEditing({
                                ...custoTempoEditing,
                                valor: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>De</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Valor descritivo Ini"
                            value={Number(custoTempoEditing.valorDescritivoIni)}
                            type="number"
                            onChange={text =>
                              setCustoTempoEditing({
                                ...custoTempoEditing,
                                valorDescritivoIni: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Até</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Valor descritivo Fim"
                            value={Number(custoTempoEditing.valorDescritivoFim)}
                            type="number"
                            onChange={text =>
                              setCustoTempoEditing({
                                ...custoTempoEditing,
                                valorDescritivoFim: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveCustoTempo}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Custo x Tempo</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Descrição</th>
                    <th>De</th>
                    <th>Até</th>
                    <th>Un</th>
                    <th>Valor</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {custoTempoLista.map(itemCustoTempo => {
                    const labelTooltip = itemCustoTempo.texto;
                    return (
                      <tr key={itemCustoTempo.seq}>
                        <td className="py-2">
                          {itemCustoTempo.descricao}
                          {}
                          <Tooltip title={labelTooltip}>
                            <Button
                              disabled={loading}
                              className="btn-icon btn-2 ml-4"
                              color="info"
                              type="button"
                              size="sm"
                              onClick={() =>
                                mostrarModalInformativa(itemCustoTempo)
                              }
                            >
                              <FaInfoCircle size={8} />
                            </Button>
                          </Tooltip>
                        </td>
                        <td className="py-2">
                          {itemCustoTempo.valorDescritivoIni ?? 0}
                        </td>
                        <td className="py-2">
                          {itemCustoTempo.valorDescritivoFim ?? 0}
                        </td>
                        <td className="py-2">{itemCustoTempo.unidadeMedida}</td>
                        <td className="py-2">{itemCustoTempo.valor}</td>
                        <td className="py-2">
                          <div className="float-right">
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              onClick={() => {
                                handleUpdate(itemCustoTempo);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            {/*
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(Number(itemCustoTempo.seq));
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                          */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorCustoTempo;
