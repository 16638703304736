import FichaTecnica from 'models/FichaTecnica';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
  Table,
} from 'reactstrap';
import api from 'services/api';

import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  rgbToHex,
} from '@material-ui/core';

import Swal from 'sweetalert2';
import { FichaTecnicaBombeamento } from 'models/FichaTecnicaBombeamento';
import BombaModel from 'models/ConfiguradorBomba';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import toast from 'react-hot-toast';
import OpcoesLances from 'views/pages/CadastroOrcamento/components/OpcoesLances';
import { FaGlobe, FaInfo, FaInfoCircle } from 'react-icons/fa';
import FichaTecnicaNecessidadeReprocessamento from 'models/FichaTecnicaNecessidadeReprocessamento';
import FuncaoEntrada from 'models/FuncaoEntrada';
// import DadosBombeamento from 'views/pages/OrcamentoResultado/components/organisms/DadosCalculados/components/DadosBombeamento';
import { ContainerRow, ContainerButtons, TextField } from './styles';
import { AdutoraBombeamentoContext } from '../..';
import { Td } from '../Adutora/styles';
import Reprocessamento from '../Reprocessamento';
// ovidio
import {
  getListaSugestaoBombas,
  BombaSugeria,
  calcularCores,
} from './sugestao';

declare let window: any;

interface LocationState {
  fichaTecnica: FichaTecnica;
  orcamentoId: number;
  orcamentoParteId: number;
}

interface Bomba {
  id: number;
  nome: string;
  urlFabricante?: string;
  indicAtivo: string;
}

interface ModeloBomba {
  seq: number;
  nome: string;
  indicAtivo?: string;
}

interface OpcaoEletricaBombeamento {
  tensaoNominal: number;
  bitolaCabo: number;
  quedaTensao: number;
}

function Bombeamento(): JSX.Element {
  const {
    vazaoPrevista,
    pressaoPrevista,
    bomba,
    quantidadeBomba,
    setFichaTecnicaBombeamento,
    quantidadeModulos,
    setQuantidadeModulos,
    orcamentoId,
    fichaTecnica,
    fichaTecnicaId,
    sessaoId,
    necessidadeReprocessamento,
    setNecessidadeReprocessamento,
    necessidadeReprocessamentoData,
  } = useContext(AdutoraBombeamentoContext);

  const location = useLocation<LocationState>();
  const { fichaTecnica: fichaTecnicaDados } = location.state;

  const fichaTecnicaBombeamento = fichaTecnica
    ?.FichaTecnicaBombeamento?.[0] as FichaTecnicaBombeamento;

  const isNecessidadeReprocessamento =
    necessidadeReprocessamento?.reprocessarBombeamento || false;

  const RENDIMENTO_DEFAULT = 75;
  const ENERGIA_BOMBEAMENTO_DEFAULT = 'Conjunta';
  const MOTOR_DEFAULT = 'Elétrico';

  const [marcasBomba, setMarcasBomba] = useState<Bomba[]>([]);
  const [modelosBomba, setModelosBomba] = useState<ModeloBomba[]>([]);
  const [modeloBombaAtiva, setModeloBombaAtiva] = useState<ModeloBomba[]>([]);

  const [marcaBombaSelecionada, setMarcaBombaSelecionada] = useState('');
  const [carregandoModelosBomba, setCarregandoModelosBomba] = useState(false);
  const [marcaBombaAtiva, setMarcaBombaAtiva] = useState<Bomba[]>([]);
  const [comboMarcas, setComboMarcas] = useState<Bomba[]>([]);
  const [modeloBombaSelecionado, setModeloBombaSelecionado] = useState('');
  const [vazaoAlternativa, setVazaoAlternativa] = useState(0);
  const [pressaoAlternativa, setPressaoAlternativa] = useState(0);
  const [rendimento, setRendimento] = useState(RENDIMENTO_DEFAULT);
  const [energiaBombeamento, setEnergiaBombeamento] = useState(
    ENERGIA_BOMBEAMENTO_DEFAULT,
  );
  const [motor, setMotor] = useState(MOTOR_DEFAULT);
  const [potenciaNominal, setPotenciaNominal] = useState(0);

  const [diametroSuccaoEntrada, setDiametroSuccaoEntrada] = useState('');
  const [diametroRecalqueSaida, setDiametroRecalqueSaida] = useState('');
  const [BombaTipoFlange, setBombaTipoFlange] = useState('');
  const [fabricanteSelecionado, setfabricanteSelecionado] = useState('');
  const [isUrlFabricante, setIsUrlFabricante] = useState(false);
  const [numeroEstagios, setNumeroEstagios] = useState(0);
  const [diametroEstimadoRotores, setDiametroEstimadoRotores] = useState(0);
  const [potenciaAbsorvidaEixoBomba, setPotenciaAbsorvidaEixoBomba] =
    useState(0);
  const [potenciaCasaMaquina, setPotenciaCasaMaquina] = useState(0);

  const [loadingCalculoBombeamento, setLoadingCalculoBombeamento] =
    useState(false);
  const [fichaTecnicaDadosBombeamento, setFichaTecnicaDadosBombeamento] =
    useState({} as FichaTecnicaBombeamento);
  const [funcaoEntradas, setFuncaoEntradas] = useState<FuncaoEntrada[]>();
  const [bombasSugeridas, setbombasSugeridas] = useState<BombaSugeria[]>([]); // ovidio
  const [semSugestaoBomba, setSemSugestaoBomba] = useState(true);
  const [acimaLimiteCurva, setAcimaLimiteCurva] = useState(false);

  // NPSH
  const [amplitudeCurva, setAmplitudeCurva] = useState(0);
  const [npshRequerido, setNpshRequerido] = useState(0);
  const [ocupacaoCurva, setOcupacaoCurva] = useState(0);
  const [acessoLimitadoEditaNpsh, setAcessoLimitadoEditaNpsh] = useState(false);
  const [bombas, setBombas] = useState([] as BombaModel[]);

  useEffect(() => {
    if (fichaTecnica?.FichaTecnicaBombeamento) {
      setFichaTecnicaBombeamento(fichaTecnica?.FichaTecnicaBombeamento);
      setFichaTecnicaDadosBombeamento(fichaTecnica?.FichaTecnicaBombeamento[0]);
    }
  }, [fichaTecnica]);

  async function atualizarBombeamento() {
    const response = await api.get(`/ficha-tecnica/${fichaTecnicaId}`);

    const varFT = response.data as FichaTecnica;

    setFichaTecnicaBombeamento(
      varFT?.FichaTecnicaBombeamento as FichaTecnicaBombeamento[],
    );
    setFichaTecnicaDadosBombeamento(
      varFT?.FichaTecnicaBombeamento?.[0] as FichaTecnicaBombeamento,
    );
  }

  async function listarBombas() {
    const response = await api.get('/integracao/bombas');

    setBombas(response.data);
  }

  useEffect(() => {
    listarBombas();
  }, []);

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 78,
      },
    });
    setAcessoLimitadoEditaNpsh(response.data);
  }

  async function calculaEletricaBombeamento() {
    const response = await api.post(
      '/integracao/bombeamento/eletrica-subterranea/calculo',
      {
        sessao: sessaoId,
        orcamentoId,
        fichaTecnicaId,
      },
    );

    return response.data as OpcaoEletricaBombeamento[];
  }

  async function calculaTransformadorBombeamento() {
    await api.post('/integracao/bombeamento/transformador/calculo', {
      sessao: sessaoId,
      orcamentoId,
      fichaTecnicaId,
    });
  }

  async function calculaBombeamento() {
    try {
      let vaiSalvar = false;
      if (bombasSugeridas.length > 0 || rendimento === 0) {
        const bombaSelecionada = bombasSugeridas.find(
          bombax => String(bombax?.modelo) === modeloBombaSelecionado,
        );

        if (bombaSelecionada?.calcRotor === 0) {
          const valor = await Swal.fire({
            title: `Atenção`,
            html: `<div style='text-align: justify!important; text-justify: inter-word;'>
            O modelo da bomba selecionada não atende a Vazão
            ${vazaoPrevista} m³/h e Altura manométrica ${pressaoPrevista} mca! </div>`,
            icon: 'warning',
            //  showCancelButton: true,
            // confirmButtonText: `Sim, prosseguir`,
            cancelButtonText: `Não`,
          });

          vaiSalvar = valor.isConfirmed;
        }
      }

      setLoadingCalculoBombeamento(true);
      const bombaFiltrada = bombas?.filter(
        item =>
          String(item.nome).toUpperCase() ===
          String(modeloBombaSelecionado).toUpperCase(),
      );

      const numeroPolos = bombaFiltrada?.find(item => item)?.nroPolos;
      const diteCodigo = bombaFiltrada?.find(item => item)?.ditbDiteCodigo;

      const response = await api.post(
        '/integracao/bombeamento/orcamento/calculo',
        {
          sessao: sessaoId,
          orcamentoId,
          fichaTecnicaId,
          bomba,
          quantidadeBomba,
          marcaBomba: marcaBombaSelecionada,
          modeloBomba: modeloBombaSelecionado,
          quantidadeModulos,
          vazaoAlternativa,
          pressaoAlternativa,
          rendimento,
          energiaBombeamento,
          motor,
          potenciaNominal,
          numeroEstagios,
          diametroEstimadoRotores,
          // npsh
          amplitudeCurva,
          npshRequerido,
          ocupacaoCurva,
          numeroPolos,
          diteCodigo,
        },
      );
      // navigator.clipboard.writeText(`${JSON.stringify(fichaTecnica)}`);
      // if (fichaTecnica.modeloSuccao !== 'Afogada') alert('ovs');

      if (response.data) {
        const ftDiametroSuccaoEntrada = response.data.diametroSuccaoEntrada;
        const ftDiametroRecalqueSaida = response.data.diametroRecalqueSaida;
        const ftBombaTipoFlange = response.data.bombaTipoFlange;
        setDiametroRecalqueSaida(
          ftDiametroRecalqueSaida ? String(ftDiametroRecalqueSaida) : '',
        );
        setDiametroSuccaoEntrada(
          ftDiametroSuccaoEntrada ? String(ftDiametroSuccaoEntrada) : '',
        );
        setBombaTipoFlange(ftBombaTipoFlange || '');
      }

      await calculaTransformadorBombeamento();
      await calculaEletricaBombeamento();

      await atualizarBombeamento();

      necessidadeReprocessamentoData({
        ...necessidadeReprocessamento,
        reprocessarBombeamento: false,
      } as FichaTecnicaNecessidadeReprocessamento);

      toast.success('Cálculo finalizado!', { position: 'top-right' });
    } finally {
      setLoadingCalculoBombeamento(false);
    }
  }

  async function carregaMarcasBombas() {
    const response = await api.get('/integracao/bombas/fabricantes');
    setMarcasBomba(response.data);
    setMarcaBombaAtiva(
      response.data.filter(
        (item: { indicAtivo: string }) => item.indicAtivo === 'S',
      ),
    );
  }

  useEffect(() => {
    if (modelosBomba.length && fichaTecnicaBombeamento) {
      const { modeloBomba } = fichaTecnicaBombeamento;
      // setModeloBombaSelecionado(modeloBomba || '');
      if (modeloBombaSelecionado === '')
        setModeloBombaSelecionado(modeloBomba || '');
      const marcas = modelosBomba?.filter(
        item => item.nome === modeloBombaSelecionado,
      );
      if (marcas.length > 0) {
        if (marcas[0].indicAtivo !== 'S') {
          const itens = modelosBomba.filter(item => item.indicAtivo === 'S');
          itens.push(marcas[0]);
          setModeloBombaAtiva(itens);
        }
      }
    }
  }, [fichaTecnicaBombeamento, modelosBomba]);

  useEffect(() => {
    async function carregaModelosBomba() {
      const codigoMarcaBombaSelecionada = marcasBomba?.find(
        marcaBomba => marcaBomba.nome === marcaBombaSelecionada,
      )?.id;
      if (!codigoMarcaBombaSelecionada) {
        return;
      }
      try {
        setCarregandoModelosBomba(true);
        const response = await api.get(
          `/integracao/bombas/${codigoMarcaBombaSelecionada}/modelos`,
        );

        setModeloBombaAtiva(
          response.data.filter(
            (item: { indicAtivo: string }) => item.indicAtivo === 'S',
          ),
        );

        setModelosBomba(response.data);
      } finally {
        setCarregandoModelosBomba(false);
      }
    }
    if (marcaBombaSelecionada && marcasBomba.length) {
      carregaModelosBomba();
    }
  }, [marcaBombaSelecionada, marcasBomba]);

  useEffect(() => {
    async function linkFabricante() {
      const urlMarcaSelecionada = marcasBomba?.find(
        marcaBomba => marcaBomba.nome === marcaBombaSelecionada,
      )?.urlFabricante;

      if (!urlMarcaSelecionada) {
        setIsUrlFabricante(false);
        return;
      }

      setfabricanteSelecionado(urlMarcaSelecionada);
      setIsUrlFabricante(true);
    }
    if (marcaBombaSelecionada && marcasBomba.length) {
      linkFabricante();
    }
  }, [marcaBombaSelecionada, marcasBomba]);

  useEffect(() => {
    carregaMarcasBombas();
  }, []);

  useEffect(() => {
    if (fichaTecnicaDadosBombeamento) {
      setPotenciaAbsorvidaEixoBomba(
        Number(fichaTecnicaDadosBombeamento?.potenciaAbsorvidaEixoBomba),
      );
      setPotenciaCasaMaquina(
        Number(fichaTecnicaDadosBombeamento?.potenciaCasaMaquina),
      );
    }
  }, [fichaTecnicaDadosBombeamento]);

  async function carregarRespostaFuncao() {
    const response = await api.get(
      `/orcamento/resposta-funcao/?orcamentoVersaoId=${orcamentoId}`,
    );

    if (response.data) {
      const { fonteEnergia } = response.data;

      if (fonteEnergia) {
        const fonteUpper = String(fonteEnergia).toUpperCase();
        let energiaUsar = ENERGIA_BOMBEAMENTO_DEFAULT;

        if (fonteUpper === 'BOMBEAMENTO') energiaUsar = 'Conjunta';
        else if (fonteUpper.includes('RAIO')) energiaUsar = 'Individual';

        // Fonte de Energia = Bombeamento → Energia bombeamento = Conjunta
        // Fonte de Energia = Raio Pivô → Energia bombeamento = Individual
        setEnergiaBombeamento(energiaUsar);
      }
    }
  }

  useEffect(() => {
    carregarRespostaFuncao();
  }, []);

  useEffect(() => {
    function setaDadosBombeamento() {
      const {
        marcaBomba: ftMarcaBomba,
        energiaBombeamento: ftEnergiaBombeamento,
        motor: ftMotor,
        potenciaNominal: ftPotenciaNominal,
        pressaoAlternativa: ftPressaoAlternativa,
        vazaoAlternativa: ftVazaoAlternativa,
        rendimento: ftRendimento,
        quantidadeModulos: ftQuantidadeModulos,
        diametroSuccaoEntrada: ftDiametroSuccaoEntrada,
        diametroRecalqueSaida: ftDiametroRecalqueSaida,
        bombaTipoFlange: ftBombaTipoFlange,
        numeroEstagios: ftNumeroEstagios,
        diametroEstimadoRotores: ftDiametroEstimadoRotores,
        // npsh
        amplitudeCurva: ftAmplitudeCurva,
        npshRequerido: ftNpshRequerido,
        ocupacaoCurva: ftOcupacaoCurva,
      } = fichaTecnicaBombeamento;
      setAcimaLimiteCurva(false);
      setMarcaBombaSelecionada(ftMarcaBomba || '');
      const marcas = marcasBomba.filter(
        item => item.nome === marcaBombaSelecionada,
      );
      if (marcas.length > 0) {
        if (marcas[0].indicAtivo !== 'S') {
          const itens = marcasBomba.filter(
            (item: { indicAtivo: string }) => item.indicAtivo === 'S',
          );
          itens.push(marcas[0]);
          setMarcaBombaAtiva(itens);
        }
      }

      // O uso da função carregarRespostaFuncao substitui esse recurso
      /* setEnergiaBombeamento(
        ftEnergiaBombeamento || ENERGIA_BOMBEAMENTO_DEFAULT,
      ); */
      setMotor(ftMotor || MOTOR_DEFAULT);
      setPotenciaNominal(ftPotenciaNominal || 0);
      setPressaoAlternativa(ftPressaoAlternativa || 0);
      setVazaoAlternativa(ftVazaoAlternativa || 0);
      setRendimento(ftRendimento || RENDIMENTO_DEFAULT);
      setQuantidadeModulos(ftQuantidadeModulos || 1);
      setNumeroEstagios(ftNumeroEstagios || 1);
      setDiametroEstimadoRotores(ftDiametroEstimadoRotores || 0);
      setDiametroRecalqueSaida(
        ftDiametroRecalqueSaida ? String(ftDiametroRecalqueSaida) : '',
      );
      setDiametroSuccaoEntrada(
        ftDiametroSuccaoEntrada ? String(ftDiametroSuccaoEntrada) : '',
      );
      setBombaTipoFlange(ftBombaTipoFlange || '');

      // NPSH
      setAmplitudeCurva(ftAmplitudeCurva || 0);
      setNpshRequerido(ftNpshRequerido || 0);
      setOcupacaoCurva(ftOcupacaoCurva || 0);
    }

    if (fichaTecnicaBombeamento) {
      setaDadosBombeamento();
    }
  }, [fichaTecnicaBombeamento, marcasBomba, marcaBombaAtiva]);

  // ovidio Chart
  window.google.load('visualization', '1', {
    packages: ['columnchart', 'corechart', 'table'],
  });

  async function initChart(selecionado: string) {
    const bombaSelecionada = bombasSugeridas.find(
      bombax => String(bombax?.modelo) === selecionado,
    );

    if (window.google && bombaSelecionada) {
      if (bombaSelecionada.fabricante)
        setMarcaBombaSelecionada(bombaSelecionada.fabricante);

      if (selecionado) setModeloBombaSelecionado(selecionado);

      if (bombaSelecionada.calcRotor > 0) {
        setRendimento(Number(bombaSelecionada.calcRend.toFixed(2)));
        setDiametroEstimadoRotores(
          Number(bombaSelecionada.calcRotor.toFixed(0)),
        );
      }

      const chartDiv = document.getElementById('bomba_chart');
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();

      data.addColumn('number', 'vazao');
      data.addColumn('number', 'alturaInicial');
      data.addColumn('number', 'alturaFinal');
      data.addColumn('number', 'ponto');

      const lista = bombaSelecionada.grafico;

      for (let i = 0; i < lista.length; i += 1) {
        const ai = lista[i].alturaInicial > 0 ? lista[i].alturaInicial : null;
        const af = lista[i].alturaFinal > 0 ? lista[i].alturaFinal : null;
        data.addRow([lista[i].vazao, ai, af, lista[i].ponto]);
      }

      // Draw the chart using the data within its DIV.
      chart.draw(data, {
        height: 450,
        title: bombaSelecionada.modelo,
        legend: 'true',
        titleY: `Altura ( ${bombaSelecionada.alturaDesej} mca) `,
        titleX: `Vazão ( ${bombaSelecionada.vazaoDesej} m³/h ) `,
        curveType: 'function',
        series: {
          2: {
            type: 'scatter',
            color: 'red',
            pointSize: 7,
          },
        },
      });
    } else if (window.google) {
      const chartDiv = document.getElementById('bomba_chart');
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();
      data.addColumn('number', 'vazao');
      data.addColumn('number', 'ponto');
      data.addRow([0, 0]);
      chart.draw(data, {
        height: 450,
        title: `Não encontrada as curvas da ${selecionado}`,
      });
    }
  }

  async function infoSugestaoBombas(modelo: string) {
    let prt = pressaoPrevista;
    let vzt = vazaoPrevista;
    let modb = modeloBombaSelecionado;
    if (vazaoAlternativa > 0) vzt = vazaoAlternativa;
    if (pressaoAlternativa > 0) prt = pressaoAlternativa;
    if (modelo !== '') modb = modelo;
    const bbs = await getListaSugestaoBombas(
      Number(prt.toFixed(2)),
      Number(vzt.toFixed(2)),
      modb,
    );

    // bbs.sort((a: any, b: any) => b.calcPercOcupacao - a.calcPercOcupacao);
    bbs.sort((a: any, b: any) => b.calcRend - a.calcRend);
    setbombasSugeridas(bbs);

    const bbsel = bbs.find(bbc => bbc?.modelo === modb);

    if (bbsel) {
      setRendimento(Number(bbsel.calcRend.toFixed(2)));
      setAmplitudeCurva(
        Number((bbsel.calcAlturaFinal - bbsel.calcAlturaInicial).toFixed(0)),
      );
      setNpshRequerido(Number(bbsel.calcNpsh.toFixed(2)));
      setOcupacaoCurva(Number((bbsel.calcPercOcupacao * 100).toFixed(2)));
      setAcimaLimiteCurva(bbsel.calcPercOcupacao > 0.9);
    }
  }

  useEffect(() => {
    infoSugestaoBombas('');
  }, [
    pressaoPrevista,
    vazaoPrevista,
    vazaoAlternativa,
    pressaoAlternativa,
    modeloBombaSelecionado,
  ]); // , vazaoAlternativa, pressaoAlternativa

  useEffect(() => {
    initChart(modeloBombaSelecionado);

    let semSugestao = true;
    if (bombasSugeridas.length > 0) {
      const bombaSelecionada = bombasSugeridas.find(
        bombax => String(bombax?.modelo) === modeloBombaSelecionado,
      );
      semSugestao = bombaSelecionada?.calcRotor === 0;
    }
    setSemSugestaoBomba(semSugestao);
  }, [modeloBombaSelecionado, bombasSugeridas]);

  useEffect(() => {
    getAcessoLimitadoPrograma();
  }, []);

  return (
    <>
      <Card className="shadow">
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>Modelos Sugeridos de bombas</LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>
        <Row>
          <Col style={{ width: '50%', margin: 'auto' }}>
            <InputLabel hidden={!semSugestaoBomba}>
              <strong>⚠︎ ATENÇÃO:</strong> Não foram localizadas as curvas para
              os parâmetros de vazão {vazaoPrevista} m³/h e altura manométrica{' '}
              {pressaoPrevista} mca <br />
              <br />
              <strong style={{ color: '#ff0000' }}>
                Favor consultar a engenharia!
              </strong>
              <br />
              <br />
            </InputLabel>
            <br />
            <InputLabel
              hidden={
                bombasSugeridas.length === 0 ||
                bombasSugeridas[0]?.modelo === ''
              }
            >
              <strong>Modelos sugeridos / atual </strong>
              <Button
                color="info"
                type="button"
                title="Atualizar sugestões"
                onClick={() => {
                  infoSugestaoBombas('');
                }}
                size="sm"
              >
                <FaInfoCircle />
              </Button>
            </InputLabel>
            <Table
              bordered
              responsive
              hidden={
                bombasSugeridas.length === 0 ||
                bombasSugeridas[0]?.modelo === ''
              }
            >
              <thead>
                <tr>
                  <th>Modelo</th>
                  {/* <th>Aplic</th> */}
                  <th>Fabric</th>
                  <th>Pot.Absorv</th>
                  <th>NPSH</th>
                  <th>Ocupação</th>
                  {/* <th>Valor</th> */}
                </tr>
              </thead>
              <tbody>
                {bombasSugeridas?.map(bbc => (
                  <tr>
                    <Td
                      title={`${bbc.aplicacao}`}
                      style={{
                        borderWidth: 2,
                        background:
                          bbc.modelo === modeloBombaSelecionado &&
                          bbc.calcRend !== 0
                            ? '#69f050'
                            : 'white',
                        borderColor:
                          bbc.modelo === modeloBombaSelecionado &&
                          bbc.calcRend === 0
                            ? 'red'
                            : '#f1f1f1',
                      }}
                      key={bbc.seq}
                      onClick={() => {
                        if (bbc) {
                          necessidadeReprocessamentoData({
                            ...necessidadeReprocessamento,
                            reprocessarBombeamento: true,
                          } as FichaTecnicaNecessidadeReprocessamento);

                          setModeloBombaSelecionado(bbc.modelo);
                          setNpshRequerido(Number(bbc.calcNpsh.toFixed(2)));
                          // Exemplo de uso
                          setOcupacaoCurva(
                            Number((bbc.calcPercOcupacao * 100).toFixed(2)),
                          );
                          setAmplitudeCurva(
                            Number(
                              (
                                bbc.calcAlturaFinal - bbc.calcAlturaInicial
                              ).toFixed(0),
                            ),
                          );
                        }
                      }}
                    >
                      <b>{bbc.modelo}</b>
                    </Td>
                    {/* <Td title={`${bbc.aplicacao}`}>{bbc.indicTipo}</Td> */}
                    <Td>{bbc.fabricante}</Td>
                    <Td title={`Rendimento: ${bbc.calcRend.toFixed(2)}%`}>
                      {bbc.calcPotencia.toFixed(2)} cv
                    </Td>
                    <Td>{bbc.calcNpsh.toFixed(2)}</Td>
                    <Td
                      style={{
                        borderWidth: 2,
                        background:
                          bbc.calcPercOcupacao > 0.9 ? '#ff0000' : 'white',
                        color:
                          bbc.calcPercOcupacao > 0.9 ? '#ffffff' : '#222222',
                      }}
                    >
                      {(bbc.calcPercOcupacao * 100).toFixed(2)}%
                    </Td>
                    {/* <Td>{bbc.valor}</Td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
            <br />
            <InputLabel
              hidden={!acimaLimiteCurva}
              style={{ fontSize: 15, color: '#ff0000' }}
            >
              *A bomba ideal será a de melhor rendimento com menor NPSH e
              percentual de ocupação até 90%.
            </InputLabel>
          </Col>
          <Col style={{ width: '50%', margin: 'auto' }}>
            <div id="bomba_chart" style={{ height: 480 }} />
          </Col>
        </Row>
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>Bombeamento</LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>
        <Row>
          <Col className="mt-2 pb-4">
            <ContainerRow>
              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Bomba</InputLabel>
                  <Select disabled value={bomba} label="Bomba" autoWidth>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Série">Série</MenuItem>
                    <MenuItem value="Paralelo">Paralelo</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <TextField
                  disabled
                  size="small"
                  label="Qtde bomba"
                  type="number"
                  value={quantidadeBomba}
                />
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Marca bomba</InputLabel>
                  <Select
                    value={marcaBombaSelecionada}
                    autoWidth
                    onChange={event =>
                      setMarcaBombaSelecionada(event.target.value as string)
                    }
                  >
                    {marcaBombaAtiva.map(marcaBomba => (
                      <MenuItem key={marcaBomba.id} value={marcaBomba.nome}>
                        {marcaBomba.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col sm="1">
                <Tooltip title="Ir para site do fabricante">
                  <Button
                    className="btn-icon btn-2 mt-4"
                    size="sm"
                    color="info"
                    disabled={!isUrlFabricante}
                    type="button"
                    onClick={() => {
                      if (isUrlFabricante) {
                        window.open(fabricanteSelecionado, '_blank');
                      }
                    }}
                  >
                    <FaGlobe />
                  </Button>
                </Tooltip>
              </Col>

              <Col sm="3">
                <FormControl fullWidth>
                  <InputLabel>Modelo bomba</InputLabel>
                  <Select
                    value={modeloBombaSelecionado}
                    autoWidth
                    disabled={carregandoModelosBomba}
                    onChange={event => {
                      setModeloBombaSelecionado(event.target.value as string);
                      infoSugestaoBombas(event.target.value as string);
                    }}
                  >
                    {modeloBombaAtiva.length > 0 ? (
                      modeloBombaAtiva.map(modeloBomba => (
                        <MenuItem
                          key={modeloBomba.seq}
                          value={modeloBomba.nome}
                        >
                          {modeloBomba.nome}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">Nenhum modelo disponível</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <TextField
                  disabled
                  size="small"
                  label="Qtde módulos"
                  type="number"
                  value={quantidadeModulos}
                />
              </Col>
            </ContainerRow>
            <ContainerRow className="mt-4">
              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Vazão alternativa"
                  value={vazaoAlternativa}
                  onChange={event =>
                    setVazaoAlternativa(Number(event.target.value) ?? 0)
                  }
                />
              </Col>

              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Pressão alternativa"
                  value={pressaoAlternativa}
                  onChange={event =>
                    setPressaoAlternativa(Number(event.target.value) ?? 0)
                  }
                />
              </Col>

              <Col sm="2">
                <TextField
                  size="small"
                  type="number"
                  label="Rendimento (%)"
                  value={rendimento}
                  onChange={event =>
                    setRendimento(Number(event.target.value) ?? 0)
                  }
                />
              </Col>
              <Col sm="2">
                <FormControl fullWidth>
                  <TextField
                    disabled
                    size="small"
                    type="number"
                    label={`Ocupação Amplitude H[${amplitudeCurva}m] (%)`}
                    value={ocupacaoCurva}
                  />
                </FormControl>
              </Col>
              <Col sm="2">
                <FormControl fullWidth>
                  <TextField
                    disabled={acessoLimitadoEditaNpsh}
                    size="small"
                    type="number"
                    label="NPSH requerido (m)"
                    value={npshRequerido}
                    onChange={event =>
                      setNpshRequerido(Number(event.target.value) ?? 0)
                    }
                  />
                </FormControl>
              </Col>
            </ContainerRow>

            <ContainerRow className="mt-4">
              <Col sm="3">
                <TextField
                  disabled
                  size="small"
                  label="Diâmetro Sucção Entrada"
                  type="number"
                  value={diametroSuccaoEntrada}
                />
              </Col>

              <Col sm="3">
                <TextField
                  disabled
                  size="small"
                  label="Diâmetro Recalque Saída"
                  type="number"
                  value={diametroRecalqueSaida}
                />
              </Col>

              <Col sm="3">
                <TextField
                  disabled
                  size="small"
                  label="Tipo Flange"
                  type="text"
                  value={BombaTipoFlange}
                />
              </Col>
              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Diâmetro Estimado dos Rotores"
                  value={diametroEstimadoRotores || 0}
                  onChange={event =>
                    setDiametroEstimadoRotores(Number(event.target.value) ?? 0)
                  }
                />
              </Col>
            </ContainerRow>

            <ContainerRow className="mt-4">
              <Col sm="3">
                <FormControl fullWidth>
                  <InputLabel>Energia bombeamento</InputLabel>
                  <Select
                    value={energiaBombeamento}
                    autoWidth
                    /* onChange={event =>
                      setEnergiaBombeamento(event.target.value as string)
                    } */
                    disabled
                  >
                    <MenuItem value="Conjunta">Conjunta</MenuItem>
                    <MenuItem value="Individual">Individual</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="3">
                <FormControl fullWidth>
                  <InputLabel>Motor</InputLabel>
                  <Select
                    value={motor}
                    autoWidth
                    onChange={event => setMotor(event.target.value as string)}
                  >
                    <MenuItem value="Elétrico">Elétrico</MenuItem>
                    <MenuItem value="Diesel">Diesel</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Potência nominal do grupo gerador"
                  value={potenciaNominal}
                  onChange={event =>
                    setPotenciaNominal(Number(event.target.value) ?? 0)
                  }
                />
              </Col>
              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Número de Estágios"
                  value={numeroEstagios || 1}
                  onChange={event =>
                    setNumeroEstagios(Number(event.target.value) ?? 0)
                  }
                />
              </Col>
            </ContainerRow>

            <ContainerButtons className="pr-4 pt--3 mt-3">
              <Col className="ml-2 mb-2 mr--2">
                <Table
                  bordered
                  responsive
                  style={{ width: '50%', margin: 'auto' }}
                >
                  <thead>
                    <tr>
                      <th>Potência Absorvida no Eixo da Bomba</th>
                      <th>Potência nominal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <Td>
                        {!potenciaAbsorvidaEixoBomba ||
                        potenciaAbsorvidaEixoBomba === 0
                          ? ''
                          : `${potenciaAbsorvidaEixoBomba} cv`}
                      </Td>
                      <Td>
                        {!potenciaCasaMaquina || potenciaCasaMaquina === 0
                          ? ''
                          : `${potenciaCasaMaquina} cv`}
                      </Td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col className="ml-2 mb-2 mr--2">
                <Button
                  disabled={loadingCalculoBombeamento || rendimento === 0}
                  className="btn-icon btn-2"
                  color="danger"
                  type="button"
                  onClick={calculaBombeamento}
                  size="sm"
                >
                  {loadingCalculoBombeamento
                    ? 'Calculando bombeamento...'
                    : 'Calcular bombeamento'}
                </Button>
                {isNecessidadeReprocessamento && <Reprocessamento />}
              </Col>
            </ContainerButtons>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Bombeamento;
