import { ListItem } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  PropostaNegocioOracle,
  PropostaNegocioVersaoAtividadeOracle,
} from 'models/PropostaGNFOracle';
import { memo, useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Col, Collapse, Row } from 'reactstrap';
import api from 'services/api';
import Material from '../Material';

interface IAtividade {
  numeroNegocio: number;
  propostaNegocioVersao: PropostaNegocioOracle;
}

function Atividade({
  propostaNegocioVersao,
  numeroNegocio,
}: IAtividade): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  const [propostaNegocioVersaoAtividade, setPropostaNegocioVersaoAtividade] =
    useState<PropostaNegocioVersaoAtividadeOracle[]>([]);

  async function getDadosGNFAtividade() {
    setIsLoading(true);
    const response = await api.get(
      `/proposta-negocio/${numeroNegocio}?tipo=atividade&sequencia=${propostaNegocioVersao.NEGO_NUMERO_NEGOCIO}`,
      {},
    );

    setPropostaNegocioVersaoAtividade(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    if (numeroNegocio && propostaNegocioVersao.NEGO_NUMERO_NEGOCIO) {
      getDadosGNFAtividade();
    }
  }, [numeroNegocio]);

  function renderLista(
    item: PropostaNegocioVersaoAtividadeOracle,
    itemPai = true,
  ) {
    const isOpen = propostaNegocioVersaoAtividade.find(
      x => x === item,
    )?.IS_OPEN;

    function handleToggleAccordion() {
      setPropostaNegocioVersaoAtividade(
        propostaNegocioVersaoAtividade.map(x =>
          x.ATIV_CODIGO === item.ATIV_CODIGO
            ? { ...x, IS_OPEN: !x.IS_OPEN }
            : x,
        ),
      );
    }

    function renderExpand() {
      return isOpen ? (
        <ExpandLess onClick={handleToggleAccordion} />
      ) : (
        <ExpandMore onClick={handleToggleAccordion} />
      );
    }

    return (
      <div key={`proposta_ativ_${item.ATIV_CODIGO}`}>
        <ListItem style={{ borderBottom: '1px dashed #b1b1b1' }}>
          <Col sm="2">
            <h3 className="mb-0">#{item.ATIV_CODIGO}</h3>
          </Col>
          <Col sm="7">
            <h3 className="mb-0">{item.DESCRICAO}</h3>
          </Col>
          <Col sm="3">
            <div className="float-right">Materiais {renderExpand()}</div>
          </Col>
        </ListItem>
        <Collapse isOpen>
          {isOpen === true ? (
            <>
              <Material
                negocioGerado={propostaNegocioVersao.NEGO_NUMERO_NEGOCIO}
                numeroNegocio={numeroNegocio}
                propostaVersaoAtividade={item}
              />
            </>
          ) : (
            <></>
          )}
        </Collapse>
      </div>
    );
  }

  return (
    <>
      <div className="ml-5">
        {isLoading ? (
          <>
            <FaSpinner className="spinner" /> Carregando...
          </>
        ) : (
          propostaNegocioVersaoAtividade.map(item => renderLista(item))
        )}
      </div>
    </>
  );
}

export default memo(Atividade);
