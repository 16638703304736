import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { CircularProgress, TextField, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';

import UserSmallHeader from 'components/Headers/UserSmallHeader';
import api from 'services/api';
import Proposta from 'models/Proposta';
import AutocompletePessoa from 'components/AutcompletePessoa';
import Pessoa from 'models/Pessoa';
import { FaEraser, FaCopy, FaInfoCircle } from 'react-icons/fa';
import { PropostaVersao } from 'models/PropostaVersao';
import { PropostaStatus } from 'models/PropostaStatus';

export default function Propostas(): JSX.Element {
  const history = useHistory();
  const [propostas, setPropostas] = useState<Proposta[]>([]);

  const [codigoOportunidade, setCodigoOportunidade] = useState('');
  const [pessoaSelecionada, setPessoaSelecionada] = useState<Pessoa | null>(
    null,
  );
  const [listaStatus, setListaStatus] = useState<PropostaStatus[]>([]);

  const [statusIdFiltrar, setStatusIdFiltrar] = useState<number>(0);

  const { data: queryListaStatus } = useQuery(
    `proposta-status`,
    async () => {
      async function getStatus(): Promise<PropostaStatus[]> {
        const response = await api.get('/proposta/status');
        return response.data;
      }

      const dataStatus = await getStatus();
      return dataStatus;
    },
    { staleTime: 1000 * 60 * 10 },
  );

  const { data: dataPropostas, isLoading } = useQuery(
    'lista-propostas',
    async () => {
      async function carregaPropostas(): Promise<Proposta[]> {
        const response = await api.get('/proposta');
        return response.data;
      }

      const data = await carregaPropostas();
      return data;
    },
  );

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (!dataPropostas?.length) {
      return;
    }
    setPropostas(dataPropostas);
  }, [dataPropostas]);

  useEffect(() => {
    if (queryListaStatus) {
      setListaStatus(queryListaStatus);
    }
  }, [queryListaStatus]);

  function propostaGnf(proposta: Proposta): boolean {
    if (!codigoOportunidade) {
      return true; // retorna todas
    }
    return proposta.codigoOportunidadeDomsge === codigoOportunidade;
  }

  function propostaCliente(proposta: Proposta): boolean {
    if (!pessoaSelecionada?.id) {
      return true; // retorna todas
    }
    return (
      proposta.pessoa?.id === pessoaSelecionada.id ||
      proposta.pessoaGnf?.id === pessoaSelecionada.id
    );
  }

  function propostaVersaoStatus(propostaVersao: PropostaVersao): boolean {
    if (!statusIdFiltrar || statusIdFiltrar === 0) {
      return true; // retorna todas
    }

    return propostaVersao.status.id === statusIdFiltrar;
  }

  function propostaStatus(proposta: Proposta): boolean {
    if (!statusIdFiltrar || statusIdFiltrar === 0) {
      return true; // retorna todas
    }

    return proposta.PropostaVersao.some(
      item => item.status.id === statusIdFiltrar,
    );
  }

  const propostasFiltradas = propostas
    .filter(propostaGnf)
    .filter(propostaCliente)
    .filter(propostaStatus);

  function limpaFiltros() {
    setCodigoOportunidade('');
    setPessoaSelecionada(null);
    setStatusIdFiltrar(0);
  }

  function formataMoeda(valor?: number | null) {
    if (valor === null || valor === undefined) {
      return '';
    }
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor || 0);
  }

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  async function duplicarRegistro(
    proposta: Proposta,
    registro: PropostaVersao,
  ) {
    async function duplicar(): Promise<any> {
      setSwal('Duplicando versão da proposta...');
      const retorno = await api.post(
        `/proposta/versao/${registro.id}/duplicar`,
        {},
      );
      return retorno.data;
    }

    async function calculaPrecificacao(
      orcamentoId: number,
      orcamentoParteId: number,
    ): Promise<void> {
      Swal.fire({
        icon: 'info',
        text: `Calculando precificação da configuração #${orcamentoId}...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      await api.post('/integracao/precificacao/calculo', {
        orcamentoId,
        orcamentoParteId,
      });
    }

    async function reprocessarVersao(versaoId: number): Promise<any> {
      setSwal('Reprocessando e recalculando versão da proposta');
      await api.post(`/proposta/versao/${versaoId}/reprocessar`, {
        frete: true,
        servico: true,
        itens: true,
        itensAvulsos: true,
      });
    }

    async function executarProcessoDuplicacao() {
      const retorno = await duplicar();

      // eslint-disable-next-line no-restricted-syntax
      for await (const configuracao of retorno.PropostaConfiguracao) {
        const orcamentoParteId =
          configuracao.orcamento?.OrcamentoVersao?.[0]?.OrcamentoProduto?.[0]
            ?.OrcamentoParte?.[0]?.id;

        if (orcamentoParteId)
          await calculaPrecificacao(configuracao.orcamentoId, orcamentoParteId);
      }

      await reprocessarVersao(retorno.id);

      setSwal();

      toast.success('Registro duplicado. Navegando para ele...');

      // Tudo OK? Então, dispara um ok e abre o registro
      history.push({
        pathname: '/admin/proposta',
        state: { proposta, propostaVersao: retorno },
      });
    }

    Swal.fire({
      title: `Deseja duplicar as informações da versão ${registro.versao} em uma nova versão da proposta #${proposta.id}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, duplicar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await executarProcessoDuplicacao();
      }
    });
  }

  return (
    <>
      <UserSmallHeader />
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">Propostas</h5>
        </CardHeader>

        <Card className="shadow pt-3 pb-2 px-2 mt-1 mb-0 mx-1">
          <Row>
            <Col sm="4" className="mb-3">
              <TextField
                className="w-100"
                size="small"
                type="text"
                label="GNF"
                InputLabelProps={{ style: { fontSize: 13 } }}
                variant="outlined"
                value={codigoOportunidade}
                onChange={event => setCodigoOportunidade(event.target.value)}
              />
            </Col>
            <Col sm="5" className="mb-2">
              <AutocompletePessoa
                onChange={(_, newValue) =>
                  setPessoaSelecionada(newValue ?? ({} as Pessoa))
                }
                pessoaSelecionada={pessoaSelecionada}
              />
            </Col>
            <Col sm="2" className="mb-2">
              <Input
                type="select"
                value={statusIdFiltrar}
                onChange={event =>
                  setStatusIdFiltrar(Number(event.target.value))
                }
              >
                <option value="0">Todos os Status...</option>
                {listaStatus.map(status => (
                  <option key={status.id} value={status.id}>
                    {status.descricao}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm="1">
              <Button
                className="btn-icon btn-2 float-right"
                color="primary"
                type="button"
                outline
                onClick={limpaFiltros}
              >
                <FaEraser />
              </Button>
            </Col>
          </Row>
        </Card>

        <CardBody className="p-0 pr-1 pl-1" style={{ background: '#ebf3fa' }}>
          {isLoading ? (
            <Row>
              <Col className="text-center mb-4 mt-4">
                <CircularProgress />
              </Col>
            </Row>
          ) : (
            <ListGroup flush>
              {propostasFiltradas.length ? (
                propostasFiltradas.map(proposta => {
                  const {
                    id,
                    createdAt,
                    codigoOportunidadeDomsge,
                    PropostaVersao: propostaVersoes,
                    pessoa,
                    pessoaGnf,
                  } = proposta;
                  const versoesStatus = propostaVersoes.filter(
                    prop => prop.status.id === 3,
                  );
                  let temPropostaAprovada = false;
                  if (versoesStatus.length > 0) {
                    temPropostaAprovada = true;
                  }

                  const temClientesDiferentes =
                    (pessoa?.id ?? 0) !== (pessoaGnf?.id ?? 0);

                  return (
                    <ListGroupItem
                      key={id}
                      className="flex-column align-items-start mt-1 mb-1 pt-3 pb-0"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex w-100 align-items-center m-0">
                          <Button color="default" size="sm">
                            {id}
                          </Button>
                          <h5 className="mt-2">
                            {pessoaGnf
                              ? `${pessoaGnf.codigoDomsge} - ${pessoaGnf.razaoSocial}`
                              : ''}
                          </h5>
                        </div>
                        <small className="mt-2">
                          {new Intl.DateTimeFormat('pt-BR').format(
                            new Date(createdAt),
                          )}
                        </small>
                      </div>
                      <h6 className="mt-2" hidden={!temClientesDiferentes}>
                        {pessoa
                          ? `${pessoa.codigoDomsge} - ${pessoa.razaoSocial}`
                          : ''}
                      </h6>{' '}
                      <h6 className="b-2">
                        {codigoOportunidadeDomsge
                          ? `GNF ${codigoOportunidadeDomsge}`
                          : ''}
                      </h6>
                      <CardHeader className="p-0 pt-2 pb-1">
                        <Row>
                          <Col xs="6">
                            <h5 className="text-muted mb-0">Versões</h5>
                          </Col>
                        </Row>
                      </CardHeader>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                        striped
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>#</th>
                            <th>Status</th>
                            <th>Valor frete</th>
                            <th>Valor itens</th>
                            <th>Valor serviço</th>
                            <th>Valor final</th>
                            <th>Gerada em</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {propostaVersoes
                            .filter(propostaVersaoStatus)
                            .map(propostaVersao => {
                              return (
                                <tr key={propostaVersao.id}>
                                  <td className="py-2">
                                    <Button
                                      className="m-0"
                                      color="primary"
                                      type="button"
                                      size="sm"
                                      outline
                                      onClick={() => {
                                        history.push({
                                          pathname: '/admin/proposta',
                                          state: { proposta, propostaVersao },
                                        });
                                      }}
                                    >
                                      <small>{propostaVersao.versao}</small>
                                    </Button>
                                  </td>
                                  <td className="py-2">
                                    <Tooltip
                                      title={String(
                                        propostaVersao.observacao ?? '-',
                                      )}
                                    >
                                      <Button size="sm" color="info" outline>
                                        <FaInfoCircle />
                                      </Button>
                                    </Tooltip>
                                    &nbsp;&nbsp;
                                    {propostaVersao.status.descricao}
                                  </td>
                                  <td className="py-2">
                                    {formataMoeda(propostaVersao.valorFrete)}
                                  </td>
                                  <td className="py-2">
                                    {formataMoeda(propostaVersao.valorItens)}
                                  </td>
                                  <td className="py-2">
                                    {formataMoeda(propostaVersao.valorServico)}
                                  </td>
                                  <td className="py-2">
                                    {formataMoeda(propostaVersao.valorFinal)}
                                  </td>
                                  <td className="py-2">
                                    {new Intl.DateTimeFormat('pt-BR').format(
                                      new Date(propostaVersao.createdAt),
                                    )}
                                  </td>
                                  {!temPropostaAprovada && (
                                    <td className="py-2">
                                      <Button
                                        className="btn-icon btn-2"
                                        type="button"
                                        title="Duplicar registro em nova versão da proposta"
                                        onClick={() =>
                                          duplicarRegistro(
                                            proposta,
                                            propostaVersao,
                                          )
                                        }
                                        size="sm"
                                      >
                                        <FaCopy />
                                      </Button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </ListGroupItem>
                  );
                })
              ) : (
                <Card className="shadow pt-3 pb-2 mt-1">
                  <h4 className="text-center">Nenhuma proposta encontrada.</h4>
                </Card>
              )}
            </ListGroup>
          )}
        </CardBody>
      </Card>
    </>
  );
}
