import { useState, useEffect } from 'react';

import Questao from 'models/Questao';

import QuestaoValorSelecao from 'models/QuestaoValorSelecao';

import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import {
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
} from '@material-ui/core';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import api from 'services/api';

import toast from 'react-hot-toast';
import { FaCheckCircle } from 'react-icons/fa';

import DefaultImage from 'assets/img/default.jpg';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ModalUploadImagem from 'components/ModalUploadImagem';
import Compress from 'compress.js';
import { GridDiv } from './styles';

interface Props {
  valoresSelecao: QuestaoValorSelecao[];
  listaValoresSelecao: QuestaoValorSelecao[];
  visaoEmLinhaDefault?: boolean;
  readOnly?: boolean;
  handleChangeValorQuestionario: (valor: string | undefined) => void;
  respostaImagemSelecionada?: QuestaoValorSelecao[];
  requiredImage?: boolean;
  handleChangeImageValue: (valor: string | undefined) => void;
  handleSetaIdImage: (valor: number) => void;
}

function ImagemMultiSelecao(props: Props): JSX.Element {
  const {
    valoresSelecao,
    listaValoresSelecao,
    visaoEmLinhaDefault = false,
    readOnly = false,
    handleChangeValorQuestionario,
    respostaImagemSelecionada,
    requiredImage = false,
    handleChangeImageValue,
    handleSetaIdImage,
  } = props;

  const [valoresSelecaoState, setValoresSelecaoState] = useState<
    QuestaoValorSelecao[]
  >([]);
  const [valorSelecionado, setValorSelecionado] =
    useState<QuestaoValorSelecao[]>();

  const [exibirModalImagem, setExibirModalImagem] = useState(false);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    checkedVisaoLinha: visaoEmLinhaDefault,
  });
  const { width } = useWindowDimensions();
  const numeroColunasImagens = Math.trunc(width / 300);
  const defaultIconColor = '#b7ada4';
  const selectedIconColor = '#1ab394';
  const [idImagemSelecionada, setIdImagemSelecionada] = useState(0);

  useEffect(() => {
    if (respostaImagemSelecionada && respostaImagemSelecionada.length > 0) {
      setValorSelecionado(respostaImagemSelecionada);
      const idImagens = listaValoresSelecao.find(
        item =>
          !respostaImagemSelecionada.find(
            it => it.questaoId === item.questaoId,
          ),
      )?.questaoId;
      setIdImagemSelecionada(Number(idImagens));
    }
    if (respostaImagemSelecionada === undefined && requiredImage) {
      handleChangeImageValue('Empty');
      handleSetaIdImage(idImagemSelecionada);
      return;
    }
    handleChangeImageValue('Selected');
  }, [respostaImagemSelecionada]);

  useEffect(() => {
    if (valoresSelecao) {
      setValoresSelecaoState(valoresSelecao);
    }
  }, [valoresSelecao]);

  useEffect(() => {
    if (valorSelecionado && !readOnly) {
      setExibirModalImagem(true);
    }
  }, [valorSelecionado]);

  useEffect(() => {
    if (loading) {
      toast.loading('Aguarde...');
      return;
    }

    toast.dismiss();
  }, [loading]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  function handleSelectImagemQuestionario(valor: QuestaoValorSelecao) {
    // eslint-disable-next-line no-nested-ternary
    const novaLista = valorSelecionado?.find(it => it.id === valor.id)
      ? valorSelecionado?.filter(it => it.id !== valor.id)
      : valorSelecionado
      ? [...valorSelecionado, valor]
      : [valor];

    handleChangeValorQuestionario(novaLista.map(it => it.descricao)?.join(','));
    setValorSelecionado(novaLista);
  }

  if (!valoresSelecao.length) {
    return <></>;
  }

  return (
    <>
      <Card className="bg-secondary shadow mt-3">
        {!readOnly && (
          <CardHeader className="bg-white border-0">
            <Row>
              <Col />

              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={state.checkedVisaoLinha}
                    onChange={handleChange}
                    name="checkedVisaoLinha"
                    color="primary"
                  />
                }
                label="Visão em linha"
                className="text-muted"
              />
            </Row>
          </CardHeader>
        )}
        <CardBody>
          <GridDiv>
            <ImageList
              cols={numeroColunasImagens}
              style={{
                flexWrap: state.checkedVisaoLinha ? 'nowrap' : 'wrap',
                transform: 'translateZ(0)',
              }}
            >
              {valoresSelecaoState.map(valor => {
                const labelImagem = `Informação sobre valor ${valor.descricao}`;
                const handleOnClickImagem = () =>
                  handleSelectImagemQuestionario(valor);

                const actionButton = readOnly ? (
                  <IconButton
                    aria-label={labelImagem}
                    style={{
                      color: valorSelecionado?.find(it => it.id === valor.id)
                        ? selectedIconColor
                        : defaultIconColor,
                    }}
                  >
                    <FaCheckCircle />
                  </IconButton>
                ) : (
                  <></>
                );

                return (
                  <ImageListItem
                    key={valor.id}
                    style={{
                      height: '180px',
                      width: '233.5px',
                    }}
                    onClick={() => handleOnClickImagem()}
                  >
                    <img
                      src={
                        valor.imagem
                          ? `data:image/jpeg;base64,${valor.imagem}`
                          : DefaultImage
                      }
                      style={{
                        cursor: 'pointer',
                        height: '100%',
                        width: '100%',
                      }}
                      alt={valor.descricao}
                    />
                    <ImageListItemBar
                      title={<span>{valor.descricao}</span>}
                      subtitle=""
                      actionIcon={actionButton}
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          </GridDiv>
        </CardBody>
      </Card>
    </>
  );
}

export default ImagemMultiSelecao;
