export default function formatNumeros(
  valor: number | null | undefined,
): string {
  if (valor === null || valor === undefined) {
    return '';
  }

  const [parteInteira, parteDecimal] = String(valor).split('.');

  let numStr = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (parteDecimal) {
    numStr += `,${parteDecimal.padEnd(2, '0')}`;
  }
  return numStr;
}
