import styled from 'styled-components';

export const Separador = styled.div`
  margin-top: 1.5rem;
`;

export const SinalizacaoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const Th = styled.th`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e9ecef;
`;

export const Td = styled.td`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;

export const ColLabel = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  vertical-align: middle;
  padding-top: 0.6rem;
`;
