import { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  ButtonGroup,
  Modal,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Alert,
} from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { LabelStatusOrcamento } from 'views/pages/css/index';
import { Td } from 'views/pages/ConfiguracaoListaGNF/styles';

import { Link, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Orcamento from 'models/Orcamento';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import api from 'services/api';
import FichaTecnicaType from 'models/FichaTecnica';
import Swal from 'sweetalert2';
import OrcamentoParte from 'models/OrcamentoParte';
import ParametrosModel from 'models/Parametros';
import ProdutoModel from 'models/Produto';
import { read, utils, writeFile } from 'xlsx';
import BotoesProposta from 'components/BotoesProposta';
import Proposta from 'models/Proposta';
import { PropostaVersao } from 'models/PropostaVersao';

import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';

import toast from 'react-hot-toast';
import DadosInformados from './components/organisms/DadosInformados';
import DadosCalculados from './components/organisms/DadosCalculados';
import DadosInformadosFotovoltaica from './components/organisms/DadosInformadosFotovoltaica';
import SimulacaoViabilidadeFotovoltaica from './components/organisms/SimulacaoViabilidadeFotovoltaica';

type LocationState = {
  fichaTecnica: FichaTecnicaType;
  orcamento: Orcamento;
  orcamentoParteId: number;
  orcamentoParte: OrcamentoParte;
};

interface Props {
  onSave?: any;
  acessoLimitado: boolean | false;
  triggerStart: boolean | false;
  sessaoId?: string | null;
  fichaTecnicaId?: number | null;
  configuracaoId: number;
  orcamentoParteId: number;
  setSessaoId?: any;
  setFichaTecnicaId?: any;
  fichaTecnicaTemp: FichaTecnicaType;
  orcamento: Orcamento;
}

export default function Resultado(props: Props): JSX.Element {
  const {
    onSave,
    acessoLimitado,
    triggerStart,
    orcamentoParteId,
    configuracaoId,
    sessaoId,
    fichaTecnicaId,
    setFichaTecnicaId,
    setSessaoId,
    fichaTecnicaTemp,
    orcamento,
  } = props;

  const history = useHistory();

  const [fichaTecnica, setFichaTecnica] =
    useState<FichaTecnicaType>(fichaTecnicaTemp);
  const [carregandoFichaTecnica, setCarregandoFichaTecnica] = useState(false);

  const [isFotovoltaica, setIsFotovoltaica] = useState(false);
  const [isIrrigacao, setisIrrigacao] = useState(false);
  const [acessoLimitadoPrecificacao, setAcessoLimitadoPrecificacao] = useState(
    {} as ValidarAcessoPrograma,
  );
  const [acessoLimitadoMateriais, setAcessoLimitadoMateriais] = useState(
    {} as ValidarAcessoPrograma,
  );
  const [acessoLimitadoPerdaCarga, setAcessoLimitadoPerdaCarga] = useState(
    {} as ValidarAcessoPrograma,
  );
  const [configuracoesMarcadas, setConfiguracoesMarcadas] = useState<number[]>(
    [],
  );
  const [modalInclusaoEmProposta, setModalInclusaoEmProposta] = useState(false);
  const [propostas, setPropostas] = useState<Proposta[]>([]);
  const [propostaSelecionadaId, setPropostaSelecionadaId] =
    useState<string>('');
  const [propostaVersaoSelecionadaId, setPropostaVersaoSelecionadaId] =
    useState<string>('');
  const [propostaVersoes, setPropostaVersoes] = useState({} as PropostaVersao);
  const [propostaConfiguracao, setPropostaConfiguracao] = useState(
    {} as PropostaConfiguracao,
  );
  const [propostasVersoesEmAberto, setPropostasVersoesEmAberto] = useState<
    PropostaVersao[]
  >([]);
  const [propostaId, setPropostaId] = useState<number>(0);
  const [propostaVersaoId, setPropostaVersaoId] = useState<number>(0);
  const [propostaVersaoNumero, setPropostaVersaoNumero] = useState<number>(0);
  const [propostaStatusName, setPropostaStatusName] = useState('');
  const [isPropostaBloqueada, setIsPropostaBloqueada] = useState(false);

  const [isConfiguracaoBloqueada, setIsConfiguracaoBloqueada] = useState(false);
  const [acessoDesbloquear, setAcessoDesbloquear] = useState(false);

  const [acessoDesbloquearConfiguracao, setAcessoDesbloquearConfiguracao] =
    useState({} as ValidarAcessoPrograma);

  async function carregaFichaTecnica() {
    try {
      setCarregandoFichaTecnica(true);
      const response = await api.get(`/ficha-tecnica/${fichaTecnicaId}`);
      setFichaTecnica(response.data);
    } finally {
      setCarregandoFichaTecnica(false);
    }
  }

  const { data: dataPropostas } = useQuery('lista-propostas', async () => {
    async function getPropostas(): Promise<Proposta[]> {
      const response = await api.get('/proposta', {
        params: {
          status: 1,
        },
      });
      return response.data;
    }

    const data = await getPropostas();
    return data;
  });

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 26,
      },
    });
    setAcessoLimitadoPrecificacao(response.data);

    const response2 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 55,
      },
    });
    setAcessoLimitadoMateriais(response2.data);

    const response3 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 58,
      },
    });
    setAcessoLimitadoPerdaCarga(response3.data);

    const response4 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 73,
      },
    });
    setAcessoDesbloquearConfiguracao(response4.data);
  }

  useEffect(() => {
    // Comando para fazer um scroll to top ao acessar a aba de Adutora.
    window.scrollTo(0, 0);
    // document.documentElement.scrollTop = 0;
  }, []);

  async function carregaPropostaConfiguracao() {
    if (orcamento?.id) {
      const response = await api.get(
        `/proposta/proposta-configuracao/${orcamento?.id}`,
      );
      setPropostaVersoes(response.data);
      setPropostaConfiguracao(response.data[0]);

      if (response.data[0]?.propostaVersaoId) {
        const resposta = await api.get(
          `/proposta/versao/${response.data[0]?.propostaVersaoId}`,
        );

        setIsPropostaBloqueada(
          resposta.data.status.indicPermiteModificar === false ?? false,
        );
        setPropostaVersoes(resposta.data);

        setPropostaId(resposta.data.propostaId ?? 0);
        setPropostaVersaoId(resposta.data.id);
        setPropostaStatusName(resposta.data.status.descricao);
        setPropostaVersaoNumero(resposta.data.versao);
      }
    }
  }

  async function getConfiguracaoBloqueada() {
    if (orcamento?.id) {
      const response = await api.get(
        `/orcamento/versao/${orcamento?.id}/bloqueio`,
      );
      setIsConfiguracaoBloqueada(response.data?.bloqueado ?? false);
    }
  }

  async function handleInclusaoEmProposta() {
    async function incluiEmProposta(): Promise<void> {
      Swal.fire({
        icon: 'info',
        text: `Incluindo configuração(ões) na proposta ${propostaSelecionadaId}...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      await api.post('/proposta/configuracao', {
        propostaVersaoId: Number(propostaVersaoSelecionadaId),
        configuracoes: configuracoesMarcadas,
      });
      Swal.close();
    }

    async function buscaProposta() {
      const response = await api.get(`/proposta/${propostaSelecionadaId}`);
      return response.data;
    }

    await incluiEmProposta();
    setConfiguracoesMarcadas([]);
    setModalInclusaoEmProposta(false);

    const proposta = await buscaProposta();
    const propostaVersaoSelecionada = propostasVersoesEmAberto.find(
      propostaVersao =>
        propostaVersao.id === Number(propostaVersaoSelecionadaId),
    );

    Swal.fire({
      title: `Navegar para proposta?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, navegar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Fechar`,
    }).then(async result => {
      if (result.isConfirmed) {
        history.push({
          pathname: '/admin/proposta',
          state: { proposta, propostaVersao: propostaVersaoSelecionada },
        });
      }
    });
  }

  async function handleAskNovaProposta() {
    async function geraNovaProposta(): Promise<Proposta> {
      Swal.fire({
        icon: 'info',
        text: 'Gerando proposta...',
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const response = await api.post('/proposta', {
        codigoGNF: orcamento?.codigoOportunidadeDomsge,
        configuracoes: configuracoesMarcadas,
      });
      Swal.close();
      return response.data;
    }

    if (!orcamento?.codigoOportunidadeDomsge) {
      Swal.fire({
        icon: 'error',
        text: `Sem GNF Base!`,
      });
      return;
    }

    Swal.fire({
      title: `Gerar proposta para a configuração ${configuracoesMarcadas}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        const proposta = await geraNovaProposta();

        history.push({
          pathname: '/admin/proposta',
          state: { proposta, propostaVersao: proposta.PropostaVersao[0] },
        });
      }
    });
  }
  async function navegaProposta(idProposta: any) {
    async function buscaProposta() {
      const response = await api.get(`/proposta/${idProposta}`);
      return response.data;
    }

    const proposta = await buscaProposta();
    const propostaVersaoSelecionada = propostasVersoesEmAberto.find(
      propostaVersao =>
        propostaVersao.id === Number(propostaVersaoSelecionadaId),
    );

    const propostaFiltrada = propostas.filter(prop => prop.id === propostaId);
    const propostaByOrcamento = propostaFiltrada.find(
      propost => propost.id === Number(propostaId),
    );
    let propostaOrcamento: PropostaVersao[] = [];
    if (propostaByOrcamento) {
      propostaOrcamento = propostaByOrcamento?.PropostaVersao.filter(
        propostaVersao => propostaVersao.status.id === 1, // status id 1: Gerada
      );
    }

    Swal.fire({
      title: `Navegar para proposta?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, navegar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Fechar`,
    }).then(async result => {
      if (result.isConfirmed) {
        history.push({
          pathname: '/admin/proposta',
          state: { proposta, propostaVersao: propostaVersoes },
        });
      }
    });
  }

  async function handleDesbloquearConfiguracao() {
    const resultado = await Swal.fire({
      title: `Deseja desbloquear a configuração? Caso exista uma proposta com essa configuração, ela voltará estar liberada para uso.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, desbloquear`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Fechar`,
    });

    if (resultado.isConfirmed) {
      try {
        toast.loading('Salvando...');
        await api.put(`/orcamento/versao/${orcamento?.id}/bloqueio`, {
          bloquear: false,
        });
      } finally {
        await getConfiguracaoBloqueada();
        toast.dismiss();
      }
    }
  }

  useEffect(() => {
    if (orcamento.id) {
      sessionStorage.setItem('NroConfigStorage', String(orcamento.id));
    }
  }, [orcamento.id]);

  useEffect(() => {
    getAcessoLimitadoPrograma();
    getConfiguracaoBloqueada();
  }, []);

  useEffect(() => {
    if (!dataPropostas?.length) {
      return;
    }
    setPropostas(dataPropostas);
  }, [dataPropostas]);

  useEffect(() => {
    if (fichaTecnicaId) carregaFichaTecnica();
  }, [fichaTecnicaId]);

  useEffect(() => {
    if (
      acessoDesbloquearConfiguracao &&
      acessoDesbloquearConfiguracao.temAcesso === true
    )
      setAcessoDesbloquear(true);
  }, [acessoDesbloquearConfiguracao]);

  useEffect(() => {
    carregaPropostaConfiguracao();
  }, []);

  useEffect(() => {
    if (configuracaoId) {
      setConfiguracoesMarcadas([...configuracoesMarcadas, configuracaoId]);
    }
  }, []);

  useEffect(() => {
    setPropostasVersoesEmAberto([]);
    const proposta = propostas.find(
      propost => propost.id === Number(propostaSelecionadaId),
    );
    if (proposta) {
      setPropostasVersoesEmAberto(
        proposta?.PropostaVersao.filter(
          propostaVersao => propostaVersao.status.id === 1, // status id 1: Gerada
        ),
      );
    }
  }, [propostaSelecionadaId]);

  useEffect(() => {
    if (fichaTecnica?.id) {
      if (
        fichaTecnica.FichaTecnicaLanceDetalhes &&
        fichaTecnica.FichaTecnicaLanceDetalhes.length > 0
      ) {
        setIsFotovoltaica(false);
        setisIrrigacao(true);
      } else if (
        fichaTecnica.FichaTecnicaFotovoltaicaGeral &&
        fichaTecnica.FichaTecnicaFotovoltaicaGeral.length > 0
      ) {
        setIsFotovoltaica(true);
        setisIrrigacao(false);
      } else {
        setIsFotovoltaica(false);
        setisIrrigacao(false);
      }
    }
  }, [fichaTecnica]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={modalInclusaoEmProposta}
        toggle={() => setModalInclusaoEmProposta(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center">
                <strong>
                  Incluir em proposta existente - GNF{' '}
                  {orcamento?.codigoOportunidadeDomsge}
                </strong>
              </div>
              <div className="btn-wrapper text-center" />
            </CardHeader>
            <CardBody className="px-lg-5 ">
              <Form role="form">
                <Input
                  type="select"
                  value={propostaSelecionadaId}
                  onChange={event =>
                    setPropostaSelecionadaId(event.target.value)
                  }
                >
                  <option value="">Selecione a proposta...</option>
                  {propostas.map(proposta => (
                    <option key={proposta.id} value={proposta.id}>
                      Proposta {proposta.id}
                    </option>
                  ))}
                </Input>
                {propostaSelecionadaId && (
                  <div className="mt-3">
                    <Input
                      type="select"
                      value={propostaVersaoSelecionadaId}
                      onChange={event =>
                        setPropostaVersaoSelecionadaId(event.target.value)
                      }
                    >
                      <option value="">Selecione a versão...</option>
                      {propostasVersoesEmAberto?.map(propostaVersao => (
                        <option
                          key={propostaVersao.id}
                          value={propostaVersao.id}
                        >
                          Versão {propostaVersao.versao}
                        </option>
                      ))}
                    </Input>
                  </div>
                )}
                <div className="text-center">
                  <Button
                    className="my-4 w-100"
                    color="primary"
                    type="button"
                    onClick={handleInclusaoEmProposta}
                  >
                    Incluir
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {isConfiguracaoBloqueada === true && (
        <>
          <Row className="mt-2" style={{ width: '100%' }}>
            <Col sm="12" className="mt-2">
              <Alert color="info" style={{ width: '100%', color: '#000000' }}>
                Validação de materiais encontrou agrupadores obrigatórios sem
                item. Caso a configuração esteja associada a uma proposta, a
                mesma ficará bloqueada até que a configuração seja corrigida
                {acessoDesbloquear === true && (
                  <>
                    <div className="float-right">
                      <Button
                        className="btn-icon btn-2"
                        color="primary"
                        type="button"
                        size="sm"
                        onClick={() => {
                          handleDesbloquearConfiguracao();
                        }}
                      >
                        Desbloquear Configuração
                      </Button>
                    </div>
                  </>
                )}
              </Alert>
            </Col>
          </Row>
        </>
      )}
      <Row className="mt-2" style={{ width: '100%' }}>
        <Col>
          {propostaId === 0 || propostaId === null ? (
            <>
              <ButtonGroup className="float-left pt-2 mr-3" role="group">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  size="sm"
                  onClick={() => {
                    setModalInclusaoEmProposta(true);
                  }}
                >
                  Incluir em proposta <FaPlusCircle />
                </Button>
              </ButtonGroup>
              <ButtonGroup className="float-left pt-2 mr-3" role="group">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  size="sm"
                  onClick={() => {
                    handleAskNovaProposta();
                  }}
                >
                  Nova proposta <FaPlusCircle />
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <></>
          )}
          <ButtonGroup className="float-right pt-2" role="group">
            <Button
              className="btn-icon btn-2"
              color="primary"
              type="button"
              outline
              size="sm"
              hidden={!acessoLimitadoPrecificacao.temAcesso}
              onClick={() => {
                history.push({
                  pathname: '/admin/orcamento-precificacao-novo',
                  state: {
                    fichaTecnica,
                    orcamentoId: configuracaoId,
                    orcamentoParteId,
                    returnToResultado: true,
                  },
                });
              }}
            >
              Precificação
            </Button>
          </ButtonGroup>

          <ButtonGroup className="float-right pt-2 mr-3" role="group">
            <Button
              className="btn-icon btn-2"
              color="primary"
              type="button"
              outline
              size="sm"
              hidden={!acessoLimitadoMateriais.temAcesso}
              onClick={() => {
                history.push({
                  pathname: '/admin/orcamento-materiais',
                  state: {
                    fichaTecnica,
                    orcamentoId: configuracaoId,
                    orcamentoParteId,
                    returnToResultado: true,
                    acessoLimitado: acessoLimitadoMateriais.acessoLimitado,
                  },
                });
              }}
            >
              MateriaisEng
            </Button>
          </ButtonGroup>

          <ButtonGroup
            className="float-right pt-2 mr-3"
            role="group"
            hidden={!isIrrigacao}
          >
            <Button
              className="btn-icon btn-2"
              color="primary"
              type="button"
              outline
              size="sm"
              onClick={() => {
                history.push({
                  pathname: '/admin/orcamento-impressao',
                  state: {
                    orcamentoVersaoId: configuracaoId,
                    tipoProduto: isIrrigacao ? 'Irrigacao' : 'Fotovoltaica',
                    fichaTecnica,
                    orcamentoId: configuracaoId,
                    returnToResultado: true,
                  },
                });
              }}
            >
              Impressão
            </Button>
          </ButtonGroup>
          {propostaId !== 0 ? (
            <ButtonGroup className="float-right pt-2 mr-3" role="group">
              <Button
                className="btn-icon btn-2"
                color={isPropostaBloqueada ? 'warning' : 'primary'}
                type="button"
                size="sm"
                onClick={() => {
                  navegaProposta(propostaId);
                }}
              >
                {propostaVersaoNumero
                  ? `${propostaId} | Versão ${propostaVersaoNumero}: `
                  : ''}
                {propostaStatusName}
              </Button>
            </ButtonGroup>
          ) : (
            <></>
          )}

          {/* Aqui estarão os botoões */}
        </Col>
      </Row>

      <Row className="mt-2" style={{ width: '100%' }}>
        {isIrrigacao && (
          <>
            <DadosInformados fichaTecnica={fichaTecnica} />
            <DadosCalculados
              carregandoFichaTecnica={carregandoFichaTecnica}
              fichaTecnica={fichaTecnica}
              orcamento={orcamento}
              acessoLimitadoPerdaCarga={acessoLimitadoPerdaCarga}
            />
          </>
        )}
        {isFotovoltaica && (
          <>
            <DadosInformadosFotovoltaica
              fichaTecnica={fichaTecnica}
              orcamento={orcamento}
              orcamentoId={orcamento.id}
            />
          </>
        )}
        {!isFotovoltaica && !isIrrigacao && (
          <>
            <Col sm="12" className="mt-2">
              <Alert color="info" style={{ width: '100%', color: '#000000' }}>
                <strong>Etapas anteriores concluídas com sucesso. </strong>
                Utilize os botões acima para visualizar os materiais gerados.
              </Alert>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col sm="12" className="mt-2">
          <small>Código interno da FT: {fichaTecnicaId}</small>
        </Col>
      </Row>
    </>
  );
}
