import Swal from 'sweetalert2';
import './style.css';

const propColor = {
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
};

const props = {
  toast: true,
  showConfirmButton: false,
  // ...propColor,
};

const Toast = Swal.mixin({
  ...props,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

const ToastAsync = Swal.mixin({
  ...props,
  position: 'top-end',
});

export { Toast, ToastAsync };
