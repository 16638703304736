import UserSmallHeader from 'components/Headers/UserSmallHeader';

import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import formatCurrency from 'utils/formatCurrency';
import api from 'services/api';
import { useQuery } from 'react-query';

import { Col, Row, Table } from 'reactstrap';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import { CircularProgress } from '@material-ui/core';
import config from 'config';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';

import { ProdutoProposta } from 'models/ProdutoProposta';
import ConfiguradorMatrizResponsabilidade from 'models/ConfiguradorMatrizResponsabilidade';
import LogoImg from '../assets/img/logo_fck.png';
import './style.css';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  produtoProposta: ProdutoProposta;
}

interface PropostaImpressaoContext {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  isPropostaExterior: boolean;
  setIsPropostaExterior: (valor: boolean) => void;
  valorDolar: number;
  setValorDolar: (valor: number) => void;
  parcelas: PropostaVersaoParcelas[];
}

export const PropostaImpressaoContext = createContext(
  {} as PropostaImpressaoContext,
);

interface iParamsLista {
  label: string;
  valor: any;
}

export default function MatrizResponsabilidade({
  valores,
}: {
  valores: iParamsLista[];
}): JSX.Element {
  const location = useLocation<LocationState>();
  const { proposta, propostaVersao, produtoProposta } = location.state;

  const propostaVersaoId = propostaVersao.id;

  const [listaMatrizResponsabilidade, setListaMatrizResponsabilidade] =
    useState<ConfiguradorMatrizResponsabilidade[]>([]);

  const [listaTiposMatriz, setListaTiposMatriz] = useState(
    [] as { sigla: string; valor: string }[],
  );

  const [respostasMatrizResponsabilidade, setRespostasMatrizResponsabilidade] =
    useState<any[]>([]);

  async function buscarTipoMatriz() {
    const response = await api.get('/matriz-responsabilidade/tipos');

    setListaTiposMatriz(response.data);
  }

  async function carregarListaMatriz() {
    const response = await api.get('/matriz-responsabilidade');
    setListaMatrizResponsabilidade(response.data);
  }

  async function carregarRespostas() {
    const response = await api.get(
      `/proposta/versao/${propostaVersaoId}/matriz`,
    );

    setRespostasMatrizResponsabilidade(response.data.respostas);
  }

  useEffect(() => {
    buscarTipoMatriz();
    carregarListaMatriz();
  }, []);

  useEffect(() => {
    if (propostaVersaoId && propostaVersao) {
      carregarRespostas();
    }
  }, [propostaVersao, propostaVersaoId]);

  function getRowMatriz(matriz: ConfiguradorMatrizResponsabilidade) {
    const resposta = respostasMatrizResponsabilidade.find(
      item => item.matrizId === matriz.id,
    );

    // Para resolver no momento o problema de formato de data. Como grava às 00 horas e na conversão subtrai 3 (indo para 21 horas), estou adicionando 5 pra ficar no dia certo
    const dataPrevista = resposta?.dataPrevista
      ? new Intl.DateTimeFormat('pt-BR').format(
          new Date(String(resposta?.dataPrevista)).setHours(
            new Date(String(resposta?.dataPrevista)).getHours() + 5,
          ),
        )
      : '';
    return (
      <tr key={`tr_item_${matriz.id}`}>
        <td>{matriz.descricao}</td>
        {listaTiposMatriz.map(item => {
          return (
            <td key={`td_${matriz.id}_${item.sigla}`}>
              {resposta?.valorDefault === item.sigla ? 'X' : ''}
            </td>
          );
        })}
        <td>{dataPrevista}</td>
      </tr>
    );
  }

  const clienteNome = valores.find(item => item.label === `clienteNome`)?.valor;
  const clienteCPFCNPJ = valores.find(
    item => item.label === `clienteCPFCNPJ`,
  )?.valor;

  const numeroProposta = valores.find(
    item => item.label === `numeroProposta`,
  )?.valor;
  const numeroVersao = valores.find(
    item => item.label === `numeroVersao`,
  )?.valor;
  const dataAtual = valores.find(item => item.label === `dataAtual`)?.valor;

  const clienteNomeFantasia = valores.find(
    item => item.label === `clienteNomeFantasia`,
  )?.valor;

  const clienteTelefone = valores.find(
    item => item.label === `clienteTelefone`,
  )?.valor;

  const propostaUF = valores.find(item => item.label === `propostaUF`)?.valor;

  const propostaMunicipio = valores.find(
    item => item.label === `propostaMunicipio`,
  )?.valor;

  const propostaInscricaoEstadual = valores.find(
    item => item.label === `propostaInscricaoEstadual`,
  )?.valor;

  const propostaObservacao = valores.find(
    item => item.label === `propostaObservacao`,
  )?.valor;

  return (
    <>
      <Row>
        <Col sm="12" className="mt-1">
          <div
            style={{
              float: 'left',
              padding: '5px',
            }}
          >
            <img src={LogoImg} alt="Logo" width="65%" height="65%" />
          </div>

          <div
            style={{
              float: 'right',
              fontSize: '14px',
              lineHeight: '1.1em',
            }}
          >
            <strong>
              FOCKINK INDÚSTRIAS ELÉTRICAS LTDA. <br />
              CNPJ 03.021.334/0003-00 INSCR.EST.090/0037652 <br />
              CNPJ (Matriz) 03.021.334/0001-30
            </strong>
          </div>
          <div style={{ width: '100%', float: 'left' }}>&nbsp;</div>
        </Col>
      </Row>
      <div
        style={{
          textAlign: 'justify',
          textJustify: 'inter-word',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h1>Matriz de Responsabilidade</h1>
        </div>
        <div>
          <strong>PROPOSTA ORÇAMENTARIA Nº: </strong>
          {numeroProposta} v{numeroVersao}
        </div>
        <div>
          <strong>Data Emissão: </strong>
          {dataAtual}
        </div>
        <div>
          <strong>Cliente: </strong>
          {clienteNome}
        </div>
        <div>
          <strong>Fazenda: </strong>
          {clienteNomeFantasia ?? ''}
        </div>
        <div>
          <strong>CPF ou CNPJ: </strong>
          {clienteCPFCNPJ}
        </div>
        <div>
          <strong>Inscrição Estadual / Produtor: </strong>
          {propostaInscricaoEstadual}
        </div>
        <div>
          <strong>Cidade / Estado: </strong>
          {propostaMunicipio} / {propostaUF}
        </div>
        <div>
          <strong>Telefone: </strong>
          {clienteTelefone}
        </div>
        <br />
        <Table
          className="tableMatriz"
          style={{
            lineHeight: '15px',
            fontSize: '14px',
          }}
        >
          <thead>
            <tr>
              <th rowSpan={2}>Serviços</th>
              <th colSpan={5}>Responsabilidade / Fornecimento</th>
            </tr>
            <tr>
              {listaTiposMatriz.map(item => {
                return <th key={`th_item_${item.sigla}`}>{item.valor}</th>;
              })}
              <th>
                Data prevista <br />
                para entrega
              </th>
            </tr>
          </thead>
          <tbody>
            {listaMatrizResponsabilidade
              .filter(item =>
                respostasMatrizResponsabilidade.find(
                  item2 => item2.matrizId === item.id,
                ),
              )
              .map(item => {
                return getRowMatriz(item);
              })}
          </tbody>
        </Table>
        <div className="alinhamentoCentro">
          <small>
            * Marcar com x a responsabilidade/fornecimento do serviço
          </small>
        </div>
        <br />
        {propostaObservacao && (
          <>
            <div>
              <strong>OBS: {propostaObservacao}</strong>
            </div>
            <br />
          </>
        )}
        <Row style={{ marginTop: '90px' }}>
          <Col sm="4">
            <div style={{ textAlign: 'center', verticalAlign: 'top' }}>
              ______________________ <br />
              Cliente
            </div>
          </Col>
          <Col sm="4">
            <div style={{ textAlign: 'center', verticalAlign: 'top' }}>
              ______________________ <br /> Representante <br />
              <div style={{ fontSize: '12px', marginTop: '20px' }}>
                Nome: __________________
              </div>
            </div>
          </Col>
          <Col sm="4">
            <div style={{ textAlign: 'center', verticalAlign: 'top' }}>
              ______________________ <br />
              Comercial Fockink <br />
              <div style={{ fontSize: '12px', marginTop: '20px' }}>
                Nome: __________________
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
