import { useContext, useEffect, useState } from 'react';
import {
  CardHeader,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Input,
} from 'reactstrap';

import api from 'services/api';
import { PropostaMaterialAvulso as IPropostaMaterialAvulso } from 'models/PropostaMaterialAvulso';
import { PropostaVersao } from 'models/PropostaVersao';
import Produto from 'models/Produto';
import AtividadeModel from 'models/ConfiguradorAtividade';
import PropostaVersaoProduto from 'models/PropostaVersaoProduto';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import AutocompleteItemDomsge from 'components/AutocompleteItemDomsge';
import { FaPlusCircle } from 'react-icons/fa';
import ItemDomsge from 'models/ItemDomsge';
import { CircularProgress } from '@material-ui/core';
import toast from 'react-hot-toast';
import { PropostaContext } from 'views/pages/Proposta';
import { useQuery } from 'react-query';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { useHistory } from 'react-router-dom';
import { Th, Td } from './styles';
import MaterialItem from '../MaterialItem';
import MaterialTotalItem from '../MaterialTotalItem';
import { MateriaisContext } from '../..';

const MySwal = withReactContent(Swal);
export default function PropostaMaterialAvulso(): JSX.Element {
  const { propostaVersao, permiteModificacao, carregaPropostaVersao } =
    useContext(PropostaContext);
  const {
    setValorItens,
    setValorFrete,
    setValorFinal,
    setValorFinalDolar,
    setValorPercentualFrete,
    setValorDesconto,
    setValorServico,
  } = useContext(MateriaisContext);
  const [materiaisAvulsos, setMateriaisAvulsos] = useState<
    IPropostaMaterialAvulso[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [itemDomsgeSelecionado, setItemDomsgeSelecionado] =
    useState<ItemDomsge | null>(null);
  const [quantidadeNovoItem, setQuantidadeNovoItem] = useState(1);
  const [atividadeNovoItem, setAtividadeNovoItem] = useState<number>();
  const [atividades, setAtividades] = useState([] as AtividadeModel[]);
  const [codEmpresa, setCodEmpresa] = useState('');
  const [acessoLimitadoListaMateriais, setAcessoLimitadoListaMateriais] =
    useState(true);
  const history = useHistory();

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 60,
      },
    });

    setAcessoLimitadoListaMateriais(response.data);
  }

  useEffect(() => {
    getAcessoLimitadoPrograma();
  }, []);

  const valorTotalAvulsos = materiaisAvulsos.reduce(
    (total, item) => total + Number(item.valorTotal || 0),
    0,
  );

  async function returnMateriais(): Promise<IPropostaMaterialAvulso[]> {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/material`,
    );
    return response.data;
  }

  const { data: dataMateriaisAvulsos, isLoading } = useQuery(
    ['proposta-configuracao-materiais-avulsos', propostaVersao.id],
    async () => {
      const data = await returnMateriais();
      return data;
    },
  );

  useEffect(() => {
    if (dataMateriaisAvulsos) {
      setMateriaisAvulsos(dataMateriaisAvulsos);
    }
  }, [dataMateriaisAvulsos]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  async function carregaMateriaisAvulsos(): Promise<void> {
    try {
      setLoading(true);
      const data = await returnMateriais();
      setMateriaisAvulsos(data);
    } finally {
      setLoading(false);
    }
  }

  async function carregaPropostaV() {
    const response = await api.get(`/proposta/versao/${propostaVersao.id}`);
    const propostaVersaoData = response.data as PropostaVersao;
    // cabeçalho
    setValorItens(propostaVersaoData.valorItens);
    setValorFrete(Number(propostaVersaoData.percentualFrete));
    setValorFinal(propostaVersaoData.valorFinal);
    setValorFinalDolar(propostaVersaoData.valorFinalDolar);
    // rodapé
    setValorPercentualFrete(propostaVersaoData.percentualFrete);
    setValorDesconto(propostaVersaoData.valorDesconto);
    setValorServico(propostaVersaoData.valorServico);
  }

  async function addMaterialAvulso() {
    if (!itemDomsgeSelecionado) {
      return;
    }
    try {
      setUpdating(true);
      const {
        FABR_EMPR_CODIGO,
        FABR_CODIGO,
        DITE_DGPE_CODIGO,
        DITE_CODIGO,
        DESCRICAO,
      } = itemDomsgeSelecionado;
      await api.post(`/proposta/versao/${propostaVersao.id}/material`, {
        fabrEmprCodigo: FABR_EMPR_CODIGO,
        fabrCodigo: FABR_CODIGO,
        diteDgpeCodigo: DITE_DGPE_CODIGO,
        diteCodigo: DITE_CODIGO,
        descricao: DESCRICAO,
        quantidade: quantidadeNovoItem,
        atividade: atividadeNovoItem,
      });
      setItemDomsgeSelecionado(null);
      setAtividadeNovoItem(0);
      setQuantidadeNovoItem(1);

      carregaMateriaisAvulsos();
      carregaPropostaV();
      carregaPropostaVersao();
    } finally {
      setUpdating(false);
    }
  }

  async function updateMaterialAvulso(materialAvulso: IPropostaMaterialAvulso) {
    try {
      setUpdating(true);
      const { id, quantidade, atividade } = materialAvulso;
      await api.put(`/proposta/versao/${propostaVersao.id}/material/${id}`, {
        quantidade: Number(quantidade),
        atividade: atividade || '',
      });
      carregaMateriaisAvulsos();
      carregaPropostaV();
      carregaPropostaVersao();
    } finally {
      setUpdating(false);
    }
  }

  async function deleteMaterialAvulso(materialAvulsoId: number) {
    let motivo = '';

    async function deletePropostaConfiguracaoMaterial() {
      try {
        setUpdating(true);
        await api.delete(
          `/proposta/versao/${propostaVersao.id}/material/${materialAvulsoId}?motivo=${motivo}`,
        );
        const novoMateriaisAvulsos = materiaisAvulsos.filter(
          materialAvulso => materialAvulso.id !== materialAvulsoId,
        );
        setMateriaisAvulsos(novoMateriaisAvulsos);
      } finally {
        setUpdating(false);
      }
    }

    async function openModal() {
      MySwal.fire({
        title: `Deseja excluir o material avulso?`,
        html: `<br />
        <strong>Informe um motivo da exclusão do material:</strong>
        <input id="swal-input1" value='${motivo}' class="swal2-input">`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, excluir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(result => {
        const input = Swal?.getHtmlContainer()?.querySelector(
          '#swal-input1',
        ) as any;

        motivo = input.value;
        if (result.isConfirmed) {
          if (motivo.length < 5) openModal();
          else deletePropostaConfiguracaoMaterial();
        }
      });
    }

    openModal();
  }

  async function listaAtividades() {
    const response = await api.get(
      `/atividades?propostaVersaoId=${propostaVersao.id}`,
    );
    setAtividades(response.data);
  }

  async function buscarCodigoEmpresaProposta() {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/empresa-proposta`,
    );
    setCodEmpresa(response.data);
  }

  useEffect(() => {
    if (propostaVersao.id) {
      listaAtividades();
      buscarCodigoEmpresaProposta();
    }
  }, [propostaVersao]);

  useEffect(() => {
    if (updating) {
      toast.loading('Aguarde...', { position: 'top-right' });
      return;
    }

    toast.dismiss();
  }, [updating]);

  function handleChangeValue(id: number, name: string, value: number | string) {
    const novoMateriaisAvulsos = materiaisAvulsos.map(materialAvulso =>
      materialAvulso.id === id
        ? {
            ...materialAvulso,
            [name]: value,
          }
        : materialAvulso,
    );
    setMateriaisAvulsos(novoMateriaisAvulsos);
  }

  function handleSelecionarItemDomsge(itemDomsge: ItemDomsge | null) {
    setItemDomsgeSelecionado(itemDomsge);
  }

  function Materiais(): JSX.Element {
    if (!materiaisAvulsos.length) {
      return (
        <tr>
          <Td className="text-center" colSpan={5}>
            Nenhum registro encontrado
          </Td>
        </tr>
      );
    }

    return (
      <>
        {materiaisAvulsos.map(materialAvulso => {
          const { id } = materialAvulso;
          return (
            <MaterialItem
              key={id}
              propostaMaterial={materialAvulso}
              canEdit={permiteModificacao}
              canDelete={permiteModificacao}
              handleChangeValue={handleChangeValue}
              disabled={updating}
              onClickUpdate={() => updateMaterialAvulso(materialAvulso)}
              onClickDelete={() => deleteMaterialAvulso(id)}
              acessoLimitadoListaMateriais={acessoLimitadoListaMateriais}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      <CardHeader>
        <Row>
          <Col sm="10">
            <h4 className="mb--1">Materiais avulsos da proposta</h4>
            <small className="text-muted"> </small>
          </Col>
          <Col className="align-items-right" sm="2">
            <Button
              size="sm"
              disabled={updating}
              className="btn-icon btn-2"
              style={{ backgroundColor: '#1a8c5b' }}
              color="success"
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                history.push({
                  pathname: `/admin/configurador-itens-lote`,
                  state: {
                    propostaId: propostaVersao.id,
                    emprCodigo: codEmpresa,
                  },
                });
              }}
            >
              <span className="btn-inner--text">Cadastro em lote</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>

      {permiteModificacao && (
        <Card>
          <AutocompleteItemDomsge
            hideTitle
            handleSelecionarItemDomsge={handleSelecionarItemDomsge}
            maxWidth="60vw"
            noAutoFocus
            emprCodigo={codEmpresa}
          >
            <Input
              type="number"
              min={1}
              className="ml-2"
              value={quantidadeNovoItem}
              onChange={event =>
                setQuantidadeNovoItem(parseInt(event.target.value, 10))
              }
            />

            <Input
              className="ml-2"
              type="select"
              value={atividadeNovoItem !== 0 ? atividadeNovoItem : ''}
              onChange={text => setAtividadeNovoItem(Number(text.target.value))}
            >
              <option value="">Atividade</option>
              {atividades.map(atividade => (
                <option value={atividade.codigo}>
                  {atividade.codigo} - {atividade.descricao}
                </option>
              ))}
            </Input>
            <Button
              disabled={updating}
              className="btn-icon btn-2"
              color="primary"
              type="button"
              onClick={addMaterialAvulso}
            >
              <FaPlusCircle />
            </Button>
          </AutocompleteItemDomsge>
        </Card>
      )}

      <Card>
        <CardBody className="p-1">
          {loading ? (
            <Row>
              <Col className="text-center mb-4 mt-4">
                <CircularProgress />
              </Col>
            </Row>
          ) : (
            <Table bordered responsive>
              <thead>
                <tr>
                  <Th>Ativ</Th>
                  <Th>Item</Th>
                  <Th className="text-center">Quantidade</Th>
                  {!acessoLimitadoListaMateriais && (
                    <>
                      <Th className="text-right">Valor unitário</Th>
                      <Th className="text-right">Valor total</Th>
                    </>
                  )}
                  {materiaisAvulsos.length > 0 && permiteModificacao && <Th />}
                </tr>
              </thead>
              <tbody>{Materiais()}</tbody>
              <tfoot>
                <MaterialTotalItem
                  texto="Total materiais avulsos"
                  valor={valorTotalAvulsos}
                  elementType="h2"
                  acessoLimitadoListaMateriais={acessoLimitadoListaMateriais}
                />
              </tfoot>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
}
