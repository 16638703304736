import FichaTecnica from 'models/FichaTecnica';
import { Card, Col } from 'reactstrap';

import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import Quadro from 'views/pages/OrcamentoResultado/components/atoms/Quadro';

interface Props {
  fichaTecnica: FichaTecnica;
}

function DadosDimensionamentoAlimentador(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const {
    correnteEletrica,
    distanciaAlimentador,
    quedaTensaoMax,
    tensaoElevacao,
  } = fichaTecnica;

  const TITULO = 'DADOS DIMENSIONAMENTO ALIMENTADOR';

  const campos = [
    { label: 'Corrente Elétrica', valor: correnteEletrica, unidade: 'A' },
    {
      label: 'Distância do Alimentador',
      valor: distanciaAlimentador,
      unidade: 'Km',
    },
    { label: 'Queda tensão Máx', valor: quedaTensaoMax, unidade: '%' },
    { label: 'Tensão Elevação', valor: tensaoElevacao, unidade: 'V' },
  ];

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Col className="mt-2">
          {campos.map(campo => (
            <Campo
              sm="12"
              key={campo.label}
              number
              label={campo.label}
              valor={campo.valor}
              unidade={campo.unidade}
            />
          ))}
        </Col>
      </Quadro>
    </Card>
  );
}

export default DadosDimensionamentoAlimentador;
