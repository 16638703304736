import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

// reactstrap components
import { Card, CardHeader, Container, Row, Button, Modal } from 'reactstrap';
// core components

import Header from 'components/Headers/Header.js';

import CustomTable from 'components/CustomTable';

import Swal from 'sweetalert2';

import api from 'services/api';

import BalancoModel from '../../../models/ConfiguradorBalanco';
import LanceModel from '../../../models/ConfiguradorLance';
import TuboModel from '../../../models/ConfiguradorTubo';

interface CustomTableList {
  id: string;
  texto: string;
}

const ConfiguradorBalancos: React.FC = () => {
  const [balancos, setBalancos] = useState([] as any[]);
  const [Lances, setLances] = useState([] as any[]);
  const [notificationModalState, setNotificationModalState] = useState(false);
  const [tubos, setTubos] = useState([] as TuboModel[]);

  const opcoesTubos: CustomTableList[] = [];

  const header: any[] = [
    {
      name: 'Nome do Lance *',
      prop: 'NomeLance',
      type: 'TEXT',
      searchable: true,
      sortable: true,
      editable: true,
      required: true,
    },
    {
      name: 'Número Tubos *',
      prop: 'NumeroTubosLances',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: true,
    },
    {
      name: 'Comprimento (m) *',
      prop: 'ComprimentoLance',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: true,
    },
    {
      name: 'Quantidade Saídas',
      prop: 'QtdeSaidasLances',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Ordem * ',
      prop: 'OrdemLances',
      type: 'NUMBER',
      searchable: true,
      sortable: true,
      editable: true,
      required: true,
    },
  ];

  const headerBalancos: any[] = [
    {
      name: 'Nome',
      prop: 'nomeBalanco',
      type: 'TEXT',
      searchable: true,
      sortable: true,
      editable: true,
      required: true,
    },
    {
      name: 'Comprimento',
      prop: 'comprimentoBalanco',
      type: 'NUMBER',
      searchable: false,
      sortable: true,
      editable: true,
      required: false,
    },
    {
      name: 'Saídas',
      prop: 'saidasBalanco',
      type: 'NUMBER',
      searchable: false,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Tubo',
      prop: 'tubosBalanco',
      type: 'SELECT',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
      values: opcoesTubos,
    },
    {
      name: 'Sigla',
      prop: 'siglaBalanco',
      type: 'TEXT',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
  ];

  const onLink: any[] = [
    {
      name: 'Tubos',
      type: 'LINK',
      pathname: `/admin/lances-tubos`,
      stateLance: Lances,
      stateTipo: 'L',
    },
  ];

  // eslint-disable-next-line array-callback-return
  tubos.map(tubo => {
    opcoesTubos.push({ id: String(tubo.id), texto: tubo.nome });
  });

  async function listaTubos() {
    const response = await api.get('/integracao/tubos');
    setTubos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Lance?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/integracao/lances/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setLances(Lances.filter(item => item.id !== row.id));
        }
      });
    } else {
      setLances(Lances.filter(item => item.id !== row.id));
    }
  }

  async function handleDeleteBalancos(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Balanço?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/integracao/balancos/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setBalancos(balancos.filter(item => item.id !== row.id));
        }
      });
    } else {
      setBalancos(balancos.filter(item => item.id !== row.id));
    }
  }

  async function handleSave(row: any) {
    if (row.isNew) {
      const retorno = await api.post(`/integracao/lances`, {
        // eslint-disable-next-line prettier/prettier
        nome: row.columns.filter((col: any) => col.prop === 'NomeLance')[0].newValue,
        numeroTubos: row.columns.filter(
          (col: any) => col.prop === 'NumeroTubosLances',
        )[0].newValue,
        comprimento: row.columns.filter(
          (col: any) => col.prop === 'ComprimentoLance',
        )[0].newValue,
        quantidadeSaidas: row.columns.filter(
          (col: any) => col.prop === 'QtdeSaidasLances',
        )[0].newValue,
        ordem: row.columns.filter((col: any) => col.prop === 'OrdemLances')[0]
          .newValue,
      });

      const registroCriado = retorno.data;

      setLances(
        Lances.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.id = registroCriado.id;
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    } else {
      await api.put(`/integracao/lances/${row.id}`, {
        // eslint-disable-next-line prettier/prettier
        nome: row.columns.filter((col: any) => col.prop === 'NomeLance')[0].newValue,
        numeroTubos: row.columns.filter(
          (col: any) => col.prop === 'NumeroTubosLances',
        )[0].newValue,
        comprimento: row.columns.filter(
          (col: any) => col.prop === 'ComprimentoLance',
        )[0].newValue,
        quantidadeSaidas: row.columns.filter(
          (col: any) => col.prop === 'QtdeSaidasLances',
        )[0].newValue,
        ordem: row.columns.filter((col: any) => col.prop === 'OrdemLances')[0]
          .newValue,
      });

      setLances(
        Lances.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    }
  }

  async function handleSaveBalancos(row: any) {
    if (row.isNew) {
      const retorno = await api.post(`/integracao/balancos`, {
        // eslint-disable-next-line prettier/prettier
        comprimento: row.columns.filter(
          (col: any) => col.prop === 'comprimentoBalanco',
        )[0].newValue,
        quantidadeSaidas: row.columns.filter(
          (col: any) => col.prop === 'saidasBalanco',
        )[0].newValue,
        nome: row.columns.filter((col: any) => col.prop === 'nomeBalanco')[0]
          .newValue,
        idTubos: row.columns.filter(
          (col: any) => col.prop === 'tubosBalanco',
        )[0].newValue.id,
        sigla: row.columns.filter((col: any) => col.prop === 'siglaBalanco')[0]
          .newValue,
      });

      const registroCriado = retorno.data;

      setBalancos(
        balancos.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.id = registroCriado.id;
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    } else {
      await api.put(`/integracao/balancos/${row.id}`, {
        // eslint-disable-next-line prettier/prettier
        comprimento: row.columns.filter(
          (col: any) => col.prop === 'comprimentoBalanco',
        )[0].newValue,
        quantidadeSaidas: row.columns.filter(
          (col: any) => col.prop === 'saidasBalanco',
        )[0].newValue,
        nome: row.columns.filter((col: any) => col.prop === 'nomeBalanco')[0]
          .newValue,
        idTubos: row.columns.filter(
          (col: any) => col.prop === 'tubosBalanco',
        )[0].newValue.id,
        sigla: row.columns.filter((col: any) => col.prop === 'siglaBalanco')[0]
          .newValue,
      });

      setBalancos(
        balancos.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    }
  }

  function handleStartEditing(row: any) {
    setLances(
      Lances.map(item => {
        if (item.id === row.id) {
          // eslint-disable-next-line no-param-reassign
          item.isEditing = true;
        }
        return item;
      }),
    );
  }

  function handleStartEditingBalancos(row: any) {
    setBalancos(
      balancos.map(item => {
        if (item.id === row.id) {
          // eslint-disable-next-line no-param-reassign
          item.isEditing = true;
        }
        return item;
      }),
    );
  }

  function handleStopEditing(row: any) {
    if (row.isNew) {
      setLances(Lances.filter(item => item.id !== row.id));
    } else {
      setLances(
        Lances.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
          }
          return item;
        }),
      );
    }
  }

  function handleStopEditingBalancos(row: any) {
    if (row.isNew) {
      setBalancos(balancos.filter(item => item.id !== row.id));
    } else {
      setBalancos(
        balancos.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
          }
          return item;
        }),
      );
    }
  }

  function handleAddRow() {
    const count = Lances.length + 1;

    setLances([
      {
        id: count,
        columns: [
          {
            prop: 'NomeLance',
            value: '',
            newValue: '',
          },
          {
            prop: 'NumeroTubosLances',
            value: '',
            newValue: '',
          },
          {
            prop: 'ComprimentoLance',
            value: '',
            newValue: '',
          },
          {
            prop: 'QtdeSaidasLances',
            value: '',
            newValue: '',
          },
          {
            prop: 'OrdemLances',
            value: '',
            newValue: '',
          },
        ],
        isNew: true,
        isEditing: true,
      },
      ...Lances,
    ]);
  }

  function handleAddRowBalancos() {
    const count = balancos.length + 1;

    setBalancos([
      {
        id: count,
        columns: [
          {
            prop: 'nomeBalanco',
            value: '',
            newValue: '',
          },
          {
            prop: 'comprimentoBalanco',
            value: '',
            newValue: '',
          },
          {
            prop: 'saidasBalanco',
            value: '',
            newValue: '',
          },
          {
            prop: 'tubosBalanco',
            value: { id: null, texto: null },
            newValue: { id: null, texto: null },
          },
          {
            prop: 'siglaBalanco',
            value: '',
            newValue: '',
          },
        ],
        isNew: true,
        isEditing: true,
      },
      ...balancos,
    ]);
  }

  async function listaLances() {
    const response = await api.get('/integracao/lances');

    const Lance: LanceModel[] = response.data;

    const rows: any[] = [];

    let count = 0;
    // eslint-disable-next-line array-callback-return
    Lance.map(item => {
      rows.push({
        id: item.id,
        nome: item.nome,
        columns: [
          {
            prop: 'NomeLance',
            value: item.nome,
            newValue: item.nome,
          },
          {
            prop: 'NumeroTubosLances',
            value: item.numeroTubos !== null ? item.numeroTubos : '',
            newValue: item.numeroTubos !== null ? item.numeroTubos : '',
          },
          {
            prop: 'ComprimentoLance',
            value: item.comprimento !== null ? item.comprimento : '',
            newValue: item.comprimento !== null ? item.comprimento : '',
          },
          {
            prop: 'QtdeSaidasLances',
            value: item.quantidadeSaidas !== null ? item.quantidadeSaidas : '',
            newValue:
              item.quantidadeSaidas !== null ? item.quantidadeSaidas : '',
          },
          {
            prop: 'OrdemLances',
            value: item.ordem ? item.ordem : '',
            // eslint-disable-next-line prettier/prettier
            newValue: item.ordem  ? item.ordem : '',
          },
        ],
        isNew: false,
        isEditing: false,
      });
      // eslint-disable-next-line no-plusplus
      count++;
    });
    setLances(rows);
  }

  async function listaBalancos() {
    const response = await api.get('/integracao/balancos');

    const Balanco: BalancoModel[] = response.data;

    const rows: any[] = [];

    let count = 0;
    // eslint-disable-next-line array-callback-return
    Balanco.map(item => {
      rows.push({
        id: item.id,
        columns: [
          {
            prop: 'nomeBalanco',
            value: item.nome,
            newValue: item.nome,
          },
          {
            prop: 'comprimentoBalanco',
            value: item.comprimento !== null ? item.comprimento : '',
            newValue: item.comprimento !== null ? item.comprimento : '',
          },
          {
            prop: 'saidasBalanco',
            value: item.quantidadeSaidas !== null ? item.quantidadeSaidas : '',
            newValue:
              item.quantidadeSaidas !== null ? item.quantidadeSaidas : '',
          },
          {
            prop: 'tubosBalanco',
            value: {
              id: item.idTubos ? item.idTubos : count,
              texto: item.tubos !== null ? item.tubos : '',
            },
            newValue: {
              id: item.idTubos ? item.idTubos : count,
              texto: item.tubos !== null ? item.tubos : '',
            },
          },
          {
            prop: 'siglaBalanco',
            value: item.sigla,
            newValue: item.sigla,
          },
        ],
        isNew: false,
        isEditing: false,
      });
      // eslint-disable-next-line no-plusplus
      count++;
    });
    setBalancos(rows);
  }

  useEffect(() => {
    listaBalancos();
    listaLances();
    listaTubos();
  }, []);

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={notificationModalState}
              toggle={() => setNotificationModalState(!notificationModalState)}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  {/* Your attention is required */}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">
                    Não é possível criar novo registro
                  </h4>
                  <p>
                    Já existe um registro com esse Balanco e Flange selecionado
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Entendi
                </Button> */}
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  Fechar
                </Button>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Lances</h3>
              </CardHeader>
              <div style={{ minHeight: 700, width: '100%' }}>
                {/* CustomTable */}
                <CustomTable
                  enableSearchField
                  rows={Lances}
                  header={header}
                  onDelete={handleDelete}
                  onSave={handleSave}
                  onAddRow={handleAddRow}
                  onStartEditing={handleStartEditing}
                  onStopEditing={handleStopEditing}
                  onLink={onLink}
                />
                {/* END CustomTable */}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={notificationModalState}
              toggle={() => setNotificationModalState(!notificationModalState)}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  {/* Your attention is required */}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">
                    Não é possível criar novo registro
                  </h4>
                  <p>Já existe um registro com esse balanço selecionado</p>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Entendi
                </Button> */}
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  Fechar
                </Button>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Balanços</h3>
              </CardHeader>
              <div style={{ minHeight: 700, width: '100%' }}>
                {/* CustomTable */}
                <CustomTable
                  rows={balancos}
                  header={headerBalancos}
                  onDelete={handleDeleteBalancos}
                  onSave={handleSaveBalancos}
                  onAddRow={handleAddRowBalancos}
                  onStartEditing={handleStartEditingBalancos}
                  onStopEditing={handleStopEditingBalancos}
                />
                {/* END CustomTable */}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorBalancos;
