/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Select as SelectAntd } from 'antd';
import { TextField } from '@material-ui/core';

interface RegraProps {
  criando?: boolean;
  sintaxeInvalida?: boolean;
}

export const Select = styled(SelectAntd)`
  max-height: 43px;
  width: 100%;
`;

export const SelectFiltro = styled(SelectAntd)`
  max-height: 43px;
`;

export const RowFilter = styled.div`
  max-height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1rem;
`;

export const BuscarRegras = styled(TextField)`
  width: 70%;
  background-color: #fff;
`;

export const Regra = styled.div<RegraProps>`
  ${props => (props.sintaxeInvalida ? 'border: 1px solid #ea394c' : '')};
  ${props => (props.criando ? 'border: 1px solid #1ab394' : '')};
  background-color: #beb8be33;
  border-radius: 3px;
  margin-top: 1rem;
  padding: 1rem;
`;
