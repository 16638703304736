import { Select as SelectMaterial } from '@material-ui/core';
import QuestaoSelecaoListImagem from 'components/QuestaoSelecaoListImagem';
import OrcamentoResposta from 'models/OrcamentoResposta';
import Questao from 'models/Questao';
import QuestaoValorSelecao from 'models/QuestaoValorSelecao';
import Select from 'react-select';

import { InputGroup, Input, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2';
import { DropdownTipoQuestao } from 'views/pages/css';
import ImagemMultiSelecao from './ImagemMultiSelecao';

interface ValorAssociacao {
  questaoValorSelecaoId: number;
  descricao: string;
  valoresDependencia: {
    id: number;
    questaoId: number;
    descricao: string;
  }[];
}

interface Props {
  questao: Questao;
  questoes: Questao[] | undefined;
  respostas: OrcamentoResposta[];
  valoresAssociacao: ValorAssociacao[];
  setaNovaRespostaQuestao: (
    questao: Questao,
    novoValor: string,
    questoesRelacionadasToSetEmpty?: Questao[],
  ) => void;
  setaNovaRespostaImagem: (novoValor: string) => void;
  setaIdImagem: (idImagem: number) => void;
}

function QuestaoInput({
  questao,
  questoes,
  respostas,
  valoresAssociacao,
  setaNovaRespostaQuestao,
  setaNovaRespostaImagem,
  setaIdImagem,
}: Props): JSX.Element {
  if (!questao) {
    return <></>;
  }

  const { tipo, unidadeMedida, valorMinimo, valorMaximo } = questao;

  if (tipo === 'TI') return <></>;

  const respostaQuestao = respostas.find(
    (resposta: OrcamentoResposta) => resposta.questaoId === questao.id,
  );

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const novoValor = event.target.value;

    setaNovaRespostaQuestao(questao, novoValor);
  }

  function handleSelectChange(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    const novoValor = event.currentTarget.getAttribute('value');

    if (novoValor === '' && questao.isObrigatorio) {
      Swal.fire({
        icon: 'error',
        title: 'Selecione um item!',
      });
    }

    const questoesRelacionadas = questoes?.filter(
      thisQuestao =>
        thisQuestao.questaoSelecaoAssociada?.questaoId === questao.id,
    );

    setaNovaRespostaQuestao(questao, novoValor ?? '', questoesRelacionadas);
  }

  function handleSelectChangDefault(valorSelecao: string) {
    const questoesRelacionadas = questoes?.filter(
      thisQuestao =>
        thisQuestao.questaoSelecaoAssociada?.questaoId === questao.id,
    );

    setaNovaRespostaQuestao(questao, valorSelecao ?? '', questoesRelacionadas);
  }

  function handleSelectChangeImage(novoValor: string | undefined) {
    setaNovaRespostaQuestao(questao, novoValor ?? '');
  }

  function handleSelectChangeImageValue(novoValor: string | undefined) {
    setaNovaRespostaImagem(novoValor ?? '');
  }

  function handleSetIdImage(idImagem: number) {
    setaIdImagem(idImagem);
  }

  // para tipos Texto ou Number cria componente <Input />
  if (['T', 'N'].includes(tipo)) {
    const isNumberInput = tipo === 'N';

    return (
      <InputGroup className="input-group-alternative invalid-feedback">
        <Input
          required={questao.isObrigatorio ?? false}
          disabled={questao.bloquear ?? false}
          type={isNumberInput ? 'number' : 'text'}
          min={valorMinimo ?? undefined}
          max={valorMaximo ?? undefined}
          step={0.01}
          value={respostaQuestao?.resposta}
          onChange={handleInputChange}
        />
        <InputGroupAddon addonType="append">{unidadeMedida}</InputGroupAddon>
      </InputGroup>
    );
  }

  // Seleção ou Imagem
  if (['S', 'IM'].includes(tipo)) {
    const questaoValoresSelecao = questao.QuestaoValorSelecao;
    const questaoValorSelecao =
      questao.QuestaoValorSelecao as QuestaoValorSelecao[];

    const questaoValoresFiltradosSelecao = questaoValoresSelecao.filter(
      valorSelecao => {
        let exibeAlternativa = true;

        // verifica se tem relação
        const relacao = valoresAssociacao.find(
          v => v.questaoValorSelecaoId === valorSelecao.id,
        );

        if (relacao) {
          exibeAlternativa = false;

          const valoresDependencia = relacao?.valoresDependencia;

          /* exibeAlternativa = valoresDependencia?.every(valorDependencia =>
            respostas.find(
              resposta =>
                resposta.questaoId === valorDependencia.questaoId &&
                resposta.resposta === valorDependencia.descricao,
            ),
          ); */

          const validarRespostas = valoresDependencia?.map(valorDependencia => {
            const respostaValida = respostas.find(
              resposta =>
                resposta.questaoId === valorDependencia.questaoId &&
                resposta.resposta === valorDependencia.descricao,
            );

            return {
              respostaValida: !!respostaValida,
              ...valorDependencia,
            };
          });

          // Agrupar em arrays por questaoId
          const agrupado = validarRespostas.reduce(
            (resultado: any, objeto: any) => {
              if (!resultado[objeto.questaoId]) {
                // eslint-disable-next-line no-param-reassign
                resultado[objeto.questaoId] = [];
              }
              resultado[objeto.questaoId].push(objeto);
              return resultado;
            },
            {},
          );

          const arraysAgrupados = Object.values(agrupado);

          // Agora pega os dados agrupados e verifica se Todos tem algum registro true
          exibeAlternativa = arraysAgrupados?.every((valorDependencia: any) => {
            const algumValido =
              valorDependencia.filter(
                (valor: any) => valor.respostaValida === true,
              ).length > 0;

            return algumValido;
          });
        }

        return exibeAlternativa;
      },
    );

    // para tipos Imagem
    if (tipo === 'IM') {
      const descricaoSelecao =
        questaoValoresFiltradosSelecao.find(
          x =>
            x.descricao === respostaQuestao?.resposta ||
            (!respostaQuestao?.resposta && x.isDefault === true),
        )?.descricao ?? '';

      const questoesRelacionadas = questoes?.filter(
        thisQuestao =>
          thisQuestao.questaoSelecaoAssociada?.questaoId === questao.id,
      );

      if (descricaoSelecao && descricaoSelecao !== '') {
        setaNovaRespostaQuestao(
          questao,
          descricaoSelecao,
          questoesRelacionadas,
        );
      }

      if (questao.permiteMultiSelecao) {
        const respostasSplit = respostaQuestao?.resposta.split(',');

        return (
          <ImagemMultiSelecao
            valoresSelecao={questaoValoresFiltradosSelecao}
            listaValoresSelecao={questaoValoresSelecao}
            visaoEmLinhaDefault
            readOnly
            handleChangeValorQuestionario={handleSelectChangeImage}
            respostaImagemSelecionada={questaoValoresFiltradosSelecao.filter(
              x =>
                respostasSplit?.includes(x.descricao) ||
                (!respostaQuestao?.resposta && x.isDefault === true),
            )}
            requiredImage={questao.isObrigatorio ?? false}
            handleChangeImageValue={handleSelectChangeImageValue}
            handleSetaIdImage={handleSetIdImage}
          />
        );
      }

      return (
        <QuestaoSelecaoListImagem
          valoresSelecao={questaoValoresFiltradosSelecao}
          listaValoresSelecao={questaoValoresSelecao}
          visaoEmLinhaDefault
          readOnly
          handleChangeValorQuestionario={handleSelectChangeImage}
          respostaImagemSelecionada={questaoValoresFiltradosSelecao.find(
            x =>
              x.descricao === respostaQuestao?.resposta ||
              (!respostaQuestao?.resposta && x.isDefault === true),
          )}
          requiredImage={questao.isObrigatorio ?? false}
          handleChangeImageValue={handleSelectChangeImageValue}
          handleSetaIdImage={handleSetIdImage}
        />
      );
    }

    const descricaoSelecao =
      questaoValoresFiltradosSelecao.find(
        item =>
          item.descricao === respostaQuestao?.resposta ||
          (!respostaQuestao?.resposta &&
            item.isDefault === true &&
            !respostaQuestao?.questao.isObrigatorio),
      )?.descricao ?? 'Selecione';

    const questoesRelacionadas = questoes?.filter(
      thisQuestao =>
        thisQuestao.questaoSelecaoAssociada?.questaoId === questao.id,
    );

    // Avalia se tem algum default
    if (
      (!respostaQuestao || !respostaQuestao?.resposta) &&
      descricaoSelecao !== 'Selecione'
    ) {
      setaNovaRespostaQuestao(questao, descricaoSelecao, questoesRelacionadas);
    }

    if (questao.permiteMultiSelecao) {
      const opcoes = questaoValoresFiltradosSelecao.map((item: any) => {
        return { label: item.descricao, value: item.descricao };
      });

      const arrayRespostas = respostaQuestao?.resposta?.split(',') ?? [];

      // Filtrar o array de objetos
      const valores =
        opcoes.filter(objeto => arrayRespostas.includes(objeto.value)) ?? [];

      return (
        <InputGroup className="input-group-alternative">
          <DropdownTipoQuestao
            style={{ minWidth: unidadeMedida ? '65%' : '100%' }}
          >
            <div style={{ width: '100%' }}>
              <Select
                closeMenuOnSelect={false}
                placeholder="Selecione"
                isMulti
                required={questao.isObrigatorio ?? false}
                value={valores}
                options={opcoes}
                onChange={(e: any) => {
                  const resposta = e.map((it: any) => it.value)?.join(',');

                  setaNovaRespostaQuestao(
                    questao,
                    resposta,
                    questoesRelacionadas,
                  );
                }}
              />
            </div>
          </DropdownTipoQuestao>
          <InputGroupAddon addonType="append">{unidadeMedida}</InputGroupAddon>
        </InputGroup>
      );
    }
    // para tipos Seleção cria componente <Dropdown />
    return (
      <InputGroup className="input-group-alternative">
        <DropdownTipoQuestao
          style={{ minWidth: unidadeMedida ? '65%' : '100%' }}
        >
          <SelectMaterial
            style={{ minWidth: unidadeMedida ? '65%' : '100%' }}
            className="dropdown-menu-arrow"
            required={questao.isObrigatorio ?? false}
            disabled={questao.bloquear ?? false}
            value={descricaoSelecao === 'Selecione' ? '' : descricaoSelecao}
            multiple={questao.permiteMultiSelecao ?? false}
            onChange={event => {
              setaNovaRespostaQuestao(
                questao,
                String(event.target.value),
                questoesRelacionadas,
              );
            }}
          >
            <option className="dropdown-item" key={null} value="">
              Selecione...
            </option>
            {questaoValoresFiltradosSelecao.map(item => {
              return (
                <option
                  className="dropdown-item"
                  key={item.id}
                  value={item.descricao}
                >
                  {item.descricao}
                </option>
              );
            })}
          </SelectMaterial>
        </DropdownTipoQuestao>
        <InputGroupAddon addonType="append">{unidadeMedida}</InputGroupAddon>
      </InputGroup>
    );
  }

  return <></>;
}

export default QuestaoInput;
