import styled from 'styled-components';
import { UncontrolledDropdown, DropdownToggle, Col } from 'reactstrap';

interface PropsDiv {
  enabled: boolean;
  tipo: string;
}

export const QuestaoContainerRow = styled.div<PropsDiv>`
  display: flex;
  flex-direction: row;
  margin: 0 0.5rem 1rem 1rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.tipo === 'TI'
      ? '#d7e3f363'
      : props.tipo === 'INF'
      ? '#88c1f2'
      : 'unset'};
  ${props => (props.enabled ? '' : 'opacity: 0.3')};
`;

export const AcoesDiv = styled.div`
  float: right;
`;

export const DropdownTipoQuestao = styled(UncontrolledDropdown)`
  display: flex;
`;

export const DropdownToggleTipoQuestao = styled(DropdownToggle)`
  width: 100%;
  text-align: start;
`;

export const ContainerInterno = styled(Col)`
  background-color: #fff;
  padding: 1.5rem 2rem 1rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid #d7e3f363;
  border-radius: 5px;
`;

export const SelecaoRelacaoContainer = styled(Col)`
  background-color: #fff;
  margin-top: 2rem;
`;
