import { Col, Label as LabelR } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const AceiteTexto = styled(LabelR)`
  font-size: 1rem;
  font-weight: 400;
  color: #000;
`;

export const Label = styled(LabelR)`
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  text-align: center;
`;

export const AssinaturaCol = styled(Col)`
  border-top: 1px solid #000;
`;
