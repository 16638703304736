import { Fab, Tooltip } from '@material-ui/core';
import { Container } from './styles';

interface Props {
  title?: string;
  children: JSX.Element;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | undefined;
}

function FloatingButton({
  children,
  title,
  onClick,
  className,
  disabled,
  hidden,
  color,
}: Props): JSX.Element {
  return (
    <Container className={className} hidden={hidden}>
      <Tooltip title={title || ''}>
        <Fab
          disabled={disabled}
          size="medium"
          color={color ?? 'primary'}
          aria-label={title}
          onClick={onClick}
        >
          {children}
        </Fab>
      </Tooltip>
    </Container>
  );
}

export default FloatingButton;
