/* eslint-disable no-eval */
import React, { useState, useEffect, useCallback } from 'react';

import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
  Badge,
  CardFooter,
  Collapse,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaCheckCircle,
  FaSpinner,
  FaTable,
  FaArrowLeft,
  FaBars,
} from 'react-icons/fa';
import { Mentions } from 'antd';

import api from 'services/api';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  iColunasGenerica,
  iTabelaGenerica,
  iTabelaImportacao,
} from 'models/TabelaGenerica';
import generateHash from 'utils/generateHash';
import { useHistory, useLocation } from 'react-router-dom';
import FloatingButton from 'components/FloatingButton';
import TipoCampoTabelaGenerica, {
  iTipoCampoTabelaGenerica,
} from '../../../staticData/TipoCampoTabelaGenerica';
import './style.css';

const { Option } = Mentions;

interface editTabelaGenerica extends iTabelaGenerica {
  labelValido: boolean;
}

interface editColunasGenerica extends iColunasGenerica {
  labelValido: boolean;
}

type LocationState = {
  stateTabela?: iTabelaGenerica;
};

const TabelaGenericaColuna: React.FC = () => {
  const location = useLocation<LocationState>();
  const { stateTabela } = location.state;

  const history = useHistory();

  const [tabelaGenerica, setTabelaGenerica] = useState(stateTabela);

  const [listaTipoCampo, setListaTipoCampo] = useState(
    [] as iTipoCampoTabelaGenerica[],
  );
  const [listaTabelaGenericaColuna, setListaTabelaGenericaColuna] = useState(
    [] as editColunasGenerica[],
  );
  const [listaTabelaImportacao, setListaTabelaImportacao] = useState(
    [] as iTabelaImportacao[],
  );
  const [mostrarDesativados, setMostrarDesativados] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [colunaEditing, setColunaEditing] = useState({} as editColunasGenerica);
  const [formModalState, setFormModalState] = useState(false);
  const [ordemAlterada, setOrdemAlterada] = useState(false);
  const [atualizandoOrdem, setAtualizandoOrdem] = useState(false);
  const prefixColumns = '#';
  const [prefix, setPrefix] = useState(prefixColumns);

  async function validarRegra(variavelBuscar: string) {
    if (!variavelBuscar) return false;
    return true;
  }

  async function carregarTabelaGenericaColuna() {
    if (!stateTabela) return;

    const response = await api.get(
      `/tabela-generica/${stateTabela.id}/colunas`,
    );

    setListaTabelaGenericaColuna(response.data);
  }

  async function carregarTabelaImportacao() {
    const response = await api.get('/tabela-generica/tabela-importacao');

    setListaTabelaImportacao(response.data);
  }

  async function carregarDados() {
    if (!stateTabela) return;

    setLoading(true);
    setTabelaGenerica(stateTabela);
    setListaTipoCampo(TipoCampoTabelaGenerica);
    await carregarTabelaGenericaColuna();
    await carregarTabelaImportacao();
    setLoading(false);
  }

  useEffect(() => {
    carregarDados();
  }, []);

  async function ativarDesativar(tabelaId: number, ativar: boolean) {
    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      await api.delete(`/tabela-generica/coluna/${tabelaId}?ativo=${ativar}`);
      toast.success('Feito!', {});

      carregarTabelaGenericaColuna();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleAtivar(colunaId: number) {
    Swal.fire({
      title: `Deseja reativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(colunaId, true);
        Swal.close();
      }
    });
  }

  async function handleDelete(colunaId: number) {
    Swal.fire({
      title: `Deseja desativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(colunaId, false);
        Swal.close();
      }
    });
  }

  async function handleInsert() {
    setColunaEditing({
      labelValido: false,
    } as editColunasGenerica);
    setFormModalState(!formModalState);
  }

  function handleUpdate(coluna: editColunasGenerica) {
    setColunaEditing({ ...coluna, labelValido: true } as editColunasGenerica);
    setFormModalState(!formModalState);
  }

  async function handleCloseModalColuna() {
    setFormModalState(false);
  }

  function renderListaSugestoes(questaoId?: number) {
    const filtrado =
      questaoId !== 0
        ? listaTabelaGenericaColuna.filter(
            coluna => coluna.ativo === true && coluna.id !== questaoId,
          )
        : listaTabelaGenericaColuna.filter(coluna => coluna.ativo === true);

    const listaSugestoesQuestao = [] as string[];

    filtrado.forEach(coluna => {
      listaSugestoesQuestao.push(`${coluna.nome}#`);
    });

    const listaSugestoes = {
      [prefixColumns]: listaSugestoesQuestao,
    } as any;

    return (listaSugestoes[prefixColumns] || []).map((value: string) => (
      <Option key={value} value={value}>
        {value}
      </Option>
    ));
  }

  async function validateRuleBeforeSave(regra: string) {
    let regraValida = true;

    try {
      const operadoresValidos = [
        {
          operador: ' = ',
          operadorJs: ' === ',
        },
        {
          operador: ' != ',
          operadorJs: ' !== ',
        },
        {
          operador: ' <> ',
          operadorJs: ' !== ',
        },
        {
          operador: ' =? ',
          operadorJs: '.indexOf(',
        },
        {
          operador: ' =! ',
          operadorJs: '.indexOf(',
        },
      ];

      let regraValidar = regra ?? '';

      // eslint-disable-next-line no-restricted-syntax
      for await (const operadorValido of operadoresValidos) {
        const { operador, operadorJs } = operadorValido;

        // No OR, valida a condição true
        if (operador === ' =? ' && regraValidar.includes(' =? ')) {
          const regrasDivididas = regraValidar.split(';');

          const regrasSintaxeOu = regrasDivididas.map(regraIndividual =>
            regraIndividual.includes(' =? ')
              ? `${regraIndividual}) >= 0`
              : regraIndividual,
          );

          regraValidar = regrasSintaxeOu.join(';');
        }

        // No OR, valida a condição false
        if (operador === ' =! ' && regraValidar.includes(' =! ')) {
          const regrasDivididas = regraValidar.split(';');

          const regrasSintaxeOu = regrasDivididas.map(regraIndividual =>
            regraIndividual.includes(' =! ')
              ? `${regraIndividual}) < 0`
              : regraIndividual,
          );

          regraValidar = regrasSintaxeOu.join(';');
        }

        regraValidar = regraValidar.split(operador).join(` ${operadorJs} `);
      }

      regraValidar = regraValidar
        .split(',')
        .join('.') // substitui todas , por .
        .split('||')
        .join(',')
        .split(';')
        .join(' && ');

      // eslint-disable-next-line no-useless-escape
      const regrasColunaArray = regraValidar.match(/\#.+?\#/g);

      if (regrasColunaArray)
        // eslint-disable-next-line no-restricted-syntax
        for await (const regraAplicacao of regrasColunaArray) {
          const numberOrString = Number.isNaN(regraValidar)
            ? `'${regraValidar}'`
            : Number(regraValidar);
          regraValidar = regraValidar
            .split(regraAplicacao)
            .join(String(numberOrString));
        }

      eval(regraValidar);
    } catch (e: any) {
      // console.log(e);
      regraValida = false;
    }
    return regraValida;
  }

  async function handleSaveColuna() {
    if (!tabelaGenerica) return;

    if (
      !colunaEditing.nome ||
      !colunaEditing.tipo ||
      (tabelaGenerica.disponibilizarRegras && !colunaEditing.labelRegras)
    ) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (colunaEditing.tipo === 'S') {
      if (
        (colunaEditing.regramento.opcoesOutraOrigem === false &&
          !colunaEditing.regramento.opcoes) ||
        (colunaEditing.regramento.opcoesOutraOrigem === true &&
          (!colunaEditing.associacao.tabelaOrigem ||
            !colunaEditing.associacao.coluna))
      ) {
        toast.error(
          'Todos os campos marcados com * são de preenchimento obrigatório!',
        );
        return;
      }

      if (
        colunaEditing.regramento.opcoesOutraOrigem === true &&
        ((colunaEditing.associacao.colunaFiltro &&
          !colunaEditing.associacao.questaoFiltro) ||
          (!colunaEditing.associacao.colunaFiltro &&
            colunaEditing.associacao.questaoFiltro))
      ) {
        toast.error(
          'Para inclusão de filtro, tanto a coluna quanto a questão devem ser informadas!',
        );
        return;
      }
    }

    const depValidar = colunaEditing.regraDependencia;

    let dependenciaSalvar = '';

    if (depValidar) {
      // Validar as dependências.

      const valido = await validateRuleBeforeSave(depValidar);

      if (!valido) {
        toast.error('A regra de exibição não está em um formato válido');
        return;
      }

      dependenciaSalvar = depValidar;
    }

    if (tabelaGenerica.disponibilizarRegras && !colunaEditing.labelValido) {
      const variavel = colunaEditing.labelRegras ?? '';

      const valido = await validarRegra(variavel);
      if (!valido) {
        toast.error(
          'A variável de regra informada já está sendo usada por outro registro',
        );
        return;
      }
    }

    const maxOrder = listaTabelaGenericaColuna.reduce(
      (max: any, current: any) =>
        max && max > current.ordem ? max : current.ordem,
      0,
    );

    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      if (colunaEditing.id) {
        await api.put(`/tabela-generica/coluna/${colunaEditing.id}`, {
          ...colunaEditing,
          labelValido: true,
          tabelaGenericaId: tabelaGenerica.id,
          hash: `${tabelaGenerica.id}.${generateHash()}`,
          // ordem: maxOrder + 1, //no update não precisa reordenar
          obrigatorio:
            colunaEditing.regramento.obrigatorio === undefined
              ? false
              : colunaEditing.regramento.obrigatorio,
          regraDependencia: dependenciaSalvar,
        });
      } else {
        await api.post(`/tabela-generica/coluna`, {
          ...colunaEditing,
          tabelaGenericaId: tabelaGenerica.id,
          ordem: maxOrder + 1,
          hash: !colunaEditing.hash
            ? `${tabelaGenerica.id}.${generateHash()}`
            : colunaEditing.hash,
          labelValido: true,
          regraDependencia: dependenciaSalvar,
        });
      }

      toast.success('Feito!', {});
      Swal.close();

      setFormModalState(false);
      setColunaEditing({} as editColunasGenerica);
      carregarTabelaGenericaColuna();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }

    setColunaEditing({
      labelValido: false,
      nome: '',
      obrigatorio: false,
      labelRegras: '',
      tipo: '',
      dependencia: [],
      regraDependencia: '',
      ordem: 1,
      hash: '',
      id: 0,
      associacao: {},
      regramento: {},
      bloquear: false,
      ativo: true,
      tabelaGenericaId: stateTabela?.id ?? 0,
    } as editColunasGenerica);
  }

  async function updateOrdem(): Promise<void> {
    if (!stateTabela) return;
    try {
      setAtualizandoOrdem(true);

      toast.loading('Salvando ordenação...');

      const response = await api.put(
        `/tabela-generica/${stateTabela.id}/reordenar`,
        [
          ...listaTabelaGenericaColuna
            .filter(item => item.ativo === true)
            ?.map((it: any, index: number) => {
              return {
                id: it.id,
                ordem: index + 1,
              };
            }),
        ],
      );

      setListaTabelaGenericaColuna(response.data);
      setOrdemAlterada(false);
    } finally {
      setAtualizandoOrdem(false);
      toast.dismiss();
    }
  }

  function montarLista() {
    if (!listaTabelaGenericaColuna.length || !tabelaGenerica) {
      return <></>;
    }

    const SortableItem = SortableElement(({ questao }: any) => {
      return (
        <Row
          className={`row-item mt-1 ${
            questao.ativo === false ? 'row-disabled' : ''
          }`}
          key={`questao_${questao.id}`}
          enabled={!questao.ativo}
        >
          <Col xs="12" sm="4">
            <div>
              <FaBars /> {questao.nome}
              <br />
              <small
                className="ml-3"
                hidden={!tabelaGenerica.disponibilizarRegras}
              >
                <i>Identificação nas Regras: {questao.labelRegras ?? ''}</i>
              </small>
            </div>
          </Col>

          <Col xs="6" sm="3">
            {listaTipoCampo.find(it => it.sigla === questao.tipo)?.nome ?? ''}
          </Col>

          <Col xs="6" sm="3">
            <div
              hidden={!mostrarDesativados}
              className={`badge badge-${questao.ativo ? 'success' : 'danger'}`}
            >
              {questao.ativo ? 'Ativo' : 'Desativado'}
            </div>
          </Col>

          <Col xs="6" sm="2">
            <div className="float-right">
              {questao.ativo && (
                <>
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="warning"
                    type="button"
                    disabled={isSaving || loading}
                    onMouseDown={() => {
                      handleUpdate(questao);
                    }}
                  >
                    <FaEdit />
                  </Button>
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="danger"
                    type="button"
                    disabled={isSaving || loading}
                    onMouseDown={() => {
                      handleDelete(questao.id);
                    }}
                  >
                    <FaTimesCircle />
                  </Button>
                </>
              )}
              {!questao.ativo && (
                <>
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="info"
                    type="button"
                    disabled={isSaving || loading}
                    onMouseDown={() => {
                      handleAtivar(questao.id);
                    }}
                  >
                    <FaCheckCircle />
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
      );
    });

    const SortableList = SortableContainer(({ items }: any) => {
      return (
        <ul>
          {items
            .filter((item: any) =>
              !mostrarDesativados ? item.ativo === true : true,
            )
            .map((value: any, index: number) => (
              <SortableItem
                key={`item_${index}_${value.id}`}
                index={index}
                questao={value}
                disabled={!value.ativo || atualizandoOrdem}
                distance={1}
              />
            ))}
        </ul>
      );
    });

    return (
      <SortableList
        items={listaTabelaGenericaColuna}
        onSortEnd={({ oldIndex, newIndex }: any) => {
          const questoesReordenadas = arrayMove(
            listaTabelaGenericaColuna,
            oldIndex,
            newIndex,
          );

          setListaTabelaGenericaColuna(questoesReordenadas);

          setOrdemAlterada(true);
        }}
      />
    );
  }

  function onSearch(_: any, newPrefix: string) {
    setPrefix(newPrefix);
  }

  if (!tabelaGenerica) return <></>;

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="6">
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  disabled={isSaving || loading}
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="xl"
          isOpen={formModalState}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader>
                <Form role="form">
                  <Row>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                        <small>Nome da Coluna *</small>
                        <Input
                          placeholder="Nome da Coluna"
                          value={colunaEditing.nome}
                          type="text"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              nome: text.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                        <small>Tipo de Coluna *</small>

                        <Input
                          placeholder="Selecione o Tipo de Coluna *"
                          type="select"
                          value={colunaEditing.tipo}
                          onChange={(event: any) => {
                            setColunaEditing({
                              ...colunaEditing,
                              tipo: event.target.value,
                            });
                          }}
                        >
                          <option value="">Selecione</option>
                          {listaTipoCampo.map(item => (
                            <option value={item.sigla}>{item.nome}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="mb-3">
                        <small>Obrigatório?</small>
                        <br />
                        <label className="custom-toggle">
                          <input
                            checked={colunaEditing.regramento?.obrigatorio}
                            type="checkbox"
                            onChange={text =>
                              setColunaEditing({
                                ...colunaEditing,
                                regramento: {
                                  ...colunaEditing.regramento,
                                  obrigatorio: text.target.checked,
                                },
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      sm="6"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaOpcoes ?? false
                        )
                      }
                    >
                      <FormGroup
                        className="mb-3"
                        hidden={
                          colunaEditing.regramento?.opcoesOutraOrigem ?? false
                        }
                      >
                        <small>Opções (separado por ponto e vírgula)*</small>
                        <Input
                          placeholder="Opções"
                          value={colunaEditing.regramento?.opcoes ?? ''}
                          type="text"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                opcoes: text.target.value,
                              },
                            })
                          }
                        />
                      </FormGroup>

                      <FormGroup
                        className="mb-3"
                        hidden={!colunaEditing.regramento?.opcoesOutraOrigem}
                      >
                        <div>
                          <small>Tabela Origem *</small>

                          <Input
                            placeholder="Selecione a Tabela de Origem *"
                            type="select"
                            value={colunaEditing.associacao?.tabelaOrigem}
                            onChange={(event: any) => {
                              setColunaEditing({
                                ...colunaEditing,
                                associacao: {
                                  ...colunaEditing.associacao,
                                  tabelaOrigem: Number(event.target.value),
                                },
                              });
                            }}
                          >
                            <option value="">Selecione</option>
                            {listaTabelaImportacao.map(item => (
                              <option value={item.id}>{item.nome}</option>
                            ))}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col
                      sm="3"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaOpcoes ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Selecionar Vários?</small>
                        <br />
                        <label className="custom-toggle">
                          <input
                            checked={
                              colunaEditing.regramento?.selecionarMultiplos ??
                              false
                            }
                            type="checkbox"
                            onChange={text =>
                              setColunaEditing({
                                ...colunaEditing,
                                regramento: {
                                  ...colunaEditing.regramento,
                                  selecionarMultiplos: text.target.checked,
                                },
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          />
                        </label>
                      </FormGroup>
                    </Col>

                    <Col
                      sm="3"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaOpcoes ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Origem Externa Valores?</small>
                        <br />
                        <label className="custom-toggle">
                          <input
                            checked={
                              colunaEditing.regramento?.opcoesOutraOrigem ??
                              false
                            }
                            type="checkbox"
                            onChange={text =>
                              setColunaEditing({
                                ...colunaEditing,
                                regramento: {
                                  ...colunaEditing.regramento,
                                  opcoesOutraOrigem: text.target.checked,
                                },
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          />
                        </label>
                      </FormGroup>
                    </Col>

                    <Col
                      sm="6"
                      hidden={
                        !colunaEditing.regramento?.opcoesOutraOrigem ||
                        (listaTabelaImportacao.find(it => {
                          return (
                            String(it.id) ===
                            String(colunaEditing.associacao?.tabelaOrigem)
                          );
                        })?.colunas?.length ?? 0) === 0
                      }
                    >
                      <FormGroup className="mb-3">
                        <div>
                          <small>
                            Selecione a coluna em{' '}
                            <i>
                              {listaTabelaImportacao.find(
                                it =>
                                  it.id ===
                                  colunaEditing.associacao?.tabelaOrigem,
                              )?.nome ?? ''}
                            </i>{' '}
                            para exibição *
                          </small>

                          <Input
                            placeholder="Selecione a Coluna"
                            type="select"
                            value={colunaEditing.associacao?.coluna}
                            onChange={(event: any) => {
                              setColunaEditing({
                                ...colunaEditing,
                                associacao: {
                                  ...colunaEditing.associacao,
                                  coluna: event.target.value,
                                },
                              });
                            }}
                          >
                            <option value="">Selecione</option>
                            {listaTabelaImportacao
                              .find(it => {
                                return (
                                  String(it.id) ===
                                  String(colunaEditing.associacao?.tabelaOrigem)
                                );
                              })
                              ?.colunas?.map(item => (
                                <option value={item}>{item}</option>
                              ))}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col
                      sm="12"
                      hidden={
                        !colunaEditing.regramento?.opcoesOutraOrigem ||
                        (listaTabelaImportacao.find(it => {
                          return (
                            String(it.id) ===
                            String(colunaEditing.associacao?.tabelaOrigem)
                          );
                        })?.colunas?.length ?? 0) === 0
                      }
                    >
                      <Row className="mt-2 mb-2 dados-filtro">
                        <Col sm="12">
                          <p>
                            Filtrar dados mostrados pela{' '}
                            <i>
                              {listaTabelaImportacao.find(
                                it =>
                                  it.id ===
                                  colunaEditing.associacao?.tabelaOrigem,
                              )?.nome ?? ''}
                            </i>
                          </p>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <div>
                              <small>
                                Coluna de{' '}
                                <i>
                                  {listaTabelaImportacao.find(
                                    it =>
                                      it.id ===
                                      colunaEditing.associacao?.tabelaOrigem,
                                  )?.nome ?? ''}
                                </i>{' '}
                                usada para filtro
                              </small>

                              <Input
                                placeholder="Selecione a Coluna"
                                type="select"
                                value={colunaEditing.associacao?.colunaFiltro}
                                onChange={(event: any) => {
                                  setColunaEditing({
                                    ...colunaEditing,
                                    associacao: {
                                      ...colunaEditing.associacao,
                                      colunaFiltro: event.target.value,
                                    },
                                  });
                                }}
                              >
                                <option value="">Selecione</option>
                                {listaTabelaImportacao
                                  .find(it => {
                                    return (
                                      String(it.id) ===
                                      String(
                                        colunaEditing.associacao?.tabelaOrigem,
                                      )
                                    );
                                  })
                                  ?.colunas?.map(item => (
                                    <option value={item}>{item}</option>
                                  ))}
                              </Input>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <div>
                              <small>
                                Questão onde estará o valor de comparação
                              </small>
                              <Input
                                placeholder="Selecione a questao"
                                type="select"
                                value={colunaEditing.associacao?.questaoFiltro}
                                onChange={(event: any) => {
                                  setColunaEditing({
                                    ...colunaEditing,
                                    associacao: {
                                      ...colunaEditing.associacao,
                                      questaoFiltro: Number(event.target.value),
                                    },
                                  });
                                }}
                              >
                                <option value="">Selecione</option>
                                {listaTabelaGenericaColuna
                                  .filter(item =>
                                    !mostrarDesativados
                                      ? item.ativo === true
                                      : true,
                                  )
                                  .filter(it => it.id !== colunaEditing.id)
                                  .map(item => (
                                    <option value={item.id}>{item.nome}</option>
                                  ))}
                              </Input>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      sm="4"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaCamposNumericos ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Valor Mínimo (zero se não houver)</small>
                        <Input
                          placeholder="Valor Mínimo"
                          value={colunaEditing.regramento?.valorMinimo}
                          type="number"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                valorMinimo: Number(text.target.value),
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      sm="4"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaCamposNumericos ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Valor Máximo (zero se não houver)</small>
                        <Input
                          placeholder="Valor Máximo"
                          value={colunaEditing.regramento?.valorMaximo}
                          type="number"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                valorMaximo: Number(text.target.value),
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      sm="4"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaCamposNumericos ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Casas Decimais (zero se não houver)</small>
                        <Input
                          placeholder="Casas Decimais"
                          value={colunaEditing.regramento?.casasDecimais}
                          type="number"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                casasDecimais: Number(text.target.value),
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2 mb-2 dados-filtro">
                    <Col sm="12">
                      <p>
                        Regra para exibição da questão
                        <br />
                        <small>
                          <i>
                            Importante: A validação utiliza o nome literal das
                            outras colunas. A desativação ou troca do nome de
                            uma coluna usada em dependência, pode ocasionar
                            erros indesejados.
                          </i>
                        </small>
                      </p>
                    </Col>
                    <Col sm="12">
                      <FormGroup className="mb-3">
                        <Mentions
                          style={{ width: '100%', height: '100px' }}
                          placeholder="Entre com # para visualizar as sugestões"
                          prefix={[prefixColumns]}
                          onSearch={onSearch}
                          value={colunaEditing?.regraDependencia ?? ''}
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regraDependencia: text ?? '',
                            })
                          }
                        >
                          {renderListaSugestoes(Number(colunaEditing.id ?? 0))}
                        </Mentions>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6">
                      <FormGroup
                        className="mb-3"
                        hidden={!tabelaGenerica.disponibilizarRegras}
                      >
                        <small>Variável para uso em Regras *</small>
                        <Input
                          placeholder="Variável para uso em Regras"
                          value={colunaEditing.labelRegras ?? ''}
                          type="text"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              labelRegras: text.target.value,
                              labelValido: false,
                            })
                          }
                        />
                        <div>
                          {colunaEditing.labelRegras && (
                            <small title="Como a variável pode ser chamada na regra">
                              <i>{`#${tabelaGenerica.labelRegras}!${colunaEditing.labelRegras}#`}</i>
                            </small>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardFooter>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="default"
                    type="button"
                    disabled={isSaving || loading}
                    onClick={handleCloseModalColuna}
                  >
                    Fechar
                  </Button>
                  <Button
                    className="my-1"
                    color="primary"
                    type="button"
                    disabled={isSaving || loading}
                    onClick={handleSaveColuna}
                  >
                    Salvar
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Modal>

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">
                      Colunas da Tabela: {stateTabela?.nome}
                    </h3>
                    <small>{stateTabela?.descricao}</small>
                  </Col>
                  <Col sm="3">
                    <FormControlLabel
                      className="float-right"
                      control={
                        <Checkbox
                          checked={mostrarDesativados}
                          onChange={event =>
                            setMostrarDesativados(event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Mostrar desativados"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                hidden
              >
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th hidden={!tabelaGenerica.disponibilizarRegras}>
                      Identificação nas Regras
                    </th>
                    <th hidden={!mostrarDesativados}>Ativo</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {listaTabelaGenericaColuna
                    .filter(item =>
                      !mostrarDesativados ? item.ativo === true : true,
                    )
                    .map(item => (
                      <tr key={item.id}>
                        <td className="py-2">{item.nome}</td>
                        <td className="py-2">
                          {listaTipoCampo.find(it => it.sigla === item.tipo)
                            ?.nome ?? ''}
                        </td>
                        <td
                          className="py-2"
                          hidden={!tabelaGenerica.disponibilizarRegras}
                        >
                          {item.labelRegras ?? ''}
                        </td>
                        <td className="py-2" hidden={!mostrarDesativados}>
                          <div
                            className={`badge badge-${
                              item.ativo ? 'success' : 'danger'
                            }`}
                          >
                            {item.ativo ? 'Sim' : 'Não'}
                          </div>
                        </td>
                        <td>
                          <div className="float-right">
                            {item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="warning"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleUpdate(item);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="danger"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </>
                            )}
                            {!item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="info"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleAtivar(item.id);
                                  }}
                                >
                                  <FaCheckCircle />
                                </Button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <div>{montarLista()}</div>

              {ordemAlterada && (
                <FloatingButton
                  title="Salvar ordenação"
                  onClick={() => updateOrdem()}
                >
                  <FaCheckCircle />
                </FloatingButton>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TabelaGenericaColuna;
