import { CardHeader, Modal, ModalBody } from 'reactstrap';

interface ModalLog {
  title: string;
  rows: string[];
  modalState: boolean;
  toggle: () => void;
}

export default function GenericModal({
  title,
  rows,
  modalState,
  toggle,
}: ModalLog): JSX.Element {
  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={modalState}
      toggle={toggle}
      scrollable
    >
      <CardHeader className="bg-transparent">
        <h3 className="mb-0">{title}</h3>
      </CardHeader>
      <ModalBody>
        {rows.map((row, index) => (
          <div key={index}>{row}</div>
        ))}
      </ModalBody>
    </Modal>
  );
}
