/* eslint-disable import/prefer-default-export */
import { Col } from 'reactstrap';
import styled from 'styled-components';

export const RowFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0.5rem 2.5rem -1rem;
`;

export const GrupoRow = styled.div`
  border: 1px solid #e0e0e0;
  margin: 0.5rem 0 0.5rem 0;
  padding: 0.8rem;
`;
export const SelecaoRelacaoContainer = styled(Col)`
  background-color: #fff;
  margin-top: 2rem;
`;
