import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import MatrizResponsabilidadeEspelharDe from 'staticData/MatrizResponsabilidadeEspelharDe';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Swal from 'sweetalert2';
import api from 'services/api';
import ModelMatrizResponsabilidade from '../../../models/ConfiguradorMatrizResponsabilidade';
import './style.css';

const ConfiguradorMatrizResponsabilidade: React.FC = () => {
  const [listaMatriz, setListaMatriz] = useState(
    [] as ModelMatrizResponsabilidade[],
  );

  const [listaTiposMatriz, setListaTiposMatriz] = useState(
    [] as { sigla: string; valor: string }[],
  );

  const [matrizEditing, setMatrizEditing] = useState(
    {} as ModelMatrizResponsabilidade,
  );

  const [matrizEspelho, setMatrizEspelho] = useState<any[]>([]);
  const [formModalState, setFormModalState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mostrarInativos, setMostrarInativos] = useState(false);

  async function buscarMatriz() {
    const response = await api.get('/matriz-responsabilidade');

    setListaMatriz(response.data);
  }

  async function carregarDadosEspelho() {
    setMatrizEspelho(MatrizResponsabilidadeEspelharDe);
  }

  async function buscarTipoMatriz() {
    const response = await api.get('/matriz-responsabilidade/tipos');

    setListaTiposMatriz(response.data);
  }

  useEffect(() => {
    carregarDadosEspelho();
    buscarTipoMatriz();
    buscarMatriz();
  }, []);

  async function handleInsert() {
    setMatrizEditing({
      isAtivo: true,
    } as ModelMatrizResponsabilidade);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemCredito: ModelMatrizResponsabilidade) {
    setMatrizEditing(itemCredito);

    setFormModalState(!formModalState);
  }

  async function handleSaveLinhaCredito() {
    // eslint-disable-next-line prettier/prettier
   const { descricao } = matrizEditing;

    if (!descricao) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);
    if (matrizEditing.id) {
      await api.put(`/matriz-responsabilidade/${matrizEditing.id}`, {
        id: matrizEditing.id,
        descricao: matrizEditing.descricao,
        isAtivo: matrizEditing.isAtivo,
        valorDefault: matrizEditing.valorDefault,
        espelharValorDe: matrizEditing.espelharValorDe ?? '',
      });
    } else {
      await api.post(`/matriz-responsabilidade/`, {
        descricao: matrizEditing.descricao,
        isAtivo: matrizEditing.isAtivo,
        valorDefault: matrizEditing.valorDefault,
        espelharValorDe: matrizEditing.espelharValorDe ?? '',
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setIsSaving(false);
    setMatrizEditing({} as ModelMatrizResponsabilidade);
    buscarMatriz();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Informações do Registro</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Descrição *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={matrizEditing.descricao}
                            type="text"
                            onChange={text =>
                              setMatrizEditing({
                                ...matrizEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Valor Default </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Valor default"
                            type="select"
                            value={matrizEditing.valorDefault ?? ''}
                            onChange={text =>
                              setMatrizEditing({
                                ...matrizEditing,
                                valorDefault: text.target.value ?? '',
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {listaTiposMatriz.map(item => (
                              <option value={item.sigla}>{item.valor}</option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Ativo?</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Ativo"
                            type="select"
                            value={matrizEditing.isAtivo ? 'S' : 'N'}
                            onChange={text =>
                              setMatrizEditing({
                                ...matrizEditing,
                                isAtivo: text.target.value === 'S',
                              })
                            }
                          >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Espelhar valores de:</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Espelhar valores de:"
                            type="select"
                            value={matrizEditing.espelharValorDe}
                            onChange={text =>
                              setMatrizEditing({
                                ...matrizEditing,
                                espelharValorDe: text.target.value,
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {matrizEspelho.map((item: any) => (
                              <option value={item.sigla}>
                                {item.descricao}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveLinhaCredito}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Matriz de Responsabilidade</h3>
              </CardHeader>
              <Row>
                <Col sm="9" />
                <Col sm="3">
                  <FormControlLabel
                    className="float-right"
                    control={
                      <Checkbox
                        checked={mostrarInativos}
                        onChange={event =>
                          setMostrarInativos(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Mostrar Inativos"
                  />
                </Col>
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Descrição</th>
                    <th>Valor Default</th>
                    <th>Ativo?</th>
                    <th>Espelhar valores de</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {listaMatriz
                    .filter(item => {
                      if (mostrarInativos) return item;
                      return item.isAtivo === true;
                    })
                    .map(itemMatriz => (
                      <tr
                        key={itemMatriz.id}
                        className={`style_${
                          itemMatriz.isAtivo ? 'ativado' : 'desativado'
                        }`}
                      >
                        <td className="py-2">{itemMatriz.descricao}</td>
                        <td className="py-2">
                          {itemMatriz.valorDefault
                            ? listaTiposMatriz.find(
                                item => item.sigla === itemMatriz.valorDefault,
                              )?.valor
                            : ''}
                        </td>
                        <td className="py-2">
                          {itemMatriz.isAtivo ? 'Sim' : 'Não'}
                        </td>
                        <td className="py-2">
                          {MatrizResponsabilidadeEspelharDe?.find(
                            modelo =>
                              modelo.sigla.toUpperCase() ===
                                itemMatriz?.espelharValorDe?.toUpperCase() ??
                              '',
                          )?.descricao ?? ''}
                        </td>
                        <td className="py-2">
                          <div className="float-right">
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              disabled={isSaving}
                              onClick={() => {
                                handleUpdate(itemMatriz);
                              }}
                            >
                              <FaEdit />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorMatrizResponsabilidade;
