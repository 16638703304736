import {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  CardHeader,
  Col,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';

import { CircularProgress, Tab, Tabs, Tooltip } from '@material-ui/core';
import { useQuery } from 'react-query';

import api from 'services/api';
import PropostaModel from 'models/Proposta';
import { PropostaVersao } from 'models/PropostaVersao';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import { PropostaStatus } from 'models/PropostaStatus';

import UserSmallHeader from 'components/Headers/UserSmallHeader';
import TabPanel from 'components/TabPanel';
import AppBar from 'components/AppBar';
import { FaHistory, FaPrint, FaUser } from 'react-icons/fa';

import ModalLog from 'components/ModalLog';
import { PropostaMeioPagamento } from 'models/PropostaMeioPagamento';
import { useAuth } from 'hooks/auth';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { ProdutoProposta } from 'models/ProdutoProposta';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { addDays } from 'date-fns';
import { PropostaLocalObra } from 'models/PropostaLocalObra';
import ModalPessoaGrupo from 'views/components/ModalPessoaGrupo';
import Materiais, { MateriaisContext } from './components/Materiais';
import LocalObra from './components/Obra/LocalObra';
import MaoDeObra from './components/Obra/MaoDeObra';
import ValoresPorConfiguracao from './components/Obra/ValoresPorConfiguracao';
import Parcelas from './components/Parcelas';
import Resultados from './components/Resultados';
import Aprovacao from './components/Aprovacao';
import LogsCalculoServico from './components/LogsCalculoServico';
import Observacao from './components/Observacao';
import ValoresAprovacao from './components/ValoresAprovacao';
import ModalValoresAprovacao from './components/ModalValoresAprovacao';
import MatrizResponsabilidade from './components/MatrizResponsabilidade';
import PosicaoGeografica from './components/Obra/PosicaoGeografica';
import ReportTemplate from '../AtaProposta/ReportTemplate';

interface LocationState {
  proposta: PropostaModel;
  propostaVersao: PropostaVersao;
  acessarAprovacao: boolean | false;
}

interface PropostaContext {
  propostaVersao: PropostaVersao;
  propostaLocalObra: PropostaLocalObra;
  setPropostaVersao: (propostaVersao: PropostaVersao) => void;
  setPropostaLocalObra: (propostaLocalObra: PropostaLocalObra) => void;
  permiteModificacao: boolean;
  formatoMaoDeObra: string;
  setFormatoMaoDeObra: (valor: string) => void;
  condicaoFrete: string;
  setCondicaoFrete: (valor: string) => void;
  carregaPropostaVersao: () => void;
  propostaConfiguracoes: PropostaConfiguracao[];
  setPropostaConfiguracoes: (
    propostaConfiguracoes: PropostaConfiguracao[],
  ) => void;
  meioPagamentos: PropostaMeioPagamento[];
  meioPagamentoIdSelecionado: number | undefined;
  setMeioPagamentoIdSelecionado: (value: number | undefined) => void;
  acessoLimitado: boolean;
  isObraExterior: boolean;
  setIsObraExterior: (value: boolean) => void;
  setTriggerMaterialCheck: (value: boolean) => void;
  isMaterialValid: boolean;
  atualizarTelaProposta: (value: boolean) => void;
  isIsentoComissao?: boolean;
  setIsIsentoComissao?: (value: boolean) => void;
  bloquearLocal: boolean;
  setBloquearLocal: (value: boolean) => void;
  baixarAta: boolean;
  setBaixarAta: (value: boolean) => void;
  configuracaoId: number | undefined;
  setConfiguracaoId: (value: number | undefined) => void;
  setPrecosDesatualizados: (value: boolean) => void;
}

const ID_STATUS_EM_APROVACAO = 6;

export const PropostaContext = createContext({} as PropostaContext);

const STATUS_APROVADO_ID = 3;
export default function Proposta(): JSX.Element {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { usuario } = useAuth();
  const [acessoLimitado, setAcessoLimitado] = useState(true);
  const [isObraExterior, setIsObraExterior] = useState(false);
  const [acessoLimitadoAta, setAcessoLimitadoAta] = useState(
    {} as ValidarAcessoPrograma,
  );
  const {
    proposta,
    propostaVersao: propostaVersaoLocation,
    acessarAprovacao,
  } = location.state;
  const [propostaVersao, setPropostaVersao] = useState<PropostaVersao>(
    propostaVersaoLocation,
  );
  const [propostaLocalObra, setPropostaLocalObra] = useState(
    {} as PropostaLocalObra,
  );
  const [propostaConfiguracoes, setPropostaConfiguracoes] = useState<
    PropostaConfiguracao[]
  >([]);
  const [carregandoPropostaConfiguracoes, setCarregandoPropostaConfiguracoes] =
    useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [maoDeObraAtualizada, setMaoDeObraAtualizada] = useState(false);
  const [statusIdAtual, setStatusIdAtual] = useState<number>(1);
  const [listaStatus, setListaStatus] = useState<PropostaStatus[]>([]);
  const [atualizandoStatus, setAtualizandoStatus] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);
  const [contemAbaAprovacao, setContemAbaAprovacao] = useState(false);
  const [formatoMaoDeObra, setFormatoMaoDeObra] = useState('');
  const [condicaoFrete, setCondicaoFrete] = useState(
    propostaVersao.condicaoFrete || '',
  );
  const [meioPagamentos, setMeioPagamentos] = useState<PropostaMeioPagamento[]>(
    [],
  );

  // Disparar processo validação material
  const [isMaterialValid, setIsMaterialValid] = useState(false);
  const [triggerMaterialCheck, setTriggerMaterialCheck] = useState(true);
  const [propostaComValor, setPropostaComValor] = useState(false);
  const [liberaImpressao, setLiberaImpressao] = useState(false);
  const [baixarAta, setBaixarAta] = useState(false);

  const [loadingProposta, setLoadingProposta] = useState(false);
  const [meioPagamentoIdSelecionado, setMeioPagamentoIdSelecionado] =
    useState<number>();
  const permiteModificacao = propostaVersao.status.indicPermiteModificar;
  const [acessoProgramaAprovacao, setAcessoProgramaAprovacao] = useState(
    {} as { podeAcessar: boolean },
  );
  const [produtoProposta, setProdutoProposta] = useState({} as ProdutoProposta);
  const [acessoLimitadoValorMaoObra, setAcessoLimitadoValorMaoObra] = useState(
    {} as ValidarAcessoPrograma,
  );
  const [sessaoId, setSessaoId] = useState('');
  const [configuracaoId, setConfiguracaoId] = useState<number>();
  const [modalCalculoLogVisivel, setModalCalculoLogVisivel] = useState(false);

  const [bloquearTudo, setBloquearTudo] = useState(false);
  const [configuracoesSemAcesso, setConfiguracoesSemAcesso] =
    useState<number[]>();
  const [isIsentoComissao, setIsIsentoComissao] = useState(false);
  const [bloquearLocal, setBloquearLocal] = useState(false);
  const [dataValidadeFormatada, setDataValidadeFormatada] = useState(
    new Date(),
  );
  const [liberaEtapa, setLiberaEtapa] = useState(true);
  const [bloquearProposta, setBloquearProposta] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfirmacaoDisparada, setModalConfirmacaoDisparada] =
    useState(false);
  const [precosDesatualizados, setPrecosDesatualizados] = useState(false);

  const [acessoProgramaAnalisador, setAcessoProgramaAnalisador] = useState(
    {} as ValidarAcessoPrograma,
  );

  const [mostrarBotaoMudarPessoa, setMostrarBotaoMudarPessoa] = useState(
    {} as ValidarAcessoPrograma,
  );

  const [modalManterPessoaGrupo, setModalManterPessoaGrupo] = useState(false);

  const [propostaCliente, setPropostaCliente] = useState<PropostaModel>(
    {} as PropostaModel,
  );

  const [
    acessoProgramaModificarStatusAprovada,
    setAcessoProgramaModificarStatusAprovada,
  ] = useState({} as ValidarAcessoPrograma);

  const { data: queryMeioPagamento } = useQuery(
    `proposta-meio-pagamento`,
    async () => {
      async function getMeioPagamentos(): Promise<PropostaMeioPagamento[]> {
        const response = await api.get('/proposta/meio-pagamento');
        return response.data;
      }

      const dataMeioPagamento = await getMeioPagamentos();
      return dataMeioPagamento;
    },
    { staleTime: 1000 * 60 * 10 },
  );

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (queryMeioPagamento) {
      setMeioPagamentos(queryMeioPagamento);
    }
  }, [queryMeioPagamento]);

  async function carregaConfiguracoes() {
    try {
      setCarregandoPropostaConfiguracoes(true);
      const response = await api.get(`/proposta/${proposta.id}`);
      const propostaCompleta = response.data as PropostaModel;
      const configuracoes = propostaCompleta.PropostaVersao.find(
        propostaVers => propostaVers.versao === propostaVersao.versao,
      )?.PropostaConfiguracao;
      setPropostaCliente(propostaCompleta);

      setIsIsentoComissao(propostaCompleta.usuario.isIsentoComissao);
      if (configuracoes) {
        setPropostaConfiguracoes(configuracoes);
        let sessao = '';
        let configId = 0;
        configuracoes.map(item => {
          sessao = String(item.fichaTecnica?.sessaoId);
          configId = item.orcamentoId;
          return <></>;
        });
        setSessaoId(sessao);
        setConfiguracaoId(configId);
      }
    } finally {
      setCarregandoPropostaConfiguracoes(false);
      setMaoDeObraAtualizada(false);
    }
  }

  const { data: queryListaStatus } = useQuery(
    `proposta-status`,
    async () => {
      async function getStatus(): Promise<PropostaStatus[]> {
        const response = await api.get('/proposta/status');
        return response.data;
      }

      const dataStatus = await getStatus();
      return dataStatus;
    },
    { staleTime: 1000 * 60 * 10 },
  );

  async function carregaPropostaVersao() {
    try {
      setLoadingProposta(true);
      const response = await api.get(
        `/proposta/versao/${propostaVersaoLocation.id}`,
      );

      const preValorFinal = response.data?.valorFinal ?? 0;

      setPropostaComValor(preValorFinal && preValorFinal > 0);

      setPropostaVersao(response.data);
    } finally {
      setLoadingProposta(false);
    }
  }

  async function getPropostaBloqueada() {
    const response = await api.get(
      `/proposta/versao/${propostaVersaoLocation.id}/bloqueio`,
    );

    const varBloquearProposta = response.data?.bloquear ?? false;
    if (varBloquearProposta === true) setBloquearProposta(true);
  }

  async function carregaLocalObra() {
    if (propostaVersao.id) {
      const response = await api.get(
        `/proposta/${propostaVersao.id}/local-obra`,
      );
      setPropostaLocalObra(response.data);
    }
  }

  async function atualizarTelaProposta(mostrarAbaAprovacao: boolean) {
    try {
      if (acessoProgramaAprovacao) {
        await carregaPropostaVersao();
        setCurrentTab(0); // define a primeira aba

        // Caso tenha que mostrar, mostra apenas se tiver acesso
        if (mostrarAbaAprovacao === true)
          setContemAbaAprovacao(acessoProgramaAprovacao.podeAcessar === true);
        else setContemAbaAprovacao(false); // desativa a aba aprovação
      }
    } finally {
      setLoadingProposta(false);
      carregaLocalObra();
    }
  }

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 28,
        usuarioId: usuario.id,
      },
    });
    setAcessoLimitado(response.data);

    const responseAnalisador = await api.get(
      '/acesso-programa/acesso-programa',
      {
        params: {
          programaId: 84,
          usuarioId: usuario.id,
        },
      },
    );
    setAcessoProgramaAnalisador(responseAnalisador.data);

    const response2 = await api.get(
      `/proposta/versao/${propostaVersao.id}/aprovacao`,
      {},
    );

    setAcessoProgramaAprovacao({
      podeAcessar: response2.data.podeAcessar ?? false,
    });

    const response3 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 59,
        usuarioId: usuario.id,
      },
    });
    setAcessoLimitadoValorMaoObra(response3.data);

    const response4 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 68,
        usuarioId: usuario.id,
      },
    });
    setAcessoProgramaModificarStatusAprovada(response4.data);

    const response5 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 87,
      },
    });

    setMostrarBotaoMudarPessoa(response5.data);
    const responseAta = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 86,
        usuarioId: usuario.id,
      },
    });
    setAcessoLimitadoAta(responseAta.data);
  }

  async function getAcessoProposta() {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/acesso`,
    );

    const acessoProposta = response.data as {
      acesso: boolean;
      configuracoes: number[];
    };

    if (acessoProposta.acesso === false) {
      setBloquearTudo(true);
      setConfiguracoesSemAcesso(acessoProposta.configuracoes);
    }
  }

  async function getProdutoProposta() {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/produto`,
    );

    setProdutoProposta(response.data);
  }

  async function carregarParametros() {
    const response = await api.get('/parametros');
    let diasVigencia;
    if (response.data) {
      diasVigencia = response.data.validadeProposta;
      const dataValidade = addDays(new Date(), diasVigencia);
      setDataValidadeFormatada(dataValidade);
    }
  }

  useEffect(() => {
    carregarParametros();
  }, []);

  useEffect(() => {
    carregaLocalObra();
  }, []);

  useEffect(() => {
    getAcessoLimitadoPrograma();
    getAcessoProposta();
  }, []);

  useEffect(() => {
    getProdutoProposta();
  }, []);

  useEffect(() => {
    carregaPropostaVersao();
    getPropostaBloqueada();
  }, [propostaVersaoLocation]);

  useEffect(() => {
    if (propostaVersao) {
      setCondicaoFrete(propostaVersao.condicaoFrete || '');
    }
  }, [propostaVersao]);

  useEffect(() => {
    if (queryListaStatus) {
      setListaStatus(queryListaStatus);
    }
  }, [queryListaStatus]);

  useEffect(() => {
    carregaConfiguracoes();
    setStatusIdAtual(propostaVersao.status.id);
  }, [proposta, propostaVersao]);

  useEffect(() => {
    if (proposta.PropostaVersao.length > 1 || propostaVersao.versao > 1) {
      setBloquearLocal(true);
    } else {
      setBloquearLocal(false);
    }
  }, [proposta, propostaVersao]);

  useEffect(() => {
    if (maoDeObraAtualizada) {
      carregaConfiguracoes();
    }
  }, [maoDeObraAtualizada]);

  async function verificarImprimirProposta() {
    const responseBloqueioImpressao = await api.get(
      `/proposta/versao/${propostaVersao.id}/validar-impressao-proposta`,
    );

    if (responseBloqueioImpressao) {
      const imprimir = Boolean(
        responseBloqueioImpressao.data?.permiteImprimirProposta ?? false,
      );
      setLiberaImpressao(imprimir);
    } else {
      setLiberaImpressao(false);
    }
  }

  useEffect(() => {
    if (propostaVersao) {
      verificarImprimirProposta();
    }
  }, [propostaVersao]);

  useEffect(() => {
    async function carregarListaPreco() {
      const response = await api.get(
        `/proposta/versao/${propostaVersao.id}/validar-precos`,
      );

      setPrecosDesatualizados(response.data.desatualizado ?? false);
    }
    carregarListaPreco();
  }, []);

  async function confirmarAtualizacaoValores() {
    Swal.fire({
      icon: 'info',
      text: `Atualizando informações da proposta`,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
    });

    try {
      await api.post(`/proposta/versao/${propostaVersao.id}/reprocessar`);

      Swal.close();
    } catch (error: any) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
    } finally {
      setPropostaConfiguracoes([]);
      setPrecosDesatualizados(false);
      // await salvarValores();
      // await buscarConfiguracoes();
    }
  }

  useEffect(() => {
    if (precosDesatualizados === true) {
      confirmarAtualizacaoValores();
    }
  }, [precosDesatualizados]);

  useEffect(() => {
    if (triggerMaterialCheck === false && proposta && propostaVersao) {
      const meioPagamentoSelecionado = propostaVersao.meioPagamento?.id ?? 0;
      const formaPagamento = propostaVersao.PropostaVersaoParcelas;
      const materiaisValor = propostaVersao.valorFinal;
      const propostaLocal = propostaVersao.propostaId;
      let somaPercentual = 0;

      if (formaPagamento) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < formaPagamento.length; i++) {
          somaPercentual += formaPagamento[i].percentual ?? 0;
        }
        /*
      // Comentei essa forma usada com reduce, para usar caso o uso do "for" não funcione mais pra frente
      const somaPercentual = formaPagamento.reduce(function (
        totalizador,
        object,
      ) {
        return totalizador + (object.percentual ?? 0);
      },
      0);
      */
      }
      if (bloquearProposta) {
        setLiberaEtapa(false);
      } else if (
        propostaLocalObra?.inscricaoEstadual &&
        propostaLocalObra?.inscricaoEstadual !== '' &&
        propostaLocalObra?.inscricaoEstadual !== null &&
        propostaLocalObra?.inscricaoEstadual !== undefined &&
        propostaVersao.condicaoFrete &&
        propostaVersao.condicaoFrete !== '' &&
        propostaVersao.condicaoFrete !== null &&
        propostaVersao.condicaoFrete !== undefined
      ) {
        setLiberaEtapa(true);
      } else {
        setLiberaEtapa(false);
      }
    }
  }, [propostaVersao, triggerMaterialCheck, proposta, propostaLocalObra]);

  async function atualizaStatus(id: number): Promise<void> {
    try {
      setAtualizandoStatus(true);
      await api.put(`/proposta/versao/${propostaVersao.id}/status/${id}`, {
        dataValidade: dataValidadeFormatada,
        modalConfirmacao: modalConfirmacaoDisparada ? 'S' : 'N',
      });
    } finally {
      setAtualizandoStatus(false);
      await carregaPropostaVersao();
    }
  }

  async function dispararMensagemMudancaStatus(novoStatusId: number) {
    if (novoStatusId !== 3)
      return {
        prosseguir: true,
        statusUsar: novoStatusId,
      };

    // Vai disparar uma modal de confirmação
    const mudancaStatus = await Swal.fire({
      title: `Atenção`,
      html: `<div style='text-align: justify!important; text-justify: inter-word;'>
      Ao mudar o status para Aprovado, será disparado processo de Aprovação, onde a proposta será analisada e aprovada ou reprovada. O status da proposta ficará como 'Em Aprovação final'. Deseja prosseguir?</div>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, prosseguir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    });

    return {
      prosseguir: mudancaStatus.isConfirmed,
      statusUsar: novoStatusId,
    };
  }

  async function handleChangeStatusAtual(novoStatusId: number) {
    // Vai disparar uma modal de confirmação
    /*
    try {
      // eslint-disable-next-line no-alert
      ReportTemplate();
    } catch {
      // eslint-disable-next-line no-alert
      alert('Erro');
    }
    */
    const retorno = await dispararMensagemMudancaStatus(novoStatusId);

    if (retorno.prosseguir) {
      if (retorno.statusUsar === STATUS_APROVADO_ID) {
        const responseBloqueioStatus = await api.get(
          `/proposta/versao/${propostaVersao.id}/status/${retorno.statusUsar}`,
        );

        const bloqueioStatus = responseBloqueioStatus.data as {
          bloquear: boolean;
          motivo?: string;
        };

        if (
          bloqueioStatus.bloquear === true &&
          bloqueioStatus.motivo === 'MATRIZ_DATAS_NAO_PREENCHIDAS'
        ) {
          const valor = await Swal.fire({
            title: `Atenção`,
            html: `<div style='text-align: justify!important; text-justify: inter-word;'>
            Algumas datas da matriz de responsabilidade não foram preenchidas.
            <br />Ao prosseguir, a proposta ficará bloqueada e nenhuma modificação futura será possível. Deseja prosseguir?</div>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, prosseguir`,
            confirmButtonColor: '#d33',
            cancelButtonText: `Não`,
          });

          if (valor.isConfirmed) {
            setModalConfirmacaoDisparada(true);
            setIsModalOpen(!isModalOpen);
          }
        } else {
          setModalConfirmacaoDisparada(false);
          setIsModalOpen(!isModalOpen);
        }
      } else {
        await atualizaStatus(retorno.statusUsar);
        setModalConfirmacaoDisparada(false);
        setStatusIdAtual(retorno.statusUsar);
      }
      setBaixarAta(true);
    }
  }

  function handleImprimeProposta() {
    if (loadingProposta || !liberaImpressao) {
      return;
    }

    history.push({
      pathname: '/admin/proposta-impressao-novo',
      state: { proposta, propostaVersao, produtoProposta },
    });

    /* if (produtoProposta.isIrrigacao)
      history.push({
        pathname: '/admin/proposta-impressao-novo',
        state: { proposta, propostaVersao, produtoProposta },
      });
    else
      history.push({
        pathname: '/admin/proposta-impressao-geral',
        state: { proposta, propostaVersao, produtoProposta },
      }); */
  }

  function handleGerarGNF() {
    history.push({
      pathname: '/admin/proposta-gerar-gnf',
      state: { proposta, propostaVersao },
    });
  }

  async function carregarInfoMaterialValido() {
    const response = await api.get(
      `/proposta/versao/${propostaVersaoLocation.id}/materiais-valor`,
    );

    const { materiaisValidos } = response.data;

    setIsMaterialValid(materiaisValidos);
    setTriggerMaterialCheck(false);
  }

  useEffect(() => {
    if (triggerMaterialCheck) {
      carregarInfoMaterialValido();
      carregaPropostaVersao();
    }
  }, [triggerMaterialCheck]);

  useEffect(() => {
    if (acessoProgramaAprovacao) {
      setContemAbaAprovacao(acessoProgramaAprovacao.podeAcessar === true);
    }
  }, [acessoProgramaAprovacao]);

  async function onSavePostback() {
    // O processo de salvar informações deu certo.
    // Como ele dispara apenas na troca para APROVADA, agora preciso disparar manualmente o processo

    await atualizaStatus(STATUS_APROVADO_ID);
    setStatusIdAtual(STATUS_APROVADO_ID);

    carregaPropostaVersao();
  }

  useEffect(() => {
    if (propostaVersao) {
      setMeioPagamentoIdSelecionado(propostaVersao?.meioPagamentoId);
    }
  }, [propostaVersao]);

  async function handleGerarAta() {
    const response = await api.post(`/ata-briefing/gerar-ata`, {
      propostaVersaoId: propostaVersao.id,
    });

    history.push({
      pathname: '/admin/ata-proposta',
      state: {
        proposta,
        propostaVersao,
        meioPagamentos,
        meioPagamentoIdSelecionado,
        propostaConfiguracoes,
        produtoProposta,
      },
    });
  }

  async function handleSaveModalPessoaGrupo(itemSelecionado: string) {
    // await getOrcamentoVersao();

    if (String(propostaCliente.pessoa?.codigoDomsge) !== itemSelecionado) {
      try {
        toast.loading('Salvando...');
        await api.put(`/pessoa/atualizar-pessoa-registro`, {
          propostaId: proposta.id,
          propostaVersaoId: propostaVersao.id,
          pessoaSgeId: itemSelecionado,
        });

        carregaPropostaVersao();
        carregaConfiguracoes();

        toast.dismiss();
        toast.success('Registro atualizado');
      } catch (ex: any) {
        toast.dismiss();
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: ex?.response?.data?.message,
        });
      }
    }
  }

  return (
    <>
      <UserSmallHeader />
      <ModalLog
        nomeChave="proposta_versao_id"
        valorChave={propostaVersao.id}
        titulo="Logs da proposta"
        modalState={modalStatusLogVisivel}
        toggle={() => setModalStatusLogVisivel(false)}
      />

      <CardHeader>
        <Row>
          <Col md="4" sm="4" xs="12" lg="4">
            <h4 className="mb-0">Proposta {proposta.id}</h4>{' '}
            <small className="mb-0">
              Versão {propostaVersao.versao} | Configurações{' '}
              {propostaConfiguracoes
                .map(config => config.orcamentoId)
                .join(', ')}
            </small>
          </Col>
          <Col md="3" sm="3" xs="12" lg="4">
            <small className="mb-0">
              <strong>Gnf: </strong>
              {proposta.codigoOportunidadeDomsge}
              <br />
              <span hidden={!propostaCliente.pessoaGnf}>
                {`${propostaCliente.pessoaGnf?.codigoDomsge} - ${propostaCliente.pessoaGnf?.razaoSocial}`}
              </span>
              <br />
              <small
                hidden={
                  (propostaCliente.pessoaGnf?.id ?? 0) ===
                  (propostaCliente.pessoa?.id ?? 0)
                }
              >
                {`${propostaCliente.pessoa?.codigoDomsge} - ${propostaCliente.pessoa?.razaoSocial}`}
              </small>
            </small>
          </Col>
          <Col md="3" sm="3" xs="12" lg="2">
            <div className="d-flex mt-2">
              {atualizandoStatus && (
                <CircularProgress
                  className="mt-2 mr-3"
                  color="inherit"
                  size={18}
                />
              )}
              <Input
                bsSize="sm"
                type="select"
                disabled={atualizandoStatus || bloquearTudo}
                value={statusIdAtual}
                onChange={event =>
                  handleChangeStatusAtual(Number(event.target.value))
                }
              >
                {listaStatus.map(status => (
                  <option
                    key={status.id}
                    value={status.id}
                    disabled={!status.indicPermiteSelecao}
                  >
                    {status.descricao}
                  </option>
                ))}
              </Input>
            </div>
          </Col>
          <Col md="2" sm="2" xs="12" lg="2">
            {acessoLimitadoAta.temAcesso && (
              <div className="mt-2 float-left">
                <Button
                  size="sm"
                  className="float-right"
                  color="warning"
                  outline
                  onClick={handleGerarAta}
                >
                  Ata
                </Button>
              </div>
            )}
            <div className="mt-2 float-right">
              <InputGroup>
                <Tooltip
                  className="ml-1"
                  title={
                    loadingProposta
                      ? 'Aguarde...'
                      : `Alterar cliente da proposta`
                  }
                >
                  <div>
                    <Button
                      size="sm"
                      className="float-right"
                      color="default"
                      outline
                      type="button"
                      hidden={
                        !mostrarBotaoMudarPessoa.temAcesso || bloquearProposta
                      }
                      onClick={() => setModalManterPessoaGrupo(true)}
                    >
                      <FaUser />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip
                  title={loadingProposta ? 'Aguarde...' : 'Geração GNF'}
                  className="ml-1"
                  hidden={statusIdAtual !== STATUS_APROVADO_ID}
                >
                  <Button
                    size="sm"
                    className="float-right"
                    color="default"
                    outline
                    disabled={
                      loadingProposta ||
                      atualizandoStatus ||
                      statusIdAtual !== STATUS_APROVADO_ID
                    }
                    onClick={handleGerarGNF}
                  >
                    Gerar GNF
                  </Button>
                </Tooltip>

                <Tooltip title="Logs" className="ml-1">
                  <Button
                    size="sm"
                    className="float-right"
                    color="default"
                    outline
                    onClick={() => setModalStatusLogVisivel(true)}
                  >
                    <FaHistory />
                  </Button>
                </Tooltip>

                <Tooltip
                  className="ml-1"
                  title={
                    loadingProposta
                      ? 'Aguarde...'
                      : `${
                          loadingProposta || !liberaImpressao
                            ? `Não é possível imprimir a proposta. Abaixo algumas das possíveis causas. Uma ou mais configurações associadas possuem materiais sem valor, a proposta está sem valor, ou os dados básicos de local não informados ou meio de pagamento não está selecionado, ou a validação de materiais encontrou agrupadores obrigatórios sem item em alguma das configurações associadas. Proposta ficará bloqueada até que seja corrigido.`
                            : 'Imprimir proposta'
                        }`
                  }
                >
                  <div>
                    <Button
                      size="sm"
                      className="float-right"
                      color="default"
                      outline
                      onClick={handleImprimeProposta}
                    >
                      <FaPrint />
                    </Button>
                  </div>
                </Tooltip>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </CardHeader>
      {bloquearTudo && (
        <div style={{ padding: '50px' }}>
          <Alert color="warning" className="mt-2 ml-5 mr-5">
            <p>
              Essa proposta possui configurações que você não possui acesso.
            </p>
            <p>
              Solicite acesso a elas:{' '}
              <strong>{configuracoesSemAcesso?.join(',')}</strong>
            </p>
          </Alert>
        </div>
      )}
      {bloquearProposta === true && (
        <>
          <div style={{ padding: '5px' }}>
            <Alert color="warning" className="mt-2 ml-5 mr-5">
              Validação de materiais encontrou agrupadores obrigatórios sem item
              em alguma das configurações associadas. Proposta ficará bloqueada
              até que seja corrigido.
            </Alert>
          </div>
        </>
      )}

      <AppBar>
        <Tabs
          value={currentTab}
          hidden={bloquearTudo}
          onChange={(_, newCurrentTab) => setCurrentTab(newCurrentTab)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {contemAbaAprovacao === true && (
            <Tab label="Aprovação" style={{ fontWeight: 600 }} />
          )}
          <Tab label="Obra" style={{ fontWeight: 600 }} />
          {liberaEtapa && (
            <Tab
              label="Materiais"
              style={{
                fontWeight: 600,
              }}
            />
          )}
          {liberaEtapa && (
            <Tab
              label="Parcelas"
              style={{
                fontWeight: 600,
              }}
            />
          )}
          {liberaEtapa && (
            <Tab
              label="Matriz Responsabilidade"
              style={{
                fontWeight: 600,
              }}
            />
          )}
          {liberaEtapa && acessoProgramaAnalisador.temAcesso === true && (
            <Tab
              label="Analisador"
              style={{
                fontWeight: 600,
              }}
            />
          )}
        </Tabs>
      </AppBar>

      {mostrarBotaoMudarPessoa.temAcesso && (
        <ModalPessoaGrupo
          pessoaId={propostaCliente.pessoa?.codigoDomsge ?? ''}
          modalState={modalManterPessoaGrupo}
          onSave={handleSaveModalPessoaGrupo}
          toggle={() => setModalManterPessoaGrupo(false)}
        />
      )}

      <div hidden={bloquearTudo}>
        <PropostaContext.Provider
          value={{
            propostaVersao,
            setPropostaVersao,
            propostaLocalObra,
            setPropostaLocalObra,
            permiteModificacao,
            formatoMaoDeObra,
            setFormatoMaoDeObra,
            condicaoFrete,
            setCondicaoFrete,
            carregaPropostaVersao,
            propostaConfiguracoes,
            setPropostaConfiguracoes,
            meioPagamentos,
            meioPagamentoIdSelecionado,
            setMeioPagamentoIdSelecionado,
            acessoLimitado,
            setIsObraExterior,
            isObraExterior,
            setTriggerMaterialCheck,
            isMaterialValid,
            atualizarTelaProposta,
            isIsentoComissao,
            setIsIsentoComissao,
            bloquearLocal,
            setBloquearLocal,
            baixarAta,
            setBaixarAta,
            configuracaoId:
              Number(configuracaoId) ??
              propostaConfiguracoes.find(item => item.orcamentoId),
            setConfiguracaoId,
            setPrecosDesatualizados,
          }}
        >
          <ModalValoresAprovacao
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            propostaVersaoId={propostaVersao.id}
            onSavePostback={onSavePostback}
          />

          {contemAbaAprovacao === true && (
            <TabPanel value={currentTab} index={0}>
              <Aprovacao propostaVersao={propostaVersao} />
            </TabPanel>
          )}

          <TabPanel
            value={currentTab}
            index={contemAbaAprovacao === true ? 1 : 0}
          >
            <ValoresAprovacao
              propostaVersaoId={propostaVersao.id}
              acessoPrograma={acessoProgramaModificarStatusAprovada}
            />
            <Observacao
              proposta={proposta}
              propostaVersaoId={propostaVersao.id}
            />
            <LocalObra
              proposta={proposta}
              propostaVersaoId={propostaVersao.id}
            />
            <PosicaoGeografica
              proposta={proposta}
              propostaVersaoId={propostaVersao.id}
              produtoProposta={produtoProposta}
            />
            <MaoDeObra
              onMaoDeObraAtualizada={setMaoDeObraAtualizada}
              orcamentoId={Number(configuracaoId)}
              configuracoes={propostaConfiguracoes
                .map(config => config.orcamentoId)
                .join(', ')}
            />
            <ValoresPorConfiguracao
              formatoMaoDeObra={formatoMaoDeObra}
              propostaConfiguracoes={propostaConfiguracoes}
              carregandoPropostaConfiguracoes={carregandoPropostaConfiguracoes}
              setModalCalculoLogVisivel={setModalCalculoLogVisivel}
              setConfiguracaoId={setConfiguracaoId}
              configuracaoId={Number(configuracaoId)}
              acessoLimitado={acessoLimitado}
              acessoLimitadoValorMaoObra={acessoLimitadoValorMaoObra}
            />

            <LogsCalculoServico
              orcamentoId={
                Number(configuracaoId) ??
                propostaConfiguracoes.find(item => item.orcamentoId)
              }
              exibeLogs={modalCalculoLogVisivel}
            />
          </TabPanel>

          <TabPanel
            value={currentTab}
            index={contemAbaAprovacao === true ? 2 : 1}
          >
            <Materiais propostaConfiguracoes={propostaConfiguracoes} />
          </TabPanel>

          <TabPanel
            value={currentTab}
            index={contemAbaAprovacao === true ? 3 : 2}
          >
            <Parcelas propostaVersao={propostaVersao} />
          </TabPanel>

          <TabPanel
            value={currentTab}
            index={contemAbaAprovacao === true ? 4 : 3}
          >
            <MatrizResponsabilidade />
          </TabPanel>

          {acessoProgramaAnalisador.temAcesso === true && (
            <TabPanel
              value={currentTab}
              index={contemAbaAprovacao === true ? 5 : 4}
            >
              <Resultados
                propostaVersao={propostaVersao}
                apenasSintetica={false}
              />
            </TabPanel>
          )}
        </PropostaContext.Provider>
      </div>
    </>
  );
}
