import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Row, Col } from 'reactstrap';

import FichaTecnica from 'models/FichaTecnica';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import { PropostaVersao } from 'models/PropostaVersao';

import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';
import OrcamentoProduto from 'models/OrcamentoProduto';
import {
  Atividade,
  Container,
  Descricao,
  HorizontalRule,
  Item,
  Typography,
  DecricaoTotal,
  DecricaoTotalDestaque,
} from './styles';

interface AtividadeItem {
  id: number;
  descricao: string;
  materiais: {
    id: number;
    descricao: string;
    quantidade: number;
  }[];
  questoes: {
    id: number;
    descricao: string;
    resposta: string;
  }[];
}

interface Equipamento {
  propostaConfiguracao: PropostaConfiguracao;
  isPropostaExterior: boolean;
  valorDolar: number;
  propostaVersao: PropostaVersao;
}

export default function Equipamento({
  propostaConfiguracao,
  isPropostaExterior,
  valorDolar,
  propostaVersao,
}: Equipamento): JSX.Element {
  const [fichaTecnica, setFichaTecnica] = useState<FichaTecnica>();
  const [orcamentoProduto, setOrcamentoProduto] = useState<OrcamentoProduto>();
  const [atividadesItens, setAtividadesItens] = useState<AtividadeItem[]>([]);
  const {
    fichaTecnica: fichaCadastrada,
    valorTotalItens,
    orcamento,
    orcamentoId,
  } = propostaConfiguracao;
  const pivoCentral = fichaCadastrada?.pivoCentral;
  const { codigoOportunidadeDomsge } = orcamento || {};
  const {
    ncm,
    finame,
    nome: descricaoProduto,
    nomeProposta,
  } = orcamentoProduto?.produto || {};
  const quantidadeLances = fichaTecnica?.FichaTecnicaLance?.reduce(
    (total, item) => total + item.quantidade,
    0,
  );
  const marca = 'Marca Fockink';
  const areaIrrigada = fichaTecnica?.areaTotalIrrigada;
  const sistema =
    fichaTecnica?.FichaTecnicaBombeamento?.[0]?.motor || 'NÃO INFORMADO';
  const raio = fichaTecnica?.raioEfetivoAreaIrrigada;
  // const [valorTotalGeral, setValorTotalGeral] = useState(0); Valor total por configuração
  const comprimentoTotalTubos = fichaCadastrada?.comprimentoTotalTubos;
  const raio1 = Number(fichaCadastrada?.aspersor1Raio ?? 0);
  const raio2 = Number(fichaCadastrada?.aspersor2Raio ?? 0);
  const incluirAspersorFinal = fichaCadastrada?.incluirAspersorFinal;
  const areaTotalIrrigadaCAF = fichaCadastrada?.areaTotalIrrigadaCAF;
  const areaEfetivaIrrigadaSAF = fichaCadastrada?.areaEfetivaIrrigadaSAF;
  const temMeioPagamentoFinanciado =
    propostaVersao.meioPagamento?.financiado ?? false;
  const recursoProprio = propostaVersao.meioPagamento?.descricao;
  const isDiferenteRecursoProprio =
    recursoProprio?.toUpperCase() !== 'Recurso Próprio'.toUpperCase();

  const mostrarTexto = temMeioPagamentoFinanciado;

  const usaAspersorFinal: boolean =
    String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
    String(incluirAspersorFinal).toUpperCase() === 'S';

  const valorArea = usaAspersorFinal
    ? Number(areaTotalIrrigadaCAF ?? 0)
    : Number(areaEfetivaIrrigadaSAF ?? 0);

  const { data: cacheFichaTecnica } = useQuery(
    `ficha-tecnica-orcamento-${orcamentoId}`,
    async () => {
      const response = await api.get(
        `/ficha-tecnica/orcamento-versao/${orcamentoId}`,
      );
      return response.data;
    },
  );

  useEffect(() => {
    if (cacheFichaTecnica) {
      setFichaTecnica(cacheFichaTecnica);
    }
  }, [cacheFichaTecnica]);

  const { data: cacheOrcamentoProduto } = useQuery(
    `configuracao-${orcamentoId}-produto`,
    async () => {
      const response = await api.get(`/orcamento/versao/${orcamentoId}`, {
        params: { completo: true },
      });
      const orcamentoProdutoData: OrcamentoProduto =
        response.data.OrcamentoProduto[0];
      return orcamentoProdutoData;
    },
  );

  /*
  -- Valor total por configuração
  async function carregaOrcamentoMateriais(): Promise<void> {
    const response = await api.get(`/orcamento/lista-materiais-agrupado-novo`, {
      params: { orcamentoId },
    });
    setValorTotalGeral(response.data.total);
    const valorGeral = formatCurrency(Number(response.data.total), 2);
    console.log(valorGeral);
  }

  useEffect(() => {
    if (orcamentoId) {
      carregaOrcamentoMateriais();
    }
  }, []);
  */

  useEffect(() => {
    if (cacheOrcamentoProduto) {
      setOrcamentoProduto(cacheOrcamentoProduto);
    }
  }, [cacheOrcamentoProduto]);

  const { data: cacheAtividadesItens } = useQuery(
    `atividade-item-configuracao-${orcamentoId}`,
    async () => {
      const response = await api.get(
        `/proposta-atividade-item/orcamento-id-novo/${orcamentoId}`,
      );
      return response.data;
    },
  );

  useEffect(() => {
    if (cacheAtividadesItens) {
      setAtividadesItens(cacheAtividadesItens);
    }
  }, [cacheAtividadesItens]);

  function AtividadesItens(): JSX.Element {
    return (
      <div className="ml-3">
        {atividadesItens.map((atividadeItem, index) => (
          <>
            <div className="mb-4">
              <Row>
                <Atividade>{`${index + 1} - ${
                  atividadeItem.descricao
                }`}</Atividade>
              </Row>
              <div className="m-3" hidden={!atividadeItem.questoes.length}>
                {atividadeItem.questoes.map(questao => (
                  <Row>
                    <Col sm="3">
                      <Item>{questao.descricao}:</Item>
                    </Col>
                    <Col sm="3">
                      <Item
                        style={{
                          fontStyle: 'italic',
                        }}
                      >
                        {questao.resposta || ''}
                      </Item>
                    </Col>
                  </Row>
                ))}
              </div>
              <div className="m-3" hidden={!atividadeItem.materiais.length}>
                <Row>
                  <Col sm="3">
                    <Item>Qtde</Item>
                  </Col>
                  <Col sm="8">
                    <Item>Descrição</Item>
                  </Col>
                </Row>
                {atividadeItem.materiais.map(material => (
                  <Row>
                    <Col sm="3">
                      <Item>{material.quantidade || 'Não possui'}</Item>
                    </Col>
                    <Col sm="8">
                      <Item>{material.descricao}</Item>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <HorizontalRule />
          </>
        ))}
      </div>
    );
  }

  function Totais(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <DecricaoTotal>Área total irrigada</DecricaoTotal>
          </Col>
          <Col>
            <DecricaoTotal>{valorArea} HA</DecricaoTotal>
          </Col>
        </Row>
        {isDiferenteRecursoProprio && (
          <>
            <Row>
              <Col>
                <DecricaoTotal>Classificação fiscal</DecricaoTotal>
              </Col>
              <Col>
                <DecricaoTotal>NCM {ncm}</DecricaoTotal>
              </Col>
            </Row>

            <Row>
              <Col>
                <DecricaoTotalDestaque>
                  Código de identificação do produto na Finame
                </DecricaoTotalDestaque>
              </Col>
              <Col>
                <DecricaoTotalDestaque>
                  {finame} {descricaoProduto}
                </DecricaoTotalDestaque>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <DecricaoTotal>Sistema</DecricaoTotal>
          </Col>
          <Col>
            <DecricaoTotal>{sistema}</DecricaoTotal>
          </Col>
        </Row>
      </>
    );
  }

  function formataValor(valor?: number | null) {
    if (!valor || !valorDolar) return formatCurrency(0, 2, isPropostaExterior);

    if (isPropostaExterior) {
      // Converte para Dólar
      const convertido = valor / valorDolar;
      return formatCurrency(convertido, 2, true);
    }
    return formatCurrency(valor);
  }

  function PrecoTotal(): JSX.Element {
    return (
      <Row className="my-3">
        <Col>
          <Typography variant="h6" className="float-left">
            <strong>PREÇO TOTAL DO SISTEMA</strong>
          </Typography>
        </Col>
        <Col>
          <Typography variant="h6" className="float-right">
            <strong>{formatCurrency(propostaVersao?.valorFinal, 2)}</strong>
          </Typography>
        </Col>
      </Row>
    );
  }

  return (
    <Container>
      <Typography variant="h5" className="mb-4">
        <i>{descricaoProduto}</i>
      </Typography>

      <div className="mb-3">
        <Descricao>
          01 {nomeProposta ?? descricaoProduto}, {marca}, sistema {sistema} com{' '}
          {quantidadeLances} torres de sustentação, raio{' '}
          {Number(comprimentoTotalTubos ?? 0) + (raio1 > raio2 ? raio1 : raio2)}{' '}
          M para área irrigada de {valorArea} HA, composto de
        </Descricao>
      </div>

      <AtividadesItens />
      <Totais />
      {/* Comentando o valor total por configuração/equipamento que é o valorTotalGeral, para posteriormente usá-lo}
      <PrecoTotal />
  { */}
      <div className="mb-4" />
    </Container>
  );
}
