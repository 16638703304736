export function arredondaDecimais(
  valor?: number | null,
  casasDecimais?: number,
): number {
  if (!valor) return 0;

  let fatorArredondamento = 100; // 2 casas decimais

  if (casasDecimais === 3) {
    fatorArredondamento = 1000;
  }

  if (casasDecimais === 4) {
    fatorArredondamento = 10000;
  }

  return (
    Math.round((valor + Number.EPSILON) * fatorArredondamento) /
    fatorArredondamento
  );
}
