import { useEffect, useState } from 'react';
import { CardHeader } from 'reactstrap';

import { Tab, Tabs } from '@material-ui/core';

import UserSmallHeader from 'components/Headers/UserSmallHeader';
import TabPanel from 'components/TabPanel';
import AppBar from 'components/AppBar';
import LayoutAta from 'views/pages/AtaProposta/LayoutAta';
import GruposAta from 'views/pages/AtaProposta/GruposAta';
import NiveisIndicadoresAta from '../NiveisIndicadoresAta';

const AdmAta: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  return (
    <>
      <UserSmallHeader />
      <CardHeader />

      <AppBar>
        <Tabs
          value={currentTab}
          onChange={(_, newCurrentTab) => setCurrentTab(newCurrentTab)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Manutenção template Ata" style={{ fontWeight: 600 }} />
          <Tab label="Grupo de usuários da ata" style={{ fontWeight: 600 }} />
          <Tab
            label="Níveis de indicadores da ata"
            style={{ fontWeight: 600 }}
          />
        </Tabs>
      </AppBar>

      <div>
        <TabPanel value={currentTab} index={0}>
          <LayoutAta />
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <GruposAta />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <NiveisIndicadoresAta />
        </TabPanel>
      </div>
    </>
  );
};
export default AdmAta;
