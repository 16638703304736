import UserSmallHeader from 'components/Headers/UserSmallHeader';

import { createContext, Key, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import Orcamento from 'models/Orcamento';
import formatCurrency from 'utils/formatCurrency';
import api from 'services/api';
import { useQuery } from 'react-query';

import { Col, Row } from 'reactstrap';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import { CircularProgress } from '@material-ui/core';
import config from 'config';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';

import { ProdutoProposta } from 'models/ProdutoProposta';
import FichaTecnica from 'models/FichaTecnica';
import LogoImg from '../assets/img/logo_fck.png';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  produtoProposta: ProdutoProposta;
}

interface PropostaImpressaoContext {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  isPropostaExterior: boolean;
  setIsPropostaExterior: (valor: boolean) => void;
  valorDolar: number;
  setValorDolar: (valor: number) => void;
  parcelas: PropostaVersaoParcelas[];
  orcamento: Orcamento;
}

export const PropostaImpressaoContext = createContext(
  {} as PropostaImpressaoContext,
);

interface iParamsLista {
  label: string;
  valor: any;
}

export default function PedidoVenda({
  valores,
  listaDadosProdutos,
}: {
  valores: iParamsLista[];
  listaDadosProdutos: [];
}): JSX.Element {
  const location = useLocation<LocationState>();
  const { proposta, propostaVersao, produtoProposta } = location.state;
  const [orcamento, setOrcamento] = useState({} as Orcamento);
  const orcamentoId = propostaVersao?.PropostaConfiguracao?.[0]?.orcamentoId;
  async function getValorByLabel(nome: string) {
    return valores.find(item => item.label === `${nome}`)?.valor;
  }
  const clienteNome = valores.find(item => item.label === `clienteNome`)?.valor;
  const tensao = valores.find(item => item.label === `resposta_tensao`)?.valor;
  const propostaValorFinal = valores.find(
    item => item.label === `propostaValorFinal`,
  )?.valor;
  const propostaValorFinalExtenso = valores.find(
    item => item.label === `propostaValorFinalExtenso`,
  )?.valor;
  const numeroProposta = valores.find(
    item => item.label === `numeroProposta`,
  )?.valor;
  const numeroVersao = valores.find(
    item => item.label === `numeroVersao`,
  )?.valor;
  const produtoNome = produtoProposta.nomeProposta;
  const produtoFiname = valores.find(
    item => item.label === `produtoFiname`,
  )?.valor;

  const marca = 'Marca Fockink';
  const areaIrrigada = valores.find(
    item => item.label === `FT_areaTotalIrrigada`,
  )?.valor;

  const raio = valores.find(
    item => item.label === `FT_raioEfetivoAreaIrrigada`,
  )?.valor;

  const dataAtual = valores.find(item => item.label === `dataAtual`)?.valor;

  const sistema = valores.find(
    item => item.label === `FT_sistemaBombeamento`,
  )?.valor;

  const comprimentoTotalTubos = valores.find(
    item => item.label === `FT_comprimentoTotalTubos`,
  )?.valor;

  const raio1 = valores.find(item => item.label === `FT_aspersor1Raio`)?.valor;

  const raio2 = valores.find(item => item.label === `FT_aspersor2Raio`)?.valor;

  const incluirAspersorFinal = valores.find(
    item => item.label === `FT_incluirAspersorFinal`,
  )?.valor;

  const areaEfetivaIrrigadaSAF = valores.find(
    item => item.label === `FT_areaEfetivaIrrigadaSAF`,
  )?.valor;

  const areaTotalIrrigadaCAF = valores.find(
    item => item.label === `FT_areaTotalIrrigadaCAF`,
  )?.valor;

  const quantidadeLances = valores.find(
    item => item.label === `FT_quantidadeLances`,
  )?.valor;

  const prazoInstalacao = valores.find(
    item => item.label === `propostaPrazoInstalacao`,
  )?.valor;

  const clienteIE = valores.find(item => item.label === `clienteIE`)?.valor;

  const clienteDocumento = valores.find(
    item => item.label === `clienteDocumento`,
  )?.valor;

  const clienteEmail = valores.find(
    item => item.label === `clienteEmail`,
  )?.valor;

  const clienteNomeFantasia = valores.find(
    item => item.label === `clienteNomeFantasia`,
  )?.valor;

  const clienteTelefone = valores.find(
    item => item.label === `clienteTelefone`,
  )?.valor;

  const clienteUF = valores.find(item => item.label === `clienteUF`)?.valor;

  const clienteMunicipio = valores.find(
    item => item.label === `clienteMunicipio`,
  )?.valor;

  const clienteEndereco = valores.find(
    item => item.label === `clienteEndereco`,
  )?.valor;

  const clienteEnderecoLatitude = valores.find(
    item => item.label === `clienteEnderecoLatitude`,
  )?.valor;

  const clienteEnderecoLongitude = valores.find(
    item => item.label === `clienteEnderecoLongitude`,
  )?.valor;

  const clienteEnderecoBairro = valores.find(
    item => item.label === `clienteEnderecoBairro`,
  )?.valor;

  const clienteEnderecoCEP = valores.find(
    item => item.label === `clienteEnderecoCEP`,
  )?.valor;

  const propostaEndereco = valores.find(
    item => item.label === `propostaEndereco`,
  )?.valor;

  const propostaUF = valores.find(item => item.label === `propostaUF`)?.valor;

  const propostaMunicipio = valores.find(
    item => item.label === `propostaMunicipio`,
  )?.valor;

  const propostaEnderecoBairro = valores.find(
    item => item.label === `propostaEnderecoBairro`,
  )?.valor;

  const propostaEnderecoCEP = valores.find(
    item => item.label === `propostaEnderecoCEP`,
  )?.valor;

  const propostaValorDadoGeodesico = valores.find(
    item => item.label === `propostaValorDadoGeodesico`,
  )?.valor;

  const propostaInscricaoEstadual = valores.find(
    item => item.label === `propostaInscricaoEstadual`,
  )?.valor;

  const usaAspersorFinal: boolean =
    String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
    String(incluirAspersorFinal).toUpperCase() === 'S';

  const valorArea = usaAspersorFinal
    ? Number(areaTotalIrrigadaCAF ?? 0)
    : Number(areaEfetivaIrrigadaSAF ?? 0);

  const propostaParcelas = valores.find(
    item => item.label === `propostaParcelas`,
  )?.valor;

  const propostaMatrizResponsabilidadeFockink = valores.find(
    item => item.label === `propostaMatrizResponsabilidadeFockink`,
  )?.valor;

  const propostaMatrizResponsabilidadeCliente = valores.find(
    item => item.label === `propostaMatrizResponsabilidadeCliente`,
  )?.valor;

  /*
  Retirado temporariamente por solicitação em comentário da task #2059
  if (
    !propostaEndereco ||
    !propostaUF ||
    !propostaMunicipio ||
    !propostaEnderecoCEP ||
    !propostaInscricaoEstadual
  )
    return (
      <>
        <Row>
          <Col sm="12" className="mt-1">
            <p>
              Para visualização do Pedido de Venda, é necessário ter o endereço
              e Inscrição Estadual preenchidos na aba de <strong>Obra</strong>{' '}
              na proposta.
            </p>
          </Col>
        </Row>
      </>
    ); */

  async function getOrcamento() {
    if (orcamentoId) {
      const response = await api.get(`/orcamento/${orcamentoId}`);
      setOrcamento(response.data);
    }
  }

  useEffect(() => {
    getOrcamento();
  }, []);

  return (
    <>
      <Row>
        <Col sm="12" className="mt-1">
          <div
            style={{
              float: 'left',
              padding: '0px',
            }}
          >
            <img src={LogoImg} alt="Logo" width="50%" height="50%" />
          </div>

          <div
            style={{
              float: 'right',
              fontSize: '12px',
              lineHeight: '1em',
            }}
          >
            <strong>
              FOCKINK INDÚSTRIAS ELÉTRICAS LTDA. <br />
              CNPJ 03.021.334/0003-00 INSCR.EST.090/0037652 <br />
              CNPJ (Matriz) 03.021.334/0001-30
            </strong>
          </div>
        </Col>
      </Row>
      <div
        style={{
          textAlign: 'justify',
          textJustify: 'inter-word',
          padding: '0px',
          lineHeight: '1em',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h1>Pedido de Venda</h1>
        </div>
        <div>
          <h2>
            PROPOSTA ORÇAMENTARIA Nº: {numeroProposta} v{numeroVersao}
            {listaDadosProdutos.length > 0 && (
              <span>
                {' (Configurações: '}
                {listaDadosProdutos.map(
                  (
                    prod: { configuracao: { orcamentoId: string } },
                    index: number,
                  ) => (
                    <span key={index}>
                      {prod.configuracao.orcamentoId}
                      {index < listaDadosProdutos.length - 1 ? ', ' : ''}
                    </span>
                  ),
                )}
                )
              </span>
            )}
          </h2>
        </div>
        <div>Data Emissão: {dataAtual}</div>
        <div>
          Gestor/Representante/Gerente:{' '}
          {propostaVersao?.proposta?.usuario?.nome} / {orcamento?.representante}
        </div>
        <br />
        <div>
          <strong>Cliente: </strong>
          {clienteNome}
        </div>
        <div>
          <strong>Fazenda: </strong>
          {clienteNomeFantasia ?? ''}
        </div>
        <div>End. Entrega: {propostaEndereco}</div>
        <Row>
          <Col sm="6">Cidade: {propostaMunicipio}</Col>
          <Col sm="2">UF: {propostaUF}</Col>
          <Col sm="2">CEP: {propostaEnderecoCEP}</Col>
        </Row>
        <Row>
          <Col sm="6">CNPJ/CPF: {clienteDocumento}</Col>
          <Col sm="6">IE/IP: {propostaInscricaoEstadual}</Col>
        </Row>
        <Row>
          <Col sm="6">End. Cobrança: {clienteEndereco}</Col>
          <Col sm="6">Bairro: {clienteEnderecoBairro}</Col>
        </Row>
        <Row>
          <Col sm="6">Cidade: {clienteMunicipio}</Col>
          <Col sm="2">UF: {clienteUF}</Col>
          <Col sm="2">CEP: {clienteEnderecoCEP}</Col>
        </Row>
        <div>Tel | Fax | Cel: {clienteTelefone}</div>
        <div>Responsável e-mail: {clienteEmail}</div>
        <div>Coordenadas Geográficas:{` ${propostaValorDadoGeodesico}`}</div>

        {/* <div>
          <h2>Produto: </h2>
        </div>
        <div style={{ color: '#0000FF' }}>
          01 {produtoNome}, {marca}, sistema {sistema} com {quantidadeLances}{' '}
          torres de sustentação, raio{' '}
          {Number(comprimentoTotalTubos ?? 0) + (raio1 > raio2 ? raio1 : raio2)}{' '}
          M para área irrigada de {valorArea} HA.
        </div>
        <div>
          <strong>COD.FINAME: {`${produtoFiname} ${produtoNome}`}</strong>
        </div>
        <div>Tensão: {tensao ?? 0} Volts </div> */}

        <div>
          <h2>Produto(s): </h2>
        </div>

        {listaDadosProdutos.length > 0
          ? listaDadosProdutos.map(
              (
                prod: {
                  produto: any;
                  fichaTecnica: {
                    tensaoTrifasica: number;
                    FichaTecnicaBombeamento: { motor: any }[];
                    FichaTecnicaLance: any[];
                    comprimentoTotalTubos: any;
                    aspersor1Raio: number;
                    aspersor2Raio: number;
                    incluirAspersorFinal: any;
                    areaTotalIrrigadaCAF: any;
                    areaEfetivaIrrigadaSAF: any;
                  };
                },
                index: number,
              ) => (
                <div key={index}>
                  <div style={{ color: '#0000FF' }}>
                    01{' '}
                    {prod.produto[0].nomeProposta ??
                      prod.produto[0].nome ??
                      'NÃO INFORMADO'}
                    {prod.produto[0].id === 1 || prod.produto[0].id === 8 ? (
                      <>
                        , {marca}, sistema{' '}
                        {prod?.fichaTecnica.FichaTecnicaBombeamento?.[0]
                          ?.motor || 'NÃO INFORMADO'}{' '}
                        com{' '}
                        {prod.fichaTecnica.FichaTecnicaLance?.reduce(
                          (total: any, item: any) => total + item.quantidade,
                          0,
                        ) || 'NÃO INFORMADO'}{' '}
                        torres de sustentação, raio{' '}
                        {Number(prod.fichaTecnica.comprimentoTotalTubos ?? 0) +
                          (prod.fichaTecnica.aspersor1Raio >
                          prod.fichaTecnica.aspersor2Raio
                            ? prod.fichaTecnica.aspersor1Raio
                            : prod.fichaTecnica.aspersor2Raio) ||
                          'NÃO INFORMADO'}{' '}
                        M para área irrigada de{' '}
                        {String(
                          prod.fichaTecnica.incluirAspersorFinal,
                        ).toUpperCase() === 'SIM' ||
                        String(
                          prod.fichaTecnica.incluirAspersorFinal,
                        ).toUpperCase() === 'S'
                          ? Number(prod.fichaTecnica.areaTotalIrrigadaCAF ?? 0)
                          : Number(
                              prod.fichaTecnica.areaEfetivaIrrigadaSAF ?? 0,
                            ) || 'NÃO INFORMADO'}{' '}
                        HA.
                        <br />
                      </>
                    ) : null}
                  </div>
                  {prod.produto[0].id === 1 || prod.produto[0].id === 8 ? ( // Mover o COD.FINAME para fora do estilo
                    <div>
                      <strong>
                        COD.FINAME:{' '}
                        {`${prod.produto[0].finame || 'NÃO INFORMADO'} ${
                          prod.produto[0].nomeProposta ??
                          prod.produto[0].nome ??
                          'NÃO INFORMADO'
                        }`}
                        <div>
                          Tensão:{' '}
                          {prod.fichaTecnica.tensaoTrifasica ?? 'NÃO INFORMADO'}{' '}
                          Volts{' '}
                        </div>
                      </strong>
                    </div>
                  ) : null}

                  <br />
                </div>
              ),
            )
          : ''}

        <div>
          <h2>Condições Específicas de Fornecimento: </h2>
        </div>
        <div>
          <strong>Fockink fornece: </strong>
          {propostaMatrizResponsabilidadeFockink}
        </div>
        <div>
          <strong>Cliente fornece: </strong>
          {propostaMatrizResponsabilidadeCliente}
        </div>
        <div>
          <strong>Diferencial de ICMS por conta do Comprador.</strong>
        </div>
        <div>Prazo de Instalação: {prazoInstalacao}</div>
        <div>
          <strong>Preço: R$ {propostaValorFinal}</strong>{' '}
          <i>({propostaValorFinalExtenso} reais)</i>
        </div>
        <div>
          <strong>Obs: </strong>Prazo vinculado ao fornecimento de dados para
          projeto definitivo, obras de infra estrutura e condições de montagem
          disponíveis conforme proposta comercial e Condições Gerais de
          Fornecimento.
        </div>
        <div>
          <strong>Condições de Pagamento: </strong>
        </div>
        {propostaParcelas.map((item: any) => (
          <div style={{ color: '#0000FF' }}>
            {item.percentual}% {formatCurrency(item.valor)}{' '}
            {item.meioPagamento?.descricao} / {item.observacao}
          </div>
        ))}
        <br />
        <div>
          O Cliente Declara ter ciência e concordar que os valores avençados no
          presente serão realizados por intermédio de Depósito/Transferência
          bancária (Banco do Brasil - 001, Agência 0709-9, Conta Corrente
          2831-2) Pix (CNPJ) 03.021.334/0001-30, nas datas especificadas neste
          instrumento, bem como o atraso do pagamento acarretara no acréscimo de
          mais 2% ao mês, referente a 1% juros de mora e 1% de despesas de
          cobrança, sobre a parcela inadimplida.
        </div>
        <div>
          O presente pedido é celebrado em caráter irrevogável, irretratável e
          irreversível obrigando o COMPRADOR e seus legítimos sucessores a
          qualquer título a cumpri-lo segundo as bases contratadas. Eventual
          desistência do COMPRADOR implica no pagamento de multa compensatória
          desde já fixada em 20% (vinte por cento) sobre o valor total do
          pedido, devendo efetuar o pagamento pelos serviços já executados e
          materiais adquiridos para o processo de industrialização, sem prejuízo
          dos termos constantes nas Condições Gerais de Fornecimento
        </div>
        <br />
        <div>
          <strong>Efeito de alteração de impostos: </strong>
        </div>
        <div>
          Os preços ofertados consideram as aliquotas, benefícios e incentivos
          fiscais previstos na legislação tributária vigente neste momento,
          assim sendo, quaisquer tributos, aumento de impostos, taxas,
          contribuições fiscais ou parafiscais ou outros encargos legais
          criados, alterados ou extintos, bem como a superveniência de
          disposições legais, quando ocorridas após a data da apresentação da
          proposta e pedido, de comprovada repercussão nos preços contratados,
          implicarão a revisão destes, ficando estipulado que o pagamento das
          diferenças apuradas deverão ser adimplidas junto a última parcela.
        </div>
        <br />
        <div>
          <strong>Observações: </strong>
        </div>
        <div>
          O presente pedido de venda será regido pelas cláusulas constantes nas
          Condições Gerais de Fornecimento que é parte integrante deste pedido
          na forma de anexo, sendo que o Comprador declara neste ato estar
          ciente e de acorco com seus termos e condições, reconhece que o
          presente pedido de venda, assim como o documento Condições Gerais de
          Fornecimento possuem força de título executivo extrajudicial, nos
          termos do Código de Processo Civil. O Comprador declara ainda estar
          ciente e concorda com as obrigações ajustadas pelas Partes na matriz
          de responsabilidades.
        </div>
        <br />
        <div>
          <strong>Aceite do Pedido: </strong>
        </div>
        <div>
          O Cliente Declara ter lido e estar de acordo com as Condições Gerais
          de Fornecimento, proposta comercial e matriz de responsabilidades, que
          são parte integrantes deste pedido.
        </div>
        <br />
        <Row>
          <Col sm="4">
            <div
              style={{
                textAlign: 'center',
                verticalAlign: 'top',
                marginTop: '100px',
              }}
            >
              ______________________ <br />
              Cliente
            </div>
          </Col>
          <Col sm="4">
            <div
              style={{
                textAlign: 'center',
                verticalAlign: 'top',
                marginTop: '100px',
              }}
            >
              ______________________ <br /> Testemunha 1 <br />
            </div>
          </Col>
          <Col sm="4">
            <div
              style={{
                textAlign: 'center',
                verticalAlign: 'top',
                marginTop: '100px',
              }}
            >
              ______________________ <br />
              Testemunha 2 <br />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
