import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaBuilding,
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import QuestaoModel from 'models/Questao';

import api from 'services/api';

import { arredondaDecimais } from 'utils/arredondaDecimais';

import ModalLog from 'components/ModalLog';
import LigacaoPressao from '../../../models/ConfiguradorLigacaoPressao';
import TubosAdutoraModel from '../../../models/ConfiguradorTubosAdutora';

const ConfiguradorLigacaoPressao: React.FC = () => {
  const [ligacaoPressao, setLigacaoPressao] = useState([] as LigacaoPressao[]);
  // eslint-disable-next-line prettier/prettier
  const [ligacaoPressaoEditing, setLigacaoPressaoEditing] = useState({} as LigacaoPressao);

  const [formModalState, setFormModalState] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [tubosAdutora, setTubosAdutora] = useState([] as TubosAdutoraModel[]);

  async function listaLigacaoPressao() {
    const response = await api.get('/perda-carga/ligacao-pressao/');

    setLigacaoPressao(response.data);
  }

  async function listaTubosAdutora() {
    const response = await api.get('/integracao/tubos-adutora/listar-ativos');

    setTubosAdutora(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaLigacaoPressao();
    listaTubosAdutora();
  }, []);

  async function handleDelete(itemId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/perda-carga/ligacao-pressao/${itemId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaLigacaoPressao();
      }
    });
  }
  async function handleInsert() {
    setLigacaoPressaoEditing({} as LigacaoPressao);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemPressao: LigacaoPressao) {
    setLigacaoPressaoEditing(itemPressao);

    setFormModalState(!formModalState);
  }

  async function handleSave() {
    // eslint-disable-next-line prettier/prettier
    const { tpRecalque,
      comprimento,
      fatorC1,
      fatorK, id} = ligacaoPressaoEditing;


    if (!tpRecalque || !comprimento || !fatorC1 || !fatorK) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);
    try {
      if (id) {
        await api.put(`/perda-carga/ligacao-pressao/${id}`, {
          id,
          tpRecalque: ligacaoPressaoEditing.tpRecalque,
          comprimento: ligacaoPressaoEditing.comprimento,
          fatorC1: ligacaoPressaoEditing.fatorC1,
          fatorK: ligacaoPressaoEditing.fatorK,
          fatorK2: ligacaoPressaoEditing.fatorK2,
          seqTubulacaoAdutora: ligacaoPressaoEditing.seqTubulacaoAdutora,
        });
      } else {
        await api.post(`/perda-carga/ligacao-pressao/`, {
          tpRecalque: ligacaoPressaoEditing.tpRecalque,
          comprimento: ligacaoPressaoEditing.comprimento,
          fatorC1: ligacaoPressaoEditing.fatorC1,
          fatorK: ligacaoPressaoEditing.fatorK,
          fatorK2: ligacaoPressaoEditing.fatorK2,
          seqTubulacaoAdutora: ligacaoPressaoEditing.seqTubulacaoAdutora,
        });
      }

      toast.success('Registro concluído');
    } finally {
      setFormModalState(false);
      setLigacaoPressaoEditing({} as LigacaoPressao);
      listaLigacaoPressao();
      setIsSaving(false);
    }
  }

  return (
    <>
      <Header showCards={false} />
      <ModalLog
        nomeChave="desconto_id"
        valorChave=""
        titulo="Logs de ligacaoPressao e comissões"
        modalState={modalStatusLogVisivel}
        toggle={() => setModalStatusLogVisivel(false)}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{ligacaoPressaoEditing.dn}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <span>TP Recalque *</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="TP Recalque"
                            type="select"
                            value={ligacaoPressaoEditing.tpRecalque}
                            onChange={text =>
                              setLigacaoPressaoEditing({
                                ...ligacaoPressaoEditing,
                                tpRecalque: text.target.value,
                              })
                            }
                          >
                            <option value="" selected>
                              Selecione...
                            </option>
                            <option value="N">Normal</option>
                            <option value="S">Série</option>
                            <option value="P">Paralelo</option>
                            <option value="E">Paralelo série</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <span>Tubulação A.</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaBuilding />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Tubulação A."
                            type="select"
                            value={ligacaoPressaoEditing.seqTubulacaoAdutora}
                            onChange={text =>
                              setLigacaoPressaoEditing({
                                ...ligacaoPressaoEditing,
                                seqTubulacaoAdutora: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {tubosAdutora.map(tuboAdutora => (
                              <option value={tuboAdutora.id}>
                                {tuboAdutora.nome}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Comprimento *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Comprimento"
                            value={ligacaoPressaoEditing.comprimento}
                            type="number"
                            onChange={text =>
                              setLigacaoPressaoEditing({
                                ...ligacaoPressaoEditing,
                                comprimento: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Fator C1 *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fator C1"
                            value={ligacaoPressaoEditing.fatorC1}
                            type="number"
                            onChange={text =>
                              setLigacaoPressaoEditing({
                                ...ligacaoPressaoEditing,
                                fatorC1: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Fator K *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fator K"
                            value={ligacaoPressaoEditing.fatorK}
                            type="number"
                            onChange={text =>
                              setLigacaoPressaoEditing({
                                ...ligacaoPressaoEditing,
                                fatorK: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Fator K2</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fator K2"
                            value={ligacaoPressaoEditing.fatorK2}
                            type="number"
                            onChange={text =>
                              setLigacaoPressaoEditing({
                                ...ligacaoPressaoEditing,
                                fatorK2: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSave}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Ligação pressão</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Tipo Recalque</th>
                    <th>Tubulação A.</th>
                    <th>DN</th>
                    <th>DI</th>
                    <th>Comprimento</th>
                    <th>Fator C1</th>
                    <th>Fator K</th>
                    <th>Fator K2</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {ligacaoPressao.map(itemPressao => {
                    let tipo_recalque = 'N';
                    if (itemPressao.tpRecalque === 'N') {
                      tipo_recalque = 'Normal';
                    } else if (itemPressao.tpRecalque === 'S') {
                      tipo_recalque = 'Série';
                    } else if (itemPressao.tpRecalque === 'P') {
                      tipo_recalque = 'Paralelo';
                    } else if (itemPressao.tpRecalque === 'E') {
                      tipo_recalque = 'Paralelo série';
                    }
                    return (
                      <tr key={itemPressao.id}>
                        <td className="py-2">{tipo_recalque}</td>
                        <td className="py-2">{itemPressao.nome}</td>
                        <td className="py-2">
                          {arredondaDecimais(itemPressao.dn)}
                        </td>
                        <td className="py-2">
                          {arredondaDecimais(itemPressao.di)}
                        </td>
                        <td className="py-2">{itemPressao.comprimento}</td>
                        <td className="py-2">{itemPressao.fatorC1}</td>
                        <td className="py-2">{itemPressao.fatorK}</td>
                        <td className="py-2">{itemPressao.fatorK2}</td>
                        <td className="py-2">
                          <div className="float-right">
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              onClick={() => {
                                handleUpdate(itemPressao);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="danger"
                              type="button"
                              onClick={() => {
                                handleDelete(Number(itemPressao.id));
                              }}
                            >
                              <FaTimesCircle />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorLigacaoPressao;
