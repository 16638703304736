import { useContext } from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { PropostaContext } from 'views/pages/Proposta';

interface Props {
  label: string;
  value: number | undefined;
  setValue: (value: number | undefined) => void;
  isPercentual: boolean;
  onClickToggle: () => void;
  acessoLimitadoVariaveisComerciais: boolean;
  campoLiberado?: boolean;
  ocultabotaoPercent?: boolean;
  validaCampo?: string | undefined;
}

export default function InputMoedaPercentual({
  label,
  value,
  setValue,
  isPercentual,
  onClickToggle,
  acessoLimitadoVariaveisComerciais,
  campoLiberado,
  ocultabotaoPercent,
  validaCampo,
}: Props): JSX.Element {
  const { acessoLimitado } = useContext(PropostaContext);
  const liberacao =
    campoLiberado === true
      ? false
      : acessoLimitadoVariaveisComerciais ?? acessoLimitado;
  async function handleValidaCampo(validaCampos: string, percent: number) {
    if (validaCampos === 'Licenciamento' && percent > 1.5) {
      Swal.fire({
        icon: 'warning',
        text: 'O valor do Licenciamento não pode ser maior que 1,5%',
      });
      setValue(0);
      return;
    }

    if (validaCampos === 'Topografia' && percent > 1) {
      Swal.fire({
        icon: 'warning',
        text: 'O valor da Topografia não pode ser maior que 1,0%',
      });
      setValue(0);
      return;
    }
    Swal.close();
  }
  return (
    <Row>
      <Col xs="6" md="7">
        <Label className="form-control-label float-right mt-3">{label}</Label>
      </Col>
      <Col xs="6" md="5">
        <InputGroup className="input-group-alternative">
          <Input
            disabled={liberacao}
            type="number"
            min={0}
            step={0.01}
            value={value}
            onChange={text => {
              setValue(Number(text.target.value));
              handleValidaCampo(String(validaCampo), Number(text.target.value));
            }}
          />
          <InputGroupAddon addonType="append">
            {isPercentual ? '%' : 'R$'}
          </InputGroupAddon>
          <Button
            hidden={ocultabotaoPercent}
            size="sm"
            onClick={() => {
              onClickToggle();
            }}
          >
            <FaExchangeAlt />
          </Button>
        </InputGroup>
      </Col>
    </Row>
  );
}
