import React from 'react';

import { Redirect, Switch, useLocation } from 'react-router-dom';

import routes from 'routes';
import { useAuth } from 'hooks/auth';
import Route from './Route';

import AdminLayout from '../layouts/Admin.js';
import AuthLayout from '../layouts/Auth.js';

const Routes: React.FC = () => {
  const location = useLocation();
  const { usuario } = useAuth();

  if (!usuario) {
    const pathRoute = location.pathname.split('/');

    const pathNameRoute = pathRoute?.[2];

    const existRoute = !!routes.find(
      route => route.layout === '/admin' && route.path === `/${pathNameRoute}`,
    );

    const pathNameToNav = localStorage.getItem('pathNameToNav');

    if (existRoute && pathNameRoute !== 'index') {
      localStorage.setItem('pathNameToNav', `${location.pathname}`);
      localStorage.setItem(
        'searchNameToNav',
        `${location.search ? location.search : ''}`,
      );
    }
  }

  return (
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} isPrivate />
      <Redirect from="/" to="/admin/index" />
    </Switch>
  );
};

export default Routes;
