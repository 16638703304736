import styled from 'styled-components';
import { Col as ColC } from 'reactstrap';

export const ColName = styled(ColC)`
  text-align: end;
`;

export const Label = styled.label`
  margin-top: 5px;
  font-size: 0.9rem;
  font-weight: 600;
`;

export const Valor = styled.label`
  font-size: 12px;
  font-weight: 800;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowFoot = styled.div`
  display: flex;
  flex-direction: row;
  color: #fff;
  background: #2f75b6;
`;
