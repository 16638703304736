import UserSmallHeader from 'components/Headers/UserSmallHeader';
import OrcamentoMaterial from 'models/OrcamentoMaterial';
import { useEffect, useState } from 'react';
import { FaExclamationTriangle, FaFileExcel, FaTrash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Badge, Button, Col, Container, InputGroup, Row } from 'reactstrap';
import api from 'services/api';
import { Checkbox, ListItem } from '@material-ui/core';
import Swal from 'sweetalert2';
import exportToSheet from 'utils/exportToSheet';
import toast from 'react-hot-toast';
import Orcamento from 'models/Orcamento';
import TagModel from 'models/Tag';
import OrcamentoMaterialModel from 'models/EngenhariaMaterial';
import FloatingButton from 'components/FloatingButton';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import ConfiguracaoMaterialAvulso from './components/MaterialAvulso';

import {
  ButtonsRightCol,
  ColLabelItem,
  LabelItem,
  MaterialListContainer,
  MaterialRow,
  MaterialContainer,
} from './styles';

interface LocationState {
  orcamentoId: number;
  acessoLimitado: boolean | false;
}

const ConfiguracaoMateriais: React.FC = () => {
  const location = useLocation<LocationState>();
  const orcamentoId = location.state?.orcamentoId;

  const [orcamentoMateriais, setOrcamentoMateriais] = useState(
    [] as OrcamentoMaterial[],
  );
  const [orcamentoKits, setOrcamentoKits] = useState(
    [] as OrcamentoMaterialModel[],
  );
  const [deletingMaterial, setDeletingMaterial] = useState(false);
  const [deletingConfiguracao, setDeletingConfiguracao] = useState(false);
  const [exibeBadge, setExibeBadge] = useState(false);
  const [orcamentos, setOrcamentos] = useState({} as Orcamento);
  const [selectAll, setSelectAll] = useState(false);
  const [materiais, setMateriais] = useState<OrcamentoMaterial[]>([]);
  const [tags, settags] = useState([] as TagModel[]);
  const [podeExcluir, setPodeExcluir] = useState(false);
  const [exibeKit, setExibeKit] = useState(false);

  const [kitCarregado, setKitCarregado] = useState(false);
  const [matCarregado, setMatCarregado] = useState(false);

  async function carregaOrcamentoMateriais(): Promise<OrcamentoMaterial[]> {
    const response = await api.get(`/orcamento/lista-materiais-novo`, {
      params: { orcamentoId },
    });

    setOrcamentoMateriais(
      response.data.filter(
        (item: OrcamentoMaterial) => item.criadoManualmente === false,
      ),
    );

    setMatCarregado(true);

    Swal.close();
    return response.data;
  }

  function comparar(
    a: OrcamentoMaterialModel,
    b: OrcamentoMaterialModel,
  ): number {
    // Primeiro, ordena por ordemAgrupador
    if (a.ordemAgrupador < b.ordemAgrupador) return -1;
    if (a.ordemAgrupador > b.ordemAgrupador) return 1;

    // Se os ordemAgrupador forem iguais, ordena por agrupador
    if (a.agrupador < b.agrupador) return -1;
    if (a.agrupador > b.agrupador) return 1;

    // Se os agrupadores forem iguais, ordena por posicaoEsquematica
    if (a.posicaoEsquematica && b.posicaoEsquematica) {
      const posicaoA = a.posicaoEsquematica; // Convertendo para string para fins de comparação
      const posicaoB = b.posicaoEsquematica;
      if (Number(posicaoA) < Number(posicaoB)) return -1;
      if (Number(posicaoA) > Number(posicaoB)) return 1;
    }

    // Se as posicoesEsquematicas forem iguais ou não existirem, ordena por atividade
    if (a.atividade < b.atividade) return -1;
    if (a.atividade > b.atividade) return 1;

    // Se as atividades forem iguais, ordena por sequenciaAtividade
    if (a.sequenciaAtividade < b.sequenciaAtividade) return -1;
    if (a.sequenciaAtividade > b.sequenciaAtividade) return 1;

    return 0; // Se todos os critérios forem iguais
  }

  function compararA(
    a: OrcamentoMaterialModel,
    b: OrcamentoMaterialModel,
  ): number {
    // Se as posicoesEsquematicas forem iguais ou não existirem, ordena por atividade
    if (a.atividade < b.atividade) return -1;
    if (a.atividade > b.atividade) return 1;

    // Se as atividades forem iguais, ordena por sequenciaAtividade
    if (a.sequenciaAtividade < b.sequenciaAtividade) return -1;
    if (a.sequenciaAtividade > b.sequenciaAtividade) return 1;

    // Primeiro, ordena por agrupador
    if (a.agrupador < b.agrupador) return -1;
    if (a.agrupador > b.agrupador) return 1;

    // Se os agrupadores forem iguais, ordena por posicaoEsquematica
    if (a.posicaoEsquematica && b.posicaoEsquematica) {
      const posicaoA = a.posicaoEsquematica; // Convertendo para string para fins de comparação
      const posicaoB = b.posicaoEsquematica;
      if (Number(posicaoA) < Number(posicaoB)) return -1;
      if (Number(posicaoA) > Number(posicaoB)) return 1;
    }

    return 0; // Se todos os critérios forem iguais
  }

  function obterInfoSeq(str: string): any {
    if (str) {
      const partes: string[] = str.split('-');
      const pos: number = partes[1]?.indexOf('.');
      const posEsq: string = partes[1]?.substring(pos + 1);
      let grupo: string = partes[1]?.substring(0, pos);
      if (grupo?.length === 1) {
        grupo = `0${grupo}`;
      }
      return { grupo, posEsq };
    }

    return { grupo: 99, posEsq: 0 };
  }

  async function carregaOrcamentoKits(): Promise<OrcamentoMaterialModel[]> {
    const response = await api.get(
      `/integracao/material/material-composicao?configuracaoId=${orcamentoId}`,
    );
    if (response.data) setOrcamentos(response.data as Orcamento);

    const listaItensort: OrcamentoMaterialModel[] = response?.data;

    // navigator.clipboard.writeText(`${JSON.stringify(response.data)}`); // Temporário só para testes

    for (let index = 0; index < listaItensort.length; index += 1) {
      if (!listaItensort[index].posicaoEsquematica) {
        const grupo = obterInfoSeq(listaItensort[index].aplicacao);
        listaItensort[index].agrupador = grupo.grupo;
        listaItensort[index].posicaoEsquematica = grupo.posEsq;
        // listaItensort[index].ordemAgrupador = obterDescAgrupamento(grupo.grupo);
      }
    }

    setOrcamentoKits(listaItensort.sort(comparar));
    setKitCarregado(true);
    Swal.close();
    return response.data;
  }

  async function carregaOrcamento() {
    if (orcamentoId) {
      const response = await api.get(`/orcamento/${orcamentoId}`);
      if (response.data) setOrcamentos(response.data as Orcamento);
    }
  }

  async function listaTags() {
    const response = await api.get('/tag');

    settags(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaTags();
  }, []);

  useEffect(() => {
    carregaOrcamentoKits();
    carregaOrcamentoMateriais();
  }, []);

  useEffect(() => {
    if (!kitCarregado || !matCarregado) {
      Swal.fire({
        icon: 'info',
        text: `Buscando materiais...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    }
  }, [kitCarregado, matCarregado]);

  useEffect(() => {
    carregaOrcamento();
  }, []);

  useEffect(() => {
    if (orcamentoMateriais.length > 0 && orcamentos.id) {
      const listaAlterada = orcamentoMateriais.filter(
        element => element.listaMateriaisAlterada === true,
      );

      if (listaAlterada.length > 0 || orcamentos.listaAlterada === true) {
        setExibeBadge(true);
      }
    }
  }, [orcamentoMateriais, orcamentos]);

  useEffect(() => {
    if (deletingMaterial) {
      toast.loading(`Excluindo item da configuração ${orcamentoId}...`, {
        position: 'top-right',
      });
      return;
    }

    toast.dismiss();
  }, [deletingMaterial]);

  async function handleDeletePropostaConfiguracaoMaterial() {
    let motivo = '';
    const idsArray: number[] = [];

    let textoDescricao = '';
    const OrcamentoMateriais = orcamentoMateriais
      .filter(items => items.excluirMaterial === true)
      .map(materialItem_ => materialItem_) as OrcamentoMaterial[];
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of OrcamentoMateriais) {
      textoDescricao = `${textoDescricao} + ${item.material.diteCodigo} - ${item.material.descricao} <br />`;
      idsArray.push(Number(item.id));
    }
    async function deletePropostaConfiguracaoMaterial() {
      try {
        setDeletingMaterial(true);
        await api.delete(
          `/orcamento/configuracao/${orcamentoId}/exclusao-lote`,
          { params: { ids: idsArray, motivo } },
        );
        setDeletingMaterial(false);
        carregaOrcamentoMateriais();
        carregaOrcamento();
      } finally {
        setDeletingMaterial(false);
      }
    }

    async function openModal() {
      Swal.fire({
        title: `Excluir item da configuração ${orcamentoId}?`,
        html: `textoDescricao
        <strong>Informe um motivo da exclusão do material:</strong>
        <input id="swal-input1" value='${motivo}' class="swal2-input">`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, excluir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(result => {
        const input = Swal?.getHtmlContainer()?.querySelector(
          '#swal-input1',
        ) as any;

        motivo = input.value;
        if (result.isConfirmed) {
          if (motivo.length < 5) openModal();
          else deletePropostaConfiguracaoMaterial();
        }
      });
    }

    openModal();
  }

  function handleSetRegraItemValue(
    materialItem: OrcamentoMaterial,
    attrName: string,
    value: string | number | boolean | null,
  ) {
    setOrcamentoMateriais(
      orcamentoMateriais.map(materialItem_ =>
        materialItem_.id === materialItem.id
          ? { ...materialItem_, [attrName]: value, regraAlterada: true }
          : materialItem_,
      ),
    );
  }

  function handleChangeAtivo(
    materialItem: OrcamentoMaterial,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = event.target;
    const value = !!checked;
    handleSetRegraItemValue(materialItem, 'excluirMaterial', value);
  }

  function handleExportToSheet() {
    const dadosPlanilha = orcamentoMateriais
      .filter(x => x.orcamentoMaterialId === null)
      .map(orcamentoMaterial => {
        const { material, regraAplicacao, quantidade } = orcamentoMaterial;
        const { diteCodigo, descricao } = material;
        return {
          Código: diteCodigo,
          Descrição: descricao,
          Aplicação: regraAplicacao,
          Quantidade: quantidade,
        };
      });
    const nomeArquivo = `Materiais - Configuração ${orcamentoId}`;
    exportToSheet(dadosPlanilha, nomeArquivo);
  }

  let agrupadorAtual: string | null = null;

  function SubTiluloSep(props: {
    texto: string;
    valor: number;
    elementType?: keyof JSX.IntrinsicElements;
    tag: TagModel;
  }): JSX.Element {
    const { texto, valor, elementType: ElementType = 'h3', tag } = props;

    const materiaisUsar = orcamentoMateriais.filter((item2: any) =>
      tag?.agrupadorTag?.find(
        items => items.regraAgrupador.nome === item2.regraAplicacao,
      ),
    );

    const allChecked = !(
      materiaisUsar.filter(item => item.excluirMaterial === false).length > 0
    );

    if (texto !== agrupadorAtual) {
      agrupadorAtual = texto;
      return (
        <>
          <ListItem
            style={{
              backgroundColor: '#2f75b6',
              color: '#fff',
              borderRadius: '4px',
              marginTop: '0.5rem',
              paddingBottom: '0px',
            }}
          >
            <ColLabelItem sm="2">
              <LabelItem
                style={{
                  fontSize: '14px',
                }}
              >
                {texto}
              </LabelItem>
            </ColLabelItem>
          </ListItem>
          <ListItem
            style={{
              backgroundColor: '#2f75b6',
              color: '#fff',
              borderRadius: '4px',
              paddingTop: '0px',
              fontSize: '10px',
            }}
          >
            <div
              style={{
                padding: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                  textAlign: 'center',
                }}
              >
                Ativ.
              </LabelItem>
            </div>
            <div
              style={{
                padding: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                  textAlign: 'center',
                }}
              >
                Seq.
              </LabelItem>
            </div>
            <div
              style={{
                padding: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                  textAlign: 'center',
                }}
              >
                Grupo
              </LabelItem>
            </div>
            <div
              style={{
                padding: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                  textAlign: 'center',
                }}
              >
                Ordem
              </LabelItem>
            </div>
            <div
              style={{
                padding: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                  paddingRight: '10px',
                }}
              >
                Cod.
              </LabelItem>
            </div>

            <ColLabelItem sm="4">
              <LabelItem
                style={{
                  fontSize: '10px',
                  paddingLeft: '10px',
                }}
              >
                Descrição
              </LabelItem>
            </ColLabelItem>
            <div
              style={{
                padding: '10px',
                paddingLeft: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                  paddingLeft: '10px',
                }}
              >
                Qtd.
              </LabelItem>
            </div>
            <div
              style={{
                padding: '10px',
                paddingLeft: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                  paddingLeft: '10px',
                }}
              >
                Un.
              </LabelItem>
            </div>
            <ColLabelItem sm="2">
              <LabelItem
                style={{
                  fontSize: '10px',
                  paddingLeft: '10px',
                }}
              >
                Aplicação
              </LabelItem>
            </ColLabelItem>
            <div
              style={{
                padding: '10px',
              }}
            >
              <LabelItem
                style={{
                  fontSize: '10px',
                }}
              >
                Status
              </LabelItem>
            </div>
            <ColLabelItem sm="1" style={{ paddingLeft: '2px' }}>
              <LabelItem>
                <span className="ml-4">
                  <Checkbox
                    checked={allChecked}
                    onChange={(e: any) => {
                      const listaAlterar = orcamentoMateriais;

                      const listaAlterada = listaAlterar.map(item => {
                        if (
                          tag?.agrupadorTag?.find(
                            items =>
                              items.regraAgrupador.nome === item.regraAplicacao,
                          )
                        ) {
                          return {
                            ...item,
                            excluirMaterial: e.target.checked,
                            regraAlterada: true,
                          };
                        }

                        return item;
                      });

                      setOrcamentoMateriais(listaAlterada);
                    }}
                    color="primary"
                    className="ml--1"
                    title="Selecionar todos"
                  />
                </span>
              </LabelItem>
            </ColLabelItem>
          </ListItem>
        </>
      );
    }
    return <></>;
  }

  function renderMateriais() {
    if (!orcamentoMateriais.length) {
      return <></>;
    }

    function renderListaSub(
      orcamentoMaterial: OrcamentoMaterialModel,
      index: number,
      itemPai = false,
    ) {
      return (
        <MaterialRow
          itemPai={itemPai}
          key={`${orcamentoMaterial.atividade + 1}_${index}`}
          style={{
            backgroundColor: '#ffffff',
            paddingTop: '0px',
            paddingBottom: '0px',
            lineHeight: '1rem',
          }}
        >
          <ListItem
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            <div
              style={{
                padding: '10px',
              }}
            >
              <span className="mb-0 text-xs">{}</span>
            </div>
            <div
              style={{
                padding: '15px',
              }}
            >
              <span className="mb-0 text-xs ml-2">{}</span>
            </div>
            <div
              style={{
                padding: '15px',
                minWidth: '50px',
                maxWidth: '50px',
              }}
            >
              <span className="mb-0 text-xs ml-2">{}</span>
            </div>
            <div
              style={{
                padding: '15px',
                minWidth: '50px',
                maxWidth: '50px',
              }}
            >
              <span className="mb-0 text-xs ml-2 mr-1">{}</span>
            </div>
            <div
              style={{
                padding: '15px',
                textAlign: 'center',
              }}
            >
              <span className="mb-0 text-xs ml-2">
                {orcamentoMaterial.diteCodigo}
              </span>
            </div>
            <Col sm="4">
              <span className="mb-0 text-xs">
                {orcamentoMaterial.descricao}
              </span>
            </Col>
            <div
              style={{
                padding: '15px',
                minWidth: '50px',
                maxWidth: '50px',
              }}
            >
              <span className="mb-0 text-xs ml-3">
                {arredondaDecimais(orcamentoMaterial.quantidade, 3) ||
                  arredondaDecimais(orcamentoMaterial.quantidadeCalculada, 3)}
              </span>
            </div>
            <div
              style={{
                padding: '15px',
                minWidth: '50px',
                maxWidth: '50px',
              }}
            >
              <span className="mb-0 text-xs ml-3">
                {orcamentoMaterial.tipoItem}
              </span>
            </div>
            <Col sm="2">
              <span className="mb-0 text-xs ml-3">
                {orcamentoMaterial.aplicacao}
              </span>
            </Col>
            <div
              style={{
                padding: '10px',
              }}
            >
              <span className="mb-0 text-xs pl-4">
                {orcamentoMaterial.cicloVidaStatus}
              </span>
            </div>

            <Col sm="1" style={{ paddingLeft: '5px' }}>
              {}
            </Col>
          </ListItem>
          <ListItem
            hidden={orcamentoMaterial.composicao.length === 0 || !exibeKit}
          >
            <Col sm="12">
              {orcamentoMaterial.composicao
                // .filter(x => x.orcamentoMaterialId === null)
                .map((orcamentoMaterialX: any, index2: number) =>
                  renderListaSub(orcamentoMaterialX, index2, true),
                )}
            </Col>
          </ListItem>
        </MaterialRow>
      );
    }

    function renderListaMaterial(
      orcamentoMaterial: OrcamentoMaterial,
      index: number,
    ) {
      const itemPai = true;
      const ciclo = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material?.diteCodigo,
      )?.cicloVidaStatus;
      const posicaoEsquematica = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material?.diteCodigo,
      )?.posicaoEsquematica;
      const atividade = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material?.diteCodigo,
      )?.atividade;
      const atividadeSeq = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material?.diteCodigo,
      )?.sequenciaAtividade;
      const agrupador = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material.diteCodigo,
      )?.agrupador;
      const chave = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material.diteCodigo,
      )?.atividade;
      const qtd = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material.diteCodigo,
      )?.quantidade;
      const qtdCalc = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material.diteCodigo,
      )?.quantidadeCalculada;
      const identificador = chave ? chave + 1 : chave;
      const tipoItem = orcamentoKits.find(
        item => item.diteCodigo === orcamentoMaterial?.material.diteCodigo,
      )?.tipoItem;

      return (
        <>
          <MaterialRow
            itemPai={itemPai}
            key={`${identificador}_${index}`}
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
              lineHeight: '1rem',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            <ListItem
              style={{
                paddingTop: '0px',
                paddingBottom: '0px',
                marginTop: '0px',
                marginBottom: '0px',
              }}
            >
              <div
                style={{
                  padding: '10px',
                }}
              >
                <span className="mb-0 text-xs">{atividade ?? ''}</span>
              </div>
              <div
                style={{
                  padding: '15px',
                }}
              >
                <span className="mb-0 text-xs ml-2">{atividadeSeq ?? ''}</span>
              </div>
              <div
                style={{
                  padding: '15px',
                  minWidth: '50px',
                  maxWidth: '50px',
                }}
              >
                <span className="mb-0 text-xs ml-2">{agrupador ?? ''}</span>
              </div>
              <div
                style={{
                  padding: '15px',
                  minWidth: '50px',
                  maxWidth: '50px',
                }}
              >
                <span className="mb-0 text-xs ml-2 mr-1">
                  {posicaoEsquematica ?? ''}
                </span>
              </div>
              <div
                style={{
                  padding: '15px',
                  textAlign: 'center',
                }}
              >
                <span className="mb-0 text-xs ml-2">
                  {orcamentoMaterial?.material?.diteCodigo}
                </span>
              </div>

              <Col sm="4">
                <span className="mb-0 text-xs">
                  {orcamentoMaterial.material.descricao}
                </span>
              </Col>
              <div
                style={{
                  padding: '15px',
                  minWidth: '50px',
                  maxWidth: '50px',
                }}
              >
                <span className="mb-0 text-xs">
                  {arredondaDecimais(orcamentoMaterial.quantidade, 3) ?? ''}
                </span>
              </div>
              <div
                style={{
                  padding: '15px',
                  minWidth: '50px',
                  maxWidth: '50px',
                }}
              >
                <span className="mb-0 text-xs">{tipoItem}</span>
              </div>
              <Col sm="2">
                <span className="mb-0 text-xs">
                  {orcamentoMaterial.regraAplicacao}
                </span>
              </Col>
              <div
                style={{
                  padding: '10px',
                }}
              >
                <span className="mb-0 text-xs pl-4">{ciclo}</span>
              </div>

              <Col sm="1" style={{ paddingLeft: '5px' }}>
                <span className="ml-4">
                  <Checkbox
                    checked={orcamentoMaterial.excluirMaterial}
                    onChange={(e: any) => {
                      handleChangeAtivo(orcamentoMaterial, e);
                    }}
                    color="primary"
                  />
                </span>
              </Col>
            </ListItem>
            <ListItem
              hidden={
                !orcamentoKits.find(
                  item =>
                    item.diteCodigo === orcamentoMaterial?.material?.diteCodigo,
                )?.composicao || !exibeKit
              }
            >
              <Col sm="12">
                {orcamentoKits
                  .find(
                    item =>
                      item.diteCodigo ===
                      orcamentoMaterial?.material?.diteCodigo,
                  )
                  ?.composicao // .filter(x => x.orcamentoMaterialId === null)
                  ?.map((orcamentoMaterialX: any, index2: number) =>
                    renderListaSub(orcamentoMaterialX, index2, true),
                  )}
              </Col>
            </ListItem>
          </MaterialRow>
        </>
      );
    }

    function renderLista(tag: TagModel, itemPai = true) {
      const materiaisUsar = orcamentoMateriais.filter((item2: any) =>
        tag?.agrupadorTag?.find(
          items => items.regraAgrupador.nome === item2.regraAplicacao,
        ),
      );

      const textoDesc = `${String(tag?.codigo)}) ${String(tag?.descricao)}`;

      const ordenado = materiaisUsar.slice().sort((a, b) => {
        const itemA = orcamentoKits.find(
          item => item.diteCodigo === a?.material?.diteCodigo,
        )?.posicaoEsquematica as any;
        const itemB = orcamentoKits.find(
          item => item.diteCodigo === b?.material?.diteCodigo,
        )?.posicaoEsquematica as any;

        if (!Number.isNaN(Number(itemA)) && !Number.isNaN(Number(itemB))) {
          return Number(itemA) - Number(itemB);
        }
        if (typeof itemA === 'string' && typeof itemB === 'string') {
          return itemA.localeCompare(itemB);
        }
        return typeof itemA === 'number' ? -1 : 1;
      });

      return (
        <>
          <SubTiluloSep texto={textoDesc} valor={1} tag={tag} key={tag.id} />
          {ordenado?.map((item: any, index: number) =>
            renderListaMaterial(item, index),
          )}
        </>
      );
    }

    return (
      <MaterialContainer>
        <Row>
          <Col hidden={!exibeBadge}>
            <span title="Lista de materiais alterada!">
              <Badge color="warning" className="ml-2">
                <span className="alert-icon">
                  <FaExclamationTriangle />
                </span>
                A liista de materiais foi alterada manualmente!
              </Badge>
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs="8" style={{ paddingLeft: '2rem', paddingTop: '1rem' }}>
            <h6 className="heading-small text-muted">Lista de materiais</h6>
          </Col>
          <ButtonsRightCol sm="2">
            <Button
              size="sm"
              className="btn-icon btn-2 float-rigth"
              style={{ backgroundColor: '#1a8c5b' }}
              color="success"
              type="button"
              onClick={handleExportToSheet}
            >
              <FaFileExcel />
              <span className="btn-inner--text">Exportar planilha</span>
            </Button>
          </ButtonsRightCol>
          <ButtonsRightCol sm="2">
            <Button
              className="btn-icon btn-2"
              color="primary"
              type="button"
              outline
              size="sm"
              onClick={() => {
                setExibeKit(!exibeKit);
              }}
            >
              Mostrar Kits
            </Button>{' '}
          </ButtonsRightCol>
        </Row>

        <MaterialListContainer>
          {tags
            .filter(item =>
              item?.agrupadorTag?.some(items =>
                orcamentoMateriais.find(
                  (item2: any) =>
                    item2.regraAplicacao === items.regraAgrupador.nome,
                ),
              ),
            )
            ?.map(tag => renderLista(tag))}
        </MaterialListContainer>
      </MaterialContainer>
    );
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (orcamentoMateriais && orcamentoKits && tags) {
      // renderMateriais();
    }
  }, [orcamentoMateriais, orcamentoKits, tags, renderMateriais()]);

  return (
    <>
      <UserSmallHeader />
      <Container className="mt-2" fluid>
        <Row className="mt-3" style={{ width: '100%' }}>
          <Col sm="12">
            <ConfiguracaoMaterialAvulso />
          </Col>
        </Row>
        <Row className="mt-3" style={{ width: '100%' }}>
          {kitCarregado && matCarregado && renderMateriais()}
          <FloatingButton
            hidden={
              !orcamentoMateriais?.filter(item => item.excluirMaterial === true)
                .length
            }
            className="hide-on-print"
            color="secondary"
            onClick={handleDeletePropostaConfiguracaoMaterial}
          >
            <FaTrash style={{ color: 'white' }} />
          </FloatingButton>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguracaoMateriais;
