import { PropostaDescontoComissao } from 'models/PropostaDescontoComissao';
import { PropostaVersao } from 'models/PropostaVersao';
import { useContext, useEffect, useState } from 'react';
import { FaCheck, FaSave } from 'react-icons/fa';
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
} from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';
import { PropostaContext } from 'views/pages/Proposta';
import { MateriaisContext } from '../..';
import Valor from '../Valor';
import ValorDestacado from '../ValorDestacado';

export default function Cabecalho(): JSX.Element {
  const {
    propostaVersao,
    setPropostaVersao,
    permiteModificacao,
    isObraExterior,
    acessoLimitado,
  } = useContext(PropostaContext);
  const {
    valorItens,
    valorFrete,
    valorFinal,
    valorFinalDolar,
    setValorItens,
    setValorFrete,
    setValorFinal,
    setValorDesconto,
    setValorPercentualFrete,
    setValorServico,
    setValorFinalDolar,
    setTriggerMaterialCheck,
    listaDescontoComissao,
    setAtualizarPrecificacao,
    setRecalculandoProposta,
    recalculandoProposta,
    descontoSelecionado,
    setDescontoSelecionado,
    descontoMaximo,
    setDescontoMaximo,
    bloquearDesconto,
    setBloquearDesconto,
  } = useContext(MateriaisContext);

  useEffect(() => {
    async function carregaPropostaVersao2() {
      const response = await api.get(`/proposta/versao/${propostaVersao.id}`);
      const propostaVersaoData = response.data as PropostaVersao;
      // cabeçalho
      setValorItens(propostaVersaoData.valorItens);
      setValorFinal(propostaVersaoData.valorFinal);
      setValorFinalDolar(
        propostaVersaoData.valorDolar ? propostaVersaoData.valorFinalDolar : 0,
      );

      // rodapé
      setValorPercentualFrete(propostaVersaoData.percentualFrete);
      setValorDesconto(propostaVersaoData.valorDesconto);
      setValorServico(propostaVersaoData.valorServico);

      if (propostaVersaoData.descontoAprovado === true)
        setBloquearDesconto(true);
    }

    if (propostaVersao.desconto) {
      setDescontoSelecionado(
        String(propostaVersao.desconto).replaceAll('.', ','),
      );
    }

    carregaPropostaVersao2();
  }, [propostaVersao]);

  return (
    <Card className="shadow p-3">
      <Row className="px-2">
        <Col sm="4">
          <FormGroup className="pt-2 mb-0">
            <Table>
              <tbody>
                <ValorDestacado label="Valor final" valor={valorFinal} />
                {isObraExterior && (
                  <Valor
                    label="Valor em Dólar"
                    isDolar
                    valor={valorFinalDolar}
                  />
                )}
              </tbody>
            </Table>
          </FormGroup>
        </Col>
        <Col sm="5" />
        <Col sm="3">
          <FormGroup className="m-0">
            <Label className="form-control-label">Desconto (%)</Label>
            <InputGroup>
              <Input
                disabled={
                  recalculandoProposta ||
                  !permiteModificacao ||
                  bloquearDesconto
                }
                value={String(descontoSelecionado)}
                onChange={event =>
                  setDescontoSelecionado(
                    event.target.value.replace(/[^0-9.,-]/g, ''),
                  )
                }
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
}
