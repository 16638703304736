import { useContext, useEffect, useState } from 'react';

import { Button, Card, CardHeader, Col, Row } from 'reactstrap';

import OpcoesLancesType from 'models/OpcoesLances';

import { FaCheckCircle } from 'react-icons/fa';
import api from 'services/api';

import Swal from 'sweetalert2';
import Material from 'models/Material';
import { AppError } from 'interfaces/AppError';
import FichaTecnica from 'models/FichaTecnica';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import triggerValidaRegras from 'services/triggerValidaRegras';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import Parametros from 'models/Parametros';
import Orcamento from 'models/Orcamento';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import OpcoesLancesManual from '../OpcoesLancesManual/index';
import { CardBody, CardBodyPai } from '../../styles';
import {
  CardOpcoes,
  CardOpcao,
  LabelSaida,
  RowHeaderCardOpcoes,
} from './styles';
import { getAreaEmHectares } from '../ParteQuestoesFormulario/funcoes';
// eslint-disable-next-line import/extensions

interface Props {
  onSave?: any;
  onCarregarVersao?: any;
  acessoLimitado: boolean | false;
  triggerStart: boolean | false;
  sessaoId?: string | null;
  fichaTecnicaId?: number | null;
  configuracaoId: number;
  orcamentoParteId: number;
  setSessaoId?: any;
  setFichaTecnicaId?: any;
}

export default function OpcoesLances(props: Props): JSX.Element {
  const {
    onSave,
    acessoLimitado,
    triggerStart,
    orcamentoParteId,
    configuracaoId,
    sessaoId,
    fichaTecnicaId,
    setFichaTecnicaId,
    setSessaoId,
    onCarregarVersao,
  } = props;

  /*
  //Como sempre busco ao acessar a tela, torna-se desnecessário manter do contexto
  //Dá pra remover futuramente
  const { setOpcoesLances, opcoesLances } = useContext(
    ConfiguracaoEtapasContext,
  ); */

  const [opMostrarLimitado, setOpMostrarLimitado] = useState('S');
  const [opMostrarTuboEspecial, setOpMostrarTuboEspecial] = useState('S');
  const [habilitarBotao, setHabilitarBotao] = useState(false);
  const [areaTotalCAF, setAreaTotalCAF] = useState(0);
  const [tentativasRequisicoes, setTentativasRequisicoes] = useState(0);
  const [habilitarBotaoManual, setHabilitarBotaoManual] = useState(false);
  const [incluirPistaSeca, setIncluirPistaSeca] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [opcoesLances, setOpcoesLances] = useState<OpcoesLancesType[]>([]);
  const [parametros, setParametros] = useState<Parametros>();
  const [orcamentos, setOrcamentos] = useState({} as Orcamento);
  const [acessoLimitadoLancesManuais, setAcessoLimitadoLancesManuais] =
    useState(true);
  const [mostrarBotaoTodasOpcoes, setMostrarBotaoTodasOpcoes] = useState(true);

  async function carregarParametros() {
    const response = await api.get('/parametros');
    if (response.data) setParametros(response.data as Parametros);
  }

  async function carregaOrcamento() {
    const response = await api.get(`/orcamento/${configuracaoId}`);
    if (response.data) setOrcamentos(response.data as Orcamento);
  }

  useEffect(() => {
    carregarParametros();
  }, []);

  useEffect(() => {
    if (configuracaoId) {
      carregaOrcamento();
    }
  }, [configuracaoId]);

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 62,
      },
    });
    setAcessoLimitadoLancesManuais(response.data);

    const response2 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 64,
      },
    });

    const acesso = response2.data as ValidarAcessoPrograma;

    setMostrarBotaoTodasOpcoes(acesso.temAcesso && !acesso.acessoLimitado);
  }

  useEffect(() => {
    getAcessoLimitadoPrograma();
  }, []);

  const SEGUNDOS_ENTRE_TENTATIVAS = 10;

  useEffect(() => {
    if (parametros) {
      // Multiplica o tempo por 6 para ser o total de tentativas no tempo
      // ex: 2 minutos x 6 = 12 tentativas (1 a cada 10 segundos)
      setTentativasRequisicoes((parametros.minutosCalculoLances ?? 1) * 6);
    }
  }, [parametros]);

  let initCountTentativas = 1;
  let firstAccess = true;
  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  function salvar() {
    if (onSave) {
      props.onSave();
    }
  }

  async function searchLancesReadyForUse() {
    try {
      if (
        fichaTecnicaId &&
        (sessaoId !== '' || sessaoId !== null || sessaoId !== undefined)
      ) {
        setIsLoadingData(true);
        const response2 = await api.get('/integracao/lances/calculo', {
          params: {
            sessaoId,
            fichaTecnicaId,
            apenasBusca: 'S',
          },
        });
        const opcoesLancesResponse = response2.data;
        if (opcoesLancesResponse && opcoesLancesResponse.length > 0) {
          setOpcoesLances(opcoesLancesResponse);
          setHabilitarBotao(true);
          firstAccess = false;
        }
      }
    } catch (error: any) {
      // Não faz nada
    } finally {
      setIsLoadingData(false);
    }
  }

  useEffect(() => {
    if (firstAccess === true) searchLancesReadyForUse();
  }, []);

  async function validaRegras(fichaId: number): Promise<void> {
    await triggerValidaRegras({
      fichaTecnicaId: Number(fichaId ?? 0),
      atualizarMaterial: true,
      validarAgrupador: false,
      orcamentoVersaoId: configuracaoId,
      origem: `Configuração: ${configuracaoId}`,
      orcamento: orcamentos,
      origemAtualizacao: 'Opcoes',
    });
  }

  async function abreSwalSugestoes(appErrorData: AppError) {
    const { message, messageCode, value } = appErrorData;

    async function gravaRespostaSugerida(
      labelInterno: string,
      valorSugerido: string | number | undefined,
    ) {
      await api.post('/orcamento/resposta-label', {
        orcamentoId: configuracaoId,
        resposta: String(valorSugerido || ''),
        label: String(labelInterno || ''),
      });
    }

    Swal.fire({
      title: 'Ops!',
      text: message,
      icon: 'warning',
      confirmButtonText: `Gravar valor e recalcular`,
      cancelButtonText: 'Voltar',
      showConfirmButton: false,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async result => {
      if (!result.isConfirmed) {
        return;
      }
      if (messageCode === 'msg-lamina-sugerida') {
        await gravaRespostaSugerida('lamina', value);
      } else if (messageCode === 'msg-raio-sugerido') {
        const novaAreaTotalSemCanhao = arredondaDecimais(
          getAreaEmHectares(Number(value)),
          3,
        );
        await gravaRespostaSugerida('area_total', novaAreaTotalSemCanhao);
        // setMensagemRaioSugerido(message);
      }
    });
  }

  async function handleSetOpcaoLance(opcao: OpcoesLancesType) {
    async function gravaOpcaoLances() {
      setSwal('Gravando lances...');
      await api.post(`/ficha-tecnica-lance/grava-opcao`, {
        fichaTecnicaId,
        orcamentoParteId,
        orcamentoId: configuracaoId,
        orcamentoVersaoId: configuracaoId,
        ...opcao,
      });
    }

    async function executaNecessidadeReprocessamento() {
      if (fichaTecnicaId) {
        await api.put(
          `/ficha-tecnica/${fichaTecnicaId}/necessidade-reprocessamento/validarReprocessamento`,
          {},
        );
      }
    }

    async function executaCalculoCambagem() {
      setSwal('Calculando cambagem...');
      await api.post('/integracao/cambagem/orcamento/calculo', {
        orcamentoParteId,
        orcamentoId: configuracaoId,
        opcao: opcao.opcao,
        sessao: sessaoId,
        fichaTecnicaId,
      });
    }

    async function executaCalculoPistaSeca() {
      setSwal('Calculando pista seca...');
      await api.post('/integracao/pista-seca/orcamento/calculo', {
        orcamentoParteId,
        orcamentoId: configuracaoId,
        opcao: opcao.opcao,
        sessao: sessaoId,
        fichaTecnicaId,
      });
    }

    async function executaCalculoEletrica() {
      setSwal('Calculando parte elétrica...');
      await api.post('/integracao/eletrica/orcamento/calculo', {
        orcamentoParteId,
        orcamentoId: configuracaoId,
        opcao: opcao.opcao,
        sessao: sessaoId,
        fichaTecnicaId,
      });
    }

    async function executaCalculoVelocidade() {
      setSwal('Calculando velocidade...');
      await api.post('/integracao/velocidade/orcamento/calculo', {
        orcamentoParteId,
        orcamentoId: configuracaoId,
        opcao: opcao.opcao,
        sessao: sessaoId,
        fichaTecnicaId,
      });
    }

    async function executaCodigoPivo() {
      setSwal('Gerando código do pivô...');
      await api.post('/integracao/pivo/orcamento/gerar-codigo', {
        orcamentoParteId,
        orcamentoId: configuracaoId,
        opcao: opcao.opcao,
        sessao: sessaoId,
        fichaTecnicaId,
      });
    }

    async function executaCalculoSaida() {
      setSwal('Calculando dados de saída...');
      await api.post('/integracao/calculo-final', {
        fichaTecnicaId,
        orcamentoParteId,
        orcamentoId: configuracaoId,
      });
    }

    async function gravaLogsFt() {
      setSwal('Gerando Logs...');
      await api.post('/integracao/ficha-tecnica-logs', {
        opcao: opcao.opcao,
        sessao: sessaoId,
        fichaTecnicaId,
      });
    }

    // setOpcaoLanceSelecionada(opcao);
    await gravaOpcaoLances();
    await executaNecessidadeReprocessamento();
    await executaCalculoCambagem();
    await executaCalculoEletrica();
    await executaCalculoVelocidade();

    if (incluirPistaSeca) await executaCalculoPistaSeca();

    await executaCodigoPivo();
    await executaCalculoSaida();
    await validaRegras(Number(fichaTecnicaId));
    await gravaLogsFt();
    salvar();
    setSwal();
  }

  async function criarProcessoJob(sessao: string) {
    if (configuracaoId) {
      await api.post(
        `/integracao/lances/calculo-job/${configuracaoId}/${sessao}`,
        {},
      );
    }
  }

  function renderOpcoes(opcao: OpcoesLancesType) {
    const { valores } = opcao;

    const valoresUnicosPorNome = [
      ...new Map(valores.map(item => [item.nome, item])).values(),
    ];

    return valoresUnicosPorNome.map(valor => {
      const quantidadeMesmoNome = valores.filter(
        x => x.nome === valor.nome,
      ).length;

      const isLance = !!valor.seq_lances || !!valor.seq_balancos;

      if (isLance) {
        return (
          <Col key={valor.nome} style={{ padding: '5px' }} sm="4">
            <CardOpcao className="bg-secondary shadow">
              <CardBody className="p-3">
                <LabelSaida>
                  <strong>{valor.nome}</strong>
                </LabelSaida>
                <LabelSaida>
                  Comprimento: {valor.comprimento} {valor.unidade_medida}
                </LabelSaida>
                <LabelSaida>Quantidade: {quantidadeMesmoNome}</LabelSaida>
              </CardBody>
            </CardOpcao>
          </Col>
        );
      }

      return (
        <Col key={valor.nome} className="ml-2" sm="12">
          <LabelSaida>
            {valor.nome}:{' '}
            <strong>
              {valor.comprimento} {valor.unidade_medida}
            </strong>
          </LabelSaida>
        </Col>
      );
    });
    // Aqui será disparado o processo de gravar
  }

  function renderIndicacao(opcao: OpcoesLancesType) {
    function renderBadgeMenorCusto() {
      return (
        <div className="badge badge-success">
          <i className="ni ni-money-coins" /> Mais econômico
        </div>
      );
    }

    function renderBadgeMelhorEficiencia() {
      return (
        <div className="badge badge-warning">
          <i className="ni ni-chart-bar-32" /> Melhor eficiência
        </div>
      );
    }

    return (
      <Row>
        {opcao.sinaliza_custos && <Col>{renderBadgeMenorCusto()}</Col>}

        {opcao.sinaliza_eficiencia && (
          <Col>{renderBadgeMelhorEficiencia()}</Col>
        )}
      </Row>
    );
  }

  useEffect(() => {
    // Comando para fazer um scroll to top ao acessar a aba de Adutora.
    window.scrollTo(0, 0);
    // document.documentElement.scrollTop = 0;
  }, []);

  async function executaCalculoLances(
    codSessao: string,
    fichaId: number | undefined,
    mostrarLimitado = 'S',
    mostrarTuboEspecial = 'S',
    mostrarOpcaoManual = false,
  ) {
    setOpMostrarLimitado(mostrarLimitado);
    setOpMostrarTuboEspecial(mostrarTuboEspecial);

    async function getSituacaoProcessamentoOrcamento(
      sessao: string,
      fichaTecnica_: FichaTecnica,
    ) {
      const response = await api.get(
        `/integracao/lances/situacao-orcamento/${configuracaoId}/${sessao}`,
      );
      const situacaoCalculoLances = response.data?.[0]?.situacao;
      if (situacaoCalculoLances === 'OK') {
        try {
          if (
            fichaTecnica_.id &&
            (sessaoId !== '' || sessaoId !== null || sessaoId !== undefined)
          ) {
            const response2 = await api.get('/integracao/lances/calculo', {
              params: {
                sessaoId: sessao,
                fichaTecnicaId: fichaTecnica_.id,
              },
            });
            const opcoesLancesResponse = response2.data;
            setOpcoesLances(opcoesLancesResponse);
            setHabilitarBotao(true);
            setSwal();
            return;
          }
        } catch (error: any) {
          const appError = error?.response?.data as AppError;
          abreSwalSugestoes(appError);
          throw error;
        }
      }

      if (initCountTentativas === tentativasRequisicoes) {
        await criarProcessoJob(sessao);

        Swal.fire({
          title: 'Atenção',
          html: `<p>Esse cálculo deve levar mais algum tempo para conclusão.
          Vamos alertar no sistema e via e-mail quando o cálculo estiver concluído.
          Pode continuar usando o sistema normalmente.</p><br />
          <p><strong>IMPORTANTE:</strong> os resultados só estarão disponíveis durante o dia da solicitação.
          Em outro dia, será necessário efetuar o cálculo novamente.</p>`,
          icon: 'info',
          confirmButtonText: 'Ok',
          cancelButtonText: 'Voltar',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false,
        });

        initCountTentativas = 1;
        return;
      }
      initCountTentativas += 1;
      setTimeout(() => {
        getSituacaoProcessamentoOrcamento(sessao, fichaTecnica_);
      }, 1000 * SEGUNDOS_ENTRE_TENTATIVAS);
    }

    setOpcoesLances([]);
    setSwal('Calculando lances...');
    try {
      const response = await api.post('/integracao/lances/orcamento/calculo', {
        orcamentoId: configuracaoId,
        orcamentoVersaoId: configuracaoId,
        orcamentoParteId,
        sessao: codSessao,
        fichaTecnicaId: fichaId,
        mostrarLimitado,
        mostrarTuboEspecial,
        areaTotalCAF,
        opcaoManual: mostrarOpcaoManual ? 0 : null,
      });

      const novaFichaTecnica = response.data.fichaTecnica;
      await getSituacaoProcessamentoOrcamento(codSessao, novaFichaTecnica);
    } catch (error: any) {
      const appError = error?.response?.data as AppError;
      abreSwalSugestoes(appError);
      throw error;
    }
  }

  async function handleCarregarOpcoes() {
    setOpcoesLances([]);
    async function executaCalculoInicial() {
      setSwal('Gerando ficha técnica...');

      // Dispara o processo que aplica os regramentos especiais de questões (as que precisam modificar respostas)
      // Exemplo, task 852
      await api.post('/integracao/regramento-especial', {
        orcamentoId: configuracaoId,
        orcamentoVersaoId: configuracaoId,
        orcamentoParteId,
      });

      const response = await api.post('/integracao/cadastro-inicial', {
        orcamentoId: configuracaoId,
        orcamentoVersaoId: configuracaoId,
        orcamentoParteId,
      });

      return response.data;
    }

    async function executaCalculoAspersores(
      codSessao: string,
      fichaId: string,
    ) {
      setSwal('Calculando aspersores...');
      const retorno = await api.post(
        '/integracao/aspersores/orcamento/calculo',
        {
          orcamentoId: configuracaoId,
          orcamentoVersaoId: configuracaoId,
          orcamentoParteId,
          sessao: codSessao,
          fichaTecnicaId: fichaId,
        },
      );

      const areaTotal = retorno.data?.aspersor?.[0]?.area_total;
      setAreaTotalCAF(areaTotal || 0);
    }

    const retornoCalculo = await executaCalculoInicial();
    const codSessao = retornoCalculo.sessao;
    const fichaId = retornoCalculo.fichaTecnica.id;

    setIncluirPistaSeca(retornoCalculo.incluirPistaSeca ?? false);
    setFichaTecnicaId(fichaId);
    setSessaoId(codSessao);
    await executaCalculoAspersores(codSessao, fichaId);
    await executaCalculoLances(codSessao, fichaId);
  }

  async function handleConfirmaCarregarOpcoes() {
    Swal.fire({
      title: `Deseja carregar as opções de lances?`,
      text: `Essa operação irá excluir os dados de FT gerados até o momento para a configuração`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.put(`/orcamento/parte/${orcamentoParteId}/andamento`, {});

        if (onCarregarVersao) await props.onCarregarVersao();

        await handleCarregarOpcoes();
      }
    });
  }

  async function handleMostrarLimitado(
    codSessao: string,
    fichaId: number | undefined,
    mostrarLimitado = 'S',
  ) {
    setOpMostrarLimitado(mostrarLimitado);
    await executaCalculoLances(
      codSessao,
      fichaId,
      mostrarLimitado,
      opMostrarTuboEspecial,
    );
  }

  async function handleMostrarTuboEspecial(
    codSessao: string,
    fichaId: number | undefined,
    mostrarTuboEspecial = 'S',
  ) {
    setOpMostrarTuboEspecial(mostrarTuboEspecial);
    await executaCalculoLances(
      codSessao,
      fichaId,
      opMostrarLimitado,
      mostrarTuboEspecial,
    );
  }

  async function handleExecutarCalculoLancesManual() {
    await executaCalculoLances(
      String(sessaoId),
      Number(fichaTecnicaId),
      opMostrarLimitado,
      opMostrarTuboEspecial,
      true,
    );
  }

  useEffect(() => {
    if (triggerStart) handleCarregarOpcoes();
  }, [triggerStart]);

  useEffect(() => {
    if (opcoesLances.length > 0) setHabilitarBotao(true);
  }, [opcoesLances]);

  return (
    <Row style={{ width: '100%' }}>
      <Col sm="12">
        <Row className="mt-2 mb--1">
          <Col sm="4">
            <Button
              size="sm"
              className="ml-4"
              color="primary"
              outline
              onClick={() => handleConfirmaCarregarOpcoes()}
            >
              Carregar Opções de Lances
            </Button>
          </Col>
          <Col sm="8" hidden={!habilitarBotao}>
            {mostrarBotaoTodasOpcoes && (
              <Button
                size="sm"
                className="float-right ml-4"
                color="danger"
                outline
                onClick={() =>
                  handleMostrarLimitado(
                    String(sessaoId),
                    Number(fichaTecnicaId ?? 0),
                    'P',
                  )
                }
              >
                Carregar todas opções
              </Button>
            )}
            <Button
              size="sm"
              className="float-right ml-4"
              color="danger"
              outline
              onClick={() =>
                handleMostrarTuboEspecial(
                  String(sessaoId),
                  Number(fichaTecnicaId ?? 0),
                  'N',
                )
              }
            >
              Desconsiderar tubos especiais
            </Button>
            {!acessoLimitado ||
              (!acessoLimitadoLancesManuais && (
                <Button
                  size="sm"
                  className="float-right ml-4"
                  color="primary"
                  outline
                  onClick={() => setHabilitarBotaoManual(!habilitarBotaoManual)}
                >
                  Configurar Manualmente
                </Button>
              ))}
          </Col>
        </Row>

        {habilitarBotaoManual && (
          <Row className="mt-2">
            <Col sm="12">
              <OpcoesLancesManual
                sessaoId={sessaoId}
                onSave={handleExecutarCalculoLancesManual}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-2">
          <Col sm="12">
            {isLoadingData ? (
              <Row>
                <Col className="text-center">
                  <CircularProgress />
                </Col>
              </Row>
            ) : (
              opcoesLances.map(opcao => {
                const numeroOpcao = opcao.valores[0].opcao_visual;

                return (
                  <CardOpcoes className="bg-secondary shadow" key={numeroOpcao}>
                    <RowHeaderCardOpcoes>
                      <div className="progress-label">
                        <span> Opção {numeroOpcao}</span>
                      </div>
                      <div>{renderIndicacao(opcao)}</div>
                    </RowHeaderCardOpcoes>
                    <Row className="mt-1" style={{ padding: '0.5rem' }}>
                      {renderOpcoes(opcao)}
                    </Row>
                    <Button
                      size="sm"
                      className="btn-icon btn-2 mt-3"
                      color="primary"
                      type="button"
                      onClick={() => handleSetOpcaoLance(opcao)}
                    >
                      <FaCheckCircle />
                      <span className="btn-inner--text">
                        Selecionar opção {numeroOpcao}
                      </span>
                    </Button>
                  </CardOpcoes>
                );
              })
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
