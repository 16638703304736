import { Card, Row, Col, FormGroup, Table } from 'reactstrap';
import Valor from '../Valor';

interface Rodape {
  valorPercentualFrete: number | null;
  valorDesconto: number | null;
  valorFrete: number | null;
  valorItens: number | null;
}

export default function Rodape({
  valorPercentualFrete,
  valorDesconto,
  valorFrete,
  valorItens,
}: Rodape): JSX.Element {
  return (
    <Card className="shadow p-3">
      <Row className="px-2">
        <Col sm="8" />
        <Col sm="4">
          <FormGroup className="pt-2 mb-0">
            <Table>
              <tbody>
                <Valor
                  label="Percentual do frete"
                  valor={valorPercentualFrete}
                  noFormat
                  unit="%"
                />
                <Valor label="Valor do frete" valor={valorFrete} />
                <Valor label="Valor do desconto" valor={valorDesconto} />
                <Valor label="Valor dos itens" valor={valorItens} />
              </tbody>
            </Table>
          </FormGroup>
        </Col>
      </Row>
    </Card>
  );
}
