import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

import { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Button, Modal, Label } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';

import api from 'services/api';
import { PropostaContext } from 'views/pages/Proposta';
import InputSelect from 'components/InputSelect';
import toast from 'react-hot-toast';

import ptBr from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', ptBr);

interface iValoresAprovacao {
  propostaVersaoId: number;
  isModalOpen: boolean | false;
  setIsModalOpen: any;
  onSavePostback?: any;
}

interface iParamsLista {
  label: string;
  valor: any;
}

interface MeioPagamentoSgeOption {
  codigo: string;
  descricao: string;
}

export default function ModalValoresAprovacao({
  propostaVersaoId,
  onSavePostback,
  isModalOpen,
  setIsModalOpen,
}: iValoresAprovacao): JSX.Element {
  const { propostaVersao, carregaPropostaVersao } = useContext(PropostaContext);

  const [prazoInstalacao, setPrazoInstalacao] = useState<any>();
  const [tipoDadoGeodesico, setTipoDadoGeodesico] = useState('');
  const [valorGeodesico, setValorGeodesico] = useState('');
  const [valorGeodesicoSede, setValorGeodesicoSede] = useState('');
  const [valorGeodesicoCliente, setValorGeodesicoCliente] = useState('');
  const [valorGeodesicoCentro, setValorGeodesicoCentro] = useState('');
  const [meioPagamentoSge, setMeioPagamentoSge] = useState('');
  const [meioPagamentoSgeOptions, setMeioPagamentoSgeOptions] = useState<
    MeioPagamentoSgeOption[]
  >([]);

  const [isSaving, setIsSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isDadosCarregados, setDadosCarregados] = useState(false);

  const tipoDadoGeodesicoOptions = [
    { value: '', name: 'Selecione' },
    { value: 'C', name: 'Centro Pivô' },
    { value: 'E', name: 'Endereço do Cliente' },
    { value: 'S', name: 'Sede' },
    { value: 'M', name: 'Outro' },
  ];

  async function carregaInformacoes() {
    if (propostaVersaoId) {
      setIsLoading(true);
      setDadosCarregados(false);
      try {
        const response = await api.get(
          `/proposta/versao/${propostaVersaoId}/variaveis`,
        );

        const valores = response.data as iParamsLista[];

        const posicaoCentroPivo =
          valores?.find(item => item.label === `posicaoCentroPivo`)?.valor ??
          '';

        const clienteEnderecoLatitude =
          valores?.find(item => item.label === `clienteEnderecoLatitude`)
            ?.valor ?? '';

        const sedeCliente =
          valores?.find(item => item.label === `sede`)?.valor ?? '';

        const clienteEnderecoLongitude =
          valores?.find(item => item.label === `clienteEnderecoLongitude`)
            ?.valor ?? '';

        if (posicaoCentroPivo) setValorGeodesicoCentro(posicaoCentroPivo);

        if (sedeCliente) setValorGeodesicoSede(sedeCliente);

        if (clienteEnderecoLatitude && clienteEnderecoLongitude)
          setValorGeodesicoCliente(
            `${clienteEnderecoLatitude},${clienteEnderecoLongitude}`,
          );

        setDadosCarregados(true);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function listaMeioPagamentoSge() {
    const response = await api.get('/integracao/meio-pagamento-sge', {
      params: { propostaVersaoId },
    });

    setMeioPagamentoSgeOptions(response.data);
  }

  useEffect(() => {
    listaMeioPagamentoSge();
  }, []);

  useEffect(() => {
    carregaInformacoes();
  }, []);

  useEffect(() => {
    if (propostaVersao && isDadosCarregados === true) {
      if (propostaVersao.prazoInstalacao)
        setPrazoInstalacao(new Date(propostaVersao.prazoInstalacao));
      else setPrazoInstalacao(new Date());

      setTipoDadoGeodesico(propostaVersao.tipoDadoGeodesico ?? '');
      setValorGeodesico(propostaVersao.valorDadoGeodesico ?? '');
      setMeioPagamentoSge(propostaVersao.meioPagamentoSge ?? '');

      if (!propostaVersao.valorDadoGeodesico) {
        if (propostaVersao.tipoDadoGeodesico !== 'M') {
          if (propostaVersao.tipoDadoGeodesico === 'S')
            setValorGeodesico(valorGeodesicoSede ?? '');
          if (propostaVersao.tipoDadoGeodesico === 'C')
            setValorGeodesico(valorGeodesicoCentro ?? '');
          if (propostaVersao.tipoDadoGeodesico === 'E')
            setValorGeodesico(valorGeodesicoCliente ?? '');
        }
      } else {
        setValorGeodesico(propostaVersao.valorDadoGeodesico);
      }
    }
  }, [propostaVersao, isDadosCarregados]);

  async function salvar() {
    if (
      !prazoInstalacao ||
      !tipoDadoGeodesico ||
      !valorGeodesico ||
      !meioPagamentoSge
    ) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }
    setIsSaving(true);
    try {
      await api.put(`/proposta/versao/${propostaVersaoId}/variaveis`, {
        valorGeodesico,
        tipoDadoGeodesico,
        prazoInstalacao,
        meioPagamentoSge,
      });

      if (onSavePostback) onSavePostback();

      toast.success('Informações salvas');
      setIsModalOpen(false);
    } finally {
      carregaPropostaVersao();
      setIsSaving(false);
    }
  }
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Informações necessárias para status <i>APROVADA</i>
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className="modal-body">
          {isLoading && (
            <>
              <small>Buscando informações. Por favor, aguarde...</small>
            </>
          )}
          <FormGroup>
            <Label className="form-control-label">Meio Pagamento SGE*:</Label>
            <InputSelect
              options={[
                { value: '', name: 'Selecione' },
                ...meioPagamentoSgeOptions.map(option => ({
                  value: option.codigo,
                  name: option.descricao,
                })),
              ]}
              value={meioPagamentoSge}
              hideNoOption
              disabled={isLoading}
              onChange={e => setMeioPagamentoSge(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label">Prazo Instalação*: </Label>
            <DatePicker
              locale="pt-BR"
              dateFormat="dd/MM/yyyy"
              showIcon
              disabled={isLoading}
              selected={prazoInstalacao}
              onChange={date => setPrazoInstalacao(date)}
            />
          </FormGroup>

          <FormGroup>
            <Label className="form-control-label">
              Local de Descarregamento dos Materiais*:
            </Label>
            <InputSelect
              options={tipoDadoGeodesicoOptions}
              value={tipoDadoGeodesico}
              hideNoOption
              disabled={isLoading}
              onChange={text => {
                setTipoDadoGeodesico(text.target.value);
                if (text.target.value !== 'M') {
                  if (text.target.value === 'S')
                    setValorGeodesico(valorGeodesicoSede ?? '');
                  if (text.target.value === 'C')
                    setValorGeodesico(valorGeodesicoCentro ?? '');
                  if (text.target.value === 'E')
                    setValorGeodesico(valorGeodesicoCliente ?? '');
                } else {
                  setValorGeodesico('');
                }
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label className="form-control-label">
              Coordenadas Geográficas*:
            </Label>
            <Input
              type="text"
              disabled={tipoDadoGeodesico !== 'M' || isLoading}
              value={valorGeodesico || ''}
              onChange={text => setValorGeodesico(text.target.value)}
            />
          </FormGroup>
        </div>

        <div className="modal-footer">
          <Button
            className="my-4"
            disabled={isSaving || isLoading}
            color="primary"
            type="button"
            onClick={() => salvar()}
          >
            {isSaving ? `Salvando...` : `Salvar`}
          </Button>
        </div>
      </Modal>
    </>
  );
}
