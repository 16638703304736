import { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

interface AdutoraTubulacao {
  seq: number;
  nome: string;
  dn: number | null;
  de: number | null;
  e: number | null;
  di: number | null;
  coeficiente: number | null;
  pn: number | null;
  velocidadeMaximaAgua: number | null;
}

export interface SegTrechos {
  distAcc: number;
  totalAcc: number;
  cotaIni: number;
  cotaFin: number;
  classePN: string;
  pFinal: number;
  diametro: number;
  menorElev: number;
  mED: number;
}

export interface ListaTrechosPn {
  distancia: number;
  elevacao: number;
  hf: number;
  pFin: number;
  pn: number;
  di: number;
}

function getClassePN(pressaoI: number, pressaoF: number) {
  let p = pressaoI;
  if (pressaoF > p) p = pressaoF;

  let retorno = 0;
  if (p <= 58) {
    retorno = 60;
  } else if (p > 58 && p <= 78) {
    retorno = 80;
  } else if (p > 78 && p <= 122) {
    retorno = 125;
  } else if (p > 122 && p <= 138) {
    retorno = 140;
  } else if (p > 138 && p <= 158) {
    retorno = 160;
  } else if (p > 158 && p <= 178) {
    retorno = 180;
  } else {
    retorno = 0;
  }
  return retorno;
}

function getHF(
  diametro: number,
  vazao: number,
  distancia: number,
  coeficiente: number,
) {
  // eslint-disable-next-line no-restricted-properties
  const diX = Math.pow(diametro / 1000, 4.87);
  // eslint-disable-next-line no-restricted-properties
  const vaX = Math.pow(vazao / (3600 * coeficiente), 1.85);
  const hf = (distancia / diX) * 10.643 * vaX;
  return hf;
}

export function getElevPonto(
  adutoraTubulacaoTrechos: any[],
  listaTrechosPn: ListaTrechosPn[],
  comprimento: number,
  ordem: number,
) {
  let distAcc = 0;
  let primeiroMaior = null;
  let distanciaTotal = 0;
  if (
    adutoraTubulacaoTrechos.length > 0 &&
    listaTrechosPn.length > 0 &&
    comprimento
  ) {
    for (let i = 0; i < ordem - 1; i += 1) {
      distAcc += Number(adutoraTubulacaoTrechos[i]?.comprimento);
    }

    distAcc += comprimento;

    for (let i = 0; i < listaTrechosPn.length; i += 1) {
      distanciaTotal += listaTrechosPn[i].distancia;
      if (distanciaTotal - 6 >= distAcc && i > 0) {
        primeiroMaior = listaTrechosPn[i].elevacao;
        break;
      }
    }
    if (!primeiroMaior) {
      primeiroMaior = listaTrechosPn[listaTrechosPn.length - 1].elevacao;
    }
  }
  return primeiroMaior;
}

export async function getTrechosAdutora(
  tubosPVC: AdutoraTubulacao[] = [],
  diametroAZ: number,
  coeficienteAZ: number,
  diametro: number,
  elevacoes: any = [],
  fichaTecnica: any,
  fichaTecnicaAdutora: any,
  adutoraTotal: number,
) {
  const {
    perdaCargaTuboPivo,
    alturaSuccao,
    pressaoTotalCentroPivo,
    vazaoCAF,
    vazaoSAF,
    incluirAspersorFinal,
  } = fichaTecnica;

  const { percentualPerdaCarga, desnivelCaptacaoCentroPivo } =
    fichaTecnicaAdutora;

  const usaAspersorFinal: boolean =
    String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
    String(incluirAspersorFinal).toUpperCase() === 'S';

  const vazaoP = usaAspersorFinal ? Number(vazaoCAF) : Number(vazaoSAF);

  let perdasHF = 0;
  let perdasAdutora = 0;
  let perdaLocal = 0;
  let mca = 0;

  // Prepara lista Elevações ******************************* 1 ***********************
  // ###################################################################################
  let listaTrechos: any[];
  let seguimentosTrechos: SegTrechos[];
  let distAdutoraTotal = 12;
  // eslint-disable-next-line prefer-const
  listaTrechos = [];
  // eslint-disable-next-line prefer-const
  seguimentosTrechos = [];

  const hfAz = getHF(diametroAZ, vazaoP, 12, coeficienteAZ); // <===reajustar para buscar do cadastro

  listaTrechos.push({
    distancia: 12,
    elevacao: elevacoes[0]?.elevacao,
    hf: hfAz,
    pressao: 0,
    pn: 125,
    di: diametroAZ,
  });

  const info = {
    perdaLocal,
    hfAz,
    perdaCargaTuboPivo,
    percentualPerdaCarga,
    desnivelCaptacaoCentroPivo,
    alturaSuccao,
    pressaoTotalCentroPivo,
    perdasHF,
    vazaoCAF,
    vazaoSAF,
    incluirAspersorFinal,
    perdasAdutora,
    mca,
  };

  if (vazaoP) {
    for (let i = 1; i < elevacoes.length; i += 1) {
      const distancia = elevacoes[i].distancia - elevacoes[i - 1].distancia;
      const { elevacao } = elevacoes[i];
      listaTrechos.push({
        distancia,
        elevacao,
        hf: 0,
        pressao: 0,
        pn: 125,
        di: 0,
      });
    }

    // Inicio calculo de pressao ******************************* 2 ***********************
    // ###################################################################################

    for (let k = 0; k < 4; k += 1) {
      // aguardeProcessando(k, 1);

      // if (k === 3) {
      //   listaTrechos = Verifica_PN_Dist(listaTrechos);
      // }

      perdasAdutora = perdasHF;

      perdaLocal =
        (perdasAdutora + perdaCargaTuboPivo) * (percentualPerdaCarga / 100);

      mca =
        desnivelCaptacaoCentroPivo +
        alturaSuccao +
        pressaoTotalCentroPivo +
        perdasAdutora +
        perdaLocal;

      perdasHF = 0;
      let pIni = mca;
      let pFin = 0;
      let eIni = 0;
      let eFin = 0;
      const altSuc = alturaSuccao;
      let pnAgora = 0;
      let di = 0;
      let bandaPn = 0.2;
      let pnfAnt = 180;

      for (let i = 0; i < listaTrechos.length; i += 1) {
        const { elevacao, distancia, pn } = listaTrechos[i];
        // tubo
        if (pnAgora !== pn) {
          const tuboFiltrado = tubosPVC.filter(item => item.pn === pn)[0];
          di = tuboFiltrado?.di || 0;
          pnAgora = pn;
        }

        // hf tubo
        let hf = getHF(di, vazaoP, distancia, 140);

        // elevacao
        eFin = elevacao;

        if (i === 0) {
          eIni = elevacao;
          hf = hfAz;
          // const xhfAz = getHF(diametroAZ, vazaoP, 12, 135); // <===reajustar para buscar do cadastro
          const xhfAz = getHF(diametroAZ, vazaoP, 12, coeficienteAZ);

          pFin = pIni - (eFin - eIni) - xhfAz - altSuc;
        } else {
          eIni = listaTrechos[i - 1].elevacao;
          pFin = pIni - (eFin - eIni) - hf;
        }

        // controla mudança de Pressao
        const pnf = getClassePN(pIni + bandaPn, pFin + bandaPn);
        if (pnf > pnfAnt) {
          bandaPn = 0.7;
          pnfAnt = pnf;
        }

        if (pnf < pnfAnt) {
          bandaPn = 0.2;
          pnfAnt = pnf;
        }

        pIni = pFin;

        perdasHF += hf;
        listaTrechos[i] = { distancia, elevacao, hf, pFin, pn: pnf, di };
      }
    }

    // Finaliza Trechos ******************************* 3 ***********************
    // ###################################################################################
    let pnIni = listaTrechos[1]?.pn;
    let pFinal = listaTrechos[1]?.pFin;
    let cotaIni = listaTrechos[0]?.elevacao;
    let cotaFin = listaTrechos[1]?.elevacao;
    let distAcc = 12;
    let classePN = `AZ ${diametroAZ}`;
    let menorElev = 9999;
    let mED = 0;

    seguimentosTrechos.push({
      distAcc,
      totalAcc: 0,
      cotaIni,
      cotaFin,
      classePN,
      pFinal,
      diametro: diametroAZ,
      menorElev: cotaIni,
      mED,
    });

    distAcc = -6;
    for (let i = 1; i < listaTrechos.length; i += 1) {
      const { elevacao, distancia, pn } = listaTrechos[i];

      if (pnIni !== pn) {
        cotaFin = listaTrechos[i - 1].elevacao;
        pFinal = listaTrechos[i - 1].pFin;
        classePN = `PVC ${diametro}/${listaTrechos[i - 1].pn}`;

        seguimentosTrechos.push({
          distAcc,
          totalAcc: 0,
          cotaIni,
          cotaFin,
          classePN,
          pFinal,
          diametro,
          menorElev,
          mED,
        });

        distAdutoraTotal += distAcc;
        pnIni = pn;
        cotaIni = cotaFin;
        distAcc = distancia;
        menorElev = 9999;
        mED = 0;
      } else {
        distAcc += distancia;
        // if (elevacao < menorElev) {
        if (elevacao < menorElev) {
          menorElev = elevacao;
          mED = distAcc;
        }
      }
    }
    if (distAcc > 0) {
      distAdutoraTotal += distAcc;
      cotaFin = listaTrechos[listaTrechos.length - 1].elevacao;
      pFinal = listaTrechos[listaTrechos.length - 1].pFin;
      classePN = `PVC ${diametro}/${listaTrechos[listaTrechos.length - 1].pn}`;
      seguimentosTrechos.push({
        distAcc,
        totalAcc: 0,
        cotaIni,
        cotaFin,
        classePN,
        pFinal,
        diametro,
        menorElev,
        mED,
      });
    }
  }
  // restante no 2 trecho
  // alert(adutoraTotal - distAdutoraTotal);
  if (seguimentosTrechos.length > 0)
    seguimentosTrechos[seguimentosTrechos.length - 1].distAcc +=
      adutoraTotal - distAdutoraTotal;

  // cota intermediária
  for (let i = 0; i < seguimentosTrechos.length; i += 1) {
    seguimentosTrechos[i].cotaFin = Number(
      seguimentosTrechos[i].cotaFin?.toFixed(1),
    );
    const seg = seguimentosTrechos[i];
    const difI = seg.menorElev - seg.cotaIni;
    const difF = seg.menorElev - seg.cotaFin;

    if (difI <= -1.0 && difF <= -1.0) {
      const difC = seg.distAcc - seg.mED;
      const newSeg = JSON.parse(JSON.stringify(seg));

      seguimentosTrechos[i].cotaFin = Number(seg.menorElev.toFixed(1));
      seguimentosTrechos[i].distAcc = seg.mED;
      i += 1;
      seguimentosTrechos.splice(i, 0, newSeg);
      seguimentosTrechos[i].cotaIni = newSeg.menorElev;
      seguimentosTrechos[i].distAcc = difC;
    } else {
      seguimentosTrechos[i].mED = -1;
    }
  }
  let totalAcc = 0;
  let restoAcc = 0;
  for (let i = 0; i < seguimentosTrechos.length; i += 1) {
    if (seguimentosTrechos[i].distAcc < 1) {
      restoAcc += seguimentosTrechos[i].distAcc;
      seguimentosTrechos.splice(i, 1);
    }
    totalAcc += seguimentosTrechos[i].distAcc;
    seguimentosTrechos[i].totalAcc = totalAcc;
  }
  seguimentosTrechos[seguimentosTrechos.length - 1].distAcc += restoAcc;
  seguimentosTrechos[seguimentosTrechos.length - 1].totalAcc += restoAcc;

  // navigator.clipboard.writeText(`${JSON.stringify(seguimentosTrechos)}`); // Temporário só para testes
  return {
    listaTrechos,
    seguimentosTrechos,
    info,
    fichaTecnica,
    fichaTecnicaAdutora,
  };
}
