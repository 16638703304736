import { ReactNode, useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Button,
  Modal,
  CardBody,
  Form,
  Input,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import toast from 'react-hot-toast';

import { Link } from 'react-router-dom';
import { Avatar, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { FaEdit, FaHistory, FaPlusCircle } from 'react-icons/fa';
import ModalLog from 'components/ModalLog';
import getInitials from '../../../utils/getInitials';

import Usuario from '../../../models/Usuario';

import api from '../../../services/api';

const UsuarioPercentual: ReactNode = () => {
  const [usuarios, setUsuarios] = useState([] as Usuario[]);
  const [usuarioEditing, setUsuarioEditing] = useState({} as Usuario);

  const [formModalState, setFormModalState] = useState(false);
  const [modalLogVisivel, setModalLogVisivel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modalProp, setModalProp] = useState('valorDolar');

  async function carregaUsuarios() {
    const response = await api.get('/usuario');
    setUsuarios(response.data);
  }

  useEffect(() => {
    carregaUsuarios();
  }, []);

  function handleUpdate(usuario: Usuario) {
    setUsuarioEditing(usuario);

    setFormModalState(!formModalState);
  }

  async function handleSave() {
    // eslint-disable-next-line prettier/prettier
    const { percentualAcrescimoProposta, percentualAssistenciaTecnicaProposta, id } = usuarioEditing;

    setIsSaving(true);

    await api.put(`/usuario/${id}/percentual`, {
      id,
      percentualAcrescimoProposta,
      percentualAssistenciaTecnicaProposta,
    });

    toast.success('Registro salvo');

    setFormModalState(false);
    setIsSaving(false);
    setUsuarioEditing({} as Usuario);
    carregaUsuarios();
  }

  async function mostrarModalLog(usuario: Usuario) {
    setModalProp(`${usuario.id}`);
    setModalLogVisivel(true);
  }

  return (
    <>
      <Header showCards={false} />
      <ModalLog
        nomeChave="usuario_percentual_id"
        valorChave={modalProp}
        titulo="Logs"
        modalState={modalLogVisivel}
        toggle={() => setModalLogVisivel(false)}
      />
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <small>Percentual de Acréscimo na Proposta:</small>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Percentual de Acréscimo na Proposta"
                      value={usuarioEditing.percentualAcrescimoProposta ?? 0}
                      type="number"
                      onChange={text =>
                        setUsuarioEditing({
                          ...usuarioEditing,
                          percentualAcrescimoProposta: Number(
                            text.target.value,
                          ),
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <small>Percentual de Assistência Técnica na Proposta:</small>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Percentual de Assistência Técnica na Proposta"
                      value={
                        usuarioEditing.percentualAssistenciaTecnicaProposta ?? 0
                      }
                      type="number"
                      onChange={text =>
                        setUsuarioEditing({
                          ...usuarioEditing,
                          percentualAssistenciaTecnicaProposta: Number(
                            text.target.value,
                          ),
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={isSaving}
                    onClick={handleSave}
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Usuários - Percentuais</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th>Usuário</th>
                    <th>Acréscimo (%)</th>
                    <th>% Assistência Técnica (%)</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {usuarios
                    .filter(item => item.isAtivo === true)
                    .map(usuario => {
                      let styleDesativado = {};
                      if (usuario.isAtivo) {
                        styleDesativado = {
                          marginRight: '0.8rem',
                          color: 'unset',
                        };
                      } else {
                        styleDesativado = {
                          backgroundColor: '#d1cfcf',
                          color: '#A9A9A9',
                          textDecoration: 'line-through',
                        };
                      }
                      return (
                        <tr key={usuario.id}>
                          <th scope="row">
                            <Link
                              style={{ ...styleDesativado }}
                              to={{
                                pathname: `/admin/cadastro-usuario`,
                                state: { usuario },
                              }}
                            >
                              <Media className="align-items-center">
                                <div className="avatar-group">
                                  <Avatar
                                    src={usuario?.imagem}
                                    style={{ backgroundColor: deepPurple[800] }}
                                  >
                                    {getInitials(usuario.nome)}
                                  </Avatar>
                                </div>

                                <span className="mb-0 ml-3 text-sm">
                                  {usuario.nome}
                                  <br />
                                  <small>
                                    {usuario.usernameDomSGE
                                      ? `Username DomSGE: ${usuario.usernameDomSGE}`
                                      : ``}
                                  </small>
                                </span>
                              </Media>
                            </Link>
                          </th>
                          <td>{usuario?.percentualAcrescimoProposta ?? 0}%</td>
                          <td>
                            {usuario?.percentualAssistenciaTecnicaProposta ?? 0}
                            %
                          </td>
                          <td className="py-2">
                            <div className="float-right">
                              <Tooltip title="Logs">
                                <Button
                                  size="sm"
                                  color="default"
                                  outline
                                  onClick={() => mostrarModalLog(usuario)}
                                >
                                  <FaHistory />
                                </Button>
                              </Tooltip>

                              <Button
                                size="sm"
                                className="btn-icon"
                                color="warning"
                                type="button"
                                onClick={() => {
                                  handleUpdate(usuario);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UsuarioPercentual;
