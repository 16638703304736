import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';

import OrcamentoCalculoServicoLogs from 'models/OrcamentoCalculoServicoLogs';
import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';

interface Params {
  exibeLogs: boolean;
  orcamentoId: number;
}

export default function LogsCalculoServico({
  exibeLogs,
  orcamentoId,
}: Params): JSX.Element {
  const [logs, setLogs] = useState([] as OrcamentoCalculoServicoLogs[]);

  async function listaLogs() {
    if (orcamentoId) {
      const response = await api.get(
        `/integracao/calculo-servico-logs/${orcamentoId}`,
      );
      setLogs(response.data);
    }
  }

  useEffect(() => {
    listaLogs();
  }, [orcamentoId]);

  return (
    <Card className="shadow mt-1 mb-1" hidden={!exibeLogs}>
      <CardBody>
        <Row className="ml-0">
          <h6 className="heading-small text-muted mb-1">
            Logs da configuração {} - {orcamentoId}
          </h6>
        </Row>
        <hr className="mt-2" />

        <Table responsive bordered>
          <thead>
            <tr>
              <th>
                <strong>Nome</strong>
              </th>
              <th colSpan={2}>
                <strong>Valor</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {logs.map((item: OrcamentoCalculoServicoLogs) => {
              return (
                <tr key={item.id}>
                  <td className="p-0 pl-2 pr-2">{item.nome}</td>
                  <td className="p-0 pl-2 pr-2">
                    {item.indicTipo?.toUpperCase() === 'O' ||
                    item.indicTipo?.toUpperCase() === 'X'
                      ? item.valor
                      : formatCurrency(item.valor)}
                  </td>
                  <td className="p-0 pl-2 pr-2">{item.texto}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
