import { Input } from 'reactstrap';
import styled from 'styled-components';

interface Props {
  small?: boolean;
}

export const ColLabel = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  vertical-align: middle;
  padding-top: 0.6rem;
`;

export const Label = styled.label<Props>`
  font-size: 0.7rem;
  font-weight: 600;
  color: #8898aa;
`;

export const Valor = styled(Input)<Props>`
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
  max-height: 70%;
  margin-top: 0;
  ${props => (props.number ? 'text-align: right' : '')}
`;
