import FichaTecnica from 'models/FichaTecnica';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import { Container, Td, Th } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function CabosLancesPivo(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const { FichaTecnicaCabosLancesPivo } = fichaTecnica;
  const { quedaTensaoV2v, quedaTensaoV2p, tensaoBalanco } = fichaTecnica;

  const campos = [
    {
      label: 'Queda Tensão ΔV2 v',
      valor: quedaTensaoV2v,
      unidade: 'V',
    },
    {
      label: 'Queda Tensão ΔV2 %',
      valor: quedaTensaoV2p,
      unidade: '%',
    },
    {
      label: 'Tensão no Balanço',
      valor: tensaoBalanco,
      unidade: 'V',
    },
  ];

  return (
    <Container>
      <Card className="shadow mt-2">
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>
                  Cabos dos lances do Pivô
                </LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <Table bordered responsive>
              <tr>
                <Th>Nº</Th>
                <Th>Lance</Th>
                <Th>Corrente (A)</Th>
                <Th>Corrente (A)</Th>
                <Th>Comprimento (km)</Th>
                <Th>Seção (mm²)</Th>
                <Th>K (V/A km)</Th>
                <Th>Queda no trecho (V)</Th>
                <Th>Queda no trecho (%)</Th>
                <Th>Queda % Acumulada</Th>
                <Th>Tensão no Lance (V)</Th>
              </tr>

              {FichaTecnicaCabosLancesPivo?.map(cabo => {
                const {
                  id,
                  ordem,
                  colunaK,
                  comprimento,
                  correnteAcumulada,
                  correnteLance,
                  percQuedaAcumulada,
                  quedaTrecho,
                  quedaTrechoPerc,
                  secao,
                  siglaLances,
                  siglaTubos,
                  tensaoLance,
                } = cabo;

                const correnteLanceArredondado =
                  arredondaDecimais(correnteLance);

                const quedaTrechoArredondado = arredondaDecimais(quedaTrecho);

                const quedaTrechoPercArredondado =
                  arredondaDecimais(quedaTrechoPerc);

                const percQuedaAcumuladaArredondado =
                  arredondaDecimais(percQuedaAcumulada);

                return (
                  <tr key={id}>
                    <Td>{ordem}</Td>
                    <Td>
                      {siglaLances} {siglaTubos}
                    </Td>
                    <Td>{correnteLanceArredondado}</Td>
                    <Td>{correnteAcumulada}</Td>
                    <Td>{comprimento}</Td>
                    <Td>{secao}</Td>
                    <Td>{colunaK}</Td>
                    <Td>{quedaTrechoArredondado}</Td>
                    <Td>{quedaTrechoPercArredondado}</Td>
                    <Td>{percQuedaAcumuladaArredondado}</Td>
                    <Td>{tensaoLance}</Td>
                  </tr>
                );
              })}
            </Table>

            <Col className="p-3 ml--6">
              <Row>
                {campos.map(campo => (
                  <Campo
                    sm="4"
                    key={campo.label}
                    label={campo.label}
                    valor={campo.valor ?? ''}
                    unidade={campo.unidade}
                  />
                ))}
              </Row>
            </Col>
          </Card>
        </CardBody>
      </Card>
    </Container>
  );
}

export default CabosLancesPivo;
