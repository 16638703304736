import { useContext, useEffect, useState } from 'react';
import { Row, Col, Table } from 'reactstrap';
import formataCnpjCpf from 'utils/formataCnpjCpf';
import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';
import Proposta from 'models/Proposta';
import { PropostaVersao } from 'models/PropostaVersao';
import { AceiteTexto, AssinaturaCol, Container, Label } from './styles';

interface Props {
  propostaVersaoId: number;
  propostaVersao?: PropostaVersao;
}

export default function FotovoltaicaParcelas(props: Props): JSX.Element {
  const { propostaVersaoId, propostaVersao } = props;

  const [fotovoltaicaViabilidade, setfotovoltaicaViabilidade] = useState(
    [] as any[],
  );

  async function getViabilidadeFotovoltaica() {
    if (
      propostaVersao &&
      (propostaVersao.PropostaConfiguracao?.length ?? 0) > 0
    ) {
      const orcamentoId =
        propostaVersao?.PropostaConfiguracao?.[0]?.orcamentoId;

      const response2 = await api.get(
        `/fotovoltaica/viabilidade-formatado?orcamentoId=${orcamentoId}`,
        {},
      );

      setfotovoltaicaViabilidade(response2.data);
    }
  }

  useEffect(() => {
    getViabilidadeFotovoltaica();
  }, []);

  return (
    <Row>
      <Col sm="12" className="mt-3">
        <Table className="align-items-center table-flush" responsive>
          <thead>
            <tr>
              <th>Ano</th>
              <th>Parcelas de pagamento</th>
              <th>Economia gerada pelo sistema FV</th>
            </tr>
          </thead>
          <tbody>
            {fotovoltaicaViabilidade.map((item: any) => {
              return (
                <tr>
                  <td>{item.anoCorrente}</td>
                  <td>{formatCurrency(item.parcelasPgto ?? 0)}</td>
                  <td>{formatCurrency(item.economiaUsina ?? 0)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
