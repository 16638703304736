const MatrizResponsabilidadeEspelharDe = [
  {
    sigla: 'LO Frete',
    descricao: 'Local da Obra - Condição de Frete',
  },
  {
    sigla: 'MO Supervisao',
    descricao: 'Mão de Obra - Mão de obra supervisão (Montador/Técnica)',
  },
  {
    sigla: 'MO Auxiliar',
    descricao: 'Mão de Obra - Mão de obra auxiliar',
  },
  {
    sigla: 'MO Alim',
    descricao: 'Mão de Obra - Alimentação',
  },
  {
    sigla: 'MO Estadia',
    descricao: 'Mão de Obra - Estadia',
  },
  {
    sigla: 'MO Munck',
    descricao: 'Mão de Obra - Munck',
  },
  {
    sigla: 'MO Retro',
    descricao: 'Mão de Obra - Retroescavadeira',
  },
  {
    sigla: 'MO Descarga',
    descricao: 'Mão de Obra - Descarga de material',
  },
  {
    sigla: 'MO Civil',
    descricao: 'Mão de Obra - Civil',
  },
  {
    sigla: 'MO Deslocamento',
    descricao: 'Mão de Obra - Deslocamento cidade/obra',
  },
];

export default MatrizResponsabilidadeEspelharDe;
