import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaPen,
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaCamera,
} from 'react-icons/fa';

import api from 'services/api';

import Compress from 'compress.js';
import ModalUploadImagem from 'components/ModalUploadImagem';
import { Tooltip } from '@material-ui/core';
import ModeloSuccaoModel from '../../../models/ConfiguradorModeloSuccao';
import TipoSuccaoModel from '../../../models/ConfiguradorTipoSuccao';

const ConfiguradorSuccao: React.FC = () => {
  const [modeloSuccao, setModeloSuccao] = useState([] as ModeloSuccaoModel[]);
  const [tipoSuccao, setTipoSuccao] = useState([] as TipoSuccaoModel[]);
  const [modeloSelecionado, setModeloSelecionado] =
    useState<ModeloSuccaoModel>();
  const [tipoTubo, setTipoTubo] = useState('');
  const [exibirModalImagem, setExibirModalImagem] = useState(false);
  const [exibirModalImagemQuestionario, setExibirModalImagemQuestionario] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [modeloSuccaoEditing, setModeloSuccaoEditing] = useState(
    {} as ModeloSuccaoModel,
  );

  const [notificationModalState, setNotificationModalState] = useState(false);
  const [formModalState, setFormModalState] = useState(false);
  const [imagemSelecionada, setImagemSelecionada] = useState('');
  const [salvandoModeloSuccao, setSalvandoModeloSuccao] = useState(false);

  async function listaModeloSuccao() {
    const response = await api.get('/integracao/succao/modelo-succao');

    setModeloSuccao(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaModeloSuccao();
  }, []);

  useEffect(() => {
    if (modeloSelecionado) {
      setExibirModalImagem(true);
    }
  }, [modeloSelecionado]);

  useEffect(() => {
    if (exibirModalImagemQuestionario) {
      setImagemSelecionada(modeloSuccaoEditing.imagemQuestionario);
    } else if (exibirModalImagem) {
      setImagemSelecionada(modeloSuccaoEditing.imagem);
    } else {
      setImagemSelecionada('');
    }
  }, [exibirModalImagemQuestionario, exibirModalImagem]);

  async function handleDelete(tuboId: number) {
    Swal.fire({
      title: `Deseja excluir o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/integracao/succao/modelo-succao/${tuboId}`);

        listaModeloSuccao();
        Swal.close();
      }
    });
  }

  async function comprimeImagem(imagem: string | null): Promise<string> {
    if (!imagem) {
      return '';
    }
    const compress = new Compress();
    const file = Compress.convertBase64ToFile(imagem?.split('base64,')[1]);
    const fileCompressed = await compress.compress([file], {
      quality: 0.7,
      maxWidth: 230,
      maxHeight: 176,
      resize: true,
    });
    return fileCompressed[0].data;
  }

  function closeModalImagem() {
    setExibirModalImagem(!exibirModalImagem);
    setExibirModalImagemQuestionario(!exibirModalImagemQuestionario);
    setModeloSelecionado(undefined);
  }

  async function salvarImagem(imagem: string | null) {
    if (!modeloSelecionado?.id) return;
    if (exibirModalImagemQuestionario) {
      try {
        setLoading(true);
        const imagemComprimida = await comprimeImagem(imagem);
        await api.put(
          `/integracao/succao/modelo-succao/imagem-questionario/${modeloSelecionado.id}`,
          {
            imagemQuestionario: imagemComprimida || null,
          },
        );
        toast.success('Registro concluído');
        Swal.close();
      } catch (error: any) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: error?.response?.data?.message,
        });
        return;
      } finally {
        setLoading(false);
        closeModalImagem();
      }
    } else {
      try {
        setLoading(true);
        await api.put(
          `/integracao/succao/modelo-succao/imagem/${modeloSelecionado.id}`,
          {
            imagem: imagem ? imagem?.split('base64,')[1] : null,
          },
        );
        toast.success('Registro concluído');
        Swal.close();
      } finally {
        setLoading(false);
        closeModalImagem();
      }
    }
    listaModeloSuccao();
  }

  async function handleInsert() {
    setModeloSuccaoEditing({} as ModeloSuccaoModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(tubo: ModeloSuccaoModel) {
    setModeloSuccaoEditing(tubo);
    setFormModalState(!formModalState);
  }

  async function handleSaveTubo() {
    // eslint-disable-next-line prettier/prettier
    const { nome, cotaA, cotaB, cotaC, cotaD, cotaE, questaoValorSelecaoId, imagemQuestionario, }  = modeloSuccaoEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }
    try {
      setSalvandoModeloSuccao(true);
      if (modeloSuccaoEditing.id) {
        await api.put(
          `/integracao/succao/modelo-succao/${modeloSuccaoEditing.id}`,
          {
            nome,
            cotaA,
            cotaB,
            cotaC,
            cotaD,
            cotaE,
            questaoId: 431,
            questaoValorSelecaoId,
          },
        );
      } else {
        const valorSelecao = await api.post(`/questao-valor-selecao`, {
          questaoId: 431,
          descricao: nome,
          imagem: imagemQuestionario,
        });
        await api.post(`/integracao/succao/modelo-succao`, {
          nome,
          questaoId: 431,
          questaoValorSelecaoId: valorSelecao.data?.id,
          cotaA: cotaA ?? 'S',
          cotaB: cotaB ?? 'N',
          cotaC: cotaC ?? 'N',
          cotaD: cotaD ?? 'N',
          cotaE: cotaE ?? 'N',
        });
      }
    } finally {
      setFormModalState(false);
      setModeloSuccaoEditing({} as ModeloSuccaoModel);
      toast.dismiss();
      setSalvandoModeloSuccao(false);
      toast.success('Registro concluído');
      listaModeloSuccao();
    }
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal alerta */}
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={notificationModalState}
              toggle={() => setNotificationModalState(!notificationModalState)}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  {/* Your attention is required */}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">
                    Não é possível remover o tubo!
                  </h4>
                  <p>
                    Esse tubo possui {tipoSuccao.length} lance(s). Remova-o(s)
                    para prosseguir com a exclusão.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Entendi
                </Button> */}
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  Fechar
                </Button>
              </div>
            </Modal>
            <ModalUploadImagem
              isOpen={exibirModalImagem}
              toggle={closeModalImagem}
              onSave={salvarImagem}
              imagem={imagemSelecionada}
              descricao={modeloSelecionado?.nome || ''}
            />

            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Modelo {modeloSuccaoEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <small>Modelo Sucção *</small>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Modelo Sucção"
                            value={modeloSuccaoEditing.nome}
                            type="text"
                            onChange={text =>
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Cota A</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo"
                            type="select"
                            value={modeloSuccaoEditing.cotaA ?? 'S'}
                            onChange={text =>
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                cotaA: text.target.value,
                              })
                            }
                          >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Cota B</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo"
                            type="select"
                            value={modeloSuccaoEditing.cotaB ?? 'N'}
                            onChange={text =>
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                cotaB: text.target.value,
                              })
                            }
                          >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Cota C</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo"
                            type="select"
                            value={modeloSuccaoEditing.cotaC ?? 'N'}
                            onChange={text =>
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                cotaC: text.target.value,
                              })
                            }
                          >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Cota D</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo"
                            type="select"
                            value={modeloSuccaoEditing.cotaD ?? 'N'}
                            onChange={text =>
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                cotaD: text.target.value,
                              })
                            }
                          >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Cota E</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo"
                            type="select"
                            value={modeloSuccaoEditing.cotaE ?? 'N'}
                            onChange={text =>
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                cotaE: text.target.value,
                              })
                            }
                          >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          disabled={salvandoModeloSuccao}
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveTubo}
                        >
                          {salvandoModeloSuccao ? 'Salvando...' : 'Salvar'}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Modelos de sucção</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th>Modelo</th>
                    <Tooltip title="As cotas marcadas como 'Sim' serão somadas no cálculo dos tubos de sucção">
                      <th>Cota A</th>
                    </Tooltip>
                    <Tooltip title="As cotas marcadas como 'Sim' serão somadas no cálculo dos tubos de sucção">
                      <th>Cota B</th>
                    </Tooltip>
                    <Tooltip title="As cotas marcadas como 'Sim' serão somadas no cálculo dos tubos de sucção">
                      <th>Cota C</th>
                    </Tooltip>
                    <Tooltip title="As cotas marcadas como 'Sim' serão somadas no cálculo dos tubos de sucção">
                      <th>Cota D</th>
                    </Tooltip>
                    <Tooltip title="As cotas marcadas como 'Sim' serão somadas no cálculo dos tubos de sucção">
                      <th>Cota E</th>
                    </Tooltip>
                    <th>{}</th>
                    <th>{}</th>
                    <th>{}</th>
                    <th>{}</th>
                  </tr>
                </thead>
                <tbody>
                  {modeloSuccao.map(tubo => (
                    <tr key={tubo.id}>
                      <td className="col">
                        <strong>{tubo.nome}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.cotaA === 'S' ? 'Sim' : 'Não'}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.cotaB === 'S' ? 'Sim' : 'Não'}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.cotaC === 'S' ? 'Sim' : 'Não'}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.cotaD === 'S' ? 'Sim' : 'Não'}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.cotaE === 'S' ? 'Sim' : 'Não'}</strong>
                      </td>
                      <td style={{ margin: 'none' }}>
                        <Link
                          to={{
                            pathname: `/admin/tipos-succao`,
                            state: { stateModelo: tubo },
                          }}
                        >
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="primary"
                            type="button"
                          >
                            <span>Tipos Sucção</span>
                          </Button>
                        </Link>
                      </td>
                      <td>
                        <Tooltip title="Imagem referente a tbubulação sucção da etapa de adutora e bombeamento">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="default"
                            type="button"
                            onClick={() => {
                              setExibirModalImagem(true);
                              setModeloSelecionado(tubo);
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                imagem: tubo?.imagem,
                              });
                            }}
                          >
                            <FaCamera />
                          </Button>
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title="Imagem referente ao questionário da ficha técnica">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="info"
                            type="button"
                            onClick={() => {
                              setExibirModalImagem(true);
                              setModeloSelecionado(tubo);
                              setExibirModalImagemQuestionario(true);
                              setModeloSuccaoEditing({
                                ...modeloSuccaoEditing,
                                imagemQuestionario: tubo?.imagemQuestionario,
                              });
                            }}
                          >
                            <FaCamera />
                          </Button>
                        </Tooltip>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(tubo);
                          }}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(tubo.id);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorSuccao;
