import { useContext } from 'react';
import { PropostaImpressaoContext } from '../..';
import { Container, Typography } from './styles';

export default function Titulo(): JSX.Element {
  const { parcelas, propostaVersao } = useContext(PropostaImpressaoContext);

  const propostaLinhaCredito = propostaVersao.PropostaLinhaCredito ?? null;

  const descricaoLinha1 = propostaLinhaCredito?.textoImpressaoLinha1 ?? '';
  const descricaoLinha2 = propostaLinhaCredito?.textoImpressaoLinha2 ?? '';

  /* const temMeioPagamentoFinanciado = parcelas.some(
    parcela => !!parcela.meioPagamento?.financiado,
  );
*/

  const temMeioPagamentoFinanciado =
    (propostaVersao.meioPagamento?.financiado ?? false) === true;

  const mostrarTexto = temMeioPagamentoFinanciado && descricaoLinha1;
  const mostrarTexto2 = temMeioPagamentoFinanciado && descricaoLinha2;

  return (
    <Container>
      <Typography variant="h5" className="mb-4">
        <strong>PROPOSTA COMERCIAL</strong>
      </Typography>
      <Typography variant="h6">
        <strong>{mostrarTexto ?? ''}</strong>
      </Typography>
      <Typography variant="h6">{mostrarTexto2 ?? ''}</Typography>
    </Container>
  );
}
