import Proposta from 'models/Proposta';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from 'services/api';

interface Params {
  codigo?: string;
  versao?: string;
}

export default function RouterProposta(): JSX.Element {
  const params = useParams<Params>();
  const history = useHistory();
  const codigoParam = params?.codigo;
  const versaoParam = params?.versao;

  async function functionNavigateToProposta() {
    try {
      if (codigoParam && versaoParam) {
        const propostaVersaoId = Number(versaoParam);
        const propostaId = Number(codigoParam);

        const response = await api.get(`/proposta`);

        const propostas = response.data as Proposta[];

        let proposta = null;
        let propostaVersao = null;

        // eslint-disable-next-line no-restricted-syntax
        for await (const registro of propostas.filter(
          item => item.id === propostaId,
        )) {
          proposta = registro;
          propostaVersao = registro.PropostaVersao?.find(
            item => item.id === propostaVersaoId,
          );
        }

        if (proposta && proposta.id) {
          history.push({
            pathname: '/admin/proposta',
            state: { proposta, propostaVersao, acessarAprovacao: true },
          });
        }
      }
    } finally {
      //
    }
  }

  useEffect(() => {
    if (codigoParam && versaoParam) {
      functionNavigateToProposta();
    }
  }, [codigoParam, versaoParam]);

  return <></>;
}
