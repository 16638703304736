import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';

import UserHeader from 'components/Headers/UserHeader';
import { Link, useLocation } from 'react-router-dom';

import api from 'services/api';
import { Avatar, TextField } from '@material-ui/core';
import getInitials from 'utils/getInitials';
import { deepOrange } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
import Cliente from '../../models/Pessoa';

type LocationState = {
  cliente?: Cliente;
};

const CadastroCliente: React.FC = () => {
  const location = useLocation<LocationState>();

  const [cliente, setCliente] = useState({} as Cliente);
  const [numeroNegocio, setNumeroNegocio] = useState(null || Number);

  // dados básicos
  const [razaoSocial, setRazaoSocial] = useState('');
  const [documento, setDocumento] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('');
  const [codigoDomsge, setCodigoDomsge] = useState('');

  const existeCliente = !!cliente?.razaoSocial;

  const tiposPessoa = [
    { id: 'F', value: 'Física' },
    { id: 'J', value: 'Jurídica' },
  ];

  function setDadosCliente(clienteData: Cliente) {
    setCliente(clienteData);

    setRazaoSocial(clienteData.razaoSocial);
    setDocumento(clienteData.documento);
    setTipoPessoa(clienteData.tipoPessoa);
    setCodigoDomsge(clienteData.codigoDomsge ?? '');
  }

  async function carregaCliente() {
    const clienteLocation = location.state?.cliente ?? null;

    if (!clienteLocation) {
      return;
    }

    setDadosCliente(clienteLocation);

    const response = await api.get(`/pessoa/${clienteLocation.id}`);

    setDadosCliente(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaCliente();
  }, []);

  async function salvaCliente() {
    const clienteObject = {
      razaoSocial,
      documento,
      tipoPessoa,
      codigoDomsge,
    };

    let response = null;

    if (cliente?.id) {
      response = await api.put(`/pessoa/${cliente.id}`, clienteObject);
    } else {
      response = await api.post(`/pessoa`, clienteObject);
    }

    setCliente(response.data);
  }

  async function cadastraClienteNumeroNegocio() {
    if (!numeroNegocio) {
      return;
    }

    try {
      Swal.fire({
        icon: 'info',
        text: `Importando número de negócio...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const response = await api.post(`/integracao/negocio`, { numeroNegocio });

      setDadosCliente(response.data);

      Swal.close();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
    }
  }

  async function handleFileRead(event: any) {
    async function convertBase64(file: File) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = error => {
          reject(error);
        };
      });
    }

    const file = event.target.files[0];

    const base64 = await convertBase64(file);
  }

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        {/* Cadastro via número negócio */}
        {!cliente?.id && (
          <Row className="mb-3">
            <Col className="order-xl-2 mb-5 mb-xl-0">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col sm="10">
                    <TextField
                      style={{ width: '100%' }}
                      label="Número de negócio"
                      type="number"
                      inputProps={{ min: '0', step: '1' }}
                      value={numeroNegocio}
                      onChange={text =>
                        setNumeroNegocio(Number(text.target.value))
                      }
                    />
                  </Col>
                  <Col className="text-right" sm="2">
                    <Button
                      color="primary"
                      onClick={cadastraClienteNumeroNegocio}
                    >
                      Cadastrar
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Col>
          </Row>
        )}
        <Row>
          {existeCliente && (
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#a">
                        {cliente?.imagem ? (
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={cliente.imagem}
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: deepOrange[500],
                              width: 180,
                              height: 180,
                              marginTop: '-3rem',
                              marginLeft: '-2.2rem',
                              marginBottom: '-7rem',
                            }}
                          >
                            <Label style={{ fontSize: '4rem' }}>
                              {getInitials(cliente.razaoSocial)}
                            </Label>
                          </Avatar>
                        )}
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5" />
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>{razaoSocial}</h3>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          <Col className="order-xl-1" xl={existeCliente ? '8' : '12'}>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Cadastro</h3>
                  </Col>
                  {existeCliente && (
                    <Col className="text-right" xs="4">
                      <Link
                        to={{
                          pathname: `/admin/cadastro-orcamento-novo`,
                          state: { pessoa: cliente },
                        }}
                      >
                        <Button color="primary" size="sm">
                          Gerar configuração
                        </Button>
                      </Link>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Dados básicos
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-razao-social"
                          >
                            Razão Social
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="lucky.jesse"
                            id="input-razao-social"
                            type="text"
                            value={razaoSocial}
                            onChange={text => setRazaoSocial(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-documento"
                          >
                            Documento
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            value={documento}
                            onChange={text => setDocumento(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Tipo de pessoa
                          </label>
                          <UncontrolledDropdown style={{ display: 'flex' }}>
                            <DropdownToggle
                              style={{ width: '100%', textAlign: 'start' }}
                              caret
                            >
                              {tiposPessoa.find(tipo => tipo.id === tipoPessoa)
                                ?.value ?? 'Selecione'}
                            </DropdownToggle>
                            <DropdownMenu>
                              {tiposPessoa.map(tipo => {
                                return (
                                  <DropdownItem
                                    key={tipo.id}
                                    value={tipo.id}
                                    onClick={event => {
                                      setTipoPessoa(
                                        event.currentTarget.getAttribute(
                                          'value',
                                        ) as string,
                                      );
                                    }}
                                  >
                                    {tipo.value}
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-codigo-domsge"
                          >
                            Código DomSGE
                          </label>
                          <Input
                            disabled
                            className="form-control-alternative"
                            id="input-codigo-domsge"
                            type="number"
                            value={codigoDomsge}
                            onChange={text =>
                              setCodigoDomsge(text.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <Input
                          id="originalFileName"
                          type="file"
                          inputProps={{
                            accept:
                              'image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt',
                          }}
                          style={{ display: 'none' }}
                          required
                          label="Document"
                          name="originalFileName"
                          onChange={e => handleFileRead(e)}
                          variant="standard"
                        />
                      </Col>
                    </Row>
                  </div>

                  <Row className="align-items-center">
                    <Col xs="8" />
                    <Col className="text-right" xs="4">
                      <Button color="primary" onClick={salvaCliente}>
                        {cliente?.id ? 'Atualizar' : 'Cadastrar'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CadastroCliente;
