import { FaInfo } from 'react-icons/fa';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

export default function Reprocessamento(): JSX.Element {
  async function mostrarModal() {
    Swal.fire({
      icon: 'info',
      html: `Houve um recálculo nos lances. É necessário salvar e calcular novamente essa etapa. O botão <i><u>Concluir Etapa e Gerar lista de Materiais</u></i> ficará indisponível até que todos os cálculos sejam refeitos.`,
    });
  }

  return (
    <>
      <Button
        className="btn-icon ml-3"
        color="warning"
        type="button"
        title="Ação necessária"
        onClick={mostrarModal}
        size="sm"
      >
        <FaInfo />
      </Button>
    </>
  );
}
