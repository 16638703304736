import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaCheckCircle,
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from 'services/api';
import './style.css';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface TermometriaFabricante {
  id: number;
  nome: string;
  descricao?: string;
  ativo?: boolean;
}

interface TermometriaModelo {
  id: number;
  termometriaFabricanteId: number;
  termometriaFabricante: TermometriaFabricante;
  modelo: number;
  raioInstalacaoA?: number;
  raioInstalacaoB?: number;
  raioInstalacaoC?: number;
  raioInstalacaoD?: number;
  quantidadePendulosA?: number;
  quantidadePendulosB?: number;
  quantidadePendulosC?: number;
  quantidadePendulosD?: number;
  alturaChapa: number;
  alturaEspalhador: number;
  acessoPenduloA?: string;
  acessoPenduloB?: string;
  acessoPenduloC?: string;
  acessoPenduloD?: string;
  alturaSuporteA?: number;
  alturaSuporteB?: number;
  alturaSuporteC?: number;
  ativo: boolean;
}

const CadastroTermometriaModelo: React.FC = () => {
  const [mod, setModelo] = useState([] as TermometriaModelo[]);

  const [fabricantes, setFabricantes] = useState<TermometriaFabricante[]>([]);

  const [mostrarDesativados, setMostrarDesativados] = useState(false);

  // eslint-disable-next-line prettier/prettier
  const [modeloEditing, setModeloEditing] = useState({} as TermometriaModelo);

  const [formModalState, setFormModalState] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  async function listaModelo() {
    const response = await api.get('/termometria/modelo');
    setModelo(response.data);
  }

  async function listaFabricantes() {
    const response = await api.get('/termometria/fabricante');
    setFabricantes(response.data);
  }

  useEffect(() => {
    listaModelo();
    listaFabricantes();
  }, []);

  // async function handleDelete(modeloExcluir: TermometriaModelo) {
  //   Swal.fire({
  //     title: `Deseja excluir o Registro?`,
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: `Sim`,
  //     confirmButtonColor: '#d33',
  //     cancelButtonText: `Não`,
  //   }).then(async result => {
  //     if (result.isConfirmed) {
  //       await api.delete(`/termometria/modelo/${modeloExcluir.id}`, {});
  //       Swal.close();
  //       toast.success('Registro excluído');
  //       setModelo(mod.filter(item => item.id !== modeloExcluir.id));
  //     }
  //   });
  // }

  async function ativarDesativar(modeloId: number, ativar: boolean) {
    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      await api.delete(`/termometria/modelo/${modeloId}?ativo=${ativar}`);
      toast.success('Feito!', {});

      listaModelo();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  // async function handleAtivar(modeloId: number, fabricanteID: number) {
  // Aqui preciso validar se pode ser reativado.
  // Se não puder, alerta o usuário

  async function handleAtivar(modeloId: number) {
    // async function checkPodeReativar(): Promise<boolean> {
    //   if (!modeloId) {
    //     return false;
    //   }

    //   const response = await api.get(
    //     `/termometria/modelo/${fabricanteID}/validar-reativacao`,
    //   );

    //   const existeFabricante = response.data.valido ?? false;

    //   return existeFabricante;
    // }

    // const podeReativar = await checkPodeReativar();

    // if (podeReativar) {
    Swal.fire({
      title: `Deseja reativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(modeloId, true);
        Swal.close();
      }
    });
    // } else {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Ops!',
    //     text: `Não é possível reativar o modelo. Ele está relacionado à fabricante inativo. Primeiro reative o fabricante antes de reativar o modelo`,
    //   });
    // }
  }

  async function handleDelete(modeloId: number) {
    Swal.fire({
      title: `Deseja desativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(modeloId, false);
        Swal.close();
      }
    });
  }

  async function handleInsert() {
    setModeloEditing({} as TermometriaModelo);
    setFormModalState(!formModalState);
  }

  function handleUpdate(modeloEditar: TermometriaModelo) {
    setModeloEditing(modeloEditar);
    setFormModalState(!formModalState);
  }

  async function handleSaveModelo() {
    // eslint-disable-next-line prettier/prettier
    const { modelo,termometriaFabricanteId, alturaChapa, alturaEspalhador } = modeloEditing;

    if (
      !modelo ||
      !termometriaFabricanteId ||
      !alturaChapa ||
      !alturaEspalhador
    ) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);

    if (modeloEditing.id) {
      await api.put(`/termometria/modelo/${modeloEditing.id}`, {
        modelo: modeloEditing.modelo,
        termometriaFabricanteId: modeloEditing.termometriaFabricanteId,
        alturaChapa: modeloEditing.alturaChapa,
        alturaEspalhador: modeloEditing.alturaEspalhador,
        raioInstalacaoA: modeloEditing.raioInstalacaoA,
        raioInstalacaoB: modeloEditing.raioInstalacaoB,
        raioInstalacaoC: modeloEditing.raioInstalacaoC,
        raioInstalacaoD: modeloEditing.raioInstalacaoD,
        quantidadePendulosA: modeloEditing.quantidadePendulosA,
        quantidadePendulosB: modeloEditing.quantidadePendulosB,
        quantidadePendulosC: modeloEditing.quantidadePendulosC,
        quantidadePendulosD: modeloEditing.quantidadePendulosD,
        acessoPenduloA: modeloEditing.acessoPenduloA,
        acessoPenduloB: modeloEditing.acessoPenduloB,
        acessoPenduloC: modeloEditing.acessoPenduloC,
        acessoPenduloD: modeloEditing.acessoPenduloD,
        alturaSuporteA: modeloEditing.alturaSuporteA,
        alturaSuporteB: modeloEditing.alturaSuporteB,
        alturaSuporteC: modeloEditing.alturaSuporteC,
      });
    } else {
      await api.post(`/termometria/modelo`, {
        modelo: modeloEditing.modelo,
        termometriaFabricanteId: modeloEditing.termometriaFabricanteId,
        alturaChapa: modeloEditing.alturaChapa,
        alturaEspalhador: modeloEditing.alturaEspalhador,
        raioInstalacaoA: modeloEditing.raioInstalacaoA,
        raioInstalacaoB: modeloEditing.raioInstalacaoB,
        raioInstalacaoC: modeloEditing.raioInstalacaoC,
        raioInstalacaoD: modeloEditing.raioInstalacaoD,
        quantidadePendulosA: modeloEditing.quantidadePendulosA,
        quantidadePendulosB: modeloEditing.quantidadePendulosB,
        quantidadePendulosC: modeloEditing.quantidadePendulosC,
        quantidadePendulosD: modeloEditing.quantidadePendulosD,
        acessoPenduloA: modeloEditing.acessoPenduloA,
        acessoPenduloB: modeloEditing.acessoPenduloB,
        acessoPenduloC: modeloEditing.acessoPenduloC,
        acessoPenduloD: modeloEditing.acessoPenduloD,
        alturaSuporteA: modeloEditing.alturaSuporteA,
        alturaSuporteB: modeloEditing.alturaSuporteB,
        alturaSuporteC: modeloEditing.alturaSuporteC,
      });
    }
    toast.success('Registro concluído');
    setFormModalState(false);
    setIsSaving(false);
    setModeloEditing({} as TermometriaModelo);
    listaModelo();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Modelos</small>
                    </div>
                    <Form role="form">
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Fabricante*</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type="select"
                                value={modeloEditing.termometriaFabricanteId}
                                onChange={e =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    termometriaFabricanteId: Number(
                                      e.target.value,
                                    ),
                                  })
                                }
                              >
                                <option value="">
                                  Selecione um Fabricante
                                </option>
                                {fabricantes
                                  .filter(fabricante => fabricante.ativo)
                                  .map(fabricante => (
                                    <option
                                      key={fabricante.id}
                                      value={fabricante.id}
                                    >
                                      {fabricante.nome}
                                    </option>
                                  ))}
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Modelo* </small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Modelo*"
                                value={modeloEditing.modelo}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    modelo: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Altura da Chapa*</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Altura da Chapa*"
                                value={modeloEditing.alturaChapa}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    alturaChapa: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Altura do Espalhador*</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Altura do Espalhador*"
                                value={modeloEditing.alturaEspalhador}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    alturaEspalhador: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Raio de Instalação A</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Raio de Instalação A"
                                value={modeloEditing.raioInstalacaoA}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    raioInstalacaoA: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Raio de Instalação B</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Raio de Instalação B"
                                value={modeloEditing.raioInstalacaoB}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    raioInstalacaoB: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Raio de Instalação C</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Raio de Instalação C"
                                value={modeloEditing.raioInstalacaoC}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    raioInstalacaoC: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Raio de Instalação D</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Raio de Instalação D"
                                value={modeloEditing.raioInstalacaoD}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    raioInstalacaoD: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Quantidade de Pêndulos A</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Quantidade de Pêndulos A"
                                value={modeloEditing.quantidadePendulosA}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    quantidadePendulosA: Number(
                                      text.target.value,
                                    ),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Quantidade de Pêndulos B</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Quantidade de Pêndulos B"
                                value={modeloEditing.quantidadePendulosB}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    quantidadePendulosB: Number(
                                      text.target.value,
                                    ),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Quantidade de Pêndulos C</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Quantidade de Pêndulos C"
                                value={modeloEditing.quantidadePendulosC}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    quantidadePendulosC: Number(
                                      text.target.value,
                                    ),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Quantidade de Pêndulos D</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Quantidade de Pêndulos D"
                                value={modeloEditing.quantidadePendulosD}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    quantidadePendulosD: Number(
                                      text.target.value,
                                    ),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          {' '}
                          <FormGroup className="mb-3">
                            <small>Acesso do Pêndulo A</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Acesso do Pêndulo A"
                                value={modeloEditing.acessoPenduloA}
                                type="text"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    acessoPenduloA: text.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Acesso do Pêndulo B</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Acesso do Pêndulo B"
                                value={modeloEditing.acessoPenduloB}
                                type="text"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    acessoPenduloB: text.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Acesso do Pêndulo C</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Acesso do Pêndulo C"
                                value={modeloEditing.acessoPenduloC}
                                type="text"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    acessoPenduloC: text.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <small>Acesso do Pêndulo D</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Acesso do Pêndulo D"
                                value={modeloEditing.acessoPenduloD}
                                type="text"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    acessoPenduloD: text.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          {' '}
                          <FormGroup className="mb-3">
                            <small>Altura do Suporte A</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Altura do Suporte A"
                                value={modeloEditing.alturaSuporteA}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    alturaSuporteA: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          {' '}
                          <FormGroup className="mb-3">
                            <small>Altura do Suporte B</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Altura do Suporte B"
                                value={modeloEditing.alturaSuporteB}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    alturaSuporteB: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup className="mb-3">
                            <small>Altura do Suporte C</small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Altura do Suporte C"
                                value={modeloEditing.alturaSuporteC}
                                type="number"
                                onChange={text =>
                                  setModeloEditing({
                                    ...modeloEditing,
                                    alturaSuporteC: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSaveModelo}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">Lista de Modelos</h3>
                  </Col>
                  <Col sm="3">
                    <FormControlLabel
                      className="float-right"
                      control={
                        <Checkbox
                          checked={mostrarDesativados}
                          onChange={event =>
                            setMostrarDesativados(event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Mostrar desativados"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th rowSpan={2}>Fabricante</th>
                    <th rowSpan={2}>Modelo</th>
                    <th colSpan={4}>Raio Instalação</th>
                    <th colSpan={4}>Qtde Pendulos</th>
                    <th rowSpan={2}>Altura Chapa</th>
                    <th rowSpan={2}>Altura Espalhador</th>
                    <th colSpan={4}>Acesso Pendulos</th>
                    <th colSpan={3}>Alt. Suporte</th>
                    <th rowSpan={2} hidden={!mostrarDesativados}>
                      Ativo
                    </th>
                    <th rowSpan={2}>&nbsp;</th>
                  </tr>
                  <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                  </tr>
                </thead>
                <tbody>
                  {mod
                    .filter(item =>
                      !mostrarDesativados ? item.ativo === true : true,
                    )
                    .map(item => (
                      <tr key={item.id}>
                        <td className="py-1">
                          {item.termometriaFabricante.nome}
                        </td>
                        <td className="py-1">{item.modelo}</td>
                        <td className="py-1">{item.raioInstalacaoA}</td>
                        <td className="py-1">{item.raioInstalacaoB}</td>
                        <td className="py-1">{item.raioInstalacaoC}</td>
                        <td className="py-1">{item.raioInstalacaoD}</td>
                        <td className="py-1">{item.quantidadePendulosA}</td>
                        <td className="py-1">{item.quantidadePendulosB}</td>
                        <td className="py-1">{item.quantidadePendulosC}</td>
                        <td className="py-1">{item.quantidadePendulosD}</td>
                        <td className="py-1">{item.alturaChapa}</td>
                        <td className="py-1">{item.alturaEspalhador}</td>
                        <td className="py-1">{item.acessoPenduloA}</td>
                        <td className="py-1">{item.acessoPenduloB}</td>
                        <td className="py-1">{item.acessoPenduloC}</td>
                        <td className="py-1">{item.acessoPenduloD}</td>
                        <td className="py-1">{item.alturaSuporteA}</td>
                        <td className="py-1">{item.alturaSuporteB}</td>
                        <td className="py-1">{item.alturaSuporteC}</td>
                        <td className="py-1" hidden={!mostrarDesativados}>
                          <div
                            className={`badge badge-${
                              item.ativo ? 'success' : 'danger'
                            }`}
                          >
                            {item.ativo ? 'Sim' : 'Não'}
                          </div>
                        </td>
                        <td>
                          <div className="float-right">
                            {item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="warning"
                                  type="button"
                                  disabled={isSaving}
                                  onClick={() => {
                                    handleUpdate(item);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="danger"
                                  type="button"
                                  disabled={isSaving}
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </>
                            )}
                            {!item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="info"
                                  type="button"
                                  disabled={isSaving}
                                  onClick={() => {
                                    handleAtivar(
                                      item.id,
                                      // item.termometriaFabricanteId,
                                    );
                                  }}
                                >
                                  <FaCheckCircle />
                                </Button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroTermometriaModelo;
