import FichaTecnica from 'models/FichaTecnica';

import { Card, CardBody, Col, Row } from 'reactstrap';

import Orcamento from 'models/Orcamento';
import { CircularProgress } from '@material-ui/core';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { ColQuestaoTitulo, LabelQuestaoTitulo } from '../../../styles';

import Campo from '../../atoms/Campo';
import AlturasManometricas from './components/AlturasManometricas';
import AspersorFinal from './components/AspersorFinal';
import AspersorDoPivo from './components/AspersorDoPivo';

import TabelasDetalhes from './components/TabelasDetalhes';
import CaracteristicasTecnicas from './components/CaracteristicasTecnicas';
import LancesBalanco from './components/LancesBalanco';

import { ColLabel, Separador, SinalizacaoContainer } from './styles';
import SinalizaEficienciaCusto from '../../molecules/SinalizaEficienciaCusto';
import { Label, Valor } from '../../atoms/Campo/styles';
import DadosAdutora from './components/DadosAdutora';
import DadosBombeamento from './components/DadosBombeamento';
import DadosMotor from './components/DadosMotor';

interface Props {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
  carregandoFichaTecnica: boolean;
  acessoLimitadoPerdaCarga: ValidarAcessoPrograma;
}

function DadosCalculados(props: Props): JSX.Element {
  const {
    fichaTecnica,
    orcamento,
    carregandoFichaTecnica,
    acessoLimitadoPerdaCarga,
  } = props;

  const {
    pivoCentral,
    areaTotalIrrigada,
    raioEfetivoAreaIrrigada,
    raioEfetivoIrrigadoSAF,
    sinalizaCustos,
    sinalizaEficiencia,
    sistemaIrrigacao,
    areaTotalIrrigadaCAF,
    vazaoSAF,
    vazaoCAF,
    vazaoTotalSAF,
    areaEfetivaIrrigadaSAF,
    valor,
  } = fichaTecnica;

  const maisEconomica = sinalizaCustos === 'S';
  const maisEficiente = sinalizaEficiencia === 'S';

  const campos = [
    {
      label: 'Raio efetivo irrigado (SAF)',
      valor: raioEfetivoIrrigadoSAF,
      unidade: 'm',
    },
    {
      label: 'Área efetiva irrigada (SAF)',
      valor: areaEfetivaIrrigadaSAF,
      unidade: 'ha',
    },
    { label: 'Valor', valor: null },
    { label: 'Vazão (SAF)', valor: vazaoTotalSAF, unidade: 'm³/h' },
    { label: 'Vazão (CAF)', valor: vazaoCAF, unidade: 'm³/h' },
    {
      label: 'Área total irrigada (CAF)',
      valor: areaTotalIrrigadaCAF,
      unidade: 'ha',
    },
  ];

  return (
    <Card className="shadow mt-2">
      <CardBody className="mt--4">
        <Card className="shadow mt-4">
          <Row className="mt--3">
            <ColQuestaoTitulo>
              <LabelQuestaoTitulo>Dados calculados</LabelQuestaoTitulo>
            </ColQuestaoTitulo>
          </Row>

          <SinalizacaoContainer className="mr-3 mb--2">
            <SinalizaEficienciaCusto
              maisEconomica={maisEconomica}
              maisEficiente={maisEficiente}
            />
          </SinalizacaoContainer>

          <CardBody className="p-3">
            <Row>
              <Col sm="12">
                <Row>
                  <ColLabel>
                    <Label>Pivô central: </Label>
                  </ColLabel>
                  <Col sm="10" style={{ maxWidth: '80.5%' }}>
                    <Valor readOnly value={pivoCentral} />
                  </Col>
                </Row>
              </Col>

              <Col sm="12">
                <Row>
                  <ColLabel>
                    <Label>Sistema de irrigação: </Label>
                  </ColLabel>
                  <Col sm="10" style={{ maxWidth: '80.5%' }}>
                    <Valor readOnly value={sistemaIrrigacao} />
                  </Col>
                </Row>
              </Col>

              {campos.map(campo => (
                <Campo
                  key={campo.label}
                  label={campo.label}
                  valor={campo.valor}
                  unidade={campo?.unidade}
                />
              ))}

              <Col sm="5">
                <LancesBalanco fichaTecnica={fichaTecnica} />
                <Separador />
                <AlturasManometricas fichaTecnica={fichaTecnica} />
              </Col>

              <Col sm="7">
                <CaracteristicasTecnicas fichaTecnica={fichaTecnica} />
                <AspersorDoPivo fichaTecnica={fichaTecnica} />
                <AspersorFinal fichaTecnica={fichaTecnica} />
              </Col>

              <Col sm="12">
                {carregandoFichaTecnica ? (
                  <Row>
                    <Col className="text-center">
                      <CircularProgress />
                    </Col>
                  </Row>
                ) : (
                  <TabelasDetalhes
                    fichaTecnica={fichaTecnica}
                    orcamento={orcamento}
                    acessoLimitadoPerdaCarga={acessoLimitadoPerdaCarga}
                  />
                )}
              </Col>

              <Col className="mt-4">
                <DadosAdutora fichaTecnica={fichaTecnica} />
              </Col>

              <Col className="mt-4">
                <DadosBombeamento fichaTecnica={fichaTecnica} />
                <DadosMotor fichaTecnica={fichaTecnica} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
}

export default DadosCalculados;
