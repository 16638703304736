import { useState, useEffect, useContext } from 'react';

import Produto from 'models/Produto';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Modal,
  Row,
} from 'reactstrap';

import {
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import api from 'services/api';

import ProdutoCategoria from 'models/ProdutoCategoria';
import { useQuery } from 'react-query';
import { GridDiv } from '../styles';

import DefaultImage from '../../../../assets/img/default.jpg';

import { OrcamentoContext } from '..';

function ProdutoListaImagem(): JSX.Element {
  const { handleSelecionarProduto } = useContext(OrcamentoContext);
  const [isLoading, setIsLoading] = useState(false);
  const [produtos, setProdutos] = useState<Produto[]>();
  const [produtoCategorias, setProdutoCategorias] = useState<
    ProdutoCategoria[]
  >([]);
  const [produtoCategoriaIdSelected, setProdutoCategoriaIdSelected] =
    useState<number>();
  const [modalVisivel, setModalVisivel] = useState(true);
  const isMobile = window.innerWidth <= 800;

  const { data: queryProdutoCategoria } = useQuery(
    `produto-categoria`,
    async () => {
      async function getData(): Promise<ProdutoCategoria[]> {
        const response = await api.get('/produto-categoria');
        return response.data;
      }
      const dataProdutoCategoria = await getData();
      return dataProdutoCategoria;
    },
  );

  useEffect(() => {
    if (queryProdutoCategoria) {
      setProdutoCategorias(queryProdutoCategoria);
    }
  }, [queryProdutoCategoria]);

  useEffect(() => {
    if (modalVisivel) {
      setProdutoCategoriaIdSelected(undefined);
      setProdutos(undefined);
    }
  }, [modalVisivel]);

  function imagensPorLinha() {
    if (isMobile || produtos?.length === 1) {
      return 1;
    }
    return 2;
  }

  async function carregaProdutos() {
    setIsLoading(true);
    try {
      const response = await api.get(`/produto`, {
        params: {
          getImagem: true,
          produtoCategoriaId: produtoCategoriaIdSelected,
        },
      });
      setProdutos(response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSelectCategoria() {
    if (!produtoCategoriaIdSelected) {
      return;
    }
    await carregaProdutos();
    setModalVisivel(false);
  }

  function Produtos(): JSX.Element {
    const descricaoCategoria = produtoCategorias.find(
      categoria => categoria.id === produtoCategoriaIdSelected,
    )?.descricao;

    if (!produtos) {
      return <></>;
    }
    if (!produtos.length && produtoCategoriaIdSelected) {
      return (
        <div className="text-center my-4">
          <strong>
            Nenhum produto da categoria {descricaoCategoria} encontrado.
          </strong>
        </div>
      );
    }

    return (
      <>
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h6 className="heading-small text-muted">
                Selecione um produtos de {descricaoCategoria}
              </h6>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <GridDiv>
            <ImageList
              rowHeight={isMobile ? 200 : 500}
              cols={imagensPorLinha()}
            >
              {produtos.map(produto => (
                <ImageListItem key={produto.id}>
                  <img
                    src={
                      produto.imagem
                        ? `data:image/jpeg;base64,${produto.imagem}`
                        : DefaultImage
                    }
                    alt={produto.nome}
                  />
                  <ImageListItemBar
                    title={produto.nome}
                    subtitle={<span>Empresa {produto.codigoEmpresa}</span>}
                    actionIcon={
                      <IconButton
                        aria-label={`Informação sobre produto ${produto.nome}`}
                        style={{
                          color: '#fff',
                        }}
                        onClick={() => handleSelecionarProduto(produto)}
                      >
                        <PlaylistAddCheck />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </GridDiv>
        </CardBody>
      </>
    );
  }

  return (
    <>
      <Modal className="modal-dialog-centered" size="sm" isOpen={modalVisivel}>
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5">
              <div className="text-center text-muted mb-4">
                <strong>Categoria do produto</strong>
              </div>
              <Form role="form">
                <Input
                  placeholder="Categoria"
                  type="select"
                  value={produtoCategoriaIdSelected}
                  onChange={text =>
                    setProdutoCategoriaIdSelected(Number(text.target.value))
                  }
                >
                  <option value="">Selecione...</option>
                  {produtoCategorias.map(produtoCategoria => (
                    <option value={produtoCategoria.id}>
                      {produtoCategoria.descricao}
                    </option>
                  ))}
                </Input>

                <div className="text-center">
                  <Button
                    className="mt-4 w-100"
                    disabled={!produtoCategoriaIdSelected || isLoading}
                    color="primary"
                    type="button"
                    onClick={handleSelectCategoria}
                  >
                    Continuar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {produtoCategoriaIdSelected && !modalVisivel && (
        <Row>
          <Col>
            <Button
              className="float-right"
              color="primary"
              type="button"
              onClick={() => setModalVisivel(true)}
            >
              Alterar categoria
            </Button>
          </Col>
        </Row>
      )}
      <Card className="bg-secondary shadow mt-3">
        {isLoading ? (
          <Row>
            <Col className="text-center">
              <CircularProgress />
            </Col>
          </Row>
        ) : (
          <Produtos />
        )}
      </Card>
    </>
  );
}

export default ProdutoListaImagem;
