export default function formatCurrency(
  valor: number | null | undefined,
  maximumFractionDigits = 10,
  isDolar = false,
): string {
  if (valor === null || valor === undefined) {
    return '';
  }
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: isDolar ? 'USD' : 'BRL',
    maximumFractionDigits,
  }).format(valor);
}
