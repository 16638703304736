import FichaTecnica from 'models/FichaTecnica';

import { Card, CardBody, Col, Row } from 'reactstrap';

import {
  ColQuestao,
  LabelQuestao,
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from '../../../styles';

import Campo from '../../atoms/Campo';

interface Props {
  fichaTecnica: FichaTecnica;
}

function DadosInformados(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const {
    areaCircularIrrigada,
    tempoOperacaoDiaria,
    anguloRotacaoIrrigado,
    laminaBruta,
    modeloPivo,
    alturaLivreEntreTorres,
    declividadeTerrenoIrrigar,
    pressaoTrabalho,
    tipoPivo,
    fabricanteAspersor,
    alturaBaseAspersorAteSolo,
    aspersorModelo,
    aspersorFinalModelo,
    configuracaoPistaSeca,
    alimentadorEletricoInjecao,
    desnivelTorrePontoMaisAlto,
    desnivelTorrePontoMaisBaixo,
    fonteEnergiaPivo,
    distanciaFonteEnergia,
    tensaoTrifasica,
    incluirSistemaPistaSeca,
    incluirAspersorFinal,
    areaCircularIrrigada360,
  } = fichaTecnica;

  const campos = [
    {
      label: 'Área total disponível',
      valor: areaCircularIrrigada,
      unidade: 'ha',
    },
    {
      label: 'Ângulo de rotação a ser irrigado',
      valor: anguloRotacaoIrrigado,
      unidade: 'º',
    },
    { label: `Lâmina bruta d'água`, valor: laminaBruta, unidade: 'mm/dia' },
    {
      label: `Área Circular Irrigada (360º)`,
      valor: areaCircularIrrigada360,
      unidade: 'ha',
    },
    {
      label: `Tempo de operação diário`,
      valor: tempoOperacaoDiaria,
      unidade: 'hr',
    },
    { label: `Modelo do Pivô`, valor: modeloPivo },
    {
      label: `Altura de vão livre`,
      valor: alturaLivreEntreTorres,
      unidade: 'm',
    },
    {
      label: `Declividade terreno a irrigar`,
      valor: declividadeTerrenoIrrigar,
      unidade: '%',
    },
    {
      label: `Pressão de trabalho`,
      valor: pressaoTrabalho,
      unidade: 'PSI',
    },
    { label: `Tipo do pivô`, valor: tipoPivo },
    { label: `Fabricante aspersor`, valor: fabricanteAspersor },
    {
      label: `Altura da base do Aspersor ao Solo`,
      valor: alturaBaseAspersorAteSolo,
      unidade: 'm',
    },
    { label: `Aspersor pivô`, valor: aspersorModelo },
    { label: `Aspersor final`, valor: incluirAspersorFinal },
    { label: `Modelo do aspersor final`, valor: aspersorFinalModelo },
    { label: `Sistema pista seca`, valor: incluirSistemaPistaSeca },
  ];

  return (
    <Card className="shadow mt-2">
      <CardBody className="mt--4">
        <Card className="shadow mt-4">
          <Row className="mt--3">
            <ColQuestaoTitulo>
              <LabelQuestaoTitulo>Dados informados</LabelQuestaoTitulo>
            </ColQuestaoTitulo>
          </Row>

          <CardBody className="p-3">
            <Row>
              {campos.map(campo => (
                <Campo
                  key={campo.label}
                  label={campo.label}
                  valor={campo.valor ?? ''}
                  unidade={campo?.unidade}
                />
              ))}

              <Campo
                label="Configuração da pista seca"
                valor={configuracaoPistaSeca}
              />

              <Campo
                sm="8"
                label="Alimentador elétrico para sistema de injeção de fertilizantes"
                valor={alimentadorEletricoInjecao}
              />

              <Col sm="4">
                <Row className="p-2 mt-1 ml-1">
                  <ColQuestao>
                    <LabelQuestao>
                      Desnível da base da Torre central até o ponto:
                    </LabelQuestao>
                  </ColQuestao>
                </Row>
              </Col>

              <Campo
                sm="4"
                label="MAIS ALTO da Área do Pivô"
                valor={desnivelTorrePontoMaisAlto}
                unidade="m"
              />

              <Campo
                sm="4"
                label="MAIS BAIXO da Área do Pivô"
                valor={desnivelTorrePontoMaisBaixo}
                unidade="m"
              />

              <Campo
                sm="4"
                label="Fonte energia pivô"
                valor={fonteEnergiaPivo}
              />

              <Col sm="8" />
              <Campo
                sm="4"
                label="Tensão trifásica"
                valor={tensaoTrifasica}
                unidade="V"
              />

              <Campo
                sm="8"
                label="Distância da Fonte de Energia até o Centro do Pivô"
                valor={distanciaFonteEnergia}
                unidade="m"
              />
            </Row>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
}

export default DadosInformados;
