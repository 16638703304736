import FichaTecnica from 'models/FichaTecnica';
import Orcamento from 'models/Orcamento';
import { Table } from 'reactstrap';

import { Td } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
}

function Cabecalho(props: Props): JSX.Element {
  const { fichaTecnica, orcamento } = props;
  const {
    pivoCentral,
    modeloPivo,
    alturaLivreEntreTorres,
    desnivelTorrePontoMaisBaixo,
    FichaTecnicaCambagem,
  } = fichaTecnica;
  const modeloPneu = FichaTecnicaCambagem?.[0]?.nomeRodado;
  const { pessoa } = orcamento;
  const nomeCliente = pessoa.razaoSocial;
  const localCliente = pessoa.municipioDescricao
    ? `${pessoa.municipioDescricao} - ${pessoa?.ufSigla || ''}`
    : '';

  const dadosBasicos = [
    { nome: 'Cliente', valor: nomeCliente },
    { nome: 'Local', valor: localCliente },
    { nome: 'Número pivô', valor: pivoCentral },
    { nome: 'Pivô central', valor: modeloPivo },
    {
      nome: 'Altura livre entre as torres',
      valor: alturaLivreEntreTorres,
      unidade: 'm',
    },
    { nome: 'Modelo do pneu', valor: modeloPneu },
    {
      nome: 'Desnível do centro do pivô ao ponto mais baixo',
      valor: desnivelTorrePontoMaisBaixo,
      unidade: 'm',
    },
  ];

  return (
    <Table bordered responsive>
      {dadosBasicos.map(dado => (
        <tr key={dado.nome}>
          <Td>
            <strong>{dado.nome}</strong>
          </Td>
          <Td>
            {dado.valor} {dado?.unidade || ''}
          </Td>
        </tr>
      ))}
    </Table>
  );
}

export default Cabecalho;
