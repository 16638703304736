import styled from 'styled-components';
import List from '@material-ui/core/List';
import { Row } from 'reactstrap';

export const PerfilListContainer = styled.div`
  padding: 0.6rem;
`;

export const PerfilRow = styled(List as any)`
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin: 0.5rem;
  background-color: ${props => (props.itemPai ? `#f9f9f9` : `#fff`)};
`;

export const ProgramaListContainer = styled.div`
  padding: 1rem;
  background-color: #fff;
`;

export const NewProgramaRow = styled(Row)`
  padding: 0 0 1rem 5px;
  background-color: #fff;
`;

export const ProgramaRow = styled(List as any)``;
