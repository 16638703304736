import styled from 'styled-components';

export const Th = styled.th`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e9ecef;
`;

export const Td = styled.td`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;

interface TrProps {
  semPreco?: boolean;
}

export const Tr = styled.tr<TrProps>`
  background-color: ${props => (props.semPreco ? '#ffd5de' : 'unset')};
`;

export const Descricao = styled.label`
  font-size: 0.75rem;
`;
