import React, { useState, useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import CustomTable from 'components/CustomTable';
import { Toast, ToastAsync } from 'components/Toast';
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  FormGroup,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaArrowLeft, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import api from 'services/api';
import AspersorModel from '../../../models/ConfiguradorAspersor';

import TuboModel from '../../../models/ConfiguradorTubo';

type LocationState = {
  stateTubo?: TuboModel;
};

interface ListaAssociados {
  id: number;
  nome: string;
}

interface CustomTableList {
  id: string;
  texto: string;
}

const ConfiguradorTubosVelocidade: React.FC = () => {
  const location = useLocation<LocationState>();
  const { stateTubo } = location.state;

  const history = useHistory();
  const codOrigem = stateTubo?.id;

  const [velocidades, setVelocidades] = useState([] as any[]);
  const [counter, setCounter] = useState(0);

  const tipo: CustomTableList[] = [
    {
      id: 'C',
      texto: 'Mais Econômico',
    },
    {
      id: 'E',
      texto: 'Mais Eficiente',
    },
  ];

  const header: any[] = [
    {
      name: 'Vazão Máxima',
      prop: 'vazaoMaxima',
      type: 'NUMBER',
      sortable: true,
      editable: true,
      required: true,
      searchable: false,
    },
    {
      name: 'Velocidade Máxima Água',
      prop: 'velocidadeMaxAgua',
      type: 'NUMBER',
      sortable: false,
      editable: true,
      required: true,
      searchable: false,
    },
    {
      name: 'Tipo',
      prop: 'tipo',
      type: 'SELECT',
      searchable: false,
      sortable: false,
      editable: true,
      required: true,
      values: tipo,
    },
  ];

  async function handleDelete(row: any) {
    if (!row.isNew) {
      const codigoExcluir = row.columns.filter(
        (col: any) => col.prop === 'tipo',
      )[0].value.id;

      await api.delete(
        `/integracao/tubos/${codOrigem}/veloc/${codigoExcluir}`,
        {},
      );
    }

    setVelocidades(velocidades.filter(item => item.id !== row.id));

    toast.success('Registro excluído');
  }

  async function handleSave(row: any) {
    if (row.isNew) {
      const objCriado: any = await api.post(
        `/integracao/tubos/${codOrigem}/veloc`,
        {
          vazaoMaxima: row.columns.filter(
            (col: any) => col.prop === 'vazaoMaxima',
          )[0].newValue,
          velocidadeMaxAgua: row.columns.filter(
            (col: any) => col.prop === 'velocidadeMaxAgua',
          )[0].newValue,
          tipo: row.columns.filter((col: any) => col.prop === 'tipo')[0]
            .newValue.id,
        },
      );

      setVelocidades(
        velocidades.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            // eslint-disable-next-line no-param-reassign
            item.columns = [
              {
                prop: 'vazaoMaxima',
                value: objCriado.data.vazaoMaxima,
                newValue: objCriado.data.vazaoMaxima,
              },
              {
                prop: 'velocidadeMaxAgua',
                value: objCriado.data.velocidadeMaxAgua,
                newValue: objCriado.data.velocidadeMaxAgua,
              },
              {
                prop: 'tipo',
                value: {
                  id: objCriado.data.tipo,
                  texto: tipo.filter(
                    (it: any) => it.id === objCriado.data.tipo,
                  )[0].texto,
                },
                newValue: {
                  id: objCriado.data.tipo,
                  texto: tipo.filter(
                    (it: any) => it.id === objCriado.data.tipo,
                  )[0].texto,
                },
              },
            ];
          }

          return item;
        }),
      );
    } else {
      await api.put(`/integracao/tubos/${codOrigem}/veloc`, {
        vazaoMaxima: row.columns.filter(
          (col: any) => col.prop === 'vazaoMaxima',
        )[0].newValue,
        velocidadeMaxAgua: row.columns.filter(
          (col: any) => col.prop === 'velocidadeMaxAgua',
        )[0].newValue,
        tipo: row.columns.filter((col: any) => col.prop === 'tipo')[0].newValue
          .id,
      });

      setVelocidades(
        velocidades.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );
    }

    toast.success('Registro salvo');
  }

  function handleStartEditing(row: any) {
    setVelocidades(
      velocidades.map(item => {
        if (item.id === row.id) {
          // eslint-disable-next-line no-param-reassign
          item.isEditing = true;
        }
        return item;
      }),
    );
  }

  function handleStopEditing(row: any) {
    if (row.isNew) {
      setVelocidades(velocidades.filter(item => item.id !== row.id));
    } else {
      setVelocidades(
        velocidades.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
          }
          return item;
        }),
      );
    }
  }

  function handleAddRow() {
    const count = counter + 1;

    setVelocidades([
      {
        id: count,
        columns: [
          { prop: 'vazaoMaxima', value: '', newValue: '' },
          { prop: 'velocidadeMaxAgua', value: '', newValue: '' },
          {
            prop: 'tipo',
            value: { id: '', texto: null },
            newValue: { id: '', texto: null },
          },
        ],
        isNew: true,
        isEditing: true,
      },
      ...velocidades,
    ]);

    setCounter(count);
  }

  async function listaVelocidades() {
    const response = await api.get(`/integracao/tubos/${codOrigem}/veloc`);

    // eslint-disable-next-line no-shadow
    const lista: any[] = response.data;

    const rows: any[] = [];

    let count = counter;
    // eslint-disable-next-line array-callback-return
    lista.map(item => {
      rows.push({
        id: count,
        columns: [
          {
            prop: 'vazaoMaxima',
            value: item.vazaoMaxima,
            newValue: item.vazaoMaxima,
          },
          {
            prop: 'velocidadeMaxAgua',
            value: item.velocidadeMaxAgua,
            newValue: item.velocidadeMaxAgua,
          },
          {
            prop: 'tipo',
            value: {
              id: item.tipo,
              texto: tipo.filter(it => it.id === item.tipo)[0].texto,
            },
            newValue: {
              id: item.tipo,
              texto: tipo.filter(it => it.id === item.tipo)[0].texto,
            },
          },
        ],
        isNew: false,
        isEditing: false,
      });
      // eslint-disable-next-line no-plusplus
      count++;
    });

    setVelocidades(rows);

    setCounter(count);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaVelocidades();
  }, []);

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: `/admin/tubos`,
                    });
                  }}
                >
                  <FaArrowLeft />
                  <span className="btn-inner--text">Voltar</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Velocidades do tubo {stateTubo?.nome}</h3>
              </CardHeader>
              <CustomTable
                enableSearchField={false}
                rows={velocidades}
                header={header}
                onDelete={handleDelete}
                onSave={handleSave}
                onAddRow={handleAddRow}
                onStartEditing={handleStartEditing}
                onStopEditing={handleStopEditing}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorTubosVelocidade;
