import XLSX from 'xlsx';

export default function exportToSheet(
  dataJSON: any[],
  nomeArquivo: string,
): void {
  const fileName = `${nomeArquivo}_${new Intl.DateTimeFormat('pt-BR').format(
    new Date(),
  )}.xlsx`;

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataJSON);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, nomeArquivo);

  XLSX.writeFile(wb, fileName);
}
