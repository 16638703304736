import UserSmallHeader from 'components/Headers/UserSmallHeader';
import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import api from 'services/api';
import { Col, Row } from 'reactstrap';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import config from 'config';
import { ProdutoProposta } from 'models/ProdutoProposta';
import { CircularProgress } from '@material-ui/core';
import FVCustoTotal_AtualFuturo from 'views/charts/FVCustoTotal_AtualFuturo';
import FVFluxoCaixa from 'views/charts/FVFluxoCaixa';
import LogoImg from './components/assets/img/logo_fck.png';
import FotovoltaicaParcelas from './components/FotovoltaicaParcelas';
import './style.css';
import FotovoltaicaGraficoCustoTotal from './components/FotovoltaicaGraficoCustoTotal';
import FotovoltaicaGraficoFluxoCaixa from './components/FotovoltaicaGraficoFluxoCaixa';
import { Container } from './styles';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  produtoProposta: ProdutoProposta;
}

interface PropostaImpressaoContext {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  isPropostaExterior: boolean;
  setIsPropostaExterior: (valor: boolean) => void;
  valorDolar: number;
  setValorDolar: (valor: number) => void;
  parcelas: PropostaVersaoParcelas[];
}

export const PropostaImpressaoContext = createContext(
  {} as PropostaImpressaoContext,
);

interface TemplateReportTableProperties {
  columnName: string;
  columnProperty: string;
}

interface TemplateReportRequisites {
  propertyName: string;
  value: string;
}

interface TemplateReport {
  content: string;
  type: string;
  align: string | 'L';
  isBold: boolean | false;
  sharedLine: boolean | false;
  breakLineBefore: boolean | false;
  imageWidth?: number;
  imageLenght?: number;
  component?: string;
  objectToLoop?: string;
  tableProperties?: TemplateReportTableProperties[];
  templatePreRequisite?: TemplateReportRequisites[];
}

export default function PropostaImpressaoGeral(): JSX.Element {
  const location = useLocation<LocationState>();
  const { propostaVersao: pVersao } = location.state;

  const [reportContent, setReportContent] = useState<TemplateReport[]>([]);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [atualizarGraficos, setAtualizarGraficos] = useState(false);

  async function buscarDadosRelatorio() {
    setIsLoadingReport(true);
    const response = await api.get(`/proposta-impressao/versao/${pVersao.id}`);

    setReportContent(response.data as TemplateReport[]);
    setIsLoadingReport(false);
  }

  const components = [
    {
      id: 'FotovoltaicaParcelas',
      componente: FotovoltaicaParcelas,
      isFotovoltaica: true,
      isIrrigacao: false,
      isChart: false,
    },
    {
      id: 'FotovoltaicaGraficoCustoTotal',
      componente: FotovoltaicaGraficoCustoTotal,
      isFotovoltaica: true,
      isIrrigacao: false,
      isChart: true,
    },
    {
      id: 'FotovoltaicaGraficoFluxoCaixa',
      componente: FotovoltaicaGraficoFluxoCaixa,
      isFotovoltaica: true,
      isIrrigacao: false,
      isChart: true,
    },
  ];

  useEffect(() => {
    buscarDadosRelatorio();
  }, []);

  function AvisoPropostaOrientativa({
    position,
  }: {
    position: string;
  }): JSX.Element {
    return (
      <div className={`marca-dagua ${position} text-center`}>
        <h2>
          {config.type === 'develop'
            ? 'AMBIENTE DE DESENVOLVIMENTO'
            : 'AMBIENTE DE HOMOLOGAÇÃO'}{' '}
          <br />
          Informações não oficiais.
        </h2>
      </div>
    );
  }

  async function processamentoPrint() {
    window.print();
  }

  function printFunction() {
    processamentoPrint();
  }

  return (
    <>
      <UserSmallHeader />
      <Container className="container-print">
        {config.type !== 'production' ? (
          <>
            <AvisoPropostaOrientativa position="top" />
          </>
        ) : (
          <></>
        )}

        <div style={{ pageBreakInside: 'auto' }}>
          {isLoadingReport ? (
            <Row>
              <Col sm="12" className="text-center mt-5">
                <CircularProgress />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm="12" className="mt-1">
                <div
                  style={{
                    float: 'left',
                    padding: '5px',
                  }}
                >
                  <img src={LogoImg} alt="Logo" width="65%" height="65%" />
                </div>

                <div style={{ width: '100%', float: 'left' }}>&nbsp;</div>

                {reportContent.map(item => {
                  const isTexto = item.type === 'T';
                  const isTitulo = item.type === 'TI';
                  const isImage = item.type === 'IMG';
                  const isTable = item.type === 'TABLE';
                  const isComponent = item.type === 'COMPONENT';

                  const isBold = isTexto && item.isBold;

                  const alignCenter = item.align === 'C';
                  const alignRight = item.align === 'R';

                  // eslint-disable-next-line no-nested-ternary
                  const alignContent = alignCenter
                    ? 'center'
                    : alignRight
                    ? 'right'
                    : 'justify';

                  if (isComponent) {
                    const componenteFiltrado = components.filter(
                      componente => componente.id === item.content,
                    );

                    const DynamicComponent =
                      componenteFiltrado && componenteFiltrado.length
                        ? componenteFiltrado[0].componente
                        : null;

                    const breakPage = item.breakLineBefore;

                    if (DynamicComponent) {
                      return (
                        <>
                          {breakPage && <div className="break-page" />}
                          <div
                            className="mt-2 component-print"
                            style={{
                              float: 'left',
                              width: item.sharedLine === true ? '50%' : '100%',
                              textAlign: alignContent,
                              padding: '5px',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {atualizarGraficos ? (
                              <>Atualizando componente</>
                            ) : (
                              <DynamicComponent
                                propostaVersaoId={pVersao.id}
                                propostaVersao={pVersao}
                              />
                            )}
                          </div>
                        </>
                      );
                    }
                  }

                  if (isTable) return <></>;

                  const fontSize = isTitulo ? '30px' : undefined;
                  const breakPage = item.breakLineBefore;

                  return (
                    <>
                      {breakPage && <div className="break-page" />}
                      <div
                        className="mt-3"
                        style={{
                          width: item.sharedLine === true ? '50%' : '100%',
                          fontWeight: isBold ? 'bold' : 'normal',
                          textAlign: alignContent,
                          // border: '1px solid red',
                          fontSize,
                          float: 'left',
                          padding: '5px',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {isImage ? (
                          <img src={item.content} alt="Imagem" width="80%" />
                        ) : (
                          item.content
                        )}
                      </div>
                      {item.sharedLine === false && <br />}
                    </>
                  );
                })}
              </Col>
            </Row>
          )}
        </div>

        <div className="mt-5">
          {config.type !== 'production' && (
            <>
              <AvisoPropostaOrientativa position="middle" />
            </>
          )}
        </div>

        <FloatingButton
          disabled={isLoadingReport}
          className="hide-on-print"
          onClick={printFunction}
        >
          <FaPrint />
        </FloatingButton>
      </Container>
    </>
  );
}
