import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Wrapper } from '@googlemaps/react-wrapper';
import Maintenance from 'views/pages/Maintenance';
import Routes from './routes/index';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import GlobalStyle from './global/styles';
import AppProvider from './hooks/index';

const queryClient = new QueryClient();

const App: React.FC = () => {
  if (process.env.REACT_APP_MAINTENANCE === 'S') {
    return <Maintenance />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppProvider>
          <Wrapper apiKey={process.env.REACT_APP_MAPS_API_KEY || ''} />
          <Routes />
          <GlobalStyle />
        </AppProvider>
      </BrowserRouter>
      <div className="d-print-none ">
        <ReactQueryDevtools initialIsOpen={false} />
      </div>
    </QueryClientProvider>
  );
};

export default App;
