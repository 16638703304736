import { useRef, useEffect, useState } from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';

import { Container } from 'reactstrap';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar';

import { Toaster } from 'react-hot-toast';

import routes from 'routes';

import api from 'services/api';
import { useAuth } from '../hooks/auth';
import Socket from 'views/pages/Socket';
import imageLogo from '../assets/img/brand/fockink_graf.png';

const Admin = props => {
  const mainContent = useRef(null);
  const location = useLocation();
  const { usuario } = useAuth();
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [acessoProgramas, setAcessoProgramas] = useState([]);
  const [usuarioRoutes, setUsuarioRoutes] = useState([]);
  const isMenuLateralAberto = document.body.classList.contains("g-sidenav-pinned");

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    document.body.style.backgroundImage = null;
  }, [location]);

  async function carregaAcessoUsuario() {
    const { id } = usuario;

    const response = await api.get(`/programa`, {
      params: {
        usuarioId: id,
      },
    });

    setAcessoProgramas(response.data);
  }

  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.add("g-sidenav-hidden");
    carregaAcessoUsuario();
  }, []);

  function getMainPath(path){
    const rawPath = path.split('/')?.[1];

    return `/${rawPath}`;
  }

  function getUsuarioTemAcesso(route) {
    return new Promise((resolve, reject) => {

      if (route?.collapse && route?.views.length) {

        const viewsUsar = route.views.filter(routeView => {
          return (routeView?.public || false) || acessoProgramas.find(
            acessoPrograma => getMainPath(acessoPrograma.arquivo) === getMainPath(routeView?.path),
          );
        });

        if(!viewsUsar || viewsUsar.length <= 0){
          resolve(null);
        }

        resolve({
          ...route,
          views: viewsUsar,
        });
      }
      else{
        const mainPath = getMainPath(route.path);

        const encontrouRegistro = acessoProgramas.find(
          acessoPrograma => acessoPrograma.arquivo === mainPath,
        );

        const programaGeral = mainPath === '/login' || mainPath === '/index' || mainPath === 'acesso-externo';

        resolve(encontrouRegistro || programaGeral ? route : null);
      }
    });
  }

  async function montarDados(){

    const rotasSalvar = [];

    for await (const route of routes){
      const usuarioTemAcesso = await getUsuarioTemAcesso(route);

      if (usuarioTemAcesso) {
        rotasSalvar.push(usuarioTemAcesso);
      } else if (route?.public) {
        rotasSalvar.push(route);
      }
    }
    setUsuarioRoutes(rotasSalvar);
  }

  useEffect(() => {
    if(acessoProgramas.length > 0 && routes.length > 0){
      montarDados();
    }
  }, [acessoProgramas, routes]);

  function getRoutes(pRoutes) {
    return pRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout !== '/admin') {
        return null;
      }

      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  }

  const getBrandText = () => {
    for (const route of usuarioRoutes) {
      if (props.location.pathname.indexOf(route.layout + route.path) !== -1) {
        return route.name;
      }
    }

    return '';
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (isMenuLateralAberto) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <Sidebar
        routes={usuarioRoutes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: '/admin/index',
          imgSrc: imageLogo,
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText()} />
        <Switch>
          {getRoutes(usuarioRoutes)}
        </Switch>
        <Container fluid>
          <AdminFooter />
          <Socket/>
        </Container>
      </div>
    </>
  );
};

export default Admin;
