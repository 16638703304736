import UserSmallHeader from 'components/Headers/UserSmallHeader';
import FichaTecnica from 'models/FichaTecnica';
import OrcamentoMaterial from 'models/OrcamentoMaterial';
import { useEffect, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';
import exportToSheet from 'utils/exportToSheet';
import formatCurrency from 'utils/formatCurrency';

import {
  LabelOrcamento,
  Th,
  Td,
  Tr,
  Descricao,
  ButtonsRightCol,
} from './styles';

interface LocationState {
  fichaTecnica: FichaTecnica;
  orcamentoId: number;
  orcamentoParteId: number;
}

interface ItemPrecificacao {
  atividade: number;
  materiais: OrcamentoMaterial[];
  total: number;
}

function Precificacao(): JSX.Element {
  const location = useLocation<LocationState>();
  const orcamentoId = location.state?.orcamentoId;
  const orcamentoParteId = location.state?.orcamentoParteId;
  const [itensPrecificacao, setItensPrecificacao] = useState(
    [] as ItemPrecificacao[],
  );
  const [valorTotalGeral, setValorTotalGeral] = useState(0);

  async function calculaPrecificacao(): Promise<void> {
    Swal.fire({
      icon: 'info',
      text: `Calculando precificação...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    await api.post('/integracao/precificacao/calculo-novo', {
      orcamentoId,
    });
  }

  async function carregaOrcamentoMateriais(
    mostrarMensagem: boolean,
  ): Promise<void> {
    if (mostrarMensagem)
      Swal.fire({
        icon: 'info',
        text: `Buscando materiais...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    const response = await api.get(`/orcamento/lista-materiais-agrupado-novo`, {
      params: { orcamentoId },
    });
    setItensPrecificacao(response.data.registros);
    setValorTotalGeral(response.data.total);
    if (mostrarMensagem) Swal.close();
  }

  async function handleCalculaPrecificacao() {
    let hasError = false;
    try {
      await calculaPrecificacao();
    } catch (e: any) {
      hasError = true;
    } finally {
      await carregaOrcamentoMateriais(!hasError);
    }
  }

  useEffect(() => {
    carregaOrcamentoMateriais(true);
  }, []);

  function Item(props: {
    id: number;
    atividade: number;
    orcamentoMaterial: OrcamentoMaterial;
  }): JSX.Element {
    const { id, atividade, orcamentoMaterial } = props;
    const { valor, valorTotal, material, quantidade, criadoManualmente } =
      orcamentoMaterial;
    const valorMoeda = formatCurrency(valor);
    const valorMoedaTotal = formatCurrency(valorTotal);

    return (
      <Tr key={id} semPreco={valor === 0}>
        <Td>{atividade}</Td>
        <Td>{material.diteCodigo}</Td>
        <Td>
          <Descricao>
            {material.descricao}
            {criadoManualmente ? (
              <>
                <span title="Material adicionado manualmente na configuração">
                  <Badge color="success" className="ml-2">
                    M
                  </Badge>
                </span>
              </>
            ) : (
              ''
            )}
          </Descricao>
        </Td>
        <Td className="text-center">{quantidade}</Td>
        <Td className="text-right">{valorMoeda}</Td>
        <Td className="text-right">{valorMoedaTotal}</Td>
      </Tr>
    );
  }

  function Total(props: {
    texto: string;
    valor: number;
    elementType?: keyof JSX.IntrinsicElements;
  }): JSX.Element {
    const { texto, valor, elementType: ElementType = 'h3' } = props;
    return (
      <tr>
        <Td colSpan={3} />
        <Td className="text-right">
          <ElementType>{texto}</ElementType>
        </Td>
        <Td className="text-right">
          <ElementType>{formatCurrency(valor)}</ElementType>
        </Td>
      </tr>
    );
  }

  function handleExportToSheet() {
    function getObjSomatorio(texto: string, valor: number) {
      return {
        Atividade: '',
        Item: '',
        Quantidade: '',
        'Valor unitário': texto,
        'Valor total': formatCurrency(valor),
      };
    }

    const itensPrecificacaoAtividade = itensPrecificacao.map(item => {
      const { atividade, materiais, total } = item;
      const itensMateriais = materiais.map(orcamentoMaterial => {
        const { material, quantidade, valor, valorTotal } = orcamentoMaterial;
        const { descricao, diteCodigo } = material;
        return {
          Atividade: atividade,
          Codigo: diteCodigo,
          Item: descricao,
          Quantidade: quantidade,
          'Valor unitário': formatCurrency(valor),
          'Valor total': formatCurrency(valorTotal),
        };
      });
      const somatorioPorAtividade = getObjSomatorio(
        `Total atividade ${atividade}`,
        total,
      );
      return [...itensMateriais, somatorioPorAtividade];
    });

    const merged = itensPrecificacaoAtividade.length
      ? itensPrecificacaoAtividade.flat(1)
      : [];

    const totalGeral = getObjSomatorio('Total geral', valorTotalGeral);
    const dadosExportacao = [...merged, totalGeral];
    const nomeArquivo = `Precificação itens - Orç ${orcamentoId}`;
    exportToSheet(dadosExportacao, nomeArquivo);
  }

  return (
    <>
      <UserSmallHeader />
      <Container fluid>
        <Row className="mt-2 ml-1">
          <Col sm="7">
            <LabelOrcamento>
              Configuração {orcamentoId} - Precificação
            </LabelOrcamento>
          </Col>
          <ButtonsRightCol>
            <Button
              size="sm"
              className="btn-icon btn-2"
              color="success"
              outline
              type="button"
              onClick={handleExportToSheet}
            >
              <FaFileExcel />
              <span className="btn-inner--text">Exportar planilha</span>
            </Button>
            <Button
              className="btn-icon btn-2 float-right"
              color="danger"
              outline
              type="button"
              size="sm"
              onClick={handleCalculaPrecificacao}
            >
              Recalcular e gerar lista
            </Button>
          </ButtonsRightCol>
        </Row>

        <Card className="shadow mt-2">
          <CardBody className="p-1">
            <Card className="shadow mt-1">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <Th>Ativ</Th>
                    <Th>Código</Th>
                    <Th>Item</Th>
                    <Th className="text-center">Qtd</Th>
                    <Th className="text-right">Valor unitário</Th>
                    <Th className="text-right">Valor total</Th>
                  </tr>
                </thead>

                {itensPrecificacao?.length ? (
                  <tbody>
                    {itensPrecificacao?.map((itemPrecificacao, index) => {
                      const { atividade, materiais, total } = itemPrecificacao;
                      return (
                        <>
                          {materiais.map(orcamentoMaterial => (
                            <Item
                              id={index}
                              atividade={atividade}
                              orcamentoMaterial={orcamentoMaterial}
                            />
                          ))}
                          <Total
                            texto={`Total atividade ${atividade}`}
                            valor={total}
                          />
                        </>
                      );
                    })}
                  </tbody>
                ) : (
                  <tr>
                    <Td className="text-center" colSpan={5}>
                      Nenhum registro encontrado
                    </Td>
                  </tr>
                )}
                <tfoot>
                  <Total
                    texto="Total geral"
                    valor={valorTotalGeral}
                    elementType="h2"
                  />
                </tfoot>
              </Table>
            </Card>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Precificacao;
