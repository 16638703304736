import { FaTimesCircle, FaCheckCircle, FaUpload } from 'react-icons/fa';

import {
  Button as ButtonRS,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from 'reactstrap';
import { useEffect, useRef, useState } from 'react';

import '../../css/cropper.css';

import DefaultImage from 'assets/img/default.jpg';

import { Cropper } from 'react-cropper';
import toast from 'react-hot-toast';
import { ButtonCarregarImagem, LabelButtonCarregarImagem } from './styles';

interface Props {
  onSave: (image: string | null) => void;
  isOpen: boolean;
  toggle: () => void;
  imagem: string | undefined;
  descricao: string | undefined;
  freeAspectRatio?: boolean;
}

function ModalUploadImagem({
  onSave,
  isOpen,
  toggle,
  imagem,
  descricao,
  freeAspectRatio,
}: Props): JSX.Element {
  const [imagemBase64, setImagemBase64] = useState('');
  const [imagemCroppedBase64, setImagemCroppedBase64] = useState('');

  const cropperRef = useRef<HTMLImageElement>(null);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    setImagemCroppedBase64(
      cropper
        .getCroppedCanvas({
          fillColor: '#fff',
        })
        .toDataURL(),
    );
  };

  useEffect(() => {
    setImagemBase64(imagem ? `data:image/jpeg;base64,${imagem}` : DefaultImage);
  }, [imagem]);

  async function handleImageRead(event: any) {
    const file = event.target.files[0];
    const limitSizeUploadInBytes =
      1024 *
      1024 *
      Number(process.env.REACT_APP_LIMIT_SIZE_UPLOAD_IMAGE_IN_MB || 5);
    const fileSizeInBytes = file.size;

    async function convertBase64() {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      });
    }

    function checkLimitSizeUpload() {
      if (fileSizeInBytes > limitSizeUploadInBytes) {
        toast.error(
          `Tamanho do arquivo (${(fileSizeInBytes / 1024 / 1024).toFixed(
            2,
          )} MB) supera o limite (${
            limitSizeUploadInBytes / 1024 / 1024
          } MB) para upload de imagens.`,
        );
        throw Error;
      }
    }

    try {
      checkLimitSizeUpload();
      const base64 = String(await convertBase64()) ?? '';
      setImagemBase64(base64);
      toast.dismiss();
    } finally {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-white border-0">
            <Row>
              <Col>
                <h6 className="heading-small text-muted">{`Editar imagem de ${descricao}`}</h6>
              </Col>
              {imagem && (
                <ButtonRS
                  className="mr-2"
                  color="danger"
                  size="sm"
                  onClick={() => onSave(null)}
                >
                  <FaTimesCircle /> Excluir
                </ButtonRS>
              )}
            </Row>
          </CardHeader>
          <CardBody>
            <Cropper
              src={imagemBase64}
              crop={onCrop}
              ref={cropperRef}
              aspectRatio={freeAspectRatio ? undefined : 4 / 3}
            />

            <Form role="form">
              <FormGroup className="mb-3">
                <input
                  id="imagem-valor"
                  style={{ display: 'none' }}
                  accept=".png, .jpg"
                  type="file"
                  onChange={e => handleImageRead(e)}
                />
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Row>
              <Col sm="4">
                <LabelButtonCarregarImagem htmlFor="imagem-valor">
                  <ButtonCarregarImagem>
                    <FaUpload /> Nova imagem
                  </ButtonCarregarImagem>
                </LabelButtonCarregarImagem>
              </Col>

              <Col
                sm="8"
                style={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <ButtonRS color="secondary" onClick={toggle}>
                  Cancelar
                </ButtonRS>

                <ButtonRS
                  color="primary"
                  onClick={() => onSave(imagemCroppedBase64)}
                >
                  <FaCheckCircle /> Salvar
                </ButtonRS>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
    </Modal>
  );
}

export default ModalUploadImagem;
