import { FaPlusCircle } from 'react-icons/fa';
import { Button, ButtonGroup } from 'reactstrap';

interface Props {
  rows: number[];
  onClick: (event: any) => void;
  onClickNovaProposta: () => void;
}

function FloatingButton({
  rows,
  onClick,
  onClickNovaProposta,
}: Props): JSX.Element {
  return (
    <>
      {!!rows.length && (
        <ButtonGroup className="float-right" role="group">
          <Button
            size="sm"
            className="btn-outline-default p-2"
            color="primary"
            type="button"
            onClick={onClick}
          >
            Incluir em proposta <FaPlusCircle />
          </Button>
          <Button
            size="sm"
            className="btn-outline-default p-2"
            color="primary"
            type="button"
            onClick={onClickNovaProposta}
          >
            Nova proposta <FaPlusCircle />
          </Button>
        </ButtonGroup>
      )}
    </>
  );
}

export default FloatingButton;
