import { useContext, useEffect, useState } from 'react';
import {
  Card,
  Col,
  CardBody,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import cepPromise from 'cep-promise';
import { CircularProgress, Typography } from '@material-ui/core';
import api from 'services/api';
import Proposta from 'models/Proposta';
import { PropostaContext } from 'views/pages/Proposta';
import InputSelect from 'components/InputSelect';
import toast from 'react-hot-toast';

interface ILocalObra {
  proposta: Proposta;
  propostaVersaoId: number;
}

interface iCondicaoFrete {
  value: string;
  name: string;
  exterior: boolean;
}

export default function LocalObra({
  proposta,
  propostaVersaoId,
}: ILocalObra): JSX.Element {
  const {
    condicaoFrete,
    setCondicaoFrete,
    setIsObraExterior,
    bloquearLocal,
    setBloquearLocal,
    atualizarTelaProposta,
  } = useContext(PropostaContext);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [buscandoCep, setBuscandoCep] = useState(false);
  const [pais, setPais] = useState('');
  const [obraExterior, setObraExterior] = useState(
    proposta.PropostaLocalObra?.exterior || false,
  );

  const [cep, setCep] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cepInvalido, setCepInvalido] = useState(false);
  const isNacional = !obraExterior;
  const [updatingLocalObra, setUpdatingLocalObra] = useState(false);
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');

  const condicaoFreteOptions = [
    { value: 'FOB', name: 'FOB', exterior: false },
    { value: 'CIF', name: 'CIF', exterior: false },
    { value: 'FCA', name: 'FCA', exterior: true },
    { value: 'EXW', name: 'EXW', exterior: true },
    { value: 'CPT', name: 'CPT', exterior: true },
    { value: 'CIP', name: 'CIP', exterior: true },
    { value: 'CRF', name: 'CRF', exterior: true },
  ] as iCondicaoFrete[];

  const obraExteriorOptions = [
    { value: 'N', name: 'Não' },
    { value: 'S', name: 'Sim' },
  ];

  function limpaCamposLocal() {
    setEstado('');
    setCidade('');
    setBairro('');
    setLogradouro('');
  }

  async function buscaCep(cepInput: string) {
    if (cepInput.length <= 7) {
      return;
    }
    try {
      setBuscandoCep(true);
      setBairro('');
      setLogradouro('');
      const response = await cepPromise(cepInput);
      setEstado(response.state);
      setCidade(response.city);
      setBairro(response.neighborhood);
      setLogradouro(response.street);
      setCepInvalido(false);
    } catch {
      limpaCamposLocal();
      setCepInvalido(true);
    } finally {
      setBuscandoCep(false);
    }
  }

  async function carregaLocalObra() {
    if (proposta.id) {
      try {
        setCarregandoDados(true);
        const response = await api.get(
          `/proposta/${propostaVersaoId}/local-obra`,
        );
        setCep(response.data.cep);
        setEstado(response.data.uf);
        setCidade(response.data.municipio);
        setBairro(response.data.bairro);
        setLogradouro(response.data.logradouro);
        setNumero(response.data.numero);
        setComplemento(response.data.complemento);
        setObraExterior(response.data.exterior);
        setInscricaoEstadual(response.data.inscricaoEstadual);

        const isExterior = Boolean(response.data.exterior);
        setPais(isExterior ? response.data.pais : 'Brasil');

        setIsObraExterior(response.data.exterior);
      } finally {
        setCarregandoDados(false);
      }
    }
  }

  useEffect(() => {
    carregaLocalObra();
  }, []);

  async function handleUpdateLocalObra() {
    if (
      !inscricaoEstadual ||
      inscricaoEstadual === '' ||
      inscricaoEstadual === null ||
      inscricaoEstadual === undefined ||
      !condicaoFrete ||
      condicaoFrete === '' ||
      condicaoFrete === null ||
      condicaoFrete === undefined
    ) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }
    try {
      setUpdatingLocalObra(true);
      await api.put(`/proposta/${propostaVersaoId}/local-obra`, {
        logradouro,
        numero: numero ? String(numero) : null,
        complemento,
        bairro,
        cep,
        municipio: cidade,
        uf: estado,
        pais,
        propostaVersaoId,
        condicaoFrete,
        exterior: obraExterior,
        inscricaoEstadual: inscricaoEstadual ? String(inscricaoEstadual) : null,
      });
    } finally {
      setUpdatingLocalObra(false);
      carregaLocalObra();
      atualizarTelaProposta(true);
    }
  }

  function handleInputCep(cepInput: string) {
    if (!cepInput) {
      limpaCamposLocal();
    }
    setCepInvalido(false);

    if (isNacional) buscaCep(cepInput);
  }

  return (
    <>
      <Card className="bg-secondary shadow mt-1 mb-1">
        <CardBody>
          <Form>
            <Row className="ml-0">
              <h6 className="heading-small text-muted mb-1">Local da obra</h6>
              {carregandoDados && (
                <CircularProgress
                  className="mt-2 ml-2"
                  color="inherit"
                  size={10}
                />
              )}
            </Row>

            <hr className="mt-2" />
            <div className="pl-lg-3">
              <Row>
                <Col lg="2" xs="12">
                  <FormGroup>
                    <Label className="form-control-label">
                      Obra no exterior?
                    </Label>
                    <InputSelect
                      options={obraExteriorOptions}
                      value={obraExterior ? 'S' : 'N'}
                      hideNoOption
                      disabled={bloquearLocal}
                      onChange={text => {
                        setObraExterior(text.target.value === 'S');
                        setIsObraExterior(text.target.value === 'S');
                        if (text.target.value !== 'S') setPais('Brasil');
                        else setPais('');
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="2" xs="12">
                  <FormGroup>
                    <Label className="form-control-label">País</Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      disabled={!obraExterior || bloquearLocal}
                      value={pais}
                      onChange={text => setPais(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="2" xs="12">
                  <FormGroup>
                    <Label className="form-control-label">CEP</Label>
                    {buscandoCep && (
                      <CircularProgress
                        className="ml-2"
                        color="inherit"
                        size={10}
                      />
                    )}
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={cep}
                      disabled={bloquearLocal}
                      onInput={event =>
                        handleInputCep(event.currentTarget.value)
                      }
                      onChange={text => setCep(text.target.value)}
                    />
                    {cepInvalido && (
                      <Typography
                        className="mt-2"
                        variant="caption"
                        display="block"
                        style={{ color: '#ed5565' }}
                      >
                        CEP inválido
                      </Typography>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="2" xs="12">
                  <FormGroup>
                    <Label className="form-control-label">Estado</Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      disabled={isNacional || bloquearLocal}
                      value={estado}
                      onChange={text => setEstado(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="3" xs="12">
                  <FormGroup>
                    <Label className="form-control-label">Cidade</Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      disabled={isNacional || bloquearLocal}
                      value={cidade}
                      onChange={text => setCidade(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="3" xs="12">
                  <FormGroup>
                    <Label className="form-control-label">Bairro</Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={bairro}
                      disabled={bloquearLocal}
                      onChange={text => setBairro(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" xs="12">
                  <FormGroup>
                    <Label className="form-control-label">Logradouro</Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={logradouro}
                      disabled={bloquearLocal}
                      onChange={text => setLogradouro(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="2" xs="6">
                  <FormGroup>
                    <Label className="form-control-label">Número</Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={numero}
                      disabled={bloquearLocal}
                      onChange={text => setNumero(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" xs="6">
                  <FormGroup>
                    <Label className="form-control-label">Complemento</Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={complemento}
                      disabled={bloquearLocal}
                      onChange={text => setComplemento(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" xs="6">
                  <FormGroup>
                    <Label className="form-control-label">
                      *Condição de frete
                    </Label>
                    <InputSelect
                      options={condicaoFreteOptions.filter(
                        item => item.exterior === obraExterior,
                      )}
                      value={condicaoFrete}
                      disabled={bloquearLocal}
                      onChange={text => setCondicaoFrete(text.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" xs="6">
                  <FormGroup>
                    <Label className="form-control-label">
                      *Inscrição Estadual
                    </Label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={inscricaoEstadual}
                      disabled={bloquearLocal}
                      onChange={text => setInscricaoEstadual(text.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {!bloquearLocal && (
                <Row>
                  <Col sm="10" />
                  <Col sm="2">
                    <Button
                      className="float-right"
                      color="default"
                      disabled={updatingLocalObra}
                      onClick={handleUpdateLocalObra}
                    >
                      {updatingLocalObra ? 'Salvando...' : 'Salvar'}
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
