export default function generateHash(): string {
  const tamanho = 11;
  const chave =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  let result = '';
  for (let i = tamanho; i > 0; i -= 1)
    result += chave[Math.floor(Math.random() * chave.length)];
  return result;
}
