import axios from 'axios';
import { ToastAsync } from 'components/Toast';
import toast from 'react-hot-toast';
import { Toast } from 'reactstrap';
import Swal from 'sweetalert2';
import triggerSignOut from './triggerSignOut';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

api.interceptors.request.use(
  config => {
    // ToastAsync.fire({
    //   icon: 'info',
    //   title: 'Carregando...',
    // });

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    // ToastAsync.close();
    // status code no intervalo de 2xx
    return response;
  },
  error => {
    // ToastAsync.close();

    let text = 'Erro interno';

    switch (error?.message || error.response?.data?.message) {
      case 'Network Error':
        text = 'Serviço inacessível';
        break;

      default:
        text = error.response?.data?.message || 'Erro interno';
        break;
    }

    if (text.toUpperCase() === 'THIS_USER_IS_DISABLED') {
      triggerSignOut();
      Swal.fire({
        icon: 'error',
        title: 'Atenção!',
        html: `Usuário foi desativado.
        <a href="/auth/login">Voltar à tela de login</a>`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: text,
      });
    }

    return Promise.reject(error);
  },
);

export default api;
