import { Col, Row } from 'reactstrap';
import { ColLabel, Label, Valor } from './styles';

function Campo({
  label,
  valor,
  unidade,
  sm,
  number,
  style,
  hidden,
  labelOnTop,
}: {
  label: string;
  valor?: string | number | null;
  unidade?: string;
  sm?: string;
  number?: boolean;
  hidden?: boolean;
  labelOnTop?: boolean;
  style?: any;
}): JSX.Element {
  const valorOrEmpty = valor ?? '';

  const unidadeOrEmpty = unidade ?? '';

  const valorToDisplay =
    valorOrEmpty || valorOrEmpty === 0
      ? `${valorOrEmpty} ${unidadeOrEmpty}`
      : '';

  return (
    <Col sm={sm ?? '4'} hidden={hidden}>
      <Row>
        {labelOnTop && (
          <>
            <Label>{label}: </Label>
            <Valor
              readOnly
              value={valorToDisplay}
              style={style ?? {}}
              number={number}
            />
          </>
        )}
        {!labelOnTop && (
          <>
            <ColLabel>
              <Label>{label}: </Label>
            </ColLabel>
            <Col sm="5">
              <Valor
                readOnly
                value={valorToDisplay}
                style={style ?? {}}
                number={number}
              />
            </Col>
          </>
        )}
      </Row>
    </Col>
  );
}

export default Campo;
