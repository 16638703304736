import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import api from 'services/api';

import { Link } from 'react-router-dom';
import { arredondaDecimais } from 'utils/arredondaDecimais';

import BocalModel from '../../../models/ConfiguradorBocal';
import AspersorModel from '../../../models/ConfiguradorAspersor';

const ConfiguradorBocais: React.FC = () => {
  const [bocais, setBocais] = useState([] as BocalModel[]);
  // eslint-disable-next-line prettier/prettier
  const [bocalEditing, setbocalEditing] = useState({} as BocalModel);
  const [aspersores, setAspersores] = useState([] as AspersorModel[]);
  const [aspersorEditing, setAspersorEditing] = useState({} as AspersorModel);
  const [aspersorAtivo, setAspersorAtivo] = useState<AspersorModel[]>([]);

  const [formModalState, setFormModalState] = useState(false);

  const [aspersoreSelecionado, setAspersoreSelecionado] = useState('');

  async function listaBocais() {
    const response = await api.get('/integracao/bocais');

    setBocais(response.data);
  }

  async function listaAspersores() {
    const response = await api.get('/integracao/aspersores');
    setAspersores(response.data);
    setAspersorAtivo(
      response.data.filter((item: { tipo: string }) => item.tipo === 'F'),
    );
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaBocais();
    listaAspersores();
  }, []);

  async function handleDelete(bocalId: number) {
    Swal.fire({
      title: `Deseja excluir o Bocal?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/integracao/bocais/${bocalId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaBocais();
      }
    });
  }
  async function handleInsert() {
    setbocalEditing({} as BocalModel);
    setAspersorEditing({} as AspersorModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(bocal: BocalModel) {
    setbocalEditing(bocal);

    if (bocal.idMoap) {
      const aspersor = aspersorAtivo.filter(item => item.id === bocal.idMoap);

      if (aspersor.length) setAspersorEditing(aspersor[0] as AspersorModel);
      else setAspersorEditing({} as AspersorModel);
    }

    setFormModalState(!formModalState);
  }

  async function handleSaveBocal() {
    // eslint-disable-next-line prettier/prettier
    const { numero } = bocalEditing;

    if (!numero) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (bocalEditing.id) {
      await api.put(`/integracao/bocais/${bocalEditing.id}`, {
        idMoap: aspersorEditing.id,
        numero: bocalEditing.numero,
        numeroSaida: bocalEditing.numeroSaida,
        sigla: bocalEditing.sigla,
      });
    } else {
      await api.post(`/integracao/bocais/`, {
        idMoap: aspersorEditing.id,
        numero: bocalEditing.numero,
        numeroSaida: bocalEditing.numeroSaida,
        sigla: bocalEditing.sigla,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setbocalEditing({} as BocalModel);
    listaBocais();
    listaAspersores();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{bocalEditing.aspersor}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Aspersor </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Aspersor"
                            type="select"
                            value={aspersorEditing.id || ''}
                            onChange={text =>
                              setAspersorEditing({
                                ...aspersorEditing,
                                id: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Aspersor...</option>
                            {aspersorAtivo.map(aspersor => (
                              <option value={aspersor.id}>
                                {aspersor.nome}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Bocal </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Bocal"
                            value={bocalEditing.numero}
                            type="number"
                            onChange={text =>
                              setbocalEditing({
                                ...bocalEditing,
                                numero: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Saída </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Saída"
                            value={bocalEditing.numeroSaida}
                            type="number"
                            onChange={text =>
                              setbocalEditing({
                                ...bocalEditing,
                                numeroSaida: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Sigla </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Saída"
                            value={bocalEditing.sigla}
                            type="text"
                            onChange={text =>
                              setbocalEditing({
                                ...bocalEditing,
                                sigla: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveBocal}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Bocais</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Aspersor</th>
                    <th>Bocal</th>
                    <th>Saída</th>
                    <th>Sigla</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {bocais.map(bocal => (
                    <tr key={bocal.id}>
                      <td className="py-2">{bocal.aspersor}</td>
                      <td className="py-2">
                        {arredondaDecimais(bocal.numero, 2)}
                      </td>
                      <td className="py-2">{bocal.numeroSaida}</td>
                      <td className="py-2">{bocal.sigla}</td>
                      <td className="py-2">
                        <div className="float-right">
                          <Link
                            to={{
                              pathname: `/admin/mca-pressao`,
                              state: {
                                stateBocal: bocal,
                                stateAspersor: bocal.aspersor,
                              },
                            }}
                            className="mr-3"
                          >
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="primary"
                              type="button"
                            >
                              <span>MCA/Pressão</span>
                            </Button>
                          </Link>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="warning"
                            type="button"
                            onClick={() => {
                              handleUpdate(bocal);
                            }}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(bocal.id);
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorBocais;
