import styled from 'styled-components';

export const Th = styled.th`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e9ecef;
`;

export const Td = styled.td`
  padding: 0 !important;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1% 0.5%;
`;
