import { Row } from 'reactstrap';
import { Container, Label, Typography } from './styles';

export default function CondicoesPagamento(): JSX.Element {
  return (
    <Container>
      <Row>
        <Typography variant="h6" className="float-left mb-3">
          <strong>3 - CONDIÇÕES DE PAGAMENTO:</strong>
        </Typography>
      </Row>

      <Row>
        <Label>
          Obs.: Os preços do presente Contrato de Fornecimento foram calculados
          considerando-se custos, encargos fiscais e de natureza social vigentes
          nesta data. Caso ocorra alguma alteração na legislação tributária e de
          custos dos insumos alteração / aumento, os preços serão cobrados no
          momento do fornecimento. <br />
          Encargos Financeiros: Incidirão encargos financeiros sobre o valor em
          Reais a partir da data da entrega do equipamento até o efetivo
          pagamento, correspondente a 4% ao mês, referente a 1% juros de mora e
          3% de despesas de cobrança.
        </Label>
      </Row>
    </Container>
  );
}
