import { useEffect, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import orderBy from 'lodash.orderby';
import InputLabel from '@material-ui/core/InputLabel';
import { Button } from 'reactstrap';

import {
  FaPen,
  FaTimesCircle,
  FaCheck,
  FaTrash,
  FaAngleUp,
  FaAngleDown,
  FaPlus,
  FaArrowCircleUp,
} from 'react-icons/fa';
import { FormHelperText, TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

interface CustomTableList {
  id: string;
  texto: string;
}

interface CustomTableHeader {
  name: string;
  prop: string;
  type: string;
  searchable: boolean;
  sortable: boolean;
  editable: boolean;
  required: boolean;
  values?: CustomTableList[];
  direction?: string | 'asc';
}

interface CustomTableColumn {
  prop: string;
  value: any | { id: string; texto: string; errorMessage?: string };
  newValue: any | { id: string; texto: string; errorMessage?: string };
}

interface CustomTableRow {
  id: number;
  isNew: boolean;
  isEditing: boolean;
  columns: CustomTableColumn[];
}

interface CustomLinkRow {
  name?: string;
  type?: string;
  pathname?: string;
  stateTipo?: string;
  stateLance?: any;
  stateTubo?: any;
}

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  header: CustomTableHeader[];
  // eslint-disable-next-line react/no-unused-prop-types
  rows?: CustomTableRow[];
  // eslint-disable-next-line react/no-unused-prop-types
  onDelete?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  onSave?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  onAddRow?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  onStartEditing?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  onStopEditing?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  onLink?: CustomLinkRow[];
  // eslint-disable-next-line react/no-unused-prop-types
  onLinkBalanco?: CustomLinkRow[];
  ocultaBotoes?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    tableCell: {
      padding: '10px',
    },
  }),
);

function CreateTable(props: Props): JSX.Element {
  const classes = useStyles();
  const { header, rows, onLink, onLinkBalanco, ocultaBotoes } = props;

  const [columnToSort, setColumnToSort] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [rowsEdit, setRowsEdit] = useState([] as CustomTableRow[]);

  // Não definir a propiedade 'sortable' como false para todos os registros, deixar ao menos um como true
  if (!columnToSort) {
    setColumnToSort(header.filter(data => data.sortable)[0].prop);
    setSortDirection(
      header.filter(data => data.sortable)[0].direction ?? 'asc',
    );
  }

  async function handleSort(sortable: boolean, columnName: string) {
    if (sortable) {
      setColumnToSort(columnName);
      setSortDirection(
        // eslint-disable-next-line no-nested-ternary
        columnToSort === columnName
          ? sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc',
      );
    }
  }

  function getSortableRows() {
    if (sortDirection === 'asc')
      return orderBy(
        rows?.filter(item => !item.isNew),
        (item: any) => {
          const column = item.columns.filter(
            (x: any) => x.prop === columnToSort,
          );

          if (typeof column[0].value === 'object') {
            return column[0].value.texto;
          }

          return column[0].value;
        },
        'asc',
      );
    return orderBy(
      rows?.filter(item => !item.isNew),
      (item: any) => {
        const column = item.columns.filter((x: any) => x.prop === columnToSort);

        if (typeof column[0].value === 'object') {
          return column[0].value.texto;
        }

        return column[0].value;
      },
      'desc',
    );
  }

  function saveTempValue(row: any, prop: any, e: any) {
    // let rowsChange: CustomTableRow[] | undefined =
    //  rowsEdit?.length === 0 ? rows : rowsEdit;
    let rowsChange: CustomTableRow[] | undefined = rowsEdit;
    rowsChange = rowsChange?.map((item: any) => {
      if (item.id === row) {
        item.columns.map((coluna: any) => {
          if (coluna.prop === prop) {
            // eslint-disable-next-line no-param-reassign
            coluna.newValue = e;
          }
          return coluna;
        });
      }
      return item;
    });

    if (rowsChange) setRowsEdit(rowsChange);
  }

  function handleStartEditing(row: any) {
    props.onStartEditing(row);
  }

  function handleStopEditing(row: any) {
    setRowsEdit(
      rowsEdit?.map((item: any) => {
        if (item.id === row.id) {
          item.columns.map((coluna: any) => {
            // eslint-disable-next-line no-param-reassign
            coluna.newValue = coluna.value;
            return coluna;
          });
        }
        return item;
      }),
    );

    setRowsEdit(rowsEdit.filter(item => item.id !== row.id));

    props.onStopEditing(row);
  }

  function handleSave(row: any) {
    let hasError = false;
    setRowsEdit(
      rowsEdit?.map((item: any) => {
        if (item.id === row.id) {
          item.columns.map((coluna: any) => {
            const cabecalho = header.filter(hd => hd.prop === coluna.prop);

            if (cabecalho[0].required) {
              if (cabecalho[0].type === 'SELECT') {
                if (coluna.newValue.id === null || coluna.newValue.id === '') {
                  hasError = true;
                  // eslint-disable-next-line no-param-reassign
                  coluna.errorMessage = 'Campo obrigatório';
                } else {
                  // eslint-disable-next-line no-param-reassign
                  coluna.errorMessage = null;
                }
              } else if (cabecalho[0].type === 'TEXT') {
                if (coluna.newValue === null || coluna.newValue === '') {
                  hasError = true;
                  // eslint-disable-next-line no-param-reassign
                  coluna.errorMessage = 'Campo obrigatório';
                } else {
                  // eslint-disable-next-line no-param-reassign
                  coluna.errorMessage = null;
                }
              } else if (cabecalho[0].type === 'NUMBER') {
                if (
                  coluna.newValue === null ||
                  coluna.newValue === '' ||
                  Number(coluna.newValue) <= 0
                ) {
                  hasError = true;
                  // eslint-disable-next-line no-param-reassign
                  coluna.errorMessage = 'Campo obrigatório';
                } else {
                  // eslint-disable-next-line no-param-reassign
                  coluna.errorMessage = null;
                }
              }
            }
            return coluna;
          });
        }
        return item;
      }),
    );

    if (!hasError) props.onSave(row);
  }

  function selectionChange(i: any, row: any) {
    const cabecalho = header?.filter(item => item.prop === row.target.name);

    const opcao = cabecalho[0]?.values?.filter(
      item => item.id === row.target.value,
    );

    saveTempValue(i, row.target.name, {
      id: opcao && opcao[0].id,
      texto: opcao && opcao[0].texto,
    });
  }

  function addRow(row: any, i: any) {
    if (row.isEditing) {
      const itemEdicao = rowsEdit.filter(item => item.id === row.id);
      let rowUsar = row;

      if (itemEdicao.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        rowUsar = itemEdicao[0];
      } else {
        setRowsEdit([row, ...rowsEdit]);
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={`tr-${i}`}>
          {header.map((y, k) => {
            const valor = rowUsar.columns.filter((x: any) => x.prop === y.prop);
            if (y.type === 'SELECT') {
              if (y.editable || (!y.editable && rowUsar.isNew)) {
                return (
                  <TableCell
                    className={classes.tableCell}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`trc-${i}-${k}`}
                  >
                    <FormControl
                      className={classes.formControl}
                      error={
                        valor[0].errorMessage !== null &&
                        valor[0].errorMessage !== undefined &&
                        valor[0].errorMessage !== ''
                      }
                    >
                      <InputLabel htmlFor="grouped-native-select">
                        {`${y.name} ${y.required ? '*' : ''}`}
                      </InputLabel>
                      <Select
                        name={y.prop}
                        value={valor[0].newValue.id}
                        defaultValue={null}
                        onChange={e => selectionChange(rowUsar.id, e)}
                      >
                        {y.values?.map(item => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.texto}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{valor[0].errorMessage}</FormHelperText>
                    </FormControl>
                  </TableCell>
                );
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell className={classes.tableCell} key={`trc-${i}-${k}`}>
                  {
                    rowUsar.columns.filter((r: any) => r.prop === y.prop)[0]
                      .newValue.texto
                  }
                </TableCell>
              );
            }
            if (y.type === 'TEXT') {
              if (y.editable || (!y.editable && rowUsar.isNew)) {
                return (
                  <TableCell
                    className={classes.tableCell}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`trc-${i}-${k}`}
                  >
                    <TextField
                      name={y.prop}
                      label={`${y.name} ${y.required ? '*' : ''}`}
                      value={valor[0].newValue}
                      defaultValue={null}
                      onChange={(e: any) =>
                        saveTempValue(rowUsar.id, y.prop, e.target.value)
                      }
                      error={
                        valor[0].errorMessage !== null &&
                        valor[0].errorMessage !== undefined &&
                        valor[0].errorMessage !== ''
                      }
                      helperText={valor[0].errorMessage}
                    />
                  </TableCell>
                );
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell className={classes.tableCell} key={`trc-${i}-${k}`}>
                  {
                    rowUsar.columns.filter((r: any) => r.prop === y.prop)[0]
                      .newValue
                  }
                </TableCell>
              );
            }
            if (y.type === 'NUMBER') {
              if (y.editable || (!y.editable && rowUsar.isNew)) {
                return (
                  <TableCell
                    className={classes.tableCell}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`trc-${i}-${k}`}
                  >
                    <TextField
                      name={y.prop}
                      label={`${y.name} ${y.required ? '*' : ''}`}
                      type="NUMBER"
                      defaultValue={null}
                      value={valor[0].newValue || ''}
                      onChange={(e: any) =>
                        saveTempValue(rowUsar.id, y.prop, e.target.value)
                      }
                      error={
                        valor[0].errorMessage !== null &&
                        valor[0].errorMessage !== undefined &&
                        valor[0].errorMessage !== ''
                      }
                      helperText={valor[0].errorMessage}
                    />
                  </TableCell>
                );
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell className={classes.tableCell} key={`trc-${i}-${k}`}>
                  {
                    row.columns.filter((r: any) => r.prop === y.prop)[0]
                      .newValue
                  }
                </TableCell>
              );
            }
            if (y.type === 'LABEL') {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell className={classes.tableCell} key={`trc-${i}-${k}`}>
                  {
                    row.columns.filter((r: any) => r.prop === y.prop)[0]
                      .newValue
                  }
                </TableCell>
              );
            }
            return <></>;
          })}
          <TableCell>
            <div
              className="float-right"
              style={{
                textAlign: 'right',
              }}
            >
              <Button
                className="btn-icon btn-2"
                size="sm"
                color="primary"
                type="button"
                onClick={() => handleSave(rowUsar)}
              >
                <FaCheck />
              </Button>
              <Button
                className="btn-icon btn-2"
                size="sm"
                color="danger"
                type="button"
                onClick={() => handleStopEditing(rowUsar)}
              >
                <FaTimesCircle />
              </Button>
            </div>
          </TableCell>
        </TableRow>
      );
    }
    return (
      // eslint-disable-next-line react/no-array-index-key
      <TableRow key={`tr-${i}`}>
        {header.map((y, k) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <TableCell className={classes.tableCell} key={`trc-${i}-${k}`}>
              {typeof row.columns.filter((r: any) => r.prop === y.prop)[0]
                .newValue === 'object'
                ? row.columns.filter((r: any) => r.prop === y.prop)[0].newValue
                    .texto
                : row.columns.filter((r: any) => r.prop === y.prop)[0].newValue}
            </TableCell>
          );
        })}
        <TableCell className={classes.tableCell}>
          {!ocultaBotoes && (
            <div
              className="float-right"
              style={{
                textAlign: 'right',
              }}
            >
              {onLink?.map((y, k) => {
                if (y.type === 'LINK') {
                  return (
                    <Link
                      to={{
                        pathname: `${y.pathname}`,
                        state: {
                          stateLance: row,
                          stateTipo: `${y.stateTipo}`,
                        },
                      }}
                    >
                      <Button
                        size="sm"
                        className="btn-icon btn-2 mr-3"
                        color="primary"
                        type="button"
                      >
                        <span>{`${y.name}`}</span>
                      </Button>
                    </Link>
                  );
                }
                return <></>;
              })}
              {onLinkBalanco?.map((y, k) => {
                if (y.type === 'LINK') {
                  return (
                    <Link
                      to={{
                        pathname: `${y.pathname}`,
                        state: {
                          stateTubo: row,
                          stateTipo: `${y.stateTipo}`,
                        },
                      }}
                    >
                      <Button
                        size="sm"
                        className="btn-icon btn-2 mr-3"
                        color="primary"
                        type="button"
                      >
                        <span>{`${y.name}`}</span>
                      </Button>
                    </Link>
                  );
                }
                return <></>;
              })}
              <Button
                className="btn-icon btn-2"
                size="sm"
                color="primary"
                type="button"
                onClick={() => handleStartEditing(row)}
              >
                <FaPen />
              </Button>
              <Button
                className="btn-icon btn-2"
                size="sm"
                color="danger"
                type="button"
                onClick={() => props.onDelete(row)}
              >
                <FaTrash />
              </Button>
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, []);

  return (
    <>
      <Table style={{ maxHeight: '700', width: '100%', overflowX: 'scroll' }}>
        <TableHead>
          <TableRow>
            {header.map((x, i) => (
              <TableCell
                // eslint-disable-next-line react/no-array-index-key
                key={`thc-${i}`}
                className={classes.tableCell}
                onClick={() => handleSort(x.sortable, x.prop)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{x.name} </span>
                  {x.sortable &&
                    columnToSort === x.prop &&
                    (sortDirection === 'asc' ? <FaAngleUp /> : <FaAngleDown />)}
                </div>
              </TableCell>
            ))}
            <TableCell className={classes.tableCell}>
              {!ocultaBotoes && (
                <div
                  className="float-right"
                  style={{
                    textAlign: 'right',
                  }}
                >
                  <Button
                    className="btn-icon btn-2"
                    size="sm"
                    color="primary"
                    type="button"
                    onClick={() => props.onAddRow()}
                  >
                    <FaPlus />
                  </Button>
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            ?.filter(item => item.isNew)
            .map((row, i) => {
              return addRow(row, i);
            })}
          {getSortableRows()?.map((row, i) => {
            return addRow(row, i);
          })}
        </TableBody>
      </Table>
    </>
  );
}

CreateTable.defaultProps = {
  rows: [],
};

export default CreateTable;
