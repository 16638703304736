import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Swal from 'sweetalert2';

import api from 'services/api';

import FreteEstadoModel from '../../../models/ConfiguradorFreteEstado';
import EstadoModel from '../../../models/Estado';

const ConfiguradorFreteEstado: React.FC = () => {
  const [estados, setEstados] = useState([] as EstadoModel[]);
  const [dadosEstados, setDadosEstados] = useState([] as FreteEstadoModel[]);
  // eslint-disable-next-line prettier/prettier
  const [estadosEditing, setEstadosEditing] = useState({} as EstadoModel);
  const [dadosEstadosEditing, setDadosEstadosEditing] = useState(
    {} as FreteEstadoModel,
  );

  const [formModalState, setFormModalState] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);
  const [exibeRespostas, setExibeRespostas] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  async function listaEstados() {
    const response = await api.get('/integracao/estado');

    setEstados(response.data);
  }

  async function listaDadosFrete() {
    if (estadosEditing.codigo) {
      const response = await api.get(
        `/integracao/estado-frete/${estadosEditing.codigo}`,
      );

      setDadosEstados(response.data);
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaEstados();
  }, []);

  useEffect(() => {
    if (estadosEditing.codigo) {
      listaDadosFrete();
    }
  }, [estadosEditing.codigo]);

  async function handleDelete(itemFreteId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/integracao/estado-frete/${itemFreteId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaEstados();
        listaDadosFrete();
      }
    });
  }

  async function handleInsert() {
    setDadosEstadosEditing({} as FreteEstadoModel);
    setEstadosEditing({} as EstadoModel);
    setIsEditing(false);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemFrete: FreteEstadoModel) {
    setDadosEstadosEditing(itemFrete);
    setIsEditing(true);

    setFormModalState(!formModalState);
  }

  async function handleSaveFreteEstado() {
    // eslint-disable-next-line prettier/prettier
    const { percentualFrete, estdCodigo } = dadosEstadosEditing;

    if (!percentualFrete || !estdCodigo) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (dadosEstadosEditing.estdCodigo) {
      await api.put(
        `/integracao/estado-frete/${dadosEstadosEditing.estdCodigo}`,
        {
          percentualFrete: dadosEstadosEditing.percentualFrete,
          pluviometria01: dadosEstadosEditing.pluviometria01,
          pluviometria02: dadosEstadosEditing.pluviometria02,
          pluviometria03: dadosEstadosEditing.pluviometria03,
          pluviometria04: dadosEstadosEditing.pluviometria04,
          pluviometria05: dadosEstadosEditing.pluviometria05,
          pluviometria06: dadosEstadosEditing.pluviometria06,
          pluviometria07: dadosEstadosEditing.pluviometria07,
          pluviometria08: dadosEstadosEditing.pluviometria08,
          pluviometria09: dadosEstadosEditing.pluviometria09,
          pluviometria10: dadosEstadosEditing.pluviometria10,
          pluviometria11: dadosEstadosEditing.pluviometria11,
          pluviometria12: dadosEstadosEditing.pluviometria12,
        },
      );
    } else {
      await api.post(`/integracao/estado-frete/`, {
        estdCodigo: dadosEstadosEditing.estdCodigo,
        percentualFrete: dadosEstadosEditing.percentualFrete,
        pluviometria01: dadosEstadosEditing.pluviometria01,
        pluviometria02: dadosEstadosEditing.pluviometria02,
        pluviometria03: dadosEstadosEditing.pluviometria03,
        pluviometria04: dadosEstadosEditing.pluviometria04,
        pluviometria05: dadosEstadosEditing.pluviometria05,
        pluviometria06: dadosEstadosEditing.pluviometria06,
        pluviometria07: dadosEstadosEditing.pluviometria07,
        pluviometria08: dadosEstadosEditing.pluviometria08,
        pluviometria09: dadosEstadosEditing.pluviometria09,
        pluviometria10: dadosEstadosEditing.pluviometria10,
        pluviometria11: dadosEstadosEditing.pluviometria11,
        pluviometria12: dadosEstadosEditing.pluviometria12,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setEstadosEditing({} as EstadoModel);
    listaEstados();
    listaDadosFrete();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{dadosEstadosEditing.descricao}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Estado </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Estado"
                            type="select"
                            value={estadosEditing.codigo || ''}
                            disabled={isEditing}
                            onChange={text => {
                              setEstadosEditing({
                                ...estadosEditing,
                                codigo: Number(text.target.value),
                              });
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                estdCodigo: text.target.value,
                              });
                            }}
                          >
                            <option value="">Estado...</option>
                            {estados.map(estadoUF => (
                              <option value={estadoUF.codigo}>
                                {estadoUF.sigla}
                                {' - '}
                                {estadoUF.descricao}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Frete % *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Frete %"
                            value={Number(dadosEstadosEditing.percentualFrete)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                percentualFrete: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 01</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 01"
                            value={Number(dadosEstadosEditing.pluviometria01)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria01: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 02</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 02"
                            value={Number(dadosEstadosEditing.pluviometria02)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria02: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 03</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 03"
                            value={Number(dadosEstadosEditing.pluviometria03)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria03: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 04</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 04"
                            value={Number(dadosEstadosEditing.pluviometria04)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria04: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 05</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 05"
                            value={Number(dadosEstadosEditing.pluviometria05)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria05: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 06</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 06"
                            value={Number(dadosEstadosEditing.pluviometria06)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria06: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 07</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 07"
                            value={Number(dadosEstadosEditing.pluviometria07)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria07: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 08</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 08"
                            value={Number(dadosEstadosEditing.pluviometria08)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria08: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 09</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 09"
                            value={Number(dadosEstadosEditing.pluviometria09)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria09: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 10</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 10"
                            value={Number(dadosEstadosEditing.pluviometria10)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria10: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 11</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 11"
                            value={Number(dadosEstadosEditing.pluviometria11)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria11: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pluviometria 12</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pluviometria 12"
                            value={Number(dadosEstadosEditing.pluviometria12)}
                            type="number"
                            onChange={text =>
                              setDadosEstadosEditing({
                                ...dadosEstadosEditing,
                                pluviometria12: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveFreteEstado}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Frete x Estado</h3>
              </CardHeader>
              <Col sm="6 py-3">
                <FormControl className="w-100" variant="outlined">
                  <InputLabel className="mt--2">Estado</InputLabel>
                  <Select
                    label="Estado"
                    value={estadosEditing.codigo || ''}
                    onChange={text => {
                      setEstadosEditing({
                        ...estadosEditing,
                        codigo: Number(text.target.value),
                      });
                      listaDadosFrete();
                      setExibeRespostas(true);
                    }}
                  >
                    <MenuItem value={undefined}>Selecione...</MenuItem>
                    {estados.map(itemEstado => {
                      return (
                        <MenuItem value={Number(itemEstado.codigo)}>
                          {itemEstado.sigla} - {itemEstado.descricao ?? ''}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Card>
            {exibeRespostas ? (
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  {dadosEstados.map(itemFrete => (
                    <>
                      <thead className="thead-light">
                        <tr key={itemFrete.estdCodigo}>
                          <th
                            rowSpan={2}
                            style={{
                              backgroundColor: '#1E90FF',
                              color: '#FFFFFF',
                            }}
                          >
                            <strong>{itemFrete.descricao}</strong>
                          </th>
                          <th>Jan</th>
                          <th>Fev</th>
                          <th>Mar</th>
                          <th>Abr</th>
                          <th>Mai</th>
                          <th>Jun</th>
                          <th>Jul</th>
                          <th>Ago</th>
                          <th>Set</th>
                          <th>Out</th>
                          <th>Nov</th>
                          <th>Dez</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={itemFrete.estdCodigo}>
                          <td className="py-2">
                            Frete: {itemFrete.percentualFrete}%
                          </td>
                          <td className="py-2">{itemFrete.pluviometria01}</td>
                          <td className="py-2">{itemFrete.pluviometria02}</td>
                          <td className="py-2">{itemFrete.pluviometria03}</td>
                          <td className="py-2">{itemFrete.pluviometria04}</td>
                          <td className="py-2">{itemFrete.pluviometria05}</td>
                          <td className="py-2">{itemFrete.pluviometria06}</td>
                          <td className="py-2">{itemFrete.pluviometria07}</td>
                          <td className="py-2">{itemFrete.pluviometria08}</td>
                          <td className="py-2">{itemFrete.pluviometria09}</td>
                          <td className="py-2">{itemFrete.pluviometria10}</td>
                          <td className="py-2">{itemFrete.pluviometria11}</td>
                          <td className="py-2">{itemFrete.pluviometria12}</td>
                          <td className="py-2">
                            <div className="float-right">
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="warning"
                                type="button"
                                onClick={() => {
                                  handleUpdate(itemFrete);
                                }}
                              >
                                <FaEdit />
                              </Button>
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="danger"
                                type="button"
                                onClick={() => {
                                  handleDelete(Number(itemFrete.estdCodigo));
                                }}
                              >
                                <FaTimesCircle />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  ))}
                </Table>
              </Card>
            ) : null}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorFreteEstado;
