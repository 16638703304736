import styled from 'styled-components';
import { TextField as TextFieldC } from '@material-ui/core';

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1% 0.5%;
`;

interface TdProps {
  editavel?: boolean;
}

export const Td = styled.td<TdProps>`
  background-color: ${props => (props.editavel ? 'unset' : '#f1f1f1')};
  padding-top: 10px !important;
  padding-bottom: 0 !important;
`;

interface TrProps {
  alterado?: boolean;
}

export const Tr = styled.tr<TrProps>`
  border: ${props => (props.alterado ? '1.1px solid #a5d2a7' : 'none')};
`;

export const TextField = styled(TextFieldC).attrs({
  InputProps: {
    style: { fontSize: 12, marginTop: -8 },
    disableUnderline: true,
  },
})``;
