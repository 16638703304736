import GenericModal from 'components/GenericModal';
import FichaTecnica from 'models/FichaTecnica';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Button, Card, Col, Row } from 'reactstrap';
import api from 'services/api';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import { AdutoraBombeamentoContext } from '../..';

interface OrcamentoPontosMapaPontos {
  latitude?: number | undefined;
  longitude?: number | undefined;
  elevacao?: number | undefined;
  distancia?: number | undefined;
  ordem?: number | undefined;
  angulo?: number | undefined;
  curvasCalculadas?: string | undefined;
}

declare let window: any;

window.google.load('visualization', '1', {
  packages: ['columnchart', 'corechart', 'table'],
});

function Elevacao(): JSX.Element {
  const {
    setQuantidadeVentosa,
    setCurvasToSave,
    curvasToSave,
    setTotalCurvasEncontradas,
    orcamentoId,
    sessaoId,
  } = useContext(AdutoraBombeamentoContext);

  const [camposElevacao, setCamposElevacao] = useState<
    OrcamentoPontosMapaPontos[]
  >([]);

  const [camposVentosa, setCamposVentosa] = useState<
    OrcamentoPontosMapaPontos[]
  >([]);

  const [anguloCurvas, setAnguloCurvas] = useState<OrcamentoPontosMapaPontos[]>(
    [],
  );

  const [rowsModal, setRowsModal] = useState<string[]>([]);

  const [modalStatusVisivel, setModalStatusVisivel] = useState(false);

  const [showElevacaoChart, setShowElevacaoChart] = useState(false);

  async function carregarCamposElevacao() {
    const response = await api.get(
      `/orcamento/pontos-mapa?orcamentoId=${orcamentoId}&tipo=elevacao_adutora`,
    );

    setShowElevacaoChart(response.data.length > 0);
    setCamposElevacao(response.data);
  }

  async function carregarCamposVentosa() {
    const response = await api.get(
      `/orcamento/pontos-mapa?orcamentoId=${orcamentoId}&tipo=pontos_ventosa`,
    );

    setQuantidadeVentosa(response.data.length ?? 0);
    setCamposVentosa(response.data);
  }

  async function carregarAngulosCurvas() {
    const response = await api.get(
      `/orcamento/pontos-mapa?orcamentoId=${orcamentoId}&tipo=angulos_curvas`,
    );

    const curvasSave: string[] = [];

    const curvas = response.data;

    curvas.map((item: any) => curvasSave.push(item.curvasCalculadas));

    setTotalCurvasEncontradas(curvas.length || 0);
    setCurvasToSave(curvasSave.join(','));
    setAnguloCurvas(response.data);
  }

  async function initChart() {
    if (window.google && camposElevacao && camposVentosa) {
      const chartDiv = document.getElementById('elevation_chart');

      const chart = new window.google.visualization.ComboChart(chartDiv);

      const data = new window.google.visualization.DataTable();

      data.addColumn('string', 'Sample');
      data.addColumn('number', 'Elevação');
      data.addColumn('number', 'Ventosa');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < camposElevacao.length; i++) {
        data.addRow([
          `${camposElevacao[i].distancia}`,
          camposElevacao[i].elevacao,
          camposVentosa?.filter(
            item => item.ordem === camposElevacao[i].ordem,
          )?.[0]?.elevacao,
        ]);
      }

      // Draw the chart using the data within its DIV.
      chart.draw(data, {
        height: 450,
        legend: 'none',
        titleY: 'Elevação (m)',
        titleX: 'Distância do Início (m)',
        seriesType: 'area',
        series: {
          1: {
            type: 'scatter',
            color: 'red',
            fontSize: 25,
          },
        },
      });
    }
  }

  useEffect(() => {
    carregarCamposElevacao();
    carregarCamposVentosa();
    carregarAngulosCurvas();
  }, []);

  useEffect(() => {
    initChart();
  }, [camposElevacao, camposVentosa]);

  async function handleOpenModalCurvas() {
    const rows: any[] = [];

    if (curvasToSave) {
      const curvas: string[] = curvasToSave.replaceAll('°', '').split(',');

      const quantidadeCurvas5 = curvas.filter(item => item === '5').length || 0;
      const quantidadeCurvas10 =
        curvas.filter(item => item === '10').length || 0;
      const quantidadeCurvas15 =
        curvas.filter(item => item === '15').length || 0;
      const quantidadeCurvas30 =
        curvas.filter(item => item === '30').length || 0;
      const quantidadeCurvas45 =
        curvas.filter(item => item === '45').length || 0;
      const quantidadeCurvas60 =
        curvas.filter(item => item === '60').length || 0;
      const quantidadeCurvas90 =
        curvas.filter(item => item === '90').length || 0;

      rows.push(`Quantidade 5°: ${quantidadeCurvas5}`);
      rows.push(`Quantidade 10°: ${quantidadeCurvas10}`);
      rows.push(`Quantidade 15°: ${quantidadeCurvas15}`);
      rows.push(`Quantidade 30°: ${quantidadeCurvas30}`);
      rows.push(`Quantidade 45°: ${quantidadeCurvas45}`);
      rows.push(`Quantidade 60°: ${quantidadeCurvas60}`);
      rows.push(`Quantidade 90°: ${quantidadeCurvas90}`);

      setRowsModal(rows);
      setModalStatusVisivel(true);
    }
  }

  return (
    <>
      <GenericModal
        title="Totalizador Curvas Localizadas"
        rows={rowsModal}
        modalState={modalStatusVisivel}
        toggle={() => setModalStatusVisivel(false)}
      />
      <Card className="shadow">
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>Elevação Adutora</LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>

        <Row hidden={showElevacaoChart}>
          <Col className="mt-2 pb-4">
            Não existem informações da elevação da adutora, extraídas do arquivo
            KML
          </Col>
        </Row>

        <Row hidden={!showElevacaoChart}>
          <Col sm="12">
            <div id="elevation_chart" style={{ height: 470 }} />
          </Col>

          <Col sm="12" hidden={!anguloCurvas.length}>
            <strong>Curvas do Trecho</strong>
            <Button
              size="sm"
              onClick={handleOpenModalCurvas}
              color="info"
              title="Visualizar total das curvas"
              outline
              className="float-right"
            >
              Total Curvas
            </Button>
            <Table className="align-items-center" responsive>
              <thead style={{ background: '#C2C2C2' }}>
                <tr>
                  <th>nº</th>
                  <th>Ângulo das Curvas</th>
                  <th>Ângulos Calculados</th>
                </tr>
              </thead>
              {anguloCurvas?.map(
                (item: OrcamentoPontosMapaPontos, index: number) => {
                  return (
                    <tr key={item.ordem}>
                      <td>{item.ordem}</td>
                      <td>{item.angulo}°</td>
                      <td>{item?.curvasCalculadas?.split(',').join(' + ')}</td>
                    </tr>
                  );
                },
              )}
            </Table>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Elevacao;
