import { Row, Card, CardBody, Col } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';

import FichaTecnica from 'models/FichaTecnica';

import {
  Label,
  Valor,
} from 'views/pages/OrcamentoResultado/components/atoms/Campo/styles';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import { Container } from './styles';
import { ColLabel } from '../DadosInformadosEletrica/style';

interface Props {
  fichaTecnica: FichaTecnica;
}

function CaboAlimentadorDoPivo(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const {
    caboAlimentadorPivo,
    distanciaAlimentador,
    correnteMaximaCaboAlimentadorPivo,
    quedaTensaoV1v,
    quedaTensaoV1p,
    metodoInstalacaoCaboAlimentadoPivo,
  } = fichaTecnica;

  const campos = [
    {
      label: 'Distância',
      valor: distanciaAlimentador,
      unidade: 'km',
    },
    {
      label: 'Corrente máxima do cabo',
      valor: correnteMaximaCaboAlimentadorPivo,
      unidade: 'A',
    },
    {
      label: 'Queda Tensão ΔV1 v',
      valor: quedaTensaoV1v,
      unidade: 'V',
    },
    {
      label: 'Queda Tensão Δ1 %',
      valor: quedaTensaoV1p,
      unidade: '%',
    },
    {
      label: 'Método de instalação',
      valor: metodoInstalacaoCaboAlimentadoPivo,
    },
  ];

  return (
    <Container>
      <Card className="shadow mt-2">
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>
                  Cabo Alimentador do Pivô
                </LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody className="p-3">
              <Row>
                {/* <Campo sm="12" label="Cabo Alimentador" valor={caboAlimentadorPivo} /> */}
                <Col sm="12">
                  <Row>
                    <ColLabel>
                      <Label>Cabo Alimentador: </Label>
                    </ColLabel>
                    <Col sm="10" style={{ maxWidth: '80.5%' }}>
                      <Valor readOnly value={caboAlimentadorPivo} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                {campos.map(campo => (
                  <Campo
                    sm="4"
                    key={campo.label}
                    label={campo.label}
                    valor={campo.valor ?? ''}
                    unidade={campo.unidade}
                  />
                ))}
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Container>
  );
}

export default CaboAlimentadorDoPivo;
