import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from 'services/api';

interface FotovoltaicaTipoCarga {
  id: number;
  sigla: string;
  descricao: string;
  incluirSomatorio: boolean;
  indicaMediaTotal: boolean;
  desativadoNoGrupo: string;
}

const CadastroTiposUcsConsumo: React.FC = () => {
  const [tiposCarga, setTiposCarga] = useState([] as FotovoltaicaTipoCarga[]);
  // eslint-disable-next-line prettier/prettier
  const [tiposCargaEditing, setTiposCargaEditing] = useState({} as FotovoltaicaTipoCarga);

  const [formModalState, setFormModalState] = useState(false);

  async function listaTiposCarga() {
    const response = await api.get('/fotovoltaica-tipo-carga');

    setTiposCarga(response.data);
  }

  useEffect(() => {
    listaTiposCarga();
  }, []);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/fotovoltaica-tipo-carga/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setTiposCarga(tiposCarga.filter(item => item.id !== row.id));
        }
      });
      listaTiposCarga();
    } else {
      setTiposCarga(tiposCarga.filter(item => item.id !== row.id));
    }
  }

  async function handleInsert() {
    setTiposCargaEditing({} as FotovoltaicaTipoCarga);
    setFormModalState(!formModalState);
  }

  function handleUpdate(TiposCarga: FotovoltaicaTipoCarga) {
    setTiposCargaEditing(TiposCarga);
    setFormModalState(!formModalState);
  }

  async function handleSaveTiposCarga() {
    // eslint-disable-next-line prettier/prettier
    const { sigla } = tiposCargaEditing;

    if (!sigla) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (tiposCargaEditing.id) {
      await api.put(`/fotovoltaica-tipo-carga/${tiposCargaEditing.id}`, {
        sigla: tiposCargaEditing.sigla,
        descricao: tiposCargaEditing.descricao,
        incluirSomatorio: tiposCargaEditing.incluirSomatorio,
        indicaMediaTotal: tiposCargaEditing.indicaMediaTotal,
        desativadoNoGrupo: tiposCargaEditing.desativadoNoGrupo,
      });
    } else {
      await api.post(`/fotovoltaica-tipo-carga`, {
        sigla: tiposCargaEditing.sigla,
        descricao: tiposCargaEditing.descricao,
        incluirSomatorio: tiposCargaEditing.incluirSomatorio,
        indicaMediaTotal: tiposCargaEditing.indicaMediaTotal,
        desativadoNoGrupo: tiposCargaEditing.desativadoNoGrupo,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setTiposCargaEditing({} as FotovoltaicaTipoCarga);
    listaTiposCarga();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/*
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        */}

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Tipos de consumo de UC</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Sigla*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Sigla*"
                            value={tiposCargaEditing.sigla}
                            type="text"
                            onChange={text =>
                              setTiposCargaEditing({
                                ...tiposCargaEditing,
                                sigla: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Descrição </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={tiposCargaEditing.descricao}
                            type="text"
                            onChange={text =>
                              setTiposCargaEditing({
                                ...tiposCargaEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Incluir Somatório</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Incluir Somatório"
                            type="select"
                            value={
                              tiposCargaEditing.incluirSomatorio ? 'S' : 'N'
                            }
                            onChange={text =>
                              setTiposCargaEditing({
                                ...tiposCargaEditing,
                                incluirSomatorio: Boolean(
                                  text.target.value === 'S',
                                ),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Indica Média Total</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Indica Média Total"
                            type="select"
                            value={
                              tiposCargaEditing.indicaMediaTotal ? 'S' : 'N'
                            }
                            onChange={text =>
                              setTiposCargaEditing({
                                ...tiposCargaEditing,
                                indicaMediaTotal: Boolean(
                                  text.target.value === 'S',
                                ),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Ativado no grupo</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Ativado no grupo"
                            type="select"
                            value={tiposCargaEditing.desativadoNoGrupo}
                            onChange={text =>
                              setTiposCargaEditing({
                                ...tiposCargaEditing,
                                desativadoNoGrupo: text.target.value,
                              })
                            }
                          >
                            <option value="" selected>
                              Selecione
                            </option>
                            <option value="A" selected>
                              Grupo A
                            </option>
                            <option value="B">Grupo B</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveTiposCarga}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Tipos de Consumo de UC</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Sigla</th>
                    <th>Descrição</th>
                    <th>Incluir Somatório</th>
                    <th>Indica Média Total</th>
                    <th>Ativado no grupo</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {tiposCarga.map(tipoCarga => (
                    <tr key={tipoCarga.id}>
                      <td className="py-1">{tipoCarga.sigla}</td>
                      <td className="py-1">{tipoCarga.descricao}</td>
                      <td className="py-1">
                        {tipoCarga.incluirSomatorio ? 'Sim' : 'Não'}
                      </td>
                      <td className="py-1">
                        {tipoCarga.indicaMediaTotal ? 'Sim' : 'Não'}
                      </td>
                      <td className="py-1">{tipoCarga.desativadoNoGrupo}</td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(tipoCarga);
                          }}
                        >
                          <FaEdit />
                        </Button>
                        {/*
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(tipoCarga);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                        */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroTiposUcsConsumo;
