import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaCheckCircle,
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from 'services/api';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface TermometriaFabricante {
  id: number;
  nome: string;
  descricao?: string;
  ativo: boolean;
}

const CadastroTermometriaFabricante: React.FC = () => {
  const [fabricante, setfabricante] = useState([] as TermometriaFabricante[]);
  // eslint-disable-next-line prettier/prettier

  const [mostrarDesativados, setMostrarDesativados] = useState(false);

  const [fabricanteEditing, setfabricanteEditing] = useState(
    {} as TermometriaFabricante,
  );

  const [formModalState, setFormModalState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  async function listaFabricante() {
    const response = await api.get('/termometria/fabricante');
    setfabricante(response.data);
  }

  useEffect(() => {
    listaFabricante();
  }, []);

  async function ativarDesativar(fabricanteId: number, ativar: boolean) {
    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      await api.delete(
        `/termometria/fabricante/${fabricanteId}?ativo=${ativar}`,
      );
      toast.success('Feito!', {});

      listaFabricante();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleAtivar(fabricanteId: number) {
    Swal.fire({
      title: `Deseja reativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(fabricanteId, true);
        Swal.close();
      }
    });
  }

  async function handleDelete(fabricanteId: number) {
    // Aqui preciso validar se pode ser desativado.
    // Se não puder, alerta o usuário
    // async function checkPodeDesativar(): Promise<boolean> {
    //   if (!fabricanteId) {
    //     return false;
    //   }

    //   const response = await api.get(
    //     `/termometria/fabricante/${fabricanteId}/validar-exclusao`,
    //   );

    //   const existeModelos = response.data.valido ?? false;

    //   return existeModelos;
    // }

    // const podeDesativar = await checkPodeDesativar();

    // if (podeDesativar) {
    Swal.fire({
      title: `Deseja desativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(fabricanteId, false);
        Swal.close();
      }
    });
    // } else {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Ops!',
    //     text: `Não é possível desativar o registro. Ele está sendo usado por questões. Primeiro desative essas questões antes de desativar a tabela`,
    //   });
    // }
  }

  async function handleInsert() {
    setfabricanteEditing({} as TermometriaFabricante);
    setFormModalState(!formModalState);
  }

  function handleUpdate(fabricanteEditar: TermometriaFabricante) {
    setfabricanteEditing(fabricanteEditar);
    setFormModalState(!formModalState);
  }

  async function handleSavefabricante() {
    // eslint-disable-next-line prettier/prettier
    const { nome } = fabricanteEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);

    if (fabricanteEditing.id) {
      await api.put(`/termometria/fabricante/${fabricanteEditing.id}`, {
        nome: fabricanteEditing.nome,
        descricao: fabricanteEditing.descricao,
      });
    } else {
      await api.post(`/termometria/fabricante`, {
        nome: fabricanteEditing.nome,
        descricao: fabricanteEditing.descricao,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setIsSaving(false);
    setfabricanteEditing({} as TermometriaFabricante);
    listaFabricante();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Fabricantes</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nome*"
                            value={fabricanteEditing.nome}
                            type="text"
                            onChange={text =>
                              setfabricanteEditing({
                                ...fabricanteEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Descrição </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={fabricanteEditing.descricao}
                            type="text"
                            onChange={text =>
                              setfabricanteEditing({
                                ...fabricanteEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSavefabricante}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">Lista de Fabricantes</h3>
                  </Col>
                  <Col sm="3">
                    <FormControlLabel
                      className="float-right"
                      control={
                        <Checkbox
                          checked={mostrarDesativados}
                          onChange={event =>
                            setMostrarDesativados(event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Mostrar desativados"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th hidden={!mostrarDesativados}>Ativo</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {fabricante
                    .filter(item =>
                      !mostrarDesativados ? item.ativo === true : true,
                    )
                    .map(item => (
                      <tr key={item.id}>
                        <td className="py-2">{item.nome}</td>
                        <td className="py-2">{item.descricao ?? ''}</td>
                        <td className="py-2" hidden={!mostrarDesativados}>
                          <div
                            className={`badge badge-${
                              item.ativo ? 'success' : 'danger'
                            }`}
                          >
                            {item.ativo ? 'Sim' : 'Não'}
                          </div>
                        </td>
                        <td>
                          <div className="float-right">
                            {item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="warning"
                                  type="button"
                                  disabled={isSaving}
                                  onClick={() => {
                                    handleUpdate(item);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="danger"
                                  type="button"
                                  disabled={isSaving}
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </>
                            )}
                            {!item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="info"
                                  type="button"
                                  disabled={isSaving}
                                  onClick={() => {
                                    handleAtivar(item.id);
                                  }}
                                >
                                  <FaCheckCircle />
                                </Button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroTermometriaFabricante;
