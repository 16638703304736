import { useContext, useEffect, useState } from 'react';

import {
  Card,
  Col,
  CardBody,
  Form,
  Row,
  Input,
  Button,
  Table,
  Alert,
} from 'reactstrap';

import { CircularProgress } from '@material-ui/core';
import api from 'services/api';
import { PropostaContext } from 'views/pages/Proposta';
import './style.css';
import ConfiguradorMatrizResponsabilidade from 'models/ConfiguradorMatrizResponsabilidade';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function MatrizResponsabilidade(): JSX.Element {
  const { propostaVersao, carregaPropostaVersao } = useContext(PropostaContext);

  const propostaVersaoId = propostaVersao.id;

  const statusId = propostaVersao.status.id;
  const statusPermiteModificar = propostaVersao.status.indicPermiteModificar;

  const [carregandoDados, setCarregandoDados] = useState(false);
  const [carregandoLista, setCarregandoLista] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingSelection, setIsSavingSelection] = useState(false);
  const [matrizEspelho, setMatrizEspelho] = useState<
    {
      sigla: string;
      valor: string;
      bloquear: boolean;
    }[]
  >([]);

  const [listaMatrizResponsabilidade, setListaMatrizResponsabilidade] =
    useState<ConfiguradorMatrizResponsabilidade[]>([]);

  const [listaRespostasResponsabilidade, setRespostasMatrizResponsabilidade] =
    useState<any[]>([]);

  const [
    listaRespostasDataResponsabilidade,
    setRespostasDataMatrizResponsabilidade,
  ] = useState<any[]>([]);

  const [listaTiposMatriz, setListaTiposMatriz] = useState(
    [] as { sigla: string; valor: string }[],
  );

  async function buscarTipoMatriz() {
    const response = await api.get('/matriz-responsabilidade/tipos');
    setListaTiposMatriz(response.data);
  }

  async function carregarListaMatriz() {
    setCarregandoLista(true);
    const response = await api.get('/matriz-responsabilidade');
    setListaMatrizResponsabilidade(
      response.data?.filter(
        (item: ConfiguradorMatrizResponsabilidade) => item.isAtivo === true,
      ),
    );
    setCarregandoLista(false);
  }

  async function carregarRespostas() {
    setCarregandoDados(true);
    const response = await api.get(
      `/proposta/versao/${propostaVersaoId}/matriz`,
    );
    const valor = response.data.respostas as any[];
    const variaveis = response.data.variaveis as any[];

    const matrizDefault = listaMatrizResponsabilidade.filter(
      item => item.valorDefault !== '' && item.isAtivo === true,
    );

    const matrizAtivo = listaMatrizResponsabilidade.filter(
      item => item.isAtivo === true,
    );

    const defaultAdicionar = matrizDefault.filter(
      (elem: any) => !valor.find((item: any) => item.id === elem.matrizId),
    );

    const salvar = valor.map((item: any) => {
      return { matrizId: item.matrizId, valor: item.valorDefault };
    });

    const salvarData = valor.map((item: any) => {
      return {
        matrizId: item.matrizId,
        dataPrevista: item.dataPrevista ? String(item.dataPrevista) : null,
      };
    });

    if (defaultAdicionar && defaultAdicionar.length > 0)
      salvar.push(
        ...defaultAdicionar.map((item: any) => {
          return { matrizId: item.id, valor: item.valorDefault };
        }),
      );

    const listaResposta: any[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const matriz of matrizAtivo) {
      // busca nas respostas
      const buscarRespostas = salvar.find(
        item2 => item2.matrizId === matriz.id,
      );

      const buscarVariaveis = variaveis.find(
        item2 => item2.sigla === matriz.espelharValorDe,
      );

      if (buscarVariaveis && buscarVariaveis?.valor) {
        listaResposta.push({
          matrizId: matriz.id,
          valor: buscarVariaveis?.valor,
        });
      } else if (buscarRespostas && buscarRespostas?.valor) {
        listaResposta.push({
          matrizId: matriz.id,
          valor: buscarRespostas?.valor,
        });
      }
    }

    setRespostasMatrizResponsabilidade(listaResposta);
    setRespostasDataMatrizResponsabilidade(salvarData);
    setMatrizEspelho(variaveis);
    setCarregandoDados(false);
    setCarregandoLista(false);
  }

  useEffect(() => {
    if (propostaVersao) {
      buscarTipoMatriz();
      carregarListaMatriz();
    }
  }, [propostaVersao]);

  useEffect(() => {
    if (propostaVersaoId && listaMatrizResponsabilidade.length > 0)
      carregarRespostas();
  }, [propostaVersaoId, listaMatrizResponsabilidade]);

  async function saveMatriz() {
    try {
      setIsSaving(true);

      await api.put(`/proposta/versao/${propostaVersaoId}/matriz`, {
        dados: listaRespostasResponsabilidade.map((item: any) => {
          const dataPrevista = listaRespostasDataResponsabilidade.find(
            item2 =>
              item2.matrizId === item.matrizId && item2.dataPrevista !== null,
          )?.dataPrevista;

          return {
            matrizId: item.matrizId,
            valor: item.valor,
            dataPrevista: dataPrevista ? new Date(dataPrevista) : null,
          };
        }),
      });
      toast.success('Informações da Matriz foram salvas');
    } finally {
      carregaPropostaVersao();
      setIsSaving(false);
    }
  }

  async function handleSaveMatriz() {
    if (
      listaRespostasResponsabilidade.length < listaMatrizResponsabilidade.length
    ) {
      Swal.fire({
        icon: 'error',
        text: `Um ou mais itens da matriz não tiveram sua responsabilidade definida. Corrija isso antes de prosseguir`,
      });
      return;
    }

    // verifica se status está com flag para não permitir modificar
    if (!statusPermiteModificar) {
      // Verifica se é um status que pode modificar
      if (statusId === 3 || statusId === 4 || statusId === 5) {
        // Dispara erro que não pode modificar.
        Swal.fire({
          icon: 'error',
          text: `Status atual não permite modificação`,
        });
      } else {
        // Dispara Swal avisando que só vai poder salvar as datas
        await saveMatriz();
      }
    } else {
      // proposta pode ser modificada normalmente
      await saveMatriz();
    }
  }

  async function atualizarValor(matrizId: number, valor: string) {
    setIsSavingSelection(true);
    setRespostasMatrizResponsabilidade([
      ...listaRespostasResponsabilidade.filter(
        item => item.matrizId !== matrizId,
      ),
      { matrizId, valor },
    ]);
    setIsSavingSelection(false);
  }

  async function atualizarData(matrizId: number, data?: string) {
    setIsSavingSelection(true);
    setRespostasDataMatrizResponsabilidade([
      ...listaRespostasDataResponsabilidade.filter(
        item => item.matrizId !== matrizId,
      ),
      { matrizId, dataPrevista: data ? String(data) : null },
    ]);
    setIsSavingSelection(false);
  }

  function getRowMatriz(matriz: ConfiguradorMatrizResponsabilidade) {
    if (!listaRespostasResponsabilidade) return <></>;

    const valorMatriz = matrizEspelho.find(
      item => item.sigla === matriz.espelharValorDe ?? '',
    );

    const dataPrev = listaRespostasDataResponsabilidade.find(
      val => val.matrizId === matriz.id,
    )?.dataPrevista;

    return (
      <tr key={`tr_item_${matriz.id}`}>
        <td>{matriz.descricao}</td>
        {listaTiposMatriz.map(item => {
          return (
            <td key={`td_${matriz.id}_${item.sigla}`}>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input "
                  id={`tr_rb_inline_${matriz.id}_${item.sigla}`}
                  name={`tr_rb_inline_${matriz.id}`}
                  type="radio"
                  disabled={isSavingSelection || valorMatriz?.bloquear === true}
                  checked={
                    item.sigla ===
                    listaRespostasResponsabilidade.find(
                      val => val.matrizId === matriz.id,
                    )?.valor
                  }
                  onChange={() => atualizarValor(matriz.id, item.sigla)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`tr_rb_inline_${matriz.id}_${item.sigla}`}
                >
                  &nbsp;
                </label>
              </div>
            </td>
          );
        })}
        <td>
          <Input
            type="date"
            disabled={isSavingSelection}
            value={dataPrev ? String(dataPrev).split('T')[0] : ''}
            onChange={event => atualizarData(matriz.id, event.target.value)}
          />
        </td>
      </tr>
    );
  }

  return (
    <Card className="bg-secondary shadow mt-1 mb-1">
      <CardBody>
        <Form>
          <Row className="mb-4">
            <Col sm="12" xs="12">
              <Alert color="warning">
                Caso alterações no bloco de Cálculo da Mão de Obra tenham sido
                feitas, será necessário salvar as mudanças aqui.
              </Alert>
            </Col>
          </Row>

          <Row className="ml-0">
            <h6 className="heading-small text-muted mb-1">
              Matriz de Responsabilidade{' '}
              {carregandoLista || carregandoDados ? (
                <CircularProgress color="inherit" size={15} />
              ) : null}
            </h6>
          </Row>
          <hr className="mt-2" />

          <Table className="tableMatriz">
            <thead>
              <tr>
                <th rowSpan={2}>Serviços</th>
                <th colSpan={listaTiposMatriz.length + 1}>
                  Responsabilidade / Fornecimento
                  {carregandoLista || carregandoDados ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                </th>
              </tr>
              <tr>
                {listaTiposMatriz.map(item => {
                  return <th key={`th_item_${item.sigla}`}>{item.valor}</th>;
                })}
                <th
                  style={{ width: '50px' }}
                  title=" Data prevista para entrega de dados definitivos e topografia"
                >
                  Data prevista <br />
                  para entrega
                </th>
              </tr>
            </thead>
            <tbody>
              {listaMatrizResponsabilidade
                .filter(item => item.isAtivo)
                .map((item: any) => {
                  return getRowMatriz(item);
                })}
            </tbody>
          </Table>

          <Row className="mt-3">
            <Col sm="9" />
            <Col sm="3">
              <Button
                className="float-right"
                color="default"
                disabled={isSaving}
                onClick={handleSaveMatriz}
              >
                {isSaving ? 'Salvando...' : 'Salvar'}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
