import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from 'services/api';

interface FotovoltaicaModulos {
  id: number;
  tipo: string;
  modelo?: string;
  pp?: number;
  area?: number;
  nPercentual?: number;
  perdaAnualPercentual?: number;
  ativo: boolean;
}

const CadastroFotovoltaicaModulos: React.FC = () => {
  const [fotovoltaicaModulos, setFotovoltaicaModulos] = useState(
    [] as FotovoltaicaModulos[],
  );
  // eslint-disable-next-line prettier/prettier
  const [fotovoltaicaModulosEditing, setFotovoltaicaModulosEditing] = useState({} as FotovoltaicaModulos);

  const [formModalState, setFormModalState] = useState(false);

  async function listaFotovoltaicaModulos() {
    const response = await api.get('/fotovoltaica-modulos');

    setFotovoltaicaModulos(response.data);
  }

  useEffect(() => {
    listaFotovoltaicaModulos();
  }, []);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/fotovoltaica-modulos/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setFotovoltaicaModulos(
            fotovoltaicaModulos.filter(item => item.id !== row.id),
          );
        }
      });
      listaFotovoltaicaModulos();
    } else {
      setFotovoltaicaModulos(
        fotovoltaicaModulos.filter(item => item.id !== row.id),
      );
    }
  }

  async function handleInsert() {
    setFotovoltaicaModulosEditing({} as FotovoltaicaModulos);
    setFormModalState(!formModalState);
  }

  function handleUpdate(fotovoltaicaModulo: FotovoltaicaModulos) {
    setFotovoltaicaModulosEditing(fotovoltaicaModulo);
    setFormModalState(!formModalState);
  }

  async function handleSaveFotovoltaicaModulos() {
    // eslint-disable-next-line prettier/prettier
    const { tipo } = fotovoltaicaModulosEditing;

    if (!tipo) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (fotovoltaicaModulosEditing.id) {
      await api.put(`/fotovoltaica-modulos/${fotovoltaicaModulosEditing.id}`, {
        tipo: fotovoltaicaModulosEditing.tipo,
        modelo: fotovoltaicaModulosEditing.modelo,
        pp: fotovoltaicaModulosEditing.pp,
        area: fotovoltaicaModulosEditing.area,
        nPercentual: fotovoltaicaModulosEditing.nPercentual,
        perdaAnualPercentual: fotovoltaicaModulosEditing.perdaAnualPercentual,
      });
    } else {
      await api.post(`/fotovoltaica-modulos`, {
        tipo: fotovoltaicaModulosEditing.tipo,
        modelo: fotovoltaicaModulosEditing.modelo,
        pp: fotovoltaicaModulosEditing.pp,
        area: fotovoltaicaModulosEditing.area,
        nPercentual: fotovoltaicaModulosEditing.nPercentual,
        perdaAnualPercentual: fotovoltaicaModulosEditing.perdaAnualPercentual,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setFotovoltaicaModulosEditing({} as FotovoltaicaModulos);
    listaFotovoltaicaModulos();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Módulos</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Tipo*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo*"
                            value={fotovoltaicaModulosEditing.tipo}
                            type="text"
                            onChange={text =>
                              setFotovoltaicaModulosEditing({
                                ...fotovoltaicaModulosEditing,
                                tipo: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Modelo </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Modelo"
                            value={fotovoltaicaModulosEditing.modelo}
                            type="text"
                            onChange={text =>
                              setFotovoltaicaModulosEditing({
                                ...fotovoltaicaModulosEditing,
                                modelo: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Pp (WP)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pp (WP)"
                            value={fotovoltaicaModulosEditing.pp}
                            type="number"
                            onChange={text =>
                              setFotovoltaicaModulosEditing({
                                ...fotovoltaicaModulosEditing,
                                pp: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Area (m²)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Area (m²)"
                            value={fotovoltaicaModulosEditing.area}
                            type="number"
                            onChange={text =>
                              setFotovoltaicaModulosEditing({
                                ...fotovoltaicaModulosEditing,
                                area: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <span>N (%)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="N (%)"
                            value={fotovoltaicaModulosEditing.nPercentual}
                            type="number"
                            onChange={text =>
                              setFotovoltaicaModulosEditing({
                                ...fotovoltaicaModulosEditing,
                                nPercentual: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <span>Perda Anual (%)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Perda Anual (%)"
                            value={
                              fotovoltaicaModulosEditing.perdaAnualPercentual
                            }
                            type="number"
                            onChange={text =>
                              setFotovoltaicaModulosEditing({
                                ...fotovoltaicaModulosEditing,
                                perdaAnualPercentual: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveFotovoltaicaModulos}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Cadastro de Módulos</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Tipo</th>
                    <th>Modelo</th>
                    <th>Pp (WP)</th>
                    <th>Area (m²)</th>
                    <th>N (%)</th>
                    <th>Perda Anual (%)</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {fotovoltaicaModulos.map(fotovoltaicaModulo => (
                    <tr key={fotovoltaicaModulo.id}>
                      <td className="py-1">{fotovoltaicaModulo.tipo}</td>
                      <td className="py-1">{fotovoltaicaModulo.modelo}</td>
                      <td className="py-1">{fotovoltaicaModulo.pp}</td>
                      <td className="py-1">{fotovoltaicaModulo.area}</td>
                      <td className="py-1">{fotovoltaicaModulo.nPercentual}</td>
                      <td className="py-1">
                        {fotovoltaicaModulo.perdaAnualPercentual}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(fotovoltaicaModulo);
                          }}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(fotovoltaicaModulo);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroFotovoltaicaModulos;
