import React, { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaPen, FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import api from 'services/api';
import TipoSuccaoModel from '../../../models/ConfiguradorTipoSuccao';
import ModeloSuccaoModel from '../../../models/ConfiguradorModeloSuccao';

type LocationState = {
  stateModelo?: ModeloSuccaoModel;
};

const ConfiguradorTipoSuccao: React.FC = () => {
  const location = useLocation<LocationState>();
  const { stateModelo } = location.state;
  const codOrigem = stateModelo?.id;
  const [tiposSuccao, setTiposSuccao] = useState([] as TipoSuccaoModel[]);
  const [tipoTubo, setTipoTubo] = useState('');
  const [idModeloSuccao, setIdModeloSuccao] = useState(0);

  const [tipoSuccaoEditing, setTipoSuccaoEditing] = useState(
    {} as TipoSuccaoModel,
  );

  const [notificationModalState, setNotificationModalState] = useState(false);
  const [formModalState, setFormModalState] = useState(false);

  async function listaTipoSuccao() {
    if (stateModelo?.id) {
      const response = await api.get(
        `/integracao/succao/tipo-succao/${stateModelo?.id}`,
      );

      setTiposSuccao(response.data);
      setIdModeloSuccao(Number(stateModelo?.id));
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaTipoSuccao();
  }, []);

  async function handleDelete(tuboId: number) {
    Swal.fire({
      title: `Deseja excluir o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/integracao/succao/tipo-succao/${tuboId}`);

        listaTipoSuccao();
        Swal.close();
      }
    });
  }

  async function handleInsert() {
    setTipoSuccaoEditing({} as TipoSuccaoModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(tubo: TipoSuccaoModel) {
    setTipoSuccaoEditing(tubo);
    setFormModalState(!formModalState);
  }

  async function handleSaveTubo() {
    // eslint-disable-next-line prettier/prettier
    const { id,
      tipoSuccao,
      dn,
      fatorK,
      ptsSuccao,
      addDuplo,
      comprTe, } = tipoSuccaoEditing;

    if (!tipoSuccao) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (tipoSuccaoEditing.id) {
      await api.put(`/integracao/succao/tipo-succao/${tipoSuccaoEditing.id}`, {
        seqSuccao: idModeloSuccao,
        tipoSuccao,
        dn,
        fatorK,
        ptsSuccao,
        addDuplo,
        comprTe,
      });
    } else {
      await api.post(`/integracao/succao/tipo-succao`, {
        seqSuccao: idModeloSuccao,
        tipoSuccao,
        dn,
        fatorK,
        ptsSuccao,
        addDuplo,
        comprTe,
      });
    }

    setFormModalState(false);
    setTipoSuccaoEditing({} as TipoSuccaoModel);
    listaTipoSuccao();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal alerta */}
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={notificationModalState}
              toggle={() => setNotificationModalState(!notificationModalState)}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  {/* Your attention is required */}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">
                    Não é possível remover o tubo!
                  </h4>
                  <p>
                    Esse tubo possui {tiposSuccao.length} lance(s). Remova-o(s)
                    para prosseguir com a exclusão.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Entendi
                </Button> */}
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  Fechar
                </Button>
              </div>
            </Modal>

            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Modelo {tipoSuccaoEditing.tipoSuccao}</small>
                    </div>
                    <Form role="form">
                      <small>Add duplo</small>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Add duplo"
                            value={tipoSuccaoEditing.addDuplo}
                            type="number"
                            onChange={text =>
                              setTipoSuccaoEditing({
                                ...tipoSuccaoEditing,
                                addDuplo: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Tipo Sucção</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo Sucção"
                            type="select"
                            value={tipoSuccaoEditing.tipoSuccao}
                            onChange={text =>
                              setTipoSuccaoEditing({
                                ...tipoSuccaoEditing,
                                tipoSuccao: text.target.value,
                              })
                            }
                          >
                            <option value="" selected>
                              Selecione...
                            </option>
                            <option value="S">Simples</option>
                            <option value="D">Duplo</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Dn</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Dn"
                            value={tipoSuccaoEditing.dn}
                            type="number"
                            onChange={text =>
                              setTipoSuccaoEditing({
                                ...tipoSuccaoEditing,
                                dn: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Fator k</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Fator k"
                            value={tipoSuccaoEditing.fatorK}
                            type="number"
                            onChange={text =>
                              setTipoSuccaoEditing({
                                ...tipoSuccaoEditing,
                                fatorK: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Pts Sucção</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Pts Sucção"
                            value={tipoSuccaoEditing.ptsSuccao}
                            type="number"
                            onChange={text =>
                              setTipoSuccaoEditing({
                                ...tipoSuccaoEditing,
                                ptsSuccao: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Compr Te</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Compr Te"
                            value={tipoSuccaoEditing.comprTe}
                            type="number"
                            onChange={text =>
                              setTipoSuccaoEditing({
                                ...tipoSuccaoEditing,
                                comprTe: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveTubo}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{stateModelo?.nome}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th>Tipo Sucção</th>
                    <th>Dn</th>
                    <th>Fator k</th>
                    <th>Pts Sucção</th>
                    <th>Add duplo</th>
                    <th>Compr Te</th>
                    <th>{}</th>
                  </tr>
                </thead>
                <tbody>
                  {tiposSuccao.map(tubo => (
                    <tr key={tubo.id}>
                      <td className="col">
                        <strong>
                          {tubo.tipoSuccao === 'S' ? 'Simples' : 'Duplo'}
                        </strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.dn}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.fatorK}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.ptsSuccao}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.addDuplo}</strong>
                      </td>
                      <td className="col">
                        <strong>{tubo.comprTe}</strong>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(tubo);
                          }}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(tubo.id);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorTipoSuccao;
