import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

// reactstrap components
import { Card, CardHeader, Container, Row, Button, Modal } from 'reactstrap';
// core components

import Header from 'components/Headers/Header.js';

import CustomTable from 'components/CustomTable';

import Swal from 'sweetalert2';

import api from 'services/api';

import BalancoModel from '../../../models/ConfiguradorBalanco';

interface IrradiacaoMunicipio {
  id: number;
  codInterno?: number;
  municipio: string;
  ufSigla: string;
  anual?: number;
  irradJaneiro?: number;
  irradFevereiro?: number;
  irradMarco?: number;
  irradAbril?: number;
  irradMaio?: number;
  irradJunho?: number;
  irradJulho?: number;
  irradAgosto?: number;
  irradSetembro?: number;
  irradOutubro?: number;
  irradNovembro?: number;
  irradDezembro?: number;
}

const ConfiguradorirradiacaoMunicipio: React.FC = () => {
  const [irradiacaoMunicipio, setIrradiacaoMunicipio] = useState([] as any[]);
  const [notificationModalState, setNotificationModalState] = useState(false);

  const header: any[] = [
    {
      name: 'Cod. Interno',
      prop: 'codInterno',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Município',
      prop: 'municipio',
      type: 'TEXT',
      searchable: true,
      sortable: true,
      editable: true,
      required: true,
    },
    {
      name: 'UF',
      prop: 'ufSigla',
      type: 'TEXT',
      searchable: true,
      sortable: true,
      editable: true,
      required: true,
    },
    {
      name: 'Anual',
      prop: 'anual',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Jan',
      prop: 'irradJaneiro',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Fev',
      prop: 'irradFevereiro',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Mar',
      prop: 'irradMarco',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Abr',
      prop: 'irradAbril',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Mai',
      prop: 'irradMaio',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Jun',
      prop: 'irradJunho',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Jul',
      prop: 'irradJulho',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Ago',
      prop: 'irradAgosto',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Set',
      prop: 'irradSetembro',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Out',
      prop: 'irradOutubro',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Nov',
      prop: 'irradNovembro',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
    {
      name: 'Dez',
      prop: 'irradDezembro',
      type: 'NUMBER',
      searchable: true,
      sortable: false,
      editable: true,
      required: false,
    },
  ];

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/irradiacao-municipio/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setIrradiacaoMunicipio(
            irradiacaoMunicipio.filter(item => item.id !== row.id),
          );
        }
      });
    } else {
      setIrradiacaoMunicipio(
        irradiacaoMunicipio.filter(item => item.id !== row.id),
      );
    }
  }

  async function handleSave(row: any) {
    if (row.isNew) {
      const retorno = await api.post(`/irradiacao-municipio/`, {
        // eslint-disable-next-line prettier/prettier
        codInterno: row.columns.filter((col: any) => col.prop === 'codInterno')[0].newValue,
        municipio: row.columns.filter((col: any) => col.prop === 'municipio')[0]
          .newValue,
        ufSigla: row.columns.filter((col: any) => col.prop === 'ufSigla')[0]
          .newValue,
        anual: row.columns.filter((col: any) => col.prop === 'anual')[0]
          .newValue,
        irradJaneiro: row.columns.filter(
          (col: any) => col.prop === 'irradJaneiro',
        )[0].newValue,
        irradFevereiro: row.columns.filter(
          (col: any) => col.prop === 'irradFevereiro',
        )[0].newValue,
        irradMarco: row.columns.filter(
          (col: any) => col.prop === 'irradMarco',
        )[0].newValue,
        irradAbril: row.columns.filter(
          (col: any) => col.prop === 'irradAbril',
        )[0].newValue,
        irradMaio: row.columns.filter((col: any) => col.prop === 'irradMaio')[0]
          .newValue,
        irradJunho: row.columns.filter(
          (col: any) => col.prop === 'irradJunho',
        )[0].newValue,
        irradJulho: row.columns.filter(
          (col: any) => col.prop === 'irradJulho',
        )[0].newValue,
        irradAgosto: row.columns.filter(
          (col: any) => col.prop === 'irradAgosto',
        )[0].newValue,
        irradSetembro: row.columns.filter(
          (col: any) => col.prop === 'irradSetembro',
        )[0].newValue,
        irradOutubro: row.columns.filter(
          (col: any) => col.prop === 'irradOutubro',
        )[0].newValue,
        irradNovembro: row.columns.filter(
          (col: any) => col.prop === 'irradNovembro',
        )[0].newValue,
        irradDezembro: row.columns.filter(
          (col: any) => col.prop === 'irradDezembro',
        )[0].newValue,
      });

      const registroCriado = retorno.data;

      setIrradiacaoMunicipio(
        irradiacaoMunicipio.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.id = registroCriado.id;
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    } else {
      await api.put(`/irradiacao-municipio/${row.id}`, {
        // eslint-disable-next-line prettier/prettier
        codInterno: row.columns.filter((col: any) => col.prop === 'codInterno')[0].newValue,
        municipio: row.columns.filter((col: any) => col.prop === 'municipio')[0]
          .newValue,
        ufSigla: row.columns.filter((col: any) => col.prop === 'ufSigla')[0]
          .newValue,
        anual: row.columns.filter((col: any) => col.prop === 'anual')[0]
          .newValue,
        irradJaneiro: row.columns.filter(
          (col: any) => col.prop === 'irradJaneiro',
        )[0].newValue,
        irradFevereiro: row.columns.filter(
          (col: any) => col.prop === 'irradFevereiro',
        )[0].newValue,
        irradMarco: row.columns.filter(
          (col: any) => col.prop === 'irradMarco',
        )[0].newValue,
        irradAbril: row.columns.filter(
          (col: any) => col.prop === 'irradAbril',
        )[0].newValue,
        irradMaio: row.columns.filter((col: any) => col.prop === 'irradMaio')[0]
          .newValue,
        irradJunho: row.columns.filter(
          (col: any) => col.prop === 'irradJunho',
        )[0].newValue,
        irradJulho: row.columns.filter(
          (col: any) => col.prop === 'irradJulho',
        )[0].newValue,
        irradAgosto: row.columns.filter(
          (col: any) => col.prop === 'irradAgosto',
        )[0].newValue,
        irradSetembro: row.columns.filter(
          (col: any) => col.prop === 'irradSetembro',
        )[0].newValue,
        irradOutubro: row.columns.filter(
          (col: any) => col.prop === 'irradOutubro',
        )[0].newValue,
        irradNovembro: row.columns.filter(
          (col: any) => col.prop === 'irradNovembro',
        )[0].newValue,
        irradDezembro: row.columns.filter(
          (col: any) => col.prop === 'irradDezembro',
        )[0].newValue,
      });

      setIrradiacaoMunicipio(
        irradiacaoMunicipio.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    }
  }

  function handleStartEditing(row: any) {
    setIrradiacaoMunicipio(
      irradiacaoMunicipio.map(item => {
        if (item.id === row.id) {
          // eslint-disable-next-line no-param-reassign
          item.isEditing = true;
        }
        return item;
      }),
    );
  }

  function handleStopEditing(row: any) {
    if (row.isNew) {
      setIrradiacaoMunicipio(
        irradiacaoMunicipio.filter(item => item.id !== row.id),
      );
    } else {
      setIrradiacaoMunicipio(
        irradiacaoMunicipio.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
          }
          return item;
        }),
      );
    }
  }

  function handleAddRow() {
    const count = irradiacaoMunicipio.length + 1;

    setIrradiacaoMunicipio([
      {
        id: count,
        columns: [
          {
            prop: 'codInterno',
            value: '',
            newValue: '',
          },
          {
            prop: 'municipio',
            value: '',
            newValue: '',
          },
          {
            prop: 'ufSigla',
            value: '',
            newValue: '',
          },
          {
            prop: 'anual',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradJaneiro',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradFevereiro',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradMarco',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradAbril',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradMaio',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradJunho',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradJulho',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradAgosto',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradSetembro',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradOutubro',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradNovembro',
            value: '',
            newValue: '',
          },
          {
            prop: 'irradDezembro',
            value: '',
            newValue: '',
          },
        ],
        isNew: true,
        isEditing: true,
      },
      ...irradiacaoMunicipio,
    ]);
  }

  async function listairradiacaoMunicipio() {
    const response = await api.get('/irradiacao-municipio/');

    const irradiacao: IrradiacaoMunicipio[] = response.data;

    const rows: any[] = [];

    let count = 0;
    // eslint-disable-next-line array-callback-return
    irradiacao.map(item => {
      rows.push({
        id: item.id,
        columns: [
          {
            prop: 'codInterno',
            value: item.codInterno,
            newValue: item.codInterno,
          },
          {
            prop: 'municipio',
            value: item.municipio !== null ? item.municipio : '',
            newValue: item.municipio !== null ? item.municipio : '',
          },
          {
            prop: 'ufSigla',
            value: item.ufSigla !== null ? item.ufSigla : '',
            newValue: item.ufSigla !== null ? item.ufSigla : '',
          },
          {
            prop: 'anual',
            value: item.anual,
            newValue: item.anual,
          },
          {
            prop: 'irradJaneiro',
            value: item.irradJaneiro,
            newValue: item.irradJaneiro,
          },
          {
            prop: 'irradFevereiro',
            value: item.irradFevereiro,
            newValue: item.irradFevereiro,
          },
          {
            prop: 'irradMarco',
            value: item.irradMarco,
            newValue: item.irradMarco,
          },
          {
            prop: 'irradAbril',
            value: item.irradAbril,
            newValue: item.irradAbril,
          },
          {
            prop: 'irradMaio',
            value: item.irradMaio,
            newValue: item.irradMaio,
          },
          {
            prop: 'irradJunho',
            value: item.irradJunho,
            newValue: item.irradJunho,
          },
          {
            prop: 'irradJulho',
            value: item.irradJulho,
            newValue: item.irradJulho,
          },
          {
            prop: 'irradAgosto',
            value: item.irradAgosto,
            newValue: item.irradAgosto,
          },
          {
            prop: 'irradSetembro',
            value: item.irradSetembro,
            newValue: item.irradSetembro,
          },
          {
            prop: 'irradOutubro',
            value: item.irradOutubro,
            newValue: item.irradOutubro,
          },
          {
            prop: 'irradNovembro',
            value: item.irradNovembro,
            newValue: item.irradNovembro,
          },
          {
            prop: 'irradDezembro',
            value: item.irradDezembro,
            newValue: item.irradDezembro,
          },
        ],
        isNew: false,
        isEditing: false,
      });
      // eslint-disable-next-line no-plusplus
      count++;
    });
    setIrradiacaoMunicipio(rows);
  }

  useEffect(() => {
    listairradiacaoMunicipio();
  }, []);

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={notificationModalState}
              toggle={() => setNotificationModalState(!notificationModalState)}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  {/* Your attention is required */}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">
                    Não é possível criar novo registro
                  </h4>
                  <p>
                    Já existe um registro com essas configurações selecionado
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Entendi
                </Button> */}
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  Fechar
                </Button>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Irradiação por município</h3>
              </CardHeader>
              <div style={{ width: '100%', overflowX: 'scroll' }}>
                {/* CustomTable */}
                <CustomTable
                  enableSearchField
                  rows={irradiacaoMunicipio}
                  header={header}
                  onDelete={handleDelete}
                  onSave={handleSave}
                  onAddRow={handleAddRow}
                  onStartEditing={handleStartEditing}
                  onStopEditing={handleStopEditing}
                />
                {/* END CustomTable */}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorirradiacaoMunicipio;
