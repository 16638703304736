import styled from 'styled-components';
import { DropdownToggle, Row } from 'reactstrap';

interface PropsQuestao {
  tipo?: string;
}

interface PieChartAnguloProps {
  angulo: number;
}

export const DropdownSelect = styled(DropdownToggle)`
  width: 100%;
  text-align: start;
  font-size: 0.7rem;
`;

export const DropdownItemLabeL = styled.label`
  font-size: 0.7rem;
`;

export const RowBotaoSalvarRespostas = styled(Row)`
  justify-content: flex-end;
  margin: 0 3rem 1rem 0;
`;

export const LabelQuestao = styled.label<PropsQuestao>`
  font-size: 0.8125rem;
  font-weight: 600;
  color: #8898aa;
`;

export const ColQuestao = styled.div<PropsQuestao>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  vertical-align: middle;
  padding-top: 0.6rem;
  ${props => {
    if (props.tipo === 'IM') {
      return `justify-content: flex-start;
              margin-left: 1.1rem;
              `;
    }
    return '';
  }}
`;

export const ColGraficoAngulo = styled.div`
  padding: 0.6rem 0 0.6rem;
`;

export const LabelQuestaoTitulo = styled.label<PropsQuestao>`
  font-size: 0.8125rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
`;

export const ColQuestaoTitulo = styled.div<PropsQuestao>`
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 0.13;
  padding: 0 1.6rem 0 1.6rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  background-color: #001c53;
`;

export const PieChartAngulo = styled.div<PieChartAnguloProps>`
  background: radial-gradient(circle, transparent 66%, white 0),
    conic-gradient(
      #a1f291 0,
      #a1f291
        ${props => {
          return `${props.angulo}%`;
        }},
      #e0e0e0 0,
      #e0e0e0 100%
    );
  width: 400px;
  height: 25rem;
`;
