import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap';

import { FaPlus, FaTrash } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { MenuItem, Select, TextField } from '@material-ui/core';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import ConfiguradorLanceTubo from 'models/ConfiguradorLanceTubo';
import api from '../../../../../services/api';
import LanceModel from '../../../../../models/ConfiguradorLance';
import BalancoModel from '../../../../../models/ConfiguradorBalanco';
import TuboModel from '../../../../../models/ConfiguradorTubo';
import ConfiguradorLanceManual from '../../../../../models/ConfiguradorLanceManual';

import { CardBodyPai } from '../../styles';
import { ConfiguracaoEtapasContext } from '../..';

interface Props {
  onSave?: any;
  sessaoId?: string | null;
}

export default function OpcoesLancesManual(props: Props): JSX.Element {
  const { sessaoId, onSave } = props;

  const { orcamentoProduto } = useContext(ConfiguracaoEtapasContext);

  const [lances, setLances] = useState([] as LanceModel[]);
  const [lancesTubos, setLancesTubos] = useState([] as ConfiguradorLanceTubo[]);
  const [tubos, setTubos] = useState([] as TuboModel[]);
  const [balancos, setBalancos] = useState([] as BalancoModel[]);
  const [lancesManual, setLancesManual] = useState([] as any[]);
  const [isSaving, setIsSaving] = useState(false);
  const [counter, setCounter] = useState(0);
  const [balancoSelecionado, setBalancoSelecionado] = useState(0);

  async function listaTubos() {
    const response = await api.get(
      `/integracao/tubos?produtoId=${
        orcamentoProduto.orcamentoProduto?.[0]?.produtoId ?? 0
      }`,
    );
    const tubosRetorno = response.data as TuboModel[];
    setTubos(tubosRetorno);
  }

  async function listaLances() {
    const response = await api.get(
      `/integracao/lances?produtoId=${
        orcamentoProduto.orcamentoProduto?.[0]?.produtoId ?? 0
      }`,
    );
    const lancesRetorno = response.data as LanceModel[];
    setLances(lancesRetorno);
  }

  async function listaLancesTubos() {
    const response = await api.get('/integracao/lances/tubos');
    const lancesRetorno = response.data as ConfiguradorLanceTubo[];
    setLancesTubos(lancesRetorno);
  }

  async function listaBalancos() {
    const response = await api.get('/integracao/balancos/listar');
    const balancoRetorno = response.data as BalancoModel[];
    setBalancos(balancoRetorno);
  }

  useEffect(() => {
    listaLances();
    listaTubos();
    listaBalancos();
    listaLancesTubos();
  }, []);

  async function listarDadosManual() {
    const response = await api.get(
      `/integracao/lances-manual?sessao=${sessaoId}`,
    );

    const opcoes = response.data as ConfiguradorLanceManual[];

    const opcoesManuais = opcoes.filter(item => item.opcao === 0);

    const balancosSelecionar = opcoesManuais.filter(
      item =>
        item.seqBalancos && item.seqBalancos !== null && item.seqBalancos > 0,
    );
    let count = counter;

    const dataSalvar = opcoesManuais
      ?.filter(item => item.seqBalancos === null || item.seqBalancos === 0)
      .map((item: any) => {
        const larguraLance =
          lances.find(item2 => item2.id === item.seqLances)?.comprimento ?? 0;

        count += 1;
        return {
          id: count,
          qtdLances: item.qtdLances,
          seqTubos: item.seqTubos,
          seqLances: item.seqLances,
          larguraLance,
          total: arredondaDecimais(larguraLance * item.qtdLances, 2),
        };
      });

    setCounter(count);
    setLancesManual(dataSalvar);

    const balancoUsar =
      balancosSelecionar && balancosSelecionar.length > 0
        ? balancosSelecionar[0].seqBalancos ?? 0
        : 0;

    setBalancoSelecionado(balancoUsar);
  }

  async function handleDelete(rowId: number) {
    setLancesManual(lancesManual.filter(item => item.id !== rowId));
  }

  function handleAddRow() {
    if (!isSaving) {
      const count = counter + 1;

      setLancesManual([
        ...lancesManual,
        {
          id: count,
          seqTubos: null,
          seqLances: null,
          qtdLances: 0,
          larguraLance: null,
          total: null,
        },
      ]);

      setCounter(count);
    }
  }

  async function handleDispararCalculo() {
    if (!lancesManual.length) return;

    const camposVazios = lancesManual.filter(
      item => !item.seqTubos || !item.seqLances || item.qtdLances === 0,
    );

    if (camposVazios.length) {
      toast.error(`Todos os campos são de preenchimento obrigatório`);
      return;
    }

    if (!balancoSelecionado || balancoSelecionado === 0) {
      toast.error(`O balanço deve ser selecionado`);
      return;
    }

    // Gerar objeto para salvar (ordem vai ser index+1)
    const dataSend = lancesManual?.map((item: any, index: any) => {
      return {
        ordem: Number(index) + 1,
        qtdLances: item.qtdLances,
        seqTubos: item.seqTubos,
        seqLances: item.seqLances,
        sessao: sessaoId,
        seqBalancos: 0,
      };
    });

    setIsSaving(true);

    try {
      await api.delete(`/integracao/lances-manual/todos?sessao=${sessaoId}`);

      await api.post(`/integracao/lances-manual/lote`, [
        ...dataSend,
        {
          ordem: 999,
          qtdLances: 0,
          seqTubos: 0,
          seqLances: 0,
          sessao: sessaoId,
          seqBalancos: balancoSelecionado,
        },
      ]);
    } finally {
      setIsSaving(false);

      await listarDadosManual();

      if (onSave) props.onSave();
    }
  }

  useEffect(() => {
    if (isSaving) {
      toast.loading('Aguarde...');
      return;
    }

    toast.dismiss();
  }, [isSaving]);

  useEffect(() => {
    if (sessaoId && lances.length && tubos.length && balancos.length) {
      listarDadosManual();
    }
  }, [sessaoId, lances, tubos, balancos]);

  function saveTempValue(rowId: any, prop: any, e: any) {
    let rowsChange: any[] = lancesManual;

    rowsChange = rowsChange?.map((item: any) => {
      if (item.id === rowId) {
        // eslint-disable-next-line no-param-reassign
        if (prop === 'seqTubos') item.seqTubos = e.target.value;

        // eslint-disable-next-line no-param-reassign
        if (prop === 'seqLances') item.seqLances = e.target.value;

        // eslint-disable-next-line no-param-reassign
        if (prop === 'qtdLances') item.qtdLances = Number(e ?? 0);

        if (item.seqLances) {
          // eslint-disable-next-line no-param-reassign
          item.larguraLance = lances.find(
            item2 => item2.id === item.seqLances,
          )?.comprimento;
        }

        if (item.seqLances && item.qtdLances && item.larguraLance) {
          // eslint-disable-next-line no-param-reassign
          item.total = arredondaDecimais(
            Number(item.larguraLance ?? 0) * Number(item.qtdLances ?? 0),
            2,
          );
        } else {
          // eslint-disable-next-line no-param-reassign
          item.total = null;
        }
      }
      return item;
    });

    if (rowsChange) setLancesManual(rowsChange);
  }

  function addRow(row: any, i: any) {
    return (
      <tr key={`table_${i}`}>
        <td>
          <TextField
            value={row.qtdLances || 0}
            defaultValue={null}
            onChange={(e: any) =>
              saveTempValue(row.id, 'qtdLances', e.target.value)
            }
          />
        </td>
        <td>
          <Select
            value={row.seqTubos || ''}
            defaultValue={null}
            onChange={e => saveTempValue(row.id, 'seqTubos', e)}
          >
            {tubos?.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              );
            })}
          </Select>
        </td>
        <td>
          <Select
            value={row.seqLances || ''}
            defaultValue={null}
            onChange={e => saveTempValue(row.id, 'seqLances', e)}
          >
            {lances
              ?.filter(item =>
                lancesTubos.some(item2 => {
                  return (
                    item2.idLance === item.id && item2.idTubos === row.seqTubos
                  );
                }),
              )
              ?.map(item => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nome}
                  </MenuItem>
                );
              })}
          </Select>
        </td>
        <td>{row.larguraLance}</td>
        <td>{row.total}</td>
        <td>
          <div
            className="float-right"
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              className="btn-icon btn-2"
              size="sm"
              color="danger"
              type="button"
              onClick={() => handleDelete(row.id)}
            >
              <FaTrash />
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <Card className="shadow mt-3" style={{ width: '100%' }}>
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="12">
            <h6 className="heading-small text-muted">
              Cadastro de opção manual
              <Button
                disabled={isSaving}
                className="btn-icon btn-2 float-right"
                size="sm"
                color="primary"
                type="button"
                onClick={() => handleAddRow()}
              >
                <FaPlus />
              </Button>
            </h6>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>Quantidade Lance</th>
              <th>Tubo</th>
              <th>Lance</th>
              <th>Largura (m)</th>
              <th>Total (m)</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {lancesManual.map((row, i) => {
              return addRow(row, i);
            })}
            <tr>
              <td colSpan={4}>
                <strong className="float-right">
                  Comprimento Total Lances (m):
                </strong>
              </td>
              <td colSpan={2}>
                {lancesManual.reduce(
                  (total, item) => total + (item.total || 0),
                  0,
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <strong className="float-right">Balanço:</strong>
              </td>
              <td colSpan={2}>
                <Select
                  value={balancoSelecionado || ''}
                  defaultValue={null}
                  onChange={e => setBalancoSelecionado(Number(e.target.value))}
                >
                  {balancos?.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <strong className="float-right">
                  Comprimento Total com Balanço (m):
                </strong>
              </td>
              <td colSpan={2}>
                {Number(
                  balancos?.find(item => item.id === balancoSelecionado)
                    ?.comprimento ?? 0,
                ) +
                  lancesManual.reduce(
                    (total, item) => total + (item.total || 0),
                    0,
                  )}
              </td>
            </tr>
          </tbody>
        </Table>
        <Button
          disabled={!lancesManual.length || isSaving}
          size="sm"
          className="btn-icon btn-2 mt-3"
          color="primary"
          type="button"
          onClick={() => handleDispararCalculo()}
        >
          {isSaving ? `Salvando` : `Salvar e Calcular Lance Manual`}
        </Button>
      </CardBody>
    </Card>
  );
}
