import { useEffect, useState } from 'react';
import { Row, Col, Table } from 'reactstrap';
import api from 'services/api';
import { PropostaVersao } from 'models/PropostaVersao';
import FVCustoTotal_AtualFuturo from 'views/charts/FVCustoTotal_AtualFuturo';
import FVCustoTotal_AtualFuturo_Highcharts from 'views/charts/FVCustoTotal_AtualFuturo_Highcharts';
import FVCustoTotal_AtualFuturo_Apex from 'views/charts/FVCustoTotal_AtualFuturo_Apex';

interface Props {
  propostaVersaoId: number;
  propostaVersao?: PropostaVersao;
}

export default function FotovoltaicaGraficoCustoTotal(
  props: Props,
): JSX.Element {
  const { propostaVersaoId, propostaVersao } = props;

  const [fotovoltaicaViabilidade, setfotovoltaicaViabilidade] = useState(
    [] as any[],
  );
  const [isLoadingContent, setIsLoadingContent] = useState(false);

  async function getViabilidadeFotovoltaica() {
    if (
      propostaVersao &&
      (propostaVersao.PropostaConfiguracao?.length ?? 0) > 0
    ) {
      setIsLoadingContent(true);
      const orcamentoId =
        propostaVersao?.PropostaConfiguracao?.[0]?.orcamentoId;

      const response2 = await api.get(
        `/fotovoltaica/viabilidade-formatado?orcamentoId=${orcamentoId}`,
        {},
      );

      setfotovoltaicaViabilidade(response2.data);
      setIsLoadingContent(false);
    }
  }

  useEffect(() => {
    getViabilidadeFotovoltaica();
  }, []);

  return (
    <>
      {isLoadingContent ? (
        <></>
      ) : (
        <Row>
          <Col sm="12" className="mt-3 graficos-proposta2">
            {fotovoltaicaViabilidade.length > 0 && (
              // eslint-disable-next-line react/jsx-pascal-case
              // <FVCustoTotal_AtualFuturo valores={fotovoltaicaViabilidade} />
              // eslint-disable-next-line react/jsx-pascal-case
              // <FVCustoTotal_AtualFuturo_Highcharts valores={fotovoltaicaViabilidade}
              // eslint-disable-next-line react/jsx-pascal-case
              <FVCustoTotal_AtualFuturo_Apex
                valores={fotovoltaicaViabilidade}
                larguraTotal={false}
                alturaTotal
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
}
