import UserSmallHeader from 'components/Headers/UserSmallHeader';
import FichaTecnica from 'models/FichaTecnica';
import Orcamento from 'models/Orcamento';
import { ParametroCambagem } from 'models/ParametroCambagem';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import api from 'services/api';
import LancesBalanco from '../../components/organisms/DadosCalculados/components/LancesBalanco';
import Cabecalho from './components/Cabecalho';

import CambagemLances from './components/CambagemLances';

import { LabelOrcamento } from './styles';

interface LocationState {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
}

function Cambagem(): JSX.Element {
  const location = useLocation<LocationState>();
  const [imagem, setImagem] = useState<string>();
  const fichaTecnica = location.state?.fichaTecnica;
  const orcamento = location.state?.orcamento;
  const { orcamentoVersaoId, modeloPivo } = fichaTecnica;

  async function carregaImagem() {
    const response = await api.get('/parametro-cambagem');
    const parametros = response.data as ParametroCambagem[];
    const imagemParametro =
      parametros.find(
        parametro => parametro.questaoValorSelecao.descricao === modeloPivo,
      )?.imagemBase64 || '';
    setImagem(imagemParametro);
  }

  useEffect(() => {
    carregaImagem();
  }, []);

  return (
    <>
      <UserSmallHeader />
      <Container fluid>
        <Row className="mt-2 ml-1">
          <LabelOrcamento>
            Configuração {orcamentoVersaoId} - Tabela de cambagem para torres de
            acionamento
          </LabelOrcamento>
        </Row>

        <Card className="shadow mt-2">
          <CardBody className="p-1">
            <Card className="shadow">
              <Row>
                <Col className="mt-2 ml-2 mb-2 mr--2">
                  <Cabecalho
                    fichaTecnica={fichaTecnica}
                    orcamento={orcamento}
                  />
                </Col>
                <Col>
                  <LancesBalanco
                    fichaTecnica={fichaTecnica}
                    ocultaBalanco
                    ocultaTotal
                    tituloCustomizado="Composição do pivô"
                  />
                </Col>
              </Row>
            </Card>
            <Card className="shadow mt-1">
              <CambagemLances fichaTecnica={fichaTecnica} />
            </Card>
            {/* imagem && (
              <div className="mt-2">
                <img
                  src={`data:image/jpeg;base64,${imagem}`}
                  style={{
                    cursor: 'pointer',
                    height: '100%',
                    width: '100%',
                  }}
                  alt="Imagem modelo pivô"
                />
              </div>
            ) */}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Cambagem;
