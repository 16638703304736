import { useState, useEffect } from 'react';

import ModeloSuccaoModel from 'models/ConfiguradorModeloSuccao';

import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { FormControlLabel, IconButton, Switch } from '@material-ui/core';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import api from 'services/api';

import toast from 'react-hot-toast';
import { FaCheckCircle } from 'react-icons/fa';

import DefaultImage from 'assets/img/default.jpg';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ModalUploadImagem from 'components/ModalUploadImagem';
import Compress from 'compress.js';
import { GridDiv } from '../styles';

interface Props {
  visaoEmLinhaDefault?: boolean;
  readOnly?: boolean;
}

function QuestaoSelecaoImagemSuccao(props: Props): JSX.Element {
  const { visaoEmLinhaDefault = false, readOnly = false } = props;

  const [modeloSelecionado, setmodeloSelecionado] =
    useState<ModeloSuccaoModel>();
  const [exibirModalImagem, setExibirModalImagem] = useState(false);
  const [modeloSuccao, setModeloSuccao] = useState([] as ModeloSuccaoModel[]);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    checkedVisaoLinha: visaoEmLinhaDefault,
  });
  const { width } = useWindowDimensions();
  const numeroColunasImagens = Math.trunc(width / 300);
  const defaultIconColor = '#b7ada4';
  const selectedIconColor = '#1ab394';

  async function listaModeloSuccao() {
    const response = await api.get('/integracao/succao/modelo-succao');
    setModeloSuccao(response.data);
  }

  useEffect(() => {
    if (loading) {
      toast.loading('Aguarde...');
      return;
    }

    toast.dismiss();
  }, [loading]);

  useEffect(() => {
    listaModeloSuccao();
  }, []);

  function closeModalImagem() {
    setExibirModalImagem(!exibirModalImagem);
    setmodeloSelecionado(undefined);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  async function comprimeImagem(imagem: string | null): Promise<string> {
    if (!imagem) {
      return '';
    }
    const compress = new Compress();
    const file = Compress.convertBase64ToFile(imagem?.split('base64,')[1]);
    const fileCompressed = await compress.compress([file], {
      quality: 0.7,
      maxWidth: 230,
      maxHeight: 176,
      resize: true,
    });
    return fileCompressed[0].data;
  }

  async function salvarImagem(imagem: string | null) {
    if (!modeloSelecionado?.id) return;
    try {
      setLoading(true);
      const imagemComprimida = await comprimeImagem(imagem);
      await api.put(
        `/integracao/succao/modelo-succao/imagem/${modeloSelecionado.id}`,
        {
          imagem: imagemComprimida || null,
        },
      );
    } finally {
      setLoading(false);
      closeModalImagem();
    }
    listaModeloSuccao();
  }

  if (!modeloSuccao.length) {
    return <></>;
  }

  return (
    <>
      <ModalUploadImagem
        isOpen={exibirModalImagem}
        toggle={closeModalImagem}
        onSave={salvarImagem}
        imagem={modeloSelecionado?.imagemQuestionario}
        descricao={modeloSelecionado?.nome}
      />

      <Card className="bg-secondary shadow mt-3">
        {!readOnly && (
          <CardHeader className="bg-white border-0">
            <Row>
              <Col />

              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={state.checkedVisaoLinha}
                    onChange={handleChange}
                    name="checkedVisaoLinha"
                    color="primary"
                  />
                }
                label="Visão em linha"
                className="text-muted"
              />
            </Row>
          </CardHeader>
        )}
        <CardBody>
          <GridDiv>
            <ImageList
              cols={numeroColunasImagens}
              style={{
                flexWrap: state.checkedVisaoLinha ? 'nowrap' : 'wrap',
                transform: 'translateZ(0)',
              }}
            >
              {modeloSuccao.map(valor => {
                const labelImagem = `Informação sobre valor ${valor.nome}`;

                const actionButton = readOnly ? (
                  <IconButton
                    aria-label={labelImagem}
                    style={{
                      color:
                        modeloSelecionado?.id === valor.id
                          ? selectedIconColor
                          : defaultIconColor,
                    }}
                  >
                    <FaCheckCircle />
                  </IconButton>
                ) : (
                  <></>
                );

                return (
                  <ImageListItem
                    key={valor.id}
                    style={{
                      height: '180px',
                      width: '233.5px',
                    }}
                  >
                    <img
                      src={
                        valor.imagemQuestionario
                          ? `data:image/jpeg;base64,${valor.imagemQuestionario}`
                          : DefaultImage
                      }
                      style={{
                        cursor: 'pointer',
                        height: '100%',
                        width: '100%',
                      }}
                      alt={valor.nome}
                    />
                    <ImageListItemBar
                      title={<span>{valor.nome}</span>}
                      subtitle=""
                      actionIcon={actionButton}
                    />
                  </ImageListItem>
                );
              })}
            </ImageList>
          </GridDiv>
        </CardBody>
      </Card>
    </>
  );
}

export default QuestaoSelecaoImagemSuccao;
