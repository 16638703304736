import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';

import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import formatCurrency from 'utils/formatCurrency';
import { Dispatch } from 'react';
import { FaHistory } from 'react-icons/fa';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { Th, Td } from './styles';

interface ValoresPorConfiguracao {
  propostaConfiguracoes: PropostaConfiguracao[];
  carregandoPropostaConfiguracoes: boolean;
  formatoMaoDeObra: string;
  setModalCalculoLogVisivel: Dispatch<React.SetStateAction<boolean>>;
  configuracaoId: number;
  setConfiguracaoId: (value: number) => void;
  acessoLimitado: boolean;
  acessoLimitadoValorMaoObra: ValidarAcessoPrograma;
}

export default function ValoresPorConfiguracao({
  formatoMaoDeObra,
  propostaConfiguracoes,
  carregandoPropostaConfiguracoes,
  setModalCalculoLogVisivel,
  configuracaoId,
  setConfiguracaoId,
  acessoLimitado,
  acessoLimitadoValorMaoObra,
}: ValoresPorConfiguracao): JSX.Element {
  const valorTotal = propostaConfiguracoes.reduce(
    (total, item) => total + Number(item.valorTotalServico || 0),
    0,
  );

  let numeroColunas = 5;

  if (acessoLimitadoValorMaoObra.acessoLimitado) {
    numeroColunas = 3;
  }

  return (
    <Card className="shadow mt-1 mb-1" hidden={formatoMaoDeObra === 'P'}>
      <CardBody>
        <Row className="ml-0">
          <h6 className="heading-small text-muted mb-1">
            Valores por configuração
          </h6>
          {carregandoPropostaConfiguracoes && (
            <CircularProgress className="mt-2 ml-2" color="inherit" size={10} />
          )}
        </Row>
        <hr className="mt-2" />

        <Table responsive bordered>
          <thead>
            <tr>
              <Th>Configuração</Th>
              <Th>Equipamento</Th>
              <Th className="text-right">Tempo de montagem</Th>
              {!acessoLimitadoValorMaoObra.acessoLimitado && (
                <>
                  <Th className="text-right">Mão de obra</Th>
                  <Th className="text-right">Despesas</Th>
                  <Th className="text-right">Valor total</Th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {propostaConfiguracoes.map(propostaConfiguracao => {
              const {
                id,
                orcamentoId,
                fichaTecnica,
                valorMaoObra,
                valorDespesa,
                valorTotalServico,
                tempoMontagem,
              } = propostaConfiguracao;
              return (
                <tr key={id}>
                  <Td>
                    {orcamentoId}

                    <Button
                      size="sm"
                      className="btn-icon float-right"
                      color="default"
                      outline
                      type="button"
                      hidden={acessoLimitadoValorMaoObra.acessoLimitado}
                      onClick={() => {
                        setModalCalculoLogVisivel(true);
                        setConfiguracaoId(orcamentoId);
                      }}
                    >
                      <FaHistory />
                      <span className="btn-inner--text">Logs</span>
                    </Button>
                  </Td>
                  <Td>{fichaTecnica?.pivoCentral}</Td>
                  <Td className="text-right">{tempoMontagem}</Td>
                  {!acessoLimitadoValorMaoObra.acessoLimitado && (
                    <>
                      <Td className="text-right">
                        {formatCurrency(valorMaoObra)}
                      </Td>
                      <Td className="text-right">
                        {formatCurrency(valorDespesa)}
                      </Td>
                      <Td className="text-right">
                        {formatCurrency(valorTotalServico)}
                      </Td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
          {!acessoLimitadoValorMaoObra.acessoLimitado && (
            <tfoot>
              <tr>
                <Td colSpan={numeroColunas} className="text-right">
                  Total
                </Td>
                <Td className="text-right">{formatCurrency(valorTotal)}</Td>
              </tr>
            </tfoot>
          )}
        </Table>
      </CardBody>
    </Card>
  );
}
