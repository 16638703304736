import { ReactNode, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from 'reactstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import UserHeader from 'components/Headers/UserHeader';
import { useHistory, useLocation } from 'react-router-dom';

import api from 'services/api';
import { Avatar } from '@material-ui/core';
import getInitials from 'utils/getInitials';
import { deepPurple } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
import Perfil from 'models/Perfil';

import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import { Toast, ToastAsync } from 'components/Toast';
import UsuarioPerfil from 'models/UsuarioPerfil';
import Usuario from '../../../models/Usuario';

import { useAuth } from '../../../hooks/auth';

import { PerfilRow, ButtonsRightCol } from './styles';

const MIN_SCORE_PASS = 3;

const UsuarioDetalhes: ReactNode = () => {
  const { usuario }: { usuario: Usuario } = useAuth();

  // dados básicos
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [usernameDomSGE, setUsernameDomSGE] = useState('');
  const [apelido, setApelido] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [passScore, setPassScore] = useState(0);

  const existeUsuario = !!usuario?.nome;

  function setDadosUsuario(usuarioData: Usuario) {
    setNome(usuarioData.nome);
    setEmail(usuarioData.email);
    setUsername(usuarioData.username);
    setUsernameDomSGE(usuarioData.usernameDomSGE ?? '');
    setApelido(usuarioData.apelido ?? '');
  }

  async function carregaUsuario() {
    const usuarioLocation = usuario ?? null;

    if (!usuarioLocation) {
      return;
    }

    setDadosUsuario(usuarioLocation);

    const response = await api.get(`/usuario/${usuarioLocation.id}`);

    setDadosUsuario(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaUsuario();
  }, []);

  async function atualizarSenha() {
    if (!existeUsuario) return;

    if (!password || password !== passwordConfirm) {
      Swal.fire({
        icon: 'warning',
        text: 'A nova senha nos campos não conferem',
      });

      return;
    }
    if (!oldPassword) {
      Swal.fire({
        icon: 'warning',
        text: 'A senha atual precisa ser informada',
      });

      return;
    }

    if (passScore < MIN_SCORE_PASS) {
      Swal.fire({
        icon: 'warning',
        text: 'A senha informada é muito fraca',
      });
      return;
    }

    const passObject = {
      oldPassword,
      password,
    };

    Swal.fire({
      icon: 'info',
      title: 'Atualizando...',
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    await api.put(`/usuario/redefinir`, passObject);

    setOldPassword('');
    setPassword('');
    setPasswordConfirm('');
    setPassScore(0);

    Swal.close();
  }

  async function handleFileRead(event: any) {
    async function convertBase64(file: File) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = error => {
          reject(error);
        };
      });
    }

    const file = event.target.files[0];

    const base64 = await convertBase64(file);
  }
  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row>
          {existeUsuario && (
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#a">
                        {usuario?.imagem ? (
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={usuario.imagem}
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: deepPurple[800],
                              width: 180,
                              height: 180,
                              marginTop: '-3rem',
                              marginLeft: '-2.2rem',
                              marginBottom: '-7rem',
                            }}
                          >
                            <Label style={{ fontSize: '4rem' }}>
                              {getInitials(usuario.nome)}
                            </Label>
                          </Avatar>
                        )}
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5" />
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>{nome}</h3>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          <Col className="order-xl-1" xl={existeUsuario ? '8' : '12'}>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Meu Perfil</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Dados básicos
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-razao-social"
                          >
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-razao-social"
                            type="text"
                            value={nome}
                            disabled
                            onChange={text => setNome(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            E-mail
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            value={email}
                            disabled
                            onChange={text => setEmail(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            value={username}
                            disabled
                            onChange={text => setUsername(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-apelido"
                          >
                            Apelido
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-apelido"
                            value={apelido}
                            disabled
                            onChange={text => setApelido(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <Input
                          id="originalFileName"
                          type="file"
                          inputProps={{
                            accept: 'image/*',
                          }}
                          style={{ display: 'none' }}
                          required
                          label="Document"
                          name="originalFileName"
                          onChange={e => handleFileRead(e)}
                          variant="standard"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username DomSGE
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            value={usernameDomSGE}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Alteração de Senha
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >
                            Senha Atual
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-password"
                            type="password"
                            value={oldPassword}
                            onChange={text => setOldPassword(text.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >
                            Senha
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-password"
                            type="password"
                            value={password}
                            onChange={text => setPassword(text.target.value)}
                          />
                          <PasswordStrengthBar
                            password={password}
                            shortScoreWord="Senha muito curta"
                            scoreWords={[
                              'Muito fraca',
                              'Fraca',
                              'Mediana',
                              'Boa',
                              'Forte',
                            ]}
                            onChangeScore={score => setPassScore(Number(score))}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password-confirm"
                          >
                            Confirmar senha
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-password-confirm"
                            type="password"
                            value={passwordConfirm}
                            onChange={text =>
                              setPasswordConfirm(text.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <Row className="align-items-center">
                    <Col className="text-left" xs="8">
                      {/* {existeUsuario && (
                        <Button
                          color="danger"
                          onClick={questionaExclusaoUsuario}
                        >
                          Excluir
                        </Button>
                      )} */}
                    </Col>

                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={atualizarSenha}
                        disabled={!existeUsuario}
                      >
                        Atualizar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsuarioDetalhes;
