import FichaTecnica from 'models/FichaTecnica';
import FichaTecnicaFotovoltaicaGeral from 'models/FichaTecnicaFotovoltaicaGeral';
import { useEffect, useState } from 'react';

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Modal,
  Row,
  Table,
} from 'reactstrap';

import api from 'services/api';
import formatNumeros from 'utils/formatNumeros';

import formatCurrency from 'utils/formatCurrency';
import FVCustoTotal_AtualFuturo_Apex from 'views/charts/FVCustoTotal_AtualFuturo_Apex';
import FVFluxoCaixa_Apex from 'views/charts/FVFluxoCaixa_Apex';
import { TipoColunasTabela } from 'models/TipoColunaTabela';
import FVGeracaoConsumo from 'views/charts/FVGeracaoConsumo';
import FotovoltaicaTotalizador from 'models/FotovoltaicaTotalizador';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { FaEdit } from 'react-icons/fa';
import Orcamento from 'models/Orcamento';
import Campo from '../../atoms/Campo';
import { ColQuestaoTitulo, LabelQuestaoTitulo } from '../../../styles';
import FotovoltaicaFinalizacaoOrcamento from '../FotovoltaicaFinalizacaoOrcamento';

interface Props {
  fichaTecnica: FichaTecnica;
  orcamentoId: number;
  orcamento: Orcamento;
}

interface TotalizadorTipo {
  nome: string;
  acessoEspecial: boolean;
  mostrar: boolean;
}

interface iModel {
  instalacaoEquipamento?: string | null;
  areaAproximada?: number | null;
  custoEnergiaSemUsina?: number | null;
  custoTotalSemUsina?: number | null;
  custoTotalComUsina?: number | null;
  economiaMedia?: number | null;
  economiaUsina?: number | null;
  energiaAno?: number | null;
  fatorAutoConsumo?: number | null;
  fichaTecnicaId?: number | null;
  inflacao?: number | null;
  lcoe?: number | null;
  lucroCaixaTotal?: number | null;
  opex?: number | null;
  potenciaInversorAprox?: number | null;
  potenciaPicoSistema?: number | null;
  potenciaPonderadaGeral?: number | null;
  prazo?: number | null;
  primeiroAnoPositivo?: number | null;
  referenciaCalculada?: number | null;
  taxa?: number | null;
  tir?: number | null;
  vpl?: number | null;
  tipoFinanciamento?: string | null;
  tipoGd?: string | null;
  tipoModulo?: string | null;
  tipoPagamento?: string | null;
  modulo?: string | null;
  moduloOriginal?: string | null;
  valorSistemaOriginal?: number | null;
  valorSistema?: number | null;
  energiaAnoOriginal?: number | null;
  potenciaPicoSistemaOriginal?: number | null;
  potenciaInversorOriginal?: number | null;
  geracao?:
    | {
        consumoAbril?: number | null;
        consumoAgosto?: number | null;
        consumoDezembro?: number | null;
        consumoFevereiro?: number | null;
        consumoJaneiro?: number | null;
        consumoJulho?: number | null;
        consumoJunho?: number | null;
        consumoMaio?: number | null;
        consumoMarco?: number | null;
        consumoNovembro?: number | null;
        consumoOutubro?: number | null;
        consumoSetembro?: number | null;
        consumoTotal?: number | null;
      }
    | any;
}

function DadosInformadosFotovoltaica(props: Props): JSX.Element {
  const { fichaTecnica, orcamentoId, orcamento } = props;

  const [fotovoltaicaTotalizador, setFotovoltaicaTotalizador] = useState(
    [] as FotovoltaicaTotalizador[],
  );

  const [fotovoltaicaViabilidade, setFotovoltaicaViabilidade] = useState(
    [] as any[],
  );

  const [acessoPrograma, setAcessoPrograma] = useState(
    {} as ValidarAcessoPrograma,
  );

  const [acessoProgramaFinalizacao, setAcessoProgramaFinalizacao] = useState(
    {} as ValidarAcessoPrograma,
  );

  const [fotovoltaicaGeral, setFotovoltaicaGeral] = useState<iModel>({});

  const [formModalState, setFormModalState] = useState(false);

  async function getAcessoPrograma() {
    const response = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 63,
      },
    });
    setAcessoPrograma(response.data);

    const response2 = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 71,
      },
    });
    setAcessoProgramaFinalizacao(response2.data);
  }

  const [valorSistema, setValorSistema] = useState<number>(0);

  async function infoFotovoltaica() {
    const response = await api.get(
      `/fotovoltaica/valores-finalizar-orcamento?orcamentoId=${orcamentoId}`,
    );

    const valores = response.data as iModel;
    setFotovoltaicaGeral(valores);
  }

  async function getTotalFotovoltaica() {
    const response = await api.get(
      `/fotovoltaica-totalizador?fichaTecnicaId=${fichaTecnica?.id}`,
      {},
    );

    setFotovoltaicaTotalizador(response.data as FotovoltaicaTotalizador[]);
  }

  async function getViabilidadeFotovoltaica() {
    const response2 = await api.get(
      `/fotovoltaica/viabilidade-formatado?orcamentoId=${fichaTecnica?.orcamentoVersaoId}`,
      {},
    );

    setFotovoltaicaViabilidade(response2.data);
  }

  async function getValorSistemaFotovoltaico() {
    const response = await api.get(
      `/fotovoltaica/valor-sistema?orcamentoId=${fichaTecnica?.orcamentoVersaoId}`,
      {},
    );

    setValorSistema(Number(response.data ?? 0));
  }

  useEffect(() => {
    getAcessoPrograma();
    infoFotovoltaica();
    getViabilidadeFotovoltaica();
    getValorSistemaFotovoltaico();
    getTotalFotovoltaica();
  }, []);

  const campos = [
    {
      label: 'Potência Pico Sistema',
      valor: fotovoltaicaGeral?.potenciaPicoSistema || 0,
      unidade: 'kWp',
      mostrar: true,
    },
    {
      label: 'Energia Ano',
      valor: fotovoltaicaGeral?.energiaAno || 0,
      unidade: 'kWh/ano',
      mostrar: true,
    },
    {
      label: 'Valor Sistema',
      valor: formatCurrency(valorSistema ?? 0, 2),
      mostrar: true,
    },
    {
      label: 'Potência Inversor Aproximada',
      valor: fotovoltaicaGeral?.potenciaInversorAprox || 0,
      unidade: 'kW',
      mostrar: true,
    },
    {
      label: 'Área Aproximada',
      valor: fotovoltaicaGeral?.areaAproximada || 0,
      unidade: 'm²',
      mostrar: true,
    },
    {
      label: 'Ponderada Geral',
      valor: Number(fotovoltaicaGeral?.potenciaPonderadaGeral || 0).toFixed(4),
      mostrar: false,
    },
    {
      label: 'Fator Autoconsumo',
      valor: fotovoltaicaGeral?.fatorAutoConsumo || 0,
      mostrar: false,
    },
  ];

  const colunasTabela1: TipoColunasTabela[] = [
    {
      tipo: '',
      prompt: 'Ano',
      label: 'Ano',
      ocultar: false,
    },
    {
      tipo: 'T1_ENERGIA_CONSUMO',
      prompt: 'Energia Consumo',
      label: 'Consumo',
      ocultar: true,
    },
    {
      tipo: 'T1_ENERGIA_GERACAO',
      prompt: 'Energia Geração',
      label: 'Geração',
      ocultar: true,
    },
    {
      tipo: 'T1_ENERGIA_SALDO_ACUMULADO',
      prompt: 'Energia Saldo Acumulado',
      label: 'Saldo Acumulado',
      ocultar: true,
    },
    {
      tipo: 'T1_ENERGIA_RESIDUAL',
      prompt: 'Energia Residual',
      label: 'Residual',
      ocultar: true,
    },
    {
      tipo: 'T1_ENERGIA_INJECAO',
      prompt: 'Energia Injeção',
      label: 'Injeção',
      ocultar: true,
    },

    {
      tipo: 'T1_CUSTO_ENERGIA_SEM_USINA',
      prompt: 'Custo Energia Sem Usina',
      label: 'Custo S/U',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_DEMANDA_SEM_USINA',
      prompt: 'Custo Demanda Sem Usina',
      label: 'Demanda S/U',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_TOTAL_SEM_USINA',
      prompt: 'Custo Total Sem Usina',
      label: 'Custo Total S/U',
      ocultar: false,
    },
    {
      tipo: 'T1_PARCELAS_PGTO',
      prompt: 'Parcelas Pgto',
      label: 'Parcelas Pgto',
      ocultar: false,
    },
    {
      tipo: 'T1_ECONOMIA_USINA',
      prompt: 'Economia Usina',
      label: 'Economia Usina',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_ENERGIA_COM_USINA',
      prompt: 'Custo Energia Com Usina',
      label: 'Custo Energia C/U',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_ICMS_COM_USINA',
      prompt: 'Custo ICMS Sobre Crédito',
      label: 'ICMS',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_TUSD_SOBRE_CREDITO',
      prompt: 'Custo TUSD sobre crédito',
      label: 'TUSD',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_DEMANDA_COM_USINA',
      prompt: 'Custo Demanda Com Usina',
      label: 'Demanda C/U',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_TOTAL_COM_USINA',
      prompt: 'Custo Total Com Usina',
      label: 'Custo Total C/U',
      ocultar: false,
    },
  ];

  const totalizadores: TotalizadorTipo[] = [
    {
      nome: 'Consumo UC conexão',
      acessoEspecial: false,
      mostrar: false,
    },
    {
      nome: 'Autoconsumo UC conexão',
      acessoEspecial: false,
      mostrar: false,
    },
    {
      nome: 'Geração',
      acessoEspecial: false,
      mostrar: true,
    },
    {
      nome: 'Injeção',
      acessoEspecial: false,
      mostrar: false,
    },
    {
      nome: 'Excedente',
      acessoEspecial: false,
      mostrar: false,
    },
    {
      nome: 'Consumo residual',
      acessoEspecial: false,
      mostrar: false,
    },
    {
      nome: 'Consumo total cliente',
      acessoEspecial: false,
      mostrar: true,
    },
  ];

  async function openModalAprovacao() {
    setFormModalState(!formModalState);
  }

  async function closeModalAfterSave() {
    await infoFotovoltaica();
    await getViabilidadeFotovoltaica();
    await getValorSistemaFotovoltaico();
    await getTotalFotovoltaica();
    setFormModalState(!formModalState);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <FotovoltaicaFinalizacaoOrcamento
          configuracaoId={orcamentoId}
          onSave={closeModalAfterSave}
          fotovoltaicaGeral={fotovoltaicaGeral}
          orcamento={orcamento}
        />
      </Modal>

      <div
        hidden={!acessoProgramaFinalizacao.temAcesso}
        style={{ width: '100%' }}
      >
        <ButtonGroup className="float-right pt-2 mr-3" role="group">
          <Button
            className="btn-icon btn-2"
            color="primary"
            type="button"
            outline
            size="sm"
            onClick={() => {
              openModalAprovacao();
            }}
          >
            Finalizar Orçamento
          </Button>
        </ButtonGroup>
      </div>
      <Card
        className="shadow mt-2"
        style={{ width: '100%' }}
        hidden={!fotovoltaicaGeral}
      >
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>Resultados</LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody className="p-3">
              <Row>
                <Col sm="11">
                  <Row>
                    {campos
                      .filter(item => item.mostrar)
                      .map(campo => (
                        <Campo
                          key={campo.label}
                          label={campo.label}
                          valor={campo.valor}
                          unidade={campo?.unidade}
                        />
                      ))}
                  </Row>
                  <Row hidden={!acessoPrograma.temAcesso}>
                    {campos
                      .filter(item => !item.mostrar)
                      .map(campo => (
                        <Campo
                          key={campo.label}
                          label={campo.label}
                          valor={campo.valor}
                          unidade={campo?.unidade}
                        />
                      ))}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <Card className="shadow mt-2" style={{ width: '100%' }}>
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>GERAÇÃO x CONSUMO</LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody className="p-3">
              <Row>
                <Col sm="11">
                  <Table className="align-items-center" responsive>
                    <thead>
                      <tr>
                        <th>Tipo</th>
                        <th>Jan</th>
                        <th>Fev</th>
                        <th>Mar</th>
                        <th>Abr</th>
                        <th>Mai</th>
                        <th>Jun</th>
                        <th>Jul</th>
                        <th>Ago</th>
                        <th>Set</th>
                        <th>Out</th>
                        <th>Nov</th>
                        <th>Dez</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fotovoltaicaTotalizador
                        ?.filter(item =>
                          totalizadores.some(
                            it2 =>
                              it2.nome === item.tipo &&
                              ((!acessoPrograma.temAcesso && it2.mostrar) ||
                                acessoPrograma.temAcesso),
                          ),
                        )
                        ?.map(total => {
                          const {
                            id,

                            tipo,
                            consumoTotal,
                            consumoJaneiro,
                            consumoFevereiro,
                            consumoMarco,
                            consumoAbril,
                            consumoMaio,
                            consumoJunho,
                            consumoJulho,
                            consumoAgosto,
                            consumoSetembro,
                            consumoOutubro,
                            consumoNovembro,
                            consumoDezembro,
                          } = total;

                          return (
                            <tr
                              key={id}
                              style={{
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              <td className="pr-0">
                                {`${tipo} `}
                                <small>(kWh)</small>
                              </td>
                              <td>{formatNumeros(consumoJaneiro || 0)}</td>
                              <td>{formatNumeros(consumoFevereiro || 0)}</td>
                              <td>{formatNumeros(consumoMarco || 0)}</td>
                              <td>{formatNumeros(consumoAbril || 0)}</td>
                              <td>{formatNumeros(consumoMaio || 0)}</td>
                              <td>{formatNumeros(consumoJunho || 0)}</td>
                              <td>{formatNumeros(consumoJulho || 0)}</td>
                              <td>{formatNumeros(consumoAgosto || 0)}</td>
                              <td>{formatNumeros(consumoSetembro || 0)}</td>
                              <td>{formatNumeros(consumoOutubro || 0)}</td>
                              <td>{formatNumeros(consumoNovembro || 0)}</td>
                              <td>{formatNumeros(consumoDezembro || 0)}</td>
                              <td>{formatNumeros(consumoTotal || 0)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row>
                <Col sm="10" className="mt-3">
                  {fotovoltaicaViabilidade.length > 0 && (
                    // eslint-disable-next-line react/jsx-pascal-case
                    <FVGeracaoConsumo
                      valores={fotovoltaicaTotalizador?.filter(item =>
                        totalizadores.some(
                          it2 => it2.nome === item.tipo && it2.mostrar,
                        ),
                      )}
                      larguraTotal={false}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <Card className="shadow mt-2" style={{ width: '100%' }}>
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>Cálculo Econômico</LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody>
              <Row>
                <Col
                  sm="11"
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflowX: 'scroll',
                  }}
                >
                  <Table style={{ width: '95%' }}>
                    <thead>
                      <tr style={{ padding: '5px' }}>
                        {colunasTabela1
                          .filter(item => item.ocultar === false)
                          .map(item => {
                            return (
                              <th
                                style={{ padding: '5px', minWidth: '25px' }}
                                title={item.prompt}
                              >
                                {item.label}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {fotovoltaicaViabilidade.map((item: any) => {
                        const {
                          anoCorrente,
                          energiaConsumo,
                          energiaGeracao,
                          energiaSaldoAcumulado,
                          energiaResidual,
                          energiaInjecao,
                          custoEnergiaSemUsina,
                          custoDemandaSemUsina,
                          custoTotalSemUsina,
                          parcelasPgto,
                          economiaUsina,
                          custoEnergiaComUsina,
                          custoIcmsComUsina,
                          custoTusdSobreCredito,
                          custoDemandaComUsina,
                          custoTotalComUsina,
                        } = item;

                        const styleTd = { padding: '5px', minWidth: '25px' };

                        return (
                          <tr key={`tabela1_${anoCorrente}`} style={styleTd}>
                            <td style={styleTd}>{anoCorrente}</td>
                            <td style={styleTd} hidden>
                              {energiaConsumo}
                            </td>
                            <td style={styleTd} hidden>
                              {energiaGeracao}
                            </td>
                            <td style={styleTd} hidden>
                              {energiaSaldoAcumulado}
                            </td>
                            <td style={styleTd} hidden>
                              {energiaResidual}
                            </td>
                            <td style={styleTd} hidden>
                              {energiaInjecao}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoEnergiaSemUsina)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoDemandaSemUsina)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoTotalSemUsina)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(parcelasPgto)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(economiaUsina)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoEnergiaComUsina)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoIcmsComUsina)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoTusdSobreCredito)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoDemandaComUsina)}
                            </td>
                            <td style={styleTd}>
                              {formatCurrency(custoTotalComUsina)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="mt-3">
                  <div>
                    <Table className="align-items-center">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: 'bold' }}>Total</th>
                          <th style={{ fontWeight: 'bold' }}>Custo Atual</th>
                          <th style={{ fontWeight: 'bold' }}>Custo Futuro</th>
                          <th style={{ fontWeight: 'bold' }}>Lucro UFV</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>25 Anos</td>
                          <td style={{ fontWeight: 'bold' }}>
                            {formatCurrency(
                              fotovoltaicaViabilidade.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.custoTotalSemUsina,
                                0,
                              ),
                            )}
                          </td>
                          <td style={{ fontWeight: 'bold' }}>
                            {formatCurrency(
                              fotovoltaicaViabilidade.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.custoTotalComUsina,
                                0,
                              ),
                            )}
                          </td>
                          <td style={{ fontWeight: 'bold' }}>
                            {formatCurrency(
                              fotovoltaicaViabilidade.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.custoTotalSemUsina,
                                0,
                              ) -
                                fotovoltaicaViabilidade.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator +
                                    currentValue.custoTotalComUsina,
                                  0,
                                ),
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>

      <Card className="shadow mt-2" style={{ width: '100%' }}>
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>
                  Custo Total (Atual x Futuro)
                </LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody>
              <Row>
                <Col sm="10">
                  {fotovoltaicaViabilidade.length > 0 && (
                    // eslint-disable-next-line react/jsx-pascal-case
                    <FVCustoTotal_AtualFuturo_Apex
                      valores={fotovoltaicaViabilidade}
                      larguraTotal
                      alturaTotal={false}
                    />
                    // <FVCustoTotal_AtualFuturo  valores={fotovoltaicaViabilidade} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="mt-2">
                  <div>
                    <Table className="align-items-center">
                      <thead>
                        <tr>
                          <th style={{ fontWeight: 'bold' }}>LCOE</th>
                          <th style={{ fontWeight: 'bold' }}>Economia média</th>
                          <th style={{ fontWeight: 'bold' }}>
                            Caixa Total (Lucro)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>
                            {fotovoltaicaGeral?.lcoe
                              ? formatNumeros(fotovoltaicaGeral?.lcoe)
                              : ''}{' '}
                            R$/kWh
                          </td>
                          <td style={{ fontWeight: 'bold' }}>
                            {fotovoltaicaGeral?.economiaMedia
                              ? fotovoltaicaGeral?.economiaMedia
                              : ''}
                            %
                          </td>
                          <td style={{ fontWeight: 'bold' }}>
                            {fotovoltaicaGeral?.lucroCaixaTotal
                              ? formatCurrency(
                                  fotovoltaicaGeral?.lucroCaixaTotal ?? 0,
                                )
                              : ''}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>

      <Card className="shadow mt-2" style={{ width: '100%' }}>
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>Fluxo de Caixa</LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody className="p-3">
              <Row>
                <Col sm="4">
                  <Table className="align-items-center" responsive>
                    <thead>
                      <tr>
                        <th>Ano</th>
                        <th>Caixa Anual</th>
                        <th>Fluxo Caixa</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fotovoltaicaViabilidade.map((item: any) => {
                        const { anoCorrente, caixaAnual, fluxoCaixa } = item;

                        return (
                          <tr key={`tabela1_${anoCorrente}`}>
                            <td>{anoCorrente}</td>
                            <td>{formatCurrency(caixaAnual)}</td>
                            <td>{formatCurrency(fluxoCaixa)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                          Total 25 anos:
                        </td>
                        <td colSpan={2} style={{ fontWeight: 'bold' }}>
                          {formatCurrency(
                            fotovoltaicaViabilidade.reduce(
                              (accumulator, currentValue) =>
                                accumulator + currentValue.caixaAnual,
                              0,
                            ),
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
                <Col sm="6">
                  <Row>
                    <Col sm="12">
                      {fotovoltaicaViabilidade.length > 0 && (
                        // <FVFluxoCaixa valores={fotovoltaicaViabilidade} />
                        // eslint-disable-next-line react/jsx-pascal-case
                        <FVFluxoCaixa_Apex
                          valores={fotovoltaicaViabilidade}
                          larguraTotal
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="mt-1">
                      <div>
                        <Table className="align-items-center">
                          <thead>
                            <tr>
                              <th style={{ fontWeight: 'bold' }}>Payback</th>
                              <th style={{ fontWeight: 'bold' }}>VPL</th>
                              <th style={{ fontWeight: 'bold' }}>TIR</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ fontWeight: 'bold' }}>
                                {fotovoltaicaGeral?.primeiroAnoPositivo ?? ''}
                              </td>
                              <td style={{ fontWeight: 'bold' }}>
                                {fotovoltaicaGeral?.vpl
                                  ? formatCurrency(fotovoltaicaGeral?.vpl ?? 0)
                                  : ''}
                              </td>
                              <td style={{ fontWeight: 'bold' }}>
                                {fotovoltaicaGeral?.tir
                                  ? `${fotovoltaicaGeral?.tir}%`
                                  : '-'}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
}

export default DadosInformadosFotovoltaica;
