import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import api from 'services/api';

import LinhaCreditoModel from '../../../models/ConfiguradorLinhaCredito';

const ConfiguradorLinhaCredito: React.FC = () => {
  const [creditos, setCreditos] = useState([] as LinhaCreditoModel[]);
  // eslint-disable-next-line prettier/prettier
  const [creditosEditing, setCreditosEditing] = useState({} as LinhaCreditoModel);

  const [formModalState, setFormModalState] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);

  async function listaLinhasCredito() {
    const response = await api.get('/proposta-linha-credito');

    setCreditos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaLinhasCredito();
  }, []);

  async function handleDelete(creditoId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/proposta-linha-credito/${creditoId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaLinhasCredito();
      }
    });
  }
  async function handleInsert() {
    setCreditosEditing({} as LinhaCreditoModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemCredito: LinhaCreditoModel) {
    setCreditosEditing(itemCredito);

    setFormModalState(!formModalState);
  }

  async function handleSaveLinhaCredito() {
    // eslint-disable-next-line prettier/prettier
    const { nome, textoImpressaoLinha1, textoImpressaoLinha2 } = creditosEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (creditosEditing.id) {
      await api.put(`/proposta-linha-credito/${creditosEditing.id}`, {
        id: creditosEditing.id,
        nome: creditosEditing.nome,
        textoImpressaoLinha1: creditosEditing.textoImpressaoLinha1,
        textoImpressaoLinha2: creditosEditing.textoImpressaoLinha2,
      });
    } else {
      await api.post(`/proposta-linha-credito/`, {
        nome: creditosEditing.nome,
        textoImpressaoLinha1: creditosEditing.textoImpressaoLinha1,
        textoImpressaoLinha2: creditosEditing.textoImpressaoLinha2,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setCreditosEditing({} as LinhaCreditoModel);
    listaLinhasCredito();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{creditosEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nome"
                            value={creditosEditing.nome}
                            type="text"
                            onChange={text =>
                              setCreditosEditing({
                                ...creditosEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Título na Impressão da Proposta</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Título na Impressão da Proposta"
                            value={creditosEditing.textoImpressaoLinha1}
                            type="text"
                            onChange={text =>
                              setCreditosEditing({
                                ...creditosEditing,
                                textoImpressaoLinha1: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Subtítulo na Impressão da Proposta</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Subtítulo na Impressão da Proposta"
                            value={creditosEditing.textoImpressaoLinha2}
                            type="text"
                            onChange={text =>
                              setCreditosEditing({
                                ...creditosEditing,
                                textoImpressaoLinha2: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveLinhaCredito}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de créditos</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Título na Impressão da Proposta</th>
                    <th>Subtítulo na Impressão da Proposta</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {creditos.map(itemCredito => (
                    <tr key={itemCredito.id}>
                      <td className="py-2">{itemCredito.nome}</td>
                      <td className="py-2">
                        {itemCredito.textoImpressaoLinha1}
                      </td>
                      <td className="py-2">
                        {itemCredito.textoImpressaoLinha2}
                      </td>
                      <td className="py-2">
                        <div className="float-right">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="warning"
                            type="button"
                            onClick={() => {
                              handleUpdate(itemCredito);
                            }}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(itemCredito.id);
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorLinhaCredito;
