/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Button,
} from 'reactstrap';

import Header from 'components/Headers/Header.js';

import { Avatar } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import { FaPlusCircle } from 'react-icons/fa';
import Cliente from '../../models/Pessoa';

import getInitials from '../../utils/getInitials';

import api from '../../services/api';

const Clientes: React.FC = () => {
  const [clientes, setClientes] = useState([] as Cliente[]);

  const history = useHistory();

  async function carregaClientes() {
    const response = await api.get('/pessoa');

    setClientes(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaClientes();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="10">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: '/admin/cadastro-cliente',
                    });
                  }}
                >
                  <FaPlusCircle />
                  <span className="btn-inner--text">Novo</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Clientes</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  {clientes.map(cliente => (
                    <tr key={cliente.id}>
                      <th scope="row">
                        <Link
                          style={{ marginRight: '0.8rem', color: 'unset' }}
                          to={{
                            pathname: `/admin/cadastro-cliente`,
                            state: { cliente },
                          }}
                        >
                          <Media className="align-items-center">
                            <div className="avatar-group">
                              <Avatar
                                src={cliente?.imagem}
                                style={{ backgroundColor: deepOrange[500] }}
                              >
                                {getInitials(cliente.razaoSocial)}
                              </Avatar>
                            </div>

                            <span className="mb-0 ml-3 text-sm">
                              {cliente.razaoSocial}
                            </span>
                          </Media>
                        </Link>
                      </th>
                      <td>
                        <strong>{cliente.documento}</strong>
                      </td>
                      <td>
                        {cliente.tipoPessoa === 'J' ? 'Jurídica' : 'Física'}
                      </td>

                      <td className="text-right" />
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Clientes;
