import styled from 'styled-components';
import { Typography as TypographyMui } from '@material-ui/core';

export const Container = styled.div`
  padding-left: 4rem;
  padding-right: 4rem;
  background-color: #fff;
`;

export const Typography = styled(TypographyMui)`
  color: #000;
  text-align: center;
`;
