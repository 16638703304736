const TipoBombaData = [
  {
    tipo: 'Normal',
    valoresCombo: [1],
  },
  {
    tipo: 'Série',
    valoresCombo: [2],
  },
  {
    tipo: 'Paralelo',
    valoresCombo: [2, 4],
  },
];

export default TipoBombaData;
