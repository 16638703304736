const config = {
  class:"dark",
  description: "",
  type: "production"
}

const envVar = process.env.REACT_APP_ENV;

if(envVar === "develop"){
  config.class="light";
  config.description = " (Desenvolvimento) ";
  config.type = "develop";
}
else if(envVar === "qa"){
  config.class="light";
  config.description = " (QA) ";
  config.type = "qa";
}

export default config;
