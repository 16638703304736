import { useContext, useEffect, useState } from 'react';
import {
  CardHeader,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
} from 'reactstrap';
import api from 'services/api';
import { PropostaMaterialAvulso as IPropostaMaterialAvulso } from 'models/PropostaMaterialAvulso';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FaTrash } from 'react-icons/fa';
import { CircularProgress } from '@material-ui/core';
import toast from 'react-hot-toast';
import { PropostaContext } from 'views/pages/Proposta';
import { useQuery } from 'react-query';
import formatCurrency from 'utils/formatCurrency';
import { Th, Td, Tr, Descricao } from './styles';
import { MateriaisContext } from '../..';

interface props {
  materiaisImportados: any[];
}
export default function PropostaMaterialImportado({
  materiaisImportados,
}: props): JSX.Element {
  const { propostaVersao, setPrecosDesatualizados } =
    useContext(PropostaContext);

  const { triggerUpdateMaterial } = useContext(MateriaisContext);

  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [acessoLimitadoListaMateriais, setAcessoLimitadoListaMateriais] =
    useState(true);

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 60,
      },
    });

    setAcessoLimitadoListaMateriais(response.data);
  }

  useEffect(() => {
    if (isSaving) {
      toast.loading('Excluindo registro', { position: 'top-right' });
    } else {
      toast.dismiss();
    }
  }, [isSaving]);

  useEffect(() => {
    getAcessoLimitadoPrograma();
  }, []);

  useEffect(() => {
    if (propostaVersao.id) {
      //
    }
  }, [propostaVersao]);

  async function deletarImportacao(item: any) {
    const confirmacao = await Swal.fire({
      title: `Atenção`,
      html: `Ao prosseguir, você estará excluindo esse registro de importação da proposta. A operação não poderá ser revertida. Deseja continuar?`,
      icon: 'warning',
      confirmButtonText: `Sim, prosseguir`,
      cancelButtonText: `Não`,
      showCancelButton: true,
    });

    const resultado = confirmacao.isConfirmed;

    if (resultado) {
      setIsSaving(true);
      // Disparar exclusão
      await api.delete(
        `/proposta/versao/${propostaVersao.id}/material-importado/${item.id}`,
      );

      setIsSaving(false);
      toast.success('Registro excluído com sucesso');
      triggerUpdateMaterial();
    }
  }

  function Materiais(item: any[]): JSX.Element {
    if (!item.length) {
      return (
        <tr>
          <Td className="text-center" colSpan={5}>
            Nenhum registro encontrado
          </Td>
        </tr>
      );
    }

    return (
      <>
        {item.map((materialAvulso: any) => {
          const { id, valor, atividade, quantidade, valorTotal, material } =
            materialAvulso;

          return (
            <Tr semPreco={valor === 0}>
              <Td>{atividade}</Td>
              <Td>
                <Descricao>
                  {material.diteCodigo} - {material.descricao}
                </Descricao>
              </Td>
              <Td className="text-center">{quantidade}</Td>
              <Td className="text-right" hidden={acessoLimitadoListaMateriais}>
                {formatCurrency(valor)}
              </Td>
              <Td className="text-right" hidden={acessoLimitadoListaMateriais}>
                {formatCurrency(valorTotal)}
              </Td>
            </Tr>
          );
        })}
      </>
    );
  }

  function Atividade(item: any[]): JSX.Element {
    if (!item.length) {
      return (
        <tr>
          <Td className="text-center" colSpan={5}>
            Nenhum registro encontrado
          </Td>
        </tr>
      );
    }

    return (
      <>
        {item.map((materialAvulso: any) => (
          <>
            {Materiais(materialAvulso.materiais)}
            <Tr id={`total_${materialAvulso.atividade}`}>
              <Td colSpan={3} />
              <Td className="text-right" hidden={acessoLimitadoListaMateriais}>
                <h3>Total Atividade {materialAvulso.atividade}</h3>
              </Td>
              <Td className="text-right" hidden={acessoLimitadoListaMateriais}>
                <h3>{formatCurrency(materialAvulso.total ?? 0, 2)}</h3>
              </Td>
            </Tr>
          </>
        ))}
      </>
    );
  }
  function getTipoHeader(tipo: any) {
    if (String(tipo.tipoImportacao).toUpperCase() === 'OUTROS') {
      return tipo.finalidade ?? '';
    }
    if (String(tipo.tipoImportacao).toUpperCase() === 'BM') {
      return 'Bombeamento Mestre';
    }

    return tipo.tipo;
  }

  function Header(item: any): JSX.Element {
    return (
      <>
        <CardHeader>
          <Row>
            <Col sm="8">
              <h4 className="mb--1">
                {getTipoHeader(item)} | {item.titulo} |{' '}
                <span style={{ fontSize: '0.7em' }}>{item.data}</span>
              </h4>
              <small className="text-muted"> </small>
            </Col>
            <Col sm="4">
              <Button
                outline
                className="btn-icon btn-2 ml-1 float-right"
                color="danger"
                type="button"
                size="sm"
                disabled={isSaving}
                onClick={() => deletarImportacao(item)}
              >
                <FaTrash />
              </Button>
              <div className="float-right mr-2" style={{ fontSize: '0.7em' }}>
                <strong>Arquivo de importação:</strong>
                <br />
                {item.nomeArquivo}
              </div>
            </Col>
          </Row>
        </CardHeader>

        <Card>
          <CardBody className="p-1">
            {loading ? (
              <Row>
                <Col className="text-center mb-4 mt-4">
                  <CircularProgress />
                </Col>
              </Row>
            ) : (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <Th>Ativ</Th>
                    <Th>Item</Th>
                    <Th className="text-center">Quantidade</Th>
                    {!acessoLimitadoListaMateriais && (
                      <>
                        <Th className="text-right">Valor unitário</Th>
                        <Th className="text-right">Valor total</Th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>{Atividade(item.agrupado)}</tbody>
                <tfoot hidden={acessoLimitadoListaMateriais}>
                  <tr>
                    <td colSpan={3} />
                    <td className="text-right">
                      <h2>Total Materiais:</h2>{' '}
                    </td>
                    <td className="text-right">
                      <h2>{formatCurrency(item.valorTotal, 2)}</h2>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            )}
          </CardBody>
        </Card>
      </>
    );
  }

  return <>{materiaisImportados.map(item => Header(item))}</>;
}
