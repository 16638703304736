import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import FichaTecnica from 'models/FichaTecnica';
import { useContext, useEffect, useState } from 'react';
import {
  FaCheckCircle,
  FaInfoCircle,
  FaPlusCircle,
  FaTrash,
  FaExclamationTriangle,
  FaInfo,
  FaRainbow,
  FaBuffer,
} from 'react-icons/fa';
import { Button, Table, Col, Row, Card, CardBody } from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import FichaTecnicaNecessidadeReprocessamento from 'models/FichaTecnicaNecessidadeReprocessamento';
import GenericModal from 'components/GenericModal';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import { CalculoAdutora } from 'models/AdutoraCalculo';
import { ContainerButtons, Td, TextField, Tr } from './styles';
import './style.css';
import { AdutoraBombeamentoContext } from '../../../..';
import {
  getTrechosAdutora,
  ListaTrechosPn,
  getElevPonto,
  SegTrechos,
} from './trechos'; // ovidio

declare let window: any;

interface Props {
  fichaTecnica: FichaTecnica;
  fichaTecnicaAdutora: any; // ovidio
  tipoCota: string;
  orcamentoId: number;
  sessaoId: string;
  atualizarTrechos: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  handleCalcularAdutora?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  handleCalcularAdutoraTotal?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  setAtualizarTrechos?: any;
  exibeCotas: boolean;
  setExibeTotais?: any;
  setSaldoAdutora?: any;
  setNovoTrecho?: any;
  setExibeTrechos?: any;
  exibeTrechos: boolean;
}

interface AdutoraTubulacaoOrcamento extends CalculoAdutora {
  tubulacaoAdutoraId: number;
  orcamentoId: number;
  sessao: string;
  ordem: number;
  cotaInicial: number | null;
  cotaFinal: number | null;
  alterado?: boolean;
  novo?: boolean;
  velocidadeErro?: boolean;
}

interface AdutoraTubulacao {
  seq: number;
  nome: string;
  dn: number | null;
  de: number | null;
  e: number | null;
  di: number | null;
  coeficiente: number | null;
  pn: number | null;
  velocidadeMaximaAgua: number | null;
}

const VELOCIDADE_MAXIMA = 2.5;

function TubulacaoAdutora(props: Props): JSX.Element {
  const {
    criarPrimeiroTrecho,
    setCriarPrimeiroTrecho,
    fichaTecnicaTubulacao,
    parametros,
    setPodeCalcularAdutora,
    podeCalcularAdutora,
    fichaTecnicaId,
    necessidadeReprocessamento,
    setNecessidadeReprocessamento,
    setBloquearConclusaoPorReprocessamento,
    necessidadeReprocessamentoData,
    listaNecessidadeReprocessamento,
  } = useContext(AdutoraBombeamentoContext);

  const {
    fichaTecnica,
    fichaTecnicaAdutora, // ovidio
    tipoCota,
    orcamentoId,
    atualizarTrechos,
    setAtualizarTrechos,
    sessaoId,
    exibeCotas,
    setExibeTotais,
    setSaldoAdutora,
    setNovoTrecho,
    setExibeTrechos,
    exibeTrechos,
  } = props;
  const { comprimentoCasaMaquinaCentro } = fichaTecnica;
  const [adutoraTubulacaoTrechos, setAdutoraTubulacaoTrechos] = useState<any[]>(
    [],
  );

  const isNecessidadeReprocessamentoTrechos =
    necessidadeReprocessamento?.reprocessarTrechos || false;

  const isNecessidadeReprocessamentoAdutora =
    necessidadeReprocessamento?.reprocessarAdutora || false;

  const [tubos, setTubos] = useState<AdutoraTubulacao[]>([]);
  const [loading, setLoading] = useState(false);
  const [adicionando, setAdicionando] = useState(false);
  const [modalStatusVisivel, setModalStatusVisivel] = useState(false);
  const [modalTitulo, setModalTitulo] = useState('');
  const [rowsModal, setRowsModal] = useState<string[]>([]);
  const [habilitaBotao, setHabilitaBotao] = useState(false);

  const adutoraTotal = comprimentoCasaMaquinaCentro || 0;
  const [adutoraSaldo, setAdutoraSaldo] = useState(0);

  const indicCotaEspecifica =
    tipoCota.toUpperCase() === 'ESPECÍFICA' ||
    tipoCota.toUpperCase() === 'ESPECIFICA';

  const headers = [
    'Trecho',
    'Comprimento',
    'Diâmetro (mm)',
    'Material',
    'Coef.',
    'Vazão m³/h',
    'HF Total (mca)',
    'Veloc. (2,5 m/s)',
    indicCotaEspecifica ? 'Cota inicial' : 'hide',
    indicCotaEspecifica ? 'Cota específica' : 'hide',
    '',
  ];
  // ovidio
  const [listaTrechos, setListaTrechos] = useState<any>([]);
  const [listaTrechosPn, setListaTrechosPn] = useState<ListaTrechosPn[]>([]);
  const [segTrechos, setSegTrechos] = useState<SegTrechos[]>([]);
  const [ajustarCotaOrdem, setAjustarCotaOrdem] = useState(999);

  async function carregaTubos() {
    const response = await api.get('/integracao/adutora/tubos');
    setTubos(response.data);
  }

  async function carregaAdutoraTrechos() {
    const response = await api.get('/integracao/adutora/orcamento', {
      params: {
        sessao: sessaoId,
        fichaTecnicaId,
        orcamentoId,
      },
    });

    const data = response?.data?.map((item: any) => {
      return {
        tubulacaoAdutoraId: tubos.find(tubo => tubo.nome === item.material)
          ?.seq,
        velocidadeErro: item.velocidade > VELOCIDADE_MAXIMA,
        ...item,
      };
    });

    const comprimentoTotal =
      adutoraTotal -
      data.reduce(
        (total: any, item: any) => total + (item?.comprimento || 0),
        0,
      );

    setAdutoraSaldo(comprimentoTotal || 0);
    setSaldoAdutora(comprimentoTotal || 0); // Variável para controlar o botão de concluir etapa

    if (comprimentoTotal === 0 && !isNecessidadeReprocessamentoAdutora) {
      setPodeCalcularAdutora(true);
    } else {
      setPodeCalcularAdutora(false);
    }

    setAdutoraTubulacaoTrechos(data);
  }

  useEffect(() => {
    if (!exibeCotas) {
      carregaAdutoraTrechos();
      setAtualizarTrechos(false);
    }
  }, [exibeCotas]);

  useEffect(() => {
    // Atualizar componente
  }, [necessidadeReprocessamento]);

  useEffect(() => {
    if (adutoraSaldo > 0 || necessidadeReprocessamento) {
      setBloquearConclusaoPorReprocessamento(true);
    } else {
      setBloquearConclusaoPorReprocessamento(false);
    }
  }, [adutoraSaldo]);

  useEffect(() => {
    if (
      adutoraTotal &&
      adutoraTubulacaoTrechos &&
      adutoraTubulacaoTrechos.length
    ) {
      const comprimentoTotal =
        adutoraTotal -
        adutoraTubulacaoTrechos.reduce(
          (total: any, item: any) => total + (item?.comprimento || 0),
          0,
        );

      setAdutoraSaldo(comprimentoTotal || 0);
      setSaldoAdutora(comprimentoTotal || 0); // Variável para controlar o botão de concluir etapa
      setNovoTrecho(true);

      if (comprimentoTotal === 0 && !isNecessidadeReprocessamentoAdutora) {
        setPodeCalcularAdutora(true);
      } else {
        setPodeCalcularAdutora(false);
      }
    }
  }, [adutoraTubulacaoTrechos, adutoraTotal]);

  useEffect(() => {
    if (atualizarTrechos) {
      carregaAdutoraTrechos();
      setAtualizarTrechos(false);
    }
  }, [atualizarTrechos]);

  useEffect(() => {
    (async () => {
      if (sessaoId) {
        Swal.fire({
          icon: 'info',
          text: 'Buscando informações da adutora...',
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        await carregaTubos();
        await carregaAdutoraTrechos();
        Swal.close();
      }
    })();
  }, [sessaoId]);

  useEffect(() => {
    if (loading) {
      toast.loading('Aguarde...', { position: 'top-right' });
      return;
    }

    toast.dismiss();
  }, [loading]);

  async function triggerSalvaTubulacao(
    isNew: boolean,
    tubulacao: AdutoraTubulacaoOrcamento,
  ) {
    const {
      ordem,
      material,
      comprimento,
      cotaFinal,
      cotaInicial,
      velocidade,
      diametro,
    } = tubulacao;

    if (comprimento && material && velocidade) {
      if (indicCotaEspecifica && (cotaFinal == null || cotaFinal < 0)) {
        toast.error(
          'Tipo cota Específica obriga informar uma cota específica!',
        );
        return;
      }

      if (ordem === 1)
        if (indicCotaEspecifica && (cotaInicial == null || cotaInicial < 0)) {
          toast.error(
            'Tipo cota Específica obriga informar a cota inicial no primeiro trecho',
          );
          return;
        }

      if (Number(velocidade) > VELOCIDADE_MAXIMA) {
        toast.error(
          `A velocidade ${velocidade} nesse trecho extrapola o máximo permitido (${VELOCIDADE_MAXIMA})!`,
        );
        return;
      }
    }

    if (Number(adutoraSaldo) > Number(adutoraTotal)) {
      toast.error(
        `A soma dos comprimentos [${adutoraSaldo}] é maior do que o comprimento informado no questionário [${adutoraTotal}]`,
      );
      return;
    }

    const tubulacaoSalvar =
      tubos.find(tubo => tubo.nome === material)?.seq || 0;

    if (!tubulacaoSalvar) return;

    async function insert() {
      await api.post('/integracao/adutora/orcamento', {
        sessao: sessaoId,
        ordem,
        fichaTecnicaId: fichaTecnica.id,
        tubulacaoAdutoraId: tubulacaoSalvar,
        tuboDescricao: material,
        metragem: comprimento,
        cotaFinal: tipoCota === 'Linear' ? null : cotaFinal,
        cotaInicial: tipoCota === 'Linear' ? null : cotaInicial,
        orcamentoId,
        velocidade,
        diametro,
      });
    }

    async function update() {
      await api.put('/integracao/adutora/orcamento', {
        sessao: sessaoId,
        ordem,
        tubulacaoAdutoraId: tubulacaoSalvar,
        tuboDescricao: material,
        metragem: comprimento,
        cotaInicial: tipoCota === 'Linear' ? null : cotaInicial,
        cotaFinal: tipoCota === 'Linear' ? null : cotaFinal,
        orcamentoId,
        velocidade,
        diametro,
        fichaTecnicaId: fichaTecnica.id,
      });
    }

    async function calcula() {
      await api.post('/integracao/adutora/orcamento/calculo-simplificado', {
        sessao: sessaoId,
        ordem,
        fichaTecnicaId: fichaTecnica.id,
        orcamentoId,
      });
    }

    try {
      setLoading(true);
      if (isNew) {
        await insert();
      } else {
        await update();
      }

      await calcula();

      await carregaAdutoraTrechos();

      props.handleCalcularAdutora();
    } finally {
      setLoading(false);
      const comprimentoTotal =
        adutoraTotal -
        adutoraTubulacaoTrechos.reduce(
          (total: any, item: any) => total + (item?.comprimento || 0),
          0,
        );
      if (comprimentoTotal === 0 && habilitaBotao) {
        setPodeCalcularAdutora(true);
      } else {
        setPodeCalcularAdutora(false);
      }
    }
  }

  function onChangeAdutoraTubulacaoOrcamento(
    tubulacaoOrdem: number,
    name: string,
    valor: number,
  ) {
    const novoValor = valor;
    let novoAdutoraTubulacaoOrcamento = null;

    if (name === 'tubulacaoAdutoraId') {
      const tuboDescricao = tubos.find(tubo => tubo.seq === novoValor)?.nome;
      const tuboDI = tubos.find(tubo => tubo.seq === novoValor)?.di;

      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? {
              ...adutora,
              material: tuboDescricao,
              [name]: novoValor,
              diametro: tuboDI,
              alterado: true,
              salvar: true,
            }
          : adutora,
      );
    } else if (name === 'comprimento' && listaTrechosPn.length > 0) {
      // comprimento

      const cota = getElevPonto(
        adutoraTubulacaoTrechos,
        listaTrechosPn,
        novoValor,
        tubulacaoOrdem,
      );

      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? {
              ...adutora,
              [name]: novoValor,
              cotaFinal: cota,
              alterado: true,
              salvar: true,
            }
          : adutora,
      );
    } else {
      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? { ...adutora, [name]: novoValor, alterado: true, salvar: true }
          : adutora,
      );
    }

    // if (name === 'tubulacaoAdutoraId') {
    const { vazaoCAF, vazaoSAF, incluirAspersorFinal } = fichaTecnica;

    const PI = 3.14159265;

    const usaAspersorFinal: boolean =
      String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
      String(incluirAspersorFinal).toUpperCase() === 'S';

    const vazao = usaAspersorFinal ? Number(vazaoCAF) : Number(vazaoSAF);

    novoAdutoraTubulacaoOrcamento = novoAdutoraTubulacaoOrcamento.map(
      adutora => {
        if (adutora.ordem !== tubulacaoOrdem || !adutora.diametro)
          return { ...adutora };

        // const tuboDI = tubos.find(tubo => tubo.di === adutora.diametro)?.di;
        const tuboDI = tubos.find(
          tubo => tubo.di === adutora.diametro || tubo.dn === adutora.diametro,
        )?.di;

        const velocidadeCalculada = arredondaDecimais(
          vazao /
            // eslint-disable-next-line no-restricted-properties
            (Math.pow(Number(tuboDI || 0) / 1000 / 2, 2) * PI * 3600),
        );

        return {
          ...adutora,
          velocidade: velocidadeCalculada,
          velocidadeErro: velocidadeCalculada > VELOCIDADE_MAXIMA,
        };
      },
    );
    // }

    const comprimentoTotal =
      adutoraTotal -
      novoAdutoraTubulacaoOrcamento.reduce(
        (total: any, item: any) => total + (item?.comprimento || 0),
        0,
      );

    setAdutoraSaldo(comprimentoTotal || 0);
    setSaldoAdutora(comprimentoTotal || 0); // Variável para controlar o botão de concluir etapa

    setAdutoraTubulacaoTrechos(novoAdutoraTubulacaoOrcamento);
    setHabilitaBotao(true);
  }

  function onFocusOutAdutoraTubulacaoOrcamento(
    tubulacaoOrdem: number,
    name: string,
    valor: number,
  ) {
    const novoValor = valor;
    let novoAdutoraTubulacaoOrcamento = null;

    const findOld = adutoraTubulacaoTrechos.filter(
      adutora => adutora.ordem === tubulacaoOrdem,
    );

    const { salvar, material, comprimento, velocidade, novo } = findOld[0];

    if (salvar === true && comprimento && material && velocidade) {
      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? { ...adutora, [name]: novoValor, alterado: true, salvar: false }
          : adutora,
      );

      setAdutoraTubulacaoTrechos(novoAdutoraTubulacaoOrcamento);

      triggerSalvaTubulacao(
        novo,
        novoAdutoraTubulacaoOrcamento.filter(
          adutora => adutora.ordem === tubulacaoOrdem,
        )[0],
      );
    }
  }

  // Ovidio Souza - análise dos trechos Adutora ##############################################################
  async function carregarCamposElevacao(diametroAZ: number) {
    const response = await api.get(
      `/orcamento/pontos-mapa?orcamentoId=${orcamentoId}&tipo=elevacao_adutora`,
    );

    if (diametroAZ > 0) {
      let xDiametro = diametroAZ;
      if (xDiametro === 141) xDiametro = 150;
      if (xDiametro === 165) xDiametro = 200;

      const xElevacoes = response.data;
      const tubosPVC = tubos.filter(item =>
        item.nome.includes(`PVC ${xDiametro}/`),
      );

      const tuboAZx = tubos.filter(item => item.nome.includes('AZ'));
      const filterTuboAZx = tuboAZx.filter(item => item.dn === diametroAZ)[0];

      // executa trechos
      if (fichaTecnicaAdutora) {
        const listaTrechosX = await getTrechosAdutora(
          tubosPVC,
          diametroAZ,
          filterTuboAZx?.coeficiente || 130,
          xDiametro,
          xElevacoes,
          fichaTecnica,
          fichaTecnicaAdutora,
          adutoraTotal,
        );
        // navigator.clipboard.writeText(`${JSON.stringify(listaTrechosX)}`); // Temporário só para testes
        setListaTrechos(listaTrechosX);
        setSegTrechos(listaTrechosX?.seguimentosTrechos);
        setListaTrechosPn(listaTrechosX?.listaTrechos);
      }
    }
  }

  function mostra_grafico(mostrar: boolean) {
    const chartDiv = document.getElementById('pnxchart');
    const segDiv = document.getElementById('segDiv');
    const segTab = document.getElementById('segTab');

    if (chartDiv && segDiv && segTab) {
      if (
        chartDiv.style.height === '1px' ||
        mostrar ||
        segTrechos.length === 0
      ) {
        chartDiv.style.height = '480px';
        segDiv.style.height = '480px';
        segTab.style.height = 'auto';
        chartDiv.style.visibility = 'visible';
        segDiv.style.visibility = 'visible';
        segTab.style.visibility = 'visible';
      } else {
        chartDiv.style.height = '1px';
        segDiv.style.height = '1px';
        segTab.style.height = '1px';
        chartDiv.style.visibility = 'hidden';
        segDiv.style.visibility = 'hidden';
        segTab.style.visibility = 'hidden';
      }
    }
  }

  function infoTrechos() {
    // let trechos = '';
    if (adutoraTubulacaoTrechos.length > 0) {
      const tuboAZ = adutoraTubulacaoTrechos.filter(item =>
        item.material.includes('AZ'),
      );
      if (tuboAZ.length > 0) {
        const diamAZ = Number(tuboAZ[0]?.material.split(' ')[1]);
        carregarCamposElevacao(diamAZ);
      }
    } else {
      toast(`Insira o primeiro trecho para verificação!`, {
        icon: '⚠️',
      });
    }
  }

  useEffect(() => {
    if (fichaTecnicaAdutora) {
      setListaTrechos(null);
      setSegTrechos([]);
      // setListaTrechosPn([]);
    }
  }, [fichaTecnicaAdutora]);

  // ffffff Ovidio Souza - final análise dos trechos Adutora ########################################################

  async function updateTrechos() {
    setListaTrechos(null);
    setSegTrechos([]);
    // setListaTrechosPn([]);
    infoTrechos();
    let novoAdutoraTubulacaoOrcamento = null;

    const { vazaoCAF, vazaoSAF, incluirAspersorFinal } = fichaTecnica;

    const PI = 3.14159265;

    const usaAspersorFinal: boolean =
      String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
      String(incluirAspersorFinal).toUpperCase() === 'S';

    const vazao = usaAspersorFinal ? Number(vazaoCAF) : Number(vazaoSAF);

    novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora => {
      const tuboSelecionar =
        tubos.find(tubo => String(tubo.nome) === String(adutora.material))
          ?.seq ?? null;

      const novoDiametro = tubos.find(
        tubo => String(tubo.nome) === String(adutora.material),
      )?.di;

      const velocidadeCalculada = arredondaDecimais(
        vazao /
          // eslint-disable-next-line no-restricted-properties
          (Math.pow(Number(novoDiametro || 0) / 1000 / 2, 2) * PI * 3600),
      );

      return {
        ...adutora,
        diametro: novoDiametro,
        tubulacaoAdutoraId: tuboSelecionar,
        velocidade: velocidadeCalculada,
        velocidadeErro: velocidadeCalculada > VELOCIDADE_MAXIMA,
      };
    });

    const comprimentoTotal =
      adutoraTotal -
      novoAdutoraTubulacaoOrcamento.reduce(
        (total: any, item: any) => total + (item?.comprimento || 0),
        0,
      );
    setAdutoraSaldo(comprimentoTotal || 0);
    setSaldoAdutora(comprimentoTotal || 0); // Variável para controlar o botão de concluir etapa
    setAdutoraTubulacaoTrechos(novoAdutoraTubulacaoOrcamento);

    // eslint-disable-next-line no-restricted-syntax
    for await (const tubulacaoSalvar of novoAdutoraTubulacaoOrcamento) {
      await triggerSalvaTubulacao(false, tubulacaoSalvar);
    }
    necessidadeReprocessamentoData({
      ...necessidadeReprocessamento,
      reprocessarTrechos: false,
    } as FichaTecnicaNecessidadeReprocessamento);

    setExibeTrechos(false);

    // Habilita o botão para calcular adutora
    setPodeCalcularAdutora(true);
    setHabilitaBotao(true);
    listaNecessidadeReprocessamento();
    if (comprimentoTotal === 0) props.handleCalcularAdutoraTotal();
  }

  async function mostrarModalTubulacao(tubulacao?: CalculoAdutora) {
    try {
      setLoading(true);

      if (tubulacao) {
        const rows: any[] = [];

        if (tubulacao?.tamanhoPVC != null)
          rows.push(`Tamanho tubos PVC: ${tubulacao?.tamanhoPVC}`);
        if (tubulacao?.quantidadePVC != null)
          rows.push(`Quantidade tubos PVC: ${tubulacao?.quantidadePVC}`);
        if (tubulacao?.quantidadeAZ05M != null)
          rows.push(`Quantidade tubos AZ 0.5M: ${tubulacao?.quantidadeAZ05M}`);
        if (tubulacao?.quantidadeAZ1M != null)
          rows.push(`Quantidade tubos AZ 1M: ${tubulacao?.quantidadeAZ1M}`);
        if (tubulacao?.quantidadeAZ2M != null)
          rows.push(`Quantidade tubos AZ 2M: ${tubulacao?.quantidadeAZ2M}`);
        if (tubulacao?.quantidadeAZ3M != null)
          rows.push(`Quantidade tubos AZ 3M: ${tubulacao?.quantidadeAZ3M}`);
        if (tubulacao?.quantidadeAZ6M != null)
          rows.push(`Quantidade tubos AZ 6M: ${tubulacao?.quantidadeAZ6M}`);

        setRowsModal(rows);
        setModalTitulo(`Tubulação da Adutora - Trecho ${tubulacao?.ordem}`);
        setModalStatusVisivel(true);
      }
    } finally {
      setLoading(false);
    }
  }

  async function salvaTubulacao(tubulacao: AdutoraTubulacaoOrcamento) {
    const {
      ordem,
      material,
      comprimento,
      cotaFinal,
      cotaInicial,
      velocidade,
      diametro,
    } = tubulacao;

    if (indicCotaEspecifica && (cotaFinal == null || cotaFinal < 0)) {
      Swal.fire({
        icon: 'error',
        text: `Tipo cota Específica obriga informar uma cota específica!`,
      });
      return;
    }

    if (ordem === 1)
      if (indicCotaEspecifica && (cotaInicial == null || cotaInicial < 0)) {
        Swal.fire({
          icon: 'error',
          text: `Tipo cota Específica obriga informar a cota inicial no primeiro trecho!`,
        });
        return;
      }

    if (!velocidade || !material || !comprimento) {
      Swal.fire({
        icon: 'error',
        text: `Um ou mais campos não foram preenchidos`,
      });
      return;
    }

    if (Number(velocidade) > VELOCIDADE_MAXIMA) {
      Swal.fire({
        icon: 'error',
        text: `A velocidade ${velocidade} nesse trecho extrapola o máximo permitido (${VELOCIDADE_MAXIMA})!`,
      });
      return;
    }

    if (!material) {
      return;
    }

    const tubulacaoSalvar =
      tubos.find(tubo => tubo.nome === material)?.seq || 0;

    if (!tubulacaoSalvar) return;

    async function insert() {
      try {
        await api.post('/integracao/adutora/orcamento', {
          sessao: sessaoId,
          ordem,
          fichaTecnicaId: fichaTecnica.id,
          tubulacaoAdutoraId: tubulacaoSalvar,
          tuboDescricao: material,
          metragem: comprimento,
          cotaFinal,
          cotaInicial,
          orcamentoId,
          velocidade,
          diametro,
        });
        setAdicionando(false);
      } catch (error: any) {
        setAdicionando(true);
        throw error;
      }
    }

    async function update() {
      await api.put('/integracao/adutora/orcamento', {
        sessao: sessaoId,
        ordem,
        tubulacaoAdutoraId: tubulacaoSalvar,
        tuboDescricao: material,
        metragem: comprimento,
        cotaInicial,
        cotaFinal,
        orcamentoId,
        velocidade,
        diametro,
        fichaTecnicaId: fichaTecnica.id,
      });
    }

    async function calcula() {
      await api.post('/integracao/adutora/orcamento/calculo-simplificado', {
        sessao: sessaoId,
        ordem,
        fichaTecnicaId: fichaTecnica.id,
        orcamentoId,
      });
    }

    try {
      setLoading(true);
      if (adicionando) {
        await insert();
      } else {
        await update();
      }

      await calcula();

      await carregaAdutoraTrechos();

      props.handleCalcularAdutora();
    } finally {
      setLoading(false);
    }
  }

  async function deleteTubulacao(tubulacao: AdutoraTubulacaoOrcamento) {
    if (tubulacao.novo) {
      const novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.filter(
        adutora => !adutora.novo,
      );
      setAdutoraTubulacaoTrechos(novoAdutoraTubulacaoOrcamento);
      setAdicionando(false);
    } else {
      const { ordem } = tubulacao;
      try {
        setLoading(true);
        await api.delete('/integracao/adutora/orcamento', {
          params: {
            sessao: sessaoId,
            ordem,
            fichaTecnicaId: fichaTecnica.id,
            orcamentoId,
          },
        });
        carregaAdutoraTrechos();
        props.handleCalcularAdutora();
      } finally {
        setLoading(false);
        setExibeTotais(false);
      }
    }
  }

  function handleAdicionaNovaLinha() {
    if (loading) return;

    const existeNovoNaoSalvo = adutoraTubulacaoTrechos.some(
      adutora => adutora.novo,
    );

    if (existeNovoNaoSalvo) {
      return;
    }
    setAdutoraTubulacaoTrechos([
      ...adutoraTubulacaoTrechos,
      {
        novo: true,
        tubulacaoAdutoraId: 0,
        material: '',
        comprimento: 0,
        orcamentoId,
        sessao: String(sessaoId),
        ordem: adutoraTubulacaoTrechos.length + 1,
        cotaFinal: null,
        cotaInicial: null,
        diametro: null,
        velocidade: null,
        vazao: null,
        coeficiente: null,
        quantidade: null,
        classe: '',
        indicTipo: '',
        quantidadeAZ05M: null,
        quantidadeAZ1M: null,
        quantidadeAZ2M: null,
        quantidadeAZ3M: null,
        quantidadeAZ6M: null,
        quantidadePVC: null,
        tamanhoPVC: null,
        alterado: false,
        hfTotal: null,
        velocidadeErro: false,
        isSaving: false,
      },
    ]);
    setAdicionando(true);
    setNovoTrecho(true);
  }

  function getDiamTuboInicial(vazao: any) {
    const PI = 3.14159265;
    const todosDiAZ = [
      100, 141, 150, 165, 200, 250, 300, 350, 400, 450, 500, 600,
    ];
    let velocidadeTubo: any;

    for (let j = 0; j < todosDiAZ.length; j += 1) {
      const diamentro = todosDiAZ[j];
      velocidadeTubo = arredondaDecimais(
        vazao /
          // eslint-disable-next-line no-restricted-properties
          (Math.pow(Number(diamentro || 0) / 1000 / 2, 2) * PI * 3600),
      );
      if (velocidadeTubo <= 2.5) {
        velocidadeTubo = todosDiAZ[j];
        break;
      }
    }
    return velocidadeTubo;
  }

  useEffect(() => {
    if (
      criarPrimeiroTrecho &&
      fichaTecnicaTubulacao &&
      adutoraTubulacaoTrechos.length === 0 &&
      tubos.length > 0
    ) {
      const { vazaoCAF, vazaoSAF, incluirAspersorFinal } = fichaTecnica;

      const PI = 3.14159265;

      const usaAspersorFinal: boolean =
        String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
        String(incluirAspersorFinal).toUpperCase() === 'S';

      const vazao = usaAspersorFinal
        ? Number(vazaoCAF ?? 0)
        : Number(vazaoSAF ?? 0);

      const diametroPrimLance: number = getDiamTuboInicial(vazao);

      const comprimentoPadrao =
        parametros?.adutoraComprimentoPadraoTrecho1 || 0;

      const tubosAZ = tubos.filter(item => item.nome.includes('AZ'));

      const isArray = Array.isArray(fichaTecnicaTubulacao);

      let diametroLigacao: number = fichaTecnicaTubulacao?.diametroLigacao ?? 0;

      // let velocidadeLigacao = fichaTecnicaTubulacao?.velocLigacao ?? 0;

      if (isArray) {
        const fichaArr: any = fichaTecnicaTubulacao;
        // por algum motivo, identificava como array
        diametroLigacao = fichaArr?.[0]?.diametroLigacao ?? 0;
        // velocidadeLigacao = fichaArr?.[0]?.velocLigacao ?? 0;
      }

      if (diametroLigacao < diametroPrimLance) {
        diametroLigacao = diametroPrimLance;
      }

      // const tuboFiltrado = tubosAZ.filter(item => item.di === diametroLigacao);
      const tuboFiltrado = tubosAZ.filter(item => item.dn === diametroLigacao);

      const velocidadeCalculada = arredondaDecimais(
        vazao /
          // eslint-disable-next-line no-restricted-properties
          (Math.pow(Number(tuboFiltrado[0]?.di || 0) / 1000 / 2, 2) *
            PI *
            3600),
      );

      const trechoInicial: AdutoraTubulacaoOrcamento = {
        novo: true,
        tubulacaoAdutoraId: tuboFiltrado[0]?.seq || 0,
        material: tuboFiltrado[0]?.nome || '',
        comprimento: comprimentoPadrao,
        orcamentoId,
        sessao: String(sessaoId),
        ordem: adutoraTubulacaoTrechos.length + 1,
        cotaFinal: null,
        cotaInicial: null,
        diametro: diametroLigacao || null,
        vazao: null,
        coeficiente: null,
        quantidade: null,
        classe: '',
        indicTipo: '',
        quantidadeAZ05M: null,
        quantidadeAZ1M: null,
        quantidadeAZ2M: null,
        quantidadeAZ3M: null,
        quantidadeAZ6M: null,
        quantidadePVC: null,
        tamanhoPVC: null,
        alterado: false,
        hfTotal: null,
        velocidade: velocidadeCalculada,
        velocidadeErro: velocidadeCalculada > VELOCIDADE_MAXIMA,
      };

      setAdutoraTubulacaoTrechos([trechoInicial]);

      // No caso de específica, não disparo o processo de salvar. Pois ele precisa informar as cotas.
      // Assim, não vai disparar os erros
      if (!indicCotaEspecifica) triggerSalvaTubulacao(true, trechoInicial);

      // Ovidio no primeiro trecho
      carregarCamposElevacao(trechoInicial?.diametro || 0);
    }
  }, [criarPrimeiroTrecho, fichaTecnicaTubulacao, tubos]);

  // cria automatico testes ovidio
  function newTrechoSugestao(seg: SegTrechos) {
    if (
      seg &&
      fichaTecnicaTubulacao &&
      adutoraTubulacaoTrechos.length > 0 &&
      tubos.length > 0
    ) {
      const diametroLigacao = Number(segTrechos[1].classePN.split('/')[1]) ?? 0;
      const tuboFiltrado = tubos.filter(item => item.nome === seg.classePN);
      const { vazaoCAF, vazaoSAF, incluirAspersorFinal } = fichaTecnica;
      const PI = 3.14159265;

      const usaAspersorFinal: boolean =
        String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
        String(incluirAspersorFinal).toUpperCase() === 'S';

      const vazao = usaAspersorFinal
        ? Number(vazaoCAF ?? 0)
        : Number(vazaoSAF ?? 0);

      const velocidadeCalculada = arredondaDecimais(
        vazao /
          // eslint-disable-next-line no-restricted-properties
          (Math.pow(Number(tuboFiltrado[0]?.di || 0) / 1000 / 2, 2) *
            PI *
            3600),
      );

      const ordem = adutoraTubulacaoTrechos.length + 1;

      setAdutoraTubulacaoTrechos([
        ...adutoraTubulacaoTrechos,
        {
          novo: true,
          tubulacaoAdutoraId: tuboFiltrado[0]?.seq || 0,
          material: tuboFiltrado[0]?.nome || '',
          diametro: tuboFiltrado[0]?.di || null,
          comprimento: seg.distAcc,
          orcamentoId,
          sessao: String(sessaoId),
          ordem,
          cotaFinal: seg.cotaFin,
          cotaInicial: seg.cotaIni,
          velocidade: velocidadeCalculada,
          velocidadeErro: velocidadeCalculada > VELOCIDADE_MAXIMA,
          vazao: null,
          coeficiente: null,
          quantidade: null,
          classe: '',
          indicTipo: '',
          quantidadeAZ05M: null,
          quantidadeAZ1M: null,
          quantidadeAZ2M: null,
          quantidadeAZ3M: null,
          quantidadeAZ6M: null,
          quantidadePVC: null,
          tamanhoPVC: null,
          alterado: false,
          hfTotal: null,
          isSaving: false,
        },
      ]);
      // setAdicionando(true);
      setNovoTrecho(true);
    }
  }
  const [inserindoAutomatico, setInserindoAutomatico] = useState(false);
  const [atualizarAutomatico, setAtualizarAutomatico] = useState(false);

  function insereAutomTrecho() {
    if (
      segTrechos.length > 0 &&
      fichaTecnicaTubulacao &&
      adutoraTubulacaoTrechos.length > 0 &&
      tubos.length > 0 &&
      inserindoAutomatico
    ) {
      if (adutoraTubulacaoTrechos.length === 1) {
        adutoraTubulacaoTrechos[0].cotaFinal = segTrechos[0].cotaFin;
        adutoraTubulacaoTrechos[0].cotaInicial = segTrechos[0].cotaIni;
      }

      if (segTrechos.length === adutoraTubulacaoTrechos.length) {
        setInserindoAutomatico(false);
        mostra_grafico(false);
        updateTrechos();
      } else {
        newTrechoSugestao(segTrechos[adutoraTubulacaoTrechos.length]);
      }
    } else {
      setInserindoAutomatico(false);
    }
  }

  useEffect(() => {
    if (segTrechos.length === adutoraTubulacaoTrechos.length) {
      setInserindoAutomatico(false);
      setAtualizarAutomatico(false);
    }
    if (atualizarAutomatico) {
      setInserindoAutomatico(true);
      setAtualizarAutomatico(false);
    }
    if (inserindoAutomatico) insereAutomTrecho();

    infoTrechos();
  }, [adutoraTubulacaoTrechos, inserindoAutomatico]);

  // ovidio
  window.google.load('visualization', '1', {
    packages: ['columnchart', 'corechart', 'table'],
  });

  async function initChart() {
    if (window.google && listaTrechosPn?.length > 0) {
      const chartDiv = document.getElementById('pnxchart');
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();

      const inicioElev =
        parseInt((listaTrechosPn[0]?.elevacao / 100).toFixed(1), 10) * 100;

      data.addColumn('number', 'Distância');
      data.addColumn('number', `Elevação = ${inicioElev}+`);
      data.addColumn('number', 'Pressão');
      data.addColumn('number', 'PN');

      let dist = 0;

      for (let i = 0; i < listaTrechosPn.length; i += 1) {
        dist += listaTrechosPn[i].distancia;

        data.addRow([
          dist,
          listaTrechosPn[i].elevacao - inicioElev,
          listaTrechosPn[i].pFin,
          listaTrechosPn[i].pn,
        ]);
      }
      // Draw the chart using the data within its DIV.
      chart.draw(data, {
        height: 450,
        title: 'Pressão na Adutora (Sugerido)',
        legend: 'true',
        titleY: 'Pressao',
        titleX: 'Distância',
        curveType: 'line',
        series: {
          0: {
            type: 'area',
            color: 'blue',
            pointSize: 0,
            // lineSize: 0,
          },
        },
      });
    } else if (window.google) {
      const chartDiv = document.getElementById('pnxchart');
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();
      data.addColumn('number', 'distancia');
      data.addColumn('number', 'elevacao');
      data.addRow([0, 0]);
      chart.draw(data, {
        height: 450,
        title: `Não encontrada dados`,
      });
    }
    mostra_grafico(true);
  }

  useEffect(() => {
    if (listaTrechosPn?.length > 0) initChart();
  }, [listaTrechosPn]);
  const cotaAcc = 0;
  return (
    <>
      <CardBody
        className="p-1"
        // onClick={() => {
        //   mostra_grafico(false);
        // }}
      >
        <b>📈 Visualizar gráfico PN Adutora / Sugeridos</b>
        <Button
          className="btn-icon ml-3"
          color="info"
          type="button"
          title="Sugestão de trechos"
          onClick={() => {
            infoTrechos();
            mostra_grafico(false);
          }}
          size="sm"
        >
          <FaInfoCircle />
        </Button>
      </CardBody>
      <div id="segDiv" style={{ height: '1px', visibility: 'hidden' }}>
        <Table id="segTab" style={{ height: '1px', visibility: 'hidden' }}>
          <tr>
            <td style={{ width: '25%', margin: 'auto' }}>
              <Table style={{ width: '300px', margin: 'auto' }}>
                <thead>
                  <tr>
                    <th>Comprim</th>
                    <th>Acc</th>
                    <th>Material</th>
                    <th>Cota</th>
                  </tr>
                </thead>
                <tbody>
                  {segTrechos.map(seg => (
                    <tr>
                      <Td>{seg.distAcc}</Td>
                      <Td>{seg.totalAcc}</Td>
                      <Td>{seg.classePN}</Td>
                      <Td>{seg.cotaFin}</Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <br />
              <Button
                disabled={loading || segTrechos.length === 0}
                hidden={
                  segTrechos.length === adutoraTubulacaoTrechos.length ||
                  adutoraTubulacaoTrechos[0]?.velocidade > 5
                }
                className="btn-icon btn-2 mr-3 float-right"
                color="warning"
                type="button"
                size="sm"
                title="Insere os trechos sugeridos a partir da definição do AZ"
                onClick={() => {
                  if (adutoraTubulacaoTrechos.length === 1) {
                    setInserindoAutomatico(true);
                  } else if (adutoraTubulacaoTrechos.length > 1) {
                    setAdutoraTubulacaoTrechos([adutoraTubulacaoTrechos[0]]);
                    setAtualizarAutomatico(true);
                  } else {
                    toast(`Na lista deve estar somente o AZ definido!`, {
                      icon: '⚠️',
                    });
                  }
                }}
              >
                <FaCheckCircle /> Inserir sugeridos
              </Button>
            </td>
            <td style={{ width: '75%' }}>
              <div
                id="pnxchart"
                style={{ height: '1px', visibility: 'hidden' }}
              />
            </td>
          </tr>
        </Table>
      </div>
      <GenericModal
        title={modalTitulo}
        rows={rowsModal}
        modalState={modalStatusVisivel}
        toggle={() => setModalStatusVisivel(false)}
      />
      <div className="mb-4">
        <strong>Total Adutora:</strong> {adutoraTotal}m |{' '}
        <strong>Saldo: </strong>{' '}
        <span style={adutoraSaldo < 0 ? { color: '#FF0000' } : {}}>
          {adutoraSaldo}m
        </span>
        <Button
          className="btn btn-outline-default float-right"
          type="button"
          size="sm"
          disabled={loading}
          onClick={handleAdicionaNovaLinha}
        >
          Novo <FaPlusCircle />
        </Button>
        <Button
          disabled={loading}
          className="btn-icon btn-2 mr-3 float-right"
          color="warning"
          type="button"
          size="sm"
          title="Reprocessar trechos"
          hidden={
            !isNecessidadeReprocessamentoTrechos ||
            adutoraTubulacaoTrechos?.length < 2
          }
          onClick={() => {
            if (adutoraSaldo === 0) {
              updateTrechos();
            } else {
              toast(
                `O saldo do comprimento total da Adutora deverá ser Zero!`,
                {
                  icon: '⚠️',
                },
              );
            }
          }}
        >
          <FaCheckCircle /> Reprocessar trechos
        </Button>
      </div>
      <Table bordered responsive>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th hidden={header === 'hide'} key={index}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {adutoraTubulacaoTrechos.map(tubulacao => {
            const alterandoOutro = false;

            const tuboSelecionar =
              tubos.find(
                tubo => String(tubo.nome) === String(tubulacao.material),
              )?.seq ?? null;
            /* adutoraTubulacaoTrechos.some(
              adutora =>
                (adutora.alterado || (adicionando && adutora.novo)) &&
                adutora.ordem !== tubulacao.ordem,
            ); */

            const objetoConvertido = tubulacao as CalculoAdutora;

            return (
              <Tr key={tubulacao.ordem} alterado={tubulacao.alterado}>
                <Td>
                  <strong>{tubulacao.ordem}º</strong>
                </Td>
                <Td editavel>
                  <TextField
                    disabled={alterandoOutro}
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    value={tubulacao.comprimento || ''}
                    onChange={event => {
                      onChangeAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'comprimento',
                        Number(event.target.value ?? 0),
                      );
                      setExibeTotais(false);
                      if (
                        tubulacao.ordem <= ajustarCotaOrdem ||
                        tubulacao.ordem === ajustarCotaOrdem + 1
                      )
                        setAjustarCotaOrdem(tubulacao.ordem);
                    }}
                    onBlur={event => {
                      onFocusOutAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'comprimento',
                        Number(event.target.value ?? 0),
                      );
                      setExibeTotais(false);
                    }}
                  />
                </Td>
                <Td>{tubulacao?.diametro}</Td>
                <Td editavel>
                  <Select
                    disabled={alterandoOutro}
                    className="mt--5"
                    autoWidth
                    disableUnderline
                    value={tuboSelecionar}
                    onChange={event => {
                      onChangeAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'tubulacaoAdutoraId',
                        Number(event.target.value ?? 0),
                      );
                      setExibeTotais(false);
                    }}
                    onBlur={event => {
                      onFocusOutAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'tubulacaoAdutoraId',
                        Number(event.target.value ?? 0),
                      );
                      setExibeTotais(false);
                    }}
                  >
                    {tubos.map(tubo => (
                      <MenuItem key={tubo.seq} value={tubo.seq}>
                        {tubo.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </Td>
                <Td>{tubulacao?.coeficiente}</Td>
                <Td>{tubulacao?.vazao}</Td>
                <Td>{tubulacao?.hfTotal}</Td>
                <Td>
                  {tubulacao?.velocidade}{' '}
                  {tubulacao.velocidadeErro && (
                    <FaExclamationTriangle
                      color="red"
                      title="Valor extrapola a velocidade máxima permitida"
                    />
                  )}
                </Td>
                <Td
                  editavel={tubulacao.ordem === 1 && indicCotaEspecifica}
                  hidden={!indicCotaEspecifica && !exibeCotas}
                >
                  <TextField
                    disabled={
                      tubulacao.ordem !== 1 ||
                      (alterandoOutro && tubulacao.ordem === 1)
                    }
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    value={tubulacao.cotaInicial || ''}
                    onChange={event => {
                      onChangeAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'cotaInicial',
                        Number(event.target.value ?? 0),
                      );
                      setExibeTotais(false);
                    }}
                    onBlur={event => {
                      onFocusOutAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'cotaInicial',
                        Number(event.target.value ?? 0),
                      );
                      setExibeTotais(false);
                    }}
                  />
                </Td>
                <Td editavel hidden={!indicCotaEspecifica && !exibeCotas}>
                  <TextField
                    disabled={alterandoOutro}
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    value={tubulacao.cotaFinal || ''}
                    onChange={event => {
                      onChangeAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'cotaFinal',
                        Number(event.target.value ?? 0),
                      );
                      if (
                        tubulacao.ordem <= ajustarCotaOrdem ||
                        tubulacao.ordem === ajustarCotaOrdem + 1
                      )
                        setAjustarCotaOrdem(tubulacao.ordem);
                      setExibeTotais(false);
                    }}
                    onBlur={event => {
                      onFocusOutAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'cotaFinal',
                        Number(event.target.value ?? 0),
                      );
                      setExibeTotais(false);
                    }}
                  />
                  <Button
                    disabled={loading}
                    className="btn-icon btn-2"
                    color={
                      tubulacao.ordem > ajustarCotaOrdem ? 'warning' : 'info'
                    }
                    hidden={tubulacao.ordem <= ajustarCotaOrdem}
                    type="button"
                    size="sm"
                    title="Busca elevação trecho Adutora"
                    onMouseDown={() => {
                      if (listaTrechosPn.length > 0) {
                        onChangeAdutoraTubulacaoOrcamento(
                          tubulacao.ordem,
                          'comprimento',
                          Number(tubulacao.comprimento ?? 0),
                        );
                        setExibeTotais(false);
                        if (
                          tubulacao.ordem <= ajustarCotaOrdem ||
                          tubulacao.ordem === ajustarCotaOrdem + 1
                        )
                          setAjustarCotaOrdem(tubulacao.ordem);
                      } else {
                        toast(
                          `Nessecita carregar o gráfico PN Adutora Sugeridos!`,
                          {
                            icon: '⚠️',
                          },
                        );
                      }
                    }}
                    onMouseUp={event => {
                      onFocusOutAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'cotaFinal',
                        Number(tubulacao.cotaFinal ?? 0),
                      );
                      setExibeTotais(false);
                    }}
                  >
                    <FaBuffer />
                  </Button>
                </Td>
                <Td>
                  <Button
                    disabled={loading}
                    className="btn-icon btn-2"
                    color="info"
                    type="button"
                    size="sm"
                    onClick={() => {
                      onChangeAdutoraTubulacaoOrcamento(
                        tubulacao.ordem,
                        'comprimento',
                        Number(tubulacao.comprimento ?? 0),
                      );
                      mostrarModalTubulacao(objetoConvertido);
                    }}
                  >
                    <FaInfoCircle />
                  </Button>

                  <Button
                    disabled={loading}
                    className="btn-icon btn-2"
                    color="primary"
                    type="button"
                    size="sm"
                    hidden
                    onClick={() => salvaTubulacao(tubulacao)}
                  >
                    <FaCheckCircle />
                  </Button>

                  <Button
                    disabled={loading}
                    className="btn-icon btn-2"
                    color="danger"
                    type="button"
                    size="sm"
                    onClick={() => deleteTubulacao(tubulacao)}
                  >
                    <FaTrash />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default TubulacaoAdutora;
