import styled from 'styled-components';

interface ContainerProps {
  noBorder?: boolean;
}

export const Container = styled.div`
  overflow: auto;
  width: 100%;
`;

export const ChildrenContainer = styled.div<ContainerProps>`
  border: ${props => (props.noBorder ? 'none' : '1px solid #c0c0c0')};
  border-radius: 3px;
`;

export const TituloContainer = styled.div`
  padding: 0.3rem 0 0.4rem 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #2f75b6;
  text-align: center;
`;

export const Titulo = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
`;

export const FootContainer = styled.div`
  padding: 0.3rem 1rem 0.4rem 1rem;
  border-radius: 5px;
  background-color: #2f75b6;
  display: flex;
  justify-content: flex-end;
`;
