import UserSmallHeader from 'components/Headers/UserSmallHeader';
import FichaTecnica from 'models/FichaTecnica';
import Orcamento from 'models/Orcamento';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import Cabecalho from './components/Cabecalho';

import Detalhes from './components/Detalhes';

import { LabelOrcamento } from './styles';

interface LocationState {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
}

function Velocidade(): JSX.Element {
  const location = useLocation<LocationState>();
  const fichaTecnica = location.state?.fichaTecnica;
  const orcamento = location.state?.orcamento;
  const { orcamentoVersaoId } = fichaTecnica;

  return (
    <>
      <UserSmallHeader />
      <Container fluid>
        <Row className="mt-2 ml-1">
          <LabelOrcamento>
            Configuração {orcamentoVersaoId} - Dados para operar o percentímetro
          </LabelOrcamento>
        </Row>

        <Card className="shadow mt-2">
          <CardBody className="p-1">
            <Card className="shadow">
              <Row>
                <Col className="mt-2 ml-2 mb-2 mr--2">
                  <Cabecalho
                    fichaTecnica={fichaTecnica}
                    orcamento={orcamento}
                  />
                </Col>
              </Row>
            </Card>
            <Card className="shadow mt-1">
              <Detalhes fichaTecnica={fichaTecnica} />
            </Card>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Velocidade;
