import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaArrowLeft, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import api from 'services/api';

import { useLocation, useHistory } from 'react-router-dom';
import { arredondaDecimais } from 'utils/arredondaDecimais';

import McaPressaoModel from '../../../models/ConfiguradorMcaPressao';
import PressaoModel from '../../../models/ConfiguradorPressaoPsi';
import AspersorModel from '../../../models/ConfiguradorAspersor';
import BocalModel from '../../../models/ConfiguradorBocal';

type LocationState = {
  stateBocal?: BocalModel;
  stateAspersor?: AspersorModel;
};

const ConfiguradorMcaPressao: React.FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const [mcas, setmcas] = useState([] as McaPressaoModel[]);
  // eslint-disable-next-line prettier/prettier
  const [McaPressaoEditing, setMcaPressaoEditing] = useState({} as McaPressaoModel);
  const [pressao, setPressao] = useState([] as PressaoModel[]);
  const [pressaoEditing, setPressaoEditing] = useState({} as PressaoModel);
  const [pressaoAtivo, setPressaoAtivo] = useState<PressaoModel[]>([]);
  const bocalState = location.state?.stateBocal;
  const bocalSelecionado = bocalState?.numero;
  const bocalId = bocalState?.id;
  const aspersor = location.state?.stateAspersor;

  const [formModalState, setFormModalState] = useState(false);

  async function listaMcas() {
    const response = await api.get(`/integracao/mca-pressao`);

    setmcas(response.data);
  }

  async function listaPressaoPsi() {
    const response = await api.get(`/integracao/mca-pressao/pressao`);
    setPressao(response.data);
    setPressaoAtivo(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaMcas();
    listaPressaoPsi();
  }, []);

  async function handleDelete(McaPressaoId: any) {
    Swal.fire({
      title: `Deseja excluir Mca/Pressão?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(
          `/integracao/mca-pressao/${bocalId}/${McaPressaoId.mca}/${McaPressaoId.pressao}`,
          {},
        );
        Swal.close();
        toast.success('Registro excluído');
        listaMcas();
      }
    });
  }
  async function handleInsert() {
    setMcaPressaoEditing({} as McaPressaoModel);
    setPressaoEditing({} as PressaoModel);
    setFormModalState(!formModalState);
  }

  async function handleSaveMcaPressao() {
    // eslint-disable-next-line prettier/prettier
    const { mca } = McaPressaoEditing;

    if (!mca) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    await api.post(`/integracao/mca-pressao/`, {
      id: bocalId,
      mca: McaPressaoEditing.mca,
      pressao: pressaoEditing.nroPsi,
      raio: McaPressaoEditing.raio,
      vazao: McaPressaoEditing.vazao,
    });

    toast.success('Registro concluído');
    setFormModalState(false);
    setMcaPressaoEditing({} as McaPressaoModel);
    listaMcas();
    listaPressaoPsi();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="10">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: `${'/admin/bocais'}`,
                    });
                  }}
                >
                  <FaArrowLeft />
                  <span className="btn-inner--text">Voltar</span>
                </Button>
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  style={{ maxHeight: '42.8px' }}
                  color="primary"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small />
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Mca </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Mca"
                            value={McaPressaoEditing.mca}
                            type="number"
                            onChange={text =>
                              setMcaPressaoEditing({
                                ...McaPressaoEditing,
                                mca: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Pressão </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Pressão"
                            type="select"
                            value={pressaoEditing.nroPsi || ''}
                            onChange={text =>
                              setPressaoEditing({
                                ...pressaoEditing,
                                nroPsi: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Pressão...</option>
                            {pressaoAtivo.map(Pressao => (
                              <option value={Pressao.nroPsi}>
                                {Pressao.nroPsi}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Raio </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Raio"
                            value={McaPressaoEditing.raio}
                            type="number"
                            onChange={text =>
                              setMcaPressaoEditing({
                                ...McaPressaoEditing,
                                raio: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Vazão </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Vazão"
                            value={McaPressaoEditing.vazao}
                            type="number"
                            onChange={text =>
                              setMcaPressaoEditing({
                                ...McaPressaoEditing,
                                vazao: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveMcaPressao}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  {aspersor} - {arredondaDecimais(bocalSelecionado, 2)}
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Mca</th>
                    <th>Pressão</th>
                    <th>Raio</th>
                    <th>Vazão</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {mcas
                    .filter((x: any) => Number(x.id) === bocalId)
                    .map(McaPressao => (
                      <tr key={McaPressao.id}>
                        <td className="py-2">{McaPressao.mca}</td>
                        <td className="py-2">
                          {arredondaDecimais(McaPressao.pressao, 2)}
                        </td>
                        <td className="py-2">{McaPressao.raio}</td>
                        <td className="py-2">{McaPressao.vazao}</td>
                        <td className="py-2">
                          <Button
                            size="sm"
                            className="btn-icon float-right"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(McaPressao);
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorMcaPressao;
