import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import Table from './Table';

interface CustomTableList {
  id: string;
  texto: string;
}

interface CustomTableHeader {
  name: string;
  prop: string;
  type: string;
  searchable: boolean;
  sortable: boolean;
  editable: boolean;
  required: boolean;
  values?: CustomTableList[];
  direction?: string | 'asc';
}

interface CustomTableColumn {
  prop: string;
  value: any | { id: string; texto: string; errorMessage: string };
  newValue: any | { id: string; texto: string; errorMessage: string };
}

interface CustomTableRow {
  id: number;
  isNew: boolean;
  isEditing: boolean;
  columns: CustomTableColumn[];
}

interface CustomLinkRow {
  name?: string;
  type?: string;
  pathname?: string;
  stateTipo?: string;
  stateLance?: any;
  stateTubo?: any;
}

interface Props {
  enableSearchField: boolean;
  header: CustomTableHeader[];
  rows?: CustomTableRow[];
  onDelete?: any;
  onSave?: any;
  onAddRow?: any;
  onStartEditing?: any;
  onStopEditing?: any;
  onLink?: CustomLinkRow[];
  onLinkBalanco?: CustomLinkRow[];
  ocultaBotoes?: boolean;
}

function CustomTable(props: Props): JSX.Element {
  const {
    header,
    enableSearchField,
    rows,
    onDelete,
    onSave,
    onAddRow,
    onStartEditing,
    onStopEditing,
    onLink,
    onLinkBalanco,
    ocultaBotoes,
  } = props;

  const [searchText, setSearchText] = useState('');

  const rowFilter = searchText
    ? rows?.filter(
        x =>
          x.columns.some(
            coluna =>
              header.some(hd => hd.prop === coluna.prop && hd.searchable) &&
              (typeof coluna.value === 'object'
                ? String(coluna.value.texto)
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                : String(coluna.value)
                    .toLowerCase()
                    .includes(searchText.toLowerCase())),
          ) || x.isNew,
      )
    : rows;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, []);

  return (
    <>
      <div style={{ padding: '15px' }}>
        <TextField
          hidden={!enableSearchField}
          id="search-field-text"
          label="Busca"
          defaultValue={null}
          value={searchText}
          style={{ marginLeft: 15, width: 300, marginBottom: 15 }}
          onChange={(text: any) => setSearchText(text.target.value)}
        />
        <Table
          header={header}
          rows={rowFilter}
          onDelete={onDelete}
          onSave={onSave}
          onAddRow={onAddRow}
          onStartEditing={onStartEditing}
          onStopEditing={onStopEditing}
          onLink={onLink}
          onLinkBalanco={onLinkBalanco}
          ocultaBotoes={ocultaBotoes}
        />
      </div>
    </>
  );
}

CustomTable.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  enableSearchField: false,
  rows: [],
};

export default CustomTable;
