import FichaTecnica from 'models/FichaTecnica';

import { Card, Table } from 'reactstrap';

import Quadro from '../../../../atoms/Quadro';
import { Td, Th } from '../../styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function AspersorFinal(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const {
    aspersor1SiglaBocal,
    aspersor1AreaTotal,
    aspersor1Raio,
    aspersor1Bocal,
    aspersor1Vazao,
    aspersor1Quantidade,

    aspersor2SiglaBocal,
    aspersor2AreaTotal,
    aspersor2Saida,
    aspersor2Raio,
    aspersor2Bocal,
    aspersor2Vazao,
    aspersor2Quantidade,
  } = fichaTecnica;

  const TITULO = 'ASPERSOR FINAL';

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Table bordered responsive>
          <Th>Modelo</Th>
          <Th>Área (ha)</Th>
          <Th>Raio (m)</Th>
          <Th>Bocal</Th>
          <Th>Vazão (m³/h)</Th>
          <Th>Lâmina (mm)</Th>

          <tr>
            <Td>{aspersor1SiglaBocal}</Td>
            <Td>{aspersor1AreaTotal}</Td>
            <Td>{aspersor1Raio}</Td>
            <Td>{aspersor1Bocal}</Td>
            <Td>{aspersor1Vazao}</Td>
            <Td>{aspersor1Quantidade}</Td>
          </tr>
          {Number(aspersor2Saida) > 0 && (
            <tr>
              <Td>{aspersor2SiglaBocal}</Td>
              <Td>{aspersor2AreaTotal}</Td>
              <Td>{aspersor2Raio}</Td>
              <Td>{aspersor2Bocal}</Td>
              <Td>{aspersor2Vazao}</Td>
              <Td>{aspersor2Quantidade}</Td>
            </tr>
          )}
        </Table>
      </Quadro>
    </Card>
  );
}

export default AspersorFinal;
