import { ReactNode, useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Button,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';

import { Link, useHistory } from 'react-router-dom';
import { Avatar, Checkbox, FormControlLabel } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { FaPlusCircle } from 'react-icons/fa';
import getInitials from '../../../utils/getInitials';

import Usuario from '../../../models/Usuario';

import api from '../../../services/api';

const Usuarios: ReactNode = () => {
  const [usuarios, setUsuarios] = useState([] as Usuario[]);
  const [mostrarDesativados, setMostrarDesativados] = useState(false);

  const history = useHistory();

  async function carregaUsuarios() {
    const response = await api.get('/usuario');

    setUsuarios(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaUsuarios();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="10">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: '/admin/cadastro-usuario',
                    });
                  }}
                >
                  <FaPlusCircle />
                  <span className="btn-inner--text">Novo</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Row>
                <Col sm="9" />
                <Col sm="3">
                  <FormControlLabel
                    className="mt-4 float-right"
                    control={
                      <Checkbox
                        checked={mostrarDesativados}
                        onChange={event =>
                          setMostrarDesativados(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Mostrar desativados"
                  />
                </Col>
              </Row>
              <CardHeader className="border-0">
                <h3 className="mb-0">Usuários</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  {usuarios
                    .filter(item =>
                      !mostrarDesativados
                        ? item.isAtivo === true
                        : item.isAtivo === false,
                    )
                    .map(usuario => {
                      let styleDesativado = {};
                      if (usuario.isAtivo) {
                        styleDesativado = {
                          marginRight: '0.8rem',
                          color: 'unset',
                        };
                      } else {
                        styleDesativado = {
                          backgroundColor: '#d1cfcf',
                          color: '#A9A9A9',
                          textDecoration: 'line-through',
                        };
                      }
                      return (
                        <tr key={usuario.id}>
                          <th scope="row">
                            <Link
                              style={{ ...styleDesativado }}
                              to={{
                                pathname: `/admin/cadastro-usuario`,
                                state: { usuario },
                              }}
                            >
                              <Media className="align-items-center">
                                <div className="avatar-group">
                                  <Avatar
                                    src={usuario?.imagem}
                                    style={{ backgroundColor: deepPurple[800] }}
                                  >
                                    {getInitials(usuario.nome)}
                                  </Avatar>
                                </div>

                                <span className="mb-0 ml-3 text-sm">
                                  {usuario.nome}
                                  <br />
                                  <small>
                                    {usuario.usernameDomSGE
                                      ? `Username DomSGE: ${usuario.usernameDomSGE}`
                                      : ``}
                                  </small>
                                </span>
                              </Media>
                            </Link>
                          </th>
                          <td>
                            <strong>{usuario.email}</strong>
                          </td>
                          <td>{usuario?.apelido}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Usuarios;
