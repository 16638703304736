import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

// reactstrap components
import { Card, CardHeader, Container, Row } from 'reactstrap';
// core components
import Swal from 'sweetalert2';
import Header from 'components/Headers/Header.js';
// eslint-disable-next-line import/no-unresolved
import CustomTable from 'components/CustomTable';

import api from 'services/api';

import AspersorModel from '../../../../models/ConfiguradorAspersor';

interface CustomTableList {
  id: string;
  texto: string;
}

const ConfiguradorModelos: React.FC = () => {
  const [aspersores, setAspersores] = useState([] as any[]);
  const [counter, setCounter] = useState(0);

  const indicBombaReforcoList: CustomTableList[] = [
    {
      id: 'S',
      texto: 'Sim',
    },
    {
      id: 'N',
      texto: 'Não',
    },
  ];

  const header: any[] = [
    {
      name: 'Código',
      prop: 'seq',
      type: 'LABEL',
      searchable: true,
      sortable: true,
      editable: false,
      required: false,
    },
    {
      name: 'Nome',
      prop: 'nome',
      type: 'TEXT',
      sortable: true,
      editable: true,
      required: true,
      searchable: true,
    },
    {
      name: 'Bomba Reforço',
      prop: 'indicReforco',
      type: 'SELECT',
      searchable: true,
      sortable: true,
      editable: true,
      required: true,
      values: indicBombaReforcoList,
    },
  ];

  async function handleDelete(row: any) {
    const delAspersor = row.columns.filter((col: any) => col.prop === 'seq')[0]
      .value;
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Aspersor?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/integracao/aspersores/${delAspersor}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setAspersores(aspersores.filter(item => item.id !== row.id));
        }
      });
    } else {
      setAspersores(aspersores.filter(item => item.id !== row.id));
    }
  }

  async function handleSave(row: any) {
    if (row.isNew) {
      const objCriado: any = await api.post(`/integracao/aspersores`, {
        nome: row.columns.filter((col: any) => col.prop === 'nome')[0].newValue,
        tipo: 'F',
        indicBombaReforco: row.columns.filter(
          (col: any) => col.prop === 'indicReforco',
        )[0].newValue.id,
      });

      setAspersores(
        aspersores.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            // eslint-disable-next-line no-param-reassign
            item.columns = [
              {
                prop: 'seq',
                value: objCriado.data.id,
                newValue: objCriado.data.id,
              },
              {
                prop: 'nome',
                value: objCriado.data.nome,
                newValue: objCriado.data.nome,
              },
              {
                prop: 'indicReforco',
                value: {
                  id: objCriado.data.indicBombaReforco,
                  texto: indicBombaReforcoList.filter(
                    (it: any) => it.id === objCriado.data.indicBombaReforco,
                  )[0].texto,
                },
                newValue: {
                  id: objCriado.data.indicBombaReforco,
                  texto: indicBombaReforcoList.filter(
                    (it: any) => it.id === objCriado.data.indicBombaReforco,
                  )[0].texto,
                },
              },
            ];
          }

          return item;
        }),
      );
    } else {
      const upAspersor = row.columns.filter((col: any) => col.prop === 'seq')[0]
        .value;

      await api.put(`/integracao/aspersores/${upAspersor}`, {
        nome: row.columns.filter((col: any) => col.prop === 'nome')[0].newValue,
        tipo: 'F',
        indicBombaReforco: row.columns.filter(
          (col: any) => col.prop === 'indicReforco',
        )[0].newValue.id,
      });

      setAspersores(
        aspersores.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );
    }

    toast.success('Registro salvo');
  }

  function handleStartEditing(row: any) {
    setAspersores(
      aspersores.map(item => {
        if (item.id === row.id) {
          // eslint-disable-next-line no-param-reassign
          item.isEditing = true;
        }
        return item;
      }),
    );
  }

  function handleStopEditing(row: any) {
    if (row.isNew) {
      setAspersores(aspersores.filter(item => item.id !== row.id));
    } else {
      setAspersores(
        aspersores.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
          }
          return item;
        }),
      );
    }
  }

  function handleAddRow() {
    const count = counter + 1;

    setAspersores([
      {
        id: count,
        columns: [
          { prop: 'seq', value: '', newValue: '' },
          { prop: 'nome', value: '', newValue: '' },
          {
            prop: 'indicReforco',
            value: { id: '', texto: null },
            newValue: { id: '', texto: null },
          },
        ],
        isNew: true,
        isEditing: true,
      },
      ...aspersores,
    ]);

    setCounter(count);
  }

  async function listaAspersores() {
    const response = await api.get('/integracao/aspersores');

    const lista: AspersorModel[] = response.data;

    const rows: any[] = [];

    let count = counter;
    // eslint-disable-next-line array-callback-return
    lista
      .filter(item => item.tipo === 'F')
      .map(item => {
        rows.push({
          id: count,
          columns: [
            {
              prop: 'seq',
              value: item.id,
              newValue: item.id,
            },
            {
              prop: 'nome',
              value: item.nome,
              newValue: item.nome,
            },
            {
              prop: 'indicReforco',
              value: {
                id: item.indicBombaReforco,
                texto:
                  indicBombaReforcoList.filter(
                    it => it.id === item.indicBombaReforco,
                  )[0].texto ?? '',
              },
              newValue: {
                id: item.indicBombaReforco,
                texto:
                  indicBombaReforcoList.filter(
                    it => it.id === item.indicBombaReforco,
                  )[0].texto ?? '',
              },
            },
          ],
          isNew: false,
          isEditing: false,
        });
        // eslint-disable-next-line no-plusplus
        count++;
        return rows;
      });

    setAspersores(rows);

    setCounter(count);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaAspersores();
  }, []);

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Aspersores Finais</h3>
              </CardHeader>
              <CustomTable
                enableSearchField
                rows={aspersores}
                header={header}
                onDelete={handleDelete}
                onSave={handleSave}
                onAddRow={handleAddRow}
                onStartEditing={handleStartEditing}
                onStopEditing={handleStopEditing}
              />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorModelos;
