import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const LabelButtonCarregarImagem = styled.label``;

export const ButtonCarregarImagem = styled(Button).attrs({
  variant: 'outlined',
  component: 'span',
})`
  text-transform: none;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  color: #212529;
  font-weight: 600;
  background-color: #f7fafc;
  border-color: #f7fafc;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.625rem 1.25rem;
  line-height: 1.5;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  border: 1px solid transparent;
  border-radius: 0.375rem;
`;
