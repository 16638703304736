import FichaTecnica from 'models/FichaTecnica';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Col, Row, Table, CardBody } from 'reactstrap';
import api from 'services/api';
import Material from 'models/Material';
import TipoBombaData from 'staticData/TipoBombaData';

import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';

import Swal from 'sweetalert2';
import { CalculoAdutora } from 'models/AdutoraCalculo';
import { FichaTecnicaAdutora } from 'models/FichaTecnicaAdutora';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import { toast } from 'react-hot-toast';
import { FaCheckCircle } from 'react-icons/fa';
import { FichaTecnicaTubulacao } from 'models/FichaTecnicaTubulacao';
import { FichaTecnicaBombeamento } from 'models/FichaTecnicaBombeamento';
import { responsiveMap } from 'antd/lib/_util/responsiveObserve';
import FichaTecnicaNecessidadeReprocessamento from 'models/FichaTecnicaNecessidadeReprocessamento';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { ContainerHeader, ContainerButtons, Td } from './styles';
import TubulacaoAdutora from './components/TubulacaoAdutora';
import { AdutoraBombeamentoContext } from '../..';
import Reprocessamento from '../Reprocessamento';

interface PerfilAdutora {
  sessao: string;
  cotaInicial: number;
  cotaFinal: number;
  pressaoInicio: number;
  pressaoFinal: number;
  classe: string;
  ordem: number;
  diametroNominal: number;
}

interface TipoBombaStatic {
  tipo: string;
  valoresCombo: number[];
}

function Adutora(): JSX.Element {
  const {
    // ovidio
    setVazaoPrevista,
    setPressaoPrevista,
    setBloquearConclusaoPorReprocessamentoCfg,

    bomba,
    setBomba,
    quantidadeBomba,
    setQuantidadeBomba,
    quantidadeVentosa,
    curvasToSave,
    totalCurvasEncontradas,
    fichaTecnicaTubulacao,
    setFichaTecnicaTubulacao,
    setQuantidadeModulos,
    quantidadeModulos,
    criarPrimeiroTrecho,
    setCriarPrimeiroTrecho,
    podeCalcularAdutora,
    setPodeCalcularAdutora,
    fichaTecnica,
    fichaTecnicaId,
    orcamentoId,
    sessaoId,
    necessidadeReprocessamento,
    setNecessidadeReprocessamento,
    setBloquearConclusaoPorReprocessamento,
    necessidadeReprocessamentoData,
    listaNecessidadeReprocessamento,
    setAtualizarDadosAdutora,
    atualizarDadosAdutora,
  } = useContext(AdutoraBombeamentoContext);

  const [tipoCota, setTipoCota] = useState('');
  const [tipoCotaParaTubulacao, setTipoCotaParaTubulacao] = useState('');

  const [bombeamentoMestre, setBombeamentoMestre] = useState('');
  const [perdaCarga, setPerdaCarga] = useState(0);
  const [perfisAdutora, setPerfisAdutora] = useState<PerfilAdutora[]>([]);
  const [loadingCalculoAdutora, setLoadingCalculoAdutora] = useState(false);
  const [loadingSaveAdutora, setLoadingSaveAdutora] = useState(false);
  const [fichaTecnicaAdutora, setFichaTecnicaAdutora] = useState<
    FichaTecnicaAdutora | undefined
  >(fichaTecnica?.FichaTecnicaAdutora);

  const [bloquearPerdaCarga, setBloquearPerdaCarga] = useState(false);
  const [tipoBombaList, setTipoBombaList] = useState<TipoBombaStatic[]>([]);
  const [qtdeBombaList, setQtdeBombaList] = useState<number[]>([]);

  const [isAdutoraCalculada, setIsAdutoraCalculada] = useState(false);
  const [atualizarTrechos, setAtualizarTrechos] = useState(false);
  const [totaisBombeamento, setTotaisBombeamento] = useState(
    {} as FichaTecnicaBombeamento,
  );
  const [acessoPrograma, setAcessoPrograma] = useState(
    {} as ValidarAcessoPrograma,
  );
  const [exibeCotas, setExibeCotas] = useState(false);
  const [exibeTotais, setExibeTotais] = useState(false);
  const [saldoAdutora, setSaldoAdutora] = useState(0);
  const [novoTrecho, setNovoTrecho] = useState(false);
  const [exibeTrechos, setExibeTrechos] = useState(false);

  const [necessarioAtualizar, setNecessarioAtualizar] = useState(false);
  const [atualizarAdutora, setAtualizarAdutora] = useState(false);

  const isNecessidadeReprocessamento =
    necessidadeReprocessamento?.reprocessarAdutora ?? false;

  const isNecessidadeReprocessamentoTrechos =
    necessidadeReprocessamento?.reprocessarTrechos ?? false;
  async function alterarBomba(varBomba: string) {
    setBomba(varBomba);

    const tipoFiltrado = tipoBombaList.find(
      tipo => tipo.tipo.toUpperCase() === varBomba.toUpperCase(),
    );
    if (tipoFiltrado && tipoFiltrado?.valoresCombo.length > 0)
      setQtdeBombaList(tipoFiltrado?.valoresCombo);

    setQuantidadeBomba(0);
  }

  async function getAcessos() {
    const response = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 77,
      },
    });
    setAcessoPrograma(response.data);
  }

  useEffect(() => {
    setTipoBombaList(TipoBombaData);
  }, []);

  useEffect(() => {
    getAcessos();
  }, []);

  useEffect(() => {
    // Atualizar componente
  }, [necessidadeReprocessamento]);

  async function carregaTrechosAdutora() {
    const response = await api.get('/integracao/adutora/orcamento', {
      params: {
        sessao: sessaoId,
        fichaTecnicaId,
        orcamentoId,
      },
    });

    setPerfisAdutora(response.data);
    if (!response.data[0]) {
      setExibeTotais(false);
    }
  }

  async function carregaFichaTecnicaAdutora() {
    const response = await api.get(
      `/ficha-tecnica-adutora/ficha-tecnica/${fichaTecnica.id}`,
    );
    setFichaTecnicaAdutora(response.data);
  }

  async function carregarRespostas() {
    const response = await api.get(
      `/orcamento/resposta-funcao/?orcamentoVersaoId=${orcamentoId}`,
    );

    if (response.data) {
      const { desnivelCaptacaoCentroPivo } = response.data;

      if (desnivelCaptacaoCentroPivo) {
        setPerdaCarga(desnivelCaptacaoCentroPivo > 0 ? 10 : 20);
      }
    }
  }

  useEffect(() => {
    if (atualizarDadosAdutora === true) {
      carregaFichaTecnicaAdutora();
      setAtualizarAdutora(true);
      setNecessarioAtualizar(true);
      setAtualizarDadosAdutora(false);
    }
  }, [atualizarDadosAdutora]);

  useEffect(() => {
    listaNecessidadeReprocessamento();
    if (loadingSaveAdutora) {
      toast.loading('Aguarde...', { position: 'top-right' });
      return;
    }

    toast.dismiss();
  }, [loadingSaveAdutora]);

  async function atualizarBombeamento() {
    const response = await api.get(
      `/integracao/bombeamento/calculo/${fichaTecnica.id}`,
    );

    setTotaisBombeamento(response.data[0]);
  }

  useEffect(() => {
    if (totaisBombeamento?.vazaoPrevista && fichaTecnicaAdutora?.pressaoTotal) {
      setVazaoPrevista(totaisBombeamento.vazaoPrevista);
      setPressaoPrevista(fichaTecnicaAdutora?.pressaoTotal);
    }
  }, [totaisBombeamento, fichaTecnicaAdutora]);

  useEffect(() => {
    if (fichaTecnica?.id) {
      atualizarBombeamento();
      // setTotaisBombeamento(Number(fichaTecnica.vazaoTotalSAF));
    }
  }, [fichaTecnica.id]);

  useEffect(() => {
    if (tipoCotaParaTubulacao !== '') {
      setExibeTotais(true);
    }
  }, [tipoCotaParaTubulacao]);

  async function handleCalcularAdutoraTotal() {
    const isCamposPreenchidos =
      tipoCota &&
      bombeamentoMestre &&
      bomba &&
      quantidadeBomba &&
      quantidadeModulos;
    if (!isCamposPreenchidos) {
      Swal.fire({
        icon: 'error',
        text: `Algum campo da adutora não foi respondido!`,
      });
      return;
    }

    let quantidadeCurvas5 = 0;
    let quantidadeCurvas10 = 0;
    let quantidadeCurvas15 = 0;
    let quantidadeCurvas30 = 0;
    let quantidadeCurvas45 = 0;
    let quantidadeCurvas60 = 0;
    let quantidadeCurvas90 = 0;
    const quantidadeCurvas = totalCurvasEncontradas || 0;
    if (curvasToSave) {
      const curvas: string[] = curvasToSave.replaceAll('°', '').split(',');

      quantidadeCurvas5 = curvas.filter(item => item === '5').length || 0;
      quantidadeCurvas10 = curvas.filter(item => item === '10').length || 0;
      quantidadeCurvas15 = curvas.filter(item => item === '15').length || 0;
      quantidadeCurvas30 = curvas.filter(item => item === '30').length || 0;
      quantidadeCurvas45 = curvas.filter(item => item === '45').length || 0;
      quantidadeCurvas60 = curvas.filter(item => item === '60').length || 0;
      quantidadeCurvas90 = curvas.filter(item => item === '90').length || 0;
    }

    try {
      setLoadingCalculoAdutora(true);
      await api.post('/integracao/adutora/orcamento/calculo', {
        orcamentoId,
        fichaTecnicaId,
        tipoCota,
        bombeamentoMestre,
        percentualPerdaCarga: perdaCarga,
        bomba,
        quantidadeBomba,
        tipoExecucao: 'G',
        quantidadeVentosa,
        quantidadeCurvas5,
        quantidadeCurvas10,
        quantidadeCurvas15,
        quantidadeCurvas30,
        quantidadeCurvas45,
        quantidadeCurvas60,
        quantidadeCurvas90,
        quantidadeCurvas,
        quantidadeModulos,
      });
      carregaFichaTecnicaAdutora();
      setAtualizarTrechos(true);
      setPodeCalcularAdutora(true);
      const response = await api.get(
        `/ficha-tecnica/${fichaTecnicaId}/necessidade-reprocessamento`,
      );
      setNecessidadeReprocessamento({
        reprocessarTrechos: response.data?.reprocessarTrechos,
        reprocessarAdutora: response.data?.reprocessarAdutora,
        reprocessarBombeamento: response.data?.reprocessarBombeamento,
        reprocessarSuccao: response.data?.reprocessarSuccao,
      } as FichaTecnicaNecessidadeReprocessamento);
      necessidadeReprocessamentoData({
        reprocessarTrechos: response.data?.reprocessarTrechos,
        reprocessarAdutora: response.data?.reprocessarAdutora,
        reprocessarBombeamento: response.data?.reprocessarBombeamento,
        reprocessarSuccao: response.data?.reprocessarSuccao,
      } as FichaTecnicaNecessidadeReprocessamento);
      toast.success('Cálculo finalizado!', { position: 'top-right' });
    } finally {
      setLoadingCalculoAdutora(false);
      carregaTrechosAdutora();
      await atualizarBombeamento();
    }
    setExibeTotais(true);
  }

  async function handleCalcularAdutora() {
    carregaTrechosAdutora();
  }

  async function salvarAdutora() {
    const isCamposPreenchidos =
      tipoCota &&
      bombeamentoMestre &&
      bomba &&
      quantidadeBomba &&
      quantidadeModulos;
    if (!isCamposPreenchidos) {
      Swal.fire({
        icon: 'error',
        text: `Algum campo da adutora não foi respondido!`,
      });
      return;
    }

    let quantidadeCurvas5 = 0;
    let quantidadeCurvas10 = 0;
    let quantidadeCurvas15 = 0;
    let quantidadeCurvas30 = 0;
    let quantidadeCurvas45 = 0;
    let quantidadeCurvas60 = 0;
    let quantidadeCurvas90 = 0;
    const quantidadeCurvas = totalCurvasEncontradas || 0;
    if (curvasToSave) {
      const curvas: string[] = curvasToSave.replaceAll('°', '').split(',');

      quantidadeCurvas5 = curvas.filter(item => item === '5').length || 0;
      quantidadeCurvas10 = curvas.filter(item => item === '10').length || 0;
      quantidadeCurvas15 = curvas.filter(item => item === '15').length || 0;
      quantidadeCurvas30 = curvas.filter(item => item === '30').length || 0;
      quantidadeCurvas45 = curvas.filter(item => item === '45').length || 0;
      quantidadeCurvas60 = curvas.filter(item => item === '60').length || 0;
      quantidadeCurvas90 = curvas.filter(item => item === '90').length || 0;
    }

    try {
      setLoadingSaveAdutora(true);

      const retorno = await api.post('/integracao/adutora/orcamento/salvar', {
        orcamentoId,
        fichaTecnicaId,
        tipoCota,
        bombeamentoMestre,
        percentualPerdaCarga: perdaCarga,
        bomba,
        quantidadeBomba,
        tipoExecucao: 'T',
        quantidadeVentosa,
        quantidadeCurvas5,
        quantidadeCurvas10,
        quantidadeCurvas15,
        quantidadeCurvas30,
        quantidadeCurvas45,
        quantidadeCurvas60,
        quantidadeCurvas90,
        quantidadeCurvas,
        quantidadeModulos,
        limparCampos: tipoCota !== tipoCotaParaTubulacao,
      });

      if (retorno.data)
        setFichaTecnicaTubulacao(retorno.data as FichaTecnicaTubulacao);

      await atualizarBombeamento();
      setCriarPrimeiroTrecho(true);
      setIsAdutoraCalculada(true);

      if (tipoCota !== tipoCotaParaTubulacao) {
        setAtualizarTrechos(true);
        carregaTrechosAdutora();
      }
      carregaFichaTecnicaAdutora();

      setTipoCotaParaTubulacao(tipoCota);

      if (isCamposPreenchidos && novoTrecho) {
        necessidadeReprocessamentoData({
          ...necessidadeReprocessamento,
          reprocessarTrechos: true,
          reprocessarAdutora: false,
          reprocessarBombeamento: true,
          reprocessarSuccao: true,
        } as FichaTecnicaNecessidadeReprocessamento);
        setExibeTrechos(true);
      }

      setNecessidadeReprocessamento({
        ...necessidadeReprocessamento,
        reprocessarTrechos: true,
        reprocessarAdutora: false,
        reprocessarBombeamento: true,
        reprocessarSuccao: true,
      } as FichaTecnicaNecessidadeReprocessamento);
    } finally {
      setLoadingSaveAdutora(false);
    }
    setExibeTotais(true);
    setNecessarioAtualizar(false);
    setAtualizarAdutora(false);
    setBloquearConclusaoPorReprocessamentoCfg(false);
  }

  async function setaDadosFichaTecnicaAdutora() {
    if (!fichaTecnicaAdutora) {
      setExibeTotais(false);
      return;
    }
    if (fichaTecnicaAdutora.tipoCota && fichaTecnicaAdutora.bomba) {
      setIsAdutoraCalculada(true);
    }

    const bloquearCampoPerda =
      fichaTecnicaAdutora.bloquearCampoPerdaCarga ?? false;

    let bloquearPerda = false;

    if (bloquearCampoPerda) {
      if (!acessoPrograma || acessoPrograma.temAcesso === false)
        bloquearPerda = true;
    }

    if (necessidadeReprocessamento?.reprocessarAdutora === true) {
      setNecessarioAtualizar(true);
    }
    setBloquearPerdaCarga(bloquearPerda);

    setTipoCota(fichaTecnicaAdutora.tipoCota || '');
    setTipoCotaParaTubulacao(fichaTecnicaAdutora.tipoCota || '');
    setBombeamentoMestre(fichaTecnicaAdutora.bombeamentoMestre || '');
    setPerdaCarga(fichaTecnicaAdutora.percentualPerdaCarga || 0);
    const varBomba = fichaTecnicaAdutora.bomba || '';
    setBomba(varBomba);
    const tipoFiltrado = TipoBombaData.find(tipo => tipo.tipo === varBomba);

    if (tipoFiltrado && tipoFiltrado?.valoresCombo.length > 0) {
      setQtdeBombaList(tipoFiltrado?.valoresCombo);

      setQuantidadeBomba(fichaTecnicaAdutora.quantidadeBomba || 0);
    }
  }
  useEffect(() => {
    if (
      orcamentoId &&
      (!fichaTecnicaAdutora || !fichaTecnicaAdutora.percentualPerdaCarga)
    )
      carregarRespostas();
    setaDadosFichaTecnicaAdutora();
  }, [fichaTecnicaAdutora, orcamentoId]);

  useEffect(() => {
    if (saldoAdutora > 0 || necessidadeReprocessamento) {
      setBloquearConclusaoPorReprocessamento(true);
    }
  }, []);

  useEffect(() => {
    if (fichaTecnica) {
      setFichaTecnicaAdutora(fichaTecnica?.FichaTecnicaAdutora);
      carregaTrechosAdutora();
    }
  }, [fichaTecnica]);

  useEffect(() => {
    if (necessarioAtualizar) {
      setBloquearConclusaoPorReprocessamento(true);
      setBloquearConclusaoPorReprocessamentoCfg(true);
      necessidadeReprocessamentoData({
        ...necessidadeReprocessamento,
        reprocessarTrechos: true,
        reprocessarAdutora: true,
        reprocessarBombeamento: true,
        reprocessarSuccao: true,
      } as FichaTecnicaNecessidadeReprocessamento);
    }
  }, [necessarioAtualizar]);

  useEffect(() => {
    if (necessarioAtualizar) {
      // salvarAdutora();
      setAtualizarAdutora(false);
    }
  }, [atualizarAdutora]);

  return (
    <Card>
      <Row className="mt--3">
        <ColQuestaoTitulo>
          <LabelQuestaoTitulo>Adutora</LabelQuestaoTitulo>
        </ColQuestaoTitulo>
      </Row>

      <Card className="shadow">
        <Row>
          <Col className="mt-2 ml-2 mb-2 mr--2">
            <Row>
              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Tipo cota</InputLabel>
                  <Select
                    value={tipoCota}
                    label="Tipo cota"
                    onChange={event =>
                      setTipoCota(event.target.value as string)
                    }
                  >
                    <MenuItem value="Linear" disabled>
                      Linear
                    </MenuItem>
                    <MenuItem value="Específica">Específica</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Bombeamento mestre</InputLabel>
                  <Select
                    value={bombeamentoMestre}
                    label="Bombeamento mestre"
                    onChange={event =>
                      setBombeamentoMestre(event.target.value as string)
                    }
                  >
                    <MenuItem value="Não" selected>
                      Não
                    </MenuItem>
                    {/* <MenuItem value="Sim">Sim</MenuItem> */}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Bomba</InputLabel>
                  <Select
                    value={bomba}
                    label="Bomba"
                    autoWidth
                    onChange={event => {
                      alterarBomba(event.target.value as string);
                      setNecessarioAtualizar(true);
                    }}
                  >
                    {tipoBombaList.map(tipo => (
                      <MenuItem key={tipo.tipo} value={tipo.tipo}>
                        {tipo.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <TextField
                  size="small"
                  type="number"
                  InputProps={{
                    style: { fontSize: 14 },
                    inputProps: { max: 100 },
                  }}
                  disabled={bloquearPerdaCarga}
                  label="Perda carga (%)"
                  value={perdaCarga}
                  onChange={event => {
                    setPerdaCarga(Number(event.target.value) ?? 0);
                    setNecessarioAtualizar(true);
                    setAtualizarAdutora(true);
                  }}
                />
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Qtde bomba</InputLabel>
                  <Select
                    value={quantidadeBomba}
                    label="Qtde bomba"
                    autoWidth
                    onChange={event => {
                      setQuantidadeBomba(event.target.value as number);
                      setNecessarioAtualizar(true);
                      setAtualizarAdutora(true);
                    }}
                  >
                    {qtdeBombaList.map(tipo => (
                      <MenuItem key={tipo} value={tipo}>
                        {tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="1">
                <TextField
                  size="small"
                  label="Qtde módulos"
                  type="number"
                  value={quantidadeModulos}
                  onChange={event => {
                    setQuantidadeModulos(Number(event.target.value) ?? 0);
                    setNecessarioAtualizar(true);
                    setAtualizarAdutora(true);
                  }}
                />
              </Col>
              <Col sm="1">
                <div className="pull-right float-right mr-4 mt-4">
                  <Button
                    disabled={loadingSaveAdutora}
                    className="btn-iconmr-0 btn-4"
                    color={necessarioAtualizar ? 'warning' : 'primary'}
                    type="button"
                    size="sm"
                    title="Atualizar dados"
                    onClick={() => salvarAdutora()}
                  >
                    <FaCheckCircle />
                    {necessarioAtualizar ? 'Reprocessar' : ''}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card className="shadow pr-3" hidden={isAdutoraCalculada}>
        <Row>
          <Col sm="10" className="mt-2">
            Preencha e salve os campos de adutora para poder preencher os
            trechos.
          </Col>
        </Row>
      </Card>

      <Card className="shadow pr-3" hidden={!isAdutoraCalculada}>
        <Row>
          <Col className="mt-2 ml-2 mb-2 mr--2">
            <TubulacaoAdutora
              fichaTecnica={fichaTecnica}
              fichaTecnicaAdutora={fichaTecnicaAdutora}
              tipoCota={tipoCotaParaTubulacao}
              orcamentoId={orcamentoId}
              sessaoId={sessaoId}
              handleCalcularAdutora={handleCalcularAdutora}
              handleCalcularAdutoraTotal={handleCalcularAdutoraTotal}
              atualizarTrechos={atualizarTrechos}
              setAtualizarTrechos={setAtualizarTrechos}
              exibeCotas={exibeCotas}
              setExibeTotais={setExibeTotais}
              setSaldoAdutora={setSaldoAdutora}
              setNovoTrecho={setNovoTrecho}
              setExibeTrechos={setExibeTrechos}
              exibeTrechos={exibeTrechos}
            />
          </Col>
        </Row>

        <ContainerButtons>
          <Button
            disabled={
              loadingCalculoAdutora ||
              !podeCalcularAdutora ||
              isNecessidadeReprocessamentoTrechos
            }
            className="btn-icon btn-2"
            color="danger"
            type="button"
            onClick={handleCalcularAdutoraTotal}
            size="sm"
          >
            {loadingCalculoAdutora
              ? 'Calculando adutora...'
              : 'Calcular adutora'}
          </Button>
          {isNecessidadeReprocessamento && <Reprocessamento />}
        </ContainerButtons>

        <Row>
          <Col className="mt-2 ml-2 mb-2 mr--2">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>Trecho</th>
                  <th>Cota inicial</th>
                  <th>Cota final</th>
                  <th>Diâmetro Nominal</th>
                  <th>Pressão no início</th>
                  <th>Pressão no final</th>
                  <th>Classe</th>
                </tr>
              </thead>
              <tbody>
                {perfisAdutora.map(perfilAdutora => (
                  <tr key={perfilAdutora.ordem}>
                    <Td>{perfilAdutora.ordem}º</Td>
                    <Td>{perfilAdutora.cotaInicial}</Td>
                    <Td>{perfilAdutora.cotaFinal}</Td>
                    <Td>{perfilAdutora.diametroNominal}</Td>
                    <Td>{perfilAdutora.pressaoInicio}</Td>
                    <Td>{perfilAdutora.pressaoFinal}</Td>
                    <Td>{perfilAdutora.classe}</Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {exibeTotais && !isNecessidadeReprocessamento ? (
          <Row>
            <Col className="mt-2 ml-2 mb-2 mr--2">
              <Table
                bordered
                responsive
                style={{ width: '50%', margin: 'auto' }}
              >
                <thead>
                  <tr>
                    <th>Vazão Total</th>
                    <th>Pressão Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Td>{totaisBombeamento?.vazaoPrevista ?? ''}&nbsp;m³/h</Td>
                    <Td>{fichaTecnicaAdutora?.pressaoTotal ?? ''}&nbsp;mca</Td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : null}
      </Card>
    </Card>
  );
}

export default Adutora;
