import { Row, Card, CardBody, Col } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';

import FichaTecnica from 'models/FichaTecnica';

import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import ATTSElevadorTensao from './components/ATTSElevadorTensao';
import TTSRebaixadorTensao from './components/TTSRebaixadorTensao';
import { Container } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function AutotransformadoresTransformadores(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const { potenciaCalculada } = fichaTecnica;

  return (
    <Container>
      <Card className="shadow mt-2">
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>
                  Autotransformadores (ATTS) e Transformadores (TTS)
                </LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody className="p-3">
              <Row>
                <Campo
                  label="Potência Calculada"
                  valor={potenciaCalculada}
                  unidade="kVA"
                />
              </Row>

              <Row>
                <Col sm="6">
                  <ATTSElevadorTensao fichaTecnica={fichaTecnica} />
                </Col>

                <Col sm="6">
                  <TTSRebaixadorTensao fichaTecnica={fichaTecnica} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Container>
  );
}

export default AutotransformadoresTransformadores;
