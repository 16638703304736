import { useState, useEffect, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Tooltip,
} from 'reactstrap';
import toast from 'react-hot-toast';

import Header from 'components/Headers/Header.js';

import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import { FaEdit, FaPlusCircle, FaTimesCircle, FaCopy } from 'react-icons/fa';

import Swal from 'sweetalert2';

import Perfil from 'models/Perfil';
import { Toast, ToastAsync } from 'components/Toast';
import AcessoPrograma from 'models/AcessoPrograma';

import api from '../../../services/api';

import {
  PerfilListContainer,
  ProgramaListContainer,
  PerfilRow,
  NewProgramaRow,
  ProgramaRow,
} from './styles';

const Perfis: ReactNode = () => {
  const [nomeNovoPerfil, setNomeNovoPerfil] = useState('');
  const [descricaoNovoPerfil, setDescricaoNovoPerfil] = useState('');
  const history = useHistory();

  const [perfis, setPerfis] = useState([] as Perfil[]);
  const [acessoProgramas, setAcessoProgramas] = useState(
    [] as AcessoPrograma[],
  );

  async function carregaPerfis() {
    const response = await api.get('/perfil');

    setPerfis(response.data);
  }

  async function carregaAcessoPrograma() {
    const response = await api.get('/acesso/programa');

    setAcessoProgramas(response.data);
  }

  function handleNavigateProgramas(perfil: Perfil) {
    history.push({
      pathname: `/admin/perfil-programa`,
      state: {
        perfisPrograma: perfil,
      },
    });
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaPerfis();
    carregaAcessoPrograma();
  }, []);

  async function duplicaPerfil(perfil: Perfil) {
    async function duplicar() {
      const retorno = await api.post(`/perfil/${perfil.id}/duplicar`, {});
      setPerfis(perf => [retorno.data, ...perf]);
      carregaAcessoPrograma();
    }

    Swal.fire({
      title: `Deseja duplicar as informações do perfil #${perfil.nome} em um novo registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, duplicar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        toast.promise(duplicar(), {
          loading: 'Aguarde...',
          success: <b>Tudo certo!</b>,
          error: <b>Algo de errado aconteceu.</b>,
        });
      }
    });
  }

  async function handleAdicionaNovoPerfil() {
    if (nomeNovoPerfil !== '') {
      Swal.fire({
        icon: 'info',
        text: `Criando novo perfil...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const response = await api.post('/perfil', {
        nome: nomeNovoPerfil,
        descricao: descricaoNovoPerfil,
      });

      setPerfis(perfil => [response.data, ...perfil]);

      setNomeNovoPerfil('');
      setDescricaoNovoPerfil('');

      Swal.close();
    } else {
      toast.error('O nome do perfil deve ser preenchido!');
    }
  }

  async function handleDeletaPerfil(perfil: Perfil) {
    ToastAsync.fire({
      icon: 'info',
      title: 'Excluindo...',
    });

    await api.delete(`/perfil/${perfil.id}`);

    Swal.close();

    Toast.fire({
      icon: 'success',
      title: 'Pronto!',
    });

    const novoArrayPerfis = perfis.filter(x => x !== perfil);

    setPerfis(novoArrayPerfis);
  }

  function questionaExclusaoPerfil(perfil: Perfil) {
    Swal.fire({
      title: `Excluir perfil ${perfil.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, excluir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(result => {
      if (result.isConfirmed) {
        handleDeletaPerfil(perfil);
      }
    });
  }

  function renderAcessoProgramas(perfil: Perfil) {
    const programasPerfil = acessoProgramas.filter(
      x => x.perfilId === perfil.id,
    );

    if (!programasPerfil.length) {
      return <Label>Nenhum programa associado.</Label>;
    }

    return programasPerfil.map(acessoPrograma => {
      const { programa } = acessoPrograma;

      return <h5 className="ml-5">• {programa.nome}</h5>;
    });
  }

  function renderListaPerfis(perfil: Perfil, itemPai = true) {
    const isOpen = perfis.find(x => x === perfil)?.isOpen;

    const display = perfis.find(x => x.id === perfil.id)?.display;

    function handleToggleAccordion() {
      setPerfis(
        perfis.map(x => (x.id === perfil.id ? { ...x, isOpen: !x.isOpen } : x)),
      );
    }

    function renderExpand() {
      return isOpen ? (
        <ExpandLess onClick={handleToggleAccordion} />
      ) : (
        <ExpandMore onClick={handleToggleAccordion} />
      );
    }

    return (
      <PerfilRow itemPai={itemPai} key={perfil.id}>
        <ListItem
          onMouseEnter={() => {
            setPerfis(
              perfis.map(x =>
                x.id === perfil.id
                  ? { ...x, display: true }
                  : { ...x, display: false },
              ),
            );
          }}
          onMouseLeave={() => {
            setPerfis(
              perfis.map(x =>
                x.id === perfil.id ? { ...x, display: false } : x,
              ),
            );
          }}
        >
          <Col sm="4">
            <h3 className="mb-0">{perfil.nome}</h3>
          </Col>

          <Col sm="4">
            <h4 className="mb-0">{perfil.descricao ?? ''}</h4>
          </Col>

          <Col sm="2" />

          <Col sm="2">
            <InputGroup style={{ justifyContent: 'flex-end' }}>
              {display && (
                <>
                  <Button
                    className="btn-icon mr-2"
                    size="sm"
                    color="info"
                    type="button"
                    onClick={() => duplicaPerfil(perfil)}
                  >
                    <FaCopy />
                  </Button>
                  <Button
                    className="btn-icon mr-2"
                    size="sm"
                    color="warning"
                    type="button"
                    onClick={() => handleNavigateProgramas(perfil)}
                  >
                    <FaEdit />
                  </Button>
                  <Button
                    size="sm"
                    className="btn-icon pb-0 pt-0 mr-4"
                    color="danger"
                    type="button"
                    onClick={() => questionaExclusaoPerfil(perfil)}
                  >
                    <FaTimesCircle />
                  </Button>
                </>
              )}
              {renderExpand()}
            </InputGroup>
          </Col>
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <ProgramaListContainer>
            {/* <NewProgramaRow>
              <Col sm="8">
                <h4>Programas</h4>
              </Col>
              <Col sm="4" />
            </NewProgramaRow> */}
            {renderAcessoProgramas(perfil)}
          </ProgramaListContainer>
        </Collapse>
      </PerfilRow>
    );
  }

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-tag" />
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  placeholder="Nome do perfil"
                  type="text"
                  value={nomeNovoPerfil ?? ''}
                  onChange={text => setNomeNovoPerfil(text.target.value)}
                />

                <Input
                  placeholder="Descrição"
                  type="text"
                  value={descricaoNovoPerfil ?? ''}
                  onChange={text => setDescricaoNovoPerfil(text.target.value)}
                />

                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleAdicionaNovoPerfil}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Card>
          <PerfilListContainer>
            {perfis.map(perfil => renderListaPerfis(perfil))}
          </PerfilListContainer>
        </Card>
      </Container>
    </>
  );
};

export default Perfis;
