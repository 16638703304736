import styled from 'styled-components';
import { Row, Label, Col } from 'reactstrap';

export const FuncaoRow = styled(Col)`
  padding-left: 1rem;
  font-weight: 800;
`;

export const FuncaoDescricao = styled(Label)`
  font-size: 16px;
`;

export const FuncaoValorSaida = styled(Label)`
  font-size: 12px;
  margin: -10px 0 0 3px;
  color: #c5c5c5;
`;

export const Entrada = styled(Row)`
  align-items: center;
`;

export const EntradaLabel = styled.label`
  font-size: 13px;
  font-weight: 600;
  margin: 4px 1rem;
`;
