import styled from 'styled-components';
import { Col, Row } from 'reactstrap';

export const PerfilRow = styled(Row)`
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  background-color: #fff;
`;

export const ButtonsRightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;
