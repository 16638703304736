import styled from 'styled-components';

interface TrProps {
  semPreco?: boolean;
}

export const Tr = styled.tr<TrProps>`
  background-color: ${props => (props.semPreco ? '#ffd5de' : 'unset')};
`;

export const Td = styled.td`
  padding-top: 0.5rem !important;
  padding-bottom: 0 !important;
`;

export const Descricao = styled.label`
  font-size: 0.75rem;
`;
