import { useContext, useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { addDays } from 'date-fns';

import api from 'services/api';
import { PropostaImpressaoContext } from '../..';
import { Container, Label, Typography } from './styles';

export default function CondicoesFornecimento(): JSX.Element {
  const { propostaVersao } = useContext(PropostaImpressaoContext);
  const [dataValidadeFormatada, setDataValidadeFormatada] = useState('');

  async function carregarParametros() {
    const response = await api.get('/parametros');
    let diasVigencia;
    if (response.data) {
      const statusValido = propostaVersao?.status?.id ?? 0;
      // Se estiver nos status 1 - Gerada ou 7 - Concluído pela Engenharia
      if ([1, 7].includes(statusValido)) {
        diasVigencia = response.data.validadeProposta;
        const dataValidade = addDays(
          new Date(propostaVersao.updatedAt),
          diasVigencia,
        );
        setDataValidadeFormatada(
          new Intl.DateTimeFormat('pt-BR').format(new Date(dataValidade)),
        );
      } else {
        setDataValidadeFormatada(
          propostaVersao.dataValidade
            ? new Intl.DateTimeFormat('pt-BR').format(
                new Date(String(propostaVersao.dataValidade)),
              )
            : '',
        );
      }
    }
  }

  useEffect(() => {
    carregarParametros();
  }, []);
  const data = [
    {
      id: 1,
      descricao: 'Imposto: IPI, ICMs : Inclusos conforme legislação Vigente.',
    },
    {
      id: 2,
      descricao:
        'Prazo previsto para entrega/instalação: conforme pedido de venda.',
    },
    {
      id: 3,
      descricao: `Validade da proposta até: ${dataValidadeFormatada}`,
    },
    {
      id: 4,
      descricao: `Tipo de frete: CIF`,
    },
  ];
  return (
    <Container>
      <Row>
        <Typography variant="h6" className="float-left mb-3">
          <strong>2 - CONDIÇÕES DE FORNECIMENTO:</strong>
        </Typography>
      </Row>
      <ul>
        {data.map(row => (
          <li key={row.id}>
            <Label>{row.descricao}</Label>
          </li>
        ))}
      </ul>
    </Container>
  );
}
