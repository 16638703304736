import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Label, Row, Table } from 'reactstrap';
import { FichaTecnicaBombeamento } from 'models/FichaTecnicaBombeamento';

import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';

import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import FichaTecnica from 'models/FichaTecnica';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import api from 'services/api';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { FaCheckCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { Td } from './styles';
import { AdutoraBombeamentoContext } from '../..';

interface iListas {
  atts: number[];
  attsPotencia: number[];
  caboEletrico: number[];
}
function Eletrica(): JSX.Element {
  const {
    sessaoId,
    fichaTecnicaBombeamento,
    fichaTecnica,
    setFichaTecnica,
    setFichaTecnicaBombeamento,
  } = useContext(AdutoraBombeamentoContext);
  const [mostrarConteudo, setMostrarConteudo] = useState(false);
  const [fichaBombeamento, setFichaBombeamento] =
    useState<FichaTecnicaBombeamento>();
  const [ficha, setFicha] = useState<FichaTecnica>();
  const [listas, setListas] = useState<iListas>();
  const [acessoPrograma, setAcessoPrograma] = useState(
    {} as ValidarAcessoPrograma,
  );

  const [novoAtts, setNovoAtts] = useState<number>();
  const [novoAttsPotencia, setNovoAttsPotencia] = useState<number>();
  const [novoCaboEletrico, setNovoCaboEletrico] = useState<number>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (fichaTecnicaBombeamento && fichaTecnica) {
      setNovoAtts(Number(fichaTecnicaBombeamento?.[0]?.tensaoNominal ?? 0));
      setNovoAttsPotencia(Number(fichaTecnica?.potenciaATTSElevador ?? 0));
      setNovoCaboEletrico(
        Number(fichaTecnicaBombeamento?.[0]?.bitolaCabo ?? 0),
      );
      setFichaBombeamento(fichaTecnicaBombeamento[0]);
      setFicha(fichaTecnica);
    }
  }, [fichaTecnicaBombeamento, fichaTecnica]);

  async function getAcessoPrograma() {
    const response = await api.get('/acesso-programa/acesso-programa', {
      params: {
        programaId: 67,
      },
    });
    setAcessoPrograma(response.data);
  }

  async function carregarCamposEletrica() {
    const response = await api.get('/integracao/eletrica/todas-listas');

    setListas(response.data as iListas);
  }

  useEffect(() => {
    getAcessoPrograma();
    carregarCamposEletrica();
  }, []);

  useEffect(() => {
    if (fichaBombeamento) {
      let mostrar = false;

      if (fichaBombeamento.bitolaCabo || fichaBombeamento.tensaoNominal)
        mostrar = true;

      setMostrarConteudo(mostrar);
    }
  }, [fichaBombeamento]);

  async function salvar() {
    let vaiSalvar = false;
    try {
      setIsSaving(true);

      const valor = await Swal.fire({
        title: `Atenção`,
        html: `<div style='text-align: justify!important; text-justify: inter-word;'>
          Ao salvar alterações dos campos <i>Diâmetro do Cabo</i>, <i>Potência</i> ou <i>Trafo</i>, o novo valor não vai refletir em cálculos já realizados. Para voltar aos valores originais, será necessário o reprocessamento dos lances e o cálculo de adutora/bombeamento. Deseja prosseguir?</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, prosseguir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      });

      vaiSalvar = valor.isConfirmed;
      if (valor.isConfirmed) {
        toast.loading('Salvando...');
        const retorno = await api.put(
          `/ficha-tecnica/${ficha?.id}/dados-eletrica`,
          {
            potencia: String(novoAttsPotencia),
            diametro: Number(novoCaboEletrico),
            trafo: Number(novoAtts),
          },
        );

        setFichaTecnica(retorno.data as FichaTecnica);
        setFichaTecnicaBombeamento(retorno.data.FichaTecnicaBombeamento);
      }
    } finally {
      toast.dismiss();
      if (vaiSalvar) toast.success('Alterações salvas');
      setIsSaving(false);
    }
  }

  return (
    <>
      <Card className="shadow">
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>Elétrica</LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>

        <Row hidden={mostrarConteudo}>
          <Col className="mt-2 pb-4">
            As informações serão mostradas após a execução do cálculo do
            bombeamento.
          </Col>
        </Row>

        <Row hidden={!mostrarConteudo}>
          <Col sm="2" className="mt-2 mb-2">
            <FormControl fullWidth>
              <InputLabel>Diâmetro do cabo alim. Pivô</InputLabel>
              <Select
                disabled={!acessoPrograma.temAcesso}
                value={novoCaboEletrico ?? ''}
                label="Diâmetro do cabo alim. Pivô"
                autoWidth
                onChange={event =>
                  setNovoCaboEletrico(event.target.value as number)
                }
              >
                {listas?.caboEletrico
                  ?.filter(
                    item => item >= Number(fichaBombeamento?.bitolaCabo ?? 0),
                  )
                  .map(item => (
                    <MenuItem key={`caboEletrico_${item}`} value={item}>
                      {`${item} mm²`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col sm="2" className="mt-2 mb-2 ml-1">
            <TextField
              disabled
              size="small"
              label="Queda Tensão"
              value={`${fichaBombeamento?.quedaTensao} %`}
            />
          </Col>
          <Col sm="2" className="mt-2 mb-2">
            <TextField
              disabled
              size="small"
              label="Potência nominal trafo"
              value={`${fichaBombeamento?.potenciaCaboTrafoTransformador} kVA`}
            />
          </Col>
          <Col sm="2" className="mt-2 mb-2">
            <FormControl fullWidth>
              <InputLabel>Potência</InputLabel>
              <Select
                disabled={!acessoPrograma.temAcesso}
                value={novoAttsPotencia ?? ''}
                label="Potência"
                autoWidth
                onChange={event =>
                  setNovoAttsPotencia(event.target.value as number)
                }
              >
                {listas?.attsPotencia
                  ?.filter(
                    item => item >= Number(ficha?.potenciaATTSElevador ?? 0),
                  )
                  .map(item => (
                    <MenuItem key={`attsPotencia_${item}`} value={item}>
                      {`${item} kVA`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>
          <Col sm="2" className="mt-2 mb-2">
            <FormControl fullWidth>
              <InputLabel>Trafo</InputLabel>
              <Select
                disabled={!acessoPrograma.temAcesso}
                value={novoAtts ?? ''}
                label="Trafo"
                autoWidth
                onChange={event => setNovoAtts(event.target.value as number)}
              >
                {listas?.atts
                  ?.filter(
                    item =>
                      item >= Number(fichaBombeamento?.tensaoNominal ?? 0),
                  )
                  .map(item => (
                    <MenuItem key={`atts_${item}`} value={item}>
                      {`${item} V`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>

          <Col sm="1" className="mt-2 mb-2" hidden={!acessoPrograma.temAcesso}>
            <div className="pull-right float-right mr-4 mt-4">
              <Button
                disabled={isSaving}
                className="btn-iconmr-0 btn-4"
                color="primary"
                type="button"
                size="sm"
                onClick={() => salvar()}
              >
                <FaCheckCircle />
              </Button>
            </div>
          </Col>

          <Col sm="12">
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Fases</th>
                  <th>Nrº Pernas</th>
                  <th>Neutro</th>
                  <th>Nrº Pernas</th>
                  <th>Terra</th>
                  <th>Nrº Pernas</th>
                  <th>Distância</th>
                </tr>
              </thead>
              <tbody>
                <tr key={1}>
                  <Td>Disjuntor_QC</Td>
                  <Td>{fichaBombeamento?.disjuntorQCFases || ''}</Td>
                  <Td>{fichaBombeamento?.disjuntorQCPernasFases || ''}</Td>
                  <Td>{fichaBombeamento?.disjuntorQCNeutro || ''}</Td>
                  <Td>{fichaBombeamento?.disjuntorQCPernasNeutro || ''}</Td>
                  <Td>{fichaBombeamento?.disjuntorQCTerra || ''}</Td>
                  <Td>{fichaBombeamento?.disjuntorQCPernasTerra || ''}</Td>
                  <Td>{fichaBombeamento?.disjuntorQCDistancia || ''}</Td>
                </tr>
                <tr key={2}>
                  <Td>QC_Motor</Td>
                  <Td>{fichaBombeamento?.qcMotorFases || ''}</Td>
                  <Td>{fichaBombeamento?.qcMotorPernasFases || ''}</Td>
                  <Td>{fichaBombeamento?.qcMotorNeutro || ''}</Td>
                  <Td>{fichaBombeamento?.qcMotorPernasNeutro || ''}</Td>
                  <Td />
                  <Td />
                  <Td>{fichaBombeamento?.qcMotorDistancia || ''}</Td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Eletrica;
