import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { TextField } from '@material-ui/core';

import 'antd/dist/antd.css';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import api from 'services/api';
import { FaCheckCircle, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import toast from 'react-hot-toast';
import { RowFilter, GrupoRow } from './styles';
import GruposAtaModel from '../../../../models/GruposAta';

const GruposAta: React.FC = () => {
  const [gruposAta, setGruposAta] = useState<GruposAtaModel[]>([]);
  const criandoItem = gruposAta.some(grupoAta => grupoAta.id === 0);

  async function carregaGruposAta() {
    const response = await api.get(`/grupo-usuarios-ata`);
    const GruposAtaResponse = response.data as GruposAtaModel[];
    setGruposAta(GruposAtaResponse);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaGruposAta();
  }, []);

  function handleSetCadDetalheValue(
    grupoUsuarios: GruposAtaModel,
    attrName: string,
    value: string | number | boolean | null,
  ) {
    setGruposAta(
      gruposAta.map(grupoUsuarios_ =>
        grupoUsuarios_.id === grupoUsuarios.id
          ? { ...grupoUsuarios_, [attrName]: value, alterado: true }
          : grupoUsuarios_,
      ),
    );
  }

  async function handleUpdate(grupoUsuarios: GruposAtaModel) {
    const { id, email, nome } = grupoUsuarios;
    await api.put(`/grupo-usuarios-ata/${id}`, {
      id,
      email,
      nome,
    });
  }

  async function handleCreate(grupoUsuarios: GruposAtaModel) {
    const { email, nome } = grupoUsuarios;
    await api.post('/grupo-usuarios-ata', {
      email,
      nome,
    });
  }

  function validaFormulario(grupoUsuarios: GruposAtaModel) {
    const { id, email, nome } = grupoUsuarios;
    if (!email || !nome) {
      return false;
    }
    return true;
  }

  async function handleSave(grupoUsuarios: GruposAtaModel) {
    const formularioOk = validaFormulario(grupoUsuarios);
    if (!formularioOk) return;
    try {
      const toastSalvaId = toast.loading('Salvando...');
      const novoGrupoUsuarios = grupoUsuarios.id === 0;
      if (novoGrupoUsuarios) {
        await handleCreate(grupoUsuarios);
      } else {
        await handleUpdate(grupoUsuarios);
      }
      await carregaGruposAta();
      toast.success('Feito!', {
        id: toastSalvaId,
      });
    } catch (error) {
      toast.dismiss();
      toast.error('Ops! Algo de errado aconteceu!');
    }
  }

  async function handleDelete(grupoUsuariosId: number) {
    const newGruposAta = gruposAta.filter(
      (grupoUsuarios: any) => grupoUsuarios.id !== grupoUsuariosId,
    );

    setGruposAta(newGruposAta);

    if (grupoUsuariosId !== 0) {
      await api.delete(`/grupo-usuarios-ata/${grupoUsuariosId}`);
    }
  }

  function questionaExclusao(grupoUsuarios: GruposAtaModel) {
    Swal.fire({
      title: `Excluir registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, excluir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(result => {
      if (result.isConfirmed) {
        handleDelete(grupoUsuarios.id);
      }
    });
  }

  function handleAdicionar() {
    setGruposAta([
      {
        id: 0,
        email: '',
        nome: '',
      },
      ...gruposAta,
    ]);
  }

  return (
    <>
      <UserSmallHeader />
      <Col>
        <RowFilter>
          <Button
            disabled={criandoItem}
            size="sm"
            className="btn btn-outline-primary"
            type="button"
            onClick={handleAdicionar}
          >
            <FaPlusCircle />
            <span className="btn-inner--text">Novo</span>
          </Button>
        </RowFilter>
      </Col>

      <Container
        className="mt-4"
        fluid
        style={{ backgroundColor: '#fff', border: '1px solid #ebe9e9' }}
      >
        <Col>
          {gruposAta.map(grupoUsuarios => {
            const { id, email, nome } = grupoUsuarios;
            return (
              <GrupoRow key={id} onSubmit={() => handleSave(grupoUsuarios)}>
                <Col sm="12">
                  <Row>
                    <Col sm="3">
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        style={{ width: '100%' }}
                        size="small"
                        variant="outlined"
                        label="Email *"
                        type="text"
                        value={email ?? ''}
                        onChange={text =>
                          handleSetCadDetalheValue(
                            grupoUsuarios,
                            'email',
                            text.target.value,
                          )
                        }
                      />
                    </Col>

                    <Col sm="3">
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        style={{ width: '100%' }}
                        size="small"
                        variant="outlined"
                        label="Nome *"
                        type="text"
                        value={nome ?? ''}
                        onChange={text =>
                          handleSetCadDetalheValue(
                            grupoUsuarios,
                            'nome',
                            text.target.value,
                          )
                        }
                      />
                    </Col>

                    <div className="mt-2 float-right">
                      {grupoUsuarios.alterado && (
                        <Button
                          size="sm"
                          className="btn-icon btn-2"
                          color="primary"
                          type="button"
                          onClick={() => handleSave(grupoUsuarios)}
                        >
                          <FaCheckCircle />
                        </Button>
                      )}
                      <Button
                        size="sm"
                        className="btn-icon btn-2"
                        color="danger"
                        type="button"
                        onClick={() => questionaExclusao(grupoUsuarios)}
                      >
                        <FaTimesCircle />
                      </Button>
                    </div>
                  </Row>
                </Col>
              </GrupoRow>
            );
          })}
        </Col>
      </Container>
    </>
  );
};

export default GruposAta;
