import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Modal,
  Col,
  FormGroup,
  InputGroup,
} from 'reactstrap';
// core components

import Header from 'components/Headers/Header.js';

import CustomTable from 'components/CustomTable';

import Swal from 'sweetalert2';

import api from 'services/api';

import Log from 'models/Log';
import { useHistory, useLocation } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Orcamento from 'models/Orcamento';

interface LocationState {
  nomeChave: string;
  valorChave: number | string;
  modalState: boolean;
  tipo?: string[];
  titulo?: string;
  orcamento: Orcamento;
}

export default function InterfaceLogs(): JSX.Element {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { nomeChave, valorChave, modalState, tipo, titulo, orcamento } =
    location.state;
  const [logs, setlogs] = useState([] as any[]);
  const [notificationModalState, setNotificationModalState] = useState(false);

  const header: any[] = [
    {
      name: 'id',
      prop: 'id',
      type: 'TEXT',
      searchable: true,
      sortable: true,
      editable: false,
      direction: 'desc',
    },
    {
      name: 'Descrição',
      prop: 'descricao',
      type: 'TEXT',
      searchable: true,
      sortable: false,
      editable: false,
      direction: 'desc',
    },
    {
      name: 'Data',
      prop: 'historic',
      type: 'TEXT',
      searchable: true,
      sortable: false,
      editable: false,
    },
    {
      name: 'Usuário',
      prop: 'usuarioAlteracao',
      type: 'TEXT',
      searchable: true,
      sortable: false,
      editable: false,
    },
  ];

  async function listalogs() {
    const response = await api.get('/log', {
      params: {
        nomeChave,
        valorChave,
        tipo,
      },
    });

    const log: Log[] = response.data;

    const rows: any[] = [];

    let count = 0;
    // eslint-disable-next-line array-callback-return
    log.map(item => {
      rows.push({
        id: count,
        columns: [
          {
            prop: 'id',
            value: { id: item.id, texto: item.id },
            newValue: { id: item.id, texto: item.id },
          },
          {
            prop: 'descricao',
            value: { id: item.id, texto: item.descricao },
            newValue: { id: item.id, texto: item.descricao },
          },
          {
            prop: 'historic',
            value: {
              id: item.id,
              texto: String(
                new Intl.DateTimeFormat('pt-BR', {
                  dateStyle: 'short',
                  timeStyle: 'medium',
                }).format(new Date(item.dataGeracao)),
              ),
            },
            newValue: {
              id: item.id,
              texto: new Intl.DateTimeFormat('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'medium',
              }).format(new Date(item.dataGeracao)),
            },
          },
          {
            prop: 'usuarioAlteracao',
            value: { id: item.id, texto: item.usuario?.nome },
            newValue: { id: item.id, texto: item.usuario?.nome },
          },
        ],
        isNew: false,
        isEditing: false,
      });
      // eslint-disable-next-line no-plusplus
      count++;
    });
    setlogs(rows);
  }

  useEffect(() => {
    listalogs();
  }, []);

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="10">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    history.push({
                      pathname: `/admin/cadastro-orcamento-novo`,
                      state: { orcamento },
                    });
                  }}
                >
                  <FaArrowLeft />
                  <span className="btn-inner--text">Voltar</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        {/* Table */}
        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={notificationModalState}
              toggle={() => setNotificationModalState(!notificationModalState)}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  {/* Your attention is required */}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">
                    Não é possível criar novo registro
                  </h4>
                  <p>
                    Já existe um registro com esse modelo e lance selecionado
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Entendi
                </Button> */}
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  Fechar
                </Button>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">logs da configuração {orcamento.id}</h3>
              </CardHeader>
              <div style={{ minHeight: 700, width: '100%' }}>
                {/* CustomTable */}
                <CustomTable
                  enableSearchField
                  rows={logs}
                  header={header}
                  onDelete={undefined}
                  ocultaBotoes
                />
                {/* END CustomTable */}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
