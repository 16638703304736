import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaHistory, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import api from 'services/api';

import { arredondaDecimais } from 'utils/arredondaDecimais';

import ModalLog from 'components/ModalLog';
import DescontoModel from '../../../models/ConfiguradorDesconto';

const ConfiguradorDesconto: React.FC = () => {
  const [descontos, setDescontos] = useState([] as DescontoModel[]);
  // eslint-disable-next-line prettier/prettier
  const [descontoEditing, setDescontoEditing] = useState({} as DescontoModel);

  const [formModalState, setFormModalState] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);

  async function listaDesconto() {
    const response = await api.get('/desconto');

    setDescontos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaDesconto();
  }, []);

  async function handleDelete(descontoId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/desconto/${descontoId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaDesconto();
      }
    });
  }
  async function handleInsert() {
    setDescontoEditing({} as DescontoModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemDesconto: DescontoModel) {
    setDescontoEditing(itemDesconto);

    setFormModalState(!formModalState);
  }

  async function handleSaveDesconto() {
    // eslint-disable-next-line prettier/prettier
    const { descricao, comissao } = descontoEditing;

    if (!descricao || !comissao) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (descontoEditing.id) {
      await api.put(`/desconto/${descontoEditing.id}`, {
        id: descontoEditing.id,
        desconto: descontoEditing.desconto,
        descricao: descontoEditing.descricao,
        comissao: descontoEditing.comissao,
      });
    } else {
      await api.post(`/desconto/`, {
        desconto: descontoEditing.desconto,
        descricao: descontoEditing.descricao,
        comissao: descontoEditing.comissao,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setDescontoEditing({} as DescontoModel);
    listaDesconto();
  }

  return (
    <>
      <Header showCards={false} />
      <ModalLog
        nomeChave="desconto_id"
        valorChave=""
        titulo="Logs de descontos e comissões"
        modalState={modalStatusLogVisivel}
        toggle={() => setModalStatusLogVisivel(false)}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{descontoEditing.descricao}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Descrição *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={descontoEditing.descricao}
                            type="text"
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Desconto </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Desconto"
                            value={descontoEditing.desconto}
                            type="number"
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                desconto: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Comissão *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Comissão"
                            value={descontoEditing.comissao}
                            type="text"
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                comissao: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveDesconto}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Lista de descontos
                  <Button
                    size="sm"
                    className="btn-icon float-right"
                    color="default"
                    outline
                    type="button"
                    onClick={() => setModalStatusLogVisivel(true)}
                  >
                    <FaHistory />
                    <span className="btn-inner--text">Logs</span>
                  </Button>
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Descrição</th>
                    <th>Desconto</th>
                    <th>Comissão</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {descontos.map(itemDesconto => (
                    <tr key={itemDesconto.id}>
                      <td className="py-2">{itemDesconto.descricao}</td>
                      <td className="py-2">
                        {arredondaDecimais(itemDesconto.desconto, 2)}%
                      </td>
                      <td className="py-2">
                        {arredondaDecimais(itemDesconto.comissao, 2)}%
                      </td>
                      <td className="py-2">
                        <div className="float-right">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="warning"
                            type="button"
                            onClick={() => {
                              handleUpdate(itemDesconto);
                            }}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(itemDesconto.id);
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorDesconto;
