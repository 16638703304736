import React, { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  CardBody,
  Form,
  Input,
  Label,
} from 'reactstrap';

import {
  FaArrowAltCircleRight,
  FaInfoCircle,
  FaPlusCircle,
  FaCopy,
  FaSearch,
} from 'react-icons/fa';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import UserSmallHeader from 'components/Headers/UserSmallHeader';
import OrcamentoVersao from 'models/OrcamentoVersao';
import BotoesProposta from 'components/BotoesProposta';

import toast from 'react-hot-toast';
import OrcamentoStatus from 'models/OrcamentoStatus';
import { Link, useHistory, useParams } from 'react-router-dom';
import Pessoa from 'models/Pessoa';
import AutocompletePessoa from 'components/AutcompletePessoa';
import { Autocomplete } from '@material-ui/lab';

import { OportunidadeCrm } from 'models/OportunidadeCrm';
import Proposta from 'models/Proposta';
import Swal from 'sweetalert2';
import { PropostaVersao } from 'models/PropostaVersao';
import { useQuery } from 'react-query';
import { useAuth } from 'hooks/auth';
import Usuario from 'models/Usuario';
import api from '../../../services/api';
import { LabelStatusOrcamento } from '../css';
import { Td, Th, TdDescricao, Municipio, PessoaContainer } from './styles';
import { BuscarRegras } from '../Regras/styles';

interface Params {
  codigo?: string;
}

interface OrcamentosVersoes {
  oportunidadeCRM: OportunidadeCrm | null;
  orcamentosVersoes: OrcamentoVersao[];
}

interface DetalhesPessoa {
  razaoSocial: string;
  municipioDescricao?: string;
  ufSigla?: string;
}

const ConfiguracaoListaGNF: React.FC = () => {
  const { usuario }: { usuario: Usuario } = useAuth();
  const history = useHistory();
  const params = useParams<Params>();
  const codigoOportunidadeDomsgeParam = params?.codigo;

  const [orcamentosVersoes, setOrcamentosVersoes] = useState(
    [] as OrcamentoVersao[],
  );
  const [configuracoesMarcadas, setConfiguracoesMarcadas] = useState<number[]>(
    [],
  );
  const [listaStatus, setListaStatus] = useState([] as OrcamentoStatus[]);
  const [codigoOportunidade, setCodigoOportunidade] = useState('');
  const [codigoGnf, setCodigoGnf] = useState('');
  const [pessoaSelecionada, setPessoaSelecionada] = useState<Pessoa>(
    {} as Pessoa,
  );
  const [detalhesPessoa, setDetalhesPessoa] = useState<DetalhesPessoa | null>();
  const [oportunidadesPesquisadas, setOportunidadesPesquisadas] = useState<
    string[]
  >([]);
  const [oportunidadeCrm, setOportunidadeCrm] =
    useState<OportunidadeCrm | null>();
  const [modalInclusaoEmProposta, setModalInclusaoEmProposta] = useState(false);
  const [propostas, setPropostas] = useState<Proposta[]>([]);
  const [propostaVersoes, setPropostaVersoes] = useState<PropostaVersao[]>([]);
  const [propostaIdFiltrada, setPropostaIdFiltrada] = useState<number>();
  const [propostaVersaoIdFiltrada, setPropostaVersaoIdFiltrada] =
    useState<number>();
  const [propostasGNF, setPropostasGNF] = useState<Proposta[]>([]);
  const [propostasVersoesEmAberto, setPropostasVersoesEmAberto] = useState<
    PropostaVersao[]
  >([]);
  const [propostaSelecionadaId, setPropostaSelecionadaId] =
    useState<string>('');
  const [propostaVersaoSelecionadaId, setPropostaVersaoSelecionadaId] =
    useState<string>('');

  const [mostrarComPropostaBloqueada, setMostrarComPropostaBloqueada] =
    useState(false);

  const [acessoLimitado, setAcessoLimitado] = useState(true);
  const [resultadoFiltrado, setResultadoFiltrado] = useState(
    [] as OrcamentoVersao[],
  );
  const [palavraChave, setPalavraChave] = useState('');
  const [exibeCampoBusca, setExibeCampoBusca] = useState(false);
  const [habilitarBotaoNovo, setHabilitarBotaoNovo] = useState(false);

  const { data: dataPropostas } = useQuery('lista-propostas', async () => {
    async function getPropostas(): Promise<Proposta[]> {
      const response = await api.get('/proposta', {
        params: {
          status: 1,
        },
      });
      return response.data;
    }

    const data = await getPropostas();
    return data;
  });

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 19,
      },
    });
    setAcessoLimitado(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    getAcessoLimitadoPrograma();
  }, []);

  useEffect(() => {
    if (!dataPropostas?.length) {
      return;
    }
    setPropostas(dataPropostas);
  }, [dataPropostas]);

  useEffect(() => {
    const propostaVersoesFiltradas = propostas.find(
      proposta => proposta.id === propostaIdFiltrada,
    )?.PropostaVersao;
    if (propostaVersoesFiltradas) {
      setPropostaVersoes(propostaVersoesFiltradas);
    }
  }, [propostaIdFiltrada]);

  function setOportunidadesPesquisadasArray(oportunidades?: string | null) {
    if (!oportunidades) return;

    const oportunidadesArray = oportunidades?.split(',') ?? [];

    setOportunidadesPesquisadas(oportunidadesArray);
  }

  function getOportunidadesPesquisadas() {
    const oportunidades = localStorage.getItem('oportunidadesPesquisadas');

    setOportunidadesPesquisadasArray(oportunidades);

    return oportunidades;
  }

  function storeOportunidadesPesquisadas() {
    if (!codigoOportunidade) return;

    const oportunidades = getOportunidadesPesquisadas();

    const novoOportunidadesPesquisadas = oportunidades
      ? `${codigoOportunidade},${oportunidades}`
      : codigoOportunidade;

    const jaExisteOportunidade = oportunidades
      ?.split(',')
      .find(oportunidade => oportunidade === codigoOportunidade);

    if (jaExisteOportunidade) return;

    setOportunidadesPesquisadasArray(novoOportunidadesPesquisadas);

    localStorage.setItem(
      'oportunidadesPesquisadas',
      novoOportunidadesPesquisadas,
    );
  }

  async function filtraPropostasGNF() {
    const propostasFilteredGNF = propostas.filter(
      proposta => proposta.codigoOportunidadeDomsge === codigoOportunidade,
    );
    setPropostasGNF(propostasFilteredGNF);
  }

  async function carregaStatus() {
    const response = await api.get('/configuracao/listas/status-orcamento');
    setListaStatus(response.data);
  }

  async function handleBuscaOportunidades(codigoOportunidadeParam?: string) {
    setOrcamentosVersoes([]);
    setExibeCampoBusca(true);
    const toastBusca = toast.loading('Buscando...');
    try {
      const response = await api.get('/orcamento/versao-codigo-domsge', {
        params: {
          codigoOportunidadeDomsgeAgrupador:
            codigoOportunidadeParam ?? codigoOportunidade,
          codigoCliente: pessoaSelecionada.codigoDomsge,
          propostaId: propostaIdFiltrada,
          propostaVersaoId: propostaVersaoIdFiltrada,
          somenteConfiguracoesEditaveis: !mostrarComPropostaBloqueada,
        },
      });

      storeOportunidadesPesquisadas();
      const orcametosVersoesData = response.data as OrcamentosVersoes;
      setOportunidadeCrm(orcametosVersoesData.oportunidadeCRM);
      const { RAZAO_SOCIAL, CIDADE, ESTADO } =
        orcametosVersoesData.oportunidadeCRM || {};
      const novoDetalhesPessoa = {
        razaoSocial: RAZAO_SOCIAL || '',
        municipioDescricao: CIDADE || '',
        ufSigla: ESTADO || '',
      };

      if (
        orcametosVersoesData.oportunidadeCRM &&
        orcametosVersoesData.oportunidadeCRM.NUMERO_NEGOCIO
      )
        setHabilitarBotaoNovo(true);
      else setHabilitarBotaoNovo(false);

      setDetalhesPessoa(novoDetalhesPessoa);
      if (!orcametosVersoesData.orcamentosVersoes.length) {
        toast.error('Nenhuma configuração encontrada!', {
          id: toastBusca,
        });
        return;
      }
      setOrcamentosVersoes(
        orcametosVersoesData.orcamentosVersoes.filter(orcamentoVersao =>
          orcamentoVersao.orcamento.PropostaConfiguracao?.filter(
            propostaConfiguracao =>
              propostaConfiguracao.propostaVersao.id ===
              propostaVersaoIdFiltrada,
          ),
        ),
      );
      filtraPropostasGNF();
      toast.remove(toastBusca);
    } catch {
      toast.remove(toastBusca);
    }
  }

  async function carregaOrcamentoByCodigoDomsge() {
    setCodigoOportunidade(codigoOportunidadeDomsgeParam || '');
    handleBuscaOportunidades(codigoOportunidadeDomsgeParam);
  }

  async function filtroTabela(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const search = event.target.value;
    setPalavraChave(search);

    if (search !== '') {
      const filterdata = orcamentosVersoes.filter(item => {
        let objetoFiltrado;
        if (
          Object.values(item)
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          item.FichaTecnica?.[0]?.pivoCentral !== null
            ? item.FichaTecnica?.[0]?.pivoCentral
                ?.toUpperCase()
                .includes(search.toUpperCase())
            : false
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(item.orcamento)
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(item.orcamento.pessoa)
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(item.orcamento.pessoaGnf)
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(item.orcamento.usuario)
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(item.OrcamentoProduto?.[0] ?? '')
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(
            Intl.DateTimeFormat('pt-BR').format(new Date(item.createdAt)),
          )
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(item.numero)
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          Object.values(item.orcamento.OrcamentoVersao[0])
            .join('')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        } else if (
          (item.OrcamentoProduto?.[0]?.produto?.nome ?? '')
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          objetoFiltrado = item;
        }
        return objetoFiltrado;
      });
      setResultadoFiltrado(filterdata);
    } else {
      setResultadoFiltrado(orcamentosVersoes);
    }
  }

  useEffect(() => {
    if (codigoOportunidadeDomsgeParam) {
      carregaOrcamentoByCodigoDomsge();
    }
  }, [codigoOportunidadeDomsgeParam]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      carregaStatus();
      getOportunidadesPesquisadas();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!oportunidadeCrm) {
      setDetalhesPessoa(null);
      return;
    }

    const { RAZAO_SOCIAL, CIDADE, ESTADO } = oportunidadeCrm;

    const novoDetalhesPessoa = {
      razaoSocial: RAZAO_SOCIAL,
      municipioDescricao: CIDADE,
      ufSigla: ESTADO,
    };

    setDetalhesPessoa(novoDetalhesPessoa);
  }, [oportunidadeCrm]);

  useEffect(() => {
    setPropostasVersoesEmAberto([]);
    const proposta = propostasGNF.find(
      propost => propost.id === Number(propostaSelecionadaId),
    );
    if (proposta) {
      setPropostasVersoesEmAberto(
        proposta?.PropostaVersao.filter(
          propostaVersao => propostaVersao.status.id === 1, // status id 1: Gerada
        ),
      );
    }
  }, [propostaSelecionadaId]);

  function handleKeyPress(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      handleBuscaOportunidades();
    }
  }

  function handleNavigateNovoOrcamento() {
    history.push({
      pathname: `/admin/cadastro-orcamento-novo`,
      state: {
        oportunidadeCrm,
        codigoOportunidade,
      },
    });
  }

  function onChangeAutocompleteCodigoOportunidade(
    pCodigoOportunidade: string | null,
  ) {
    setHabilitarBotaoNovo(false);
    setCodigoOportunidade(pCodigoOportunidade ?? '');
    setPessoaSelecionada({} as Pessoa);
    setDetalhesPessoa({} as Pessoa);
  }

  function handleChangeSelecao(
    event: React.ChangeEvent<HTMLInputElement>,
    orcamentoId: number,
  ) {
    const isSelected = event.target.checked;
    if (isSelected) {
      setConfiguracoesMarcadas([...configuracoesMarcadas, orcamentoId]);
      return;
    }
    const novoConfiguracoesMarcadas = configuracoesMarcadas.filter(
      configId => configId !== orcamentoId,
    );
    setConfiguracoesMarcadas(novoConfiguracoesMarcadas);
  }

  async function handleAskNovaProposta() {
    async function geraNovaProposta(): Promise<Proposta> {
      Swal.fire({
        icon: 'info',
        text: 'Gerando proposta...',
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const response = await api.post('/proposta', {
        codigoGNF: codigoOportunidade !== '' ? codigoOportunidade : codigoGnf,
        codigoCliente: pessoaSelecionada.id,
        configuracoes: configuracoesMarcadas,
      });
      Swal.close();
      return response.data;
    }

    Swal.fire({
      title: `Gerar proposta para as configurações ${configuracoesMarcadas.join(
        ', ',
      )}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        const proposta = await geraNovaProposta();

        history.push({
          pathname: '/admin/proposta',
          state: { proposta, propostaVersao: proposta.PropostaVersao[0] },
        });
      }
    });
  }

  async function handleInclusaoEmProposta() {
    async function incluiEmProposta(): Promise<void> {
      Swal.fire({
        icon: 'info',
        text: `Incluindo configuração(ões) na proposta ${propostaSelecionadaId}...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      await api.post('/proposta/configuracao', {
        propostaVersaoId: Number(propostaVersaoSelecionadaId),
        configuracoes: configuracoesMarcadas,
      });
      Swal.close();
    }

    async function buscaProposta() {
      const response = await api.get(`/proposta/${propostaSelecionadaId}`);
      return response.data;
    }

    await incluiEmProposta();
    setConfiguracoesMarcadas([]);
    setModalInclusaoEmProposta(false);

    const proposta = await buscaProposta();
    const propostaVersaoSelecionada = propostasVersoesEmAberto.find(
      propostaVersao =>
        propostaVersao.id === Number(propostaVersaoSelecionadaId),
    );

    Swal.fire({
      title: `Navegar para proposta?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, navegar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Fechar`,
    }).then(async result => {
      if (result.isConfirmed) {
        history.push({
          pathname: '/admin/proposta',
          state: { proposta, propostaVersao: propostaVersaoSelecionada },
        });
      }
    });
  }

  async function duplicarRegistro(codigo: number) {
    async function duplicar() {
      const retorno = await api.post(`/orcamento//${codigo}/duplicar`, {});

      history.push({
        pathname: `/admin/cadastro-orcamento-novo`,
        state: { orcamento: retorno.data },
      });
    }

    Swal.fire({
      title: `Deseja duplicar as informações da configuração #${codigo} em um novo registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, duplicar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        toast.promise(duplicar(), {
          loading: 'Aguarde...',
          success: <b>Tudo certo!</b>,
          error: <b>Algo de errado aconteceu.</b>,
        });
      }
    });
  }

  return (
    <>
      <UserSmallHeader />

      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={modalInclusaoEmProposta}
        toggle={() => setModalInclusaoEmProposta(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center">
                <strong>
                  Incluir em proposta existente - GNF {codigoOportunidade}
                </strong>
              </div>
              <div className="btn-wrapper text-center" />
            </CardHeader>
            <CardBody className="px-lg-5 ">
              <Form role="form">
                <Input
                  type="select"
                  value={propostaSelecionadaId}
                  onChange={event =>
                    setPropostaSelecionadaId(event.target.value)
                  }
                >
                  <option value="">Selecione a proposta...</option>
                  {propostasGNF.map(proposta => (
                    <option key={proposta.id} value={proposta.id}>
                      Proposta {proposta.id}
                    </option>
                  ))}
                </Input>
                {propostaSelecionadaId && (
                  <div className="mt-3">
                    <Input
                      type="select"
                      value={propostaVersaoSelecionadaId}
                      onChange={event =>
                        setPropostaVersaoSelecionadaId(event.target.value)
                      }
                    >
                      <option value="">Selecione a versão...</option>
                      {propostasVersoesEmAberto?.map(propostaVersao => (
                        <option
                          key={propostaVersao.id}
                          value={propostaVersao.id}
                        >
                          Versão {propostaVersao.versao}
                        </option>
                      ))}
                    </Input>
                  </div>
                )}
                <div className="text-center">
                  <Button
                    className="my-4 w-100"
                    color="primary"
                    type="button"
                    onClick={handleInclusaoEmProposta}
                  >
                    Incluir
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>

      <Container className="mt-2" fluid>
        <Row>
          <Col>
            <Card className="shadow p-2">
              <Row onKeyPress={handleKeyPress}>
                <Col sm="2">
                  <Autocomplete
                    options={oportunidadesPesquisadas}
                    value={codigoOportunidade}
                    clearOnBlur={false}
                    onInputChange={(_, novoCodigoOportunidade) =>
                      onChangeAutocompleteCodigoOportunidade(
                        novoCodigoOportunidade,
                      )
                    }
                    onChange={(_, novoCodigoOportunidade) =>
                      onChangeAutocompleteCodigoOportunidade(
                        novoCodigoOportunidade,
                      )
                    }
                    renderInput={autcompleteParams => (
                      <TextField
                        {...autcompleteParams}
                        size="small"
                        variant="outlined"
                        label="GNF Base"
                        type="text"
                      />
                    )}
                  />
                </Col>
                <Col sm="5">
                  <AutocompletePessoa
                    onChange={(_, newValue) => {
                      setCodigoOportunidade('');
                      setPessoaSelecionada(newValue ?? ({} as Pessoa));
                    }}
                    limitarPorConfiguracao
                    pessoaSelecionada={pessoaSelecionada}
                  />
                </Col>
                <Col sm="2">
                  <FormControl className="w-100" variant="outlined">
                    <InputLabel className="mt--2">Proposta</InputLabel>
                    <Select
                      label="Proposta"
                      value={propostaIdFiltrada}
                      onChange={event =>
                        setPropostaIdFiltrada(
                          event.target.value
                            ? Number(event.target.value)
                            : undefined,
                        )
                      }
                    >
                      <MenuItem value={undefined}>Selecione...</MenuItem>
                      {propostas.map(proposta => (
                        <MenuItem value={proposta.id}>
                          Proposta {proposta.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col sm="2">
                  <FormControl className="w-100" variant="outlined">
                    <InputLabel className="mt--2">Versão</InputLabel>
                    <Select
                      label="Versão"
                      disabled={!propostaIdFiltrada}
                      value={propostaVersaoIdFiltrada}
                      onChange={event =>
                        setPropostaVersaoIdFiltrada(
                          event.target.value
                            ? Number(event.target.value)
                            : undefined,
                        )
                      }
                    >
                      <MenuItem value={undefined}>Todas versões</MenuItem>
                      {propostaVersoes.map(propostaVersao => (
                        <MenuItem value={propostaVersao.id}>
                          Versão {propostaVersao.versao}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col sm="1">
                  <Button
                    className="btn-icon btn-2"
                    color="primary"
                    type="button"
                    onClick={() => handleBuscaOportunidades()}
                  >
                    <FaSearch />
                  </Button>
                </Col>
                <Col sm="5">
                  <FormControlLabel
                    className="ml-2 mt-2"
                    control={
                      <Checkbox
                        className="p-0"
                        color="primary"
                        size="small"
                        checked={mostrarComPropostaBloqueada}
                        onChange={event =>
                          setMostrarComPropostaBloqueada(event.target.checked)
                        }
                      />
                    }
                    label="Mostrar com proposta bloqueada"
                  />
                </Col>
                <Col sm="12">
                  <div className="mt-2">
                    <PessoaContainer>
                      <Row>{detalhesPessoa?.razaoSocial}</Row>
                    </PessoaContainer>
                    <PessoaContainer>
                      <Row>
                        <Municipio>
                          {detalhesPessoa?.municipioDescricao}{' '}
                        </Municipio>
                        {detalhesPessoa?.ufSigla
                          ? `/${detalhesPessoa?.ufSigla}`
                          : ''}
                      </Row>
                    </PessoaContainer>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="4">
                    <h4>{orcamentosVersoes.length} configuração(ões)</h4>
                  </Col>
                  <Col sm="3">
                    <BotoesProposta
                      rows={configuracoesMarcadas}
                      onClick={() => setModalInclusaoEmProposta(true)}
                      onClickNovaProposta={handleAskNovaProposta}
                    />
                  </Col>
                  <Col sm="2">
                    {habilitarBotaoNovo && (
                      <Button
                        size="sm"
                        className="btn-outline-default p-2 float-right"
                        color="primary"
                        type="button"
                        onClick={handleNavigateNovoOrcamento}
                      >
                        Nova configuração <FaPlusCircle />
                      </Button>
                    )}
                  </Col>
                  <Col sm="3">
                    <BuscarRegras
                      className="float-right"
                      id="outlined-basic"
                      label="Buscar"
                      variant="outlined"
                      size="small"
                      type="text"
                      hidden={!exibeCampoBusca}
                      onChange={event => {
                        filtroTabela(event);
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <>
                <Table className="align-items-center" responsive>
                  <thead style={{ background: 'red' }}>
                    <tr>
                      <Th> </Th>
                      <Th>#</Th>
                      <Th>GNF</Th>
                      <Th>Cliente</Th>
                      <Th>Produto</Th>
                      <Th>Proposta</Th>
                      <Th>Usuário</Th>
                      <Th>Data</Th>
                      <Th> </Th>
                    </tr>
                  </thead>
                  <tbody id="tabela-configuracoes">
                    {palavraChave.length > 1
                      ? resultadoFiltrado.map(orcamentoVersao => {
                          const {
                            id,
                            orcamento,
                            OrcamentoProduto,
                            createdAt,
                            FichaTecnica,
                          } = orcamentoVersao;
                          const dataCriacao = new Intl.DateTimeFormat(
                            'pt-BR',
                          ).format(new Date(createdAt));
                          const {
                            status,
                            codigoOportunidadeDomsge,
                            pessoa,
                            pessoaGnf,
                          } = orcamento;
                          const orcamentoId = orcamento.id;
                          const statusDescricao = listaStatus.find(
                            x => x.value === status,
                          );
                          const produto = OrcamentoProduto?.[0]?.produto;
                          if (!produto) return <tr />;
                          const produtoId = OrcamentoProduto?.[0]?.produto?.id;
                          const { nome, codigoEmpresa } = produto;
                          const propostaId =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              .propostaId;
                          const propostaVersaoNumero =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              .versao;

                          const propostaStatusName =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              ?.status?.descricao ?? '';

                          const isPropostaBloqueada =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              ?.status?.indicPermiteModificar === false ??
                            false;
                          const pivoCentral = FichaTecnica?.[0]?.pivoCentral;
                          const nomeCliente = pessoa?.razaoSocial;
                          const nomeClienteSub = pessoaGnf?.razaoSocial;
                          const temClientesDiferentes =
                            (pessoa?.id ?? 0) !== (pessoaGnf?.id ?? 0);

                          return (
                            <tr key={id}>
                              <Td>
                                <Checkbox
                                  className="p-0"
                                  color="primary"
                                  size="small"
                                  hidden={isPropostaBloqueada}
                                  checked={configuracoesMarcadas.some(
                                    configuracaoId => configuracaoId === id,
                                  )}
                                  onChange={event => {
                                    handleChangeSelecao(event, id);
                                    setCodigoGnf(
                                      codigoOportunidadeDomsge ?? '',
                                    );
                                  }}
                                />
                              </Td>
                              <Td>
                                <Link
                                  title="Navegar para a configuração"
                                  to={{
                                    pathname: `/admin/cadastro-orcamento-novo`,
                                    state: { orcamento },
                                  }}
                                  style={{
                                    borderRadius: '5px',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  {orcamentoId}
                                </Link>
                              </Td>
                              <Td>
                                <div className="d-flex flex-column">
                                  <tr>{codigoOportunidadeDomsge}</tr>
                                  <tr>
                                    <small>{codigoEmpresa}</small>
                                  </tr>
                                </div>
                              </Td>
                              <Td>
                                <div className="d-flex flex-column">
                                  <tr>{nomeClienteSub ?? ''}</tr>
                                  <tr hidden={!temClientesDiferentes}>
                                    <small>{nomeCliente ?? ''}</small>
                                  </tr>
                                  <tr>
                                    <small>{pivoCentral}</small>
                                  </tr>
                                </div>
                              </Td>
                              <Td>{nome}</Td>
                              <Td>
                                <LabelStatusOrcamento
                                  bgcolor={
                                    isPropostaBloqueada ? '#f8ac59' : '#1a7bb9'
                                  }
                                  className="mt-1"
                                  hidden={
                                    !propostaVersaoNumero || !propostaStatusName
                                  }
                                >
                                  {propostaVersaoNumero
                                    ? `${propostaId} | Versão ${propostaVersaoNumero}: `
                                    : ''}
                                  {propostaStatusName}
                                </LabelStatusOrcamento>
                              </Td>

                              <td>
                                <LabelStatusOrcamento
                                  bgcolor="#57ad75"
                                  title={`Criado por ${
                                    orcamento.usuario?.nome || ''
                                  }`}
                                  hidden={
                                    usuario?.id ===
                                    (orcamento.usuario?.id || null)
                                  }
                                >
                                  {orcamento.usuario?.nome || ''}
                                </LabelStatusOrcamento>
                              </td>
                              <Td>{dataCriacao}</Td>
                              <Td>
                                <Button
                                  className="btn-icon btn-2"
                                  type="button"
                                  title="Duplicar registro em uma nova configuração"
                                  onClick={() => duplicarRegistro(id)}
                                  size="sm"
                                >
                                  <FaCopy />
                                </Button>

                                <Link
                                  title="Navegar para a configuração"
                                  to={{
                                    pathname: `/admin/cadastro-orcamento-novo`,
                                    state: { orcamento },
                                  }}
                                >
                                  <FaArrowAltCircleRight size={18} />
                                </Link>
                              </Td>
                            </tr>
                          );
                        })
                      : orcamentosVersoes.map(orcamentoVersao => {
                          const {
                            id,
                            orcamento,
                            OrcamentoProduto,
                            createdAt,
                            FichaTecnica,
                          } = orcamentoVersao;
                          const dataCriacao = new Intl.DateTimeFormat(
                            'pt-BR',
                          ).format(new Date(createdAt));
                          const {
                            status,
                            codigoOportunidadeDomsge,
                            pessoa,
                            pessoaGnf,
                          } = orcamento;
                          const orcamentoId = orcamento.id;
                          const statusDescricao = listaStatus.find(
                            x => x.value === status,
                          );
                          const produto = OrcamentoProduto?.[0]?.produto;
                          if (!produto) return <tr />;
                          const produtoId = OrcamentoProduto?.[0]?.produto?.id;
                          const { nome, codigoEmpresa } = produto;
                          const propostaId =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              .propostaId;
                          const propostaVersaoNumero =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              .versao;

                          const propostaStatusName =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              ?.status?.descricao ?? '';

                          const isPropostaBloqueada =
                            orcamento?.PropostaConfiguracao?.[0]?.propostaVersao
                              ?.status?.indicPermiteModificar === false ??
                            false;
                          const pivoCentral = FichaTecnica?.[0]?.pivoCentral;
                          const nomeCliente = pessoa?.razaoSocial;
                          const nomeClienteSub = pessoaGnf?.razaoSocial;
                          const temClientesDiferentes =
                            (pessoa?.id ?? 0) !== (pessoaGnf?.id ?? 0);

                          return (
                            <tr key={id}>
                              <Td>
                                <Checkbox
                                  className="p-0"
                                  color="primary"
                                  size="small"
                                  hidden={isPropostaBloqueada}
                                  checked={configuracoesMarcadas.some(
                                    configuracaoId => configuracaoId === id,
                                  )}
                                  onChange={event => {
                                    handleChangeSelecao(event, id);
                                    setCodigoGnf(
                                      codigoOportunidadeDomsge ?? '',
                                    );
                                  }}
                                />
                              </Td>
                              <Td>
                                <Link
                                  title="Navegar para a configuração"
                                  to={{
                                    pathname: `/admin/cadastro-orcamento-novo`,
                                    state: { orcamento },
                                  }}
                                  style={{
                                    borderRadius: '5px',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  {orcamentoId}
                                </Link>
                              </Td>
                              <Td>
                                <div className="d-flex flex-column">
                                  <tr>{codigoOportunidadeDomsge}</tr>
                                  <tr>
                                    <small>{codigoEmpresa}</small>
                                  </tr>
                                </div>
                              </Td>
                              <Td>
                                <div className="d-flex flex-column">
                                  <tr>{nomeClienteSub ?? ''}</tr>
                                  <tr hidden={!temClientesDiferentes}>
                                    <small>{nomeCliente ?? ''}</small>
                                  </tr>
                                  <tr>
                                    <small>{pivoCentral}</small>
                                  </tr>
                                </div>
                              </Td>
                              <Td>{nome}</Td>
                              <Td>
                                <LabelStatusOrcamento
                                  bgcolor={
                                    isPropostaBloqueada ? '#f8ac59' : '#1a7bb9'
                                  }
                                  className="mt-1"
                                  hidden={
                                    !propostaVersaoNumero || !propostaStatusName
                                  }
                                >
                                  {propostaVersaoNumero
                                    ? `${propostaId} | Versão ${propostaVersaoNumero}: `
                                    : ''}
                                  {propostaStatusName}
                                </LabelStatusOrcamento>
                              </Td>

                              <td>
                                <LabelStatusOrcamento
                                  bgcolor="#57ad75"
                                  title={`Criado por ${
                                    orcamento.usuario?.nome || ''
                                  }`}
                                  hidden={
                                    usuario?.id ===
                                    (orcamento.usuario?.id || null)
                                  }
                                >
                                  {orcamento.usuario?.nome || ''}
                                </LabelStatusOrcamento>
                              </td>
                              <Td>{dataCriacao}</Td>
                              <Td>
                                <Button
                                  className="btn-icon btn-2"
                                  type="button"
                                  title="Duplicar registro em uma nova configuração"
                                  onClick={() => duplicarRegistro(id)}
                                  size="sm"
                                >
                                  <FaCopy />
                                </Button>

                                <Link
                                  title="Navegar para a configuração"
                                  to={{
                                    pathname: `/admin/cadastro-orcamento-novo`,
                                    state: { orcamento },
                                  }}
                                >
                                  <FaArrowAltCircleRight size={18} />
                                </Link>
                              </Td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguracaoListaGNF;
