import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaBuilding,
  FaEdit,
  FaHistory,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import QuestaoModel from 'models/Questao';

import api from 'services/api';

import { arredondaDecimais } from 'utils/arredondaDecimais';

import ModalLog from 'components/ModalLog';
import ParteModel from 'models/Parte';
import Produto from 'models/Produto';
import EstruturaJsonModel from '../../../models/ConfiguradorEstruturaJson';

const ConfiguradorEstruturaJson: React.FC = () => {
  const [estruturasJson, setEstruturasJson] = useState(
    [] as EstruturaJsonModel[],
  );
  // eslint-disable-next-line prettier/prettier
  const [estruturaJsonEditing, setEstruturaJsonEditing] = useState({} as EstruturaJsonModel);
  const [questao, setQuestao] = useState<QuestaoModel[]>([]);

  const [formModalState, setFormModalState] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtoId, setProdutoId] = useState(0);
  const [partes, setPartes] = useState([] as ParteModel[]);
  const [parte, setParte] = useState({} as ParteModel);

  async function listaEstruturaJson() {
    if (produtoId <= 0) {
      return;
    }

    const response1 = await api.get(
      `/configuracao-estrutura-json/lista-por-produto/${produtoId}`,
    );

    const estrutura = response1.data as EstruturaJsonModel[];

    setEstruturasJson(estrutura);
  }

  async function listaQuestoes() {
    if (produtoId <= 0) {
      return;
    }

    const response = await api.get(`/questao/listar-por-produto/${produtoId}`);

    const questoes = response.data as QuestaoModel[];

    setQuestao(questoes);
  }

  async function carregaProdutos() {
    const response = await api.get('/produto');

    setProdutos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaProdutos();
  }, []);

  useEffect(() => {
    if (produtoId > 0) {
      listaEstruturaJson();
      listaQuestoes();
    }
  }, [produtoId]);

  async function handleDelete(descontoId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/configuracao-estrutura-json/${descontoId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaEstruturaJson();
      }
    });
  }
  async function handleInsert() {
    setEstruturaJsonEditing({} as EstruturaJsonModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemEstrutura: EstruturaJsonModel) {
    setEstruturaJsonEditing(itemEstrutura);

    setFormModalState(!formModalState);
  }

  async function handleFilter(id: number) {
    const response = await api.get(`/produto/${id}`);

    setProdutos(response.data);
  }

  async function handleSaveDesconto() {
    // eslint-disable-next-line prettier/prettier
    const { labelSaida, questaoId } = estruturaJsonEditing;

    if (!labelSaida || !questaoId) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (estruturaJsonEditing.id) {
      setIsSaving(true);
      await api.put(`/configuracao-estrutura-json/${estruturaJsonEditing.id}`, {
        id: estruturaJsonEditing.id,
        labelSaida: estruturaJsonEditing.labelSaida,
        questaoId: estruturaJsonEditing.questaoId,
        bloquearManual: estruturaJsonEditing.bloquearManual,
      });
    } else {
      setIsSaving(true);
      await api.post(`/configuracao-estrutura-json/`, {
        labelSaida: estruturaJsonEditing.labelSaida,
        questaoId: estruturaJsonEditing.questaoId,
        bloquearManual: estruturaJsonEditing.bloquearManual,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setEstruturaJsonEditing({} as EstruturaJsonModel);
    listaEstruturaJson();
    setIsSaving(false);
  }

  return (
    <>
      <Header showCards={false} />
      <ModalLog
        nomeChave="desconto_id"
        valorChave=""
        titulo="Logs de estruturasJson e comissões"
        modalState={modalStatusLogVisivel}
        toggle={() => setModalStatusLogVisivel(false)}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{estruturaJsonEditing.labelSaida}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Propriedade Json *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Propriedade Json"
                            value={estruturaJsonEditing.labelSaida}
                            type="text"
                            onChange={text =>
                              setEstruturaJsonEditing({
                                ...estruturaJsonEditing,
                                labelSaida: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Questão *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Questão"
                            type="select"
                            value={estruturaJsonEditing.questaoId}
                            onChange={text =>
                              setEstruturaJsonEditing({
                                ...estruturaJsonEditing,
                                questaoId: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Questão...</option>
                            {questao
                              .sort((a, b) =>
                                a.descricao.localeCompare(b.descricao),
                              )
                              .filter(
                                filter =>
                                  filter.tipo !== 'TI' && !filter.dataHoraFim,
                              )
                              .map(questoes => (
                                <option value={questoes.id}>
                                  {questoes.descricao}
                                </option>
                              ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Bloqueio manual</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Bloqueio manual"
                            type="select"
                            value={
                              estruturaJsonEditing.bloquearManual ? 'S' : 'N'
                            }
                            onChange={text =>
                              setEstruturaJsonEditing({
                                ...estruturaJsonEditing,
                                bloquearManual: Boolean(
                                  text.target.value === 'S',
                                ),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSaveDesconto}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Mapas - configuração</h3>
                <Col md="6" className="mt-4 mb-2 ml-0">
                  <InputGroup className="input-group-alternative input-group">
                    <Input
                      className="ml-2"
                      type="select"
                      value={produtoId}
                      onChange={text => setProdutoId(Number(text.target.value))}
                    >
                      <option value="">Selecionar produto...</option>
                      {produtos.map(produto => (
                        <option value={produto.id}>{produto.nome}</option>
                      ))}
                    </Input>
                  </InputGroup>
                </Col>
              </CardHeader>
              {/* Combo select aqui */}

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Propriedade Json</th>
                    <th>Questão</th>
                    <th>Bloqueio manual</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {estruturasJson.map(itemEstrutura => {
                    return (
                      <tr key={itemEstrutura.id}>
                        <td className="py-2">{itemEstrutura.labelSaida}</td>
                        <td className="py-2">
                          {itemEstrutura.questao.descricao}
                        </td>
                        <td className="py-2">
                          {itemEstrutura.bloquearManual ? 'Sim' : 'Não'}
                        </td>
                        <td className="py-2">
                          <div className="float-right">
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              onClick={() => {
                                handleUpdate(itemEstrutura);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="danger"
                              type="button"
                              onClick={() => {
                                handleDelete(itemEstrutura.id);
                              }}
                            >
                              <FaTimesCircle />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorEstruturaJson;
