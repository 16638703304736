import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
  CardBody,
  Input,
  InputGroup,
  FormGroup,
  Table,
  Form,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import api from 'services/api';
import toast from 'react-hot-toast';
import {
  FaCheckCircle,
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './style.css';
import Produto from 'models/Produto';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { iReport, tipoReport, iReportEdit } from 'models/TemplateReport';

const TemplateRelatorio: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [registroEditing, setRegistroEditing] = useState({} as iReport);
  const [registroReportEditing, setRegistroReportEditing] = useState(
    {} as iReportEdit,
  );
  const [listaRegistros, setListaRegistros] = useState([] as iReport[]);
  const [listaProdutos, setListaProdutos] = useState([] as Produto[]);
  const [formModalReportState, setFormModalReportState] = useState(false);
  const [formModalState, setFormModalState] = useState(false);
  const [mostrarDesativados, setMostrarDesativados] = useState(false);

  async function carregarTabela() {
    const response = await api.get('/report');
    setListaRegistros(response.data);
  }

  async function listaProduto() {
    const response = await api.get('/produto');
    setListaProdutos(response.data);
  }

  async function carregarDados() {
    setIsLoading(true);
    await listaProduto();
    await carregarTabela();
    setIsLoading(false);
  }

  async function handleInsert(reportContentPaiId: number) {
    setRegistroEditing({ reportContentPaiId } as iReport);
    setFormModalState(!formModalState);
  }

  function handleUpdate(tabela: iReport) {
    setRegistroEditing({ ...tabela, labelValido: true } as iReport);
    setFormModalState(!formModalState);
  }

  async function handleInsertReport() {
    setRegistroReportEditing({} as iReportEdit);
    setFormModalReportState(!formModalReportState);
  }

  function handleUpdateReport(tabela: iReport) {
    setRegistroReportEditing({
      id: tabela.id,
      nome: tabela.nome,
      descricao: tabela.descricao,
    } as iReportEdit);
    setFormModalReportState(!formModalState);
  }

  async function handleSaveReportTabela() {
    const { id, descricao, nome } = registroReportEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      if (id) {
        await api.put(`/report/${id}`, {
          descricao,
          nome,
          produtoId: [],
          tipo: 'R',
          ignorarPrimeiraPagina: false,
          mostrarPrimeiraPagina: false,
        });
      } else {
        await api.post(`/report`, {
          id,
          descricao,
          nome,
          produtoId: [],
          tipo: 'R',
          ignorarPrimeiraPagina: false,
          mostrarPrimeiraPagina: false,
        });
      }

      toast.success('Feito!', {});
      Swal.close();

      setFormModalReportState(false);
      setRegistroReportEditing({} as iReport);
      carregarDados();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleSaveTabela() {
    const {
      id,
      descricao,
      nome,
      produtoId,
      tipo,
      ignorarPrimeiraPagina,
      mostrarPrimeiraPagina,
      reportContentPaiId,
    } = registroEditing;

    if (!nome || !tipo) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    let ignorar = false;
    let mostrar = false;

    if (tipo !== 'C') {
      if (mostrarPrimeiraPagina && ignorarPrimeiraPagina) {
        toast.error(
          'Deve-se optar por mostrar apenas na primeira página ou ignorá-la. Ambos os campos não podem estar marcados',
        );
        return;
      }

      ignorar = ignorarPrimeiraPagina;
      mostrar = mostrarPrimeiraPagina;
    }

    // Valida se tem outro tipo Principal
    if (tipo === 'P') {
      if (
        listaRegistros.filter(
          it =>
            it.tipo === 'P' &&
            it.id !== id &&
            it.reportContentPaiId === reportContentPaiId,
        ).length > 0
      ) {
        toast.error(
          'Só é permitido haver 1 registro de tipo Principal por relatório',
        );
        return;
      }
    }

    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      if (id) {
        await api.put(`/report/${id}`, {
          descricao,
          nome,
          produtoId,
          tipo,
          ignorarPrimeiraPagina: ignorar,
          mostrarPrimeiraPagina: mostrar,
          reportContentPaiId,
        });
      } else {
        await api.post(`/report`, {
          id,
          descricao,
          nome,
          produtoId,
          tipo,
          ignorarPrimeiraPagina: ignorar,
          mostrarPrimeiraPagina: mostrar,
          reportContentPaiId,
        });
      }

      toast.success('Feito!', {});
      Swal.close();

      setFormModalState(false);
      setRegistroEditing({} as iReport);
      carregarDados();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function ativarDesativar(tabelaId: number, ativar: boolean) {
    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      await api.delete(`/report/${tabelaId}?ativo=${ativar}`);
      toast.success('Feito!', {});

      carregarDados();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleDelete(tabelaId: number) {
    const result = await Swal.fire({
      title: `Deseja desativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    });

    if (result.isConfirmed) {
      ativarDesativar(tabelaId, false);
      Swal.close();
    }
  }

  async function handleAtivar(tabelaId: number) {
    Swal.fire({
      title: `Deseja reativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(tabelaId, true);
        Swal.close();
      }
    });
  }

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <Button
                className="btn-icon btn-2"
                color="primary"
                type="button"
                disabled={isSaving || isLoading}
                onClick={handleInsertReport}
              >
                <FaPlusCircle />
              </Button>

              <Link
                className="mr-2 float-right"
                to={{
                  pathname: `/admin/imagem-template`,
                  state: {},
                }}
              >
                <Button
                  disabled={isSaving || isLoading}
                  className="btn-icon"
                  color="primary"
                  type="button"
                >
                  Imagens
                </Button>
              </Link>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalReportState}
              toggle={() => setFormModalReportState(!formModalReportState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nome"
                            value={registroReportEditing.nome}
                            type="text"
                            onChange={text =>
                              setRegistroReportEditing({
                                ...registroReportEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Descrição</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={registroReportEditing.descricao ?? ''}
                            type="text"
                            onChange={text =>
                              setRegistroReportEditing({
                                ...registroReportEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving || isLoading}
                          onClick={handleSaveReportTabela}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nome"
                            value={registroEditing.nome}
                            type="text"
                            onChange={text =>
                              setRegistroEditing({
                                ...registroEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Descrição</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={registroEditing.descricao ?? ''}
                            type="text"
                            onChange={text =>
                              setRegistroEditing({
                                ...registroEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Tipo *</small>

                        <Input
                          placeholder="Selecione o Tipo *"
                          type="select"
                          value={registroEditing.tipo}
                          onChange={(event: any) => {
                            setRegistroEditing({
                              ...registroEditing,
                              tipo: event.target.value,
                            });
                          }}
                        >
                          <option value="">Selecione</option>
                          {tipoReport
                            .filter(it => it.selecionavel === true)
                            ?.map(it => (
                              <option value={it.tipo}>{it.label}</option>
                            ))}
                        </Input>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Produto (padrão disponível para todos):</small>
                        <Select
                          closeMenuOnSelect={false}
                          placeholder="Selecione..."
                          isMulti
                          options={listaProdutos.map((item: Produto) => {
                            return { label: item.nome, value: item.id };
                          })}
                          value={listaProdutos
                            .filter((it: Produto) => {
                              if (!registroEditing?.produtoId) return false;
                              return registroEditing.produtoId.includes(it.id);
                            })
                            ?.map((item: Produto) => {
                              return {
                                label: item.nome,
                                value: item.id,
                              };
                            })}
                          onChange={(e: any) => {
                            setRegistroEditing({
                              ...registroEditing,
                              produtoId: e.map((it: any) => it.value),
                            });
                          }}
                        />
                      </FormGroup>
                      <FormControlLabel
                        hidden={
                          registroEditing.tipo === 'C' ||
                          registroEditing.tipo === 'R' ||
                          registroEditing.tipo === 'P'
                        }
                        control={
                          <Checkbox
                            checked={registroEditing.ignorarPrimeiraPagina}
                            onChange={event =>
                              setRegistroEditing({
                                ...registroEditing,
                                ignorarPrimeiraPagina: event.target.checked,
                              })
                            }
                            color="primary"
                          />
                        }
                        label="Ignorar na Primeira Página"
                      />{' '}
                      <br />
                      <FormControlLabel
                        hidden={
                          registroEditing.tipo === 'C' ||
                          registroEditing.tipo === 'R' ||
                          registroEditing.tipo === 'P'
                        }
                        control={
                          <Checkbox
                            checked={registroEditing.mostrarPrimeiraPagina}
                            onChange={event =>
                              setRegistroEditing({
                                ...registroEditing,
                                mostrarPrimeiraPagina: event.target.checked,
                              })
                            }
                            color="primary"
                          />
                        }
                        label="Mostrar apenas na Primeira Página"
                      />
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving || isLoading}
                          onClick={handleSaveTabela}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">Lista de Relatórios</h3>
                  </Col>
                  <Col sm="3">
                    <FormControlLabel
                      className="float-right"
                      control={
                        <Checkbox
                          checked={mostrarDesativados}
                          onChange={event =>
                            setMostrarDesativados(event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Mostrar desativados"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {listaRegistros
                  .filter(item => item.tipo === 'R')
                  .filter(item =>
                    !mostrarDesativados ? item.ativo === true : true,
                  )
                  .map(item => (
                    <div className="report-pai mb-5">
                      <Row className="report-pai-header">
                        <Col sm="6">
                          <h3 className="mb-0">{item.nome}</h3>
                          <small>{item.descricao ?? ''}</small>
                        </Col>
                        <Col sm="2">
                          <div
                            hidden={!mostrarDesativados}
                            className={`badge badge-${
                              item.ativo ? 'success' : 'danger'
                            }`}
                          >
                            {item.ativo
                              ? 'Relatório Ativo'
                              : 'Relatório Desativado'}
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="float-right">
                            {item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="primary"
                                  type="button"
                                  disabled={isSaving || isLoading}
                                  onClick={() => {
                                    handleInsert(item.id);
                                  }}
                                >
                                  <FaPlusCircle /> Adicionar Elemento
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="warning"
                                  type="button"
                                  disabled={isSaving || isLoading}
                                  onClick={() => {
                                    handleUpdateReport(item);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="danger"
                                  type="button"
                                  disabled={isSaving || isLoading}
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </>
                            )}
                            {!item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="info"
                                  type="button"
                                  disabled={isSaving || isLoading}
                                  onClick={() => {
                                    handleAtivar(item.id);
                                  }}
                                >
                                  <FaCheckCircle />
                                </Button>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row hidden={item.ativo === false}>
                        <Col sm="11" className=" ml-4 mt-1">
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th>Nome</th>
                                <th>Descrição</th>
                                <th>Tipo</th>
                                <th>Produtos</th>
                                <th hidden={!mostrarDesativados}>Ativo</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              {listaRegistros
                                .filter(itemFilho =>
                                  !mostrarDesativados
                                    ? itemFilho.ativo === true
                                    : true,
                                )
                                .filter(
                                  itemFilho =>
                                    itemFilho.reportContentPaiId === item.id &&
                                    itemFilho.tipo !== 'R',
                                )
                                .map(itemFilho => (
                                  <tr key={itemFilho.id}>
                                    <td className="py-2">{itemFilho.nome}</td>
                                    <td className="py-2">
                                      {itemFilho.descricao ?? ''}
                                    </td>
                                    <td className="py-2">
                                      {tipoReport.find(
                                        it => it.tipo === itemFilho.tipo,
                                      )?.label ?? ''}
                                    </td>
                                    <td className="py-2">
                                      {itemFilho.produtoId &&
                                      itemFilho.produtoId.length > 0
                                        ? listaProdutos
                                            .filter(it =>
                                              itemFilho.produtoId.includes(
                                                it.id,
                                              ),
                                            )
                                            ?.map(it => it.nome)
                                            .join(', ')
                                        : 'Todos os Produtos'}
                                    </td>
                                    <td
                                      className="py-2"
                                      hidden={!mostrarDesativados}
                                    >
                                      <div
                                        className={`badge badge-${
                                          itemFilho.ativo ? 'success' : 'danger'
                                        }`}
                                      >
                                        {itemFilho.ativo ? 'Sim' : 'Não'}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="float-right">
                                        {itemFilho.ativo && (
                                          <>
                                            <Link
                                              className="mr-2"
                                              to={{
                                                pathname: `/admin/edicao-template`,
                                                state: {
                                                  stateReport: itemFilho,
                                                  itemPai: item,
                                                  produtos:
                                                    itemFilho.produtoId &&
                                                    itemFilho.produtoId.length >
                                                      0
                                                      ? listaProdutos.filter(
                                                          it =>
                                                            itemFilho.produtoId.includes(
                                                              it.id,
                                                            ),
                                                        )
                                                      : [],
                                                },
                                              }}
                                            >
                                              <Button
                                                size="sm"
                                                disabled={isSaving || isLoading}
                                                className="btn-icon"
                                                color="info"
                                                type="button"
                                              >
                                                Conteúdo
                                              </Button>
                                            </Link>
                                            <Button
                                              size="sm"
                                              className="btn-icon"
                                              color="warning"
                                              type="button"
                                              disabled={isSaving || isLoading}
                                              onClick={() => {
                                                handleUpdate(itemFilho);
                                              }}
                                            >
                                              <FaEdit />
                                            </Button>
                                            <Button
                                              size="sm"
                                              className="btn-icon"
                                              color="danger"
                                              type="button"
                                              disabled={isSaving || isLoading}
                                              onClick={() => {
                                                handleDelete(itemFilho.id);
                                              }}
                                            >
                                              <FaTimesCircle />
                                            </Button>
                                          </>
                                        )}
                                        {!itemFilho.ativo && (
                                          <>
                                            <Button
                                              size="sm"
                                              className="btn-icon"
                                              color="info"
                                              type="button"
                                              disabled={isSaving || isLoading}
                                              onClick={() => {
                                                handleAtivar(itemFilho.id);
                                              }}
                                            >
                                              <FaCheckCircle />
                                            </Button>
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  ))}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TemplateRelatorio;
