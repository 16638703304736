import { Label as LabelR, Row } from 'reactstrap';
import { Typography as TypographyMui } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Label = styled(LabelR)`
  margin-left: -1rem;
  font-size: 1rem;
  font-weight: 550;
  color: #000;
`;

export const Typography = styled(TypographyMui)`
  color: #000;
  text-align: center;
`;

export const CondicaoPagamentoRecursoRow = styled(Row)`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding-top: 6px;
`;

export const Th = styled.th`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e9ecef;
`;

export const Td = styled.td`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;
