import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
// reactsTrap components
import { Card, CardHeader, Table, Container, Row, Button } from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import api from 'services/api';
import { useLocation } from 'react-router-dom';
import { Td, TextField, Tr } from './styles';
import ItensAvulsosModel from '../../../models/ConfiguradorItensAvulsosLote';
import { PropostaContext } from '../Proposta';

interface LocationState {
  propostaId: number;
  orcamentoId: number;
  emprCodigo: string;
  geral: boolean | undefined;
}

const ConfiguradorItensAvulsos: React.FC = () => {
  const location = useLocation<LocationState>();
  const propostaVersaoId = location.state?.propostaId ?? null;
  const orcamentoId = location.state?.orcamentoId ?? null;
  const emprCodigo = location.state?.emprCodigo ?? '';
  const geral = location.state?.geral;
  const [itensAvulsos, setItensAvulsos] = useState([] as ItensAvulsosModel[]);
  const [item1, setItem1] = useState<number>();
  const [item2, setItem2] = useState<number>();
  const [item3, setItem3] = useState<number>();
  const [item4, setItem4] = useState<number>();
  const [item5, setItem5] = useState<number>();
  const [item6, setItem6] = useState<number>();
  const [item7, setItem7] = useState<number>();
  const [item8, setItem8] = useState<number>();
  const [item9, setItem9] = useState<number>();
  const [item10, setItem10] = useState<number>();
  const [quantidade1, setQuantidade1] = useState<number>();
  const [quantidade2, setQuantidade2] = useState<number>();
  const [quantidade3, setQuantidade3] = useState<number>();
  const [quantidade4, setQuantidade4] = useState<number>();
  const [quantidade5, setQuantidade5] = useState<number>();
  const [quantidade6, setQuantidade6] = useState<number>();
  const [quantidade7, setQuantidade7] = useState<number>();
  const [quantidade8, setQuantidade8] = useState<number>();
  const [quantidade9, setQuantidade9] = useState<number>();
  const [quantidade10, setQuantidade10] = useState<number>();
  const [atividade1, setAtividade1] = useState<number>();
  const [atividade2, setAtividade2] = useState<number>();
  const [atividade3, setAtividade3] = useState<number>();
  const [atividade4, setAtividade4] = useState<number>();
  const [atividade5, setAtividade5] = useState<number>();
  const [atividade6, setAtividade6] = useState<number>();
  const [atividade7, setAtividade7] = useState<number>();
  const [atividade8, setAtividade8] = useState<number>();
  const [atividade9, setAtividade9] = useState<number>();
  const [atividade10, setAtividade10] = useState<number>();
  const [controle1, setControle1] = useState(true);
  const [controle2, setControle2] = useState(true);
  const [controle3, setControle3] = useState(true);
  const [controle4, setControle4] = useState(true);
  const [controle5, setControle5] = useState(true);
  const [controle6, setControle6] = useState(true);
  const [controle7, setControle7] = useState(true);
  const [controle8, setControle8] = useState(true);
  const [controle9, setControle9] = useState(true);
  const [controle10, setControle10] = useState(true);
  const [editado, setEditado] = useState(false);
  const [tamanhoObjetoEnviado, setTamanhoObjetoEnviado] = useState<number>();
  const [tamanhoObjetoRetorno, setTamanhoObjetoRetorno] = useState<number>();
  const [objetoMenor, setObjetoMenor] = useState(false);

  const { propostaVersao, permiteModificacao } = useContext(PropostaContext);
  const [itensLinha, setItensLinha] = useState({} as ItensAvulsosModel);

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao tentar inserir um material!',
        html: mensagem,
      });
    }
  }

  async function adicionaObjeto() {
    setSwal('Inserindo materiais...');
    const rows: any[] = [];
    rows.push({
      columns: [
        {
          id: 1,
          item: item1,
          quantidade: quantidade1,
          atividade: atividade1,
        },
        {
          id: 2,
          item: item2,
          quantidade: quantidade2,
          atividade: atividade2,
        },
        {
          id: 3,
          item: item3,
          quantidade: quantidade3,
          atividade: atividade3,
        },
        {
          id: 4,
          item: item4,
          quantidade: quantidade4,
          atividade: atividade4,
        },
        {
          id: 5,
          item: item5,
          quantidade: quantidade5,
          atividade: atividade5,
        },
        {
          id: 6,
          item: item6,
          quantidade: quantidade6,
          atividade: atividade6,
        },
        {
          id: 7,
          item: item7,
          quantidade: quantidade7,
          atividade: atividade7,
        },
        {
          id: 8,
          item: item8,
          quantidade: quantidade8,
          atividade: atividade8,
        },
        {
          id: 9,
          item: item9,
          quantidade: quantidade9,
          atividade: atividade9,
        },
        {
          id: 10,
          item: item10,
          quantidade: quantidade10,
          atividade: atividade10,
        },
      ],
    });

    const codigoEmpresa = emprCodigo !== '' ? emprCodigo : '710';
    let count = 1;
    let tamanho = 0;
    while (count <= 10) {
      const arrayEnv = rows.filter(
        // eslint-disable-next-line no-loop-func
        (item: any) => item.columns[count - 1].item !== undefined,
      );
      if (arrayEnv.length > 0) {
        tamanho += 1;
      }
      // eslint-disable-next-line no-plusplus
      count++;
    }

    if (propostaVersaoId > 0) {
      const response = await api.post(`/integracao/item-lote`, {
        rows,
        emprCodigo: codigoEmpresa ?? '',
        geral,
        propostaId: propostaVersaoId,
      });
      setItensAvulsos(response.data);
      setTamanhoObjetoEnviado(rows.length);
      setTamanhoObjetoRetorno(response.data.length);
      if (response.data.length < tamanho) {
        setSwal(
          'Os materiais destacados não foram inseridos por não possuir cadastro ou a atividade informada não existe!',
          'error',
        );
        setObjetoMenor(true);
      } else {
        toast.success('Registro concluído');
        setSwal();
      }
      setEditado(true);
    } else if (orcamentoId > 0) {
      const response = await api.post(`/integracao/orcamento/item-lote`, {
        rows,
        emprCodigo: codigoEmpresa ?? '',
        geral,
        orcamentoId,
      });
      setItensAvulsos(response.data);
      setTamanhoObjetoEnviado(rows.length);
      setTamanhoObjetoRetorno(response.data.length);
      if (response.data.length < tamanho) {
        setSwal(
          'Os materiais destacados não foram inseridos por não possuir cadastro ou a atividade informada não existe!',
          'error',
        );
        setObjetoMenor(true);
      } else {
        toast.success('Registro concluído');
        setSwal();
      }
      setEditado(true);
      toast.success('Registro concluído');
    } else {
      setSwal('Erro ao tentar inserir!', 'error');
    }
  }

  useEffect(() => {
    if (editado && objetoMenor === true) {
      if (item1 !== undefined) {
        const listItem1 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item1 &&
            Number(item.ativCodigo) === atividade1 &&
            Number(item.quantidade) === quantidade1,
        );
        setControle1(listItem1.length > 0);
        if (listItem1.length > 0) {
          setItem1(undefined);
          setQuantidade1(undefined);
          setAtividade1(undefined);
        }
      }
      if (item2 !== undefined) {
        const listItem2 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item2 &&
            Number(item.ativCodigo) === atividade2 &&
            Number(item.quantidade) === quantidade2,
        );
        setControle2(listItem2.length > 0);
        if (listItem2.length > 0) {
          setItem2(undefined);
          setQuantidade2(undefined);
          setAtividade2(undefined);
        }
      }
      if (item3 !== undefined) {
        const listItem3 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item3 &&
            Number(item.ativCodigo) === atividade3 &&
            Number(item.quantidade) === quantidade3,
        );
        setControle3(listItem3.length > 0);
        if (listItem3.length > 0) {
          setItem3(undefined);
          setQuantidade3(undefined);
          setAtividade3(undefined);
        }
      }
      if (item4 !== undefined) {
        const listItem4 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item4 &&
            Number(item.ativCodigo) === atividade4 &&
            Number(item.quantidade) === quantidade4,
        );
        setControle4(listItem4.length > 0);
        if (listItem4.length > 0) {
          setItem4(undefined);
          setQuantidade4(undefined);
          setAtividade4(undefined);
        }
      }
      if (item5 !== undefined) {
        const listItem5 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item5 &&
            Number(item.ativCodigo) === atividade5 &&
            Number(item.quantidade) === quantidade5,
        );
        setControle5(listItem5.length > 0);
        if (listItem5.length > 0) {
          setItem5(undefined);
          setQuantidade5(undefined);
          setAtividade5(undefined);
        }
      }
      if (item6 !== undefined) {
        const listItem6 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item6 &&
            Number(item.ativCodigo) === atividade6 &&
            Number(item.quantidade) === quantidade6,
        );
        setControle6(listItem6.length > 0);
        if (listItem6.length > 0) {
          setItem6(undefined);
          setQuantidade6(undefined);
          setAtividade6(undefined);
        }
      }
      if (item7 !== undefined) {
        const listItem7 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item7 &&
            Number(item.ativCodigo) === atividade7 &&
            Number(item.quantidade) === quantidade7,
        );
        setControle7(listItem7.length > 0);
        if (listItem7.length > 0) {
          setItem7(undefined);
          setQuantidade7(undefined);
          setAtividade7(undefined);
        }
      }
      if (item8 !== undefined) {
        const listItem8 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item8 &&
            Number(item.ativCodigo) === atividade8 &&
            Number(item.quantidade) === quantidade8,
        );
        setControle8(listItem8.length > 0);
        if (listItem8.length > 0) {
          setItem8(undefined);
          setQuantidade8(undefined);
          setAtividade8(undefined);
        }
      }
      if (item9 !== undefined) {
        const listItem9 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item9 &&
            Number(item.ativCodigo) === atividade9 &&
            Number(item.quantidade) === quantidade9,
        );
        setControle9(listItem9.length > 0);
        if (listItem9.length > 0) {
          setItem9(undefined);
          setQuantidade9(undefined);
          setAtividade9(undefined);
        }
      }
      if (item10 !== undefined) {
        const listItem10 = itensAvulsos.filter(
          item =>
            Number(item.diteCodigo) === item10 &&
            Number(item.ativCodigo) === atividade10 &&
            Number(item.quantidade) === quantidade10,
        );
        setControle10(listItem10.length > 0);
        if (listItem10.length > 0) {
          setItem10(undefined);
          setQuantidade10(undefined);
          setAtividade10(undefined);
        }
      }
    }
  }, [editado, itensAvulsos]);

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Cadastro de materiais avulsos em lote</h3>
              </CardHeader>
              <Table bordered responsive>
                <thead>
                  <th>Item</th>
                  <th>Quantidade</th>
                  <th>Atividade</th>
                </thead>
                <tbody>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle1 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle1 === true ? 0 : item1}
                        onChange={event => setItem1(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle1 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle1 === true ? 0 : quantidade1}
                        onChange={event =>
                          setQuantidade1(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle1 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle1 === true ? 0 : atividade1}
                        onChange={event =>
                          setAtividade1(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle2 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle2 === true ? '' : item2}
                        onChange={event => setItem2(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle2 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle2 === true ? '' : quantidade2}
                        onChange={event =>
                          setQuantidade2(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle2 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle2 === true ? '' : atividade2}
                        onChange={event =>
                          setAtividade2(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle3 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle3 === true ? '' : item3}
                        onChange={event => setItem3(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle3 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle3 === true ? '' : quantidade3}
                        onChange={event =>
                          setQuantidade3(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle3 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle3 === true ? '' : atividade3}
                        onChange={event =>
                          setAtividade3(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle4 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle4 === true ? '' : item4}
                        onChange={event => setItem4(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle4 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle4 === true ? '' : quantidade4}
                        onChange={event =>
                          setQuantidade4(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle4 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle4 === true ? '' : atividade4}
                        onChange={event =>
                          setAtividade4(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle5 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle5 === true ? '' : item5}
                        onChange={event => setItem5(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle5 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle5 === true ? '' : quantidade5}
                        onChange={event =>
                          setQuantidade5(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle5 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle5 === true ? '' : atividade5}
                        onChange={event =>
                          setAtividade5(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle6 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle6 === true ? '' : item6}
                        onChange={event => setItem6(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle6 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle6 === true ? '' : quantidade6}
                        onChange={event =>
                          setQuantidade6(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle6 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle6 === true ? '' : atividade6}
                        onChange={event =>
                          setAtividade6(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle7 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle7 === true ? '' : item7}
                        onChange={event => setItem7(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle7 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle7 === true ? '' : quantidade7}
                        onChange={event =>
                          setQuantidade7(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle7 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle7 === true ? '' : atividade7}
                        onChange={event =>
                          setAtividade7(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle8 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle8 === true ? '' : item8}
                        onChange={event => setItem8(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle8 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle8 === true ? '' : quantidade8}
                        onChange={event =>
                          setQuantidade8(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle8 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle8 === true ? '' : atividade8}
                        onChange={event =>
                          setAtividade8(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle9 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle9 === true ? '' : item9}
                        onChange={event => setItem9(Number(event.target.value))}
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle9 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle9 === true ? '' : quantidade9}
                        onChange={event =>
                          setQuantidade9(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle9 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle9 === true ? '' : atividade9}
                        onChange={event =>
                          setAtividade9(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle10 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={editado && controle10 === true ? '' : item10}
                        onChange={event =>
                          setItem10(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle10 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={
                          editado && controle10 === true ? '' : quantidade10
                        }
                        onChange={event =>
                          setQuantidade10(Number(event.target.value))
                        }
                      />
                    </Td>
                    <Td
                      editavel
                      style={{
                        backgroundColor:
                          controle10 === true ? '#fff' : '#ffd5de',
                      }}
                    >
                      <TextField
                        size="medium"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={
                          editado && controle10 === true ? '' : atividade10
                        }
                        onChange={event =>
                          setAtividade10(Number(event.target.value))
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td editavel>
                      <small className="text-muted">{}</small>
                    </Td>
                    <Td editavel>
                      <small className="text-muted">{}</small>
                    </Td>
                    <Td editavel className="mb-2 mt-2 float-right">
                      <Button
                        size="sm"
                        className="btn-2"
                        style={{ backgroundColor: '#1a8c5b' }}
                        color="success"
                        type="button"
                        onClick={() => adicionaObjeto()}
                      >
                        Incluir
                      </Button>
                    </Td>
                  </Tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorItensAvulsos;
