import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'react-apexcharts';

import formatCurrency from 'utils/formatCurrency';
import formatNumeros from 'utils/formatNumeros';

interface Props {
  valores: any[];
  larguraTotal: boolean | true;
}

function FVGeracaoConsumo(props: Props): JSX.Element {
  const { valores, larguraTotal } = props;

  const [reportContent, setReportContent] = useState<any[]>([]);
  const [isLoadingReport, setIsLoadingReport] = useState(true);

  const [consumo, setConsumo] = useState<any[]>([]);
  const [geracao, setGeracao] = useState<any[]>([]);

  useEffect(() => {
    if (valores && valores.length > 0) {
      setReportContent(valores);
      setIsLoadingReport(false);
    }
  }, [valores]);

  useEffect(() => {
    if (reportContent && reportContent.length > 0) {
      setReportContent(valores);
      setIsLoadingReport(false);
    }
  }, [reportContent]);

  const chart = {
    options: {
      chart: {
        id: 'custo-total-bar',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: '#d6d6d6',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        lines: {
          show: true,
        },
        labels: {
          formatter(value: any) {
            return formatNumeros(Number(value));
          },
        },
      },
      tooltip: {
        x: {
          formatter(val: any) {
            return `${val}`;
          },
          title: {
            formatter(seriesName: any) {
              return `${seriesName}:`;
            },
          },
        },
        y: {
          formatter(val: any) {
            return `${formatNumeros(Number(val))}`;
          },
          title: {
            formatter(seriesName: any) {
              return `${seriesName}:`;
            },
          },
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
      },
    },
    series: [
      {
        name: 'Geração',
        data: reportContent
          .filter(item => item.tipo === 'Geração')
          .map(item => {
            const {
              consumoJaneiro,
              consumoFevereiro,
              consumoMarco,
              consumoAbril,
              consumoMaio,
              consumoJunho,
              consumoJulho,
              consumoAgosto,
              consumoSetembro,
              consumoOutubro,
              consumoNovembro,
              consumoDezembro,
            } = item;

            return [
              consumoJaneiro,
              consumoFevereiro,
              consumoMarco,
              consumoAbril,
              consumoMaio,
              consumoJunho,
              consumoJulho,
              consumoAgosto,
              consumoSetembro,
              consumoOutubro,
              consumoNovembro,
              consumoDezembro,
            ];
          })[0],
        color: 'rgb(255, 99, 132)',
      },
      {
        name: 'Consumo',
        data: reportContent
          .filter(item => item.tipo === 'Consumo total cliente')
          .map(item => {
            const {
              consumoJaneiro,
              consumoFevereiro,
              consumoMarco,
              consumoAbril,
              consumoMaio,
              consumoJunho,
              consumoJulho,
              consumoAgosto,
              consumoSetembro,
              consumoOutubro,
              consumoNovembro,
              consumoDezembro,
            } = item;

            return [
              consumoJaneiro,
              consumoFevereiro,
              consumoMarco,
              consumoAbril,
              consumoMaio,
              consumoJunho,
              consumoJulho,
              consumoAgosto,
              consumoSetembro,
              consumoOutubro,
              consumoNovembro,
              consumoDezembro,
            ];
          })[0],
        color: 'rgb(75, 192, 192)',
      },
    ],
  };

  return (
    <>
      {!isLoadingReport ? (
        <div
          className="grafico"
          style={{
            width: larguraTotal ? '100%' : '70vw',
          }}
        >
          <Chart
            height="300px"
            options={chart.options}
            series={chart.series}
            type="bar"
          />
        </div>
      ) : (
        <>Carregando Gráfico</>
      )}
    </>
  );
}

export default FVGeracaoConsumo;
