/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { ReactNode, useState } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  FormGroup,
  InputGroup,
  Row,
  NavItem,
  NavbarBrand,
  Modal,
  Card,
  CardBody,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';

import { FaArrowLeft } from 'react-icons/fa';

import { Avatar } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import getInitials from 'utils/getInitials';

import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import classnames from 'classnames';
import Usuario from '../../models/Usuario';

import { useAuth } from '../../hooks/auth';

import config from '../../config/index.js';
import imageLogo from '../../assets/img/brand/logo-fockink.png';
import ModalReport from './ModalReport';

type Props = {
  brandText: string;
  sidenavOpen: any;
  toggleSidenav: any;
};

const AdminNavbar: ReactNode = (props: Props) => {
  const location = useLocation();
  const history = useHistory();

  const locationState = location.state as any;

  const returnToResultado = locationState?.returnToResultado || false;
  const [formModalState, setFormModalState] = useState(false);

  const { usuario, signOut }: { usuario: Usuario; signOut: () => void } =
    useAuth();

  const { brandText, sidenavOpen, toggleSidenav } = props;

  const labelUsuario = usuario?.apelido || usuario.username;

  function handleSignOut() {
    signOut();
  }

  function handleOpenModalReport() {
    setFormModalState(!formModalState);
  }

  const logo = {
    innerLink: '/admin/index',
    imgSrc: imageLogo,
    imgAlt: '...',
  } as any;

  return (
    <>
      <Navbar className="navbar-top navbar-expand navbar-dark">
        <Container fluid>
          <Collapse navbar isOpen>
            <Row>
              {location.state && !returnToResultado && (
                <FormGroup className="mr-3">
                  <InputGroup className="input-group-alternative">
                    <Button
                      size="sm"
                      className="btn-icon btn-2"
                      color="primary"
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <FaArrowLeft />
                    </Button>
                  </InputGroup>
                </FormGroup>
              )}
              {location.state && returnToResultado && (
                <FormGroup className="mr-3">
                  <InputGroup className="input-group-alternative">
                    <Button
                      size="sm"
                      className="btn-icon btn-2"
                      color="primary"
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: '/admin/etapas-orcamento',
                          state: {
                            orcamentoVersaoId: locationState?.orcamentoId,
                            openResultado: true,
                          },
                        });
                      }}
                    >
                      <FaArrowLeft />
                    </Button>
                  </InputGroup>
                </FormGroup>
              )}
              <Link
                className="h4 mb-0 mt-1 text-white text-uppercase d-none d-lg-inline-block"
                to="/"
              >
                Fockink |Portal de Orçamentos {config.description}{' '}
                {brandText ? `| ${brandText}` : ''}
              </Link>
            </Row>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames('pr-3 sidenav-toggler', {
                    active: sidenavOpen,
                  })}
                  onClick={toggleSidenav}
                  role="button"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem>
                {logo ? (
                  <Link to="/">
                    <img
                      alt={logo.imgAlt}
                      style={{ height: '1.5em', marginRight: '10px' }}
                      src={logo.imgSrc}
                    />
                  </Link>
                ) : null}
              </NavItem>

              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <div className="avatar-group">
                      <Avatar
                        src={usuario?.imagem}
                        style={{ backgroundColor: deepPurple[800] }}
                      >
                        {getInitials(labelUsuario)}
                      </Avatar>
                    </div>

                    <span className="mb-0 ml-3 text-sm">
                      {capitalizeFirstLetter(labelUsuario)}
                    </span>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bem-vindo!</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => {
                      history.push({
                        pathname: '/admin/meu-perfil',
                      });
                    }}
                  >
                    <i className="ni ni-badge" />
                    <span>Meu Perfil</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      handleOpenModalReport();
                    }}
                  >
                    <i className="fa fa-question" />
                    <span>Ajuda</span>
                  </DropdownItem>
                  <DropdownItem onClick={handleSignOut}>
                    <i className="ni ni-user-run" />
                    <span>Sair</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            {/* Modal ajuda */}
            {formModalState && (
              <ModalReport
                setFormModalState={setFormModalState}
                formModalState={formModalState}
                labelUsuario={labelUsuario}
              />
            )}
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
