import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Alert,
} from 'reactstrap';

import Swal from 'sweetalert2';
import api from 'services/api';
import logo from '../../assets/img/brand/argon-react.png';

import { useAuth } from '../../hooks/auth';

const Login: React.FC = () => {
  const [credential, setCredential] = useState('');
  const [password, setPassword] = useState('');

  const [requisitandoLogin, setRequisitandoLogin] = useState(false);
  const [nameLoginButton, setNameLoginButton] = useState('Entrar');

  const { signIn, errorMessage } = useAuth();

  function handleSubmit() {
    setRequisitandoLogin(true);

    signIn({ credential, password });
  }

  useEffect(() => {
    setNameLoginButton(requisitandoLogin ? 'Entrando...' : 'Entrar');
  }, [requisitandoLogin]);

  useEffect(() => {
    const erroDisparado = errorMessage !== '';

    if (erroDisparado) {
      setRequisitandoLogin(false);
    }
  }, [errorMessage]);

  async function modalEsqueciSenha() {
    const { value: email } = await Swal.fire({
      title: 'Recuperação de senha',
      input: 'email',
      inputPlaceholder: 'Informe o endereço de e-mail da sua conta',
      confirmButtonText: `Prosseguir com recuperação`,
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      allowOutsideClick: false,
      inputValidator: value => {
        if (!value) return 'É preciso informar um endereço de e-mail';
        return null;
      },
    });

    if (email) {
      Swal.fire({
        icon: 'info',
        text: `Disparando processo de recuperação de senha`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await api.put(`/usuario/recuperar-senha`, { email });

      Swal.fire('Um e-mail foi enviado!', '', 'success');
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <div className="mb-5 text-center">
                  <img src={logo} alt="" style={{ maxHeight: '2.5rem' }} />
                </div>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Usuário ou e-mail"
                    type="text"
                    onChange={text => setCredential(text.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Senha"
                    type="password"
                    autoComplete="new-password"
                    onChange={text => setPassword(text.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              {errorMessage && (
                <Alert className="text-center" color="danger">
                  {errorMessage}
                </Alert>
              )}

              <div className="text-center">
                <Button
                  disabled={requisitandoLogin}
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {nameLoginButton}
                </Button>
              </div>

              <div className="text-right">
                <Link to="/" onClick={() => modalEsqueciSenha()}>
                  Esqueci Minha Senha
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
