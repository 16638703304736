import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Modal,
  InputGroup,
  Input,
  Col,
  CardBody,
  Table,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
// core components

import Header from 'components/Headers/Header.js';

import CustomTable from 'components/CustomTable';

import Swal from 'sweetalert2';

import api from 'services/api';

import ProdutoModel from 'models/Produto';
import { FaCheck, FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Select from 'react-select';
import ModeloModel from '../../../models/ConfiguradorModelo';
import LanceModel from '../../../models/ConfiguradorLance';

const ConfiguradorModelos: React.FC = () => {
  const [modelos, setModelos] = useState([] as ModeloModel[]);
  const [lances, setLances] = useState([] as LanceModel[]);
  const [produtos, setProdutos] = useState([] as ProdutoModel[]);
  const [produtosRegistros, setProdutosRegistros] = useState([] as any[]);
  const [registrosFiltrados, setRegistrosFiltrados] = useState([] as any[]);
  const [registrosGerais, setRegistrosGerais] = useState([] as any[]);
  const [formModalModelo, setFormModalModelo] = useState(false);
  const [registroEditing, setRegistroEditing] = useState({} as any);

  const [modeloFiltrar, setModeloFiltrar] = useState(0);
  const [produtoFiltrar, setProdutoFiltrar] = useState(0);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterChanged, setFilterChanged] = useState(true);

  async function listaModelos() {
    const response = await api.get('/integracao/modelos');
    setModelos(response.data);
  }

  async function listaLances() {
    const response = await api.get('/integracao/declividades/lances-tubos');
    setLances(response.data);
  }

  async function listaProdutos() {
    const response = await api.get('/produto');
    setProdutos(response.data);
  }

  async function listaRegistros() {
    const responseRegistros = await api.get('/integracao/declividades');
    const responseProdutos = await api.get('/integracao/declividades/produtos');

    setProdutosRegistros(responseProdutos.data);

    let filtrado = responseRegistros.data;

    if (produtoFiltrar && produtoFiltrar !== 0) {
      filtrado = filtrado.filter((item: any) =>
        responseProdutos.data.find(
          (it2: any) =>
            it2.idModelo === item.idModelo &&
            it2.idLanceTubo === item.idLanceTubo &&
            it2.idProduto === produtoFiltrar,
        ),
      );
    }

    if (modeloFiltrar && modeloFiltrar !== 0) {
      filtrado = filtrado.filter(
        (item: any) => item.idModelo === modeloFiltrar,
      );
    }

    setRegistrosGerais(responseRegistros.data);
    setRegistrosFiltrados(filtrado);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaModelos();
    listaLances();
    listaRegistros();
    listaProdutos();
  }, []);

  async function filtrar() {
    // if (!modeloFiltrar || !produtoFiltrar) return;
    setIsLoading(true);
    await listaRegistros();
    setIsLoading(false);
    setFilterChanged(false);
  }

  useEffect(() => {
    if (filterChanged === true) {
      setRegistrosFiltrados([]);
    }
  }, [filterChanged]);

  async function handleDelete(registro: any) {
    Swal.fire({
      title: `Deseja excluir o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.post(`/integracao/declividades`, {
          acao: 'D',
          idLanceTubo: registro.idLanceTubo,
          idModelo: registro.idModelo,
          seq: registro.seq,
          declividadeLimite: registro.declividadeLimite,
          declividadeAte: registro.declividadeAte,
          produtos: produtosRegistros
            .filter(
              item =>
                item.idModelo === registro.idModelo &&
                item.idLanceTubo === registro.idLanceTubo,
            )
            // eslint-disable-next-line array-callback-return, consistent-return
            ?.map((item: any) => {
              const prodReturn = produtos.find(pr => pr.id === item.idProduto);

              if (prodReturn)
                return { label: prodReturn.nome, value: prodReturn.id };
            })
            .map((item: any) => item.value),
        });
        Swal.close();
        toast.success('Registro excluído');
        filtrar();
      }
    });
  }

  async function handleEdit(registro: any) {
    setIsSaving(false);
    setRegistroEditing({
      ...registro,
      tipo: 'U',
      produtos: produtosRegistros
        .filter(
          item =>
            item.idModelo === registro.idModelo &&
            item.idLanceTubo === registro.idLanceTubo,
        )
        // eslint-disable-next-line array-callback-return, consistent-return
        ?.map((item: any) => {
          const prodReturn = produtos.find(pr => pr.id === item.idProduto);

          if (prodReturn)
            return { label: prodReturn.nome, value: prodReturn.id };
        }),
    });
    setFormModalModelo(!formModalModelo);
  }

  async function handleNew() {
    setIsSaving(false);
    setRegistroEditing({ tipo: 'I' } as any);
    setFormModalModelo(!formModalModelo);
  }

  async function handleSave(registro: any) {
    try {
      if (!registro.produtos || registro.produtos.length === 0) {
        toast.error('Um ou mais produtos devem ser informados');
        return;
      }

      if (
        !registro.idLanceTubo ||
        !registro.idModelo ||
        !registro.declividadeAte ||
        !registro.declividadeLimite
      ) {
        toast.error('Todos os registros com * devem ser preenchidos');
        return;
      }

      const produtosSalvar = registro.produtos;
      const produtosSalvos = produtosRegistros.filter(
        (item: any) =>
          item.idModelo === registro.idModelo &&
          item.idLanceTubo === registro.idLanceTubo,
      );

      const existemRegistros = registrosGerais.filter(
        (item: any) =>
          item.idModelo === registro.idModelo &&
          item.idLanceTubo === registro.idLanceTubo,
      );

      let salvar = true;
      let existeDivergencia = false;

      const idsSalvar = registro.produtos.map((item: any) => item.value);
      const idsSalvos = produtosRegistros.map((item: any) => item.idProduto);

      if (existemRegistros.length === 0 || produtosSalvos.length === 0) {
        // Por algum motivo, não existe registro salvo. Então, não precisa mostrar a mensagem
        existeDivergencia = false;
      } else if (produtosSalvar.length !== produtosSalvos.length) {
        existeDivergencia = true;
      } else if (idsSalvar.every((ai: any) => idsSalvos.includes(ai))) {
        // Ambos os arrays são iguais. Salva
        existeDivergencia = false;
      } else {
        existeDivergencia = true;
      }

      if (existeDivergencia) {
        const resposta = await Swal.fire({
          title: `Atenção`,
          html: `Já existem combinações de Lance x Modelo selecionados que estão associados ao(s) produto(s): <br /><i>
        ${produtosSalvos
          .map(
            (item: any) =>
              produtos.find(pr => pr.id === item.idProduto)?.nome ?? '',
          )
          .join('<br />')}</i>
          <br /><br /> Ao salvar, todos os registros passarão a estar associados <u>apenas</u> ao(s) seguinte(s) produto(s): <br />
          <i>${produtosSalvar.map((item: any) => item.label).join('<br />')}</i>
        <br /><br /><strong>Deseja continuar?</strong>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Sim`,
          confirmButtonColor: '#d33',
          cancelButtonText: `Não`,
        });

        salvar = resposta.isConfirmed;
      }

      if (salvar) {
        setIsSaving(true);

        await api.post(`/integracao/declividades`, {
          acao: registro.tipo,
          idLanceTubo: registro.idLanceTubo,
          idModelo: registro.idModelo,
          seq: registro.seq,
          declividadeLimite: registro.declividadeLimite,
          declividadeAte: registro.declividadeAte,
          produtos: registro.produtos.map((item: any) => item.value),
        });

        setFormModalModelo(!formModalModelo);
        toast.success('Alterações salvas');
        filtrar();
      }
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleNew}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalModelo}
              toggle={() => setFormModalModelo(!formModalModelo)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Registro</small>
                    </div>
                    <Form role="form">
                      <Row>
                        <FormGroup className="col-sm-12">
                          <small>Modelos *:</small>
                          <InputGroup className="input-group-alternative input-group">
                            <Input
                              placeholder="Modelo"
                              type="select"
                              value={registroEditing.idModelo}
                              onChange={event =>
                                setRegistroEditing({
                                  ...registroEditing,
                                  idModelo: Number(event.target.value),
                                })
                              }
                            >
                              <option value="">Selecione...</option>
                              {modelos.map(modelo => (
                                <option value={modelo.id}>{modelo.nome}</option>
                              ))}
                            </Input>
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="col-sm-12">
                          <small>Lances *:</small>
                          <InputGroup className="input-group-alternative input-group">
                            <Input
                              placeholder="Lances"
                              type="select"
                              value={registroEditing.idLanceTubo || ''}
                              onChange={event =>
                                setRegistroEditing({
                                  ...registroEditing,
                                  idLanceTubo: Number(event.target.value),
                                })
                              }
                            >
                              <option value="">Selecione...</option>
                              {lances.map(lance => (
                                <option value={lance.id}>{lance.nome}</option>
                              ))}
                            </Input>
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="col-sm-6">
                          <small>Declividade Limite *:</small>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Declividade Limite"
                              value={registroEditing.declividadeLimite}
                              type="number"
                              onChange={text =>
                                setRegistroEditing({
                                  ...registroEditing,
                                  declividadeLimite: Number(text.target.value),
                                })
                              }
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="col-sm-6">
                          <small>Declividade Até *:</small>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Declividade Até"
                              value={registroEditing.declividadeAte}
                              type="number"
                              onChange={text =>
                                setRegistroEditing({
                                  ...registroEditing,
                                  declividadeAte: Number(text.target.value),
                                })
                              }
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="col-sm-12">
                          <small>Produtos *:</small>
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Selecione os Produtos"
                            isMulti
                            options={produtos.map((item: ProdutoModel) => {
                              return { label: item.nome, value: item.id };
                            })}
                            value={registroEditing.produtos}
                            onChange={(e: any) => {
                              setRegistroEditing({
                                ...registroEditing,
                                produtos: [...e],
                              });
                            }}
                          />
                          <small>
                            <i>
                              Alteração dos produtos vão refletir em todos os
                              registros de mesmo Lance x Modelo cadastrados
                            </i>
                          </small>
                        </FormGroup>

                        <FormGroup className="col-sm-12 ">
                          <Button
                            className="btn-icon"
                            color="primary"
                            type="button"
                            onClick={() => {
                              handleSave(registroEditing);
                            }}
                            disabled={isSaving}
                          >
                            <FaCheck /> Salvar
                          </Button>
                        </FormGroup>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Lances por Modelos (Produtos e Declividades)
                </h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <InputGroup className="input-group-alternative input-group">
                      <Input
                        placeholder="Produto"
                        type="select"
                        value={produtoFiltrar}
                        onChange={event => {
                          setFilterChanged(true);
                          setProdutoFiltrar(Number(event.target.value));
                        }}
                      >
                        <option value="">Selecione o Produto...</option>
                        {produtos.map(produto => (
                          <option value={produto.id}>{produto.nome}</option>
                        ))}
                      </Input>
                      <Input
                        placeholder="Modelo"
                        type="select"
                        value={modeloFiltrar}
                        onChange={event => {
                          setFilterChanged(true);
                          setModeloFiltrar(Number(event.target.value));
                        }}
                      >
                        <option value="">Selecione o modelo...</option>
                        {modelos.map(lance => (
                          <option value={lance.id}>{lance.nome}</option>
                        ))}
                      </Input>
                      <Button
                        size="sm"
                        className="btn-icon float-right ml-3"
                        color="primary"
                        type="button"
                        onClick={filtrar}
                        disabled={isLoading}
                      >
                        Filtrar
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
                <div
                  style={{ minHeight: 700, width: '100%' }}
                  hidden={filterChanged === true}
                >
                  <Table
                    className="align-items-center table-flush mt-5"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th>Lance</th>
                        <th>Modelo</th>
                        <th>Declividade Limite</th>
                        <th>Declividade Até</th>
                        <th>Produtos</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {registrosFiltrados.map(
                        (registro: any, index: number) => {
                          return (
                            <tr key={`id_${index}`}>
                              <td>{registro.lance} </td>
                              <td>
                                {modelos.find(
                                  item => item.id === registro.idModelo,
                                )?.nome ?? ''}
                              </td>
                              <td>{registro.declividadeLimite} </td>
                              <td>{registro.declividadeAte} </td>
                              <td>
                                {produtosRegistros
                                  .filter(
                                    item =>
                                      item.idModelo === registro.idModelo &&
                                      item.idLanceTubo === registro.idLanceTubo,
                                  )
                                  ?.map((item: any) => {
                                    return (
                                      produtos.find(
                                        pr => pr.id === item.idProduto,
                                      )?.nome ?? ''
                                    );
                                  })
                                  .join(', ')}
                              </td>
                              <td>
                                <InputGroup>
                                  <Button
                                    size="sm"
                                    className="btn-icon"
                                    color="warning"
                                    type="button"
                                    onClick={() => {
                                      handleEdit(registro);
                                    }}
                                  >
                                    <FaEdit />
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="btn-icon float-right ml-1"
                                    color="danger"
                                    type="button"
                                    onClick={() => {
                                      handleDelete(registro);
                                    }}
                                  >
                                    <FaTimesCircle />
                                  </Button>
                                </InputGroup>
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </Table>
                </div>
                <div hidden={!filterChanged}>
                  <p className="mt-5">
                    Utilize o filtro por Produto ou Modelo para mostrar os
                    registros associados
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorModelos;
