import { useState, useEffect, useContext, memo } from 'react';

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';

import OrcamentoResposta from 'models/OrcamentoResposta';
import Questao from 'models/Questao';

import api from 'services/api';

import toast from 'react-hot-toast';
import FuncaoEntrada from 'models/FuncaoEntrada';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import PivoImage from 'assets/img/pivo.png';
import { arredondaDecimais } from 'utils/arredondaDecimais';

import QuestaoRelacao from 'models/QuestaoRelacao';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { FaArrowCircleRight, FaInfoCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import Material from 'models/Material';
import OpcoesLances from 'models/OpcoesLances';
import {
  iTabelaGenerica,
  iTabelaGenericaResposta,
} from 'models/TabelaGenerica';
import TipoCampoTabelaGenerica, {
  iTipoCampoTabelaGenerica,
} from 'staticData/TipoCampoTabelaGenerica';
import { ConfiguracaoEtapasContext, OrcamentoContext } from '../..';

import {
  ColGraficoAngulo,
  ColQuestao,
  ColQuestaoTitulo,
  LabelQuestao,
  LabelQuestaoTitulo,
  PieChartAngulo,
} from './styles';

import {
  getRaioAreaIrrigada,
  getAreaEmHectares,
  getAreaIrrigadaComAspersorFinalACAF,
  getAreaIrrigadaSemAspersorFinalASAF,
  getVazaoSemAspersorFinalQSAF,
} from './funcoes';
import QuestaoInput from './components/QuestaoInput';
import FotovoltaicaInfoUsina from '../FotovoltaicaInfoUsina';
import FotovoltaicaInfoUsinaSimplificado from '../FotovoltaicaInfoUsinaSimplificado';
import GenericTableRender from './components/GenericTableRender';

interface QuestaoGrupo {
  questaoTitulo: { id: number; descricao: string } | null;
  questaoInfo: { id: number; descricao: string } | null;
  listaQuestoes: Questao[];
}

interface ValorAssociacao {
  questaoValorSelecaoId: number;
  descricao: string;
  valoresDependencia: {
    id: number;
    questaoId: number;
    descricao: string;
  }[];
}

interface QuestaoRelacoes {
  id: number;
  descricao: string;
  questaoRelacao: QuestaoRelacao[];
}

interface Props {
  mensagemRaioSugerido?: string;
  configuracaoId: number;
  parteId: number;
  orcamentoParteId: number;
  onSave?: any;
  onBloquearPartes?: any;
  fichaTecnicaId?: number | null;
  sessaoId?: string;
}

export default function ParteQuestoesFormulario(props: Props): JSX.Element {
  const {
    onSave,
    configuracaoId,
    orcamentoParteId,
    fichaTecnicaId,
    onBloquearPartes,
    mensagemRaioSugerido,
    parteId,
    sessaoId,
  } = props;

  const { setOpcoesLances } = useContext(ConfiguracaoEtapasContext);

  const [questoes, setQuestoes] = useState<Questao[]>();
  const [questoesPorGrupo, setQuestoesPorGrupo] = useState<QuestaoGrupo[]>([]);
  const [questaoRelacoes, setQuestaoRelacoes] = useState(
    [] as QuestaoRelacoes[],
  );
  const [valoresAssociacao, setValoresAssociacao] = useState(
    [] as ValorAssociacao[],
  );
  const [respostas, setRespostas] = useState([] as OrcamentoResposta[]);
  const [anguloIrrigado, setAnguloIrrigado] = useState<string | number>();
  const [tempoOperacao, setTempoOperacao] = useState<string | number>();
  const [laminaBruta, setLaminaBruta] = useState<string | number>();
  const [areaTotalSemCanhao, setAreaTotalSemCanhao] = useState<
    string | number
  >();
  const [raio, setRaio] = useState<string | number>('');
  const [vazao, setVazao] = useState(0);
  const [areaIrrigadaCalculada, setAreaIrrigadaCalculada] = useState(0);
  const [existeAnguloIrrigado, setExisteAnguloIrrigado] = useState(false);
  const [existeRaioAreaIrrigada, setExisteRaioAreaIrrigada] = useState(false);
  const [existeVazao, setExisteVazao] = useState(false);
  const [funcaoEntradas, setFuncaoEntradas] = useState<FuncaoEntrada[]>();
  const [opMostrarLimitado, setOpMostrarLimitado] = useState('S');
  const [opMostrarTuboEspecial, setOpMostrarTuboEspecial] = useState('S');
  const [areaTotalCAF, setAreaTotalCAF] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [listaRespostas, setListaRespostas] = useState(
    [] as OrcamentoResposta[],
  );
  const [respostasAlteradas, setRespostasAlteradas] =
    useState<OrcamentoResposta[]>();
  const [respostasTabelaAlteradas, setRespostasTabelaAlteradas] = useState<
    {
      questaoId: number;
      resposta: any[];
    }[]
  >([]);

  const [selecaoIamgemVazia, setSelecaoIamgemVazia] = useState(false);
  const [idImagemSelecionada, setIdImagemSelecionada] = useState(0);

  const { orcamentoProduto } = useContext(ConfiguracaoEtapasContext);

  const produtoDaConfiguracao = orcamentoProduto?.orcamentoProduto?.[0];

  // Futuramente pode ser uma estrutura em banco
  const componentes: any[] = [
    {
      nome: 'FotovoltaicaInfoUsina',
      componente: FotovoltaicaInfoUsinaSimplificado,
    },
  ];

  const [listaTabelaGenerica, setListaTabelaGenerica] = useState([] as any[]);

  const [listaRespostasTabelaGenerica, setListaRespostasTabelaGenerica] =
    useState([] as any[]);

  const [listaTipoCampo, setListaTipoCampo] = useState(
    [] as iTipoCampoTabelaGenerica[],
  );

  async function carregarTabelaGenerica() {
    const response = await api.get(
      `/orcamento/parte/${parteId}/tabelas-genericas`,
    );
    setListaTabelaGenerica(response.data);
  }

  async function carregarRespostaTabelaGenerica() {
    if (configuracaoId) {
      const response = await api.get(
        `/tabela-generica/respostas-configuracao/${configuracaoId}`,
      );

      const lista = response.data as any[];

      setListaRespostasTabelaGenerica(lista);
    }
  }

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  useEffect(() => {
    // Comando para fazer um scroll to top ao acessar a aba de Adutora.
    window.scrollTo(0, 0);
    // document.documentElement.scrollTop = 0;
  }, []);

  async function carregaFuncaoEntradas() {
    if (!produtoDaConfiguracao || !produtoDaConfiguracao?.produtoId) return;

    const response = await api.get(`/funcao-entrada`);

    const valores = response.data as any[];
    const salvar = valores
      ?.filter(item => (item.FuncaoEntradaQuestaoProduto?.length ?? 0) > 0)
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((item: any) => {
        const questaoProduto = item.FuncaoEntradaQuestaoProduto?.find(
          (it: any) => it.produtoId === produtoDaConfiguracao?.produtoId,
        );

        return {
          ...item,
          questaoId: questaoProduto?.questaoId || item.questaoId,
          questao: questaoProduto?.questao || item.questao,
        };
      });

    setFuncaoEntradas(salvar);
  }

  async function carregaQuestoes() {
    if (!parteId) {
      return [];
    }
    const response = await api.get(`/questao/parte/${parteId}/agrupa-titulo`);

    const agrupado = response.data.agrupado as QuestaoGrupo[];
    const infoMensagem = response.data.infoMensagem as QuestaoGrupo[];
    setQuestoesPorGrupo(agrupado);
    setQuestaoRelacoes(response.data.questoesRelacoes);
    setValoresAssociacao(response.data.valoresAssociacao);
    return agrupado;
  }

  async function getRespostas(): Promise<OrcamentoResposta[] | []> {
    if (!configuracaoId) return [];
    const response = await api.get(`/orcamento/resposta`, {
      params: { configuracaoId },
    });

    return response.data;
  }

  const { data: queryQuestoesAgrupadas, isLoading } = useQuery(
    `questoes-parteId-${parteId}`,
    async () => {
      const dataOrcamentos = await carregaQuestoes();
      return dataOrcamentos;
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    if (queryQuestoesAgrupadas) {
      setQuestoesPorGrupo(queryQuestoesAgrupadas);
    }
  }, [queryQuestoesAgrupadas]);

  const { data: queryRespostas } = useQuery(
    `respostas-parteId-${orcamentoParteId}`,
    async () => {
      const dataRespostas = await getRespostas();
      return dataRespostas;
    },
    { staleTime: Infinity },
  );

  function handleSetRespostasAlteradas(pRespostas: OrcamentoResposta[]) {
    setRespostasAlteradas(pRespostas);
  }

  useEffect(() => {
    if (queryRespostas) {
      setListaTipoCampo(TipoCampoTabelaGenerica);
      carregarTabelaGenerica();
      carregarRespostaTabelaGenerica();
      setRespostas(queryRespostas);
      setListaRespostas(queryRespostas);
      carregaFuncaoEntradas();
    }
  }, [queryRespostas]);

  useEffect(() => {
    function getRespostaByLabelEntrada(labelFuncaoEntrada: string) {
      if (!funcaoEntradas || funcaoEntradas.length === 0) return null;

      const questao = funcaoEntradas.find(
        e => e.labelInterno === labelFuncaoEntrada,
      )?.questao;

      if (!questao) return null;

      const resposta = respostas
        .find(res => res.questaoId === questao.id)
        ?.resposta.replace(',', '.');

      const respostaToReturn = Number.isNaN(Number(resposta))
        ? resposta?.toUpperCase()
        : Number(resposta);

      return respostaToReturn ?? null;
    }

    if (respostas.length) {
      const respostasAlteradas2 = respostas.filter(
        resposta => resposta.changed,
      );

      handleSetRespostasAlteradas(respostasAlteradas2);

      setAreaTotalSemCanhao(
        getRespostaByLabelEntrada('area_total') ?? areaTotalSemCanhao,
      );

      setTempoOperacao(
        getRespostaByLabelEntrada('tempo_operacao') ?? tempoOperacao,
      );

      setLaminaBruta(getRespostaByLabelEntrada(`lamina`) ?? laminaBruta);

      setAnguloIrrigado(
        getRespostaByLabelEntrada('angulo_rotacao') ?? anguloIrrigado,
      );
    }
  }, [respostas]);

  useEffect(() => {
    const raioAspersorFinal = 0;

    const raioTubulacao =
      getRaioAreaIrrigada(areaTotalSemCanhao) - raioAspersorFinal;

    setRaio(arredondaDecimais(raioTubulacao));

    const areaIrrigadaSemAspersorFinalASAF =
      getAreaIrrigadaSemAspersorFinalASAF(raioTubulacao, anguloIrrigado);

    const vazaoSemAspersorFinalQSAF = getVazaoSemAspersorFinalQSAF(
      laminaBruta,
      areaIrrigadaSemAspersorFinalASAF,
      tempoOperacao,
    );

    setVazao(arredondaDecimais(vazaoSemAspersorFinalQSAF));

    const areaIrrigadaComAspersorFinalASAF =
      getAreaIrrigadaComAspersorFinalACAF(areaTotalSemCanhao, anguloIrrigado);

    setAreaIrrigadaCalculada(
      arredondaDecimais(areaIrrigadaComAspersorFinalASAF),
    );
  }, [areaTotalSemCanhao, tempoOperacao, laminaBruta, anguloIrrigado]);

  async function carregaRespostas() {
    if (!questoesPorGrupo.length) {
      return;
    }
    const respostasData = await getRespostas();
    setRespostas(respostasData);
    setListaRespostas(respostasData);
  }

  function existeQuestaoByLabelInterno(labelInterno: string): boolean {
    const questaoId = funcaoEntradas?.find(
      x => x.labelInterno === labelInterno,
    )?.questaoId;

    return !!questoes?.find(questao => questao.id === questaoId);
  }

  useEffect(() => {
    if (!funcaoEntradas || !questoes) {
      return;
    }

    function setVisibilidadeAnguloIrrigado() {
      const existeQuestao = existeQuestaoByLabelInterno('angulo_rotacao');
      setExisteAnguloIrrigado(existeQuestao);
    }

    function setVisibilidadeRaioAreaIrrigida() {
      const existeQuestao = existeQuestaoByLabelInterno('area_total');
      setExisteRaioAreaIrrigada(existeQuestao);
    }

    setVisibilidadeAnguloIrrigado();
    setVisibilidadeRaioAreaIrrigida();
  }, [funcaoEntradas, questoes]);

  useEffect(() => {
    function setVisibilidadeVazao() {
      const existeQuestaoLamina = existeQuestaoByLabelInterno('lamina');
      const existeQuestaoTempoOperacao =
        existeQuestaoByLabelInterno('tempo_operacao');

      setExisteVazao(
        existeRaioAreaIrrigada &&
          existeQuestaoLamina &&
          existeQuestaoTempoOperacao,
      );
    }

    setVisibilidadeVazao();
  }, [existeRaioAreaIrrigada]);

  useEffect(() => {
    const questoesArray = [] as Questao[];
    questoesPorGrupo.forEach(grupo => {
      grupo.listaQuestoes.forEach(questao => {
        questoesArray.push(questao);
      });
    });
    setQuestoes(questoesArray);

    carregaRespostas();
  }, [questoesPorGrupo]);

  useEffect(() => {
    if (parteId) {
      carregaQuestoes();
    }
  }, [parteId]);

  function getOcultaQuestao(
    questaoId: number,
    novoRespostas?: OrcamentoResposta[],
  ): boolean {
    // verifica se questão possui dependência
    const questoesRelacao = questaoRelacoes.find(
      questaoRelacao => questaoRelacao.id === questaoId,
    )?.questaoRelacao;

    const questoesRelacaoComCondicaoParaExibir = questoesRelacao?.filter(
      questaoRelacao =>
        !!questaoRelacao.questaoSelecaoAssociadaIds?.length ||
        questaoRelacao.valor,
    );

    // se possui dependência, verifica se deve ser exibida baseado na resposta
    let existemRespostasEsperadas = false;

    if (questoesRelacaoComCondicaoParaExibir?.length) {
      // busca lista de questaoSelecaoAssociada da questão da iteração (ids)

      const idsQuestaoSelecaoAssociada = [] as number[];

      questoesRelacaoComCondicaoParaExibir?.forEach(questaoRelacao => {
        questaoRelacao.questaoSelecaoAssociadaIds?.forEach(id => {
          idsQuestaoSelecaoAssociada.push(id);
        });
      });

      const idsQuestoesRelacao = questoesRelacaoComCondicaoParaExibir?.map(
        questaoRelacao => questaoRelacao.questaoRelacaoId,
      );

      const questoesRelacaoRespondidas = (novoRespostas ?? respostas).filter(
        resposta => idsQuestoesRelacao.includes(resposta.questaoId),
      );

      // busca questao_valor_selecao de questões acima respondidas
      const valoresSelecaoRespostas = questoesRelacaoRespondidas.map(
        resposta => {
          // Buscar condição para exibir
          const buscarCondicao = questoesRelacaoComCondicaoParaExibir.find(
            item => item.questaoRelacaoId === resposta.questaoId,
          );

          let retornar = false;

          if (buscarCondicao) {
            const { condicao, valor } = buscarCondicao;
            // resposta.resposta

            const respostaUsuario = resposta.resposta;

            if (!valor) {
              // buscar respostaParaQuestaoAssociada

              const idAssociados = buscarCondicao.questaoSelecaoAssociadaIds;

              if ((idAssociados?.length ?? 0) === 0) return true;

              const existe =
                buscarCondicao.questao.QuestaoValorSelecao?.filter(
                  item =>
                    idAssociados?.includes(item?.id ?? 0) &&
                    item.descricao === respostaUsuario,
                ) ?? [];

              retornar = existe && existe.length > 0;

              // const total = resposta.questao.QuestaoValorSelecao.filter(
              //  valorSelecao => valorSelecao.descricao === respostaUsuario,
              // );

              // retornar = respostaUsar === respostaUsuario ?? false;
            }
            // Testa se o valor é número
            else if (
              !Number.isNaN(valor ?? '') &&
              !Number.isNaN(respostaUsuario ?? '')
            ) {
              const respostaUsuarioNumero = Number(respostaUsuario);
              const valorNumero = Number(valor);
              if (condicao === '!=')
                retornar = respostaUsuarioNumero !== valorNumero;
              if (condicao === '==')
                retornar = respostaUsuarioNumero === valorNumero;
              if (condicao === '>')
                retornar = respostaUsuarioNumero > valorNumero;
              if (condicao === '<')
                retornar = respostaUsuarioNumero < valorNumero;
              if (condicao === '>=')
                retornar = respostaUsuarioNumero >= valorNumero;
              if (condicao === '<=')
                retornar = respostaUsuarioNumero <= valorNumero;
            }
          }

          return retornar ? resposta.questaoId : 0;
        },
      );

      if (
        questoesRelacaoRespondidas.length !== idsQuestoesRelacao.length ||
        !valoresSelecaoRespostas.length
      ) {
        return true;
      }

      existemRespostasEsperadas = questoesRelacaoComCondicaoParaExibir.every(
        valorSelecao =>
          valoresSelecaoRespostas.includes(valorSelecao.questaoRelacaoId ?? -1),
      );
    }

    const possuiRelacoes = !!questoesRelacaoComCondicaoParaExibir?.length;

    const ocultaQuestao = possuiRelacoes && !existemRespostasEsperadas;

    return ocultaQuestao;
  }

  function setaNovaRespostaQuestao(
    questao: Questao,
    novoValorString: string,
    questoesRelacionadasToSetEmpty?: Questao[],
  ) {
    const respostaQuestao = respostas.find(
      resposta => resposta.questaoId === questao.id,
    );

    if (novoValorString === respostaQuestao?.resposta) {
      return;
    }

    let novoArrayRespostas = [] as OrcamentoResposta[];

    function apagaRespostasQuestoesRelacionadas() {
      if (questoesRelacionadasToSetEmpty) {
        questoesRelacionadasToSetEmpty.forEach(questaoRelacionada => {
          const respostaQuestaoRelacionada = respostas.find(
            resposta => resposta.questaoId === questaoRelacionada.id,
          );

          if (respostaQuestaoRelacionada) {
            novoArrayRespostas = novoArrayRespostas.map(
              resposta =>
                resposta.id === respostaQuestaoRelacionada.id
                  ? { ...resposta, changed: true } // altera valor
                  : resposta, // mantém valor
            );
          }
        });
      }
    }

    // cria objeto resposta
    const thisResposta = {
      questaoId: questao.id,
      questao,
      resposta: novoValorString,
      orcamentoParteId,
      changed: true,
    } as OrcamentoResposta;

    if (respostaQuestao) {
      // resposta já existe
      novoArrayRespostas = respostas.map(
        resposta =>
          resposta === respostaQuestao
            ? { ...resposta, resposta: novoValorString, changed: true } // altera valor
            : resposta, // mantém valor
      );

      apagaRespostasQuestoesRelacionadas();
    } else {
      // resposta ainda não existe
      novoArrayRespostas = [...respostas, thisResposta];
    }

    const questoesFilhas = questaoRelacoes.filter(questaoRelacionada =>
      questaoRelacionada.questaoRelacao.find(
        x => x.questaoRelacaoId === questao.id,
      ),
    );
    questoesFilhas.forEach(questaoFilha => {
      const ocultaQuestao = getOcultaQuestao(
        questaoFilha.id,
        novoArrayRespostas,
      );
      if (ocultaQuestao) {
        // Para questões ocultas, era excluída a resposta.
        /* novoArrayRespostas = novoArrayRespostas.filter(
          x => x.questao.id !== questaoFilha.id,
        ); */
      }
    });

    setRespostas(novoArrayRespostas);
  }

  function setaNovaRespostaImagem(novoValorString: string) {
    if (novoValorString === 'Empty') {
      setSelecaoIamgemVazia(true);
    } else {
      setSelecaoIamgemVazia(false);
    }
  }

  function setaIdImagem(idImagem: number) {
    setIdImagemSelecionada(idImagem);
  }

  useDebouncedEffect(
    () => {
      const novaAreaTotalSemCanhao = arredondaDecimais(
        getAreaEmHectares(Number(raio)),
        3,
      );

      setAreaTotalSemCanhao(novaAreaTotalSemCanhao);

      const questaoAreaTotal = funcaoEntradas?.find(
        x => x.labelInterno === 'area_total',
      )?.questao;

      if (!questaoAreaTotal) return;

      setaNovaRespostaQuestao(
        questaoAreaTotal,
        String(arredondaDecimais(novaAreaTotalSemCanhao)),
      );
    },
    1000,
    [raio],
  );

  function porcentagemAnguloIrrigado(): number {
    if (!anguloIrrigado) return 0;

    return (100 * Number(anguloIrrigado)) / 360;
  }

  function limpaRespostasAlteradas() {
    setRespostasTabelaAlteradas([]);
    setRespostasAlteradas([]);
    const newRespostas = respostas.map(resposta => ({
      ...resposta,
      changed: false,
    }));
    setRespostas(newRespostas);
  }

  function getQuestoesRespostaForaIntervalo(): string[] {
    const questoesRespostaForaIntervalo = [] as string[];
    respostasAlteradas?.forEach(respostaAlterada => {
      const { resposta, questao } = respostaAlterada;
      const { tipo, valorMinimo, valorMaximo, isObrigatorio } = questao;
      // if(!resposta && questao.isObrigatorio){
      // }
      if (tipo === 'N') {
        const respostaNumber = Number(resposta.replaceAll(',', '.') ?? 0);
        const foraDoIntervalo =
          (valorMinimo && respostaNumber < valorMinimo) ||
          (valorMaximo && respostaNumber > valorMaximo);
        if (foraDoIntervalo) {
          questoesRespostaForaIntervalo.push(questao.descricao);
        }
      }
    });
    return questoesRespostaForaIntervalo;
  }

  function handleSalvarRespostas() {
    const questoesRespostaForaIntervalo = getQuestoesRespostaForaIntervalo();
    if (questoesRespostaForaIntervalo.length) {
      Swal.fire({
        icon: 'info',
        title: 'Respostas de questões fora do intervalo:',
        html: `${questoesRespostaForaIntervalo.join('<br />')}`,
      });
      return;
    }

    // Busca as imagens obrigatórias visíveis na tela
    const questoesImagemObrigatoria = questoes?.filter(
      item =>
        item.tipo === 'IM' &&
        item.isObrigatorio === true &&
        getOcultaQuestao(item.id) === false,
    );

    let questaoImagemSemResposta = false;
    // IF para conferir se tem algum registro
    if (questoesImagemObrigatoria && questoesImagemObrigatoria.length > 0) {
      questoesImagemObrigatoria?.forEach(item => {
        const buscandoAlterada = respostasAlteradas?.find(
          respostaAlterada => respostaAlterada.questao.id === item.id,
        );

        if (!buscandoAlterada) {
          // Busca nas normais
          const buscandoNormais = respostas?.find(
            resposta => resposta.questao.id === item.id,
          );

          if (!buscandoNormais?.resposta) {
            questaoImagemSemResposta = true;
          }
        } else if (!buscandoAlterada?.resposta) {
          questaoImagemSemResposta = true;
        }
      });
    }

    if (questaoImagemSemResposta) {
      Swal.fire({
        icon: 'error',
        title: `Uma ou mais questões de seleção de imagem obrigatória não foram preenchidas!`,
      });
      return;
    }

    // Conferir se tem tabela obrigatória
    const tabelasObrigatorias = questoes?.filter(
      item =>
        item.tipo === 'TB' &&
        item.isObrigatorio === true &&
        getOcultaQuestao(item.id) === false,
    );

    if ((tabelasObrigatorias?.length ?? 0) > 0) {
      const tabelaVazia = tabelasObrigatorias?.filter(item => {
        const alterado = respostasTabelaAlteradas.find(
          it => it.questaoId === item.id,
        );
        const respondido = listaRespostasTabelaGenerica.find(
          it => it.questaoId === item.id,
        );

        if (alterado || respondido) {
          if (alterado) {
            return alterado?.resposta.length === 0;
          }
          if (respondido) {
            return respondido?.resposta.length === 0;
          }
        }
        return true;
      });

      if (tabelaVazia && tabelaVazia?.length > 0) {
        Swal.fire({
          icon: 'error',
          title: `Uma ou mais tabelas obrigatórias precisam ter valores!`,
          html: `${tabelaVazia
            ?.map(it => `<strong>${it.descricao}</strong>`)
            .join(',')}`,
        });
        return;
      }
    }

    async function limpaOpcoesLances() {
      if (
        fichaTecnicaId &&
        (sessaoId !== '' || sessaoId !== null || sessaoId !== undefined)
      ) {
        await api.delete(`/integracao/lances-manual/todos?sessao=${sessaoId}`);
      }
    }

    async function salvarRespostaTabelas() {
      if (configuracaoId && respostasTabelaAlteradas.length > 0) {
        await api.post(
          `/tabela-generica/respostas-configuracao/${configuracaoId}`,
          respostasTabelaAlteradas,
        );
      }
    }

    // eslint-disable-next-line no-unused-expressions
    async function salvaNovasRespostas() {
      // if (!respostasAlteradas?.length) return;
      setSwal('Salvando respostas...');
      await api.post(`/orcamento/respostas`, {
        respostasAlteradas,
        orcamentoId: configuracaoId,
        orcamentoParteId,
      });

      await salvarRespostaTabelas();

      limpaRespostasAlteradas();
      setSwal('');

      setOpcoesLances([] as OpcoesLances[]);
      if (onSave) props.onSave();
    }

    limpaOpcoesLances();
    salvaNovasRespostas();
  }

  async function handleRespostaChange(questaoId: number, resposta: any[]) {
    if (respostasTabelaAlteradas.find(item => item.questaoId === questaoId)) {
      setRespostasTabelaAlteradas(
        respostasTabelaAlteradas.map(item => {
          return {
            questaoId: item.questaoId,
            resposta: item.questaoId === questaoId ? resposta : item.resposta,
          };
        }),
      );
    } else {
      setRespostasTabelaAlteradas([
        ...respostasTabelaAlteradas,
        {
          questaoId,
          resposta,
        },
      ]);
    }
  }

  return (
    <Card className="shadow mt-2" style={{ width: '100%' }}>
      <CardBody>
        {isLoading ? (
          <Row>
            <Col className="text-center">
              <CircularProgress />
            </Col>
          </Row>
        ) : (
          <Form onSubmit={handleSubmit(handleSalvarRespostas)}>
            <div className="mt--4">
              {questoesPorGrupo.map((questaoGrupo, index) => {
                const { questaoTitulo, listaQuestoes, questaoInfo } =
                  questaoGrupo;
                const isPrimeiroGrupoQuestoes = index === 0;
                const exibeElementoEspecifico =
                  isPrimeiroGrupoQuestoes &&
                  (existeAnguloIrrigado ||
                    existeRaioAreaIrrigada ||
                    existeVazao);

                if (questaoTitulo && getOcultaQuestao(questaoTitulo.id)) {
                  return <></>;
                }
                return (
                  <Card className="shadow mt-5">
                    {questaoTitulo &&
                      !getOcultaQuestao(questaoTitulo.id) && ( // Verifica se questaoTitulo não é null e não deve ser ocultado
                        <Row key={questaoTitulo.id} className="mt--3">
                          <ColQuestaoTitulo>
                            <LabelQuestaoTitulo>
                              {questaoTitulo.descricao}
                            </LabelQuestaoTitulo>
                          </ColQuestaoTitulo>
                        </Row>
                      )}

                    <CardBody className="p-3">
                      <Row>
                        <Col
                          className={
                            exibeElementoEspecifico ? 'col-sm-12 col-xl-6' : ''
                          }
                        >
                          {questaoTitulo &&
                            !getOcultaQuestao(questaoTitulo.id) &&
                            listaQuestoes.map(questao => {
                              // Renderiza questões apenas se o título não estiver oculto
                              const ocultaQuestao = getOcultaQuestao(
                                questao.id,
                              );
                              if (ocultaQuestao) return null;
                              const { valorMinimo, valorMaximo } = questao;
                              const textoValorMinimo = valorMinimo
                                ? `Valor mínimo ${valorMinimo}`
                                : 'Sem valor mínimo';
                              const textoValorMaximo = valorMaximo
                                ? `Valor máximo ${valorMaximo}`
                                : 'Sem valor máximo';

                              if (questao.tipo === 'COMP') {
                                if (!questao.descricao) return <></>;

                                const componenteFiltrado = componentes.filter(
                                  item => item.nome === questao.descricao,
                                );

                                const DynamicComponent =
                                  componenteFiltrado &&
                                  componenteFiltrado.length
                                    ? componenteFiltrado[0].componente
                                    : null;

                                return (
                                  <>
                                    {DynamicComponent ? (
                                      <DynamicComponent
                                        orcamentoParteId={orcamentoParteId}
                                        configuracaoId={configuracaoId}
                                        permiteSalvar={false}
                                        salvarAutomaticamente
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              }

                              if (questao.tipo === 'TB') {
                                const tabelaUsar = listaTabelaGenerica.find(
                                  item => item.id === questao.tabelaGenericaId,
                                );

                                if (!tabelaUsar) return <></>;

                                return (
                                  <GenericTableRender
                                    key={questao.id}
                                    questao={questao}
                                    questoes={questoes}
                                    respostas={listaRespostasTabelaGenerica.find(
                                      item => item.questaoId === questao.id,
                                    )}
                                    handleRespostaChange={handleRespostaChange}
                                    valoresAssociacao={valoresAssociacao}
                                    tabelaGenerica={{
                                      ...tabelaUsar,
                                      TabelaGenericaColuna:
                                        tabelaUsar?.TabelaGenericaColuna?.filter(
                                          (it: any) => it.ativo === true,
                                        ),
                                    }}
                                    configuracaoTabela={listaTipoCampo}
                                  />
                                );
                              }

                              if (questao.tipo === 'INF') {
                                return (
                                  <Row key={questao.id}>
                                    <Col className="col-sm-12 col-xl-12">
                                      <Alert
                                        className="col-sm-12 col-xl-12"
                                        color="info"
                                      >
                                        <strong>{questao.descricao}</strong>
                                      </Alert>
                                    </Col>
                                  </Row>
                                );
                              }
                              return (
                                <>
                                  <Row
                                    key={questao.id}
                                    className="p-2 mt-1 ml-1"
                                  >
                                    <ColQuestao tipo={questao.tipo}>
                                      <LabelQuestao tipo={questao.tipo}>
                                        {(valorMinimo || valorMaximo) && (
                                          <Tooltip
                                            className="mr-2"
                                            title={
                                              <div>
                                                {textoValorMinimo}
                                                <br />
                                                {textoValorMaximo}
                                              </div>
                                            }
                                          >
                                            <IconButton>
                                              <FaInfoCircle
                                                size={12}
                                                color="#888787"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                        {questao.descricao}
                                        {questao.isObrigatorio ? ' *' : ''}
                                      </LabelQuestao>
                                    </ColQuestao>
                                    <Col
                                      sm={questao.tipo === 'IM' ? '12' : '6'}
                                    >
                                      <QuestaoInput
                                        questao={questao}
                                        questoes={questoes}
                                        respostas={respostas}
                                        valoresAssociacao={valoresAssociacao}
                                        setaNovaRespostaQuestao={
                                          setaNovaRespostaQuestao
                                        }
                                        setaNovaRespostaImagem={
                                          setaNovaRespostaImagem
                                        }
                                        setaIdImagem={setaIdImagem}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                        </Col>

                        {exibeElementoEspecifico && (
                          // gráfico
                          <Col className="col-sm-12 col-xl-6">
                            {existeRaioAreaIrrigada && (
                              <>
                                <Row key="a1" className="p-2 mt-1 ml-1">
                                  <ColQuestao>
                                    <LabelQuestao>
                                      {mensagemRaioSugerido && (
                                        <Tooltip
                                          className="mr-2"
                                          title={mensagemRaioSugerido}
                                        >
                                          <IconButton>
                                            <FaInfoCircle
                                              size={15}
                                              color="#d95550"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      Raio disponível a ser irrigado sem
                                      aspersor final
                                    </LabelQuestao>
                                  </ColQuestao>
                                  <Col sm="6">
                                    <InputGroup className="input-group-alternative">
                                      <Input
                                        type="number"
                                        min={0}
                                        step={0.01}
                                        value={raio}
                                        onChange={text =>
                                          setRaio(text.target.value)
                                        }
                                      />
                                      <InputGroupAddon addonType="append">
                                        m
                                      </InputGroupAddon>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {existeAnguloIrrigado && (
                              <Row className="justify-content-center">
                                <ColGraficoAngulo>
                                  <LabelQuestao>
                                    Gráfico ângulo de rotação a ser irrigado
                                  </LabelQuestao>
                                </ColGraficoAngulo>
                                <PieChartAngulo
                                  angulo={porcentagemAnguloIrrigado()}
                                >
                                  <img
                                    alt="Gráfico ângulo irrigado"
                                    src={PivoImage}
                                    style={{
                                      width: '12.3rem',
                                      margin: '11.5rem 0px 0px 10.9rem',
                                    }}
                                  />
                                </PieChartAngulo>
                              </Row>
                            )}

                            {existeVazao && (
                              <Row key="a2" className="p-2 mt-1 ml-1">
                                <ColQuestao>
                                  <LabelQuestao>Vazão total</LabelQuestao>
                                </ColQuestao>
                                <Col sm="6">
                                  <ColQuestao>
                                    <LabelQuestao>{vazao} m³/h</LabelQuestao>
                                  </ColQuestao>
                                </Col>
                              </Row>
                            )}

                            {existeAnguloIrrigado && (
                              <Row key="a1" className="p-2 mt-1 ml-1">
                                <ColQuestao>
                                  <LabelQuestao>
                                    Área irrigada calculada
                                  </LabelQuestao>
                                </ColQuestao>
                                <Col sm="6">
                                  <ColQuestao>
                                    <LabelQuestao>
                                      {areaIrrigadaCalculada} ha
                                    </LabelQuestao>
                                  </ColQuestao>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}

              <Button
                size="sm"
                className="btn-icon btn-2"
                color="primary"
                type="submit"
              >
                <FaArrowCircleRight />
                <span className="btn-inner--text">Salvar Respostas</span>
              </Button>
            </div>
          </Form>
        )}
      </CardBody>
    </Card>
  );
}
