export function getRaioAreaIrrigada(
  areaTotalHectares?: string | number,
): number {
  const raioArea = Math.sqrt(
    (Number(areaTotalHectares ?? 0) * 10000) / Math.PI,
  );
  return raioArea;
}

export function getAreaEmHectares(raioEmMetros: string | number): number {
  return (Math.PI * Number(raioEmMetros) ** 2) / 10000;
}

export function getAreaIrrigadaComAspersorFinalACAF(
  areaTotalIrrigadaEmHectares?: string | number,
  anguloIrrigado?: string | number,
): number {
  return (
    (Number(areaTotalIrrigadaEmHectares ?? 0) * Number(anguloIrrigado ?? 0)) /
    360
  );
}

export function getAreaIrrigadaSemAspersorFinalASAF(
  raioTubulacao?: string | number,
  anguloIrrigado?: string | number,
): number {
  return (
    (((Math.PI * Number(raioTubulacao ?? 0) ** 2) / 10000) *
      Number(anguloIrrigado ?? 0)) /
    360
  );
}

export function getVazaoSemAspersorFinalQSAF(
  lamina?: string | number,
  areaIrrigadaSemAspersorFinalASAF?: string | number,
  tempoOperacaoDiario?: string | number,
): number {
  return (
    (Number(lamina ?? 0) * Number(areaIrrigadaSemAspersorFinalASAF ?? 0) * 10) /
    Number(tempoOperacaoDiario ?? 0)
  );
}
