/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
  CardBody,
  Input,
  InputGroup,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import api from 'services/api';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import toast from 'react-hot-toast';
import { FaArrowLeft, FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { iColunas, iImagens, iReport } from 'models/TemplateReport';
import Select from 'react-select';
import Produto from 'models/Produto';
import { Cropper } from 'react-cropper';
import Swal from 'sweetalert2';
import './style.css';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { IconButton } from '@material-ui/core';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TemplateRelatorioImagem: React.FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [listaProdutos, setListaProdutos] = useState([] as Produto[]);
  const [formModalState, setFormModalState] = useState(false);
  const [imageEditing, setImageEditing] = useState({} as iImagens);
  const [imagens, setImagens] = useState([] as iImagens[]);

  const resizeImage = (base64Str: string, maxWidth = 400, maxHeight = 350) => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let { width } = img;
        let { height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (ctx) ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  };

  async function listaProduto() {
    const response = await api.get('/produto');
    setListaProdutos(response.data);
  }

  async function loadImages() {
    const response = await api.get(`/report/imagens/todas`);
    setImagens(response.data);
  }

  async function loadData() {
    await loadImages();
    await listaProduto();
    setIsLoading(false);
  }

  useEffect(() => {
    if (isLoading) {
      loadData();
    }
  }, [isLoading]);

  async function handleInsert() {
    setImageEditing({} as iImagens);
    setFormModalState(!formModalState);
  }

  function handleUpdate(item: iImagens) {
    setImageEditing({ ...item } as iImagens);
    setFormModalState(!formModalState);
  }

  async function ativarDesativar(id: number) {
    setIsSaving(true);
    const toastId = toast.loading('Excluindo...');
    try {
      await api.delete(`/report/imagens/${id}`);
      toast.success('Feito!', {});
      const filtrado = imagens.filter(it => it.id !== id);

      setImagens(filtrado);
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com a exclusão',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleDelete(item: iImagens) {
    const result = await Swal.fire({
      title: `Deseja excluir a imagem?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    });

    if (result.isConfirmed) {
      ativarDesativar(item.id);
      Swal.close();
    }
  }

  async function handleSaveTabela() {
    const { id, variavel, produtoId, base64, base64Small } = imageEditing;

    if (!variavel) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (!id && !base64 && !base64) {
      toast.error(
        'Para criação de um registro, é obrigatório selecionar uma imagem!',
      );
      return;
    }

    if (
      imagens
        .filter((it: any) => (id !== 0 ? it.id !== id : id))
        ?.find((it: any) => it.variavel === variavel)
    ) {
      toast.error('A variável informada já está sendo usada por outra imagem');
      return;
    }

    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      if (id) {
        const retorno = await api.put(`/report/imagens/${id}`, {
          variavel,
          produtoId,
        });

        const filtrado = imagens.map(it => {
          if (it.id !== id) return it;

          return {
            ...retorno.data,
          };
        });

        setImagens(filtrado);
      } else {
        const retorno = await api.post(`/report/imagens/`, {
          variavel,
          produtoId,
          base64,
          base64Small,
        });

        setImagens([...imagens, { ...retorno.data }]);
      }

      toast.success('Feito!', {});
      Swal.close();

      setFormModalState(false);
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleImageRead(event: any) {
    const file = event.target.files[0];
    const limitSizeUploadInBytes = 1024 * 1024 * 5;
    const fileSizeInBytes = file.size;

    async function convertBase64() {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = error => {
          reject(error);
        };
      });
    }

    function checkLimitSizeUpload() {
      if (fileSizeInBytes > limitSizeUploadInBytes) {
        toast.error(
          `Tamanho do arquivo (${(fileSizeInBytes / 1024 / 1024).toFixed(
            2,
          )} MB) supera o limite (${
            limitSizeUploadInBytes / 1024 / 1024
          } MB) para upload de imagens.`,
        );
        throw Error;
      }
    }

    try {
      checkLimitSizeUpload();
      const base64 = String(await convertBase64()) ?? '';
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const result = await resizeImage(base64);

      setImageEditing({
        ...imageEditing,
        base64,
        base64Small: String(result),
      });

      toast.dismiss();
    } finally {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="6">
            <FormGroup className="mb-4">
              <Button
                className="btn-icon btn-2 mt-3"
                color="primary"
                type="button"
                disabled={isSaving || isLoading}
                onClick={handleInsert}
              >
                <FaPlusCircle />
              </Button>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered"
              size="md"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Variável*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Variável"
                            value={imageEditing.variavel}
                            type="text"
                            onChange={text =>
                              setImageEditing({
                                ...imageEditing,
                                variavel: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Produto (padrão disponível para todos):</small>
                        <Select
                          closeMenuOnSelect={false}
                          placeholder="Selecione..."
                          isMulti
                          options={listaProdutos.map((item: Produto) => {
                            return { label: item.nome, value: item.id };
                          })}
                          value={listaProdutos
                            .filter((it: Produto) => {
                              if (!imageEditing?.produtoId) return false;
                              return imageEditing.produtoId.includes(it.id);
                            })
                            ?.map((item: Produto) => {
                              return {
                                label: item.nome,
                                value: item.id,
                              };
                            })}
                          onChange={(e: any) => {
                            setImageEditing({
                              ...imageEditing,
                              produtoId: e.map((it: any) => it.value),
                            });
                          }}
                        />
                      </FormGroup>

                      {imageEditing?.base64Small &&
                        imageEditing?.base64Small !== '' && (
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src={imageEditing?.base64Small}
                              alt="Imagem para Upload"
                            />
                          </div>
                        )}
                      <FormGroup className="mb-3">
                        <input
                          hidden={(imageEditing?.id ?? 0) !== 0}
                          id="imagem-valor"
                          accept=".png, .jpg"
                          type="file"
                          className="mt-2"
                          onChange={e => handleImageRead(e)}
                        />
                        <p>
                          <small className="mt-2">
                            <i>
                              O sistema irá manter a imagem em tamanhos
                              diferentes. No relatório, será usada a versão
                              original.
                            </i>
                          </small>
                        </p>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving || isLoading}
                          onClick={handleSaveTabela}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">Imagens</h3>
                  </Col>
                </Row>
              </CardHeader>

              <Row>
                <Col sm="12">
                  <ImageList
                    cols={8}
                    gap={8}
                    style={{
                      flexWrap: 'wrap',
                      transform: 'translateZ(0)',
                    }}
                  >
                    {imagens.map((item, index: number) => (
                      <ImageListItem key={`key_${index}`}>
                        <Button
                          size="sm"
                          color="danger"
                          type="button"
                          className="float-right mr-1 mt-1"
                          onClick={() => handleDelete(item)}
                          style={{
                            position: 'absolute',
                            right: 0,
                            zIndex: 990,
                          }}
                        >
                          <FaTrash />
                        </Button>

                        <img
                          src={item.base64Small}
                          alt={item.variavel}
                          loading="lazy"
                        />
                        <ImageListItemBar
                          title={item.variavel}
                          actionIcon={
                            <Button
                              size="sm"
                              color="primary"
                              type="button"
                              className="mr-1"
                              onClick={() => handleUpdate(item)}
                            >
                              <FaEdit />
                            </Button>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Col>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TemplateRelatorioImagem;
