import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  Modal,
  Row,
} from 'reactstrap';
import { Tooltip, TextField } from '@material-ui/core';

import 'antd/dist/antd.css';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import api from 'services/api';
import {
  FaCheckCircle,
  FaPencilAlt,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import AutocompleteItemDomsge from 'components/AutocompleteItemDomsge';
import Material from 'models/Material';

import toast from 'react-hot-toast';
import { RowFilter, CadDetalhe } from './styles';
import CadDetalhesType from '../../../models/CadDetalhes';

type ItemDomsge = {
  DITE_CODIGO: string;
  FABR_CODIGO: string;
  DITE_DGPE_CODIGO: string;
  FABR_EMPR_CODIGO: string;
  DESCRICAO: string;
  QUANTIDADE?: number;
};

const CadDetalhes: React.FC = () => {
  const [cadDetalhes, setCadDetalhes] = useState<CadDetalhesType[]>([]);
  const criandoItem = cadDetalhes.some(cadDetalhe => cadDetalhe.id === 0);
  const [editandoItemDomsge, setEditandoItemDomsge] =
    useState<CadDetalhesType>();
  const [itemDomsgeSelecionado, setItemDomsgeSelecionado] =
    useState<ItemDomsge | null>(null);

  function getItemDomsgeObj(material: Material): ItemDomsge | undefined {
    if (!material.diteCodigo) return undefined;

    const {
      diteCodigo,
      diteDgpeCodigo,
      fabrCodigo,
      fabrEmprCodigo,
      descricao,
      quantidade,
    } = material;

    return {
      DITE_CODIGO: diteCodigo,
      DITE_DGPE_CODIGO: diteDgpeCodigo,
      FABR_CODIGO: fabrCodigo,
      FABR_EMPR_CODIGO: fabrEmprCodigo,
      DESCRICAO: descricao,
      QUANTIDADE: quantidade,
    };
  }

  async function carregaCadDetalhes() {
    const response = await api.get(`/cad-detalhes`);
    const cadDetalhesResponse = response.data as CadDetalhesType[];
    const cadDetalhesCompleto = cadDetalhesResponse.map(cadDetalhe => ({
      ...cadDetalhe,
      inputItemDomsge: `${cadDetalhe.material.diteCodigo} - ${cadDetalhe.material.descricao}`,
      itemSelecionado: getItemDomsgeObj(cadDetalhe.material),
      diteCodigo: cadDetalhe.material?.diteCodigo,
    }));
    setCadDetalhes(cadDetalhesCompleto);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaCadDetalhes();
  }, []);

  function handleSetItemDomsge(
    cadDetalhe?: CadDetalhesType,
    itemSelecionado?: ItemDomsge | null,
  ) {
    if (!cadDetalhe || !itemSelecionado) return;
    setCadDetalhes(
      cadDetalhes.map(cadDetalhe_ =>
        cadDetalhe_.id === cadDetalhe.id
          ? {
              ...cadDetalhe_,
              itemSelecionado,
              diteCodigo: itemSelecionado?.DITE_CODIGO,
            }
          : cadDetalhe_,
      ),
    );
    setEditandoItemDomsge(undefined);
  }

  function handleSetCadDetalheValue(
    cadDetalhe: CadDetalhesType,
    attrName: string,
    value: string | number | boolean | null | ItemDomsge,
  ) {
    setCadDetalhes(
      cadDetalhes.map(cadDetalhe_ =>
        cadDetalhe_.id === cadDetalhe.id
          ? { ...cadDetalhe_, [attrName]: value, alterado: true }
          : cadDetalhe_,
      ),
    );
  }

  async function handleUpdate(cadDetalhe: CadDetalhesType) {
    const { id, diametro, equipamento, itemSelecionado } = cadDetalhe;
    await api.put(`/cad-detalhes/${id}`, {
      id,
      diametro,
      equipamento,
      diteCodigo: itemSelecionado?.DITE_CODIGO,
    });
  }

  async function handleCreate(cadDetalhe: CadDetalhesType) {
    const { diametro, equipamento, itemSelecionado } = cadDetalhe;
    await api.post('/cad-detalhes', {
      diametro,
      equipamento,
      diteCodigo: itemSelecionado?.DITE_CODIGO,
    });
  }

  function validaFormulario(cadDetalhe: CadDetalhesType) {
    const { equipamento, diametro, itemSelecionado } = cadDetalhe;
    if (!equipamento || !diametro || !itemSelecionado?.DITE_CODIGO) {
      return false;
    }
    return true;
  }

  async function handleSave(cadDetalhe: CadDetalhesType) {
    const formularioOk = validaFormulario(cadDetalhe);
    if (!formularioOk) return;
    try {
      const toastSalvaId = toast.loading('Salvando...');
      const novoCadDetalhe = cadDetalhe.id === 0;
      if (novoCadDetalhe) {
        await handleCreate(cadDetalhe);
      } else {
        await handleUpdate(cadDetalhe);
      }
      await carregaCadDetalhes();
      toast.success('Feito!', {
        id: toastSalvaId,
      });
    } catch (error) {
      toast.dismiss();
      toast.error('Ops! Algo de errado aconteceu!');
    }
  }

  async function handleDelete(cadDetalheId: number) {
    const newCadDetalhes = cadDetalhes.filter(
      cadDetalhe => cadDetalhe.id !== cadDetalheId,
    );

    setCadDetalhes(newCadDetalhes);

    if (cadDetalheId !== 0) {
      await api.delete(`/cad-detalhes/${cadDetalheId}`);
    }
  }

  function questionaExclusao(cadDetalhe: CadDetalhesType) {
    Swal.fire({
      title: `Excluir registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, excluir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(result => {
      if (result.isConfirmed) {
        handleDelete(cadDetalhe.id);
      }
    });
  }

  function handleAdicionar() {
    setCadDetalhes([
      {
        id: 0,
        diametro: 0,
        equipamento: '',
        materialId: 0,
        material: {} as Material,
      },
      ...cadDetalhes,
    ]);
  }

  function handleSelecionarItemDomsge(itemDomsge: ItemDomsge | null) {
    setItemDomsgeSelecionado(itemDomsge);
    setCadDetalhes(
      cadDetalhes.map(cadDetalhe_ =>
        cadDetalhe_.id === editandoItemDomsge?.id ?? 0
          ? { ...cadDetalhe_, alterado: true, itemSelecionado: itemDomsge }
          : cadDetalhe_,
      ),
    );
  }

  return (
    <>
      <UserSmallHeader />
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: '60vw', width: '100%' }}
        isOpen={!!editandoItemDomsge}
        toggle={() => setEditandoItemDomsge(undefined)}
        onClosed={() => setEditandoItemDomsge(undefined)}
      >
        <AutocompleteItemDomsge
          handleSelecionarItemDomsge={handleSelecionarItemDomsge}
        >
          <Button
            className="btn-icon btn-2"
            color="primary"
            type="button"
            onClick={() => {
              handleSetItemDomsge(editandoItemDomsge, itemDomsgeSelecionado);
            }}
          >
            <FaCheckCircle />
          </Button>
        </AutocompleteItemDomsge>
      </Modal>
      <Col>
        <RowFilter>
          <Button
            disabled={criandoItem}
            size="sm"
            className="btn btn-outline-primary"
            type="button"
            onClick={handleAdicionar}
          >
            <FaPlusCircle />
            <span className="btn-inner--text">Novo</span>
          </Button>
        </RowFilter>
      </Col>

      <Container
        className="mt-4"
        fluid
        style={{ backgroundColor: '#fff', border: '1px solid #ebe9e9' }}
      >
        <Col>
          {cadDetalhes.map(cadDetalhe => {
            const { id, diametro, equipamento, itemSelecionado } = cadDetalhe;
            const itemSelecionadoText = itemSelecionado?.DITE_CODIGO
              ? `${itemSelecionado?.DITE_CODIGO} - ${itemSelecionado?.DESCRICAO}`
              : '';
            return (
              <CadDetalhe key={id} onSubmit={() => handleSave(cadDetalhe)}>
                <Col sm="12">
                  <Row>
                    <Col sm="3">
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        style={{ width: '100%' }}
                        size="small"
                        variant="outlined"
                        label="Equipamento *"
                        type="text"
                        value={equipamento ?? ''}
                        onChange={text =>
                          handleSetCadDetalheValue(
                            cadDetalhe,
                            'equipamento',
                            text.target.value,
                          )
                        }
                      />
                    </Col>

                    <Col sm="2">
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        size="small"
                        variant="outlined"
                        label="Diâmetro *"
                        type="number"
                        value={diametro ?? ''}
                        onChange={text =>
                          handleSetCadDetalheValue(
                            cadDetalhe,
                            'diametro',
                            text.target.value,
                          )
                        }
                      />
                    </Col>

                    <Col sm="6">
                      <InputGroup className="input-group-alternative">
                        <Tooltip title={itemSelecionadoText}>
                          <Input
                            disabled
                            placeholder="Item DomSGE *"
                            type="text"
                            value={itemSelecionadoText}
                          />
                        </Tooltip>
                        <Button
                          size="sm"
                          className="btn-icon btn-2"
                          color="secondary"
                          type="button"
                          onClick={() => setEditandoItemDomsge(cadDetalhe)}
                        >
                          <FaPencilAlt />
                        </Button>
                      </InputGroup>
                    </Col>

                    <div className="mt-2 float-right">
                      {cadDetalhe.alterado && (
                        <Button
                          size="sm"
                          className="btn-icon btn-2"
                          color="primary"
                          type="button"
                          onClick={() => handleSave(cadDetalhe)}
                        >
                          <FaCheckCircle />
                        </Button>
                      )}
                      <Button
                        size="sm"
                        className="btn-icon btn-2"
                        color="danger"
                        type="button"
                        onClick={() => questionaExclusao(cadDetalhe)}
                      >
                        <FaTimesCircle />
                      </Button>
                    </div>
                  </Row>
                </Col>
              </CadDetalhe>
            );
          })}
        </Col>
      </Container>
    </>
  );
};

export default CadDetalhes;
