import FichaTecnica from 'models/FichaTecnica';
import { Card, CardBody, Row } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function DadosAdutora({ fichaTecnica }: Props): JSX.Element {
  const { alturaSuccao } = fichaTecnica;
  const fichaTecnicaAdutora = fichaTecnica.FichaTecnicaAdutora;
  const fichaTecnicaTubulacao = fichaTecnica.FichaTecnicaTubulacao;
  const {
    desnivelCaptacaoCentroPivo,
    perdaCarga,
    perdaCargaLocalizada,
    alturaManometricaTotal,
  } = fichaTecnicaAdutora || {};

  const {
    diametroTubulacao,
    diametroLigacao,
    tipoSuccao,
    diametroEntradaBombaSuccaoDupla,
  } = fichaTecnicaTubulacao?.[0] || {};

  return (
    <Card className="shadow">
      <ColQuestaoTitulo>
        <LabelQuestaoTitulo>Adutora</LabelQuestaoTitulo>
      </ColQuestaoTitulo>

      <CardBody className="p-3">
        <Row>
          <Campo
            sm="12"
            label="Desnível Captação ao Centro"
            valor={desnivelCaptacaoCentroPivo}
            number
            unidade="m"
          />

          <Campo
            sm="12"
            label="Perda de Carga na Adutora"
            valor={perdaCarga}
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Perda de Carga Localizada"
            valor={perdaCargaLocalizada}
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Altura Manométrica Total"
            valor={alturaManometricaTotal}
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Altura de Sucção"
            valor={alturaSuccao}
            number
            unidade="m"
          />

          <Campo
            sm="12"
            label="Tipo de Sucção"
            style={{ textAlign: 'right' }}
            valor={tipoSuccao}
          />

          <Campo
            sm="12"
            label="Diâmetro de entrada na bomba p/ sucção dupla"
            valor={diametroEntradaBombaSuccaoDupla}
            number
            hidden={tipoSuccao !== 'Duplo'}
            unidade="mm"
          />

          <Campo
            sm="12"
            label="Diâmetro da Tubulação de Sucção"
            valor={diametroTubulacao}
            number
            unidade="mm"
          />

          <Campo
            sm="12"
            label="Diâmetro da Ligação de Pressão"
            valor={diametroLigacao}
            number
            unidade="mm"
          />
        </Row>
      </CardBody>
    </Card>
  );
}

export default DadosAdutora;
