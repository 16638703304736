import React, { useState, useEffect } from 'react';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
} from 'reactstrap';

import Header from 'components/Headers/Header.js';

import { FaArrowAltCircleRight } from 'react-icons/fa';

import api from 'services/api';

import Questao from 'models/Questao';
import FuncaoEntrada from 'models/FuncaoEntrada';

import Swal from 'sweetalert2';
import Funcao from '../../../models/Funcao';

import {
  FuncaoDescricao,
  FuncaoValorSaida,
  FuncaoRow,
  Entrada,
  EntradaLabel,
} from './styles';

const Funcoes: React.FC = () => {
  const [funcoes, setFuncoes] = useState([] as Funcao[]);
  const [questoes, setQuestoes] = useState([] as Questao[]);

  async function carregaFuncoes() {
    const response = await api.get('/funcao');

    const funcoesData = response.data as Funcao[];

    setFuncoes(funcoesData);
  }

  async function carregaQuestoes() {
    const response = await api.get('/questao');

    setQuestoes(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    (async () => {
      await carregaQuestoes();

      carregaFuncoes();
    })();
  }, []);

  async function handleClickQuestao(
    questao: Questao,
    entrada: FuncaoEntrada,
    funcao: Funcao,
  ) {
    const questaoId = questao.id;

    Swal.fire({
      icon: 'info',
      text: `Alterando entrada da função...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    await api.put(`/funcao/${funcao.id}/entrada`, {
      funcaoEntradaId: entrada.id,
      questaoId,
    });

    Swal.close();

    carregaFuncoes();
  }

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Funções</h3>
              </CardHeader>
              {funcoes.map(funcao => (
                <CardHeader key={funcao.id}>
                  <FuncaoRow>
                    <Row>
                      <FuncaoDescricao>{funcao.descricao}</FuncaoDescricao>
                    </Row>
                    <Row>
                      <FuncaoValorSaida>{funcao.valorSaida}</FuncaoValorSaida>
                    </Row>
                  </FuncaoRow>
                  {funcao.FuncaoEntrada.map(entrada => {
                    return (
                      <Col className="mt-2" key={entrada.questao.id}>
                        <Entrada>
                          <Col sm="3">
                            <EntradaLabel>{entrada.label}</EntradaLabel>
                          </Col>
                          <Col sm="9">
                            <FaArrowAltCircleRight
                              style={{ marginRight: '10px' }}
                            />
                            <UncontrolledDropdown>
                              <DropdownToggle caret>
                                {entrada.questao.descricao}
                              </DropdownToggle>
                              <DropdownMenu>
                                {questoes.map(questao => {
                                  return (
                                    <DropdownItem
                                      key={questao.id}
                                      value={questao.id}
                                      onClick={() =>
                                        handleClickQuestao(
                                          questao,
                                          entrada,
                                          funcao,
                                        )
                                      }
                                    >
                                      {questao.descricao}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Col>
                        </Entrada>
                      </Col>
                    );
                  })}
                </CardHeader>
              ))}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Funcoes;
