import FichaTecnica from 'models/FichaTecnica';
import { Table } from 'reactstrap';

import { Td } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function CambagemLances(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const fichaTecnicaCambagem = fichaTecnica.FichaTecnicaCambagem?.filter(
    cambagem => !!cambagem.nomeLances,
  );

  const headersNames = [
    'Lance c/ diâmetro',
    'Viga Base',
    '**Medida "X"',
    '**Medida "Y"',
  ];

  return (
    <Table bordered responsive>
      <tr>
        {headersNames.map(headerName => (
          <th key={headerName}>{headerName}</th>
        ))}
      </tr>

      {fichaTecnicaCambagem?.map(cambagem => {
        const { id, nomeLances, nomeVigabase, medidax, mediday } = cambagem;

        return (
          <tr key={id}>
            <Td>{nomeLances}</Td>
            <Td>{nomeVigabase}</Td>
            <Td>{medidax}</Td>
            <Td>{mediday}</Td>
          </tr>
        );
      })}
    </Table>
  );
}

export default CambagemLances;
