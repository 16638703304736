import styled from 'styled-components';

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface TdProps {
  editavel?: boolean;
}

export const Td = styled.td<TdProps>`
  background-color: ${props => (props.editavel ? 'unset' : '#f1f1f1')};
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;
