import FichaTecnica from 'models/FichaTecnica';
import { Card } from 'reactstrap';

import Campo from '../../../../atoms/Campo';
import Quadro from '../../../../atoms/Quadro';

import { Container } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function CaracteristicasTecnicas(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const {
    vazaoTotal,
    vazaoPorAreaSAF,
    velocidadeAteUltimaTorre,
    laminaBrutaMinimaVolta,
    regulagemPercentual,
    tempoMinimoVolta,
  } = fichaTecnica;

  const TITULO = 'CARACTERÍSTICAS TÉCNICAS (SAF)';

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Container>
          <Campo
            sm="6"
            label="Vazão Total (SAF)"
            valor={vazaoTotal}
            unidade="m³/h"
          />
          <Campo
            sm="6"
            label="Vazão por Área (SAF)"
            valor={vazaoPorAreaSAF}
            unidade="m³/ha.h"
          />

          <Campo
            sm="6"
            label="Velocidade última torre"
            valor={velocidadeAteUltimaTorre}
            unidade="m/h"
          />
          <Campo
            sm="6"
            label="Tempo mínimo por volta"
            valor={tempoMinimoVolta}
            unidade="h"
          />

          <Campo
            sm="6"
            label="Lâmina mínima por volta"
            valor={laminaBrutaMinimaVolta}
            unidade="mm"
          />
          <Campo
            sm="6"
            label="Regulagem percentual"
            valor={regulagemPercentual}
            unidade="%"
          />
        </Container>
      </Quadro>
    </Card>
  );
}

export default CaracteristicasTecnicas;
