import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Swal from 'sweetalert2';

import api from 'services/api';

import QuestaoModel from 'models/Questao';
import questaoTipoModel from '../../../models/ConfiguradorRegramentoEspecialQuestaoTipo';
import RegramentoQuestaoEspecialModel from '../../../models/ConfiguradorRegramentoEspecialQuestao';

interface QuestaoModelProps {
  id: number;
  descricao: string;
}

const CadastroquestaoTipo: React.FC = () => {
  const [questaoTipo, setQuestaoTipo] = useState([] as questaoTipoModel[]);
  // eslint-disable-next-line prettier/prettier
  const [questaoTipoEditing, setQuestaoTipoEditing] = useState({} as questaoTipoModel);
  const [tiposQuestao, setTiposQuestao] = useState([] as questaoTipoModel[]);
  const [regramentoQuestaoEspecial, setRegramentoQuestaoEspecial] = useState(
    [] as RegramentoQuestaoEspecialModel[],
  );
  const [regramentoEspecialEditing, setRegramentoEspecialEditing] = useState(
    {} as RegramentoQuestaoEspecialModel,
  );
  const [questoes, setQuestoes] = useState<QuestaoModelProps[]>([]);

  const [formModalState, setFormModalState] = useState(false);
  const [exibeRespostas, setExibeRespostas] = useState(false);
  const [questaoIdFiltrada, setQuestaoIdFiltrada] = useState<number>();

  async function listaQuestaoTipo() {
    const response = await api.get('/regramento-especial');

    setQuestaoTipo(response.data);
    setTiposQuestao(response.data);
  }

  async function listaRegramentoQuestaoEspecial() {
    if (questaoIdFiltrada) {
      const response = await api.get(
        `/regramento-especial/questao/${questaoIdFiltrada}`,
      );
      setRegramentoQuestaoEspecial(response.data);
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaQuestaoTipo();
    // listaRegramentoQuestaoEspecial();
  }, []);

  useEffect(() => {
    if (questaoIdFiltrada) {
      listaRegramentoQuestaoEspecial();
    }
  }, [questaoIdFiltrada]);

  async function handleDelete(regramentoId: number) {
    Swal.fire({
      title: `Deseja excluir o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/regramento-especial/${regramentoId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaQuestaoTipo();
        listaRegramentoQuestaoEspecial();
      }
    });
  }
  async function handleInsert() {
    setQuestaoTipoEditing({} as questaoTipoModel);
    setRegramentoEspecialEditing({} as RegramentoQuestaoEspecialModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(regramento: RegramentoQuestaoEspecialModel) {
    setRegramentoEspecialEditing(regramento);
    setFormModalState(!formModalState);
  }

  async function handleSaveRegramento() {
    // eslint-disable-next-line prettier/prettier
    const { valor } = regramentoEspecialEditing;

    if (!valor) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (regramentoEspecialEditing.id) {
      await api.put(`/regramento-especial/${regramentoEspecialEditing.id}`, {
        tipoId: questaoIdFiltrada,
        resposta: regramentoEspecialEditing.resposta,
        valor: regramentoEspecialEditing.valor.replaceAll(',', '.'),
      });
    } else {
      await api.post(`/regramento-especial/`, {
        tipoId: questaoIdFiltrada,
        resposta: regramentoEspecialEditing.resposta,
        valor: regramentoEspecialEditing.valor.replaceAll(',', '.'),
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setRegramentoEspecialEditing({} as RegramentoQuestaoEspecialModel);
    listaQuestaoTipo();
    listaRegramentoQuestaoEspecial();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{regramentoEspecialEditing.resposta}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Resposta </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Resposta"
                            value={regramentoEspecialEditing.resposta}
                            type="text"
                            onChange={text =>
                              setRegramentoEspecialEditing({
                                ...regramentoEspecialEditing,
                                resposta: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Valor </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Saída"
                            value={regramentoEspecialEditing.valor}
                            type="text"
                            onChange={text =>
                              setRegramentoEspecialEditing({
                                ...regramentoEspecialEditing,
                                valor: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveRegramento}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Variáveis por questões</h3>
              </CardHeader>
              <Col sm="6 py-3">
                <FormControl className="w-100" variant="outlined">
                  <InputLabel className="mt--2">Questão Tipo</InputLabel>
                  <Select
                    label="Questão"
                    value={questaoIdFiltrada}
                    onChange={event => {
                      setQuestaoIdFiltrada(
                        event.target.value
                          ? Number(event.target.value)
                          : undefined,
                      );
                      listaRegramentoQuestaoEspecial();
                      setExibeRespostas(true);
                    }}
                  >
                    <MenuItem value={undefined}>Selecione...</MenuItem>
                    {tiposQuestao.map(questaoItem => {
                      return (
                        <MenuItem value={Number(questaoItem.id)}>
                          {questaoItem.nome} -{' '}
                          {questaoItem.questao?.descricao ?? ''}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Card>
            {exibeRespostas ? (
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Resposta</th>
                      <th>Valor</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regramentoQuestaoEspecial.map(regra => (
                      <tr key={regra.id}>
                        <td className="py-2">{regra.resposta}</td>
                        <td className="py-2">{regra.valor}</td>
                        <td className="py-2">
                          <div className="float-right">
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              onClick={() => {
                                handleUpdate(regra);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="danger"
                              type="button"
                              onClick={() => {
                                handleDelete(regra.id);
                              }}
                            >
                              <FaTimesCircle />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            ) : null}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroquestaoTipo;
