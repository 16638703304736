import { Label as LabelR } from 'reactstrap';
import { Typography as TypographyMui } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Label = styled(LabelR)`
  font-size: 1rem;
  font-weight: 400;
  color: #000;
`;

export const Typography = styled(TypographyMui)`
  color: #000;
  text-align: center;
`;
