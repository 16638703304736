import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import formatCurrency from 'utils/formatCurrency';
import Chart from 'react-apexcharts';
import formatNumeros from 'utils/formatNumeros';

interface Props {
  valores: any[];
  larguraTotal: boolean | true;
}

function FVFluxoCaixa_Apex(props: Props): JSX.Element {
  const { valores, larguraTotal } = props;

  const [reportContent, setReportContent] = useState<any[]>([]);
  const [isLoadingReport, setIsLoadingReport] = useState(true);

  useEffect(() => {
    if (valores && valores.length > 0) {
      setReportContent(valores);
      setIsLoadingReport(false);
    }
  }, [valores]);

  const chart = {
    options: {
      chart: {
        id: 'fluxo-caixa-line',
        toolbar: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
        labels: {
          formatter(value: any) {
            return formatNumeros(Number(value));
          },
        },
      },
      grid: {
        show: true,
        borderColor: '#d6d6d6',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: reportContent.map(item => {
          return item.anoCorrente;
        }),
      },
      tooltip: {
        x: {
          formatter(val: any) {
            return `Ano: ${val}`;
          },
        },
        y: {
          formatter(val: any) {
            return `${formatCurrency(Number(val) ?? 0)}`;
          },
          title: {
            formatter(seriesName: any) {
              return `${seriesName}:`;
            },
          },
        },
      },
    },

    series: [
      {
        name: 'Fluxo de Caixa',
        data: reportContent.map(item => {
          return item.fluxoCaixa;
        }),
        color: 'rgb(255, 99, 132)',
      },
    ],
  };

  return (
    <>
      {!isLoadingReport ? (
        <div
          className="grafico"
          style={{ width: larguraTotal ? '100%' : '70vw' }}
        >
          <Chart options={chart.options} series={chart.series} type="line" />
        </div>
      ) : (
        <>Carregando Gráfico</>
      )}
    </>
  );
}

export default FVFluxoCaixa_Apex;
