import { Col, Row } from 'reactstrap';

interface Props {
  maisEconomica: boolean;
  maisEficiente: boolean;
}

function SinalizaEficienciaCusto({
  maisEconomica,
  maisEficiente,
}: Props): JSX.Element {
  function renderBadgeMenorCusto() {
    return (
      <div className="badge badge-success badge-lg">
        <i className="ni ni-money-coins" /> Mais econômico
      </div>
    );
  }

  function renderBadgeMelhorEficiencia() {
    return (
      <div className="badge badge-warning badge-lg">
        <i className="ni ni-chart-bar-32" /> Mais eficiente
      </div>
    );
  }

  return (
    <Row>
      {maisEconomica && <Col>{renderBadgeMenorCusto()}</Col>}
      {maisEficiente && <Col>{renderBadgeMelhorEficiencia()}</Col>}
    </Row>
  );
}

export default SinalizaEficienciaCusto;
