import { Button as ButtonR } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e9ecef;
`;

export const Button = styled(ButtonR)`
  width: 100%;
`;
