import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import CustomTable from 'components/CustomTable';
import api from 'services/api';
import { FaEdit } from 'react-icons/fa';

interface EstadoImpostos {
  id: number;
  ufSigla: string;
  descricao?: string;
  icmsComercial?: number;
  icmsIndustrial?: number;
  icmsResidencial?: number;
  icmsRural?: number;
}

const CadastroEstadoImpostos: React.FC = () => {
  const [estadoImpostos, setEstadoImpostos] = useState([] as any[]);
  const [estadoEditing, setEstadoEditing] = useState({} as EstadoImpostos);

  const [formModalState, setFormModalState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  async function listaEstadoImpostos() {
    const response = await api.get('/estado-impostos/');

    const estados: EstadoImpostos[] = response.data;

    setEstadoImpostos(estados);
  }

  useEffect(() => {
    listaEstadoImpostos();
  }, []);

  function handleUpdate(estado: EstadoImpostos) {
    setEstadoEditing(estado);
    setFormModalState(!formModalState);
  }

  async function handleSave() {
    // eslint-disable-next-line prettier/prettier
    const { ufSigla, id, icmsComercial, icmsIndustrial, icmsResidencial, icmsRural, descricao } = estadoEditing;

    if (!ufSigla) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);
    if (id) {
      await api.put(`/estado-impostos/${id}`, {
        ufSigla,
        icmsComercial,
        icmsIndustrial,
        icmsResidencial,
        icmsRural,
        descricao,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setEstadoEditing({} as EstadoImpostos);
    listaEstadoImpostos();
    setIsSaving(false);
  }

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Edição de ICMS do Estado</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>UF*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="UF*"
                            value={estadoEditing.ufSigla}
                            type="text"
                            onChange={text =>
                              setEstadoEditing({
                                ...estadoEditing,
                                ufSigla: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Descrição</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={estadoEditing.descricao}
                            type="text"
                            onChange={text =>
                              setEstadoEditing({
                                ...estadoEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Rural (%)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rural (%)"
                            value={estadoEditing.icmsRural}
                            type="number"
                            onChange={text =>
                              setEstadoEditing({
                                ...estadoEditing,
                                icmsRural: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Industrial (%)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Industrial (%)"
                            value={estadoEditing.icmsIndustrial}
                            type="number"
                            onChange={text =>
                              setEstadoEditing({
                                ...estadoEditing,
                                icmsIndustrial: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Comercial (%)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Comercial (%)"
                            value={estadoEditing.icmsComercial}
                            type="number"
                            onChange={text =>
                              setEstadoEditing({
                                ...estadoEditing,
                                icmsComercial: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Residencial (%)</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Residencial (%)"
                            value={estadoEditing.icmsResidencial}
                            type="number"
                            onChange={text =>
                              setEstadoEditing({
                                ...estadoEditing,
                                icmsResidencial: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSave}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">ICMS por Estados</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>UF</th>
                    <th>Descrição</th>
                    <th>Rural (%)</th>
                    <th>Industrial (%)</th>
                    <th>Comercial (%)</th>
                    <th>Residencial (%)</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {estadoImpostos.map(estadoImposto => (
                    <tr key={estadoImposto.id}>
                      <td className="py-1">{estadoImposto.ufSigla}</td>
                      <td className="py-1">{estadoImposto.descricao}</td>
                      <td className="py-1">{estadoImposto.icmsRural}</td>
                      <td className="py-1">{estadoImposto.icmsIndustrial}</td>
                      <td className="py-1">{estadoImposto.icmsComercial}</td>
                      <td className="py-1">{estadoImposto.icmsResidencial}</td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(estadoImposto);
                          }}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroEstadoImpostos;
