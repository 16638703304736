import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import Pessoa from 'models/Pessoa';
import { useState } from 'react';
import api from 'services/api';

interface Props {
  disabled?: boolean;
  limitarPorConfiguracao?: boolean | false;
  pessoaSelecionada: Pessoa | null;
  onChange: (event: any, pessoa: Pessoa | null) => void;
}

function AutocompletePessoa({
  disabled,
  pessoaSelecionada,
  limitarPorConfiguracao: propLimitarPorConfiguracao,
  onChange,
}: Props): JSX.Element {
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([] as Pessoa[]);
  const [open, setOpen] = useState(false);

  const limitarPorConfiguracao =
    propLimitarPorConfiguracao !== undefined
      ? propLimitarPorConfiguracao
      : false;

  const limitarResultados = limitarPorConfiguracao ?? false;

  function getLabel(option: Pessoa) {
    const id = option?.codigoDomsge;
    const name = option?.razaoSocial;

    if (id) {
      return `${id} - ${name ?? ''}`;
    }

    return `${name ?? ''}`;

    return '';
  }

  useDebouncedEffect(
    () => {
      let active = true;

      if (!open || loadingClientes) {
        return undefined;
      }

      (async () => {
        setLoadingClientes(true);

        const response = await api.get('/pessoa', {
          params: { search: inputValue, limitarResultados },
        });

        setLoadingClientes(false);

        if (active) {
          setOptions(response.data);
        }
      })();

      return () => {
        active = false;
      };
    },
    1000,
    [inputValue, open],
  );

  const styleDisabled = disabled ? { backgroundColor: '#f2f2f2' } : {};
  return (
    <Autocomplete
      disabled={disabled}
      size="small"
      open={open}
      options={options}
      loading={loadingClientes}
      inputValue={inputValue}
      style={styleDisabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option: Pessoa, value) =>
        option && option.id === value.id
      }
      getOptionLabel={getLabel}
      loadingText="Carregando..."
      noOptionsText="Nada encontrado"
      value={pessoaSelecionada}
      onChange={onChange}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          label="Cliente*"
          variant="outlined"
          InputLabelProps={{ style: { fontSize: 13 } }}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 12 },
            endAdornment: (
              <>
                {loadingClientes ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default AutocompletePessoa;
