import styled from 'styled-components';

export const Th = styled.th`
  padding: 1 !important;
  background: #e9ecef;
`;

export const Td = styled.td`
  padding: 0.4rem 0 0 0.6rem !important;
`;

export const DivOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
  padding: 0 !important;
`;
