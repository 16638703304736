import { Row, Col } from 'reactstrap';
import { useContext } from 'react';
import formataCnpjCpf from 'utils/formataCnpjCpf';
import { Container, Label } from './styles';

import LogoImg from './assets/img/logo_fck.png';
import ISO9001Img from './assets/img/iso_9001.png';
import { PropostaImpressaoContext } from '../..';

export default function Header(): JSX.Element {
  const { proposta, propostaVersao } = useContext(PropostaImpressaoContext);
  const { pessoa, PropostaLocalObra } = proposta;

  const logradouro = PropostaLocalObra?.logradouro ?? '';
  const numero = PropostaLocalObra?.numero ?? '';
  const complemento = PropostaLocalObra?.complemento ?? '';

  const local =
    logradouro || numero || complemento
      ? `${logradouro} ${numero} ${complemento}`
      : 'Não Informado';

  const cidade = PropostaLocalObra?.municipio;
  const uf = PropostaLocalObra?.uf;
  const cep = PropostaLocalObra?.cep;
  const inscricaoEstadual = PropostaLocalObra?.inscricaoEstadual;
  const gnf = proposta?.codigoOportunidadeDomsge;
  const fone = '';
  const numeroProposta = `${proposta.id} V${propostaVersao.versao}`;

  function Texto({ children }: { children: string }): JSX.Element {
    return (
      <Row>
        <Col>
          <Label>{children}</Label>
        </Col>
      </Row>
    );
  }

  function CampoValor({
    label,
    valor,
  }: {
    label: string;
    valor?: string | null;
  }): JSX.Element {
    return (
      <Row>
        <Col sm="4">
          <Label>{label}:</Label>
        </Col>
        <Col sm="8">
          <Label>{valor || ''}</Label>
        </Col>
      </Row>
    );
  }

  function DadosEmpresa() {
    const dados = [
      'FOCKINK INDÚSTRIAS ELÉTRICAS LTDA.',
      'CNPJ 03.021.334/0003-00 INSCR.EST.090/0037652',
      'CNPJ (Matriz) 03.021.334/0001-30',
      'Av. Presidente Kennedy, 3280',
      '98.280-000 - Panambi - RS - Brasil',
      'Fone: (55) 3375.9500 / 3375.9651',
      'E-mail: irrigacao@fockink.ind.br',
      'Site: www.fockink.ind.br',
    ];

    return (
      <>
        {dados.map((dado, index) => (
          <Texto key={index}>{dado}</Texto>
        ))}
      </>
    );
  }

  return (
    <Container>
      <Col>
        <Row>
          <img src={LogoImg} alt="Logo" width="50%" height="50%" />
          <div className="mt-5 ml-3">
            <img src={ISO9001Img} alt="ISO 9001" />
          </div>
        </Row>
        <CampoValor label="Nr. Proposta Ext" valor={numeroProposta} />
        <CampoValor label="Nr. GNF" valor={gnf} />

        <CampoValor label="Cliente" valor={pessoa?.razaoSocial} />
        <CampoValor
          label="CNPJ/CPF"
          valor={formataCnpjCpf(pessoa?.documento)}
        />
        <CampoValor label="Inscrição Estadual" valor={inscricaoEstadual} />
      </Col>
      <Col className="text-right">
        <DadosEmpresa />

        <div className="mt-4" />

        <CampoValor
          label="Data"
          valor={
            propostaVersao.dataProposta
              ? new Intl.DateTimeFormat('pt-BR').format(
                  new Date(propostaVersao.dataProposta),
                )
              : ''
          }
        />
        <CampoValor label="Local" valor={local ?? 'Não Informado'} />
        <CampoValor label="Cidade/UF" valor={`${cidade || ''}/${uf || ''}`} />
        <CampoValor label="CEP" valor={cep} />
        {/* <CampoValor label="Fone" valor={fone} /> */}
      </Col>
    </Container>
  );
}
