import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from 'services/api';

interface FotovoltaicaDistribuidoras {
  id: number;
  distribuidora: string;
  fatorPFP?: number;
  icmsMenor1MW: boolean;
  icmsMaior1MW: boolean;
  disjuntorMT?: number;
  religadorMT?: string;
}

const CadastroFotovoltaicaDistribuidoras: React.FC = () => {
  const [fotovoltaicaDistribuidoras, setFotovoltaicaDistribuidoras] = useState(
    [] as FotovoltaicaDistribuidoras[],
  );
  // eslint-disable-next-line prettier/prettier
  const [fotovoltaicaDistribuidorasEditing, setFotovoltaicaDistribuidorasEditing] = useState({} as FotovoltaicaDistribuidoras);

  const [formModalState, setFormModalState] = useState(false);

  async function listaFotovoltaicaDistribuidoras() {
    const response = await api.get('/fotovoltaica-distribuidoras');

    setFotovoltaicaDistribuidoras(response.data);
  }

  useEffect(() => {
    listaFotovoltaicaDistribuidoras();
  }, []);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/fotovoltaica-distribuidoras/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setFotovoltaicaDistribuidoras(
            fotovoltaicaDistribuidoras.filter(item => item.id !== row.id),
          );
        }
      });
      listaFotovoltaicaDistribuidoras();
    } else {
      setFotovoltaicaDistribuidoras(
        fotovoltaicaDistribuidoras.filter(item => item.id !== row.id),
      );
    }
  }

  async function handleInsert() {
    setFotovoltaicaDistribuidorasEditing({} as FotovoltaicaDistribuidoras);
    setFormModalState(!formModalState);
  }

  function handleUpdate(fotovoltaicaDistribuidora: FotovoltaicaDistribuidoras) {
    setFotovoltaicaDistribuidorasEditing(fotovoltaicaDistribuidora);
    setFormModalState(!formModalState);
  }

  async function handleSaveFotovoltaicaDistribuidoras() {
    // eslint-disable-next-line prettier/prettier
    const { distribuidora } = fotovoltaicaDistribuidorasEditing;

    if (!distribuidora) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (fotovoltaicaDistribuidorasEditing.id) {
      await api.put(
        `/fotovoltaica-distribuidoras/${fotovoltaicaDistribuidorasEditing.id}`,
        {
          distribuidora: fotovoltaicaDistribuidorasEditing.distribuidora,
          fatorPFP: fotovoltaicaDistribuidorasEditing.fatorPFP,
          icmsMenor1MW: fotovoltaicaDistribuidorasEditing.icmsMenor1MW,
          icmsMaior1MW: fotovoltaicaDistribuidorasEditing.icmsMaior1MW,
          disjuntorMT: fotovoltaicaDistribuidorasEditing.disjuntorMT,
          religadorMT: fotovoltaicaDistribuidorasEditing.religadorMT,
        },
      );
    } else {
      await api.post(`/fotovoltaica-distribuidoras`, {
        distribuidora: fotovoltaicaDistribuidorasEditing.distribuidora,
        fatorPFP: fotovoltaicaDistribuidorasEditing.fatorPFP,
        icmsMenor1MW: fotovoltaicaDistribuidorasEditing.icmsMenor1MW,
        icmsMaior1MW: fotovoltaicaDistribuidorasEditing.icmsMaior1MW,
        disjuntorMT: fotovoltaicaDistribuidorasEditing.disjuntorMT,
        religadorMT: fotovoltaicaDistribuidorasEditing.religadorMT,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setFotovoltaicaDistribuidorasEditing({} as FotovoltaicaDistribuidoras);
    listaFotovoltaicaDistribuidoras();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Distribuidoras</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Distribuidora*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Distribuidora*"
                            value={
                              fotovoltaicaDistribuidorasEditing.distribuidora
                            }
                            type="text"
                            onChange={text =>
                              setFotovoltaicaDistribuidorasEditing({
                                ...fotovoltaicaDistribuidorasEditing,
                                distribuidora: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Fator PFP </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fator PFP"
                            value={fotovoltaicaDistribuidorasEditing.fatorPFP}
                            type="number"
                            onChange={text =>
                              setFotovoltaicaDistribuidorasEditing({
                                ...fotovoltaicaDistribuidorasEditing,
                                fatorPFP: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>ICMS {'<'} 1MW</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="ICMS < 1MW"
                            type="select"
                            value={
                              fotovoltaicaDistribuidorasEditing.icmsMenor1MW
                                ? 'S'
                                : 'N'
                            }
                            onChange={text =>
                              setFotovoltaicaDistribuidorasEditing({
                                ...fotovoltaicaDistribuidorasEditing,
                                icmsMenor1MW: Boolean(
                                  text.target.value === 'S',
                                ),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim = TUSD
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>ICMS {'>'} 1MW</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="ICMS > 1MW"
                            type="select"
                            value={
                              fotovoltaicaDistribuidorasEditing.icmsMaior1MW
                                ? 'S'
                                : 'N'
                            }
                            onChange={text =>
                              setFotovoltaicaDistribuidorasEditing({
                                ...fotovoltaicaDistribuidorasEditing,
                                icmsMaior1MW: Boolean(
                                  text.target.value === 'S',
                                ),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Disjuntor MT</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Disjuntor MT"
                            value={
                              fotovoltaicaDistribuidorasEditing.disjuntorMT
                            }
                            type="number"
                            onChange={text =>
                              setFotovoltaicaDistribuidorasEditing({
                                ...fotovoltaicaDistribuidorasEditing,
                                disjuntorMT: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <span>Religador MT</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Religador MT"
                            value={
                              fotovoltaicaDistribuidorasEditing.religadorMT
                            }
                            type="text"
                            onChange={text =>
                              setFotovoltaicaDistribuidorasEditing({
                                ...fotovoltaicaDistribuidorasEditing,
                                religadorMT: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveFotovoltaicaDistribuidoras}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Cadastro de Distribuidoras</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Distribuidora</th>
                    <th>Fator PFP</th>
                    <th>ICMS {'<'} 1MW</th>
                    <th>ICMS {'>'} 1MW</th>
                    <th>Disjuntor MT</th>
                    <th>Religador MT</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {fotovoltaicaDistribuidoras.map(fotovoltaicaDistribuidora => (
                    <tr key={fotovoltaicaDistribuidora.id}>
                      <td className="py-1">
                        {fotovoltaicaDistribuidora.distribuidora}
                      </td>
                      <td className="py-1">
                        {fotovoltaicaDistribuidora.fatorPFP
                          ?.toString()
                          .replace('.', ',')}
                      </td>
                      <td className="py-1">
                        {fotovoltaicaDistribuidora.icmsMenor1MW
                          ? 'TUSD'
                          : 'Não'}
                      </td>
                      <td className="py-1">
                        {fotovoltaicaDistribuidora.icmsMaior1MW ? 'Sim' : 'Não'}
                      </td>
                      <td className="py-1">
                        {fotovoltaicaDistribuidora.disjuntorMT}
                      </td>
                      <td className="py-1">
                        {fotovoltaicaDistribuidora.religadorMT}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(fotovoltaicaDistribuidora);
                          }}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(fotovoltaicaDistribuidora);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroFotovoltaicaDistribuidoras;
