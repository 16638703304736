import FichaTecnica from 'models/FichaTecnica';
import { Table } from 'reactstrap';

import { Td } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function Detalhes(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const fichaTecnicaVelocidade = fichaTecnica.FichaTecnicaVelocidades;

  const headersNames = [
    'Regulagem (%)',
    'Precipitação/volta (mm/volta',
    'Tempo/volta (horas)',
  ];

  return (
    <Table bordered responsive>
      <tr>
        {headersNames.map(headerName => (
          <th key={headerName}>{headerName}</th>
        ))}
      </tr>

      {fichaTecnicaVelocidade?.length ? (
        fichaTecnicaVelocidade?.map(velocidade => {
          const { id, regulagem, precipitacao, tempo } = velocidade;

          return (
            <tr key={id}>
              <Td>{regulagem}</Td>
              <Td>{precipitacao}</Td>
              <Td>{tempo}</Td>
            </tr>
          );
        })
      ) : (
        <tr>
          <Td className="text-center" colSpan={3}>
            Nenhum registro encontrado
          </Td>
        </tr>
      )}
    </Table>
  );
}

export default Detalhes;
