import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import api from 'services/api';
import TagModel from '../../../../models/Tag';

const ConfiguradorGruposAgrupadores: React.FC = () => {
  const [tags, settags] = useState([] as TagModel[]);
  // eslint-disable-next-line prettier/prettier
  const [tagEditing, setTagEditing] = useState({} as TagModel);
  const [formModalState, setFormModalState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  async function listaTags() {
    const response = await api.get('/tag');

    settags(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaTags();
  }, []);

  async function handleDelete(tag: TagModel) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/tag/${tag.id}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaTags();
      }
    });
  }

  async function handleInsert() {
    setTagEditing({} as TagModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(tag: TagModel) {
    setTagEditing(tag);

    setFormModalState(!formModalState);
  }

  async function handleSavetag() {
    // eslint-disable-next-line prettier/prettier
    const { descricao } = tagEditing;

    if (!descricao) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);

    if (tagEditing.id) {
      await api.put(`/tag/${tagEditing.id}`, {
        id: tagEditing.id,
        codigo: tagEditing.codigo,
        descricao: tagEditing.descricao.toUpperCase() || '',
        ordem: tagEditing.ordem,
      });
    } else {
      await api.post(`/tag/`, {
        codigo: tagEditing.codigo,
        descricao: tagEditing.descricao.toUpperCase() || '',
        ordem: tagEditing.ordem,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setTagEditing({} as TagModel);
    setIsSaving(false);
    listaTags();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{tagEditing.descricao}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Código *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Código"
                            value={tagEditing.codigo}
                            type="number"
                            onChange={text =>
                              setTagEditing({
                                ...tagEditing,
                                codigo: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Descrição </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={tagEditing.descricao}
                            type="text"
                            onChange={text =>
                              setTagEditing({
                                ...tagEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Ordem *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Ordem"
                            value={tagEditing.ordem}
                            type="number"
                            onChange={text =>
                              setTagEditing({
                                ...tagEditing,
                                ordem: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSavetag}
                        >
                          {isSaving ? `Salvando` : `Salvar`}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Cadastro de Aplicação</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th>Ordem</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {tags.map(tag => (
                    <tr key={tag.id}>
                      <td className="py-2">{tag.codigo}</td>
                      <td className="py-2">{tag.descricao.toUpperCase()}</td>
                      <td className="py-2">{tag.ordem}</td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(tag);
                          }}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(tag);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorGruposAgrupadores;
