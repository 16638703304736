import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
} from 'reactstrap';

import 'antd/dist/antd.css';

import { Mentions } from 'antd';
import UserSmallHeader from 'components/Headers/UserSmallHeader';

import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { BookmarkBorder, Bookmark } from '@material-ui/icons';
import './estilo.css';

import api from 'services/api';

import {
  FaBan,
  FaCheckCircle,
  FaCopy,
  FaEdit,
  FaFileExcel,
  FaPencilAlt,
  FaPlusCircle,
  FaSearch,
  FaTimesCircle,
} from 'react-icons/fa';

import Produto from 'models/Produto';
import RegraItem from 'models/RegraItem';
import RegraItemColuna from 'models/RegraItemColuna';
import toast from 'react-hot-toast';

import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import AutocompleteItemDomsge from 'components/AutocompleteItemDomsge';
import exportToSheet from 'utils/exportToSheet';

import RegraAgrupador from 'models/RegraAgrupador';
import { BuscarRegras, RowFilter, Select, SelectFiltro, Regra } from './styles';

const { Option } = Mentions;

type ItemDomsge = {
  DITE_CODIGO: string;
  FABR_CODIGO: string;
  DITE_DGPE_CODIGO: string;
  FABR_EMPR_CODIGO: string;
  DESCRICAO: string;
  QUANTIDADE?: number;
};

type LocationState = {
  produto?: Produto;
};

const RegrasGrupos: React.FC = () => {
  const location = useLocation<LocationState>();
  const [inputFiltro, setInputFiltro] = useState('');
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtoFiltroId, setProdutoFiltroId] = useState<number>(0);
  const [codEmpresa, setCodEmpresa] = useState('');
  const isFiltroPorProduto = produtoFiltroId !== 0;
  const [regrasItem, setRegrasItem] = useState<RegraItem[]>([]);
  const [regraItemColunas, setRegraItemColunas] = useState<RegraItemColuna[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [regraItemCopiaOrigem, setRegraItemCopiaOrigem] = useState<any>();
  const criandoItem = regrasItem.some(regraItem => regraItem.id === 0);

  const [editandoItemDomsge, setEditandoItemDomsge] = useState<RegraItem>();
  const [itemDomsgeSelecionado, setItemDomsgeSelecionado] =
    useState<ItemDomsge | null>(null);
  const [regraAgrupador, setRegraAgrupador] = useState<RegraAgrupador[]>([]);
  const [inputFiltroAplicacao, setInputFiltroAplicacao] = useState('');
  const [inputFiltroAtividade, setInputFiltroAtividade] = useState('');
  const [inputFiltroSequencia, setInputFiltroSequencia] = useState('');
  const [inputFiltroObservacao, setInputFiltroObservacao] = useState('');
  const [inputFiltroItem, setInputFiltroItem] = useState('');
  const [inputFiltroRegraAplicacao, setInputFiltroRegraAplicacao] =
    useState('');
  const [regraItemEditing, setRegraItemEditing] = useState({} as RegraItem);
  const [idRegraItem, setIdRegraItem] = useState<number>(0);
  const [habilitaBotao, setHabilitaBotao] = useState(false);
  const [regraItemAnterior, setRegraItemAnterior] = useState<RegraItem[]>([]);

  const [produtoFiltrado, setProdutoFiltrado] = useState('');
  const [descricaoProposta, setDescricaoProposta] = useState('');
  const [formModalState, setFormModalState] = useState(false);

  const prefixColumns = '#';
  const prefixCount = 'CONT[';

  const [prefix, setPrefix] = useState(prefixColumns);

  async function carregaProdutos() {
    const response = await api.get('/produto');

    setProdutos(response.data);
  }

  async function carregaAgrupador() {
    const response = await api.get('/regra-agrupador');

    setRegraAgrupador(response.data);
  }

  function getItemDomsgeObj(regraItem: RegraItem): ItemDomsge | undefined {
    if (!regraItem?.material?.diteCodigo) return undefined;

    const {
      diteCodigo,
      diteDgpeCodigo,
      fabrCodigo,
      fabrEmprCodigo,
      descricao,
      quantidade,
    } = regraItem.material;

    return {
      DITE_CODIGO: diteCodigo,
      DITE_DGPE_CODIGO: diteDgpeCodigo,
      FABR_CODIGO: fabrCodigo,
      FABR_EMPR_CODIGO: fabrEmprCodigo,
      DESCRICAO: descricao,
      QUANTIDADE: quantidade,
    };
  }
  // essa tela será alterada

  async function carregaRegrasItemColuna() {
    const response = await api.get(`/regra-item-coluna`);

    setRegraItemColunas(response.data);
  }

  async function limparFiltros() {
    setLoading(true);

    setInputFiltroAtividade('');
    setInputFiltroSequencia('');
    setInputFiltroAplicacao('');
    setInputFiltroObservacao('');
    setProdutoFiltroId(0);
    setInputFiltroRegraAplicacao('');
    setInputFiltroItem('');

    setRegrasItem([]);

    setLoading(false);
  }

  async function editarDescItem() {
    setFormModalState(!formModalState);
  }

  async function filtrarRegras() {
    setLoading(true);
    const response = await api.get('/regra-item/filtro', {
      params: {
        atividade: inputFiltroAtividade,
        sequencia: inputFiltroSequencia,
        aplicacao: inputFiltroAplicacao,
        observacao: inputFiltroObservacao,
        produtoId: produtoFiltroId,
        regraAplicacao: inputFiltroRegraAplicacao,
        item: inputFiltroItem,
      },
    });

    const regrasItemResponse = response.data as RegraItem[];

    setRegrasItem(
      regrasItemResponse.map(regraItem => ({
        ...regraItem,
        show: false,
        loadingItens: false,
        inputItemDomsge: `${regraItem.material?.diteCodigo} - ${regraItem.material?.descricao}`,
        itemSelecionado: getItemDomsgeObj(regraItem),
        diteCodigo: regraItem.material?.diteCodigo,
      })),
    );

    setLoading(false);
  }

  async function handleUpdateDescMaterial() {
    if (!produtoFiltroId) {
      toast.error('A seleção do produto é obrigatória!');
      return;
    }

    if (!itemDomsgeSelecionado?.DITE_CODIGO) {
      toast.error('O Item é obrigatório');
      return;
    }
    setFormModalState(false);

    await Swal.fire({
      icon: 'warning',
      html: `
        <h1>Atenção</h1>
        <p>Tem certeza que deseja prosseguir com a operação? Todas as regras que contenham este item e produto serão afetadas!</p>`,
      width: '650px',
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.put('/material/atualizar-descricao-proposta', {
          diteCodigo: itemDomsgeSelecionado?.DITE_CODIGO ?? null,
          descricaoProposta,
          produtoId: produtoFiltroId,
        });
        Swal.close();
        toast.success('Registro feito');
        filtrarRegras();
      }
    });

    setLoading(false);
    setDescricaoProposta('');
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaProdutos();
    carregaAgrupador();
    carregaRegrasItemColuna();
  }, []);

  useEffect(() => {
    if (location.state?.produto) {
      const { produto } = location.state;

      setProdutoFiltroId(produto.id);
      setCodEmpresa(String(produto.codigoEmpresa));
    }
  }, [location.state]);

  function handleSetRegraItemValueItemDomsge(
    regraItem?: RegraItem,
    itemSelecionado?: ItemDomsge | null,
  ) {
    if (!regraItem || !itemSelecionado) return;

    setRegrasItem(
      regrasItem.map(regraItem_ =>
        regraItem_.id === regraItem.id
          ? {
              ...regraItem_,
              itemSelecionado,
              diteCodigo: itemSelecionado?.DITE_CODIGO,
            }
          : regraItem_,
      ),
    );

    setEditandoItemDomsge(undefined);
  }

  function handleVoltaValorAnterior(idRegraAtual: number) {
    const regraAtual = regraItemAnterior.filter(
      regraItem_ => regraItem_.id === idRegraAtual,
    );

    setRegrasItem(
      regraAtual.map(regraItem_ =>
        regraItem_.id === idRegraAtual
          ? { ...regraItem_, regraAlterada: false }
          : regraItem_,
      ),
    );
    filtrarRegras();
  }

  function handleSalvalorAtual(idRegraAtual: number) {
    const regraAtual = regrasItem.filter(
      regraItem_ => regraItem_.id === idRegraAtual,
    );

    setRegraItemAnterior(regraAtual);
  }

  function handleSetRegraItemValue(
    regraItem: RegraItem,
    attrName: string,
    value: string | number | boolean | null | ItemDomsge,
  ) {
    setRegrasItem(
      regrasItem.map(regraItem_ =>
        regraItem_.id === regraItem.id
          ? { ...regraItem_, [attrName]: value, regraAlterada: true }
          : regraItem_,
      ),
    );
    setRegraItemEditing({
      ...regraItemEditing,
      [attrName]: value,
    });
  }

  function handleEditRegraValue() {
    setRegrasItem(
      regrasItem.map(regraItem_ =>
        regraItem_.id === regraItemEditing.id
          ? {
              ...regraItem_,
              aplicacao: regraItemEditing.aplicacao,
              regraAplicacao: regraItemEditing.regraAplicacao,
              regraQuantidade: regraItemEditing.regraQuantidade,
              atividade: regraItemEditing.atividade,
              sequencia: regraItemEditing.sequencia,
              ativo: regraItemEditing.ativo,
              itemSelecionado: regraItemEditing.itemSelecionado,
              produtoId: regraItemEditing.produtoId,
              observacao: regraItemEditing.observacao,
              itemListadoProposta: regraItemEditing.itemListadoProposta,
              regraAgrupadorId: regraItemEditing.regraAgrupadorId,
              regraAlterada: true,
              onEdit: true,
              nomeItemProposta: regraItemEditing.nomeItemProposta,
            }
          : regraItem_,
      ),
    );
  }

  function handleChangeCheckBox(
    regraItem: RegraItem,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked, name } = event.target;
    handleSetRegraItemValue(regraItem, name, checked);
  }

  function handleChangeAtivo(
    regraItem: RegraItem,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = event.target;
    const value = checked ? 1 : 0;
    handleSetRegraItemValue(regraItem, 'ativo', value);
  }

  function handleChangeRegraItem(
    regraItem: RegraItem,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    handleSetRegraItemValue(regraItem, event.target.name, event.target.value);
  }

  async function validaRegra(regraItem: RegraItem): Promise<RegraItem> {
    const response = await api.post('/regra-item/valida-regra', { regraItem });
    return response.data;
  }

  function validaRegraDuplicada(regraItem: RegraItem): boolean {
    if (!regraItemCopiaOrigem) return false;

    const {
      produtoId,
      regraAplicacao,
      regraQuantidade,
      aplicacao,
      atividade,
      inputItemDomsge,
      sequencia,
      observacao,
    } = regraItem;

    const regraItemValoresComparacao = {
      produtoId,
      regraAplicacao,
      regraQuantidade,
      aplicacao,
      atividade,
      inputItemDomsge,
      sequencia,
      observacao,
    };

    const regraItemValoresComparacaoOrigem = {
      produtoId: regraItemCopiaOrigem.produtoId,
      regraAplicacao: regraItemCopiaOrigem.regraAplicacao,
      regraQuantidade: regraItemCopiaOrigem.regraQuantidade,
      aplicacao: regraItemCopiaOrigem.aplicacao,
      atividade: regraItemCopiaOrigem.atividade,
      inputItemDomsge: regraItemCopiaOrigem.inputItemDomsge,
      sequencia: regraItemCopiaOrigem.sequencia,
      observacao: regraItemCopiaOrigem.observacao,
    };

    const valoresIguais =
      Object.entries(regraItemValoresComparacaoOrigem).toString() ===
      Object.entries(regraItemValoresComparacao).toString();

    return valoresIguais;
  }

  async function handleSave(regraItem: RegraItem) {
    const {
      id,
      materialId,
      regraQuantidade,
      regraAplicacao,
      atividade,
      sequencia,
      ativo,
      diteCodigo,
      produtoId,
      observacao,
      itemListadoProposta,
      regraAgrupadorId,
      nomeItemProposta,
      naoConsiderarPerdaCarga,
    } = regraItem;

    const propsComuns = {
      regraQuantidade,
      regraAplicacao,
      atividade,
      sequencia,
      diteCodigo,
      ativo,
      produtoId,
      observacao,
      itemListadoProposta,
      regraAgrupadorId,
      nomeItemProposta,
      naoConsiderarPerdaCarga: naoConsiderarPerdaCarga ?? false,
    };

    async function handleUpdate() {
      await api.put('/regra-item', {
        id,
        materialId,
        ...propsComuns,
      });
    }

    async function handleCreate() {
      const response = await api.post('/regra-item', {
        ...propsComuns,
      });

      return response.data;
    }

    if (!regraAgrupadorId) {
      toast.error('A aplicação deve ser informada');
      return;
    }

    if (!produtoId) {
      toast.error('O produto deve ser informado');
      return;
    }

    if (!atividade && itemListadoProposta) {
      toast.error('Informe uma atividade para incluir item na proposta');
      return;
    }

    if (!regraAgrupador || !regraAplicacao) {
      toast.error('As regras de Aplicação e Quantidade devem ser informadas');
      return;
    }

    try {
      const toastSalvaRegraId = toast.loading('Salvando...');

      let novaRegraItem = regraItem;

      if (regraItem.id === 0) {
        const valoresIguais = validaRegraDuplicada(regraItem);

        if (valoresIguais) {
          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            html: 'Regra identica à regra de origem!',
          });

          toast.dismiss();

          return;
        }

        novaRegraItem = await handleCreate();

        setRegraItemCopiaOrigem(undefined);
      } else {
        await handleUpdate();
      }

      toast.loading('Validando sintaxe das regras...', {
        id: toastSalvaRegraId,
      });

      await filtrarRegras();
      const regraItemValidada = await validaRegra(novaRegraItem);
      toast.dismiss();
      if (
        !regraItemValidada.sintaxeAplicacaoValida ||
        !regraItemValidada.sintaxeQuantidadeValida
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          html: 'Regra salva, porém com erros',
        });
        return;
      }

      setHabilitaBotao(false);
      toast.success('Feito!');
    } catch (error) {
      toast.dismiss();
      toast.error('Ops! Algo de errado aconteceu!');
    }
  }

  async function handleDelete(regraItemId: number) {
    const newRegrasItem = regrasItem.filter(
      regraItem => regraItem.id !== regraItemId,
    );

    setRegrasItem(newRegrasItem);

    if (regraItemId !== 0) {
      await api.put(`/regra-item/desabilita/${regraItemId}`);
    }
  }

  function questionaExclusaoRegra(regraItem: RegraItem) {
    Swal.fire({
      title: `Excluir regra #${regraItem.id ?? ''}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, excluir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(result => {
      if (result.isConfirmed) {
        handleDelete(regraItem.id);
      }
    });
  }

  function inputEncontrouFiltro(texto?: string | null) {
    if (!texto) return false;

    return texto.toLowerCase().includes(inputFiltro.toLowerCase());
  }

  function retornaRegrasItemFiltradas() {
    const regrasItemProduto = isFiltroPorProduto
      ? regrasItem.filter(regraItem => regraItem.produtoId === produtoFiltroId)
      : regrasItem;

    return inputFiltro
      ? regrasItemProduto.filter(
          regraItem =>
            inputEncontrouFiltro(String(regraItem.id)) ||
            inputEncontrouFiltro(regraItem?.regraAplicacao) ||
            inputEncontrouFiltro(regraItem?.regraQuantidade) ||
            inputEncontrouFiltro(regraItem?.aplicacao) ||
            inputEncontrouFiltro(String(regraItem?.atividade)) ||
            inputEncontrouFiltro(regraItem?.inputItemDomsge) ||
            inputEncontrouFiltro(String(regraItem?.sequencia)) ||
            inputEncontrouFiltro(String(regraItem?.observacao)),
        )
      : regrasItemProduto;
  }

  function handleAdicionar() {
    setRegrasItem([
      {
        id: 0,
        materialId: 0,
        ativo: 0,
        show: false,
        loadingItens: false,
        inputItemDomsge: '',
        itemSelecionado: null,
        diteCodigo: null,
        produtoId: produtoFiltroId,
        sintaxeQuantidadeValida: true,
        sintaxeAplicacaoValida: true,
      },
      ...regrasItem,
    ]);
    setHabilitaBotao(true);
  }

  function handleDuplicar(regraItem: RegraItem) {
    const {
      produtoId,
      regraAplicacao,
      regraQuantidade,
      aplicacao,
      atividade,
      inputItemDomsge,
      sequencia,
      observacao,
      nomeItemProposta,
    } = regraItem;

    setRegraItemCopiaOrigem({
      produtoId,
      inputItemDomsge,
      aplicacao,
      regraQuantidade,
      regraAplicacao,
      sequencia,
      atividade,
      observacao,
      nomeItemProposta,
    });

    setRegrasItem([
      {
        ...regraItem,
        id: 0,
      },
      ...regrasItem,
    ]);
  }

  function handleExportToSheet() {
    const regrasItemToExport = retornaRegrasItemFiltradas();

    const dadosPlanilha = regrasItemToExport.map(regraItem => {
      const {
        produtoId,
        itemSelecionado,
        aplicacao,
        regraQuantidade,
        regraAplicacao,
        sequencia,
        atividade,
        ativo,
        observacao,
      } = regraItem;

      return {
        Id: regraItem.id,
        Produto: produtos.find(produto => produto.id === produtoId)?.nome,
        'Código Item Domsge': itemSelecionado?.DITE_CODIGO,
        'Descrição Item Domsge': itemSelecionado?.DESCRICAO,
        Aplicação: aplicacao,
        'Regra quantidade': regraQuantidade,
        'Regra aplicação': regraAplicacao,
        Sequência: sequencia,
        Atividade: atividade,
        Observação: observacao,
        Ativo: ativo ? 'Sim' : 'Não',
      };
    });

    const nomeArquivo = 'Regras';

    exportToSheet(dadosPlanilha, nomeArquivo);
  }

  function renderListaSugestoes(produtoId: number) {
    const regraItemColunasFiltradas =
      produtoId !== 0
        ? regraItemColunas.filter(coluna => coluna.produtoId === produtoId)
        : regraItemColunas;

    // coluna => coluna.produtoId === produtoId || !coluna.produtoId,

    const listaSugestoesQuestao = [] as string[];
    const listaSugestoesCont = [] as string[];

    regraItemColunasFiltradas.forEach(coluna => {
      const isTipoColuna = coluna.tipo === 'coluna';
      const isTipoContador = coluna.tipo === 'contador';

      if (isTipoColuna) {
        listaSugestoesQuestao.push(`${coluna.nome}#`);
        return;
      }

      if (isTipoContador) {
        listaSugestoesCont.push(`${coluna.nome}`);
      }
    });

    const listaSugestoes = {
      [prefixColumns]: listaSugestoesQuestao,
      [prefixCount]: listaSugestoesCont,
    } as any;

    return (listaSugestoes[prefix] || []).map((value: string) => (
      <Option key={value} value={value}>
        {value}
      </Option>
    ));
  }

  function onSearch(_: any, newPrefix: string) {
    setPrefix(newPrefix);
  }

  function RegrasItem() {
    // const regrasItemProdutoFiltro = retornaRegrasItemFiltradas();

    return regrasItem.map(regraItem => {
      const {
        id,
        sintaxeAplicacaoValida,
        sintaxeQuantidadeValida,
        aplicacao,
        regraAplicacao,
        regraQuantidade,
        atividade,
        sequencia,
        ativo,
        itemSelecionado,
        produtoId,
        observacao,
        itemListadoProposta,
        regraAgrupadorId,
        nomeItemProposta,
        material,
        naoConsiderarPerdaCarga,
      } = regraItem;

      const itemSelecionadoText = itemSelecionado?.DITE_CODIGO
        ? `${itemSelecionado?.DITE_CODIGO} - ${itemSelecionado?.DESCRICAO}`
        : '';

      const descItemProposta = nomeItemProposta || material?.descricao;

      const emprCodigo =
        produtoId && produtoId !== 0
          ? produtos.find(produto => produto.id === produtoId)?.codigoEmpresa
          : '';

      const sintaxeInvalida =
        !sintaxeAplicacaoValida || !sintaxeQuantidadeValida;

      const labelProposta = `Incluir na proposta   - Identificado como:   ${descItemProposta}`;

      return (
        <div className="container-fluxos">
          <Col>
            <Regra
              key={id}
              criando={id === 0}
              sintaxeInvalida={sintaxeInvalida}
              className="pb-0"
            >
              <Row>
                <Col sm="1">
                  <h6 className="heading-small text-muted">#{regraItem.id}</h6>
                  <Checkbox
                    disabled={
                      regraItem.id !== 0 && regraItem.id !== idRegraItem
                    }
                    checked={!!ativo}
                    onChange={e => handleChangeAtivo(regraItem, e)}
                    color="primary"
                  />
                </Col>
                <Col sm="11">
                  <Row>
                    <Col sm="2">
                      <Select
                        disabled={
                          regraItem.id !== 0 && regraItem.id !== idRegraItem
                        }
                        value={produtoId ?? 0}
                        onChange={value =>
                          handleSetRegraItemValue(
                            regraItem,
                            'produtoId',
                            value ? Number(value) : null,
                          )
                        }
                      >
                        <Select.Option value={0}>Todos produtos</Select.Option>
                        {produtos.map(produto => (
                          <Select.Option key={produto.id} value={produto.id}>
                            {produto.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col sm="6">
                      <InputGroup className="input-group-alternative">
                        <Tooltip title={itemSelecionadoText}>
                          <Input
                            disabled
                            placeholder="Item DomSGE"
                            type="text"
                            value={itemSelecionadoText}
                          />
                        </Tooltip>
                        <Button
                          disabled={
                            regraItem.id !== 0 && regraItem.id !== idRegraItem
                          }
                          size="sm"
                          className="btn-icon btn-2"
                          color="secondary"
                          type="button"
                          onClick={() => {
                            setEditandoItemDomsge(regraItem);
                            setProdutoFiltrado(emprCodigo ?? '');
                          }}
                        >
                          <FaPencilAlt />
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col sm="3">
                      <Select
                        value={regraAgrupadorId ?? 0}
                        disabled={
                          regraItem.id !== 0 && regraItem.id !== idRegraItem
                        }
                        onChange={value =>
                          handleSetRegraItemValue(
                            regraItem,
                            'regraAgrupadorId',
                            value ? Number(value) : null,
                          )
                        }
                      >
                        {regraAgrupador.map(agrupador => (
                          <Select.Option
                            key={agrupador.id}
                            value={agrupador.id}
                          >
                            {agrupador.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col sm="1">
                      <Tooltip title="Duplicar">
                        <Button
                          disabled={criandoItem}
                          size="sm"
                          className="btn-icon"
                          color="secondary"
                          type="button"
                          onClick={() => handleDuplicar(regraItem)}
                        >
                          <FaCopy />
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col className="mt-2" sm="11">
                      <Mentions
                        style={{ width: '100%', height: '43px' }}
                        placeholder="Regra quantidade; entre com # para sugestões"
                        prefix={[prefixColumns, prefixCount]}
                        onSearch={onSearch}
                        disabled={
                          (regraItem.id !== 0 &&
                            regraItem.id !== idRegraItem) ||
                          !produtoId
                        }
                        value={regraQuantidade ?? ''}
                        onChange={text =>
                          handleSetRegraItemValue(
                            regraItem,
                            'regraQuantidade',
                            text,
                          )
                        }
                      >
                        {renderListaSugestoes(Number(produtoId))}
                      </Mentions>
                    </Col>
                    <Col className="mt-2 ml--4" sm="1">
                      {!sintaxeQuantidadeValida && (
                        <Tooltip title="Sintaxe quantidade inválida">
                          <FaTimesCircle color="#f5365c" />
                        </Tooltip>
                      )}
                    </Col>

                    <Col className="mt-2" sm="11">
                      <Mentions
                        style={{ width: '100%', height: '43px' }}
                        placeholder="Regra aplicação; entre com # para sugestões"
                        prefix={[prefixColumns, prefixCount]}
                        onSearch={onSearch}
                        disabled={
                          (regraItem.id !== 0 &&
                            regraItem.id !== idRegraItem) ||
                          !produtoId
                        }
                        value={regraAplicacao ?? ''}
                        onChange={text =>
                          handleSetRegraItemValue(
                            regraItem,
                            'regraAplicacao',
                            text,
                          )
                        }
                      >
                        {renderListaSugestoes(Number(produtoId))}
                      </Mentions>
                    </Col>

                    <Col className="mt-2 ml--4" sm="1">
                      {!sintaxeAplicacaoValida && (
                        <Tooltip title="Sintaxe aplicação inválida">
                          <FaTimesCircle color="#f5365c" />
                        </Tooltip>
                      )}
                    </Col>

                    <Col className="mt-2" sm="2">
                      <Input
                        placeholder="Atividade"
                        type="number"
                        name="atividade"
                        disabled={
                          regraItem.id !== 0 && regraItem.id !== idRegraItem
                        }
                        value={atividade ?? ''}
                        onChange={e => handleChangeRegraItem(regraItem, e)}
                      />
                    </Col>

                    <Col className="mt-2" sm="2">
                      <Input
                        placeholder="Sequência"
                        type="number"
                        name="sequencia"
                        disabled={
                          regraItem.id !== 0 && regraItem.id !== idRegraItem
                        }
                        min={0}
                        value={sequencia ?? ''}
                        onChange={e => handleChangeRegraItem(regraItem, e)}
                      />
                    </Col>

                    <Col className="mt-2" sm="7">
                      <Input
                        placeholder="Observação"
                        type="text"
                        name="observacao"
                        disabled={
                          regraItem.id !== 0 && regraItem.id !== idRegraItem
                        }
                        value={observacao ?? ''}
                        onChange={e => handleChangeRegraItem(regraItem, e)}
                      />
                    </Col>

                    <Col className="mt-2" sm="11">
                      <>
                        <FormControlLabel
                          disabled={
                            regraItem.id !== 0 && regraItem.id !== idRegraItem
                          }
                          className="mb-0"
                          control={
                            <Checkbox
                              checked={!!naoConsiderarPerdaCarga}
                              onChange={e => handleChangeCheckBox(regraItem, e)}
                              name="naoConsiderarPerdaCarga"
                              color="secondary"
                              icon={<BookmarkBorder />}
                              checkedIcon={<Bookmark />}
                            />
                          }
                          label="Ignorar no cálculo de perda de carga, se gerado"
                        />
                      </>
                    </Col>

                    <Col sm="11">
                      <>
                        <FormControlLabel
                          disabled={
                            regraItem.id !== 0 && regraItem.id !== idRegraItem
                          }
                          className="mb-0"
                          control={
                            <Checkbox
                              checked={!!itemListadoProposta}
                              onChange={e => handleChangeCheckBox(regraItem, e)}
                              name="itemListadoProposta"
                              color="secondary"
                              icon={<BookmarkBorder />}
                              checkedIcon={<Bookmark />}
                            />
                          }
                          label={labelProposta}
                        />
                        <div className="float-right">
                          {regraItem.id === idRegraItem &&
                            regraItem.regraAlterada &&
                            habilitaBotao && (
                              <Button
                                size="sm"
                                className="btn-icon btn-2"
                                color="primary"
                                type="button"
                                onClick={() => {
                                  handleSave(regraItem);
                                  setCodEmpresa(String(emprCodigo));
                                  setIdRegraItem(0);
                                }}
                              >
                                <FaCheckCircle /> Salvar
                              </Button>
                            )}
                          {regraItem.id === idRegraItem && habilitaBotao && (
                            <Button
                              size="sm"
                              className="btn-icon btn-2"
                              color="default"
                              type="button"
                              onClick={() => {
                                setIdRegraItem(0);
                                setHabilitaBotao(false);
                                handleVoltaValorAnterior(Number(regraItem.id));
                              }}
                            >
                              Cancelar
                            </Button>
                          )}
                          <Button
                            size="sm"
                            className="btn-icon btn-2"
                            color="danger"
                            type="button"
                            hidden={habilitaBotao}
                            onClick={() => questionaExclusaoRegra(regraItem)}
                          >
                            <FaTimesCircle /> Excluir
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon btn-2"
                            color="warning"
                            type="button"
                            hidden={habilitaBotao}
                            onClick={() => {
                              handleEditRegraValue();
                              setIdRegraItem(regraItem.id);
                              setHabilitaBotao(true);
                              handleSalvalorAtual(Number(regraItem.id));
                            }}
                          >
                            <FaEdit /> Editar
                          </Button>
                        </div>
                      </>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Regra>
          </Col>
        </div>
      );
    });
  }

  function handleSelecionarItemDomsge(itemDomsge: ItemDomsge | null) {
    setItemDomsgeSelecionado(itemDomsge);

    setRegrasItem(
      regrasItem.map(regraItem_ =>
        regraItem_.id === editandoItemDomsge?.id ?? 0
          ? { ...regraItem_, regraAlterada: true }
          : regraItem_,
      ),
    );
  }

  return (
    <>
      {
        // <div className="header-fixo" />
      }
      <UserSmallHeader />
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: '60vw', width: '100%' }}
        isOpen={!!editandoItemDomsge}
        toggle={() => {
          setEditandoItemDomsge(undefined);
          setProdutoFiltrado('');
        }}
        onClosed={() => {
          setEditandoItemDomsge(undefined);
          setProdutoFiltrado('');
        }}
      >
        <AutocompleteItemDomsge
          handleSelecionarItemDomsge={handleSelecionarItemDomsge}
          emprCodigo={produtoFiltrado}
        >
          <Button
            className="btn-icon btn-2"
            color="primary"
            type="button"
            onClick={() => {
              handleSetRegraItemValueItemDomsge(
                editandoItemDomsge,
                itemDomsgeSelecionado,
              );
            }}
          >
            <FaCheckCircle />
          </Button>
        </AutocompleteItemDomsge>
      </Modal>
      {/* Modal edição */}
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={formModalState}
        toggle={() => {
          setFormModalState(!formModalState);
          setDescricaoProposta('');
        }}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Edtar descrição do material na proposta</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3 mr-3">
                  <Row>
                    <Col sm="3">
                      <SelectFiltro
                        value={produtoFiltroId ?? 0}
                        onChange={value => setProdutoFiltroId(Number(value))}
                      >
                        <SelectFiltro.Option value={0}>
                          Todos produtos
                        </SelectFiltro.Option>
                        {produtos.map(produto => (
                          <SelectFiltro.Option
                            key={produto.id}
                            value={produto.id}
                          >
                            {produto.nome}
                          </SelectFiltro.Option>
                        ))}
                      </SelectFiltro>
                    </Col>
                    <Col sm="9">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-tag" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          placeholder="Descrição do material na proposta"
                          type="text"
                          value={descricaoProposta}
                          onChange={text =>
                            setDescricaoProposta(
                              text.target.value.toUpperCase(),
                            )
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="mb-3">
                  <AutocompleteItemDomsge
                    handleSelecionarItemDomsge={handleSelecionarItemDomsge}
                    emprCodigo={produtoFiltrado}
                    noAutoFocus
                    maxWidth="80%"
                    hideTitle
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Row>
                    <div>
                      <Alert color="warning" className="mt-2 mr-3 ml-3">
                        <p>
                          A alteração da descrição do material na proposta é
                          feita em lote e será refletida em todas as regras onde
                          o material e produto selecionado estão inseridos.
                        </p>
                      </Alert>
                    </div>
                  </Row>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => {
                      handleSetRegraItemValueItemDomsge(
                        editandoItemDomsge,
                        itemDomsgeSelecionado,
                      );
                      handleUpdateDescMaterial();
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      <div className="menu-fixo">
        <Col>
          <RowFilter>
            <Col sm="8">
              <RowFilter>
                <SelectFiltro
                  value={produtoFiltroId ?? 0}
                  onChange={value => setProdutoFiltroId(Number(value))}
                >
                  <SelectFiltro.Option value={0}>
                    Todos produtos
                  </SelectFiltro.Option>
                  {produtos.map(produto => (
                    <SelectFiltro.Option key={produto.id} value={produto.id}>
                      {produto.nome}
                    </SelectFiltro.Option>
                  ))}
                </SelectFiltro>
                <BuscarRegras
                  className="ml-2 mr-2"
                  id="outlined-basic"
                  label="Filtrar por aplicação"
                  variant="outlined"
                  size="small"
                  value={inputFiltroAplicacao}
                  onChange={event =>
                    setInputFiltroAplicacao(event.target.value)
                  }
                />
                <BuscarRegras
                  className="ml-2 mr-2"
                  id="outlined-basic"
                  label="Filtrar por atividade"
                  variant="outlined"
                  size="small"
                  value={inputFiltroAtividade}
                  onChange={event =>
                    setInputFiltroAtividade(event.target.value)
                  }
                />
                <BuscarRegras
                  className="ml-2 mr-2"
                  id="outlined-basic"
                  label="Filtrar por sequência"
                  variant="outlined"
                  size="small"
                  value={inputFiltroSequencia}
                  onChange={event =>
                    setInputFiltroSequencia(event.target.value)
                  }
                />
              </RowFilter>
            </Col>
            <Col sm="3">
              <RowFilter>
                <Button
                  className="btn-icon btn-2"
                  color="success"
                  type="button"
                  onClick={() => filtrarRegras()}
                >
                  {' '}
                  Filtrar
                </Button>
                <Button
                  className="btn-icon btn-2 sm"
                  color="default"
                  type="button"
                  onClick={() => editarDescItem()}
                >
                  <FaEdit />
                </Button>
                <Button
                  className="btn-icon btn-2"
                  color="danger"
                  type="button"
                  onClick={() => limparFiltros()}
                >
                  <FaBan />
                </Button>
                <Button
                  disabled={criandoItem}
                  size="sm"
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={handleAdicionar}
                >
                  <FaPlusCircle />
                  <span className="btn-inner--text">Novo</span>
                </Button>

                <Button
                  size="sm"
                  className="btn btn-outline-success"
                  type="button"
                  onClick={() => handleExportToSheet()}
                >
                  <FaFileExcel />
                  <span className="btn-inner--text">Exportar</span>
                </Button>
              </RowFilter>
            </Col>
          </RowFilter>
          <RowFilter>
            <Col sm="8">
              <RowFilter>
                <BuscarRegras
                  className="mr-2"
                  id="outlined-basic"
                  label="Filtrar por observação"
                  variant="outlined"
                  size="small"
                  value={inputFiltroObservacao}
                  onChange={event =>
                    setInputFiltroObservacao(event.target.value)
                  }
                />
                <BuscarRegras
                  className="ml-2 mr-2"
                  id="outlined-basic"
                  label="Filtrar por Regra Aplicação"
                  variant="outlined"
                  size="small"
                  value={inputFiltroRegraAplicacao}
                  onChange={event =>
                    setInputFiltroRegraAplicacao(event.target.value)
                  }
                />
                <BuscarRegras
                  className="ml-2 mr-2"
                  id="outlined-basic"
                  label="Filtrar por Cod. Item"
                  variant="outlined"
                  size="small"
                  value={inputFiltroItem}
                  onChange={event => setInputFiltroItem(event.target.value)}
                />
              </RowFilter>
            </Col>
            <Col sm="3" />
          </RowFilter>
        </Col>
      </div>

      <Container
        className="mt-4 container-fluxos"
        fluid
        style={{ backgroundColor: '#fff', border: '1px solid #ebe9e9' }}
      >
        <Col>
          {loading ? (
            <Row>
              <Col className="text-center my-3">
                <strong className="text-muted">Carregando...</strong>
              </Col>
            </Row>
          ) : (
            RegrasItem()
          )}
        </Col>
      </Container>
    </>
  );
};

export default RegrasGrupos;
