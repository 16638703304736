import { Input } from 'reactstrap';

interface Option {
  value: string | number;
  name: string | null;
}

interface Props {
  options: Option[];
  value?: string | number | readonly string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  [x: string]: any;
}

export default function InputSelect({
  options,
  value,
  onChange,
  hideNoOption = false,
  ...rest
}: Props): JSX.Element {
  return (
    <Input
      className="form-control-alternative"
      type="select"
      value={value}
      onChange={onChange}
      {...rest}
    >
      {!hideNoOption && <option value="">Selecione...</option>}
      {options.map(option => (
        <option value={option.value}>{option.name || ''}</option>
      ))}
    </Input>
  );
}
