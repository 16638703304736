import UpdateDeleteInputGroup from 'components/UpdateDeleteInputGroup';
import Material from 'models/Material';
import { Input } from 'reactstrap';
import { Descricao, Td, Tr } from './styles';

interface OrcamentoMaterialItem {
  id: number;
  quantidade: number;
  valor: number | null;
  valorTotal: number | null;
  atividade: number | null;
  material: Material;
}

export default function MaterialItem({
  propostaMaterial,
  onClickUpdate,
  onClickDelete,
  disabled,
  handleChangeValue,
  canEdit,
  canDelete,
}: {
  propostaMaterial: OrcamentoMaterialItem;
  onClickUpdate?: () => void;
  onClickDelete?: () => void;
  disabled?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  handleChangeValue?: (
    id: number,
    name: string,
    value: number | string,
  ) => void;
}): JSX.Element {
  const { id, atividade, material, quantidade } = propostaMaterial;

  return (
    <Tr>
      <Td>{atividade}</Td>
      <Td>
        <Descricao>
          {material.diteCodigo} - {material.descricao}
        </Descricao>
      </Td>
      <Td className="text-center">
        {canEdit ? (
          <Input
            key={id}
            className="mt--1 mb-2"
            bsSize="sm"
            type="number"
            min={1}
            value={String(quantidade)}
            onChange={event =>
              handleChangeValue
                ? handleChangeValue(
                    id,
                    'quantidade',
                    parseInt(event.target.value, 10),
                  )
                : undefined
            }
          />
        ) : (
          quantidade
        )}
      </Td>
      {(canEdit || canDelete) && (
        <Td>
          <UpdateDeleteInputGroup
            className="mr--3"
            disabled={disabled}
            canEdit={canEdit}
            canDelete={canDelete}
            onClickUpdate={onClickUpdate}
            onClickDelete={onClickDelete}
          />
        </Td>
      )}
    </Tr>
  );
}
