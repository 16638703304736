import FichaTecnica from 'models/FichaTecnica';
import { Card, Col, Label } from 'reactstrap';

import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import Quadro from 'views/pages/OrcamentoResultado/components/atoms/Quadro';
import {
  RowFoot,
  ColName,
} from 'views/pages/OrcamentoResultado/components/organisms/DadosCalculados/components/AlturasManometricas/styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function CargasEletricas(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const { bombaFertilizante, painelComando, parteAerea, cargasEletricasTotal } =
    fichaTecnica;

  const TITULO = 'CARGAS ELÉTRICAS';
  const RODAPE = 'Total';
  const UNIDADE_MEDIDA = 'kVA';

  const campos = [
    { label: 'Bomba Fertilizante', valor: bombaFertilizante },
    {
      label: 'Painel de Comando',
      valor: painelComando,
    },
    { label: 'Parte Aérea', valor: parteAerea },
  ];

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Col className="mt-2">
          {campos.map(campo => (
            <Campo
              sm="12"
              key={campo.label}
              number
              label={campo.label}
              valor={campo.valor}
              unidade={UNIDADE_MEDIDA}
            />
          ))}
        </Col>

        <RowFoot className="pr-3">
          <ColName sm="8">
            <Label>{RODAPE}: </Label>
          </ColName>

          <Col className="text-right pr-4" sm="4">
            <Label>
              {cargasEletricasTotal} {UNIDADE_MEDIDA}
            </Label>
          </Col>
        </RowFoot>
      </Quadro>
    </Card>
  );
}

export default CargasEletricas;
