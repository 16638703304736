import { CircularProgress } from '@material-ui/core';
import { useAuth } from 'hooks/auth';
import Log from 'models/Log';
import Usuario from 'models/Usuario';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';
import { useQuery } from 'react-query';
import {
  CardHeader,
  Badge,
  Modal,
  ModalBody,
  Row,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Col,
  Table,
} from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';

interface ModalLog {
  configuracaoId: number;
  modalState: boolean;
  toggle: () => void;
  codigoOportunidade: number;
}

interface iUsuario {
  id: number;
  apelido: string | '';
  nome: string | '';
  username: string | '';
  isAtivo: boolean;
}
interface OrcamentoAcesso {
  id: number;
  orcamentoId: number;
  usuarioId: number;
  acessoLimitado: boolean;
  usuario: Usuario;
}

export default function ModalAcesso({
  configuracaoId,
  codigoOportunidade,
  modalState,
  toggle,
}: ModalLog): JSX.Element {
  const { usuario }: { usuario: Usuario } = useAuth();
  const [registros, setRegistros] = useState<OrcamentoAcesso[]>([]);
  const [usuarios, setUsuarios] = useState<iUsuario[]>([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState({} as iUsuario);
  const [registrosFiltrados, setRegistrosFiltrados] = useState<
    OrcamentoAcesso[]
  >([]);
  const [codigoUsuarioDomSge, setCodigoUsuarioDomSge] = useState(0);
  const [codOportunidade, setCodOportunidade] = useState(0);

  const [isSaving, setIsSaving] = useState(false);

  async function getRegistros() {
    const response = await api.get(`/orcamento/acesso/${configuracaoId}`);

    const reg: OrcamentoAcesso[] = response.data;

    setRegistros(reg);
    if (codigoUsuarioDomSge > 0) {
      const filtro = reg.filter(
        regi => regi.usuarioId === Number(codigoUsuarioDomSge),
      );
      setRegistrosFiltrados(filtro);
    }
  }

  async function getUsuarioPortal(cod: number) {
    if (cod > 0) {
      try {
        const resposta = await api.get(`/usuario/indicadorCrm/${cod}`);
        if (
          resposta.data &&
          resposta.data.length > 0 &&
          !Number.isNaN(resposta.data?.[0]?.id)
        ) {
          const filtro = registros.filter(
            regi => regi.usuarioId === resposta.data[0]?.id,
          );
          setRegistrosFiltrados(filtro);
          setCodigoUsuarioDomSge(Number(resposta.data[0]?.id));
        }
      } catch (error) {
        toast.dismiss();
        throw error;
      }
    }
  }

  async function getOrcamentoVersao() {
    const response = await api.get(`/orcamento/versao/${configuracaoId}`, {
      params: { completo: true },
    });

    const orc = response.data;
    setCodOportunidade(orc?.orcamento?.codigoOportunidadeDomsge ?? null);

    if (!Number.isNaN(orc?.orcamento?.codigoOportunidadeDomsge)) {
      getUsuarioPortal(Number(codigoOportunidade));
    }
  }

  async function getUsuarios() {
    const response = await api.get(`/usuario`);

    const reg: iUsuario[] = response.data;

    setUsuarios(reg.filter(item => item.id !== usuario?.id && item.isAtivo));
  }

  useEffect(() => {
    getRegistros();
    if (configuracaoId) {
      getOrcamentoVersao();
    }
    getUsuarios();
  }, []);

  async function handleAdicionaUsuario() {
    if (usuarioSelecionado?.id) {
      try {
        setIsSaving(true);
        await api.post(`/orcamento/acesso`, {
          usuarioId: usuarioSelecionado?.id,
          orcamentoId: configuracaoId,
        });

        setUsuarioSelecionado({} as iUsuario);
      } catch (error) {
        toast.dismiss();
      } finally {
        getRegistros();
        if (configuracaoId) {
          getOrcamentoVersao();
        }
        setIsSaving(false);
      }
    }
  }

  async function getUsuarioDomSge() {
    if (!codigoOportunidade) {
      Swal.fire({
        icon: 'warning',
        text: 'Sem GNF Base!',
      });

      return;
    }
    const filtro = registros.filter(
      regi => regi.usuarioId === Number(codigoUsuarioDomSge),
    );

    if (filtro.length > 0) {
      Swal.fire({
        icon: 'warning',
        text: 'Vendedor já possui acesso à configuração!',
      });

      return;
    }

    setIsSaving(true);
    let response = null;
    let resposta = null;
    // eslint-disable-next-line no-useless-catch
    try {
      response = await api.get(`/usuario/indicadorCrm/${codigoOportunidade}`);

      if (!response.data || !response.data[0]) {
        Swal.fire({
          icon: 'warning',
          text: 'Vendedor não possui usuário cadastrado no portal!',
        });

        return;
      }
    } catch (error) {
      toast.dismiss();
      throw error;
    }

    try {
      resposta = await api.get(`/usuario/indicadorCrm/${codigoOportunidade}`);
    } catch (error) {
      toast.dismiss();
      throw error;
    }

    setCodigoUsuarioDomSge(resposta?.data?.[0]?.id ?? null);

    try {
      Swal.fire({
        icon: 'info',
        text: `Aguarde...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      await api.post(`/orcamento/acesso`, {
        usuarioId: response?.data[0].id,
        orcamentoId: configuracaoId,
        isVendedor: true,
        cdEmissor: response?.data[0].codIndicador,
        gnf: codigoOportunidade,
      });
      toast.success('Compartilhado com o vendedor.');
      Swal.close();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
    }

    getRegistros();
    if (configuracaoId) {
      getOrcamentoVersao();
    }
    setIsSaving(false);
  }

  async function handleExcluirUsuario(id: number) {
    if (id) {
      Swal.fire({
        title: `Deseja excluir essa associação? O usuário irá perder o acesso`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          setIsSaving(true);
          await api.delete(`/orcamento/acesso/${id}`, {});

          getRegistros();
          if (configuracaoId) {
            getOrcamentoVersao();
          }
          setIsSaving(false);
          Swal.close();
        }
      });
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={modalState}
      toggle={toggle}
      scrollable
    >
      <CardHeader className="bg-transparent">
        <h3 className="mb-0">
          Compartilhar acesso à configuração {configuracaoId} com:
        </h3>
        <small>
          Os usuários listados tem acesso à configuração. Podendo efetuar
          modificações na mesma.
        </small>
      </CardHeader>
      <ModalBody style={{ height: '800px' }}>
        <Row>
          <Col sm="8">
            <InputGroup>
              <UncontrolledDropdown>
                <DropdownToggle caret>
                  {usuarioSelecionado.nome ?? 'Usuário'}
                </DropdownToggle>
                <DropdownMenu>
                  <div style={{ height: 400, overflowY: 'scroll' }}>
                    <DropdownItem disabled>Usuário</DropdownItem>
                    <DropdownItem divider />
                    {usuarios
                      .filter(el => {
                        return !registros.find(element => {
                          return element.usuarioId === el.id;
                        });
                      })
                      .map(user => {
                        return (
                          <DropdownItem
                            key={user.id}
                            value={user.id}
                            onClick={() => setUsuarioSelecionado(user)}
                          >
                            {user.nome}
                          </DropdownItem>
                        );
                      })}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button
                style={{ maxHeight: '60px' }}
                color="primary"
                disabled={isSaving}
                onClick={handleAdicionaUsuario}
              >
                <FaPlusCircle />
              </Button>
            </InputGroup>
          </Col>
          <Col sm="4">
            <Button
              style={{ maxHeight: '60px' }}
              color="primary"
              onClick={getUsuarioDomSge}
              disabled={isSaving}
            >
              Compartilhar Vendedor
            </Button>
          </Col>
        </Row>

        <Table bordered responsive className="mt-5">
          {registros.map(acesso => {
            return (
              <tr key={acesso.id}>
                <td>{acesso.usuario.nome}</td>
                <td>
                  <Button
                    className="btn-icon btn-2 float-right"
                    size="sm"
                    color="danger"
                    type="button"
                    title="Excluir"
                    disabled={isSaving}
                    onClick={() => {
                      handleExcluirUsuario(acesso.id);
                    }}
                  >
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            );
          })}
        </Table>
      </ModalBody>
    </Modal>
  );
}
