import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import GrupoUsuarios from 'models/GrupoUsuarios';
import { useState } from 'react';
import api from 'services/api';

interface Props {
  disabled?: boolean;
  limitarPorConfiguracao?: boolean | false;
  grupoUsuariosSelecionado: GrupoUsuarios | null;
  onChange: (event: any, grupoUsuarios: GrupoUsuarios | null) => void;
}

function AutocompleteGrupoUsuarios({
  disabled,
  grupoUsuariosSelecionado,
  onChange,
}: Props): JSX.Element {
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([] as GrupoUsuarios[]);
  const [open, setOpen] = useState(false);

  function getLabel(option: GrupoUsuarios) {
    const id = option?.id;
    const name = option?.nome;

    if (id) {
      return `${id} - ${name ?? ''}`;
    }

    return `${name ?? ''}`;

    return '';
  }

  useDebouncedEffect(
    () => {
      let active = true;

      if (!open || loadingClientes) {
        return undefined;
      }

      (async () => {
        setLoadingClientes(true);

        const response = await api.get(
          '/integracao/grupo-atendimento/grupo-usuario',
          {
            params: { search: inputValue },
          },
        );

        setLoadingClientes(false);

        if (active) {
          setOptions(response.data);
        }
      })();

      return () => {
        active = false;
      };
    },
    1000,
    [inputValue, open],
  );

  return (
    <Autocomplete
      disabled={disabled}
      size="small"
      open={open}
      options={options}
      loading={loadingClientes}
      inputValue={inputValue}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option: GrupoUsuarios, value) =>
        option && option.id === value.id
      }
      getOptionLabel={getLabel}
      loadingText="Carregando..."
      noOptionsText="Nada encontrado"
      value={grupoUsuariosSelecionado}
      onChange={onChange}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          label="Grupo de usuários*"
          variant="outlined"
          InputLabelProps={{ style: { fontSize: 13 } }}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 12 },
            endAdornment: (
              <>
                {loadingClientes ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default AutocompleteGrupoUsuarios;
