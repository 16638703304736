import { FaTools } from 'react-icons/fa';

const Maintenance: React.FC = () => {
  return (
    <>
      <div className="text-center" style={{ padding: '10%' }}>
        <FaTools size={70} />

        <h1 style={{ marginTop: 10 }}>Sistema em Manutenção</h1>
        <p>
          Desculpe-nos pelo transtorno. Estamos atualizando o sistema. Voltamos
          em breve.
        </p>
      </div>
    </>
  );
};

export default Maintenance;
