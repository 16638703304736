import formatCurrency from 'utils/formatCurrency';

export default function Valor({
  label,
  valor,
  noFormat,
  unit,
  isDolar,
}: {
  label: string;
  valor: number | null;
  noFormat?: boolean;
  unit?: string;
  isDolar?: boolean;
}): JSX.Element {
  return (
    <tr style={{ backgroundColor: '#B0C4DE' }}>
      <td className="p-1">
        <h3>{label}:</h3>
      </td>
      <td className="p-1">
        <h3>
          <strong>
            {noFormat ? valor : formatCurrency(valor, 2, isDolar)} {unit}
          </strong>
        </h3>
      </td>
    </tr>
  );
}
