export default function chunkArray(items: any[], size: number): any[] {
  const chunks = [];
  // eslint-disable-next-line no-param-reassign
  items = [].concat(...items);

  while (items.length) {
    chunks.push(items.splice(0, size));
  }

  return chunks;
}
