import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import api from 'services/api';

import FlangeModel from '../../../models/ConfiguradorFlange';

const ConfiguradorFlanges: React.FC = () => {
  const [flanges, setFlanges] = useState([] as FlangeModel[]);
  // eslint-disable-next-line prettier/prettier
  const [FlangeEditing, setFlangeEditing] = useState({} as FlangeModel);

  const [formModalState, setFormModalState] = useState(false);

  async function listaFlanges() {
    const response = await api.get('/integracao/flanges');

    setFlanges(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaFlanges();
  }, []);

  async function handleDelete(flangeId: number) {
    await api.delete(`/integracao/flanges/${flangeId}`);

    listaFlanges();
  }

  async function handleInsert() {
    setFlangeEditing({} as FlangeModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(flange: FlangeModel) {
    setFlangeEditing(flange);
    setFormModalState(!formModalState);
  }

  async function handleSaveFlange() {
    // eslint-disable-next-line prettier/prettier
    const { nome } = FlangeEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (FlangeEditing.id) {
      await api.put(`/integracao/flanges/${FlangeEditing.id}`, {
        nome,
      });
    } else {
      await api.post(`/integracao/flanges`, {
        nome,
      });
    }

    setFormModalState(false);
    setFlangeEditing({} as FlangeModel);
    listaFlanges();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Flange {FlangeEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>nome da Flange *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="nome do Flange"
                            value={FlangeEditing.nome}
                            type="text"
                            onChange={text =>
                              setFlangeEditing({
                                ...FlangeEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveFlange}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Flanges</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  {flanges.map(flange => (
                    <tr key={flange.id}>
                      <td className="col">
                        <strong>{flange.nome}</strong>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(flange);
                          }}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(flange.id);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorFlanges;
