import FichaTecnica from 'models/FichaTecnica';
import Orcamento from 'models/Orcamento';

import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

import Quadro from '../../../../atoms/Quadro';
import { Container, Button } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
  orcamentoParteId: number;
}

function TabelasDetalhes(props: Props): JSX.Element {
  const { fichaTecnica, orcamento, orcamentoParteId } = props;

  return (
    <Card className="shadow mt-2">
      <Quadro noBorder>
        <Container>
          <Link
            style={{ width: '100%' }}
            to={{
              pathname: `/admin/tabela-velocidade`,
              state: {
                fichaTecnica,
                orcamento,
              },
            }}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Tabela Velocidade</span>
            </Button>
          </Link>

          <Link
            style={{ width: '100%' }}
            to={{
              pathname: `/admin/tabela-cambagem`,
              state: {
                fichaTecnica,
                orcamento,
              },
            }}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Tabela Cambagem</span>
            </Button>
          </Link>

          <Link
            style={{ width: '100%' }}
            to={{
              pathname: `/admin/configuracao-eletrica`,
              state: {
                fichaTecnica,
              },
            }}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Configuração Elétrica</span>
            </Button>
          </Link>

          <Link
            style={{ width: '100%' }}
            to={{
              pathname: '/admin/adutora-bombeamento',
              state: {
                fichaTecnica,
                orcamentoId: orcamento.id,
                orcamentoParteId,
              },
            }}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Adutora e bombeamento</span>
            </Button>
          </Link>

          <Button>FK CAD</Button>
        </Container>
      </Quadro>
    </Card>
  );
}

export default TabelasDetalhes;
