import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import Pessoa from 'models/Pessoa';
import { useState } from 'react';
import api from 'services/api';

interface Props {
  disabled?: boolean;
  data: { id: number; descricao: string }[];
  valorSelecionado: number | string | null;
  label: string | null;
  hideSequence?: boolean | false;
  onChange: (event: any, valor: number | null) => void;
}

export default function AutocompleteLocalData({
  data,
  disabled,
  valorSelecionado,
  hideSequence,
  onChange,
  label,
}: Props): JSX.Element {
  const [loadingData, setLoadingData] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([] as any[]);
  const [open, setOpen] = useState(false);

  function getLabel(option: any) {
    const id = option?.id;
    const name = option?.descricao;

    if (id && !hideSequence) {
      return `${id} - ${name ?? ''}`;
    }

    return `${name ?? ''}`;
  }

  useDebouncedEffect(
    () => {
      let active = true;

      if (!open || loadingData) {
        return undefined;
      }

      (async () => {
        setLoadingData(true);

        const dadosFiltrados = data;

        setLoadingData(false);

        if (active) {
          setOptions(dadosFiltrados);
        }
      })();

      return () => {
        active = false;
      };
    },
    1000,
    [inputValue, open],
  );

  const styleDisabled = disabled ? { backgroundColor: '#f2f2f2' } : {};
  return (
    <Autocomplete
      className="mt-3"
      disabled={disabled}
      size="small"
      open={open}
      options={options}
      loading={loadingData}
      inputValue={inputValue}
      style={styleDisabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option: any, value) => {
        return option && option.id === value;
      }}
      getOptionLabel={getLabel}
      loadingText="Carregando..."
      noOptionsText="Nada encontrado"
      value={data?.find(item => item.id === valorSelecionado) ?? null}
      onChange={onChange}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          label={`${label}`}
          variant="outlined"
          InputLabelProps={{ style: { fontSize: 13 } }}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 12 },
            endAdornment: (
              <>
                {loadingData ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
