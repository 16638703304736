import { CircularProgress } from '@material-ui/core';
import Log from 'models/Log';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CardHeader, Badge, Modal, ModalBody, Row } from 'reactstrap';
import api from 'services/api';

interface ModalLog {
  nomeChave: string;
  valorChave: number | string;
  modalState: boolean;
  toggle: () => void;
  tipo?: string[];
  titulo?: string;
}

export default function ModalLog({
  nomeChave,
  valorChave,
  modalState,
  toggle,
  tipo,
  titulo,
}: ModalLog): JSX.Element {
  const [logs, setLogs] = useState<Log[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  async function loadLogs() {
    setIsLoading(true);
    const response = await api.get('/log', {
      params: {
        nomeChave,
        valorChave,
        tipo,
      },
    });

    setLogs(response.data as Log[]);
    setIsLoading(false);
  }

  useEffect(() => {
    if (modalState === true) {
      loadLogs();
    }
  }, [modalState]);

  function getBgColor(classe: string | null): string {
    if (!classe) {
      return 'default';
    }

    const handleBgColor = {
      update: 'info',
      delete: 'danger',
    };

    return (handleBgColor as any)[classe];
  }

  function StatusLog({ log }: { log: Log }): JSX.Element {
    const dataGeracao = new Intl.DateTimeFormat('pt-BR', {
      dateStyle: 'long',
      timeStyle: 'medium',
    }).format(new Date(log.dataGeracao));
    const bgColor = getBgColor(log.tipo.classe);
    return (
      <div
        className="timeline timeline-one-side"
        data-timeline-axis-style="dashed"
        data-timeline-content="axis"
      >
        <div className="timeline-block">
          <span className={`timeline-step badge-${bgColor}`}>
            <i className="ni ni-bell-55" />
          </span>
          <div className="timeline-content my-2">
            <Badge color={bgColor} pill className="p-2 px-4">
              {log.usuario?.nome ?? 'Sem usuário'}
            </Badge>
            <Row>
              <small className="font-weight-bold ml-3 mt-3">
                {dataGeracao}
              </small>
            </Row>
            <p className="text-sm my-2">{log.descricao}</p>
          </div>
        </div>
      </div>
    );
  }

  function StatusLogs(): JSX.Element {
    if (isLoading) {
      return (
        <div className="text-center">
          <CircularProgress className="mt-2 ml-2" color="inherit" size={20} />
        </div>
      );
    }

    if (logs.length) {
      return (
        <>
          {logs.map(log => (
            <StatusLog key={log.id} log={log} />
          ))}
        </>
      );
    }

    return (
      <div className="text-center">
        <small className="text-muted font-weight-bold">
          Nenhum log registrado
        </small>
      </div>
    );
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={modalState}
      toggle={toggle}
      scrollable
    >
      <CardHeader className="bg-transparent">
        <h3 className="mb-0">{titulo || 'Logs registrados'}</h3>
      </CardHeader>
      <ModalBody>
        <StatusLogs />
      </ModalBody>
    </Modal>
  );
}
