import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Col,
  CardBody,
  Form,
  Row,
  Input,
  Label,
  Button,
  Table,
  CardHeader,
  FormGroup,
} from 'reactstrap';

import { CircularProgress } from '@material-ui/core';
import api from 'services/api';
import { PropostaVersaoServico } from 'models/PropostaVersaoServico';
import { PropostaContext } from 'views/pages/Proposta';
import InputSelect from 'components/InputSelect';
import OrcamentoCalculoServicoLogs from 'models/OrcamentoCalculoServicoLogs';
import Swal from 'sweetalert2';

interface IMaoDeObra {
  onMaoDeObraAtualizada: Dispatch<React.SetStateAction<boolean>>;
  orcamentoId: number;
  configuracoes: string;
}

function MaoDeObra({
  onMaoDeObraAtualizada,
  orcamentoId,
  configuracoes,
}: IMaoDeObra): JSX.Element {
  const {
    propostaVersao,
    acessoLimitado,
    formatoMaoDeObra,
    setFormatoMaoDeObra,
    carregaPropostaVersao,
    atualizarTelaProposta,
  } = useContext(PropostaContext);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [updatingMaoObra, setUpdatingMaoObra] = useState(false);

  const [maoObraSupervisao, setMaoObraSupervisao] = useState('');
  const [maoObraAuxiliar, setMaoObraAuxiliar] = useState('');
  const [alimentacao, setAlimentacao] = useState('');
  const [estadia, setEstadia] = useState('');
  const [munck, setMunck] = useState('');
  const [retroescavadeira, setRetroescavadeira] = useState('');
  const [civil, setCivil] = useState('');
  const [descargaMaterial, setDescargaMaterial] = useState('');
  const [deslocamentoCidadeObra, setDeslocamentoCidadeObra] = useState('');
  const [km, setKm] = useState(0);
  const [numerosAuxiliares, SetNumerosAuxiliares] = useState(0);

  const formatoMaoDeObraOptions = [
    { value: 'P', name: 'Percentual' },
    { value: 'C', name: 'Calculada' },
  ];

  async function carregaMaoObra() {
    try {
      setCarregandoDados(true);
      const response = await api.get(
        `/proposta/versao/${propostaVersao.id}/servicos`,
      );
      const data = response.data as PropostaVersaoServico;
      setMaoObraSupervisao(data.maoObraSup || '');
      setMaoObraAuxiliar(data.maoObraAux || '');
      setAlimentacao(data.alimentacao || '');
      setEstadia(data.estadia || '');
      setMunck(data.munck || '');
      setRetroescavadeira(data.retroescavadeira || '');
      setCivil(data.civil || '');
      setKm(data.km ?? 0);
      setDescargaMaterial(data.descargaMaterial || '');
      setDeslocamentoCidadeObra(data.deslocamento || '');
      SetNumerosAuxiliares(data.numerosAuxiliares ?? 0);
      setFormatoMaoDeObra(data.formatoValor ?? 'P');
    } finally {
      setCarregandoDados(false);
    }
  }

  useEffect(() => {
    carregaMaoObra();
  }, [propostaVersao]);

  async function gravaLogsCalculo(sessaoUsar: string): Promise<boolean> {
    const retorno = await api.post('/integracao/calculo-servico-logs', {
      sessao: sessaoUsar,
      orcamentoId,
      configuracoes,
      propostaVersaoId: propostaVersao.id,
    });

    const aprovacao = retorno.data.necessidaAprovacao ?? false;

    if (aprovacao) {
      const valor = await Swal.fire({
        title: `Atenção`,
        html: `O cálculo da Mão de Obra encontrou valores inferiores ao mínimo parametrizado no sistema. Foi disparado um processo de aprovação para o grupo responsável.
        A proposta ficará bloqueada até que seja aprovada.`,
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: `Ok`,
      });
    }

    return aprovacao;
  }

  async function handleUpdateMaoObra() {
    let temAprovacao = false;
    try {
      setUpdatingMaoObra(true);
      const retorno = await api.put(
        `/proposta/versao/${propostaVersao.id}/servicos`,
        {
          maoObraSup: maoObraSupervisao,
          maoObraAux: maoObraAuxiliar,
          alimentacao,
          estadia,
          munck,
          retroescavadeira,
          civil,
          km,
          descargaMaterial,
          deslocamento: deslocamentoCidadeObra,
          formatoValor: formatoMaoDeObra,
          numerosAuxiliares,
        },
      );
      onMaoDeObraAtualizada(true);
      temAprovacao = await gravaLogsCalculo(retorno.data.sessao);
    } finally {
      await atualizarTelaProposta(temAprovacao);
      setUpdatingMaoObra(false);
    }
  }

  function CampoSimNao({
    label,
    value,
    bloquear,
    onChange,
  }: {
    label: string;
    value: string;
    bloquear: boolean | false;
    onChange: Dispatch<SetStateAction<string>>;
  }): JSX.Element {
    return (
      <Row className="mb-3">
        <Col sm="6" xs="12">
          <Label className="form-control-label mt-3 float-right">{label}</Label>
        </Col>
        <Col sm="6" xs="12">
          <Input
            className="form-control-alternative"
            type="select"
            value={value}
            disabled={bloquear}
            onChange={text => onChange(text.target.value)}
          >
            <option value="">Selecione...</option>
            <option value="S">Sim</option>
            <option value="N">Não</option>
          </Input>
        </Col>
      </Row>
    );
  }

  return (
    <Card className="bg-secondary shadow mt-1 mb-1">
      <CardBody>
        <Form>
          <Row className="ml-0">
            <h6 className="heading-small text-muted mb-1">Mão de obra</h6>
            {carregandoDados && (
              <CircularProgress
                className="mt-2 ml-2"
                color="inherit"
                size={10}
              />
            )}
          </Row>
          <hr className="mt-2" />

          <Row className="mb-3">
            <Col sm="2" xs="12">
              <Label className="form-control-label mt-3">Formato</Label>
            </Col>
            <Col sm="4" xs="12">
              <InputSelect
                options={formatoMaoDeObraOptions}
                value={formatoMaoDeObra}
                disabled={acessoLimitado}
                onChange={text => setFormatoMaoDeObra(text.target.value)}
              />
            </Col>
          </Row>

          <hr className="mt-2" />

          {formatoMaoDeObra === 'C' && (
            <div className="mb-5">
              <CampoSimNao
                label="Mão de obra supervisão (Montador/Técnica)"
                value={maoObraSupervisao}
                onChange={setMaoObraSupervisao}
                bloquear={acessoLimitado}
              />
              <CampoSimNao
                label="Mão de obra auxiliar"
                value={maoObraAuxiliar}
                onChange={setMaoObraAuxiliar}
                bloquear={acessoLimitado}
              />
              <Row
                className="mb-3"
                hidden={String(maoObraAuxiliar).toUpperCase() !== 'S'}
              >
                <Col sm="6" xs="12">
                  <Label className="form-control-label mt-3 float-right">
                    <span className="form-control-label float-right">
                      Nº Auxiliares Extras:
                    </span>

                    <p>
                      <span className="form-control-label float-right">
                        <small>Cálculo já usa 4 auxiliares como padrão</small>
                      </span>
                    </p>
                  </Label>
                </Col>
                <Col sm="6" xs="12">
                  <Input
                    className="form-control-alternative mt-2"
                    type="number"
                    disabled={String(maoObraAuxiliar).toUpperCase() !== 'S'}
                    value={numerosAuxiliares}
                    onChange={text =>
                      SetNumerosAuxiliares(Number(text.target.value))
                    }
                  />
                </Col>
              </Row>
              <CampoSimNao
                label="Alimentação"
                value={alimentacao}
                onChange={setAlimentacao}
                bloquear={acessoLimitado}
              />
              <CampoSimNao
                label="Estadia"
                value={estadia}
                onChange={setEstadia}
                bloquear={acessoLimitado}
              />
              <CampoSimNao
                label="Munck"
                value={munck}
                onChange={setMunck}
                bloquear={acessoLimitado}
              />
              <CampoSimNao
                label="Retroescavadeira"
                value={retroescavadeira}
                onChange={setRetroescavadeira}
                bloquear={acessoLimitado}
              />
              <CampoSimNao
                label="Descarga de material"
                value={descargaMaterial}
                onChange={setDescargaMaterial}
                bloquear={acessoLimitado}
              />
              <CampoSimNao
                label="Civil"
                value={civil}
                onChange={setCivil}
                bloquear={acessoLimitado}
              />
              <CampoSimNao
                label="Deslocamento cidade/obra"
                value={deslocamentoCidadeObra}
                onChange={setDeslocamentoCidadeObra}
                bloquear={acessoLimitado}
              />

              <Row
                className="mb-3"
                hidden={
                  String(deslocamentoCidadeObra).toUpperCase() !== 'S' &&
                  String(munck).toUpperCase() !== 'S'
                }
              >
                <Col sm="6" xs="12">
                  <Label className="form-control-label mt-3 float-right">
                    Km deslocamento
                  </Label>
                </Col>
                <Col sm="6" xs="12">
                  <Input
                    className="form-control-alternative"
                    type="number"
                    disabled={
                      acessoLimitado ||
                      (String(deslocamentoCidadeObra).toUpperCase() !== 'S' &&
                        String(munck).toUpperCase() !== 'S')
                    }
                    value={km}
                    onChange={text => setKm(Number(text.target.value))}
                  />
                </Col>
              </Row>
            </div>
          )}

          <Row>
            <Col sm="9" />
            <Col sm="3">
              <Button
                className="float-right"
                color="default"
                disabled={updatingMaoObra}
                onClick={handleUpdateMaoObra}
              >
                {updatingMaoObra
                  ? 'Salvando e recalculando proposta...'
                  : 'Salvar'}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default memo(MaoDeObra);
