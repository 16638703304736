import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap';

interface Props {
  label: string;
  value?: number;
  onChange?: (inputValue: number) => void;
  disabled?: boolean;
}

export default function InputNumber({
  label,
  value,
  onChange,
  disabled,
}: Props): JSX.Element {
  return (
    <Row>
      <Col xs="6" md="7">
        <Label className="form-control-label float-right mt-3">{label}</Label>
      </Col>
      <Col xs="6" md="5">
        <InputGroup className="input-group-alternative">
          <Input
            disabled={disabled}
            type="number"
            min={0}
            step={1}
            value={value}
            onChange={text =>
              onChange ? onChange(Number(text.target.value)) : undefined
            }
          />
        </InputGroup>
      </Col>
    </Row>
  );
}
