import FichaTecnica from 'models/FichaTecnica';
import { Card, Col } from 'reactstrap';

import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import Quadro from 'views/pages/OrcamentoResultado/components/atoms/Quadro';

interface Props {
  fichaTecnica: FichaTecnica;
}

function ATTSElevadorTensao(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const { tensaoTrifasica, tensaoATTSElevador, potenciaATTSElevador } =
    fichaTecnica;

  const TITULO = 'ATTS ELEVADOR DE TENSÃO';

  const campos = [
    { label: 'Tensão Primária', valor: tensaoTrifasica, unidade: 'V' },
    {
      label: 'Tensão Secundária',
      valor: tensaoATTSElevador,
      unidade: 'V',
    },
    { label: 'Potência', valor: potenciaATTSElevador, unidade: 'kVA' },
  ];

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Col className="mt-2">
          {campos.map(campo => (
            <Campo
              sm="12"
              key={campo.label}
              number
              label={campo.label}
              valor={campo.valor}
              unidade={campo.unidade}
            />
          ))}
        </Col>
      </Quadro>
    </Card>
  );
}

export default ATTSElevadorTensao;
