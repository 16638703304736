import UserSmallHeader from 'components/Headers/UserSmallHeader';
import { useEffect, useState } from 'react';
import { FaPlusCircle, FaCheckCircle, FaTrash } from 'react-icons/fa';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Input,
  InputGroup,
  Row,
  Table,
} from 'reactstrap';
import api from 'services/api';
import { useQuery } from 'react-query';
import { CircularProgress } from '@material-ui/core';
import { ContainerButtons, Th, Td } from './styles';
import ProdutoCategoriaModel from '../../../models/ProdutoCategoria';

interface IProdutoCategoria extends ProdutoCategoriaModel {
  novo?: boolean;
}

export default function ProdutoCategoria(): JSX.Element {
  const [produtoCategorias, setProdutoCategorias] = useState<
    IProdutoCategoria[]
  >([]);
  const [atualizando, setAtualizando] = useState(false);

  const { data: queryProdutoCategoria, isLoading } = useQuery(
    `produto-categoria`,
    async () => {
      async function getData(): Promise<ProdutoCategoriaModel[]> {
        const response = await api.get('/produto-categoria');
        return response.data;
      }

      const dataProdutoCategoria = await getData();
      return dataProdutoCategoria;
    },
  );

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (queryProdutoCategoria) {
      setProdutoCategorias(queryProdutoCategoria);
    }
  }, [queryProdutoCategoria]);

  function handleAdicionaNovaLinha() {
    const existeNovoNaoSalvo = produtoCategorias.some(
      produtoCategoria => produtoCategoria.novo,
    );
    if (existeNovoNaoSalvo) {
      return;
    }
    setProdutoCategorias([
      ...produtoCategorias,
      {
        novo: true,
        id: 0,
        descricao: '',
      },
    ]);
  }

  function handleChangeValue(
    produtoCategoriaId: number,
    name: string,
    value: number | string,
  ) {
    const novoProdutoCategorias = produtoCategorias.map(produtoCategoria =>
      produtoCategoria.id === produtoCategoriaId
        ? {
            ...produtoCategoria,
            [name]: value,
          }
        : produtoCategoria,
    );
    setProdutoCategorias(novoProdutoCategorias);
  }

  async function handleUpdate(produtoCategoriaInput: IProdutoCategoria) {
    const { id, descricao } = produtoCategoriaInput;
    const adicionando = id === 0;

    async function insert(): Promise<IProdutoCategoria> {
      const response = await api.post(`/produto-categoria`, {
        descricao,
      });
      return response.data;
    }

    async function update() {
      await api.put(`/produto-categoria/${id}`, {
        descricao,
      });
    }

    try {
      setAtualizando(true);
      if (adicionando) {
        const novo = await insert();
        const novoProdutoCategorias = produtoCategorias.map(produtoCategoria =>
          produtoCategoria.id === 0 ? novo : produtoCategoria,
        );
        setProdutoCategorias(novoProdutoCategorias);
      } else {
        await update();
      }
    } finally {
      setAtualizando(false);
    }
  }

  async function handleDelete(produtoCategoriaInput: IProdutoCategoria) {
    const novoPropostaVersaoParcelas = produtoCategorias.filter(
      produtoCategoria => produtoCategoria.id !== produtoCategoriaInput.id,
    );
    setProdutoCategorias(novoPropostaVersaoParcelas);
    if (produtoCategoriaInput.novo) {
      return;
    }
    const { id } = produtoCategoriaInput;
    await api.delete(`/produto-categoria/${id}`);
  }

  return (
    <>
      <UserSmallHeader />
      <Card>
        <CardHeader>
          <strong className="text-muted">Categorias de produtos</strong>
          <ContainerButtons>
            <Button
              className="btn btn-outline-default mb-2"
              type="button"
              size="sm"
              onClick={handleAdicionaNovaLinha}
            >
              Novo <FaPlusCircle />
            </Button>
          </ContainerButtons>
          {isLoading ? (
            <Row>
              <Col className="text-center">
                <CircularProgress />
              </Col>
            </Row>
          ) : (
            <Table responsive bordered>
              <thead>
                <tr>
                  <Th>#</Th>
                  <Th>Descrição</Th>
                  <Th />
                </tr>
              </thead>
              <tbody>
                {produtoCategorias.map(produtoCategoria => {
                  const { id, descricao } = produtoCategoria;
                  return (
                    <tr key={id}>
                      <Td style={{ width: '5%' }}>
                        <Input disabled value={id} />
                      </Td>
                      <Td>
                        <Input
                          type="text"
                          value={descricao}
                          onChange={event =>
                            handleChangeValue(
                              id,
                              'descricao',
                              event.target.value,
                            )
                          }
                        />
                      </Td>
                      <Td style={{ width: '10%' }}>
                        <InputGroup className="mt-2 pl-2 ">
                          <Button
                            disabled={atualizando || !descricao}
                            outline
                            className="btn-icon"
                            color="primary"
                            type="button"
                            size="sm"
                            onClick={() => handleUpdate(produtoCategoria)}
                          >
                            <FaCheckCircle />
                          </Button>
                          <Button
                            disabled={atualizando}
                            outline
                            className="btn-icon btn-2 ml-1"
                            color="danger"
                            type="button"
                            size="sm"
                            onClick={() => handleDelete(produtoCategoria)}
                          >
                            <FaTrash />
                          </Button>
                        </InputGroup>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </CardHeader>
      </Card>
    </>
  );
}
