import React, { useState, useEffect } from 'react';
import { Button, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import { TextField } from '@material-ui/core';

import 'antd/dist/antd.css';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import api from 'services/api';
import { FaCheckCircle, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Select } from 'antd';

import toast from 'react-hot-toast';
import { RowFilter, GrupoRow, SelecaoRelacaoContainer } from './styles';
// eslint-disable-next-line import/extensions
import NiveisIndicadoresAtaModel from '../../../../models/NiveisIndicadoresAta';
import TiposIndicadoresModel from '../../../../models/TiposIndicadores';

const NiveisIndicadoresAta: React.FC = () => {
  const [niveisIndicadoresAta, setNiveisIndicadoresAta] = useState<
    NiveisIndicadoresAtaModel[]
  >([]);
  const [tiposIndicadores, setTiposIndicadores] = useState<
    TiposIndicadoresModel[]
  >([]);
  const [tiposIndicadoresFiltrados, setTiposIndicadoresFiltrados] = useState<
    TiposIndicadoresModel[]
  >([]);
  const [filtroIndicador, setFiltroIndicador] = useState<any[]>([]);
  const [idsTiposIndicadores, setIdsTiposIndicadores] = useState<number[]>();
  const criandoItem = niveisIndicadoresAta.some(
    nivelIndicador => nivelIndicador.id === 0,
  );

  const { Option } = Select;
  const idsArray: any[] = [];
  const idsIndicadores: number[] = [];
  const idsUsar: any[] = [];

  async function carregaNiveisIndicadoresAta() {
    const response = await api.get(`/niveis-indicadores-ata`);
    const NiveisIndicadoresAtaResponse =
      response.data as NiveisIndicadoresAtaModel[];
    setNiveisIndicadoresAta(NiveisIndicadoresAtaResponse);
    // eslint-disable-next-line array-callback-return
    NiveisIndicadoresAtaResponse.map(item => {
      idsUsar.push([item.indicadoresAssociadosIds]);
    });
    setFiltroIndicador(idsUsar);
  }

  async function carregaTiposIndicadoresAta() {
    const response = await api.get(`/niveis-indicadores-ata/tipos-indicadores`);
    const tiposIndicadoresAtaResponse =
      response.data as TiposIndicadoresModel[];
    setTiposIndicadores(tiposIndicadoresAtaResponse);
    const nivel1 = niveisIndicadoresAta.find(
      (indic: any) => indic.descricaoNivel === 'Nível 1',
    )?.indicadoresAssociadosIds;

    const nivel2 = niveisIndicadoresAta.find(
      (indic: any) => indic.descricaoNivel === 'Nível 2',
    )?.indicadoresAssociadosIds;

    const nivel3 = niveisIndicadoresAta.find(
      (indic: any) => indic.descricaoNivel === 'Nível 3',
    )?.indicadoresAssociadosIds;

    const questoesAssociadas = tiposIndicadoresAtaResponse.filter(
      questao =>
        !nivel1?.includes(questao.codigo) &&
        !nivel2?.includes(questao.codigo) &&
        !nivel3?.includes(questao.codigo),
    );
    setTiposIndicadoresFiltrados(questoesAssociadas);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaNiveisIndicadoresAta();
  }, []);

  useEffect(() => {
    if (filtroIndicador) carregaTiposIndicadoresAta();
  }, [filtroIndicador]);

  function handleSetCadDetalheValue(
    grupoUsuarios: NiveisIndicadoresAtaModel,
    attrName: string,
    value: string | number | boolean | null,
  ) {
    setNiveisIndicadoresAta(
      niveisIndicadoresAta.map(grupoUsuarios_ =>
        grupoUsuarios_.id === grupoUsuarios.id
          ? { ...grupoUsuarios_, [attrName]: value, alterado: true }
          : grupoUsuarios_,
      ),
    );
  }

  // eslint-disable-next-line array-callback-return
  tiposIndicadores.map(item => {
    idsArray.push({ label: item.descricao, value: item.codigo });
  });

  // eslint-disable-next-line array-callback-return
  tiposIndicadores.map(item => {
    idsIndicadores.push(item.codigo);
  });

  async function handleUpdate(grupoUsuarios: NiveisIndicadoresAtaModel) {
    const { id, descricaoNivel, indicadoresAssociadosIds } = grupoUsuarios;
    await api.put(`/niveis-indicadores-ata/${id}`, {
      id,
      descricaoNivel,
      indicadoresAssociadosIds,
    });
  }

  async function handleCreate(grupoUsuarios: NiveisIndicadoresAtaModel) {
    const { descricaoNivel, indicadoresAssociadosIds } = grupoUsuarios;
    await api.post('/niveis-indicadores-ata', {
      descricaoNivel,
      indicadoresAssociadosIds,
    });
  }

  function validaFormulario(grupoUsuarios: NiveisIndicadoresAtaModel) {
    const { id, descricaoNivel, indicadoresAssociadosIds } = grupoUsuarios;
    if (!descricaoNivel || !indicadoresAssociadosIds) {
      return false;
    }
    return true;
  }

  async function handleSave(idNivel: number, desc: string) {
    if (!idsTiposIndicadores?.length) {
      toast.error('É necessário adicionar pelo menos um tipo de indicador');
      return;
    }
    try {
      await api.put(`/niveis-indicadores-ata/${idNivel}`, {
        descricaoNivel: String(desc),
        indicadoresAssociadosIds: idsTiposIndicadores,
      });

      await carregaNiveisIndicadoresAta();
      toast.success('Feito!', {});
    } catch (error) {
      toast.dismiss();
      toast.error('Ops! Algo de errado aconteceu!');
    }
  }

  async function handleDelete(grupoUsuariosId: number) {
    const newNiveisIndicadoresAta = niveisIndicadoresAta.filter(
      (grupoUsuarios: any) => grupoUsuarios.id !== grupoUsuariosId,
    );

    setNiveisIndicadoresAta(newNiveisIndicadoresAta);

    if (grupoUsuariosId !== 0) {
      await api.delete(`/niveis-indicadores-ata/${grupoUsuariosId}`);
    }
  }

  function questionaExclusao(grupoUsuarios: NiveisIndicadoresAtaModel) {
    Swal.fire({
      title: `Excluir registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, excluir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(result => {
      if (result.isConfirmed) {
        handleDelete(grupoUsuarios.id);
      }
    });
  }

  function createQuestaoRelacao(questao: TiposIndicadoresModel) {
    return {
      id: questao.codigo,
      descricao: String(questao.descricao),
    };
  }

  async function handleHabilitaEdicao(idNivel: number, desc: string) {
    setNiveisIndicadoresAta(
      niveisIndicadoresAta.map(grupoUsuarios_ =>
        grupoUsuarios_.id === idNivel
          ? { ...grupoUsuarios_, alterado: true }
          : grupoUsuarios_,
      ),
    );
  }

  function handleQuestaoAssociadaSelected(questoesId: number[]) {
    const idsFiltrados = tiposIndicadores.filter(
      (indic: any) => indic.codigo === questoesId,
    );
    setIdsTiposIndicadores(questoesId);

    // eslint-disable-next-line array-callback-return
    idsFiltrados.map(item => {
      idsIndicadores.push(item.codigo);
    });
  }

  return (
    <>
      <UserSmallHeader />

      <Container
        className="mt-4"
        fluid
        style={{ backgroundColor: '#fff', border: '1px solid #ebe9e9' }}
      >
        <Col>
          {niveisIndicadoresAta.map(nivelIndicador => {
            const { id, descricaoNivel, indicadoresAssociadosIds } =
              nivelIndicador;

            return (
              <GrupoRow
                key={id}
                onSubmit={() =>
                  handleSave(nivelIndicador.id, nivelIndicador.descricaoNivel)
                }
              >
                <Col sm="12">
                  <Row>
                    <Col sm="3">
                      <TextField
                        inputProps={{ style: { fontSize: 14 } }}
                        style={{ width: '100%' }}
                        size="small"
                        variant="outlined"
                        label="Nível *"
                        type="text"
                        disabled
                        value={
                          descricaoNivel === 'Nível 1'
                            ? `${descricaoNivel} - Recebe cópia da ata`
                            : `${descricaoNivel} - Não recebe cópia da ata`
                        }
                        onChange={text =>
                          handleSetCadDetalheValue(
                            nivelIndicador,
                            'descricaoNivel',
                            text.target.value,
                          )
                        }
                      />
                    </Col>

                    <Col>
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Selecione os indicadores"
                        onChange={handleQuestaoAssociadaSelected}
                        onSelect={() =>
                          handleHabilitaEdicao(
                            nivelIndicador.id,
                            nivelIndicador.descricaoNivel,
                          )
                        }
                        onDeselect={() =>
                          handleHabilitaEdicao(
                            nivelIndicador.id,
                            nivelIndicador.descricaoNivel,
                          )
                        }
                        optionLabelProp="label"
                        defaultValue={indicadoresAssociadosIds}
                      >
                        {tiposIndicadoresFiltrados.map(tipoIndicador => (
                          <Option
                            key={`${String(tipoIndicador.codigo)}`}
                            value={tipoIndicador.codigo}
                            label={tipoIndicador.descricao}
                          >
                            {tipoIndicador.descricao}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <div className="mt-2 float-right">
                      {nivelIndicador.alterado && (
                        <Button
                          size="sm"
                          className="btn-icon btn-2"
                          color="primary"
                          type="button"
                          onClick={() =>
                            handleSave(
                              nivelIndicador.id,
                              nivelIndicador.descricaoNivel,
                            )
                          }
                        >
                          <FaCheckCircle />
                        </Button>
                      )}
                    </div>
                  </Row>
                </Col>
              </GrupoRow>
            );
          })}
        </Col>
      </Container>
    </>
  );
};

export default NiveisIndicadoresAta;
