import UserSmallHeader from 'components/Headers/UserSmallHeader';
import FichaTecnica from 'models/FichaTecnica';
import FichaTecnicaLancePerdaCarga from 'models/FichaTecnicaLancePerdaCarga';
import FichaTecnicaLogs from 'models/FichaTecnicaLogs';
import Orcamento from 'models/Orcamento';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TabPanel from 'components/TabPanel';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  NavLink,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  Table,
  TabContent,
  TabPane,
} from 'reactstrap';

import api from 'services/api';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';

import {
  AppBar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@material-ui/core';
import triggerValidaRegras from 'services/triggerValidaRegras';
import { FichaTecnicaPerdaCargaItem } from 'models/FichaTecnicaPerdaCargaItem';
import { FichaTecnicaPerdaCargaResumo } from 'models/FichaTecnicaPerdaCargaResumo';
import Detalhes from './components/Detalhes';

import { LabelOrcamento } from './styles';

interface LocationState {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
  acessoLimitadoPerdaCarga: ValidarAcessoPrograma;
}

interface PerdaCargaTotais {
  totalPerdaSistema?: number | null;
  percentualPerdaSistema?: number | null;
  mcaTotal?: number | null;
}

function PerdaCarga(): JSX.Element {
  const location = useLocation<LocationState>();
  const fichaTecnica = location.state?.fichaTecnica;
  const orcamento = location.state?.orcamento;
  const acessoLimitado = location.state?.acessoLimitadoPerdaCarga;
  const { orcamentoVersaoId, id, incluirSistemaPistaSeca } = fichaTecnica;
  const [loadingPistaSeca, setLoadingPistaSeca] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [currentTab, setCurrentTab] = useState('aerea');

  const usaPistaSeca: boolean =
    String(incluirSistemaPistaSeca).toUpperCase() === 'SIM' ||
    String(incluirSistemaPistaSeca).toUpperCase() === 'S';

  const [perdaCarga, setPerdaCarga] = useState(
    [] as FichaTecnicaLancePerdaCarga[],
  );

  const [perdaCargaBomba, setPerdaCargaBomba] = useState(
    [] as FichaTecnicaPerdaCargaItem[],
  );

  const [perdaCargaBombaResumo, setPerdaCargaBombaResumo] = useState(
    [] as FichaTecnicaPerdaCargaResumo[],
  );

  const [perdaCargaTotais, setPerdaCargaTotais] = useState(
    {} as PerdaCargaTotais,
  );

  const [logs, setLogs] = useState([] as FichaTecnicaLogs[]);

  async function listaPistaSeca() {
    setLoadingPistaSeca(true);
    const response = await api.get(`/ficha-tecnica/${id}/pista-seca`);
    setPerdaCarga(response.data);
    setLoadingPistaSeca(false);
  }

  async function listaLogs() {
    setLoadingLogs(true);
    const response = await api.get(`/integracao/ficha-tecnica-logs/${id}`);
    setLogs(response.data);
    setLoadingLogs(false);
  }

  async function listaPerdaCargaBomba() {
    const response = await api.get(`/ficha-tecnica/${id}/perda-carga-bomba`);

    setPerdaCargaTotais(response.data.totais as PerdaCargaTotais);
    setPerdaCargaBomba(response.data.materiais);
    setPerdaCargaBombaResumo(response.data.resumo);
  }

  useEffect(() => {
    if (usaPistaSeca === true) listaPistaSeca();
  }, [usaPistaSeca]);

  useEffect(() => {
    listaLogs();
    listaPerdaCargaBomba();
  }, []);

  async function atualizarMateriais() {
    await triggerValidaRegras(
      {
        fichaTecnicaId: id,
        atualizarMaterial: true,
        validarAgrupador: true,
        orcamentoVersaoId,
        origem: 'Opcoes',
        orcamento,
      },
      true,
    );
  }

  async function handleSalvarPistaSeca() {
    Swal.fire({
      title: `Deseja salvar essas informações e gerar a lista de materiais?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setLoadingPistaSeca(true);
          await api.put(`/ficha-tecnica/${id}/pista-seca`, {
            perdaCarga,
          });

          await atualizarMateriais();

          await listaPistaSeca();
          toast.success('Concluído com sucesso!', { position: 'top-right' });
          Swal.close();
        } finally {
          setLoadingPistaSeca(false);
        }
      }
    });
  }

  async function changePistaSecaOption(idItem: number, valorItem: string) {
    const valoresAtualizados = perdaCarga.map(
      (item: FichaTecnicaLancePerdaCarga) => {
        if (item.id === idItem) {
          return {
            ...item,
            tipoPistaSeca: valorItem ?? '',
          };
        }

        return item;
      },
    );

    setPerdaCarga(valoresAtualizados);
  }

  return (
    <>
      <UserSmallHeader />
      <Container fluid>
        <Row className="mt-2 ml-1">
          <LabelOrcamento>
            Configuração {orcamentoVersaoId} - Tabela de Perda de Carga e Logs
          </LabelOrcamento>
        </Row>

        <Row>
          <Col sm="12">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
              style={{ width: '100%' }}
            >
              <NavItem key="parte_header_aerea">
                <NavLink
                  aria-selected={currentTab === 'aerea'}
                  className={`mb-sm-3 mb-md-0 ${
                    currentTab === 'aerea' ? 'active' : ''
                  }
              `}
                  onClick={() => {
                    if (currentTab !== 'aerea') {
                      setCurrentTab('aerea');
                    }
                  }}
                  href="#"
                  role="tab"
                >
                  Parte Áerea
                </NavLink>
              </NavItem>
              <NavItem key="parte_header_sistema">
                <NavLink
                  aria-selected={currentTab === 'sistema'}
                  className={`mb-sm-3 mb-md-0 ${
                    currentTab === 'sistema' ? 'active' : ''
                  }
              `}
                  onClick={() => {
                    if (currentTab !== 'sistema') {
                      setCurrentTab('sistema');
                    }
                  }}
                  href="#"
                  role="tab"
                >
                  Sistema
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>

        <TabContent activeTab={`tabs${currentTab}`}>
          <TabPane tabId="tabsaerea">
            <Card className="shadow mt-2 " hidden={!usaPistaSeca}>
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">
                  Pista Seca
                  <Button
                    disabled={
                      loadingPistaSeca || acessoLimitado.acessoLimitado === true
                    }
                    className="btn-icon btn-2 float-right"
                    color="primary"
                    type="button"
                    onClick={handleSalvarPistaSeca}
                    size="sm"
                  >
                    {loadingPistaSeca
                      ? 'Salvando informações...'
                      : 'Salvar informações e gerar materiais'}
                  </Button>
                </h3>
              </CardHeader>
              <CardBody className="p-1">
                <Row className="justify-content-md-center">
                  <Col sm="8" className="mt-2 ml-2 mb-2 mr--2">
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Torre</strong>
                          </th>
                          <th>
                            <strong>Lance</strong>
                          </th>
                          <th>
                            <strong title="Total Saídas Simples">
                              Qtde. Simples
                            </strong>
                          </th>
                          <th>
                            <strong title="Total Saídas Duplas">
                              Qtde. Duplas
                            </strong>
                          </th>
                          <th style={{ width: '20%' }}>
                            <strong>Vazão Pista Seca</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {perdaCarga.map((item: FichaTecnicaLancePerdaCarga) => {
                          return (
                            <tr key={item.id}>
                              <td>Torre {item.numeroLance}</td>
                              <td>{`${item.siglaLance ?? ''} ${
                                item.siglaTubo ?? ''
                              }`}</td>
                              <td>
                                {item.tipoPistaSeca &&
                                item.tipoPistaSeca !== 'null'
                                  ? item.totalSimples
                                  : 0}
                              </td>
                              <td>
                                {item.tipoPistaSeca &&
                                item.tipoPistaSeca !== 'null'
                                  ? item.totalDuplo
                                  : 0}
                              </td>
                              <td className="p-0 pl-2 pr-2">
                                <FormControl fullWidth>
                                  <Select
                                    value={
                                      item.tipoPistaSeca &&
                                      item.tipoPistaSeca !== 'null'
                                        ? item.tipoPistaSeca
                                        : 'null'
                                    }
                                    onChange={event =>
                                      changePistaSecaOption(
                                        item.id,
                                        event.target.value as string,
                                      )
                                    }
                                  >
                                    <MenuItem value="null">N/A</MenuItem>
                                    <MenuItem
                                      value={item.tipoPistaSecaCalculado ?? ''}
                                    >
                                      {item.tipoPistaSecaCalculado ?? ''}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={2}>
                            <strong className="float-right">Total</strong>
                          </td>
                          <td>
                            <strong>
                              {perdaCarga.reduce(
                                (
                                  total: any,
                                  item: FichaTecnicaLancePerdaCarga,
                                ) =>
                                  total +
                                  (item.tipoPistaSeca &&
                                  item.tipoPistaSeca !== 'null'
                                    ? item.totalSimples
                                    : 0),
                                0,
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {perdaCarga.reduce(
                                (
                                  total: any,
                                  item: FichaTecnicaLancePerdaCarga,
                                ) =>
                                  total +
                                  (item.tipoPistaSeca &&
                                  item.tipoPistaSeca !== 'null'
                                    ? item.totalDuplo
                                    : 0),
                                0,
                              )}
                            </strong>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow mt-1" hidden>
              <Detalhes fichaTecnica={fichaTecnica} />
            </Card>
            <Card className="shadow mt-2 ">
              <CardBody className="p-1">
                <Row>
                  <Col>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Número Vão</strong>
                          </th>
                          <th>
                            <strong>Diâmetro</strong>
                          </th>
                          <th>
                            <strong>Distância centro</strong>
                          </th>
                          <th>
                            <strong>Vazão saída</strong>
                          </th>
                          <th>
                            <strong>Vazão trecho</strong>
                          </th>
                          <th>
                            <strong>Veloc. água</strong>
                          </th>
                          <th>
                            <strong>Perda carga</strong>
                          </th>
                          <th>
                            <strong>Aspersor duplo</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs.map((item: FichaTecnicaLogs) => {
                          return (
                            <tr key={item.id}>
                              <td className="p-0 pl-2 pr-2">
                                {item.nroLances}
                              </td>
                              <td className="p-0 pl-2 pr-2">{item.diametro}</td>
                              <td className="p-0 pl-2 pr-2">
                                {item.distanciaCentro}
                              </td>
                              <td className="p-0 pl-2 pr-2">
                                {item.vazaoSaida}
                              </td>
                              <td className="p-0 pl-2 pr-2">
                                {item.vazaoTrecho}
                              </td>
                              <td className="p-0 pl-2 pr-2">
                                {item.velocAgua}
                              </td>
                              <td className="p-0 pl-2 pr-2">
                                {item.perdaCarga}
                              </td>
                              <td className="p-0 pl-2 pr-2">
                                {item.aspersorDuplo}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </TabPane>

          <TabPane tabId="tabssistema">
            <Card className="shadow mt-2 ">
              <CardBody className="p-1">
                <Row>
                  <Col>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Grupo</strong>
                          </th>
                          <th>
                            <strong>Nome</strong>
                          </th>
                          <th>
                            <strong>
                              Perdas Gerais{' '}
                              <span
                                style={{
                                  textTransform: 'lowercase',
                                }}
                              >
                                (mca)
                              </span>
                            </strong>
                          </th>
                          <th>
                            <strong>
                              Perdas Específicas{' '}
                              <span
                                style={{
                                  textTransform: 'lowercase',
                                }}
                              >
                                (mca)
                              </span>
                            </strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {perdaCargaBombaResumo.map(
                          (item: FichaTecnicaPerdaCargaResumo) => {
                            return (
                              <tr key={item.id}>
                                <td className="p-0 pl-2 pr-2">{item.grupo}</td>
                                <td className="p-0 pl-2 pr-2">{item.nome}</td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.perdasGerais}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.perdasEspecificas}
                                </td>
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={2} className="p-0 pl-2 pr-2 text-right">
                            <strong>Subtotais:</strong>
                          </td>
                          <td className="p-0 pl-2 pr-2">
                            {perdaCargaBombaResumo?.reduce(function (
                              accumulator,
                              object,
                            ) {
                              return accumulator + (object?.perdasGerais ?? 0);
                            },
                            0)}
                          </td>
                          <td className="p-0 pl-2 pr-2">
                            {perdaCargaBombaResumo?.reduce(function (
                              accumulator,
                              object,
                            ) {
                              return (
                                accumulator + (object?.perdasEspecificas ?? 0)
                              );
                            },
                            0)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="p-0 pl-2 pr-2 text-right">
                            <strong>Perdas (Adutora + Aérea):</strong>
                          </td>
                          <td className="p-0 pl-2 pr-2">
                            {perdaCargaTotais.mcaTotal ?? 0} mca
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="p-0 pl-2 pr-2 text-right">
                            <strong>Perdas Específicas Sistema:</strong>
                          </td>
                          <td className="p-0 pl-2 pr-2">
                            {perdaCargaTotais.totalPerdaSistema ?? 0} mca
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="p-0 pl-2 pr-2 text-right">
                            <strong>Percentual Perdas Específicas:</strong>
                          </td>
                          <td className="p-0 pl-2 pr-2">
                            {perdaCargaTotais.percentualPerdaSistema ?? 0}%
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Código</strong>
                          </th>
                          <th>
                            <strong>Item</strong>
                          </th>
                          <th>
                            <strong>Agrupador</strong>
                          </th>
                          <th>
                            <strong>Agrupador Fator K</strong>
                          </th>
                          <th>
                            <strong>Item Fator K</strong>
                          </th>
                          <th>
                            <strong>Item Fator KV</strong>
                          </th>
                          <th>
                            <strong>
                              DI{' '}
                              <span
                                style={{
                                  textTransform: 'lowercase',
                                }}
                              >
                                (mm)
                              </span>
                            </strong>
                          </th>
                          <th>
                            <strong>Grupo</strong>
                          </th>
                          <th>
                            <strong>Quantidade</strong>
                          </th>
                          <th>
                            <strong>
                              Vazão{' '}
                              <span
                                style={{
                                  textTransform: 'lowercase',
                                }}
                              >
                                (m³/h)
                              </span>
                            </strong>
                          </th>
                          <th>
                            <strong>
                              Velocidade Item{' '}
                              <span
                                style={{
                                  textTransform: 'lowercase',
                                }}
                              >
                                (m/s)
                              </span>
                            </strong>
                          </th>
                          <th>
                            <strong>
                              Velocidade{' '}
                              <span
                                style={{
                                  textTransform: 'lowercase',
                                }}
                              >
                                (m/s)
                              </span>
                            </strong>
                          </th>
                          <th>
                            <strong>Fator K</strong>
                          </th>
                          <th>
                            <strong>Perda K</strong>
                          </th>
                          <th>
                            <strong>Perda KV</strong>
                          </th>
                          <th>
                            <strong>
                              Total Perda{' '}
                              <span
                                style={{
                                  textTransform: 'lowercase',
                                }}
                              >
                                (mca)
                              </span>
                            </strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {perdaCargaBomba.map(
                          (item: FichaTecnicaPerdaCargaItem) => {
                            return (
                              <tr key={item.id}>
                                <td className="p-0 pl-2 pr-2">
                                  {item.codigoItem}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.descricao}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.agrupador}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.fatorKAgrupador}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.fatorKItem}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.fatorKvItem}
                                </td>
                                <td className="p-0 pl-2 pr-2">{item.di}</td>
                                <td className="p-0 pl-2 pr-2">{item.grupo}</td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.quantidade}
                                </td>
                                <td className="p-0 pl-2 pr-2">{item.vazao}</td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.velocidadeItem}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.velocidade}
                                </td>
                                <td className="p-0 pl-2 pr-2">{item.fatorK}</td>
                                <td className="p-0 pl-2 pr-2">{item.perdaK}</td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.perdaKv}
                                </td>
                                <td className="p-0 pl-2 pr-2">
                                  {item.totalPerda}
                                </td>
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
}

export default PerdaCarga;
