import { MenuItem, Select } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Card,
  Col,
  CardBody,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  CardHeader,
  Table,
  Alert,
} from 'reactstrap';
import api from 'services/api';
import { PropostaContext } from 'views/pages/Proposta';
import { MateriaisContext } from '../Materiais';

interface iParam {
  propostaVersaoId: number;
  tipo: string;
  carregarFinalidade?: boolean | false;
}

export default function ImportarExcel({
  propostaVersaoId,
  tipo,
  carregarFinalidade,
}: iParam): JSX.Element {
  const { setPrecosDesatualizados } = useContext(PropostaContext);

  const { triggerUpdateMaterial } = useContext(MateriaisContext);

  const [formModalState, setFormModalState] = useState(false);
  const [modalConfirmacaoState, setModalConfirmacaoState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [fileToSend, setFileToSend] = useState('');
  const [finalidade, setFinalidade] = useState('');

  const [sessao, setSessao] = useState('');
  const [dadosCarregados, setDadosCarregados] = useState([] as any[]);

  async function carregarDados() {
    setIsSaving(true);
    try {
      await api.post(`/proposta/versao/${propostaVersaoId}/importar-dados`, {
        sessao,
        tipo,
        finalidade,
      });

      toast.success(`Itens importados com sucesso`);
      setSessao('');
      setModalConfirmacaoState(false);
      setDadosCarregados([]);
      triggerUpdateMaterial();
    } catch (error: any) {
      const mensagem = `Não foi possível prosseguir com a carga dos dados. ${error?.response?.data?.message}`;

      throw mensagem;
    } finally {
      setIsSaving(false);
    }
  }

  async function uploadFile() {
    if (!fileToSend) {
      toast.error(`Selecione um arquivo`);
      return;
    }

    if (carregarFinalidade && !['M', 'R', 'E'].includes(finalidade)) {
      toast.error(`A finalidade é obrigatória`);
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', fileToSend);
      formData.append('tipo', tipo);

      const retorno = await api.post(
        `/upload/xlsx?propostaVersaoId=${propostaVersaoId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (retorno.data.registros && retorno.data.registros.length) {
        setDadosCarregados(retorno.data.registros);
        setSessao(retorno.data.sessao);

        // Desativa essa modal e abre a outra
        setIsLoading(false);
        setFormModalState(false);
        setModalConfirmacaoState(true);
      } else {
        toast.error(`O processamento não retornou nenhum item`);
      }
    } catch (error: any) {
      const mensagem = `Não foi possível prosseguir com o upload do arquivo. ${error?.response?.data?.message}`;

      throw mensagem;
    } finally {
      setFileToSend('');

      const fileInput = document.getElementById(
        'fileUpload',
      ) as HTMLInputElement;

      if (fileInput) {
        fileInput.value = '';
      }

      setIsLoading(false);
    }
  }

  const readUploadFile = async (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      setFileToSend(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (isLoading) {
      toast.loading('Carregando arquivo....', { position: 'top-right' });
    } else {
      toast.dismiss();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSaving) {
      toast.loading('Realizando Carga dos Dados', { position: 'top-right' });
    } else {
      toast.dismiss();
    }
  }, [isSaving]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader>
              <div className="text-center text-muted">{tipo}</div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Row hidden={!carregarFinalidade}>
                <Col sm="12">
                  <strong>Finalidade *: </strong>
                  <Select
                    label="Finalidade"
                    value={finalidade ?? ''}
                    onChange={text => {
                      setFinalidade(String(text.target.value));
                    }}
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    <MenuItem value="M">Modernização</MenuItem>
                    <MenuItem value="R">Reposição</MenuItem>
                    <MenuItem value="E">Equipamento Especial</MenuItem>
                  </Select>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="12">
                  <label htmlFor="fileUpload">
                    <strong>Selecione o arquivo: </strong>
                  </label>
                  <input
                    type="file"
                    name="fileUpload"
                    id="fileUpload"
                    onChange={readUploadFile}
                  />
                  <br />
                  <small className="mt-2">
                    Selecione um arquivo no formato .xlsx ou .xslm. Ao clicar em{' '}
                    <i>Carregar Arquivo</i>, o sistema fará um processamento e
                    mostrará uma prévia dos dados a serem carregados.{' '}
                  </small>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="mt-4">
                  <Button
                    color="primary"
                    onClick={uploadFile}
                    disabled={isLoading}
                  >
                    Carregar Arquivo
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        size="xl"
        isOpen={modalConfirmacaoState}
        toggle={() => setModalConfirmacaoState(!modalConfirmacaoState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader>
              <div className="text-center text-muted">{tipo}</div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Row>
                <Col sm="12">
                  <small className="mt-2">
                    Ao clicar em <i>Confirmar Carga dos Dados</i> os itens
                    abaixo serão carregados na proposta. Se houver alguma
                    desconformidade, clique em <i>Cancelar</i>.
                  </small>

                  <Alert
                    color="warning"
                    hidden={
                      !dadosCarregados.find(
                        (item: any) => item.itemEncontrado === false,
                      )
                    }
                  >
                    <strong>Atenção!</strong> Os itens em vermelho não foram
                    encontrados no SGE. Verifique se os códigos estão corretos e
                    se estão ativos no sistema. Por conta disso, a importação
                    não será possível.
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col
                  sm="12"
                  className=" mt-3"
                  style={{ height: '300px', overflowY: 'scroll' }}
                >
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Código</th>
                        <th>Descrição</th>
                        <th>Quantidade</th>
                        <th>Atividade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dadosCarregados.map((item: any) => {
                        const erroItem = !item.itemEncontrado ?? false;

                        const style = erroItem
                          ? { backgroundColor: '#f55044', color: '#FFFFFF' }
                          : {};

                        return (
                          <tr key={item.id} style={{ ...style }}>
                            <td className="py-1">{item.codigo}</td>
                            <td className="py-1">{item.descricao}</td>
                            <td className="py-1">{item.quantidade}</td>
                            <td className="py-1">{item.atividade}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col sm="3" className="mt-4">
                  <Button
                    color="default"
                    onClick={() => {
                      setSessao('');
                      setModalConfirmacaoState(false);
                      setDadosCarregados([]);
                    }}
                    disabled={isSaving}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm="6" className="mt-4">
                  <Button
                    color="primary"
                    onClick={carregarDados}
                    disabled={
                      isSaving ||
                      dadosCarregados.find(
                        (item: any) => item.itemEncontrado === false,
                      )
                    }
                    hidden={
                      isSaving ||
                      dadosCarregados.find(
                        (item: any) => item.itemEncontrado === false,
                      )
                    }
                  >
                    Confirmar Carga dos Dados
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>

      <Button
        className="btn-icon btn-2 mr-1 m-3 "
        color="info"
        size="sm"
        onClick={() => setFormModalState(true)}
      >
        Adicionar {tipo}
      </Button>
    </>
  );
}
