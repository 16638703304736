/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuth } from 'hooks/auth';
import toast from 'react-hot-toast';
import { Icon } from '@material-ui/core';
import { Button } from 'reactstrap';

interface modalParams {
  configuracaoId: number;
  link: string;
}

const Socket: React.FC = () => {
  const { usuario } = useAuth();
  const socket = io(`${process.env.REACT_APP_URL_API}`);

  async function mostrarToast(id: number, link: string) {
    toast(
      t => (
        <div>
          <Button
            color="danger"
            type="button"
            outline
            size="sm"
            className="float-right"
            onClick={() => toast.dismiss(t.id)}
          >
            x
          </Button>

          <a href={link} target="_blank">
            Configuração #{id} Calculada. Clique para acessar
          </a>
        </div>
      ),
      {
        icon: <Icon />,
        position: 'top-right',
        duration: 50000,
      },
    );
  }

  useEffect(() => {
    if (socket) {
      socket.emit('userConnected', usuario.id);

      socket.on(
        'CONFIGURACAO_CALCULO_FINALIZADO',
        (message: { id: number; link: string }) => {
          mostrarToast(message.id, message.link);
        },
      );
    }
  }, []);

  return <></>;
};

export default Socket;
