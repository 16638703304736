import FiltroProps from './models/filtro.props';

export function getParamsFormatados(
  filtro: FiltroProps,
  dataInicio: any,
  dataFim: any,
) {
  const cliente =
    filtro.cliente !== '' && filtro.cliente !== 'T' && filtro.cliente !== '0'
      ? String(filtro.cliente)
      : 'T';
  return `dataInicio=${dataInicio}&dataFim=${dataFim}&status=${filtro.status}&cliente=${cliente}&produto=${filtro.produto}&periodo=${filtro.periodo}&usuario=${filtro.usuario}`;
}
