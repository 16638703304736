import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap';

import { FaExclamationTriangle } from 'react-icons/fa';

interface Props {
  label: string;
  value?: number;
  onChange?: (inputValue: number) => void;
  disabled?: boolean;
  hide?: boolean;
  isSaveRequired?: boolean;
}

export default function InputMoeda({
  label,
  value,
  onChange,
  disabled,
  hide,
  isSaveRequired,
}: Props): JSX.Element {
  return (
    <Row hidden={hide}>
      <Col xs="6" md="7">
        <Label className="form-control-label float-right mt-3">
          {label}&nbsp;
          <span
            hidden={!isSaveRequired}
            title="É necessário salvar para que o valor do campo passa ser válido"
          >
            <FaExclamationTriangle color="red" />
          </span>
        </Label>
      </Col>
      <Col xs="6" md="5">
        <InputGroup className="input-group-alternative">
          <Input
            disabled={disabled}
            type="number"
            min={0}
            step={0.01}
            value={value}
            onChange={text =>
              onChange ? onChange(Number(text.target.value)) : undefined
            }
          />
          <InputGroupAddon addonType="append">R$</InputGroupAddon>
        </InputGroup>
      </Col>
    </Row>
  );
}
