import formatCurrency from 'utils/formatCurrency';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { Td } from './styles';

export default function MaterialTotalItem(props: {
  texto: string;
  valor: number;
  elementType?: keyof JSX.IntrinsicElements;
  acessoLimitadoListaMateriais?: boolean;
}): JSX.Element {
  const {
    texto,
    valor,
    elementType: ElementType = 'h3',
    acessoLimitadoListaMateriais,
  } = props;
  let numeroColunas = 3;

  if (acessoLimitadoListaMateriais) {
    numeroColunas = 1;
  }
  return (
    <tr>
      <Td colSpan={numeroColunas} />
      <Td className="text-right">
        <ElementType>{texto}</ElementType>
      </Td>
      <Td className="text-right">
        <ElementType>{formatCurrency(valor)}</ElementType>
      </Td>
    </tr>
  );
}
