export interface iTipoCampoTabelaGenerica {
  sigla: string;
  nome: string;
  habilitaOpcoes: boolean;
  habilitaMascara: boolean;
  habilitaCamposNumericos: boolean;
}

const TipoCampoTabelaGenerica: iTipoCampoTabelaGenerica[] = [
  {
    sigla: 'T',
    nome: 'Texto',
    habilitaOpcoes: false,
    habilitaMascara: false,
    habilitaCamposNumericos: false,
  },
  {
    sigla: 'N',
    nome: 'Número',
    habilitaOpcoes: false,
    habilitaMascara: true,
    habilitaCamposNumericos: true,
  },
  {
    sigla: 'D',
    nome: 'Data',
    habilitaOpcoes: false,
    habilitaMascara: true,
    habilitaCamposNumericos: false,
  },
  {
    sigla: 'H',
    nome: 'Hora',
    habilitaOpcoes: false,
    habilitaMascara: true,
    habilitaCamposNumericos: false,
  },
  {
    sigla: 'DH',
    nome: 'Data e Hora',
    habilitaOpcoes: false,
    habilitaMascara: true,
    habilitaCamposNumericos: false,
  },
  {
    sigla: 'S',
    nome: 'Seleção',
    habilitaOpcoes: true,
    habilitaMascara: false,
    habilitaCamposNumericos: false,
  },
];

export default TipoCampoTabelaGenerica;
