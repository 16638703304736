import UpdateDeleteInputGroup from 'components/UpdateDeleteInputGroup';
import Material from 'models/Material';
import { Badge, Input } from 'reactstrap';
import formatCurrency from 'utils/formatCurrency';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { useEffect } from 'react';
import { Descricao, Td, Tr } from './styles';

interface PropostaMaterialItem {
  id: number;
  quantidade: number;
  valor: number | null;
  valorTotal: number | null;
  atividade: number | null;
  criadoManualmente?: boolean | false;
  material: Material;
}

export default function MaterialItem({
  propostaMaterial,
  onClickUpdate,
  onClickDelete,
  disabled,
  handleChangeValue,
  canEdit,
  canDelete,
  acessoLimitadoListaMateriais,
  acessoLimitado,
}: {
  propostaMaterial: PropostaMaterialItem;
  onClickUpdate?: () => void;
  onClickDelete?: () => void;
  disabled?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  handleChangeValue?: (
    id: number,
    name: string,
    value: number | string,
  ) => void;
  acessoLimitadoListaMateriais: boolean;
  acessoLimitado?: boolean;
}): JSX.Element {
  const {
    id,
    atividade,
    material,
    quantidade,
    valor,
    valorTotal,
    criadoManualmente,
  } = propostaMaterial;
  const valorMoeda = formatCurrency(valor);
  const valorMoedaTotal = formatCurrency(valorTotal);

  return (
    <Tr semPreco={valor === 0}>
      <Td>{atividade}</Td>
      <Td>
        <Descricao>
          {material.diteCodigo} - {material.descricao}
          {criadoManualmente !== undefined && criadoManualmente === true ? (
            <>
              <span title="Material adicionado manualmente na configuração">
                <Badge color="success" className="ml-2">
                  M
                </Badge>
              </span>
            </>
          ) : (
            ''
          )}
        </Descricao>
      </Td>
      <Td className="text-center">
        {canEdit ? (
          <Input
            key={id}
            className="mt--1 mb-2"
            bsSize="sm"
            type="number"
            min={1}
            value={String(quantidade)}
            onChange={event =>
              handleChangeValue
                ? handleChangeValue(
                    id,
                    'quantidade',
                    parseInt(event.target.value, 10),
                  )
                : undefined
            }
          />
        ) : (
          quantidade
        )}
      </Td>
      <Td className="text-right" hidden={acessoLimitadoListaMateriais}>
        {valorMoeda}
      </Td>
      <Td className="text-right" hidden={acessoLimitadoListaMateriais}>
        {valorMoedaTotal}
      </Td>
      {!acessoLimitadoListaMateriais && (
        <Td>
          <UpdateDeleteInputGroup
            className="mr--3"
            disabled={disabled}
            canEdit={canEdit}
            canDelete={canDelete}
            onClickUpdate={onClickUpdate}
            onClickDelete={onClickDelete}
          />
        </Td>
      )}
    </Tr>
  );
}
