import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';

import { FaPlusCircle } from 'react-icons/fa';

import api from 'services/api';

import { useQuery } from 'react-query';
import ProdutoCategoria from 'models/ProdutoCategoria';
import toast from 'react-hot-toast';
import ProdutoModel from '../../../models/Produto';
import ListaProdutos from './components/ListaProdutos';

const Produtos: React.FC = () => {
  const [nome, setNome] = useState('');
  const [codigoEmpresa, setCodigoEmpresa] = useState('');
  const [produtoCategoriaId, setProdutoCategoriaId] = useState<number>();

  const [produtos, setProdutos] = useState([] as ProdutoModel[]);
  const [produtoCategorias, setProdutoCategorias] = useState<
    ProdutoCategoria[]
  >([]);

  const { data: queryProdutoCategoria } = useQuery(
    `produto-categoria`,
    async () => {
      async function getData(): Promise<ProdutoCategoria[]> {
        const response = await api.get('/produto-categoria');
        return response.data;
      }

      const dataProdutoCategoria = await getData();
      return dataProdutoCategoria;
    },
  );

  async function carregaProdutos() {
    const response = await api.get('/produto', { params: { getImagem: true } });
    setProdutos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (queryProdutoCategoria) {
      setProdutoCategorias(queryProdutoCategoria);
    }
  }, [queryProdutoCategoria]);

  async function handleAdicionaNovoProduto() {
    if (!nome || !codigoEmpresa || !produtoCategoriaId) {
      toast.error('Todos os campos são obrigatórios!');
      return;
    }
    await api.post(`/produto`, {
      nome,
      codigoEmpresa,
      produtoCategoriaId,
    });
    carregaProdutos();
    setNome('');
    setCodigoEmpresa('');
    setProdutoCategoriaId(undefined);
  }

  function InputSelectProdutoCategoria(): JSX.Element {
    return (
      <Input
        placeholder="Categoria"
        type="select"
        value={produtoCategoriaId || undefined}
        onChange={text => setProdutoCategoriaId(Number(text.target.value))}
      >
        <option value="">Categoria...</option>
        {produtoCategorias.map(produtoCategoria => (
          <option value={produtoCategoria.id}>
            {produtoCategoria.descricao}
          </option>
        ))}
      </Input>
    );
  }

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-tag" />
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  placeholder="Novo produto"
                  type="text"
                  value={nome ?? ''}
                  onChange={text => setNome(text.target.value)}
                />

                <InputGroupAddon addonType="append">
                  <Input
                    placeholder="Código da empresa"
                    type="number"
                    min="0"
                    value={codigoEmpresa ?? ''}
                    onChange={text =>
                      setCodigoEmpresa(String(text.target.value))
                    }
                  />
                </InputGroupAddon>

                <InputGroupAddon addonType="append">
                  <InputSelectProdutoCategoria />
                </InputGroupAddon>

                <Button
                  className="btn-icon btn-2"
                  disabled={!nome || !codigoEmpresa || !produtoCategoriaId}
                  color="primary"
                  type="button"
                  onClick={handleAdicionaNovoProduto}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <ListaProdutos
          produtos={produtos}
          produtoCategorias={produtoCategorias}
          carregaProdutos={carregaProdutos}
        />
      </Container>
    </>
  );
};

export default Produtos;
