import { Row, Card, CardBody, Col } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';

import FichaTecnica from 'models/FichaTecnica';

import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';

import {
  Label,
  Valor,
} from 'views/pages/OrcamentoResultado/components/atoms/Campo/styles';
import CargasEletricas from './components/CargasEletricas';
import DadosDimensionamentoAlimentador from './components/DadosDimensionamentoAlimentador';
import { ColLabel } from './style';

interface Props {
  fichaTecnica: FichaTecnica;
}

function DadosInformadosEletrica(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const {
    pivoCentral,
    modeloPivo,
    declividadeTerrenoIrrigar,
    aspersorFinalModelo,
    fonteEnergiaPivo,
    tensaoTrifasica,
    distanciaFonteEnergia,
    alimentadorEletricoInjecao,
  } = fichaTecnica;

  const campos = [
    {
      label: 'Modelo do Pivô',
      valor: modeloPivo,
    },
    {
      label: 'Declividade terro a irrigar',
      valor: declividadeTerrenoIrrigar,
      unidade: '%',
    },
    {
      label: 'Modelo do aspersor final',
      valor: aspersorFinalModelo,
    },
    {
      label: 'Fonte Energia Pivô',
      valor: fonteEnergiaPivo,
    },
    {
      label: 'Tensão Trifásica',
      valor: tensaoTrifasica,
      unidade: 'V',
    },
    {
      label: 'Distância da Fonte ao centro do Pivô',
      valor: distanciaFonteEnergia,
      unidade: 'm',
    },
  ];

  return (
    <Card className="shadow mt-2">
      <CardBody className="mt--4">
        <Card className="shadow mt-4">
          <Row className="mt--3">
            <ColQuestaoTitulo>
              <LabelQuestaoTitulo>Dados informados</LabelQuestaoTitulo>
            </ColQuestaoTitulo>
          </Row>

          <CardBody className="p-3">
            <Row>
              {/* <Campo sm="12" label="Pivô Central" valor={pivoCentral} /> */}
              <Col sm="12">
                <Row>
                  <ColLabel>
                    <Label>Pivô central: </Label>
                  </ColLabel>
                  <Col sm="10" style={{ maxWidth: '80.5%' }}>
                    <Valor readOnly value={pivoCentral} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              {campos.map(campo => (
                <Campo
                  sm="4"
                  key={campo.label}
                  label={campo.label}
                  valor={campo.valor ?? ''}
                  unidade={campo.unidade}
                />
              ))}

              <Col>
                <Campo
                  sm="12"
                  label="Alimentador elétrico para sistema de injeção de fertilizantes"
                  valor={alimentadorEletricoInjecao}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <CargasEletricas fichaTecnica={fichaTecnica} />
              </Col>

              <Col sm="6">
                <DadosDimensionamentoAlimentador fichaTecnica={fichaTecnica} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
}

export default DadosInformadosEletrica;
