import UserSmallHeader from 'components/Headers/UserSmallHeader';
import FichaTecnica from 'models/FichaTecnica';
import { useLocation } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import AutotransformadoresTransformadores from './components/AutotransformadoresTransformadores';
import CaboAlimentadorDoPivo from './components/CaboAlimentadorDoPivo';
import CabosLancesPivo from './components/CabosLancesPivo';
import DadosInformadosEletrica from './components/DadosInformadosEletrica';
import TabelaTensoes from './components/TabelaTensoes';
import { LabelOrcamento } from './styles';

interface LocationState {
  fichaTecnica: FichaTecnica;
}

function ConfiguracaoEletrica(): JSX.Element {
  const location = useLocation<LocationState>();

  const fichaTecnica = location.state?.fichaTecnica;

  const { orcamentoVersaoId } = fichaTecnica;

  return (
    <>
      <UserSmallHeader />
      <Container fluid>
        <Row className="mt-2">
          <LabelOrcamento>
            Configuração {orcamentoVersaoId} - Configuração elétrica
          </LabelOrcamento>
        </Row>

        <Row>
          <DadosInformadosEletrica fichaTecnica={fichaTecnica} />
          <CaboAlimentadorDoPivo fichaTecnica={fichaTecnica} />
        </Row>

        <Row>
          <AutotransformadoresTransformadores fichaTecnica={fichaTecnica} />
        </Row>

        <Row>
          <CabosLancesPivo fichaTecnica={fichaTecnica} />
        </Row>

        <Row>
          <TabelaTensoes fichaTecnica={fichaTecnica} />
        </Row>
      </Container>
    </>
  );
}

export default ConfiguracaoEletrica;
