export default function formataCnpjCpf(
  numeroDocumento?: string | null,
): string {
  if (!numeroDocumento) return '';
  const isCpf = numeroDocumento?.length === 11;
  const isCnpj = numeroDocumento?.length === 14;
  if (isCnpj) {
    return numeroDocumento
      .replace(/[^\d]/g, '') // retira os caracteres indesejados
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  if (isCpf) {
    return numeroDocumento
      .replace(/[^\d]/g, '') // retira os caracteres indesejados
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  return '';
}
