import { useEffect, useState } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Tooltip,
} from 'reactstrap';

import api from 'services/api';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import Orcamento from 'models/Orcamento';

import { FaArrowCircleRight } from 'react-icons/fa';
import formatCurrency from 'utils/formatCurrency';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import { ColQuestaoTitulo, LabelQuestao, LabelQuestaoTitulo } from './styles';

interface Props {
  configuracaoId: number;
  onSave?: any;
  fotovoltaicaGeral: iModel;
  orcamento: Orcamento;
}

interface iEnergia {
  consumoAbril?: number | null;
  consumoAgosto?: number | null;
  consumoDezembro?: number | null;
  consumoFevereiro?: number | null;
  consumoJaneiro?: number | null;
  consumoJulho?: number | null;
  consumoJunho?: number | null;
  consumoMaio?: number | null;
  consumoMarco?: number | null;
  consumoNovembro?: number | null;
  consumoOutubro?: number | null;
  consumoSetembro?: number | null;
  consumoTotal?: number | null;
}

interface iModel {
  instalacaoEquipamento?: string | null;
  areaAproximada?: number | null;
  custoEnergiaSemUsina?: number | null;
  custoTotalSemUsina?: number | null;
  custoTotalComUsina?: number | null;
  economiaMedia?: number | null;
  economiaUsina?: number | null;
  energiaAno?: number | null;
  fatorAutoConsumo?: number | null;
  fichaTecnicaId?: number | null;
  inflacao?: number | null;
  lcoe?: number | null;
  lucroCaixaTotal?: number | null;
  opex?: number | null;
  potenciaInversorAprox?: number | null;
  potenciaPicoSistema?: number | null;
  potenciaPonderadaGeral?: number | null;
  prazo?: number | null;
  primeiroAnoPositivo?: number | null;
  referenciaCalculada?: number | null;
  taxa?: number | null;
  tir?: number | null;
  vpl?: number | null;
  tipoFinanciamento?: string | null;
  tipoGd?: string | null;
  tipoModulo?: string | null;
  tipoPagamento?: string | null;
  modulo?: string | null;
  moduloOriginal?: string | null;
  valorSistemaOriginal?: number | null;
  valorSistema?: number | null;
  energiaAnoOriginal?: number | null;
  potenciaPicoSistemaOriginal?: number | null;
  potenciaInversorOriginal?: number | null;
  geracao?: iEnergia;
}

export default function FotovoltaicaFinalizacaoOrcamento(
  props: Props,
): JSX.Element {
  const { configuracaoId, onSave, fotovoltaicaGeral, orcamento } = props;

  const [modulo, setModulo] = useState<string>('');
  const [gnf, setGnf] = useState<string>('');
  const [potenciaPico, setPotenciaPico] = useState<number>();
  const [potenciaInversor, setPotenciaInversor] = useState<number>();
  const [energiaGeradaTotal, setEnergiaGeradaTotal] = useState<number>();
  const [energiaGeradaJaneiro, setEnergiaGeradaJaneiro] = useState<number>();
  const [energiaGeradaFevereiro, setEnergiaGeradaFevereiro] =
    useState<number>();
  const [energiaGeradaMarco, setEnergiaGeradaMarco] = useState<number>();
  const [energiaGeradaAbril, setEnergiaGeradaAbril] = useState<number>();
  const [energiaGeradaMaio, setEnergiaGeradaMaio] = useState<number>();
  const [energiaGeradaJunho, setEnergiaGeradaJunho] = useState<number>();
  const [energiaGeradaJulho, setEnergiaGeradaJulho] = useState<number>();
  const [energiaGeradaAgosto, setEnergiaGeradaAgosto] = useState<number>();
  const [energiaGeradaSetembro, setEnergiaGeradaSetembro] = useState<number>();
  const [energiaGeradaOutubro, setEnergiaGeradaOutubro] = useState<number>();
  const [energiaGeradaNovembro, setEnergiaGeradaNovembro] = useState<number>();
  const [energiaGeradaDezembro, setEnergiaGeradaDezembro] = useState<number>();
  const [valor, setValor] = useState<number>();
  const [salvando, setSalvando] = useState(false);
  const [fichaTecnicaId, setFichaTecnicaId] = useState<number>();

  async function infoFotovoltaica() {
    const valores = fotovoltaicaGeral as iModel;

    setModulo(valores.modulo ?? '');
    setGnf(orcamento?.codigoOportunidadeDomsge ?? '');
    setPotenciaPico(valores.potenciaPicoSistema ?? 0);
    setPotenciaInversor(valores.potenciaInversorAprox ?? 0);

    setEnergiaGeradaTotal(valores.geracao?.consumoTotal ?? 0);

    setEnergiaGeradaJaneiro(valores.geracao?.consumoJaneiro ?? 0);
    setEnergiaGeradaFevereiro(valores.geracao?.consumoFevereiro ?? 0);
    setEnergiaGeradaMarco(valores.geracao?.consumoMarco ?? 0);
    setEnergiaGeradaAbril(valores.geracao?.consumoAbril ?? 0);
    setEnergiaGeradaMaio(valores.geracao?.consumoMaio ?? 0);
    setEnergiaGeradaJunho(valores.geracao?.consumoJunho ?? 0);
    setEnergiaGeradaJulho(valores.geracao?.consumoJulho ?? 0);
    setEnergiaGeradaAgosto(valores.geracao?.consumoAgosto ?? 0);
    setEnergiaGeradaSetembro(valores.geracao?.consumoSetembro ?? 0);
    setEnergiaGeradaOutubro(valores.geracao?.consumoOutubro ?? 0);
    setEnergiaGeradaNovembro(valores.geracao?.consumoNovembro ?? 0);
    setEnergiaGeradaDezembro(valores.geracao?.consumoDezembro ?? 0);

    setValor(arredondaDecimais(valores.valorSistema ?? 0, 2));
    setFichaTecnicaId(valores.fichaTecnicaId ?? 0);
  }

  useEffect(() => {
    if (fotovoltaicaGeral) infoFotovoltaica();
  }, [fotovoltaicaGeral]);

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  async function handleSave() {
    if (
      !potenciaInversor ||
      !potenciaPico ||
      !energiaGeradaTotal ||
      !valor ||
      !modulo
    ) {
      toast.error('Alguns campos não foram preenchidos!');
      return;
    }

    try {
      setSalvando(true);
      setSwal('Salvando alterações e executando cálculos...');

      await api.post('/fotovoltaica/valores-finalizar-orcamento', {
        orcamentoId: configuracaoId,
        fichaTecnicaId,
        modulo,
        potenciaInversorAprox: potenciaInversor,
        potenciaPicoSistema: potenciaPico,
        energiaAno: energiaGeradaTotal,
        energiaGeradaJaneiro,
        energiaGeradaFevereiro,
        energiaGeradaMarco,
        energiaGeradaAbril,
        energiaGeradaMaio,
        energiaGeradaJunho,
        energiaGeradaJulho,
        energiaGeradaAgosto,
        energiaGeradaSetembro,
        energiaGeradaOutubro,
        energiaGeradaNovembro,
        energiaGeradaDezembro,
        valorSistema: valor,
      });

      setSwal('');
      toast.success('Registro salvo');

      if (onSave) props.onSave();
    } finally {
      setSalvando(false);
      toast.dismiss();
      setSwal('');
    }
  }

  function handleEnergiaChange(mes: string, valorEnergia: number) {
    let energiaTotal = 0;

    energiaTotal +=
      mes === 'Janeiro' ? valorEnergia : Number(energiaGeradaJaneiro ?? 0);
    energiaTotal +=
      mes === 'Fevereiro' ? valorEnergia : Number(energiaGeradaFevereiro ?? 0);
    energiaTotal +=
      mes === 'Março' ? valorEnergia : Number(energiaGeradaMarco ?? 0);
    energiaTotal +=
      mes === 'Abril' ? valorEnergia : Number(energiaGeradaAbril ?? 0);
    energiaTotal +=
      mes === 'Maio' ? valorEnergia : Number(energiaGeradaMaio ?? 0);
    energiaTotal +=
      mes === 'Junho' ? valorEnergia : Number(energiaGeradaJunho ?? 0);
    energiaTotal +=
      mes === 'Julho' ? valorEnergia : Number(energiaGeradaJulho ?? 0);
    energiaTotal +=
      mes === 'Agosto' ? valorEnergia : Number(energiaGeradaAgosto ?? 0);
    energiaTotal +=
      mes === 'Setembro' ? valorEnergia : Number(energiaGeradaSetembro ?? 0);
    energiaTotal +=
      mes === 'Outubro' ? valorEnergia : Number(energiaGeradaOutubro ?? 0);
    energiaTotal +=
      mes === 'Novembro' ? valorEnergia : Number(energiaGeradaNovembro ?? 0);
    energiaTotal +=
      mes === 'Dezembro' ? valorEnergia : Number(energiaGeradaDezembro ?? 0);

    setEnergiaGeradaTotal(energiaTotal);
  }

  function renderContentMonth(
    label: string,
    handleFunction: any,
    valorEnergia: number,
  ) {
    return (
      <Col sm="4">
        <FormGroup>
          <small>{label}:</small>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={`${label}`}
              value={valorEnergia}
              type="number"
              onChange={text => {
                handleFunction(Number(text.target.value));
                handleEnergiaChange(label, Number(text.target.value));
              }}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText>kWh</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Col>
    );
  }

  return (
    <>
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Edição dos dados</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <small>GNF:</small>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-tag" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="GNF" value={gnf} type="text" disabled />
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <small>Potência Pico Sistema:</small>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Potência Pico Sistema"
                    value={potenciaPico}
                    type="number"
                    onChange={text =>
                      setPotenciaPico(Number(text.target.value))
                    }
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>kWp</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <small>Módulo:</small>
                <Select
                  label="Módulo"
                  value={modulo || ''}
                  onChange={event => {
                    setModulo(String(event.target.value));
                  }}
                  style={{ width: '100%' }}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  <MenuItem value="Nacional">Nacional</MenuItem>
                  <MenuItem value="Importado">Importado</MenuItem>
                </Select>
              </FormGroup>
              <FormGroup className="mb-3">
                <small>Potência Inversor:</small>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Potência Inversor"
                    value={potenciaInversor}
                    type="number"
                    onChange={text =>
                      setPotenciaInversor(Number(text.target.value))
                    }
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>kW</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <small>Valor:</small>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>R$</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Valor"
                    value={valor}
                    type="number"
                    onChange={text => setValor(Number(text.target.value))}
                  />
                </InputGroup>
              </FormGroup>

              <Row className="mb-3">
                <Col sm="12">
                  <strong>
                    Energia Gerada no Ano: {energiaGeradaTotal}
                    kWh/a
                  </strong>
                  <hr className="mt-2" />
                </Col>
                {renderContentMonth(
                  'Janeiro',
                  setEnergiaGeradaJaneiro,
                  Number(energiaGeradaJaneiro),
                )}
                {renderContentMonth(
                  'Fevereiro',
                  setEnergiaGeradaFevereiro,
                  Number(energiaGeradaFevereiro),
                )}
                {renderContentMonth(
                  'Março',
                  setEnergiaGeradaMarco,
                  Number(energiaGeradaMarco),
                )}
                {renderContentMonth(
                  'Abril',
                  setEnergiaGeradaAbril,
                  Number(energiaGeradaAbril),
                )}
                {renderContentMonth(
                  'Maio',
                  setEnergiaGeradaMaio,
                  Number(energiaGeradaMaio),
                )}
                {renderContentMonth(
                  'Junho',
                  setEnergiaGeradaJunho,
                  Number(energiaGeradaJunho),
                )}
                {renderContentMonth(
                  'Julho',
                  setEnergiaGeradaJulho,
                  Number(energiaGeradaJulho),
                )}
                {renderContentMonth(
                  'Agosto',
                  setEnergiaGeradaAgosto,
                  Number(energiaGeradaAgosto),
                )}
                {renderContentMonth(
                  'Setembro',
                  setEnergiaGeradaSetembro,
                  Number(energiaGeradaSetembro),
                )}
                {renderContentMonth(
                  'Outubro',
                  setEnergiaGeradaOutubro,
                  Number(energiaGeradaOutubro),
                )}
                {renderContentMonth(
                  'Novembro',
                  setEnergiaGeradaNovembro,
                  Number(energiaGeradaNovembro),
                )}
                {renderContentMonth(
                  'Dezembro',
                  setEnergiaGeradaDezembro,
                  Number(energiaGeradaDezembro),
                )}
              </Row>

              <div className="text-center">
                <Button
                  size="sm"
                  className="my-4 btn-icon btn-2"
                  color="primary"
                  type="button"
                  disabled={salvando}
                  onClick={handleSave}
                >
                  <FaArrowCircleRight />
                  <span className="btn-inner--text">Salvar Respostas</span>
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
