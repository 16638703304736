import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaBuilding,
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';

import api from 'services/api';

import Perfil from 'models/Perfil';
import AutocompleteGrupoUsuarios from 'components/AutocompleteGrupoUsuarios';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import GrupoUsuariosModel from '../../../models/GrupoUsuarios';

const ConfiguradorGrupoUsuario: React.FC = () => {
  const [grupos, setGrupos] = useState([] as GrupoUsuariosModel[]);
  // eslint-disable-next-line prettier/prettier
  const [gruposEditing, setGruposEditing] = useState({} as GrupoUsuariosModel);

  const [formModalState, setFormModalState] = useState(false);
  const [perfis, setPerfis] = useState([] as Perfil[]);
  const [grupoSelecionado, setGrupoSelecionado] =
    useState<GrupoUsuariosModel | null>(null);
  const [isDefault, setIsDefault] = useState(true);
  const [definirDefault, setDefinirDefault] = useState(0);
  const tiposAlerta = [
    {
      label: 'Alertas de ajuda',
      tipo: 'A',
    },
    {
      label: 'Grupos sem item',
      tipo: 'G',
    },
    {
      label: 'Itens sem valor',
      tipo: 'I',
    },
  ];

  async function listaGrupos() {
    const response = await api.get('/integracao/grupo-atendimento');

    setGrupos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaGrupos();
  }, []);

  useEffect(() => {
    if (grupos.length > 0) {
      const valoresFiltrados = grupos.filter(valor => valor.isDefault === true);
      if (valoresFiltrados.length > 0) {
        setDefinirDefault(valoresFiltrados[0].id ?? 0);
      } else {
        setDefinirDefault(0);
      }
    }
  }, [grupos]);

  async function carregaPerfis() {
    const response = await api.get('/perfil');

    setPerfis(response.data);
  }

  useEffect(() => {
    carregaPerfis();
  }, []);

  async function handleChangeValorDefault(
    event: React.ChangeEvent<HTMLInputElement>,
    idDefault: any,
  ) {
    if (event.target.checked === true) {
      setDefinirDefault(idDefault);
    } else {
      setDefinirDefault(0);
    }
  }

  async function handleDelete(grupoId: number) {
    await api.delete(`/integracao/grupo-atendimento/${grupoId}`);

    listaGrupos();
  }

  async function handleInsert() {
    setGruposEditing({} as GrupoUsuariosModel);
    setGrupoSelecionado({} as GrupoUsuariosModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(grupo: GrupoUsuariosModel) {
    setGruposEditing(grupo);
    setGrupoSelecionado(grupo);
    setFormModalState(!formModalState);
  }

  async function handleSaveGrupo() {
    // eslint-disable-next-line prettier/prettier
    const { nome, grupoId, perfilId, tipoAlerta } = gruposEditing;

    if (!nome && !grupoId && !perfilId) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    try {
      if (gruposEditing.id) {
        await api.put(`/integracao/grupo-atendimento/${gruposEditing.id}`, {
          nome: grupoSelecionado?.nome,
          grupoId: grupoSelecionado?.grupoId,
          perfilId,
          isDefault: gruposEditing.id === definirDefault,
          tipoAlerta,
        });
      } else {
        await api.post(`/integracao/grupo-atendimento`, {
          nome: grupoSelecionado?.nome,
          grupoId: grupoSelecionado?.grupoId,
          perfilId,
          isDefault: definirDefault !== 0,
          tipoAlerta,
        });
      }

      toast.success('Registro concluído');
      setGruposEditing({} as GrupoUsuariosModel);
      listaGrupos();
    } finally {
      setFormModalState(false);
    }
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="MD"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Grupo {gruposEditing.nome}</small>
                    </div>
                    <Form
                      role="form"
                      className="align-items-center table-flush"
                    >
                      <FormGroup className="mb-3">
                        <Col sm="12" className="mb-3">
                          <AutocompleteGrupoUsuarios
                            onChange={(_, newValue) => {
                              setGrupoSelecionado(
                                newValue ?? ({} as GrupoUsuariosModel),
                              );
                            }}
                            grupoUsuariosSelecionado={grupoSelecionado}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Col sm="12" className="mb-3">
                          <span>Perfil</span>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaBuilding />
                              </InputGroupText>
                            </InputGroupAddon>

                            <Input
                              placeholder="Perfil"
                              type="select"
                              value={gruposEditing.perfilId}
                              onChange={event =>
                                setGruposEditing({
                                  ...gruposEditing,
                                  perfilId: Number(event.target.value),
                                })
                              }
                            >
                              <option value="">Selecione...</option>
                              {perfis.map((item: Perfil) => {
                                return (
                                  <option value={item.id}>{item.nome}</option>
                                );
                              })}
                            </Input>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Col sm="12" className="mb-3">
                          <span>Tipo de alerta</span>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Tubo"
                              type="select"
                              value={gruposEditing.tipoAlerta}
                              onChange={text =>
                                setGruposEditing({
                                  ...gruposEditing,
                                  tipoAlerta: String(text.target.value),
                                })
                              }
                            >
                              <option value="">Selecione...</option>
                              <option value="I">Itens sem valor</option>
                            </Input>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Col sm="12" className="mb-3">
                          <span>Perfil</span>
                          <InputGroup className="input-group-alternative">
                            <FormControlLabel
                              className="float-right"
                              control={
                                <Checkbox
                                  checked={definirDefault === gruposEditing.id}
                                  onChange={event =>
                                    handleChangeValorDefault(
                                      event,
                                      gruposEditing.id,
                                    )
                                  }
                                  color="primary"
                                />
                              }
                              label="Grupo Padrão"
                            />
                          </InputGroup>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveGrupo}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Grupos de atendimento (BPM)</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <td>Id</td>
                  <td>Grupo</td>
                  <td>Perfil</td>
                  <td>Tipo de alerta</td>
                  <td>Grupo padrão</td>
                </thead>
                <tbody>
                  {grupos.map(grupo => {
                    const perfilNome = perfis.find(
                      perfil => perfil.id === grupo.perfilId,
                    )?.nome;
                    const labelAlerta = tiposAlerta.find(
                      tipo => tipo.tipo === grupo.tipoAlerta,
                    )?.label;
                    return (
                      <tr key={grupo.id}>
                        <td className="col-1">
                          <strong>{grupo.id}</strong>
                        </td>
                        <td className="col-3">
                          <strong>{grupo.nome}</strong>
                        </td>
                        <td className="col-3">
                          <strong>{perfilNome}</strong>
                        </td>
                        <td className="col-3">
                          <strong>{labelAlerta}</strong>
                        </td>
                        <td className="col-3">
                          <strong>{grupo.isDefault ? 'Sim' : 'Não'}</strong>
                        </td>

                        <td className="col-2">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="warning"
                            type="button"
                            onClick={() => {
                              handleUpdate(grupo);
                            }}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(grupo.id);
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorGrupoUsuario;
