import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Swal from 'sweetalert2';

import api from 'services/api';

import { useLocation } from 'react-router-dom';

import CurvaBombaModel from '../../../models/ConfiguradorCurvaBomba';
import BombaModel from '../../../models/ConfiguradorBomba';

type LocationState = {
  stateBomba?: BombaModel;
};

const ConfiguradorCurvasBombas: React.FC = () => {
  const location = useLocation<LocationState>();
  const { stateBomba } = location.state;
  const [curvasBombas, setCurvasBombas] = useState([] as CurvaBombaModel[]);
  const idBomba = stateBomba?.id;
  // eslint-disable-next-line prettier/prettier
  const [curvaBombaEditing, setCurvaBombaEditing] = useState({} as CurvaBombaModel);

  const [formModalState, setFormModalState] = useState(false);

  async function listaCurvasBombas() {
    const response = await api.get(`/integracao/curvas-bombas/${idBomba}`);

    setCurvasBombas(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaCurvasBombas();
  }, []);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/integracao/curvas-bombas/${row.seq}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setCurvasBombas(curvasBombas.filter(item => item.seq !== row.seq));
        }
      });
      listaCurvasBombas();
    } else {
      setCurvasBombas(curvasBombas.filter(item => item.seq !== row.seq));
    }
  }

  async function handleInsert() {
    setCurvaBombaEditing({} as CurvaBombaModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(bomba: CurvaBombaModel) {
    setCurvaBombaEditing(bomba);

    setFormModalState(!formModalState);
  }

  async function handleSaveBomba() {
    // eslint-disable-next-line prettier/prettier
    const { vazao } = curvaBombaEditing;

    if (!vazao) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (curvaBombaEditing.seq) {
      await api.put(`/integracao/curvas-bombas/${idBomba}`, {
        seq: curvaBombaEditing.seq,
        seqModeloBomba: idBomba,
        vazao: curvaBombaEditing.vazao,
        alturaInicial: curvaBombaEditing.alturaInicial,
        alturaFinal: curvaBombaEditing.alturaFinal,
        rendAlturaInicial: curvaBombaEditing.rendAlturaInicial,
        rendAltura33p: curvaBombaEditing.rendAltura33p,
        rendAltura66p: curvaBombaEditing.rendAltura66p,
        rendAlturaFinal: curvaBombaEditing.rendAlturaFinal,
        npshAlturaInicial: curvaBombaEditing.npshAlturaInicial,
        npshAltura33p: curvaBombaEditing.npshAltura33p,
        npshAltura66p: curvaBombaEditing.npshAltura66p,
        npshAlturaFinal: curvaBombaEditing.npshAlturaFinal,
        rotorAlturaInicial: curvaBombaEditing.rotorAlturaInicial,
        rotorAltura33p: curvaBombaEditing.rotorAltura33p,
        rotorAltura66p: curvaBombaEditing.rotorAltura66p,
        rotorAlturaFinal: curvaBombaEditing.rotorAlturaFinal,
      });
    } else {
      await api.post(`/integracao/curvas-bombas/`, {
        seqModeloBomba: idBomba,
        vazao: curvaBombaEditing.vazao,
        alturaInicial: curvaBombaEditing.alturaInicial,
        alturaFinal: curvaBombaEditing.alturaFinal,
        rendAlturaInicial: curvaBombaEditing.rendAlturaInicial,
        rendAltura33p: curvaBombaEditing.rendAltura33p,
        rendAltura66p: curvaBombaEditing.rendAltura66p,
        rendAlturaFinal: curvaBombaEditing.rendAlturaFinal,
        npshAlturaInicial: curvaBombaEditing.npshAlturaInicial,
        npshAltura33p: curvaBombaEditing.npshAltura33p,
        npshAltura66p: curvaBombaEditing.npshAltura66p,
        npshAlturaFinal: curvaBombaEditing.npshAlturaFinal,
        rotorAlturaInicial: curvaBombaEditing.rotorAlturaInicial,
        rotorAltura33p: curvaBombaEditing.rotorAltura33p,
        rotorAltura66p: curvaBombaEditing.rotorAltura66p,
        rotorAlturaFinal: curvaBombaEditing.rotorAlturaFinal,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setCurvaBombaEditing({} as CurvaBombaModel);
    listaCurvasBombas();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Vazão *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Vazão"
                            value={Number(curvaBombaEditing.vazao)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                vazao: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Altura Inicial</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Altura Inicial"
                            value={Number(curvaBombaEditing.alturaInicial)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                alturaInicial: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Altura Final</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Altura Final"
                            value={Number(curvaBombaEditing.alturaFinal)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                alturaFinal: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rend Altura Inicial</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rend Altura Inicial"
                            value={Number(curvaBombaEditing.rendAlturaInicial)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rendAlturaInicial: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rend Altura 33p</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rend Altura 33p"
                            value={Number(curvaBombaEditing.rendAltura33p)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rendAltura33p: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rend Altura 66p</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rend Altura 66p"
                            value={Number(curvaBombaEditing.rendAltura66p)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rendAltura66p: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rend Altura Final</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rend Altura Final"
                            value={Number(curvaBombaEditing.rendAlturaFinal)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rendAlturaFinal: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Npsh Altura Inicial</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Npsh Altura Inicial"
                            value={Number(curvaBombaEditing.npshAlturaInicial)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                npshAlturaInicial: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Npsh Altura 33p</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Npsh Altura 33p"
                            value={Number(curvaBombaEditing.npshAltura33p)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                npshAltura33p: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Npsh Altura 66p</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Npsh Altura 66p"
                            value={Number(curvaBombaEditing.npshAltura66p)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                npshAltura66p: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Npsh Altura Final</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Npsh Altura Final"
                            value={Number(curvaBombaEditing.npshAlturaFinal)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                npshAlturaFinal: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rotor Altura Inicial</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rotor Altura Inicial"
                            value={Number(curvaBombaEditing.rotorAlturaInicial)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rotorAlturaInicial: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rotor Altura 33p</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rotor Altura 33p"
                            value={Number(curvaBombaEditing.rotorAltura33p)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rotorAltura33p: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rotor Altura 66p</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rotor Altura 66p"
                            value={Number(curvaBombaEditing.rotorAltura66p)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rotorAltura66p: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Rotor Altura Final</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Rotor Altura Final"
                            value={Number(curvaBombaEditing.rotorAlturaFinal)}
                            type="number"
                            onChange={text =>
                              setCurvaBombaEditing({
                                ...curvaBombaEditing,
                                rotorAlturaFinal: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveBomba}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Curvas - {stateBomba?.nome}</h3>
              </CardHeader>
              <Table className="align-rows-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Vazão</th>
                    <th>Altura Inicial</th>
                    <th>Altura Final</th>
                    <th>Rend AlturaInicial</th>
                    <th>Rend Altura33p</th>
                    <th>Rend Altura66p</th>
                    <th>Rend AlturaFinal</th>
                    <th>Npsh AlturaInicial</th>
                    <th>Npsh Altura33p</th>
                    <th>Npsh Altura66p</th>
                    <th>Npsh AlturaFinal</th>
                    <th>Rotor AlturaInicial</th>
                    <th>Rotor Altura33p</th>
                    <th>Rotor Altura66p</th>
                    <th>Rotor AlturaFinal</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {curvasBombas.map(bomba => (
                    <tr key={bomba.seq}>
                      <td className="py-2">{bomba.vazao}</td>
                      <td className="py-2">{bomba.alturaInicial}</td>
                      <td className="py-2">{bomba.alturaFinal}</td>
                      <td className="py-2">{bomba.rendAlturaInicial}</td>
                      <td className="py-2">{bomba.rendAltura33p}</td>
                      <td className="py-2">{bomba.rendAltura66p}</td>
                      <td className="py-2">{bomba.rendAlturaFinal}</td>
                      <td className="py-2">{bomba.npshAlturaInicial}</td>
                      <td className="py-2">{bomba.npshAltura33p}</td>
                      <td className="py-2">{bomba.npshAltura66p}</td>
                      <td className="py-2">{bomba.npshAlturaFinal}</td>
                      <td className="py-2">{bomba.rotorAlturaInicial}</td>
                      <td className="py-2">{bomba.rotorAltura33p}</td>
                      <td className="py-2">{bomba.rotorAltura66p}</td>
                      <td className="py-2">{bomba.rotorAlturaFinal}</td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(bomba);
                          }}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(bomba);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorCurvasBombas;
