import FichaTecnica from 'models/FichaTecnica';
import { Card, CardBody, Row } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function DadosMotor({ fichaTecnica }: Props): JSX.Element {
  const fichaTecnicaBombeamento = fichaTecnica?.FichaTecnicaBombeamento?.[0];
  const { potenciaCasaMaquina } = fichaTecnicaBombeamento || {};

  return (
    <Card className="shadow">
      <ColQuestaoTitulo>
        <LabelQuestaoTitulo>Motor</LabelQuestaoTitulo>
      </ColQuestaoTitulo>

      <CardBody className="p-3">
        <Row>
          <Campo
            sm="12"
            label="Potência nominal"
            valor={potenciaCasaMaquina}
            number
            unidade="CV"
          />
        </Row>
      </CardBody>
    </Card>
  );
}

export default DadosMotor;
