import styled from 'styled-components';
import { Col, Label } from 'reactstrap';

export const ComponentTitle = styled.h1`
  font-size: 1rem;
`;

export const Th = styled.th`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e9ecef;
`;

export const LabelItem = styled(Label)`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
`;

export const LabelTema = styled(Label)`
  font-size: 14px;
  margin-bottom: 0;
  justify-content: flex-end;
`;
export const TituloDestaque = styled.h1`
  background-color: aqua;
`;

export const MaterialListContainer = styled.div`
  padding: 0.6rem;
`;

export const InputAta = styled.input`
  width: 100%;
  color: #000;
  border: none !important;
  border-radius: 0 !important;
`;

export const TextAreaAta = styled.input`
  width: 100%;
  color: #000;
  border: none !important;
  border-radius: 0 !important;
`;

export const Td = styled.td`
  padding: 0 4px 0 !important;
`;

export const SpanTitulo = styled.span`
  font-size: 14px;
`;

export const LabelQuestaoTitulo = styled.label`
  font-size: 0.8125rem;
  color: #fff;
  font-weight: 800;
`;

export const LabelQuestao = styled.label`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  justify-content: flex-start;
`;

export const Labelcabecalho = styled.label`
  font-size: 12px;
  margin-bottom: 0;
  justify-content: flex-start;
`;

export const ColQuestao = styled.div`
  display: flex;
`;

export const DivSub = styled.div`
  border: 1px solid #c2c2c2;
  margin-top: 3em;
  margin-bottom: 3em;
`;

export const Geral = styled(Col)`
  border: 1px solid #c2c2c2;
  font-family: 'Calibri';
`;

export const CabecalhoFixoAta = styled(Col)`
  border: 1px solid #c2c2c2;
  justify-content: flex-start;
`;

export const TituloAta = styled(Col)`
  border: 1px solid #c2c2c2;
  font-style: bold;
  text-align: center;
`;

export const TemaAta = styled(Col)`
  border: 1px solid #c2c2c2;
  text-align: center;
`;

export const CabecalhoEditAta = styled(Col)`
  border: 1px solid #c2c2c2;
  margin: 0 auto;
`;

export const ConteudoDocumentoAta = styled(Col)`
  /*line-height: 5em;
  margin: 10px; */
`;

export const ColunaDados = styled(Col)`
  border: 1px solid #c2c2c2;
`;

export const DivFormatada = styled.div`
  margin-left: 10px;
`;
