import { useEffect, useState } from 'react';
import { CardHeader } from 'reactstrap';

import { Tab, Tabs } from '@material-ui/core';

import UserSmallHeader from 'components/Headers/UserSmallHeader';
import TabPanel from 'components/TabPanel';
import AppBar from 'components/AppBar';
import ConfiguradorGruposAgrupadores from 'views/pages/ConfiguradorAgrupadores/ConfiguradorGruposAgrupadores';
import ConfiguradorAgrupadores from '../index';

const AdmAgrupadores: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  return (
    <>
      <UserSmallHeader />
      <CardHeader />

      <AppBar>
        <Tabs
          value={currentTab}
          onChange={(_, newCurrentTab) => setCurrentTab(newCurrentTab)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Lista de agrupadores" style={{ fontWeight: 600 }} />
          <Tab label="Cadastro Aplicação" style={{ fontWeight: 600 }} />
        </Tabs>
      </AppBar>

      <div>
        <TabPanel value={currentTab} index={0}>
          <ConfiguradorAgrupadores />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <ConfiguradorGruposAgrupadores />
        </TabPanel>
      </div>
    </>
  );
};
export default AdmAgrupadores;
