import FichaTecnica from 'models/FichaTecnica';
import FichaTecnicaFotovoltaicaGeral from 'models/FichaTecnicaFotovoltaicaGeral';
import { useEffect, useState } from 'react';

import { Card, CardBody, Row, Table } from 'reactstrap';

import api from 'services/api';
import { ColQuestaoTitulo, LabelQuestaoTitulo } from '../../../styles';

import Campo from '../../atoms/Campo';

interface Props {
  fichaTecnica: FichaTecnica;
  orcamentoId: number;
}

interface FotovoltaicaTotalizador {
  id: number;
  tipo: string;
  consumoTotal: number;
  consumoJaneiro: number;
  consumoFevereiro: number;
  consumoMarco: number;
  consumoAbril: number;
  consumoMaio: number;
  consumoJunho: number;
  consumoJulho: number;
  consumoAgosto: number;
  consumoSetembro: number;
  consumoOutubro: number;
  consumoNovembro: number;
  consumoDezembro: number;
}

function DadosInformadosFotovoltaica(props: Props): JSX.Element {
  const { fichaTecnica, orcamentoId } = props;

  const fotovoltaicaGeral =
    (fichaTecnica
      ?.FichaTecnicaFotovoltaicaGeral?.[0] as FichaTecnicaFotovoltaicaGeral) ||
    null;
  const [fotovoltaicaTotalizador, setFotovoltaicaTotalizador] = useState(
    [] as FotovoltaicaTotalizador[],
  );

  async function getTotalFotovoltaica() {
    const response = await api.get(
      `/fotovoltaica-totalizador?fichaTecnicaId=${fichaTecnica?.id}`,
      {},
    );
    setFotovoltaicaTotalizador(response.data as FotovoltaicaTotalizador[]);
  }

  useEffect(() => {
    getTotalFotovoltaica();
  }, []);

  const campos = [
    {
      label: 'Potência Pico Sistema',
      valor: fotovoltaicaGeral?.potenciaPicoSistema || 0,
      unidade: 'kWp',
    },
    {
      label: 'Potência Inversor Aproximada',
      valor: fotovoltaicaGeral?.potenciaInversorAprox || 0,
      unidade: 'kW',
    },
    {
      label: 'Energia Ano',
      valor: fotovoltaicaGeral?.energiaAno || 0,
      unidade: 'kWh/ano',
    },
    {
      label: 'Área Aproximada',
      valor: fotovoltaicaGeral?.areaAproximada || 0,
      unidade: 'm²',
    },
    {
      label: 'Ponderada Geral',
      valor: Number(fotovoltaicaGeral?.potenciaPonderadaGeral || 0).toFixed(4),
    },
    {
      label: 'Fator Autoconsumo',
      valor: fotovoltaicaGeral?.fatorAutoConsumo || 0,
    },
  ];

  return (
    <>
      <Card
        className="shadow mt-2"
        style={{ width: '100%' }}
        hidden={!fotovoltaicaGeral}
      >
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>Total</LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody className="p-3">
              <Row>
                {campos.map(campo => (
                  <Campo
                    key={campo.label}
                    label={campo.label}
                    valor={campo.valor}
                    unidade={campo?.unidade}
                  />
                ))}
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <Card className="shadow mt-2" style={{ width: '100%' }}>
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>Totalizador UC</LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <CardBody className="p-3">
              <Row>
                <Table className="align-items-center table-flush" responsive>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Jan</th>
                      <th>Fev</th>
                      <th>Mar</th>
                      <th>Abr</th>
                      <th>Mai</th>
                      <th>Jun</th>
                      <th>Jul</th>
                      <th>Ago</th>
                      <th>Set</th>
                      <th>Out</th>
                      <th>Nov</th>
                      <th>Dez</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fotovoltaicaTotalizador?.map(total => {
                      const {
                        id,

                        tipo,
                        consumoTotal,
                        consumoJaneiro,
                        consumoFevereiro,
                        consumoMarco,
                        consumoAbril,
                        consumoMaio,
                        consumoJunho,
                        consumoJulho,
                        consumoAgosto,
                        consumoSetembro,
                        consumoOutubro,
                        consumoNovembro,
                        consumoDezembro,
                      } = total;

                      return (
                        <tr
                          key={id}
                          style={{
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <td className="pr-0">{tipo}</td>
                          <td>{consumoJaneiro || 0}</td>
                          <td>{consumoFevereiro || 0}</td>
                          <td>{consumoMarco || 0}</td>
                          <td>{consumoAbril || 0}</td>
                          <td>{consumoMaio || 0}</td>
                          <td>{consumoJunho || 0}</td>
                          <td>{consumoJulho || 0}</td>
                          <td>{consumoAgosto || 0}</td>
                          <td>{consumoSetembro || 0}</td>
                          <td>{consumoOutubro || 0}</td>
                          <td>{consumoNovembro || 0}</td>
                          <td>{consumoDezembro || 0}</td>
                          <td>{consumoTotal || 0}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
}

export default DadosInformadosFotovoltaica;
