import React from 'react';

// eslint-disable-next-line import/extensions
import config from '../../config';

const UserSmallHeader: React.FC = () => {
  return (
    <div className="header pb-7 d-flex align-items-center">
      <span className={`mask bg-gradient-${config.class} opacity-8`} />
    </div>
  );
};

export default UserSmallHeader;
