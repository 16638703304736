import { Row, Col } from 'reactstrap';
import { Container, Label } from './styles';

export default function FornecedoraFaturamento(): JSX.Element {
  function Texto({ children }: { children: string }): JSX.Element {
    return (
      <Row>
        <Col>
          <Label>{children}</Label>
        </Col>
      </Row>
    );
  }

  return (
    <Container>
      <Row>
        <Col sm="3">
          <Label>Fornecedora e Faturamento:</Label>
        </Col>
        <Col sm="9">
          <Texto>FOCKINK INDÚSTRIAS ELÉTRICAS LTDA.</Texto>
          <Texto>Av. Presidente Kennedy, 3280 - Bairro Arco-Íris</Texto>
          <Texto>Cx. Postal: 48</Texto>
          <Texto>98280-000 - Panambi - RS</Texto>
          <Texto>CNPJ: 03.021.334/0003-00</Texto>
          <Texto>INSCR.ESTADUAL: 090/0037652</Texto>
          <Texto>Fone/Fax: (55) 3375.9500</Texto>
          <Texto>E-mail: irrigacao@fockink.ind.br</Texto>
        </Col>
      </Row>
    </Container>
  );
}
