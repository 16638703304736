import FichaTecnica from 'models/FichaTecnica';

import { Card, Col } from 'reactstrap';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';

import Quadro from '../../../../atoms/Quadro';
import { Label, ColName, RowFoot } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function AlturasManometricas(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const {
    pressaoExtremoPivo,
    desnivelCentroPontoMaisAlto,
    perdaCargaTuboPivo,
    alturaAspersores,
  } = fichaTecnica;

  const pressaoTotalCentroPivo =
    Number(pressaoExtremoPivo ?? 0) +
    Number(desnivelCentroPontoMaisAlto ?? 0) +
    Number(perdaCargaTuboPivo ?? 0) +
    Number(alturaAspersores ?? 0);

  const TITULO = 'ALTURAS MANOMÉTRICAS';
  const RODAPE = 'Pressão Total no Centro do Pivô';
  const UNIDADE_MEDIDA = 'm.c.a';

  const campos = [
    { label: 'Pressão Extremidade Pivô', valor: pressaoExtremoPivo },
    {
      label: 'Desnível Centro Ponto + Alto',
      valor: desnivelCentroPontoMaisAlto,
    },
    { label: 'Perda de Carga Tubo Pivô', valor: perdaCargaTuboPivo },
    { label: 'Altura dos Aspersores', valor: alturaAspersores },
  ];

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Col className="mt-2">
          {campos.map(campo => (
            <Campo
              sm="12"
              key={campo.label}
              number
              label={campo.label}
              valor={campo.valor}
              unidade={UNIDADE_MEDIDA}
            />
          ))}
        </Col>

        <RowFoot className="pr-3">
          <ColName sm="8">
            <Label>{RODAPE}: </Label>
          </ColName>

          <Col className="text-right pr-4" sm="4">
            <Label>
              {arredondaDecimais(pressaoTotalCentroPivo)} {UNIDADE_MEDIDA}
            </Label>
          </Col>
        </RowFoot>
      </Quadro>
    </Card>
  );
}

export default AlturasManometricas;
