import FichaTecnica from 'models/FichaTecnica';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import { Container, Td, Th } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function TabelaTensoes(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const { FichaTecnicaTensoes } = fichaTecnica;

  const headers = [
    'Tensão Primária',
    'Tensão Secundária',
    'ΔV1v',
    'Tensão Primária',
    'Tensão Secundária',
    'ΔV2v',
    'Tensão no Balanço',
    'Variações da rede energia',
  ];

  return (
    <Container>
      <Card className="shadow mt-2">
        <CardBody className="mt--4">
          <Card className="shadow mt-4">
            <Row className="mt--3">
              <ColQuestaoTitulo>
                <LabelQuestaoTitulo>Tabela das Tensões</LabelQuestaoTitulo>
              </ColQuestaoTitulo>
            </Row>

            <Table bordered responsive>
              <tr>
                {headers.map(header => (
                  <Th>{header}</Th>
                ))}
              </tr>

              {FichaTecnicaTensoes?.map(tensao => {
                const {
                  id,
                  tensaoPrimariaElevador,
                  tensaoSecundariaElevador,
                  cabo,
                  tensaoPrimariaRebaixador,
                  tensaoSecundariaRebaixador,
                  pivo,
                  tensaoBalanco,
                  variacao,
                } = tensao;

                return (
                  <tr key={id}>
                    <Td>{tensaoPrimariaElevador} V</Td>
                    <Td>{tensaoSecundariaElevador} V</Td>
                    <Td>{cabo} V</Td>
                    <Td>{tensaoPrimariaRebaixador} V</Td>
                    <Td>{tensaoSecundariaRebaixador} V</Td>
                    <Td>{pivo} V</Td>
                    <Td>{tensaoBalanco} V</Td>
                    <Td>{variacao}</Td>
                  </tr>
                );
              })}
            </Table>
          </Card>
        </CardBody>
      </Card>
    </Container>
  );
}

export default TabelaTensoes;
