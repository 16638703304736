import UserSmallHeader from 'components/Headers/UserSmallHeader';

import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import formatCurrency from 'utils/formatCurrency';
import api from 'services/api';
import { Col, Row, Table } from 'reactstrap';
import { ProdutoProposta } from 'models/ProdutoProposta';
import ConfiguradorMatrizResponsabilidade from 'models/ConfiguradorMatrizResponsabilidade';
import FVCustoTotal_AtualFuturo_Apex from 'views/charts/FVCustoTotal_AtualFuturo_Apex';
import formatNumeros from 'utils/formatNumeros';
import FVFluxoCaixa_Apex from 'views/charts/FVFluxoCaixa_Apex';
import './style.css';
import { TipoColunasTabela } from 'models/TipoColunaTabela';
import LogoImg from '../assets/img/logo_fck.png';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  produtoProposta: ProdutoProposta;
}

interface PropostaImpressaoContext {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  isPropostaExterior: boolean;
  setIsPropostaExterior: (valor: boolean) => void;
  valorDolar: number;
  setValorDolar: (valor: number) => void;
  parcelas: PropostaVersaoParcelas[];
}

export const PropostaImpressaoContext = createContext(
  {} as PropostaImpressaoContext,
);

interface iParamsLista {
  label: string;
  valor: any;
}

interface TotalizadorTipo {
  nome: string;
  acessoEspecial: boolean;
  mostrar: boolean;
}

interface iModel {
  instalacaoEquipamento?: string | null;
  areaAproximada?: number | null;
  custoEnergiaSemUsina?: number | null;
  custoTotalSemUsina?: number | null;
  custoTotalComUsina?: number | null;
  economiaMedia?: number | null;
  economiaUsina?: number | null;
  energiaAno?: number | null;
  fatorAutoConsumo?: number | null;
  fichaTecnicaId?: number | null;
  inflacao?: number | null;
  lcoe?: number | null;
  lucroCaixaTotal?: number | null;
  opex?: number | null;
  potenciaInversorAprox?: number | null;
  potenciaPicoSistema?: number | null;
  potenciaPonderadaGeral?: number | null;
  prazo?: number | null;
  primeiroAnoPositivo?: number | null;
  referenciaCalculada?: number | null;
  taxa?: number | null;
  tir?: number | null;
  vpl?: number | null;
  tipoFinanciamento?: string | null;
  tipoGd?: string | null;
  tipoModulo?: string | null;
  tipoPagamento?: string | null;
  modulo?: string | null;
  moduloOriginal?: string | null;
  valorSistemaOriginal?: number | null;
  valorSistema?: number | null;
  energiaAnoOriginal?: number | null;
  potenciaPicoSistemaOriginal?: number | null;
  potenciaInversorOriginal?: number | null;
  geracao?:
    | {
        consumoAbril?: number | null;
        consumoAgosto?: number | null;
        consumoDezembro?: number | null;
        consumoFevereiro?: number | null;
        consumoJaneiro?: number | null;
        consumoJulho?: number | null;
        consumoJunho?: number | null;
        consumoMaio?: number | null;
        consumoMarco?: number | null;
        consumoNovembro?: number | null;
        consumoOutubro?: number | null;
        consumoSetembro?: number | null;
        consumoTotal?: number | null;
      }
    | any;
}

export default function FVSimulacaoViabilidade({
  valores,
}: {
  valores: iParamsLista[];
}): JSX.Element {
  const location = useLocation<LocationState>();
  const { proposta, propostaVersao, produtoProposta } = location.state;

  const propostaVersaoId = propostaVersao.id;

  const [fotovoltaicaGeral, setFotovoltaicaGeral] = useState<iModel>({});

  const [fotovoltaicaViabilidade, setFotovoltaicaViabilidade] = useState(
    [] as any[],
  );
  const [variaveisFotovoltaica, setVariaveisFotovoltaica] = useState(
    [] as iParamsLista[],
  );

  const [valorSistema, setValorSistema] = useState<number>(0);

  const colunasTabela1: TipoColunasTabela[] = [
    {
      tipo: '',
      prompt: 'Ano',
      label: 'Ano',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_TOTAL_SEM_USINA',
      prompt: 'Custo Total Anual sem UFV',
      label: 'Custo s/UFV',
      ocultar: false,
    },
    {
      tipo: 'T1_PARCELAS_PGTO',
      prompt: 'Pagamento',
      label: 'Pagamento',
      ocultar: false,
    },
    {
      tipo: 'T1_ECONOMIA_USINA',
      prompt: 'Economia UFV',
      label: 'Economia UFV',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_ENERGIA_COM_USINA',
      prompt: 'Energia Residual',
      label: 'Energia resid.',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_ICMS_COM_USINA',
      prompt: 'Custo ICMS',
      label: 'ICMS Exc.',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_TUSD_SOBRE_CREDITO',
      prompt: 'Custo TUSD',
      label: 'TUSD Exc.',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_DEMANDA_COM_USINA',
      prompt: 'Custo Demanda Futuro com UFV',
      label: 'Demanda c/UFV',
      ocultar: false,
    },
    {
      tipo: 'T1_CUSTO_TOTAL_COM_USINA',
      prompt: 'Custo Total Futuro com UFV',
      label: 'Custo c/UFV',
      ocultar: false,
    },
  ];

  async function getValorSistemaFotovoltaico() {
    const response = await api.get(
      `/fotovoltaica/valor-sistema?orcamentoId=${propostaVersao.PropostaConfiguracao?.[0]?.orcamentoId}`,
      {},
    );

    setValorSistema(Number(response.data ?? 0));
  }

  async function infoFotovoltaica() {
    const response = await api.get(
      `/fotovoltaica/valores-finalizar-orcamento?orcamentoId=${propostaVersao.PropostaConfiguracao?.[0]?.orcamentoId}`,
    );

    const valores2 = response.data as iModel;

    setFotovoltaicaGeral(valores2);
  }

  async function getViabilidadeFotovoltaica() {
    const response2 = await api.get(
      `/fotovoltaica/viabilidade-formatado?orcamentoId=${propostaVersao.PropostaConfiguracao?.[0]?.orcamentoId}`,
      {},
    );

    setFotovoltaicaViabilidade(response2.data);
  }

  useEffect(() => {
    infoFotovoltaica();
    getValorSistemaFotovoltaico();
    getViabilidadeFotovoltaica();
  }, []);

  async function carregaInformacoes() {
    if (propostaVersaoId) {
      try {
        const response = await api.get(
          `/proposta/versao/${propostaVersaoId}/variaveis`,
        );

        const valores2 = response.data as iParamsLista[];

        setVariaveisFotovoltaica(valores2);
      } finally {
        //        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    carregaInformacoes();
  }, []);

  return (
    <>
      <div className="print-div">
        <Row>
          <Col sm="12" className="mt-1">
            <div
              style={{
                float: 'left',
                padding: '5px',
              }}
            >
              <img src={LogoImg} alt="Logo" width="65%" height="65%" />
            </div>
            <div style={{ width: '100%', float: 'left' }}>&nbsp;</div>
          </Col>
        </Row>

        <div style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
          <div
            className="mt-1 print-div-tit-1"
            style={{
              width: '100%',
              fontWeight: 'normal',
              fontSize: '30px!important',
              textAlign: 'center',
              padding: '5px',
              whiteSpace: 'pre-line',
              marginBottom: '30px',
            }}
          >
            RESUMO DE VIABILIDADE ECONÔMICA
          </div>
          <div>
            <strong>
              {valores?.find(item => item.label === `clienteNome`)?.valor ?? ''}
              {' - '}
              {valores?.find(item => item.label === `totalKWp`)?.valor ??
                ''}{' '}
              kWp{' '}
            </strong>
          </div>
          <div>
            Proposta GNF{' '}
            {valores?.find(item => item.label === `configuracaoGNF`)?.valor ??
              ''}
          </div>
          <br />
          <h2>Premissas</h2>
          <div>
            <strong>Valor sistema: </strong>
            {formatCurrency(valorSistema ?? 0, 2) ?? ''}
          </div>
          <div>
            <strong>Forma de pagamento: </strong>
            {valores?.find(item => item.label === `formaPagamento`)?.valor ??
              ''}
          </div>
          <div>
            <strong>Taxa aumento tarifa energia: </strong>
            {valores?.find(item => item.label === `taxaInflacao`)?.valor ?? ''}%
            a.a.
          </div>
          <div>
            <strong>Opex (custo operacional em relação ao Capex): </strong>
            {valores?.find(item => item.label === `opex`)?.valor ?? ''}% a.a.
          </div>
          <div>
            <strong>Taxa mínima de atratividade: </strong>
            {valores?.find(item => item.label === `taxaMinimaAtratividade`)
              ?.valor ?? ''}
            % a.a.
          </div>
          <div
            hidden={
              String(
                valores?.find(item => item.label === `formaPagamento`)?.valor,
              ).toUpperCase() !== 'FINANCIADO'
            }
          >
            <strong>Taxa de financiamento: </strong>
            {valores?.find(item => item.label === `taxaFinanciamento`)?.valor ??
              ''}
            % a.a.
          </div>
          <div
            hidden={
              String(
                valores?.find(item => item.label === `formaPagamento`)?.valor,
              ).toUpperCase() !== 'FINANCIADO'
            }
          >
            <strong>Prazo de financiamento: </strong>
            {valores?.find(item => item.label === `prazoFinanciamento`)
              ?.valor ?? ''}{' '}
            anos
          </div>

          <br />
          <h2>Métricas</h2>
          <div>
            <strong>Custo atual:</strong>Gasto atual com energia, projetado para
            25 anos no futuro;
          </div>
          <div>
            <strong>Custo futuro:</strong>Gasto com energia se instalada FV,
            projetado para 25 anos;
          </div>
          <div>
            <strong>Economia:</strong>Percentual de economia de energia (consumo
            em kWh);
          </div>
          <div>
            <strong>LCOE: </strong>Custo equivalente de geração em 25 anos;
          </div>
          <div>
            <strong>Caixa total: </strong>Lucro total gerado em 25 anos.
          </div>
          <br />
          <Row className="mt-3">
            <Col sm="10">
              <h2>Custo Total (Atual x Futuro) </h2>
            </Col>
          </Row>
          <Row>
            <Col sm="10">
              <div
                className="component-print component-print-1"
                style={{
                  width: '100%',
                  height: 'auto!important',
                  padding: '5px',
                }}
              >
                {fotovoltaicaViabilidade.length > 0 && (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <FVCustoTotal_AtualFuturo_Apex
                    valores={fotovoltaicaViabilidade}
                    larguraTotal
                    alturaTotal={false}
                    alturaEspecifica="600px"
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className="row-grafico-tabela mt-1">
            <Col sm="12">
              <div className="totalizador-destaque grafico-tabela">
                <Table className="align-items-center">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: 'bold' }}>LCOE</th>
                      <th style={{ fontWeight: 'bold' }}>Economia média</th>
                      <th style={{ fontWeight: 'bold' }}>
                        Caixa Total (Lucro)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: 'bold' }}>
                        {fotovoltaicaGeral?.lcoe
                          ? formatNumeros(fotovoltaicaGeral?.lcoe)
                          : ''}{' '}
                        R$/kWh
                      </td>
                      <td style={{ fontWeight: 'bold' }}>
                        {fotovoltaicaGeral?.economiaMedia
                          ? fotovoltaicaGeral?.economiaMedia
                          : ''}
                        %
                      </td>
                      <td style={{ fontWeight: 'bold' }}>
                        {fotovoltaicaGeral?.lucroCaixaTotal
                          ? formatCurrency(
                              fotovoltaicaGeral?.lucroCaixaTotal ?? 0,
                            )
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <div style={{ pageBreakInside: 'avoid' }}>
            <Row className="mt-1">
              <Col sm="10">
                <h2>Fluxo de Caixa </h2>
              </Col>
            </Row>

            <Row className="row-grafico-tabela mt-2">
              <Col sm="12" className="grafico-tabela">
                <Table
                  className="align-items-center table-fluxo-caixa"
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Ano</th>
                      <th>Caixa Anual</th>
                      <th>Fluxo Caixa</th>
                      <th>Ano</th>
                      <th>Caixa Anual</th>
                      <th>Fluxo Caixa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fotovoltaicaViabilidade
                      .filter((item: any, index: number) => index <= 12)
                      .map((item: any) => {
                        const { anoCorrente, caixaAnual, fluxoCaixa } = item;

                        const indexSecondColumn = 12;
                        return (
                          <tr key={`tabela1_${anoCorrente}`}>
                            <td>{anoCorrente}</td>
                            <td>{formatCurrency(caixaAnual)}</td>
                            <td>{formatCurrency(fluxoCaixa)}</td>
                            <td>
                              {fotovoltaicaViabilidade[
                                anoCorrente + indexSecondColumn
                              ]
                                ? fotovoltaicaViabilidade[
                                    anoCorrente + indexSecondColumn
                                  ]?.anoCorrente
                                : ''}
                            </td>
                            <td>
                              {fotovoltaicaViabilidade[
                                anoCorrente + indexSecondColumn
                              ]
                                ? formatCurrency(
                                    fotovoltaicaViabilidade[
                                      anoCorrente + indexSecondColumn
                                    ]?.caixaAnual,
                                  )
                                : ''}
                            </td>
                            <td>
                              {fotovoltaicaViabilidade[
                                anoCorrente + indexSecondColumn
                              ]
                                ? formatCurrency(
                                    fotovoltaicaViabilidade[
                                      anoCorrente + indexSecondColumn
                                    ]?.fluxoCaixa,
                                  )
                                : ''}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                        Total 25 anos:
                      </td>
                      <td colSpan={2} style={{ fontWeight: 'bold' }}>
                        {formatCurrency(
                          fotovoltaicaViabilidade.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.caixaAnual,
                            0,
                          ),
                        )}
                      </td>
                      <td colSpan={3} />
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
          </div>
          <Row className="row-grafico">
            <Col sm="9" className="grafico-tabela col-grafico mt-2">
              <div
                className="component-print"
                style={{
                  width: '100%',
                  padding: '5px',
                  whiteSpace: 'pre-line',
                }}
              >
                {fotovoltaicaViabilidade.length > 0 && (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <FVFluxoCaixa_Apex
                    valores={fotovoltaicaViabilidade}
                    larguraTotal
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className="row-grafico-tabela mt-2">
            <Col sm="12">
              <div className="totalizador-destaque grafico-tabela">
                <Table className="align-items-center ">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: 'bold' }}>Payback</th>
                      <th style={{ fontWeight: 'bold' }}>VPL</th>
                      <th style={{ fontWeight: 'bold' }}>TIR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: 'bold' }}>
                        {fotovoltaicaGeral?.primeiroAnoPositivo ?? ''}
                      </td>
                      <td style={{ fontWeight: 'bold' }}>
                        {fotovoltaicaGeral?.vpl
                          ? formatCurrency(fotovoltaicaGeral?.vpl ?? 0)
                          : ''}
                      </td>
                      <td style={{ fontWeight: 'bold' }}>
                        {fotovoltaicaGeral?.tir
                          ? `${fotovoltaicaGeral?.tir}%`
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <div style={{ pageBreakInside: 'avoid' }}>
            <Row className="mt-1">
              <Col sm="10">
                <h2>Cálculo Econômico</h2>
              </Col>
            </Row>
            <Row className="row-grafico-tabela mt-2">
              <Col
                sm="12"
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                }}
              >
                <Table
                  className="align-items-center table-fluxo-caixa"
                  responsive
                >
                  <thead>
                    <tr style={{ padding: '5px' }}>
                      {colunasTabela1
                        .filter(item => item.ocultar === false)
                        .map(item => {
                          return <th title={item.prompt}>{item.label}</th>;
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {fotovoltaicaViabilidade.map((item: any) => {
                      const {
                        anoCorrente,
                        custoTotalSemUsina,
                        parcelasPgto,
                        economiaUsina,
                        custoEnergiaComUsina,
                        custoIcmsComUsina,
                        custoTusdSobreCredito,
                        custoDemandaComUsina,
                        custoTotalComUsina,
                      } = item;

                      const styleTd = {};

                      return (
                        <tr key={`tabela1_${anoCorrente}`} style={styleTd}>
                          <td style={styleTd}>{anoCorrente}</td>
                          <td style={styleTd}>
                            {formatCurrency(custoTotalSemUsina)}
                          </td>
                          <td style={styleTd}>
                            {formatCurrency(parcelasPgto)}
                          </td>
                          <td style={styleTd}>
                            {formatCurrency(economiaUsina)}
                          </td>
                          <td style={styleTd}>
                            {formatCurrency(custoEnergiaComUsina)}
                          </td>
                          <td style={styleTd}>
                            {formatCurrency(custoIcmsComUsina)}
                          </td>
                          <td style={styleTd}>
                            {formatCurrency(custoTusdSobreCredito)}
                          </td>
                          <td style={styleTd}>
                            {formatCurrency(custoDemandaComUsina)}
                          </td>
                          <td style={styleTd}>
                            {formatCurrency(custoTotalComUsina)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
