import { useEffect, useState } from 'react';
import {
  CardHeader,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Input,
  Modal,
  FormGroup,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';
import AutocompleteItemDomsge from 'components/AutocompleteItemDomsge';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import ItemDomsge from 'models/ItemDomsge';
import { CircularProgress } from '@material-ui/core';
import toast from 'react-hot-toast';
import { RegraPerdaEspecificaItem } from 'models/RegraPerdaEspecificaItem';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import { Th, Td } from './styles';

interface editingRegraPerdaEspecificaItem {
  isNew: boolean | true;
  id?: number | null;
  fatorK?: number | null;
  fatorKV?: number | null;
  di?: number | null;
  materialId?: number | null;
  fabrEmprCodigo?: string | null;
  fabrCodigo?: string | null;
  diteDgpeCodigo?: string | null;
  diteCodigo?: string | null;
  descricao?: string | null;
}

export default function ConfiguradorPerdaEspecifica(): JSX.Element {
  const [registros, setRegistros] = useState<RegraPerdaEspecificaItem[]>([]);

  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [registroEdicao, setRegistroEdicao] =
    useState<editingRegraPerdaEspecificaItem>(
      {} as editingRegraPerdaEspecificaItem,
    );

  async function carregarItensEspecificos(): Promise<void> {
    try {
      setLoading(true);
      const response = await api.get(`/regra-perda-especifica`);
      const lista = response.data;
      setRegistros(lista);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    carregarItensEspecificos();
  }, []);

  async function handleOpenModal() {
    setIsModalOpen(true);
    setRegistroEdicao({
      isNew: true,
    });
    setIsEditing(false);
  }

  async function salvar() {
    // Verificações caso seja inclusão
    if (!isEditing && registroEdicao.isNew) {
      const {
        descricao,
        fabrCodigo,
        fabrEmprCodigo,
        diteCodigo,
        diteDgpeCodigo,
      } = registroEdicao;
      if (
        !descricao ||
        !fabrCodigo ||
        !fabrEmprCodigo ||
        !diteCodigo ||
        !diteDgpeCodigo
      ) {
        toast.error('Um material deve ser selecionado');
        return;
      }
    }

    const { fatorK, fatorKV } = registroEdicao;

    if (!fatorK && !fatorKV) {
      toast.error('Fator K ou Fator KV devem ser informados!');
      return;
    }

    const data = {
      fabrEmprCodigo: registroEdicao.fabrEmprCodigo,
      fabrCodigo: registroEdicao.fabrCodigo,
      diteDgpeCodigo: registroEdicao.diteDgpeCodigo,
      diteCodigo: registroEdicao.diteCodigo,
      descricao: registroEdicao.descricao,
      fatorK: registroEdicao.fatorK ?? null,
      fatorKV: registroEdicao.fatorKV ?? null,
      di: registroEdicao.di ?? null,
      materialId: registroEdicao.materialId,
    };

    try {
      setIsSaving(true);

      if (isEditing)
        await api.put(`/regra-perda-especifica/${registroEdicao.id}`, data);
      else await api.post(`/regra-perda-especifica`, data);

      await carregarItensEspecificos();

      setIsModalOpen(false);
      setRegistroEdicao({
        isNew: true,
      });
    } finally {
      setIsSaving(false);
    }
  }

  function handleSelecionarItemDomsge(itemDomsge: ItemDomsge | null) {
    const buscarExistente = registros.find(
      item => item.material.diteCodigo === itemDomsge?.DITE_CODIGO,
    );
    if (buscarExistente) {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro!',
        html: 'O material selecionado já consta em um registro existente!',
      });
    } else {
      setRegistroEdicao({
        ...registroEdicao,
        diteCodigo: itemDomsge?.DITE_CODIGO,
        descricao: itemDomsge?.DESCRICAO,
        fabrCodigo: itemDomsge?.FABR_CODIGO,
        fabrEmprCodigo: itemDomsge?.FABR_EMPR_CODIGO,
        diteDgpeCodigo: itemDomsge?.DITE_DGPE_CODIGO,
      });
    }
  }

  async function handleEdit(registro: RegraPerdaEspecificaItem) {
    setIsEditing(true);
    setIsModalOpen(true);

    setRegistroEdicao({
      isNew: false,
      id: registro.id,
      fatorK: registro.fatorK,
      fatorKV: registro.fatorKV,
      di: registro.di,
      materialId: registro.materialId,
      diteCodigo: registro.material.diteCodigo,
      descricao: registro.material.descricao,
    });
  }

  async function handleDelete(registro: RegraPerdaEspecificaItem) {
    const resposta = await Swal.fire({
      title: `Deseja excluir o registro de perda específica para o item ${registro.material.diteCodigo} - ${registro.material.descricao}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, excluir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    });

    const excluir = resposta.isConfirmed;

    if (excluir) {
      try {
        setIsSaving(true);

        Swal.fire({
          icon: 'info',
          text: `Excluindo registro`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        await api.delete(`/regra-perda-especifica/${registro.id}`);

        await carregarItensEspecificos();

        toast.success('Feito!', {});
      } finally {
        setIsSaving(false);
        Swal.close();
      }
    }
  }

  return (
    <>
      <UserSmallHeader />

      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        style={{ maxWidth: '60vw', width: '100%' }}
      >
        <CardHeader className="bg-transparent">
          <h4>
            Perda Específica de Item
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              <span aria-hidden>×</span>
            </button>
          </h4>
        </CardHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Row>
                <Col sm="12" className="mt-2">
                  <strong>Item:</strong>
                  <div hidden={isEditing}>
                    <AutocompleteItemDomsge
                      hideTitle
                      handleSelecionarItemDomsge={handleSelecionarItemDomsge}
                      maxWidth="80%"
                      noAutoFocus
                      emprCodigo=""
                    />
                  </div>
                  <div
                    hidden={!isEditing}
                  >{`${registroEdicao.diteCodigo} - ${registroEdicao.descricao}`}</div>
                </Col>
                <Col sm="12" className="mt-2">
                  <FormGroup className="mb-3">
                    <strong>Fator K:</strong>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-tag" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Fator K"
                        value={Number(registroEdicao.fatorK)}
                        type="number"
                        onChange={text =>
                          setRegistroEdicao({
                            ...registroEdicao,
                            fatorK: Number(text.target.value),
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm="12" className="mt-2">
                  <FormGroup className="mb-3">
                    <strong>Fator KV:</strong>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-tag" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Fator KV"
                        value={Number(registroEdicao.fatorKV)}
                        type="number"
                        onChange={text =>
                          setRegistroEdicao({
                            ...registroEdicao,
                            fatorKV: Number(text.target.value),
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm="12" className="mt-2">
                  <FormGroup className="mb-3">
                    <strong>Di:</strong>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-tag" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Di"
                        value={Number(registroEdicao.di)}
                        type="number"
                        onChange={text =>
                          setRegistroEdicao({
                            ...registroEdicao,
                            di: Number(text.target.value),
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button
            className="my-4"
            disabled={isSaving || loading}
            color="primary"
            type="button"
            onClick={() => salvar()}
          >
            {isSaving ? `Salvando...` : `Salvar`}
          </Button>
        </ModalFooter>
      </Modal>

      <CardHeader>
        <Row>
          <Col sm="12">
            <h4 className="mb--1">
              Perdas Específicas de Itens
              <Button
                // disabled={loading || !isSaving}
                className="btn-icon float-right"
                color="primary"
                type="button"
                size="sm"
                onClick={handleOpenModal}
              >
                <FaPlusCircle />
              </Button>
            </h4>
            <small className="text-muted"> </small>
          </Col>
        </Row>
      </CardHeader>

      <Card>
        <CardBody className="p-1">
          {loading ? (
            <Row>
              <Col className="text-center mb-4 mt-4">
                <CircularProgress />
              </Col>
            </Row>
          ) : (
            <Table bordered responsive>
              <thead>
                <tr>
                  <Th>Material</Th>
                  <Th>Fator K</Th>
                  <Th>Fator KV</Th>
                  <Th>Di</Th>
                  <Th />
                </tr>
              </thead>
              <tbody>
                {registros.map((item: RegraPerdaEspecificaItem) => {
                  return (
                    <tr key={item.id}>
                      <Td>{`${item.material.diteCodigo} - ${item.material.descricao}`}</Td>
                      <Td>{item.fatorK ?? null}</Td>
                      <Td>{item.fatorKV ?? null}</Td>
                      <Td>{item.di ?? null}</Td>
                      <Td>
                        <div
                          className="float-right"
                          style={{
                            textAlign: 'right',
                          }}
                        >
                          <Button
                            className="btn-icon btn-2"
                            size="sm"
                            color="primary"
                            title="Editar registro"
                            type="button"
                            onClick={() => handleEdit(item)}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            className="btn-icon btn-2"
                            size="sm"
                            color="danger"
                            title="Remover registro"
                            type="button"
                            onClick={() => handleDelete(item)}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
}
