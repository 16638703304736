import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'react-apexcharts';

import formatCurrency from 'utils/formatCurrency';
import formatNumeros from 'utils/formatNumeros';

interface Props {
  valores: any[];
  larguraTotal: boolean | true;
  alturaTotal: boolean | true;
  alturaEspecifica?: string | '500px';
}

function FVCustoTotal_AtualFuturo_Apex(props: Props): JSX.Element {
  const { valores, larguraTotal, alturaTotal, alturaEspecifica } = props;

  const [reportContent, setReportContent] = useState<any[]>([]);
  const [isLoadingReport, setIsLoadingReport] = useState(true);

  useEffect(() => {
    if (valores && valores.length > 0) {
      setReportContent(valores);
      setIsLoadingReport(false);
    }
  }, [valores]);

  const chart = {
    options: {
      chart: {
        id: 'custo-total-bar',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: '#d6d6d6',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        lines: {
          show: true,
        },
        labels: {
          formatter(value: any) {
            return formatNumeros(Number(value));
          },
        },
      },
      tooltip: {
        x: {
          formatter(val: any) {
            return `Ano: ${val}`;
          },
        },
        y: {
          formatter(val: any) {
            return `${formatCurrency(Number(val) ?? 0)}`;
          },
          title: {
            formatter(seriesName: any) {
              return `${seriesName}:`;
            },
          },
        },
      },
      xaxis: {
        categories: reportContent.map(item => {
          return item.anoCorrente;
        }),
      },
    },
    series: [
      {
        name: 'Custo Total Atual',
        data: reportContent.map(item => {
          return item.custoTotalSemUsina;
        }),
        color: 'rgb(255, 99, 132)',
      },
      {
        name: 'Custo Total FT',
        data: reportContent.map(item => {
          return item.custoTotalComUsina;
        }),
        color: 'rgb(75, 192, 192)',
      },
    ],
  };

  return (
    <>
      {!isLoadingReport ? (
        <div
          className="grafico"
          style={{ width: larguraTotal ? '100%' : '70vw' }}
        >
          <Chart
            height={alturaTotal ? undefined : alturaEspecifica ?? '500px'}
            options={chart.options}
            series={chart.series}
            type="bar"
          />
        </div>
      ) : (
        <>Carregando Gráfico</>
      )}
    </>
  );
}

export default FVCustoTotal_AtualFuturo_Apex;
