import FichaTecnica from 'models/FichaTecnica';
import Orcamento from 'models/Orcamento';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';

import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

import Quadro from '../../../../atoms/Quadro';
import { Container, Button } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
  acessoLimitadoPerdaCarga: ValidarAcessoPrograma;
}

export default function TabelasDetalhes(props: Props): JSX.Element {
  const { fichaTecnica, orcamento, acessoLimitadoPerdaCarga } = props;

  return (
    <Card className="shadow mt-2">
      <Quadro noBorder>
        <Container>
          <Link
            style={{ width: '100%' }}
            to={{
              pathname: `/admin/tabela-velocidade`,
              state: {
                fichaTecnica,
                orcamento,
                orcamentoId: orcamento.id,
                returnToResultado: true,
              },
            }}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Tabela Velocidade</span>
            </Button>
          </Link>

          <Link
            style={{ width: '100%' }}
            to={{
              pathname: `/admin/tabela-cambagem`,
              state: {
                fichaTecnica,
                orcamento,
                orcamentoId: orcamento.id,
                returnToResultado: true,
              },
            }}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Tabela Cambagem</span>
            </Button>
          </Link>
          <Link
            style={{ width: '100%' }}
            to={{
              pathname: `/admin/configuracao-eletrica`,
              state: {
                fichaTecnica,
                orcamentoId: orcamento.id,
                returnToResultado: true,
              },
            }}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Configuração Elétrica</span>
            </Button>
          </Link>
          <Link
            style={{ width: '100%' }}
            to={{
              pathname: `/admin/orcamento-perda-carga`,
              state: {
                fichaTecnica,
                orcamento,
                orcamentoId: orcamento.id,
                acessoLimitadoPerdaCarga,
                returnToResultado: true,
              },
            }}
            hidden={!acessoLimitadoPerdaCarga.temAcesso}
          >
            <Button className="btn-icon btn-2" type="button">
              <span className="btn-inner--text">Tabela Perda de Carga</span>
            </Button>
          </Link>
          <Button hidden>FK CAD</Button>
        </Container>
      </Quadro>
    </Card>
  );
}
