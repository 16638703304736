import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Button,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import ProdutoModel from 'models/Produto';

import api from 'services/api';
import { FaCheckCircle } from 'react-icons/fa';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

type LocationState = {
  produto?: ProdutoModel;
};

interface RegraItemColunaVariavel {
  id: number;
  tabela: string;
  coluna: string;
  tipo: string;
  identificador: string;
  nome: string;
}

interface RegraItemColuna {
  id: number;
  produtoId: number;
  tabela: string;
  coluna: string;
  tipo: string;
  identificador: string;
  nome: string;
}

interface iRegraItemColunaVariavel extends RegraItemColunaVariavel {
  id: number;
  checked: boolean;
  tabela: string;
  coluna: string;
  tipo: string;
  identificador: string;
  nome: string;
}

const VariaveisRegras: React.FC = () => {
  const location = useLocation<LocationState>();
  const { produto } = location.state;

  const [listaVariaveis, setListaVariaveis] = useState(
    [] as RegraItemColunaVariavel[],
  );
  const [listaRegistros, setListaRegistros] = useState(
    [] as iRegraItemColunaVariavel[],
  );

  const [splitArray, setSplitArray] = useState(10);

  const [isSaving, setIsSaving] = useState(false);

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  async function buscarDados() {
    if (!produto) return;
    const responseRegistros = await api.get(
      `/regra-item-coluna/variaveis-produto/${produto.id}`,
    );

    const responseVariaveis = await api.get(`/regra-item-coluna/variaveis`);

    const variaveis = responseVariaveis.data as RegraItemColunaVariavel[];
    const registros = responseRegistros.data as RegraItemColuna[];

    const dadosGravar = variaveis.map((variavel: RegraItemColunaVariavel) => {
      const existe = registros.filter(
        item =>
          item.nome === variavel.nome &&
          item.identificador === variavel.identificador &&
          item.coluna === variavel.coluna &&
          item.tabela === variavel.tabela,
      );

      return {
        ...variavel,
        checked: existe.length > 0,
      } as iRegraItemColunaVariavel;
    });

    setListaVariaveis(variaveis);
    setSplitArray(Math.ceil(dadosGravar.length / 2));
    setListaRegistros(dadosGravar as iRegraItemColunaVariavel[]);
  }

  useEffect(() => {
    if (produto && produto.id) {
      buscarDados();
    }
  }, [produto]);

  async function handleSalvar() {
    try {
      setIsSaving(true);

      toast.loading('Salvando associação de variáveis...');

      const retorno = await api.post(
        `/regra-item-coluna/variaveis-produto/${produto?.id}`,
        [
          ...listaRegistros.map((item: iRegraItemColunaVariavel) => {
            return {
              id: item.id,
              utilizar: item.checked,
            };
          }),
        ],
      );

      const dadosInvalidos = retorno.data.elementosNaoAtualizados as string[];

      toast.dismiss();
      toast.success('Informações salvas com sucesso');

      if (dadosInvalidos && dadosInvalidos.length > 0) {
        Swal.fire({
          html: `<h2>Atenção:</h2>
          <p>Alterações realizadas com sucesso. Mas, uma ou mais variáveis não puderam ser desativadas pois já estão associadas à regras para este produto: </p>
          ${dadosInvalidos.join('<br />')}`,
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonColor: '#d33',
          cancelButtonText: `Fechar`,
        });
      }
    } finally {
      setEnableSaveButton(false);
      setIsSaving(false);
    }
  }

  if (!produto) return <></>;

  function RenderItems(lista: iRegraItemColunaVariavel[]) {
    return lista.map((variavel: iRegraItemColunaVariavel) => {
      return (
        <div key={`var_${variavel.id}`} className="p-2">
          <Row>
            <Col sm="9">
              <strong>{variavel.nome}</strong>
            </Col>
            <Col sm="3">
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  checked={variavel.checked}
                  onChange={ev => {
                    setListaRegistros(
                      listaRegistros.map(item => {
                        if (item.id === variavel.id)
                          return {
                            ...item,
                            checked: ev.target.checked,
                          };
                        return { ...item };
                      }),
                    );
                    setEnableSaveButton(true);
                  }}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
            <Col sm="12">
              <small>
                <i>{`${variavel.tipo}: ${variavel.tabela ?? ''}.${
                  variavel.coluna
                }`}</i>
              </small>
            </Col>
          </Row>
        </div>
      );
    });
  }

  return (
    <>
      <Header showCards={false} />

      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Defina as variáveis de Regras para {produto.nome} (#{' '}
                  {produto.id})
                  <div className="float-right">
                    <Button
                      hidden={!enableSaveButton}
                      disabled={isSaving}
                      size="md"
                      className="btn-icon btn-2"
                      color="primary"
                      type="button"
                      onClick={handleSalvar}
                    >
                      <FaCheckCircle /> Salvar
                    </Button>
                  </div>
                </h3>
                <h6>
                  <i>
                    Para o uso de questões como variáveis nas regras, devem ser
                    definidas na construção das regras
                  </i>
                </h6>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col sm="6">
                    {RenderItems(listaRegistros.slice(0, splitArray))}
                  </Col>
                  <Col sm="6">
                    {RenderItems(listaRegistros.slice(-splitArray))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VariaveisRegras;
