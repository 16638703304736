import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaPen, FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import api from 'services/api';

import TuboModel from '../../../models/ConfiguradorTubo';

const ConfiguradorTubos: React.FC = () => {
  const [tubos, setTubos] = useState([] as TuboModel[]);
  const [tubosLances, setTubosLances] = useState([] as TuboModel[]);
  const [tipoTubo, setTipoTubo] = useState('');

  const [tuboEditing, setTuboEditing] = useState({} as TuboModel);

  const [notificationModalState, setNotificationModalState] = useState(false);
  const [formModalState, setFormModalState] = useState(false);

  async function listaTubos() {
    const response = await api.get('/integracao/tubos');

    setTubos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaTubos();
  }, []);

  async function handleDelete(tuboId: number) {
    async function checkExisteLancesTubo(): Promise<boolean> {
      if (!tuboId) {
        return false;
      }

      const response = await api.get(`/integracao/tubos/${tuboId}/lances`);

      const existeLances = !!response.data.length;

      if (existeLances) {
        setNotificationModalState(true);
      }

      setTubosLances(response.data);

      return existeLances;
    }

    const existeTubos = await checkExisteLancesTubo();

    if (!existeTubos) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/integracao/tubos/${tuboId}`);

          listaTubos();
          Swal.close();
        }
      });
    }
  }

  async function handleInsert() {
    setTuboEditing({} as TuboModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(tubo: TuboModel) {
    setTuboEditing(tubo);
    setFormModalState(!formModalState);
  }

  async function handleSaveTubo() {
    // eslint-disable-next-line prettier/prettier
    const { nome, comprimento, diametro, quantidadeSaidas,  sigla, siglaLimpa} = tuboEditing;

    if (
      !nome ||
      !comprimento ||
      !diametro ||
      !quantidadeSaidas ||
      !sigla ||
      !siglaLimpa
    ) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (tuboEditing.id) {
      await api.put(`/integracao/tubos/${tuboEditing.id}`, {
        nome,
        comprimento,
        diametro,
        quantidadeSaidas,
        sigla,
        siglaLimpa,
        indicTipo: tuboEditing.indicTipo,
      });
    } else {
      await api.post(`/integracao/tubos`, {
        nome,
        comprimento,
        diametro,
        quantidadeSaidas,
        sigla,
        siglaLimpa,
        indicTipo: tuboEditing.indicTipo,
      });
    }

    setFormModalState(false);
    setTuboEditing({} as TuboModel);
    listaTubos();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal alerta */}
            <Modal
              className="modal-dialog-centered modal-danger"
              contentClassName="bg-gradient-danger"
              isOpen={notificationModalState}
              toggle={() => setNotificationModalState(!notificationModalState)}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">
                  {/* Your attention is required */}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="py-3 text-center">
                  <i className="ni ni-bell-55 ni-3x" />
                  <h4 className="heading mt-4">
                    Não é possível remover o tubo!
                  </h4>
                  <p>
                    Esse tubo possui {tubosLances.length} lance(s). Remova-o(s)
                    para prosseguir com a exclusão.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                {/* <Button className="btn-white" color="default" type="button">
                  Entendi
                </Button> */}
                <Button
                  className="text-white ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() =>
                    setNotificationModalState(!notificationModalState)
                  }
                >
                  Fechar
                </Button>
              </div>
            </Modal>

            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Tubo {tuboEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <small>Nome do tubo *</small>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Nome do Tubo"
                            value={tuboEditing.nome}
                            type="text"
                            onChange={text =>
                              setTuboEditing({
                                ...tuboEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Diâmetro (mm) *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaPen />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Diâmetro"
                            min="0"
                            value={
                              tuboEditing.diametro
                                ? Number(tuboEditing.diametro)
                                : ''
                            }
                            type="number"
                            onChange={text =>
                              setTuboEditing({
                                ...tuboEditing,
                                diametro: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Comprimento (m) *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaPen />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Comprimento"
                            min="0"
                            value={
                              tuboEditing.comprimento
                                ? Number(tuboEditing.comprimento)
                                : ''
                            }
                            type="number"
                            onChange={text =>
                              setTuboEditing({
                                ...tuboEditing,
                                comprimento: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Quantidade Saídas *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaPen />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Quantidade Saídas"
                            min="0"
                            value={
                              tuboEditing.quantidadeSaidas
                                ? Number(tuboEditing.quantidadeSaidas)
                                : ''
                            }
                            type="number"
                            onChange={text =>
                              setTuboEditing({
                                ...tuboEditing,
                                quantidadeSaidas: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Sigla *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Sigla do Tubo"
                            value={tuboEditing.sigla}
                            type="text"
                            onChange={text =>
                              setTuboEditing({
                                ...tuboEditing,
                                sigla: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Sigla na composição do código do pivô *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaPen />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            placeholder="Sigla da Composição"
                            min="0"
                            value={
                              tuboEditing.siglaLimpa
                                ? Number(tuboEditing.siglaLimpa)
                                : ''
                            }
                            type="number"
                            onChange={text =>
                              setTuboEditing({
                                ...tuboEditing,
                                siglaLimpa: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Tipo</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo"
                            type="select"
                            value={tuboEditing.indicTipo}
                            onChange={text =>
                              setTuboEditing({
                                ...tuboEditing,
                                indicTipo: text.target.value,
                              })
                            }
                          >
                            <option value="">Tipo...</option>
                            <option value="N">Normal</option>
                            <option value="E">Especial</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveTubo}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Tubos</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  {tubos.map(tubo => (
                    <tr key={tubo.id}>
                      <td className="col">
                        <strong>{tubo.nome}</strong>
                      </td>
                      <td style={{ margin: 'none' }}>
                        <Link
                          to={{
                            pathname: `/admin/tubos-velocidade`,
                            state: { stateTubo: tubo },
                          }}
                        >
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="primary"
                            type="button"
                          >
                            <span>Velocidades</span>
                          </Button>
                        </Link>
                      </td>
                      <td style={{ margin: 'none' }}>
                        <Link
                          to={{
                            pathname: `/admin/lances-tubos`,
                            state: { stateTubo: tubo, stateTipo: 'T' },
                          }}
                        >
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="primary"
                            type="button"
                          >
                            <span>Lances</span>
                          </Button>
                        </Link>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleUpdate(tubo);
                          }}
                        >
                          <FaEdit />
                        </Button>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(tubo.id);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorTubos;
