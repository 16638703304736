import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import * as XLSX from 'xlsx';

import 'antd/dist/antd.css';

import { Mentions } from 'antd';
import UserSmallHeader from 'components/Headers/UserSmallHeader';

import { Checkbox } from '@material-ui/core';
import chunkArray from 'utils/chunckArray';

import api from 'services/api';

import {
  FaCheckCircle,
  FaCopy,
  FaEdit,
  FaFileExcel,
  FaPencilAlt,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';

import Produto from 'models/Produto';
import RegraItem from 'models/RegraItem';
import RegraItemColuna from 'models/RegraItemColuna';
import RegraAgrupador from 'models/RegraAgrupador';
import toast from 'react-hot-toast';

import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import exportToSheet from 'utils/exportToSheet';

import { BuscarRegras, RowFilter, SelectFiltro } from './styles';

type ItemDomsge = {
  DITE_CODIGO: string;
  FABR_CODIGO: string;
  DITE_DGPE_CODIGO: string;
  FABR_EMPR_CODIGO: string;
  DESCRICAO: string;
  QUANTIDADE?: number;
};

type LocationState = {
  produto?: Produto;
};

const ConfiguradorRegras: React.FC = () => {
  const location = useLocation<LocationState>();
  const [inputFiltro, setInputFiltro] = useState('');
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtoFiltroId, setProdutoFiltroId] = useState<number>(0);
  const [codEmpresa, setCodEmpresa] = useState('');
  const isFiltroPorProduto = produtoFiltroId !== 0;
  const [regrasItem, setRegrasItem] = useState<RegraItem[]>([]);
  const [regraItemColunas, setRegraItemColunas] = useState<RegraItemColuna[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [regraItemCopiaOrigem, setRegraItemCopiaOrigem] = useState<any>();
  const [regraAgrupador, setRegraAgrupador] = useState<RegraAgrupador[]>([]);

  const [items, setItems] = useState([]);
  const [ecxelData, setExcelData] = useState<any>();
  const [ecxelFile, setExcelFile] = useState<any>();
  const [ecxelFileError, setExcelFileError] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selecionado, setSelecionado] = useState(false);
  const fileType = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  const [isSaving, setIsSaving] = useState(false);

  async function carregaProdutos() {
    const response = await api.get('/produto');

    setProdutos(response.data);
  }

  async function carregaRegrasAgrupadores() {
    const response = await api.get('/regra-agrupador');

    setRegraAgrupador(response.data);
  }

  function getItemDomsgeObj(regraItem: RegraItem): ItemDomsge | undefined {
    if (!regraItem?.material?.diteCodigo) return undefined;

    const {
      diteCodigo,
      diteDgpeCodigo,
      fabrCodigo,
      fabrEmprCodigo,
      descricao,
      quantidade,
    } = regraItem.material;

    return {
      DITE_CODIGO: diteCodigo,
      DITE_DGPE_CODIGO: diteDgpeCodigo,
      FABR_CODIGO: fabrCodigo,
      FABR_EMPR_CODIGO: fabrEmprCodigo,
      DESCRICAO: descricao,
      QUANTIDADE: quantidade,
    };
  }

  async function carregaRegrasItemColuna() {
    const response = await api.get(`/regra-item-coluna`);

    setRegraItemColunas(response.data);
  }

  async function carregaRegrasItem() {
    setLoading(true);
    const response = await api.get(`/regra-item`);

    const regrasItemResponse = response.data as RegraItem[];

    setRegrasItem(
      regrasItemResponse.map(regraItem => ({
        ...regraItem,
        show: false,
        loadingItens: false,
        inputItemDomsge: `${regraItem.material?.diteCodigo} - ${regraItem.material?.descricao}`,
        itemSelecionado: getItemDomsgeObj(regraItem),
        diteCodigo: regraItem.material?.diteCodigo,
      })),
    );
    setLoading(false);
  }

  const handleFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = ev => {
          setExcelFileError('');
          setExcelFile(ev.target?.result);
          setSelecionado(true);
        };
      } else {
        setExcelFileError('Selecione um arquivo .xlsx');
        setExcelFile(null);
        setSelecionado(false);
      }
    } else {
      setExcelFile(null);
    }
  };

  async function handleUpdate(data: any) {
    try {
      const LIMIT_PER_LOOP = 100;
      const loopMateriais = chunkArray(data, LIMIT_PER_LOOP);
      let loopContador = 0;
      // eslint-disable-next-line no-restricted-syntax
      for await (const loop of loopMateriais) {
        loopContador += loop.length;

        Swal.fire({
          icon: 'info',
          text: `Importando regras ${loopContador} de ${data.length}...`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        await api.put('/regra-item/arquivo-upload-regras/', {
          data: loop,
        });
      }
      Swal.fire('Registro salvo!', '', 'success');
      carregaRegrasItem();
    } finally {
      setExcelData(null);
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (ecxelFile !== null) {
      const workbook = XLSX.read(ecxelFile, { type: 'buffer' });
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      setExcelData(data);
      await handleUpdate(data);
    } else {
      setExcelData(null);
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaProdutos();
    carregaRegrasItemColuna();
    carregaRegrasItem();
    carregaRegrasAgrupadores();
  }, []);

  useEffect(() => {
    if (location.state?.produto) {
      const { produto } = location.state;

      setProdutoFiltroId(produto.id);
      setCodEmpresa(String(produto.codigoEmpresa));
    }
  }, [location.state]);

  function handleSetRegraItemValue(
    regraItem: RegraItem,
    attrName: string,
    value: string | number | boolean | null | ItemDomsge,
  ) {
    setRegrasItem(
      regrasItem.map(regraItem_ =>
        regraItem_.id === regraItem.id
          ? { ...regraItem_, [attrName]: value, regraAlterada: true }
          : regraItem_,
      ),
    );
  }

  function handleChangeAtivo(
    regraItem: RegraItem,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = event.target;
    const value = !!checked;
    handleSetRegraItemValue(regraItem, 'exportar', value);
  }

  async function validaRegra(regraItem: RegraItem): Promise<RegraItem> {
    const response = await api.post('/regra-item/valida-regra', { regraItem });
    return response.data;
  }

  function validaRegraDuplicada(regraItem: RegraItem): boolean {
    if (!regraItemCopiaOrigem) return false;

    const {
      produtoId,
      regraAplicacao,
      regraQuantidade,
      aplicacao,
      atividade,
      inputItemDomsge,
      sequencia,
      observacao,
    } = regraItem;

    const regraItemValoresComparacao = {
      produtoId,
      regraAplicacao,
      regraQuantidade,
      aplicacao,
      atividade,
      inputItemDomsge,
      sequencia,
      observacao,
    };

    const regraItemValoresComparacaoOrigem = {
      produtoId: regraItemCopiaOrigem.produtoId,
      regraAplicacao: regraItemCopiaOrigem.regraAplicacao,
      regraQuantidade: regraItemCopiaOrigem.regraQuantidade,
      aplicacao: regraItemCopiaOrigem.aplicacao,
      atividade: regraItemCopiaOrigem.atividade,
      inputItemDomsge: regraItemCopiaOrigem.inputItemDomsge,
      sequencia: regraItemCopiaOrigem.sequencia,
      observacao: regraItemCopiaOrigem.observacao,
    };

    const valoresIguais =
      Object.entries(regraItemValoresComparacaoOrigem).toString() ===
      Object.entries(regraItemValoresComparacao).toString();

    return valoresIguais;
  }

  function inputEncontrouFiltro(texto?: string | null) {
    if (!texto) return false;

    return texto.toLowerCase().includes(inputFiltro.toLowerCase());
  }

  function retornaRegrasItemFiltradas() {
    const regrasItemProduto = isFiltroPorProduto
      ? regrasItem.filter(regraItem => regraItem.produtoId === produtoFiltroId)
      : regrasItem;

    return inputFiltro
      ? regrasItemProduto.filter(
          regraItem =>
            inputEncontrouFiltro(String(regraItem.id)) ||
            inputEncontrouFiltro(regraItem?.regraAplicacao) ||
            inputEncontrouFiltro(regraItem?.regraQuantidade) ||
            inputEncontrouFiltro(regraItem?.aplicacao) ||
            inputEncontrouFiltro(String(regraItem?.atividade)) ||
            inputEncontrouFiltro(regraItem?.inputItemDomsge) ||
            inputEncontrouFiltro(String(regraItem?.sequencia)) ||
            inputEncontrouFiltro(String(regraItem?.observacao)),
        )
      : regrasItemProduto;
  }

  function handleAdicionar() {
    setRegrasItem([
      {
        id: 0,
        materialId: 0,
        ativo: 0,
        show: false,
        loadingItens: false,
        inputItemDomsge: '',
        itemSelecionado: null,
        diteCodigo: null,
        produtoId: produtoFiltroId,
        sintaxeQuantidadeValida: true,
        sintaxeAplicacaoValida: true,
      },
      ...regrasItem,
    ]);
  }

  function handleExportToSheet() {
    const regrasItemToExport = retornaRegrasItemFiltradas();

    const dadosPlanilha = regrasItemToExport
      .filter(item => item.exportar)
      .map(regraItem => {
        const {
          produtoId,
          itemSelecionado,
          regraAgrupadorId,
          regraQuantidade,
          regraAplicacao,
          sequencia,
          atividade,
          ativo,
          observacao,
          itemListadoProposta,
        } = regraItem;

        return {
          id: regraItem.id,
          produtoId: produtos.find(produto => produto.id === produtoId)?.id,
          dite_codigo: itemSelecionado?.DITE_CODIGO,
          descricao: itemSelecionado?.DESCRICAO,
          aplicacao: regraAgrupador.find(
            regraAgrup => regraAgrup.id === regraAgrupadorId,
          )?.nome,
          regraAgrupadorId: regraAgrupador.find(
            regraAgrup => regraAgrup.id === regraAgrupadorId,
          )?.id,
          regraQuantidade,
          regraAplicacao,
          sequencia,
          atividade,
          observacao,
          ativo,
          itemListadoProposta,
        };
      });

    const nomeArquivo = 'Regras';

    exportToSheet(dadosPlanilha, nomeArquivo);
  }

  function RegrasItem() {
    const regrasItemProdutoFiltro = retornaRegrasItemFiltradas();

    return (
      <Card
        className="shadow"
        style={{ maxHeight: 860, maxWidth: '100%', overflowX: 'scroll' }}
      >
        <Form
          role="form"
          className="py-0 float-right"
          onSubmit={e => handleSubmit(e)}
        >
          <FormGroup className="form-control-label float-right mt-3">
            <input
              id="imagem-valor"
              accept=".csv, .xlsx"
              type="file"
              onChange={e => handleFile(e)}
            />
            <Button
              size="sm"
              disabled={!selecionado || isSaving}
              className="btn btn-outline-primary"
              type="submit"
            >
              <FaFileExcel />
              <span className="btn-inner--text">Importar</span>
            </Button>
          </FormGroup>
        </Form>
        <Table className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th colSpan={3}>
                <Checkbox
                  checked={selectAll}
                  onChange={(e: any) => {
                    setSelectAll(e.target.checked);
                    setRegrasItem(
                      regrasItem.map(regraItem_ => {
                        return {
                          ...regraItem_,
                          exportar: e.target.checked,
                          regraAlterada: true,
                        };
                      }),
                    );
                  }}
                  color="primary"
                  className="ml--1"
                  title="Selecionar todos"
                />
              </th>
              <th colSpan={3}>Id</th>
              <th colSpan={3}>Item</th>
              <th colSpan={3}>Regra</th>
            </tr>
          </thead>
          <tbody>
            {regrasItemProdutoFiltro.map(regraItem => {
              const { id } = regraItem;

              const regraItemState = regrasItem.find(
                regraItem_ => regraItem_.id === id,
              );

              if (!regraItemState) return <></>;

              const { regraQuantidade, exportar, itemSelecionado, produtoId } =
                regraItemState;

              const regraItemColunasFiltradas =
                produtoId !== 0
                  ? regraItemColunas.filter(
                      coluna =>
                        coluna.produtoId === produtoId || !coluna.produtoId,
                    )
                  : regraItemColunas;

              const itemSelecionadoText = itemSelecionado?.DITE_CODIGO
                ? `${itemSelecionado?.DITE_CODIGO} - ${itemSelecionado?.DESCRICAO}`
                : '';

              const listaSugestoesQuestao = [] as string[];
              const listaSugestoesCont = [] as string[];

              regraItemColunasFiltradas.forEach(coluna => {
                const isTipoColuna = coluna.tipo === 'coluna';
                const isTipoContador = coluna.tipo === 'contador';

                if (isTipoColuna) {
                  listaSugestoesQuestao.push(`${coluna.nome}#`);
                  return;
                }

                if (isTipoContador) {
                  listaSugestoesCont.push(`${coluna.nome}`);
                }
              });

              return (
                <tr key={regraItem.id}>
                  <td className="p-1" colSpan={3}>
                    <Col sm="2">
                      <Checkbox
                        checked={!!exportar}
                        onChange={e => handleChangeAtivo(regraItem, e)}
                        color="primary"
                      />
                    </Col>
                  </td>
                  <td className="pl-lg-4" colSpan={3}>
                    <InputGroup size="sm-2">
                      <span className="mb-0 text-xs">#{regraItem.id}</span>
                    </InputGroup>
                  </td>
                  <td className="pl-lg-4" colSpan={3}>
                    <InputGroup size="sm-2">
                      <span className="mb-0 text-xs">
                        {itemSelecionadoText}
                      </span>
                    </InputGroup>
                  </td>
                  <td className="pl-lg-4" colSpan={3}>
                    <InputGroup size="sm-2">
                      <span className="mb-0 text-xs">
                        {regraQuantidade ?? ''}
                      </span>
                    </InputGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }

  return (
    <>
      <UserSmallHeader />
      <Col>
        <RowFilter>
          <SelectFiltro
            value={produtoFiltroId ?? 0}
            onChange={value => setProdutoFiltroId(Number(value))}
          >
            <SelectFiltro.Option value={0}>Todos produtos</SelectFiltro.Option>
            {produtos.map(produto => (
              <SelectFiltro.Option key={produto.id} value={produto.id}>
                {produto.nome}
              </SelectFiltro.Option>
            ))}
          </SelectFiltro>

          <BuscarRegras
            id="outlined-basic"
            label="Filtrar por regras"
            variant="outlined"
            size="small"
            value={inputFiltro}
            onChange={event => setInputFiltro(event.target.value)}
          />

          <Button
            size="sm"
            className="btn btn-outline-success"
            type="button"
            onClick={() => handleExportToSheet()}
          >
            <FaFileExcel />
            <span className="btn-inner--text">Exportar</span>
          </Button>
        </RowFilter>
      </Col>

      <div style={{ maxHeight: 860, maxWidth: '100%', overflowX: 'scroll' }}>
        <Col className="mt-5">
          {loading ? (
            <Row>
              <Col className="text-center my-3">
                <strong className="text-muted">Carregando...</strong>
              </Col>
            </Row>
          ) : (
            RegrasItem()
          )}
        </Col>
      </div>
    </>
  );
};

export default ConfiguradorRegras;
