import { Typography as TypographyMui } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Typography = styled(TypographyMui)`
  color: #000;
  text-align: center;
`;
