import React, { createContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  Button,
} from 'reactstrap';

// eslint-disable-next-line import/extensions
import config from 'config';
import { useQuery } from 'react-query';
import api from 'services/api';
import ProdutoModel from 'models/Produto';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { PropostaStatus } from 'models/PropostaStatus';
import Pessoa from 'models/Pessoa';
import Usuario from 'models/Usuario';
import toast from 'react-hot-toast';
import { useAuth } from 'hooks/auth';
import AutocompleteLocalData from 'components/AutocompleteLocalData';
import PropostaFunilEstagio from './components/PropostaFunilEstagio';
import PropostaMesValor from './components/PropostaMesValor';
import PropostaMapaConfiguracoes from './components/PropostaMapaConfiguracoes';
import NavegacaoRapida from './components/NavegacaoRapida';
import './styles.css';
import FiltroProps from './models/filtro.props';

interface DashboardContext {
  dataInicio?: any;
  dataFim?: any;
  filtros: FiltroProps;
  setFiltros: (value: any) => void;
  reloadAll: boolean;
  setReloadAll: (value: boolean) => void;
  produtos?: ProdutoModel[];
  status?: PropostaStatus[];
  refreshMapa: boolean;
  setRefreshMapa: (value: boolean) => void;
  refreshMesAno: boolean;
  setRefreshMesAno: (value: boolean) => void;
  refreshFunilEstagio: boolean;
  setRefreshFunilEstagio: (value: boolean) => void;
}

export const DashboardContext = createContext({} as DashboardContext);

const Dashboard: React.FC = () => {
  // Isso aqui pode ser configurável depois, talvez por acesso ou por usuário
  // Mas vamos manter a lógica pré-programada
  // Criar um novo widget, colocando ele nesse array, na ordem correta.
  // Depois, podemos pensar em deixar dinâmico.
  const components = [
    {
      id: 'PropostaMapaConfiguracoes',
      componente: PropostaMapaConfiguracoes,
      sm: 12,
      className: 'mb-4 mt-4',
      active: true,
      cacheTime: 240000,
    },
    {
      id: 'NavegacaoRapida',
      componente: NavegacaoRapida,
      sm: 2,
      className: 'mb-4 mt-4',
      active: false,
      cacheTime: 240000,
    },
    {
      id: 'PropostaFunilEstagio',
      componente: PropostaFunilEstagio,
      sm: 4,
      className: 'mb-4 mt-4',
      active: true,
      cacheTime: 240000,
    },
    {
      id: 'PropostaMesValor',
      componente: PropostaMesValor,
      sm: 8,
      className: 'mb-4 mt-4',
      active: true,
      cacheTime: 240000,
    },
  ];

  const { usuario }: { usuario: Usuario } = useAuth();

  // Últimos 12 meses
  const varDataInicio = new Date();
  varDataInicio.setDate(1);
  varDataInicio.setMonth(varDataInicio.getMonth() - 11);

  const varDataFim = new Date();

  const [filtroPeriodo, setFiltroPeriodo] = useState('I');
  const [filtroStatus, setFiltroStatus] = useState('T');
  const [filtroProduto, setFiltroProduto] = useState('T');
  const [filtroCliente, setFiltroCliente] = useState('0');
  const [filtroUsuario, setFiltroUsuario] = useState(String(usuario.id));
  const [checkFinalizados, setCheckFinalizados] = useState(false);

  const [reloadAll, setReloadAll] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [refreshMapa, setRefreshMapa] = useState(false);
  const [refreshMesAno, setRefreshMesAno] = useState(false);
  const [refreshFunilEstagio, setRefreshFunilEstagio] = useState(false);

  async function sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const [filtros, setFiltros] = useState<FiltroProps>({
    cliente: filtroCliente,
    usuario: filtroUsuario,
    produto: filtroProduto,
    status: filtroStatus,
    periodo: filtroPeriodo,
  });

  // Descomentar as duas linhas abaixo para considerar o fim do mês
  // varDataFim.setMonth(varDataFim.getMonth() + 1);
  // varDataFim.setDate(0);

  const dataInicio = varDataInicio.toISOString().split('T')[0];
  const dataFim = varDataFim.toISOString().split('T')[0];

  // Ano corrente
  // const dataInicio = `${new Date().getFullYear()}-01-01`;
  // const dataFim = `${new Date().getFullYear()}-12-31`;

  // Podemos definir, nesse nível global, a forma de busca de dados, bem como outros filtros globais que vão ter depois.
  // Bastando, então, enviar as informações para dentro dos componentes.

  // formato datas: YYYY-MM-DD

  const { data: cacheRetornoProdutos } = useQuery(
    `dashboard-produtos`,
    async () => {
      const response = await api.get('/produto');

      return response.data as ProdutoModel[];
    },
    { staleTime: 240000 },
  );

  const { data: cacheListaStatus } = useQuery(
    `proposta-status`,
    async () => {
      async function getStatus(): Promise<PropostaStatus[]> {
        const response = await api.get('/proposta/status');
        return response.data;
      }

      const dataStatus = await getStatus();
      return dataStatus;
    },
    { staleTime: 1000 * 60 * 10 },
  );

  const { data: cacheListaUsuarios } = useQuery(
    `proposta-usuarios_${usuario.id}`,
    async () => {
      async function getUsuarios(): Promise<Usuario[]> {
        const response = await api.get('/usuario/hierarquia');

        return response.data;
      }

      const dataStatus = await getUsuarios();
      return dataStatus;
    },
    { staleTime: 1000 * 60 },
  );

  const { data: cacheListaCliente } = useQuery(
    `proposta-cliente`,
    async () => {
      async function getLista(): Promise<Pessoa[]> {
        const response = await api.get('/pessoa');

        return response.data;
      }

      const data = await getLista();
      return data;
    },
    { staleTime: 1000 * 60 * 10 },
  );

  async function filtrar() {
    toast.loading('Filtrando resultados', { position: 'top-right' });

    setFiltros({
      cliente: filtroCliente,
      usuario: filtroUsuario ?? String(usuario.id),
      produto: filtroProduto,
      status: filtroStatus,
      periodo: checkFinalizados ? 'F' : 'I',
    });

    setRefreshMapa(true);
    setRefreshMesAno(true);
    setRefreshFunilEstagio(true);

    await sleep(3000);
    toast.dismiss();
  }

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      filtrar();
    }
  }, [firstLoad]);

  function handleSelecionar(event: any, val: any) {
    setFiltroCliente(val && val.id ? (val.id as string) : '');
  }

  function handleSelecionarUsuario(event: any, val: any) {
    setFiltroUsuario(val && val.id ? (val.id as string) : '');
  }

  return (
    <>
      <div className={`header bg-gradient-${config.class} pb-8 pt-5 pt-7`}>
        <Container fluid>
          <div className="header-body">
            <Row>
              <div
                style={{
                  display: 'flex',
                  float: 'left',
                  fontFamily: 'Open Sans',
                  fontSize: '50px',
                  color: '#fff',
                  alignItems: 'botom',
                  marginTop: '40px',
                  marginLeft: '40px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    fontSize: '50px',
                    color: '#fff',
                  }}
                >
                  Bem vindo!
                  <br />
                  <div
                    style={{
                      width: '100%',
                      fontSize: '15px',
                      color: '#fff',
                      fontStyle: 'italic',
                    }}
                  >
                    {`Período: ${new Date(varDataInicio).toLocaleDateString(
                      'BR',
                    )} até ${new Date(varDataFim).toLocaleDateString('BR')}`}
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col sm="12">
                <Card className="card-stats mb-4 mt-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <Col sm="3">
                        <FormControl fullWidth>
                          <InputLabel> </InputLabel>
                          <AutocompleteLocalData
                            label="Cliente *"
                            data={
                              cacheListaCliente?.map((item: Pessoa) => {
                                return {
                                  id: item.id,
                                  descricao: item.razaoSocial,
                                };
                              }) as any[]
                            }
                            valorSelecionado={Number(filtroCliente ?? 0)}
                            onChange={handleSelecionar}
                          />
                        </FormControl>
                      </Col>
                      <Col sm="3">
                        <FormControl fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={`${filtroStatus}`}
                            label="Status"
                            autoWidth
                            onChange={event =>
                              setFiltroStatus(event.target.value as string)
                            }
                          >
                            <MenuItem value="T">Todos</MenuItem>
                            {cacheListaStatus?.map((item: PropostaStatus) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.descricao}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <FormControlLabel
                            hidden={
                              filtroStatus !== 'T' &&
                              String(filtroStatus) !== '3'
                            }
                            control={
                              <Checkbox
                                checked={checkFinalizados}
                                onChange={event =>
                                  setCheckFinalizados(event.target.checked)
                                }
                                style={{ transform: 'scale(0.7)' }}
                                color="primary"
                              />
                            }
                            label={
                              <div style={{ fontSize: 12 }}>
                                Usar Data de Aprovação
                              </div>
                            }
                          />
                        </FormControl>
                      </Col>
                      <Col sm="2">
                        <FormControl fullWidth>
                          <InputLabel>Produto</InputLabel>
                          <Select
                            value={`${filtroProduto}`}
                            label="Produto"
                            autoWidth
                            onChange={event =>
                              setFiltroProduto(event.target.value as string)
                            }
                          >
                            <MenuItem value="T">Todos</MenuItem>
                            {cacheRetornoProdutos
                              ?.filter(item => !item.disabledAt)
                              ?.map(item => {
                                return (
                                  <MenuItem value={item.id}>
                                    {item.nome}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col sm="2">
                        <FormControl fullWidth>
                          <InputLabel> </InputLabel>
                          <AutocompleteLocalData
                            label="Usuário"
                            hideSequence
                            data={
                              cacheListaUsuarios
                                ?.filter(item => item.isAtivo === true)
                                ?.map((item: Usuario) => {
                                  return {
                                    id: item.id,
                                    descricao: item.nome,
                                  };
                                }) as any[]
                            }
                            valorSelecionado={Number(filtroUsuario ?? 0)}
                            onChange={handleSelecionarUsuario}
                          />
                        </FormControl>
                      </Col>
                      <Col sm="2">
                        <Button
                          className="btn-icon btn-2 mt-2 float-right"
                          color="success"
                          type="button"
                          onClick={() => filtrar()}
                        >
                          Filtrar
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <DashboardContext.Provider
                value={{
                  dataInicio,
                  dataFim,
                  filtros,
                  setFiltros,
                  produtos: cacheRetornoProdutos ?? [],
                  status: cacheListaStatus ?? [],
                  reloadAll,
                  setReloadAll,
                  refreshMapa,
                  setRefreshMapa,
                  refreshMesAno,
                  setRefreshMesAno,
                  refreshFunilEstagio,
                  setRefreshFunilEstagio,
                }}
              >
                {components
                  .filter(item => item.active === true)
                  .map(item => {
                    const DynamicComponent = item.componente;

                    if (DynamicComponent) {
                      return (
                        <>
                          <Col sm={`${item.sm}`}>
                            <DynamicComponent
                              className={`${item.className}`}
                              cacheTime={item.cacheTime}
                            />
                          </Col>
                        </>
                      );
                    }
                    return <></>;
                  })}
              </DashboardContext.Provider>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
