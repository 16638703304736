export interface iColunas {
  variavel: string;
  produtoId: number;
}

export interface iImagens {
  id: number;
  base64: string;
  base64Small: string;
  descricao: string;
  variavel: string;
  produtoId: number[];
}

export interface iReport {
  id: number;
  descricao: string;
  nome: string;
  produtoId: number[];
  tipo: string;
  ativo: boolean;
  ignorarPrimeiraPagina: boolean;
  mostrarPrimeiraPagina: boolean;
  reportContentPaiId?: number;
}

export interface iReportEdit {
  id: number;
  descricao: string;
  nome: string;
  tipo: string;
}

export const tipoReport = [
  { tipo: 'R', label: 'Relatório', selecionavel: false },
  { tipo: 'P', label: 'Principal', selecionavel: true },
  { tipo: 'C', label: 'Conteúdo', selecionavel: true },
  { tipo: 'F', label: 'Rodapé', selecionavel: true },
  { tipo: 'H', label: 'Cabeçalho', selecionavel: true },
  { tipo: 'B', label: `Marca d'água`, selecionavel: true },
];
