import InputSelect from 'components/InputSelect';

import BancoFinanciamento from 'models/BancoFinanciamento';
import Parametros from 'models/Parametros';
import { PropostaDescontoComissao } from 'models/PropostaDescontoComissao';
import { useContext, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import api from 'services/api';
import { PropostaContext } from 'views/pages/Proposta';
import PropostaLinhaCredito from 'models/PropostaLinhaCredito';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import InputMoeda from './components/InputMoeda';
import InputMoedaPercentual from './components/InputMoedaPercentual';

import InputPercentual from './components/InputPercentual';
import { MateriaisContext } from '../..';

interface comboList {
  name: string;
  value: string;
}

interface iListaTaxaFlat {
  banco: string;
  formato: string;
  taxa: number;
}

export default function Precificacao(): JSX.Element {
  const {
    propostaVersao,
    formatoMaoDeObra,
    carregaPropostaVersao,
    meioPagamentos,
    meioPagamentoIdSelecionado,
    setMeioPagamentoIdSelecionado,
    acessoLimitado,
    isObraExterior,
    setTriggerMaterialCheck,
    isIsentoComissao,
  } = useContext(PropostaContext);

  const {
    listaDescontoComissao,
    atualizarPrecificacao,
    setAtualizarPrecificacao,
    valorLicenciamento,
    setValorLicenciamento,
    valorTopografia,
    setValorTopografia,
    valorOutros,
    setValorOutros,
    isLicenciamentoPercentual,
    setIsLicenciamentoPercentual,
    isTopografiaPercentual,
    setIsTopografiaPercentual,
    isOutrosPercentual,
    setIsOutrosPercentual,
    isFretePercentual,
    setIsFretePercentual,
    valorDolarCambial,
    setValorDolarCambial,
    valorDespesaDespachante,
    setValorDespesaDespachante,
    valorSeguroFrete,
    setValorSeguroFrete,
    valorDolarParametro,
    setValorDolarParametro,
    percentualAssistenciaTecnica,
    setPercentualAssistenciaTecnica,
    percentualTaxaFlat,
    setPercentualTaxaFlat,
    percentualComissao,
    setPercentualComissao,
    percentualAjusteComissao,
    setPercentualAjusteComissao,
    percentualIndicadorNegocio,
    setPercentualIndicadorNegocio,
    percentualComissaoReceber,
    setPercentualComissaoReceber,
    percentualServicos,
    setPercentualServicos,
    percentualICMS,
    setPercentualICMS,
    percentualPISCofins,
    setPercentualPISCofins,
    percentualIPI,
    setPercentualIPI,
    percentualST,
    setPercentualST,
    isSaveRequired,
    setIsSaveRequired,
    salvandoDifal,
    setSalvandoDifal,
    idBancoFinanciamentoSelecionado,
    setIdBancoFinanciamentoSelecionado,
    linhaCreditoSelecionado,
    setLinhaCreditoSelecionado,
    formatoBancoSelecionado,
    setFormatoBancoSelecionado,
    isPercentualLicenciamento,
    setIsPercentualLicenciamento,
    isPercentualTopografia,
    setIsPercentualTopografia,
    isPercentualOutros,
    setIsPercentualOutros,
    isPercentualFrete,
    setIsPercentualFrete,
    valorFrete,
    setValorFrete,
    isFinanciado,
    setIsFinanciado,
    motivoOutros,
    setMotivoOutros,
  } = useContext(MateriaisContext);
  const [difal, setDifal] = useState('do Cliente');

  const [bancosFinanciamento, setBancoFinanciamento] = useState<
    BancoFinanciamento[]
  >([]);

  const [listaTaxaFlat, setListaTaxaFlat] = useState<iListaTaxaFlat[]>([]);

  const [propostaLinhaCredito, setPropostaLinhaCredito] = useState<comboList[]>(
    [],
  );
  const valoresTipos = [
    {
      label: 'Licenciamento',
      isPercentual: isLicenciamentoPercentual,
      valorState: valorLicenciamento,
      setValor: setValorLicenciamento,
    },
    {
      label: 'Topografia',
      isPercentual: isTopografiaPercentual,
      valorState: valorTopografia,
      setValor: setValorTopografia,
    },
    {
      label: 'Outros',
      isPercentual: isOutrosPercentual,
      valorState: valorOutros,
      setValor: setValorOutros,
    },
    {
      label: 'Frete',
      isPercentual: isFretePercentual,
      valorState: valorFrete,
      setValor: setValorFrete,
    },
  ];

  const [
    acessoLimitadoVariaveisComerciais,
    setAcessoLimitadoVariaveisComerciais,
  ] = useState(true);
  const [acessoLimitadoControleTaxaFlat, setAcessoLimitadoControleTaxaFlat] =
    useState(true);

  async function buscarListaDescontos() {
    const lista: PropostaDescontoComissao[] = listaDescontoComissao;
  }

  useEffect(() => {
    if (listaDescontoComissao && listaDescontoComissao.length > 0)
      buscarListaDescontos();
  }, [listaDescontoComissao]);

  const isFinanciados = meioPagamentos.find(
    meioPagamento => meioPagamento.id === meioPagamentoIdSelecionado,
  )?.financiado;

  setIsFinanciado(Boolean(isFinanciados));

  const difalOptions: comboList[] = [
    { name: 'do Cliente', value: 'do Cliente' },
    { name: 'da Fockink', value: 'da Fockink' },
  ];

  const formatosBanco: comboList[] = [
    { name: 'BROTO', value: 'BROTO' },
    { name: 'CONVENCIONAL', value: 'CONVENCIONAL' },
    { name: 'ESTEIRA', value: 'ESTEIRA' },
    { name: 'FCO', value: 'FCO' },
    { name: 'CDC', value: 'CDC' },
    { name: 'FEIRA', value: 'FEIRA' },
  ];

  function Subtitle({
    name,
    className,
  }: {
    name: string;
    className?: string;
  }): JSX.Element {
    return (
      <div className={className}>
        <Row className="ml-2">
          <h6 className="heading-small text-muted mb-1">{name}</h6>
        </Row>
        <hr className="mt-2" />
      </div>
    );
  }

  const variaveisComerciais = [
    {
      label: 'Assistência Técnica',
      value: percentualAssistenciaTecnica,
      onChange: setPercentualAssistenciaTecnica,
      acessoLimitadoVariaveisComerciais,
    },
    {
      label: 'Taxa Flat',
      value: percentualTaxaFlat,
      onChange: setPercentualTaxaFlat,
      disabled: acessoLimitadoControleTaxaFlat,
    },
    {
      label: 'Comissão',
      value: percentualComissao,
      onChange: setPercentualComissao,
      acessoLimitadoVariaveisComerciais,
      disabled: isIsentoComissao && acessoLimitadoVariaveisComerciais,
    },
    {
      label: 'Ajuste de Comissão',
      value: percentualAjusteComissao,
      onChange: setPercentualAjusteComissao,
      acessoLimitadoVariaveisComerciais,
    },
    {
      label: 'Indicador de Negócios',
      value: percentualIndicadorNegocio,
      onChange: setPercentualIndicadorNegocio,
      acessoLimitadoVariaveisComerciais,
    },
    {
      label: 'Comissões a Receber',
      value: percentualComissaoReceber,
      onChange: setPercentualComissaoReceber,
      acessoLimitadoVariaveisComerciais,
    },

    {
      label: 'M.O. / Serviços',
      value: percentualServicos,
      onChange: setPercentualServicos,
      hide: formatoMaoDeObra !== 'P',
    },
  ];

  const variaveisTributarias = [
    {
      label: 'ICMS',
      value: percentualICMS,
      onChange: setPercentualICMS,
    },
    {
      label: 'PIS/Cofins',
      value: percentualPISCofins,
      onChange: setPercentualPISCofins,
    },
    {
      label: 'IPI',
      value: percentualIPI,
      onChange: setPercentualIPI,
    },
    {
      label: 'ST',
      value: percentualST,
      onChange: setPercentualST,
    },
  ];

  async function carregarParametros() {
    const response = await api.get(`/parametros`);

    const parametros = response.data as Parametros;

    setValorDolarParametro(parametros.valorDolar ?? 0);
  }

  useEffect(() => {
    carregarParametros();
  }, []);

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 61,
      },
    });
    setAcessoLimitadoVariaveisComerciais(response.data);

    const responseTaxa = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 74,
      },
    });
    setAcessoLimitadoControleTaxaFlat(responseTaxa.data);
  }

  async function getTaxaFlat() {
    const response = await api.get('integracao/banco/flat', {});
    setListaTaxaFlat(response.data as iListaTaxaFlat[]);
  }

  useEffect(() => {
    getTaxaFlat();
    getAcessoLimitadoPrograma();
  }, []);

  function getIsPercentual(valorTipoLabelInput: string) {
    return (
      valoresTipos.find(valorTipo => valorTipo.label === valorTipoLabelInput)
        ?.isPercentual || false
    );
  }

  useEffect(() => {
    // console.log({ fretePercentual: isFretePercentual });
    const percentualLicenciamento = getIsPercentual('Licenciamento');
    const percentualTopografia = getIsPercentual('Topografia');
    const percentualOutros = getIsPercentual('Outros');
    const percentualFrete = getIsPercentual('Frete');
    setIsPercentualLicenciamento(true);
    setIsPercentualTopografia(true);
    setIsPercentualOutros(percentualOutros);
    setIsPercentualFrete(percentualFrete);
  }, []);

  useEffect(() => {
    // console.log({ fretePercentual: isFretePercentual });
    const percentualLicenciamento = getIsPercentual('Licenciamento');
    const percentualTopografia = getIsPercentual('Topografia');
    const percentualOutros = getIsPercentual('Outros');
    const percentualFrete = getIsPercentual('Frete');
    setIsPercentualLicenciamento(true);
    setIsPercentualTopografia(true);
    setIsPercentualOutros(percentualOutros);
    setIsPercentualFrete(percentualFrete);
  }, [
    isFretePercentual,
    isTopografiaPercentual,
    isOutrosPercentual,
    isLicenciamentoPercentual,
  ]);

  const { data: queryBancos } = useQuery(
    `banco-financiamento`,
    async () => {
      const response = await api.get(`/integracao/banco`);
      return response.data;
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    if (queryBancos) {
      setBancoFinanciamento(queryBancos);
    }
  }, [queryBancos]);

  const { data: queryPropostaLinhaCredito } = useQuery(
    `proposta-linha-credito`,
    async () => {
      const response = await api.get(`/proposta-linha-credito`);
      return response.data as PropostaLinhaCredito[];
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    if (queryPropostaLinhaCredito) {
      setPropostaLinhaCredito(
        queryPropostaLinhaCredito.map((item: PropostaLinhaCredito) => {
          return { name: item.nome, value: String(item.id) } as comboList;
        }),
      );
    }
  }, [queryPropostaLinhaCredito]);

  useEffect(() => {
    if (!isFinanciado) {
      setIdBancoFinanciamentoSelecionado('');
      setFormatoBancoSelecionado('');
      setLinhaCreditoSelecionado(0);
      setPercentualTaxaFlat(0);
    }
  }, [isFinanciado]);

  useEffect(() => {
    setDifal(propostaVersao.difalPorConta || 'do Cliente');
    setMeioPagamentoIdSelecionado(propostaVersao?.meioPagamentoId);
    setIdBancoFinanciamentoSelecionado(propostaVersao.banco || '');
    setLinhaCreditoSelecionado(propostaVersao.propostaLinhaCreditoId ?? 0);
    setFormatoBancoSelecionado(propostaVersao.formatoBanco || '');

    if (isObraExterior) {
      if (!propostaVersao.valorDolar) {
        setIsSaveRequired(true);
        setValorDolarCambial(valorDolarParametro || 0);
      } else {
        setValorDolarCambial(propostaVersao.valorDolar || 0);
      }
    }
  }, [propostaVersao, valorDolarParametro]);

  async function alteraDifal(novoValor: string) {
    try {
      setSalvandoDifal(true);
      const response = await api.post(
        `/proposta/versao/${propostaVersao.id}/precificacao/calculo-irr`,
        { difalPorConta: novoValor },
      );
      setDifal(novoValor);
      const { data } = response;
      if (!data) return;
      setPercentualTaxaFlat(data.percentualTaxaFlat);
      setPercentualICMS(data.percentualICMS);
      setPercentualPISCofins(data.percentualPISCofins);
      setPercentualIPI(data.percentualIPI);
      setPercentualST(data.percentualST);
      carregaPropostaVersao();
    } finally {
      setSalvandoDifal(false);
      setTriggerMaterialCheck(true);
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col className="mb-2" xs="6" lg="3">
              <Label className="form-control-label">Meio de pagamento</Label>
              <InputSelect
                options={meioPagamentos.map(meioPagamento => ({
                  value: meioPagamento.id,
                  name: meioPagamento.descricao,
                }))}
                value={meioPagamentoIdSelecionado}
                onChange={text =>
                  setMeioPagamentoIdSelecionado(Number(text.target.value))
                }
              />
            </Col>
            {isFinanciado && (
              <>
                <Col className="mb-2" xs="6" lg="3">
                  <Label className="form-control-label">Banco</Label>
                  <InputSelect
                    options={bancosFinanciamento.map(bancoFinanciamento => ({
                      value: bancoFinanciamento.codigo,
                      name: bancoFinanciamento.razao_social,
                    }))}
                    value={idBancoFinanciamentoSelecionado}
                    onChange={text => {
                      const banco = text.target.value;
                      setPercentualTaxaFlat(
                        listaTaxaFlat.find(
                          item =>
                            item.banco === banco &&
                            item.formato === formatoBancoSelecionado,
                        )?.taxa ?? 0,
                      );
                      setIdBancoFinanciamentoSelecionado(banco);
                    }}
                  />
                </Col>
                <Col className="mb-2" xs="6" lg="3">
                  <Label className="form-control-label">Formato</Label>
                  <InputSelect
                    options={formatosBanco}
                    value={formatoBancoSelecionado}
                    onChange={text => {
                      const valor = text.target.value;
                      setPercentualTaxaFlat(
                        listaTaxaFlat.find(
                          item =>
                            item.banco === idBancoFinanciamentoSelecionado &&
                            item.formato === valor,
                        )?.taxa ?? 0,
                      );
                      setFormatoBancoSelecionado(valor);
                    }}
                  />
                </Col>
                <Col className="mb-2" xs="6" lg="3">
                  <Label className="form-control-label">Linha de Crédito</Label>
                  <InputSelect
                    options={propostaLinhaCredito.filter((item: any) => {
                      if (
                        formatoBancoSelecionado === 'FCO' ||
                        formatoBancoSelecionado === 'CDC'
                      ) {
                        return item.name === formatoBancoSelecionado;
                      }

                      return true;
                    })}
                    value={linhaCreditoSelecionado}
                    onChange={text =>
                      setLinhaCreditoSelecionado(Number(text.target.value))
                    }
                  />
                </Col>
              </>
            )}
          </Row>
          {/* }
          <Row>
            <Col className="mb-2 float-right">
              <Button
                className="btn-icon btn-2 float-right mt-3"
                color="danger"
                type="button"
                outline
                size="sm"
                disabled={atualizandoInfosBancarias}
                onClick={salvaInfosBancarias}
              >
                {atualizandoInfosBancarias ? 'Salvando...' : 'Salvar'}
              </Button>
            </Col>
          </Row>
                  { */}
        </CardBody>
      </Card>
      <Card className="shadow p-3">
        <Subtitle name="Variáveis comerciais" />
        <Row className="px-2">
          <Col className="mb-2" xs="12" md="6">
            <InputMoedaPercentual
              label="Licenciamento"
              value={valorLicenciamento}
              setValue={setValorLicenciamento}
              onClickToggle={() =>
                setIsLicenciamentoPercentual(isPercentualLicenciamento)
              }
              isPercentual
              acessoLimitadoVariaveisComerciais={false}
              campoLiberado
              ocultabotaoPercent
              validaCampo="Licenciamento"
            />
          </Col>
          <Col className="mb-2" xs="12" md="6">
            <InputMoedaPercentual
              label="Topografia"
              value={valorTopografia}
              setValue={setValorTopografia}
              onClickToggle={() =>
                setIsTopografiaPercentual(isPercentualTopografia)
              }
              isPercentual
              acessoLimitadoVariaveisComerciais={false}
              campoLiberado
              ocultabotaoPercent
              validaCampo="Topografia"
            />
          </Col>
          <Col className="mb-2" xs="12" md="6">
            <InputMoedaPercentual
              label="Outros"
              value={valorOutros}
              setValue={setValorOutros}
              onClickToggle={() => setIsOutrosPercentual(!isOutrosPercentual)}
              isPercentual={isOutrosPercentual}
              acessoLimitadoVariaveisComerciais={false}
              campoLiberado
            />
            {Number(valorOutros) > 0 && (
              <Row className="form-control-label mt-3">
                <Col xs="6" md="7">
                  <Label className="form-control-label float-right mt-3">
                    Motivo
                  </Label>
                </Col>
                <Col xs="6" md="5">
                  <Input
                    className="input-group-alternative"
                    type="text"
                    value={motivoOutros}
                    onChange={text => setMotivoOutros(text.target.value)}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col className="mb-2" xs="12" md="6">
            <InputMoedaPercentual
              label="Frete"
              value={Number(valorFrete)}
              setValue={setValorFrete}
              onClickToggle={() => setIsFretePercentual(!isFretePercentual)}
              isPercentual
              acessoLimitadoVariaveisComerciais={
                acessoLimitadoVariaveisComerciais
              }
              ocultabotaoPercent
            />
          </Col>

          {variaveisComerciais.map(campo => {
            if (campo.hide) return <></>;
            return (
              <Col key={campo.label} className="mb-2" xs="12" md="6">
                <InputPercentual
                  disabled={
                    campo.acessoLimitadoVariaveisComerciais ||
                    campo.disabled ||
                    campo.label === 'M.O. / Serviços'
                  }
                  label={campo.label}
                  value={campo.value}
                  onChange={campo.onChange}
                />
              </Col>
            );
          })}

          <Col className="mb-2" xs="12" md="6">
            <InputMoeda
              label="Seguro de Frete"
              value={valorSeguroFrete}
              onChange={setValorSeguroFrete}
              hide={!isObraExterior}
            />
          </Col>

          <Col className="mb-2" xs="12" md="6">
            <InputMoeda
              label="Despesas Despachante"
              value={valorDespesaDespachante}
              onChange={setValorDespesaDespachante}
              hide={!isObraExterior}
            />
          </Col>

          <Col className="mb-2" xs="12" md="6">
            <InputMoeda
              label="Valor Atual do Dólar Parametrizado"
              value={valorDolarParametro}
              disabled
              hide={!isObraExterior}
            />
          </Col>

          <Col className="mb-2" xs="12" md="6">
            <InputMoeda
              label="Valor Dólar Cambial na Proposta"
              value={valorDolarCambial}
              disabled={acessoLimitadoVariaveisComerciais}
              onChange={setValorDolarCambial}
              hide={!isObraExterior}
              isSaveRequired={isSaveRequired}
            />
          </Col>
        </Row>

        <Subtitle className="mt-4" name="Variáveis tributárias" />
        <Row className="px-2">
          {variaveisTributarias.map(campo => (
            <Col key={campo.label} className="mb-2" xs="12" md="6" lg="4">
              <InputPercentual
                disabled
                label={campo.label}
                value={campo.value}
                onChange={campo.onChange}
              />
            </Col>
          ))}
        </Row>

        <Row>
          <Col />
          {/* }
          <Col xs="3" md="2">
            <Button
              className="btn-icon btn-2 float-right w-100 mt-3"
              color="danger"
              type="button"
              outline
              size="sm"
              disabled={salvandoPercentuais || salvandoDifal}
              onClick={salvaPercentuais}
            >
              {salvandoPercentuais ? 'Salvando...' : 'Salvar'}
            </Button>
          </Col>
          { */}
        </Row>
        <hr className="mt-4" />
        <Row>
          <Col />
          <Col className="mb-2" xs="6" lg="4">
            <Label className="form-control-label">DIFAL por conta</Label>
            <InputSelect
              options={difalOptions}
              value={difal}
              disabled={salvandoDifal || acessoLimitado}
              hideNoOption
              onChange={text => alteraDifal(text.target.value)}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
