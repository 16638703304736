import FichaTecnica from 'models/FichaTecnica';

import { Card, Col } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';

import Quadro from '../../../../atoms/Quadro';
import { Row } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function AlturasManometricas(props: Props): JSX.Element {
  const { fichaTecnica } = props;

  const quantidadeSaidasSimples = Number(
    fichaTecnica?.quantidadeSaidasSimples ?? 0,
  );
  const quantidadeSaidasDuplas = Number(
    fichaTecnica?.quantidadeSaidasDuplas ?? 0,
  );

  const quantidadeTotalSaidaAspersores =
    quantidadeSaidasSimples + quantidadeSaidasDuplas;

  const TITULO = 'ASPERSOR DO PIVÔ';

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Row>
          <Campo
            sm="6"
            label="Quant. Aspersores Simples"
            valor={quantidadeSaidasSimples > 0 ? quantidadeSaidasSimples : '0'}
          />
          <Campo
            sm="6"
            label="Quant. Aspersores Duplo"
            valor={quantidadeSaidasDuplas > 0 ? quantidadeSaidasDuplas : '0'}
          />
        </Row>

        <Row>
          <Col sm="6" />

          <Campo
            sm="6"
            label="Quant. total saída Aspersores"
            valor={quantidadeTotalSaidaAspersores}
          />
        </Row>
      </Quadro>
    </Card>
  );
}

export default AlturasManometricas;
