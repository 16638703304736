/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { useState, ReactNode, useEffect } from 'react';

import { NavLink as NavLinkRRD, Link, useLocation } from 'react-router-dom';

import classnames from 'classnames';

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap';

import { useAuth } from 'hooks/auth';

import { FaLockOpen, FaMapPin, FaUnlock } from 'react-icons/fa';
import Usuario from '../../models/Usuario';

const Sidebar: ReactNode = ({
  toggleSidenav,
  sidenavOpen,
  routes,
  logo,
  rtlActive,
}: any) => {
  const [state, setState] = useState({} as any);
  const location = useLocation();

  const { usuario }: { usuario: Usuario; signOut: () => void } = useAuth();

  const labelUsuario = usuario?.apelido ?? usuario.username;

  function getCollapseInitialState(pRoutes: any) {
    for (let i = 0; i < pRoutes.length; i++) {
      if (pRoutes[i].collapse && getCollapseInitialState(pRoutes[i].views)) {
        return true;
      }
      if (location.pathname.indexOf(pRoutes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  const getCollapseStates = (pRoutes: any) => {
    let initialState = {};

    pRoutes.map((route: any) => {
      if (route.collapse) {
        initialState = {
          [route.state]: getCollapseInitialState(route.views),
          ...getCollapseStates(route.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  useEffect(() => {
    setState(getCollapseStates(routes));
  }, []);

  function activeRoute(routeName: string) {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  const onMouseClickEvent = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      // document.body.classList.add('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-show');
    } else {
      // document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-show');
    }
    toggleSidenav();
  };

  /* const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };

  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }; */

  const closeSidenav = () => {
    if (sidenavOpen) onMouseClickEvent();
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  const createLinks = (pRoutes: any) => {
    return pRoutes.map((prop: any, key: number) => {
      if (prop.redirect || prop.invisible) {
        return null;
      }

      if (prop.collapse) {
        // Caso não tenha registros, retorna false.
        if (!prop.views) return <></>;

        const st = {} as any;

        st[prop.state] = !state[prop.state];

        return (
          <NavItem key={prop.path || prop.state}>
            <NavLink
              href="#"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                active: getCollapseInitialState(prop.views),
              })}
              onClick={e => {
                e.preventDefault();
                if (sidenavOpen) onMouseClickEvent();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} title={prop.name} />
                  <span className="nav-link-text" hidden={sidenavOpen}>
                    {prop.name}
                  </span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon" title={prop.name}>
                    {prop.miniName}
                  </span>
                  <span className="sidenav-normal" hidden={sidenavOpen}>
                    {prop.name}
                  </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }

      return (
        <NavItem
          className={activeRoute(prop.layout + prop.path)}
          key={prop.path}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} title={prop.name} />
                <span className="nav-link-text" hidden={sidenavOpen}>
                  {prop.name}
                </span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal" hidden={sidenavOpen}>
                  {prop.name}
                </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div
        className="sidenav-header d-flex align-items-center"
        onClick={onMouseClickEvent}
        title="Clique para fixar ou soltar o menu"
      >
        {logo ? (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            <img
              alt={logo.imgAlt}
              src={logo.imgSrc}
              style={{
                maxWidth: '100%',
                maxHeight: '2rem',
                verticalAlign: 'middle',
                borderStyle: 'none',
                overflowClipMargin: 'content-box',
                overflow: 'clip',
              }}
            />
          </div>
        ) : (
          <div
            className={classnames('sidenav-toggler d-none d-xl-block', {
              active: sidenavOpen,
            })}
          >
            Menu
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        )}
      </div>

      <div className="navbar-inner">
        <Collapse navbar isOpen>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={`${
        !sidenavOpen || window.innerWidth < 800 ? 'sidenav' : ''
      } navbar-vertical navbar-expand-xs navbar-light bg-white d-print-none ${
        rtlActive ? '' : 'fixed-left'
      }`}
      // onMouseEnter={onMouseEnterSidenav}
      // onMouseLeave={onMouseLeaveSidenav}
    >
      {scrollBarInner}
    </Navbar>
  );
};

export default Sidebar;
