import { Label as LabelR } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Label = styled(LabelR)`
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
  color: #2a2a2a;
`;
