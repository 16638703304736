import styled from 'styled-components';

export const Th = styled.th`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e9ecef;
`;

export const Td = styled.td`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;
