/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { ReactNode, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  FormGroup,
  InputGroup,
  Modal,
  Card,
  CardBody,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';

import api from 'services/api';
import Swal from 'sweetalert2';
import FilaItensModel from '../../models/FilaItens';
import GrupoUsuariosModel from '../../models/GrupoUsuarios';

type Props = {
  formModalState?: any;
  setFormModalState?: any;
  labelUsuario?: any;
};

export default function ModalReport({
  formModalState,
  setFormModalState,
  labelUsuario,
}: Props): JSX.Element {
  const location = useLocation();
  const [filaItens, setFilaItens] = useState([] as FilaItensModel[]);
  const [filaItensEditing, setFilaItensEditing] = useState(
    {} as FilaItensModel,
  );
  const [grupos, setGrupos] = useState([] as GrupoUsuariosModel[]);
  const [gruposEditing, setGruposEditing] = useState({} as GrupoUsuariosModel);
  const [isSaving, setIsSaving] = useState(false);
  const [NroConfigStorage, setNroConfigStorage] = useState('');
  const locationState = location.state as any;

  const contextConfiguracao = locationState?.telaConfig || false;

  async function listaGrupos() {
    const response = await api.get('/integracao/grupo-atendimento');

    setGrupos(response.data);
  }

  useEffect(() => {
    if (String(sessionStorage.getItem('TelaConfig')) === 'Sim') {
      setNroConfigStorage(String(sessionStorage.getItem('NroConfigStorage')));
      setFilaItensEditing({
        ...filaItensEditing,
        nroConfiguracao: Number(sessionStorage.getItem('NroConfigStorage')),
      });
    } else {
      setNroConfigStorage('');
    }
  }, []);

  useEffect(() => {
    listaGrupos();
  }, []);

  async function gerarFila() {
    // eslint-disable-next-line prettier/prettier
    const { assunto, descricao, nroConfiguracao } = filaItensEditing;
    const { grupoId } = gruposEditing;

    if (!assunto || !descricao || !grupoId) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }
    setIsSaving(true);
    try {
      await api.post(`/integracao/fila-itens`, {
        assunto: filaItensEditing.assunto,
        descricao: filaItensEditing.descricao,
        grupoId: gruposEditing.grupoId,
        nroConfiguracao: filaItensEditing.nroConfiguracao,
        modalAjuda: true,
        reportar: true,
      });
      toast.success('Registro concluído');
      Swal.close();
    } catch (error: any) {
      setIsSaving(false);
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
      return;
    }
    setIsSaving(false);
    setFormModalState(!formModalState);
  }

  return (
    <>
      {/* Modal ajuda */}
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  Informe o assunto e a descrição para gerar uma solicitação de
                  atendimento.
                </small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <small>Grupos de usuários *</small>
                  <InputGroup className="input-group-alternative input-group">
                    <Input
                      placeholder="Grupos de Usuários *"
                      type="select"
                      value={gruposEditing.grupoId || ''}
                      onChange={event =>
                        setGruposEditing({
                          ...gruposEditing,
                          grupoId: Number(event.target.value),
                        })
                      }
                    >
                      <option value="">Selecione...</option>
                      {grupos.map(perfil => (
                        <option value={perfil.grupoId}>{perfil.nome}</option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-comments-o" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Configuração"
                      value={
                        NroConfigStorage !== ''
                          ? NroConfigStorage
                          : filaItensEditing.nroConfiguracao
                      }
                      type="number"
                      onChange={text =>
                        setFilaItensEditing({
                          ...filaItensEditing,
                          nroConfiguracao: Number(text.target.value),
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-comments-o" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Assunto"
                      value={filaItensEditing.assunto}
                      type="text"
                      onChange={text =>
                        setFilaItensEditing({
                          ...filaItensEditing,
                          assunto: text.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i
                          className="ni ni-align-center"
                          style={{
                            alignSelf: 'start',
                            marginTop: '0.3rem',
                          }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Descrição"
                      value={filaItensEditing.descricao}
                      rows="3"
                      type="textarea"
                      name="descricao"
                      onChange={text =>
                        setFilaItensEditing({
                          ...filaItensEditing,
                          descricao: text.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    disabled={isSaving}
                    color="primary"
                    type="button"
                    onClick={() => gerarFila()}
                  >
                    {isSaving ? `Reportando` : `Reportar`}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
}
