import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import {
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaCheckCircle,
  FaTable,
} from 'react-icons/fa';
import api from 'services/api';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { iTabelaGenerica } from 'models/TabelaGenerica';
import { Link } from 'react-router-dom';
import './style.css';

interface editTabelaGenerica extends iTabelaGenerica {
  labelValido: boolean;
}

const TabelaGenerica: React.FC = () => {
  const [listaTabelaGenerica, setListaTabelaGenerica] = useState(
    [] as iTabelaGenerica[],
  );
  const [mostrarDesativados, setMostrarDesativados] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [tabelaEditing, setTabelaEditing] = useState({} as editTabelaGenerica);
  const [formModalState, setFormModalState] = useState(false);

  async function validarRegra(variavelBuscar: string) {
    if (!variavelBuscar) return false;

    // Temporariametne desativando o registro.
    // Na segunda etapa vamos ter uma visão melhor do uso de regras.

    return true;
    /* try {
      setIsValidatingTag(true);
      const response = await api.get(
        `/regra/validar-variavel?variavel=${variavelBuscar}&tabela=${tabelaEditing.id}`,
      );
      setIsValidatingTag(false);
      return response.data.valido ?? false;
    } catch (e: any) {
      setIsValidatingTag(false);
      return false;
    } */
  }

  async function carregarTabelaGenerica() {
    const response = await api.get('/tabela-generica');
    setListaTabelaGenerica(response.data);
  }

  async function carregarDados() {
    setLoading(true);
    await carregarTabelaGenerica();
    setLoading(false);
  }

  useEffect(() => {
    carregarDados();
  }, []);

  async function ativarDesativar(tabelaId: number, ativar: boolean) {
    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      await api.delete(`/tabela-generica/${tabelaId}?ativo=${ativar}`);
      toast.success('Feito!', {});

      carregarTabelaGenerica();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleAtivar(tabelaId: number) {
    Swal.fire({
      title: `Deseja reativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(tabelaId, true);
        Swal.close();
      }
    });
  }

  async function handleDelete(tabelaId: number) {
    // Aqui preciso validar se pode ser desativado.
    // Se não puder, alerta o usuário
    async function checkPodeDesativar(): Promise<boolean> {
      if (!tabelaId) {
        return false;
      }

      const response = await api.get(
        `/tabela-generica/${tabelaId}/validar-exclusao`,
      );

      const existeLances = response.data.valido ?? false;

      return existeLances;
    }

    const podeDesativar = await checkPodeDesativar();

    if (podeDesativar) {
      Swal.fire({
        title: `Deseja desativar o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          ativarDesativar(tabelaId, false);
          Swal.close();
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: `Não é possível desativar o registro. Ele está sendo usado por questões. Primeiro desative essas questões antes de desativar a tabela`,
      });
    }
  }

  async function handleInsert() {
    setTabelaEditing({
      labelValido: false,
      disponibilizarRegras: false,
    } as editTabelaGenerica);
    setFormModalState(!formModalState);
  }

  function handleUpdate(tabela: iTabelaGenerica) {
    setTabelaEditing({ ...tabela, labelValido: true } as editTabelaGenerica);
    setFormModalState(!formModalState);
  }

  async function handleSaveTabela() {
    const { nome, disponibilizarRegras, descricao, labelValido, labelRegras } =
      tabelaEditing;

    if (!nome || (disponibilizarRegras && !labelRegras)) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    // Se não for válido, faz a validação
    if (disponibilizarRegras && !labelValido) {
      const variavel = labelRegras ?? '';

      const valido = await validarRegra(variavel);
      if (!valido) {
        toast.error(
          'A variável de regra informada não é válida. Por favor, escolha outra!',
        );
        return;
      }
    }

    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      if (tabelaEditing.id) {
        await api.put(`/tabela-generica/${tabelaEditing.id}`, {
          nome,
          disponibilizarRegras,
          labelRegras,
          descricao,
        });
      } else {
        await api.post(`/tabela-generica`, {
          nome,
          disponibilizarRegras,
          labelRegras,
          descricao,
        });
      }

      toast.success('Feito!', {});
      Swal.close();

      setFormModalState(false);
      setTabelaEditing({} as editTabelaGenerica);
      carregarTabelaGenerica();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  disabled={isSaving || loading}
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome da Tabela*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nome da Tabela"
                            value={tabelaEditing.nome}
                            type="text"
                            onChange={text =>
                              setTabelaEditing({
                                ...tabelaEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Descrição</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={tabelaEditing.descricao ?? ''}
                            type="text"
                            onChange={text =>
                              setTabelaEditing({
                                ...tabelaEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <small>Disponibilizar em Regras?</small>
                        <br />
                        <label className="custom-toggle">
                          <input
                            checked={tabelaEditing.disponibilizarRegras}
                            type="checkbox"
                            onChange={text =>
                              setTabelaEditing({
                                ...tabelaEditing,
                                disponibilizarRegras: text.target.checked,
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          />
                        </label>
                      </FormGroup>
                      <FormGroup
                        className="mb-3"
                        hidden={!tabelaEditing.disponibilizarRegras}
                      >
                        <small>Variável para uso em Regras *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Variável para uso em Regras"
                            value={tabelaEditing.labelRegras ?? ''}
                            type="text"
                            onChange={text =>
                              setTabelaEditing({
                                ...tabelaEditing,
                                labelRegras: text.target.value,
                                labelValido: false,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving || loading}
                          onClick={handleSaveTabela}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">Lista de Tabelas Genéricas</h3>
                  </Col>
                  <Col sm="3">
                    <FormControlLabel
                      className="float-right"
                      control={
                        <Checkbox
                          checked={mostrarDesativados}
                          onChange={event =>
                            setMostrarDesativados(event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Mostrar desativados"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Identificação nas Regras</th>
                    <th hidden={!mostrarDesativados}>Ativo</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {listaTabelaGenerica
                    .filter(item =>
                      !mostrarDesativados ? item.ativo === true : true,
                    )
                    .map(item => (
                      <tr key={item.id}>
                        <td className="py-2">{item.nome}</td>
                        <td className="py-2">{item.descricao ?? ''}</td>
                        <td className="py-2">
                          {item.disponibilizarRegras
                            ? item.labelRegras ?? ''
                            : ''}
                        </td>
                        <td className="py-2" hidden={!mostrarDesativados}>
                          <div
                            className={`badge badge-${
                              item.ativo ? 'success' : 'danger'
                            }`}
                          >
                            {item.ativo ? 'Sim' : 'Não'}
                          </div>
                        </td>
                        <td>
                          <div className="float-right">
                            {item.ativo && (
                              <>
                                <Link
                                  className="mr-2"
                                  to={{
                                    pathname: `/admin/tabela-generica-coluna`,
                                    state: {
                                      stateTabela: item,
                                    },
                                  }}
                                >
                                  <Button
                                    size="sm"
                                    disabled={isSaving || loading}
                                    className="btn-icon"
                                    color="info"
                                    type="button"
                                  >
                                    <FaTable /> Colunas
                                  </Button>
                                </Link>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="warning"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleUpdate(item);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="danger"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </>
                            )}
                            {!item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="info"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleAtivar(item.id);
                                  }}
                                >
                                  <FaCheckCircle />
                                </Button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TabelaGenerica;
