/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useEffect, useState, useMemo, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import JoditEditor from 'jodit-react';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter, {
  AlignAction,
  DeleteAction,
  ImageSpec,
} from 'quill-blot-formatter';
import { Card, Row, Col, Container, Form, CardBody, Button } from 'reactstrap';

import api from 'services/api';
import Header from 'components/Headers/Header.js';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaMeioPagamento } from 'models/PropostaMeioPagamento';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import { ProdutoProposta } from 'models/ProdutoProposta';
import TemplateAtaModel from '../../../../models/TemplateAta';
// import 'assets/vendor/quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import { DivSub } from '../style';

const LayoutAta: React.FC = () => {
  const [templateAtaEditing, setTemplateAtaEditing] = useState(
    {} as TemplateAtaModel,
  );
  const [texto, setTexto] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  // const [valorTotalGeral, setValorTotalGeral] = useState(0); Valor total por configuração
  const editor = useRef(null);
  const [content, setContent] = useState('');

  // const formats = ['align', 'float'];
  Quill.register('modules/blotFormatter', BlotFormatter);

  async function listaDadosEditor() {
    const response = await api.get(`/layout-ata/`);

    setTexto(response.data[0].htmlBase);
    setTemplateAtaEditing(response.data[0]);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    // listaDadosAta();
    listaDadosEditor();
  }, []);

  async function handleSave() {
    // eslint-disable-next-line prettier/prettier
    const { id } = templateAtaEditing;

    if (!templateAtaEditing.id) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }
    setIsSaving(true);

    try {
      if (templateAtaEditing.id) {
        await api.put(`/layout-ata/${templateAtaEditing.id}`, {
          htmlBase: texto,
        });
      }

      toast.success('Registro concluído');
      setTemplateAtaEditing({} as TemplateAtaModel);
    } finally {
      setIsSaving(false);
      listaDadosEditor();
    }
  }

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <Form>
            <Row>
              <Col className="col">
                <CardBody>
                  <DivSub>
                    <Row>
                      <Col>
                        <JoditEditor
                          ref={editor}
                          value={texto}
                          onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={setTexto}
                        />
                      </Col>
                    </Row>
                  </DivSub>
                </CardBody>
              </Col>
            </Row>
            <div className="space-items mt-2 mb-2 mr-4">
              <Row>
                <Col>
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={isSaving}
                    onClick={handleSave}
                  >
                    {isSaving ? 'Salvando...' : 'Salvar'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </Container>
    </>
  );
};

export default LayoutAta;
