import styled from 'styled-components';
import { Label as LabelR } from 'reactstrap';

export const TrTotal = styled.tr`
  color: #fff;
  background: #2f75b6;
`;

export const Label = styled(LabelR)`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: -5px;
`;
