import AutocompleteLocalData from 'components/AutocompleteLocalData';
import Pessoa from 'models/Pessoa';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';

interface ModalParam {
  pessoaId: string;
  modalState: boolean;
  toggle: () => void;
  onSave: (itemSelecionado: string) => void;
}

interface iPessoaUsar {
  CODIGO: string;
  NOME_FANTASIA: string;
  RAZAO_SOCIAL: string;
}

export default function ModalPessoaGrupo({
  pessoaId,
  modalState,
  toggle,
  onSave,
}: ModalParam): JSX.Element {
  // Buscar a pessoa

  const [lista, setLista] = useState<iPessoaUsar[]>([]);
  const [itemSelecionado, setItemSelecionado] = useState('');

  async function getListaPessoas() {
    const response = await api.get(
      `/integracao/pessoa/${pessoaId}/listar-grupo`,
    );
    setLista(response.data as iPessoaUsar[]);
  }

  // Montar lista
  useEffect(() => {
    if (modalState === true) {
      getListaPessoas();
      setItemSelecionado(pessoaId);
    }
  }, [modalState]);

  function handleSelecionar(event: any, val: any) {
    setItemSelecionado(val && val.id ? (val.id as string) : '');
  }

  async function handleConfirmarOperacao() {
    if (!itemSelecionado) {
      toast.error('É obrigatório selecionar um cliente na lista');
      return;
    }

    if (itemSelecionado !== pessoaId) {
      const resultado = await Swal.fire({
        title: `Deseja prosseguir com essa alteração do cliente da proposta?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, prosseguir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Fechar`,
      });

      if (resultado.isConfirmed) {
        onSave(itemSelecionado);
        toggle();
      }
    } else {
      // Os valores eram iguais, não será necessário uma confirmação ou até mesmo salvar
      onSave(pessoaId);
      toggle();
    }
  }

  if (!lista) return <></>;

  return (
    <>
      {lista && (
        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={modalState}
          toggle={toggle}
          scrollable
        >
          <CardHeader className="bg-transparent">
            <h3 className="mb-0">Alterar Cliente da Proposta</h3>
          </CardHeader>
          <ModalBody>
            <Row className="mt-0">
              <Col sm="12" className="mt-0">
                <span>
                  <small>
                    Utilize o campo abaixo para trocar o cliente da proposta.
                    São listados apenas clientes do mesmo grupo empresarial do
                    cliente atual.
                  </small>
                </span>
                <AutocompleteLocalData
                  data={
                    lista?.map((item: iPessoaUsar) => {
                      return {
                        id: `${item.CODIGO}`,
                        descricao: `${
                          item.NOME_FANTASIA
                            ? `${item.NOME_FANTASIA}`
                            : `${item.RAZAO_SOCIAL}`
                        }`,
                      };
                    }) as any[]
                  }
                  label="Cliente *"
                  valorSelecionado={itemSelecionado}
                  onChange={handleSelecionar}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col sm="6">
                <Button
                  className="btn-icon"
                  color="default"
                  type="button"
                  style={{ width: '100%' }}
                  onClick={() => {
                    toggle();
                  }}
                >
                  Cancelar
                </Button>
              </Col>
              <Col sm="6">
                <Button
                  className="btn-icon"
                  color="primary"
                  type="button"
                  style={{ width: '100%' }}
                  onClick={() => {
                    handleConfirmarOperacao();
                  }}
                >
                  Atualizar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
