import styled from 'styled-components';
import List from '@material-ui/core/List';
import { Col, Label } from 'reactstrap';

interface PropsQuestao {
  tipo?: string;
}

export const ButtonsRightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2.1rem 0 0;
`;

export const ColLabelItem = styled(Col)`
  padding-left: 0.7rem;
`;

export const LabelItem = styled(Label)`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
`;

export const MaterialListContainer = styled.div`
  padding: 0.6rem;
`;

export const MaterialRow = styled(List as any)`
  border-top: 1px solid #e8e8e8;
  border-radius: 5px;
  background-color: ${props => (props.itemPai ? `#f3f3f4` : `#fff`)};
`;

export const LabelOrcamento = styled(Label)`
  color: #8898aa;
  font-size: 1.4rem;
  font-weight: 600;
`;

export const MaterialContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
`;

export const ColQuestaoTitulo = styled.div<PropsQuestao>`
  flex-grow: 1;
  padding: 2px 1.6rem 0 1.6rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  background-color: #2f75b6;
`;

export const LabelQuestao = styled.label<PropsQuestao>`
  font-size: 0.71rem;
  font-weight: 600;
  color: #8898aa;
`;

export const ColQuestao = styled.div<PropsQuestao>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  vertical-align: middle;
`;

export const LabelQuestaoTitulo = styled.label<PropsQuestao>`
  font-size: 0.8125rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
`;
