import { ReactNode, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from 'reactstrap';

import UserHeader from 'components/Headers/UserHeader';
import { useHistory, useLocation } from 'react-router-dom';

import api from 'services/api';
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import getInitials from 'utils/getInitials';
import { deepPurple } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
import Perfil from 'models/Perfil';

import { FaArrowCircleUp, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import { Toast, ToastAsync } from 'components/Toast';
import toast from 'react-hot-toast';
import UsuarioPerfil from 'models/UsuarioPerfil';
import Usuario from '../../../models/Usuario';

import { PerfilRow, ButtonsRightCol } from './styles';

type LocationState = {
  usuario?: Usuario;
};

type UsuarioLista = {
  codUsuario: string;
  descUsuario: string;
  ativo: boolean;
};

const CadastroUsuario: ReactNode = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();

  const [usuario, setUsuario] = useState({} as Usuario);

  const [perfis, setPerfis] = useState([] as Perfil[]);
  const [perfilSelecionado, setPerfilSelecionado] = useState({} as Perfil);

  const [perfisUsuario, setPerfisUsuario] = useState([] as UsuarioPerfil[]);

  const [listaUsuarios, setListaUsuarios] = useState([] as UsuarioLista[]);

  const [usuarioSge, setUsuarioSge] = useState({} as UsuarioLista);

  // dados básicos
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [apelido, setApelido] = useState('');
  const [usernameDomSGE, setUsernameDomSGE] = useState('');
  const [isIsentoComissao, setIsIsentoComissao] = useState(false);

  const existeUsuario = !!usuario?.nome;

  function setDadosUsuario(usuarioData: Usuario) {
    setUsuario(usuarioData);

    setNome(usuarioData.nome);
    setEmail(usuarioData.email);
    setUsername(usuarioData.username);
    setApelido(usuarioData.apelido ?? '');
    setUsernameDomSGE(usuarioData.usernameDomSGE ?? '');
    setIsIsentoComissao(usuarioData.isIsentoComissao);
  }

  async function carregarUsuarioSGE(codUsuario: string) {
    const response = await api.get(`/integracao/usuario/${codUsuario}`);

    const registro = response.data as UsuarioLista;

    if (
      !registro.ativo &&
      listaUsuarios.filter(item => item.ativo === false).length === 0
    ) {
      setListaUsuarios([
        ...listaUsuarios,
        {
          descUsuario: registro.descUsuario,
          codUsuario: registro.codUsuario,
          ativo: false,
        },
      ]);
    }

    setUsuarioSge(registro);
  }

  async function carregaUsuario() {
    const usuarioLocation = location.state?.usuario ?? null;

    if (!usuarioLocation) {
      return;
    }

    setDadosUsuario(usuarioLocation);

    const response = await api.get(`/usuario/${usuarioLocation.id}`);

    if (response.data.usernameDomSGE && response.data.usernameDomSGE !== '') {
      await carregarUsuarioSGE(response.data.usernameDomSGE);
    }

    setDadosUsuario(response.data);
  }

  async function carregaPerfis() {
    if (!existeUsuario) {
      return;
    }

    const response = await api.get(`/perfil`);

    setPerfis(response.data);
  }

  async function carregaPerfisUsuario() {
    if (!existeUsuario) {
      return;
    }

    // const response = await api.get(`/usuario/${usuario.id}/perfil`);

    const response = await api.get(`/acesso/usuario-perfil`);

    setPerfisUsuario(
      response.data.filter((x: any) => x.usuarioId === usuario.id),
    );
  }

  async function carregaListaUsuarios() {
    const response = await api.get(`/integracao/usuario`);

    setListaUsuarios(response.data as UsuarioLista[]);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaListaUsuarios();
  }, []);

  useEffect(() => {
    if (listaUsuarios.length) carregaUsuario();
  }, [listaUsuarios]);

  useEffect(() => {
    carregaPerfis();
    carregaPerfisUsuario();
  }, [usuario]);

  async function salvaUsuario() {
    const usuarioObject = {
      nome,
      email,
      username,
      apelido,
      usernameDomSGE,
      isIsentoComissao,
    };

    let response = null;

    const title = existeUsuario
      ? 'Atualizando...'
      : `Cadastrando ${username}...`;

    Swal.fire({
      icon: 'info',
      title,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    if (usuario?.id) {
      response = await api.put(`/usuario/${usuario.id}`, usuarioObject);
    } else {
      response = await api.post(`/usuario`, usuarioObject);
    }

    if (usernameDomSGE) {
      Swal.fire({
        icon: 'info',
        title: 'Salvando informações de acesso no DomSGE',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await api.post(
        `/integracao/usuario/liberar-acesso/${usernameDomSGE}`,
        {},
      );
    }

    setUsuario(response.data);

    Swal.close();
  }

  async function handleFileRead(event: any) {
    async function convertBase64(file: File) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = error => {
          reject(error);
        };
      });
    }

    const file = event.target.files[0];

    const base64 = await convertBase64(file);
  }

  async function handleAtiva(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja reativar o usuário ${usuario.username}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/usuario/${usuario.id}/validaUsuario`, {
            isAtivo: true,
          });
          Swal.close();
          toast.success('Usuário reativado');
          carregaListaUsuarios();
        }
      });
    } else {
      carregaListaUsuarios();
    }
  }

  async function handleDesativa(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Desativar usuário ${usuario.username}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/usuario/${usuario.id}/validaUsuario`, {
            isAtivo: false,
          });
          Swal.close();
          toast.success('Usuário desativado');
          carregaListaUsuarios();
        }
      });
    } else {
      carregaListaUsuarios();
    }
  }

  async function handleAdicionaPerfil() {
    if (!perfilSelecionado?.id) {
      return;
    }

    const jaFoiAdicionado = perfisUsuario.find(
      usuarioPerfil => usuarioPerfil.perfilId === perfilSelecionado.id,
    );

    if (jaFoiAdicionado) {
      return;
    }

    ToastAsync.fire({
      icon: 'info',
      title: 'Associando...',
    });

    await api.post(`/acesso/usuario-perfil`, {
      usuarioId: usuario.id,
      perfilId: perfilSelecionado.id,
    });

    carregaPerfisUsuario();

    Toast.close();
  }

  async function handleExcluiPerfil(usuarioPerfil: UsuarioPerfil) {
    const novoArrayPerfilUsuario = perfisUsuario.filter(
      x => x !== usuarioPerfil,
    );

    setPerfisUsuario(novoArrayPerfilUsuario);

    await api.delete(`/acesso/usuario-perfil/${usuarioPerfil.id}`);
  }

  async function gerarNovaSenha() {
    Swal.fire({
      icon: 'info',
      text: `Gerando nova senha...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    const usuarioObject = {
      email: usuario.email,
    };

    await api.put(`/usuario/recuperar-senha`, usuarioObject);

    toast.success('Registro atualizado');
    Swal.close();
  }

  function questionaNovaSenha() {
    Swal.fire({
      title: `Gerar uma nova senha para ${usuario.username}?`,
      text: `Usuário receberá um e-mail com uma senha gerada aleatoriamente.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, continuar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(result => {
      if (result.isConfirmed) {
        gerarNovaSenha();
      }
    });
  }

  let styleDesativado = {};
  if (usuario.isAtivo) {
    styleDesativado = {};
  } else {
    styleDesativado = {
      color: '#A9A9A9',
      textDecoration: 'line-through',
    };
  }

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row>
          {existeUsuario && (
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#a">
                        {usuario?.imagem ? (
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={usuario.imagem}
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: deepPurple[800],
                              width: 180,
                              height: 180,
                              marginTop: '-3rem',
                              marginLeft: '-2.2rem',
                              marginBottom: '-7rem',
                            }}
                          >
                            <Label style={{ fontSize: '4rem' }}>
                              {getInitials(usuario.nome)}
                            </Label>
                          </Avatar>
                        )}
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        {/* <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">10</span>
                        <span className="description">Photos</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                      </div> */}
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3 style={{ ...styleDesativado }}>{nome}</h3>
                    <Col className="mt-4">
                      {existeUsuario && usuario.isAtivo ? (
                        <Button
                          size="sm"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDesativa(usuario);
                          }}
                        >
                          Desativar
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          color="primary"
                          type="button"
                          onClick={() => {
                            handleAtiva(usuario);
                          }}
                        >
                          Ativar
                        </Button>
                      )}
                    </Col>
                    {/* <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Bucharest, Romania
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Solution Manager - Creative Tim Officer
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    University of Computer Science
                  </div>
                  <hr className="my-4" />
                  <p>
                    Ryan — the name taken by Melbourne-raised, Brooklyn-based
                    Nick Murphy — writes, performs and records all of his own
                    music.
                  </p>
                  <a href="#" onClick={e => e.preventDefault()}>
                    Show more
                  </a> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          <Col className="order-xl-1" xl={existeUsuario ? '8' : '12'}>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Cadastro</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Dados básicos
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-razao-social"
                          >
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-razao-social"
                            type="text"
                            value={nome}
                            onChange={text => setNome(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            E-mail
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            value={email}
                            onChange={text => setEmail(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            value={username}
                            onChange={text => setUsername(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-apelido"
                          >
                            Apelido
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-apelido"
                            value={apelido}
                            onChange={text => setApelido(text.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username DomSGE
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="input-username"
                            value={usernameDomSGE}
                            onChange={text =>
                              setUsernameDomSGE(text.target.value)
                            }
                          /> */}

                          <br />
                          <Select
                            value={usernameDomSGE}
                            label="Username DomSGE"
                            fullWidth
                            onChange={event =>
                              setUsernameDomSGE(event.target.value as string)
                            }
                          >
                            {listaUsuarios?.map(item => (
                              <MenuItem
                                key={item.codUsuario}
                                value={item.codUsuario}
                              >
                                {`${item.descUsuario} [${item.codUsuario}] ${
                                  !item.ativo ? ` - Desativado` : ''
                                }`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormControlLabel
                          className="mt-4 float-right"
                          control={
                            <Checkbox
                              checked={isIsentoComissao}
                              onChange={event =>
                                setIsIsentoComissao(event.target.checked)
                              }
                              color="primary"
                            />
                          }
                          label="Isentar comissão"
                        />
                      </Col>
                      <Col>
                        <Input
                          id="originalFileName"
                          type="file"
                          inputProps={{
                            accept: 'image/*',
                          }}
                          style={{ display: 'none' }}
                          required
                          label="Document"
                          name="originalFileName"
                          onChange={e => handleFileRead(e)}
                          variant="standard"
                        />
                      </Col>
                    </Row>
                  </div>

                  <Row className="align-items-center">
                    <Col className="text-left" xs="6">
                      {/* {existeUsuario && (
                        <Button
                          color="danger"
                          onClick={questionaExclusaoUsuario}
                        >
                          Excluir
                        </Button>
                      )} */}
                    </Col>

                    <Col className="text-right" xs="6">
                      {existeUsuario && (
                        <Button color="info" onClick={questionaNovaSenha}>
                          Gerar Nova Senha
                        </Button>
                      )}

                      <Button color="primary" onClick={salvaUsuario}>
                        {usuario?.id ? 'Atualizar' : 'Cadastrar'}
                      </Button>
                    </Col>
                  </Row>

                  {existeUsuario && (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">Perfis</h6>

                      <Row>
                        <Col sm="8" />
                        <Col sm="4">
                          <InputGroup style={{ justifyContent: 'flex-end' }}>
                            <UncontrolledDropdown className="mb-3">
                              <DropdownToggle caret>
                                {perfilSelecionado.nome ?? 'Perfis'}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem disabled>Perfil</DropdownItem>
                                <DropdownItem divider />
                                {perfis.map(perfil => {
                                  return (
                                    <DropdownItem
                                      key={perfil.id}
                                      value={perfil.id}
                                      onClick={() =>
                                        setPerfilSelecionado(perfil)
                                      }
                                    >
                                      {perfil.nome}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </UncontrolledDropdown>

                            <Button
                              style={{ maxHeight: '42.8px' }}
                              color="primary"
                              onClick={handleAdicionaPerfil}
                            >
                              <FaPlusCircle />
                            </Button>
                          </InputGroup>
                        </Col>
                      </Row>

                      <div className="pl-lg-4">
                        {perfisUsuario.map(usuarioPerfil => {
                          return (
                            <PerfilRow key={usuarioPerfil.id}>
                              <Col md="11">
                                <label
                                  className="form-control-label"
                                  htmlFor="perfil"
                                >
                                  {usuarioPerfil.perfil.nome}
                                </label>
                              </Col>

                              <ButtonsRightCol md="1">
                                <Col>
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      handleExcluiPerfil(usuarioPerfil)
                                    }
                                  >
                                    <FaTimesCircle />
                                  </Button>
                                </Col>
                              </ButtonsRightCol>
                            </PerfilRow>
                          );
                        })}
                      </div>
                    </>
                  )}
                  {/* <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            id="input-address"
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="New York"
                            id="input-city"
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="United States"
                            id="input-country"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Postal code
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postal-code"
                            placeholder="Postal code"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div> */}
                  {/* <hr className="my-4" /> */}

                  {/* <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      About Me
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                        Open Source."
                        type="textarea"
                      />
                    </FormGroup>
                  </div> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CadastroUsuario;
