import { useContext, useEffect, useState } from 'react';

import {
  Card,
  Col,
  CardBody,
  Form,
  Row,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import api from 'services/api';
import Proposta from 'models/Proposta';
import { PropostaContext } from 'views/pages/Proposta';

interface IObservacao {
  proposta: Proposta;
  propostaVersaoId: number;
}

export default function Observacao({
  proposta,
  propostaVersaoId,
}: IObservacao): JSX.Element {
  const { propostaVersao, carregaPropostaVersao } = useContext(PropostaContext);
  const [updatingObservacao, setUpdatingObservacao] = useState(false);

  const [observacao, setObservacao] = useState(propostaVersao.observacao || '');

  async function handleUpdateObservacao() {
    if (!propostaVersaoId || !proposta.id) return;
    try {
      setUpdatingObservacao(true);
      await api.put(`/proposta/${propostaVersaoId}/observacao`, {
        observacao,
        propostaId: proposta.id,
      });
    } finally {
      setUpdatingObservacao(false);
      carregaPropostaVersao();
    }
  }

  useEffect(() => {
    carregaPropostaVersao();
  }, []);

  return (
    <>
      <Card className="bg-secondary shadow mt-1 mb-1">
        <CardBody>
          <Form>
            <div className="pl-lg-3">
              <Row>
                <Col sm="6" lg="6" xs="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="example-date-input"
                    >
                      Observação
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i
                            className="ni ni-align-center"
                            style={{
                              alignSelf: 'start',
                              marginTop: '0.3rem',
                            }}
                          />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Observação"
                        value={observacao}
                        rows="2"
                        type="textarea"
                        name="observacao"
                        onChange={text => setObservacao(text.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col sm="4" lg="4" xs="4" />
                <Col sm="1">
                  <Button
                    className="float-right mt-4"
                    color="default"
                    disabled={updatingObservacao}
                    onClick={handleUpdateObservacao}
                  >
                    {updatingObservacao ? 'Salvando...' : 'Salvar'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
