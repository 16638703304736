import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

import React, { createContext, useEffect, useState } from 'react';
import { FaArrowCircleRight, FaInfo, FaInfoCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import FichaTecnicaNecessidadeReprocessamento from 'models/FichaTecnicaNecessidadeReprocessamento';
import RegraAgrupador from 'models/RegraAgrupador';
import triggerValidaRegras from 'services/triggerValidaRegras';
import Orcamento from 'models/Orcamento';
import FichaTecnicaLanceDetalhes from 'models/FichaTecnicaLanceDetalhes';
import UserSmallHeader from '../../../../../components/Headers/UserSmallHeader';
import api from '../../../../../services/api';
import FichaTecnica from '../../../../../models/FichaTecnica';
import { FichaTecnicaBombeamento } from '../../../../../models/FichaTecnicaBombeamento';
import { FichaTecnicaTubulacao } from '../../../../../models/FichaTecnicaTubulacao';
import Parametros from '../../../../../models/Parametros';
import { LabelOrcamento } from './styles';
import Adutora from './components/Adutora';
import Bombeamento from './components/Bombeamento';
import Succao from './components/Succao';
import Eletrica from './components/Eletrica';
import Elevacao from './components/Elevacao';

interface AdutoraBombeamentoContext {
  orcamentoId: number;
  // ovidio
  vazaoPrevista: number;
  setVazaoPrevista: (value: any) => void;
  pressaoPrevista: number;
  setPressaoPrevista: (value: any) => void;
  setBloquearConclusaoPorReprocessamentoCfg: (value: boolean) => void;

  bomba: string;
  setBomba: (value: string) => void;
  quantidadeBomba: number;
  setQuantidadeBomba: (value: number) => void;
  fichaTecnicaBombeamento: FichaTecnicaBombeamento[];
  setFichaTecnica: (value: FichaTecnica) => void;
  fichaTecnica: FichaTecnica;
  setFichaTecnicaBombeamento: (value: FichaTecnicaBombeamento[]) => void;
  fichaTecnicaTubulacao?: FichaTecnicaTubulacao;
  setFichaTecnicaTubulacao: (value: FichaTecnicaTubulacao) => void;
  quantidadeVentosa: number;
  setQuantidadeVentosa: (value: number) => void;
  curvasToSave: string;
  setCurvasToSave: (value: string) => void;
  totalCurvasEncontradas: number;
  setTotalCurvasEncontradas: (value: number) => void;
  quantidadeModulos: number;
  setQuantidadeModulos: (value: number) => void;
  parametros?: Parametros;
  criarPrimeiroTrecho: boolean;
  setCriarPrimeiroTrecho: (value: boolean) => void;
  podeCalcularAdutora: boolean;
  fichaTecnicaId: number;
  sessaoId: string;
  setPodeCalcularAdutora: (value: boolean) => void;
  necessidadeReprocessamento?: FichaTecnicaNecessidadeReprocessamento;
  setNecessidadeReprocessamento: (
    value: FichaTecnicaNecessidadeReprocessamento,
  ) => void;
  setBloquearConclusaoPorReprocessamento: (value: boolean) => void;
  necessidadeReprocessamentoData: (
    value: FichaTecnicaNecessidadeReprocessamento,
  ) => void;
  listaNecessidadeReprocessamento: () => void;
  setAtualizarDadosAdutora: (value: boolean) => void;
  atualizarDadosAdutora: boolean;
}

export const AdutoraBombeamentoContext = createContext(
  {} as AdutoraBombeamentoContext,
);

interface Props {
  onSave?: any;
  acessoLimitado: boolean | false;
  triggerStart: boolean | false;
  sessaoId?: string | null;
  fichaTecnicaId?: number | null;
  configuracaoId: number;
  orcamentoParteId: number;
  setSessaoId?: any;
  setFichaTecnicaId?: any;
}

export default function AdutoraBombeamento(props: Props): JSX.Element {
  const {
    onSave,
    acessoLimitado,
    triggerStart,
    orcamentoParteId,
    configuracaoId,
    sessaoId,
    fichaTecnicaId,
    setFichaTecnicaId,
    setSessaoId,
  } = props;

  const orcamentoId = configuracaoId;
  // ovidio
  const [vazaoPrevista, setVazaoPrevista] = useState(0);
  const [pressaoPrevista, setPressaoPrevista] = useState(0);

  const [bomba, setBomba] = useState('');
  const [quantidadeBomba, setQuantidadeBomba] = useState(0);
  const [quantidadeVentosa, setQuantidadeVentosa] = useState(0);
  const [fichaTecnicaBombeamento, setFichaTecnicaBombeamento] = useState<
    FichaTecnicaBombeamento[]
  >([]);
  const [curvasToSave, setCurvasToSave] = useState('');
  const [totalCurvasEncontradas, setTotalCurvasEncontradas] = useState(0);
  const [quantidadeModulos, setQuantidadeModulos] = useState(1);
  const [criarPrimeiroTrecho, setCriarPrimeiroTrecho] = useState(false);
  const [podeCalcularAdutora, setPodeCalcularAdutora] = useState(false);
  const [atualizarDadosAdutora, setAtualizarDadosAdutora] = useState(false);
  const [parametros, setParametros] = useState<Parametros>();
  const [fichaTecnicaTubulacao, setFichaTecnicaTubulacao] =
    useState<FichaTecnicaTubulacao>();
  const [fichaTecnica, setFichaTecnica] = useState<FichaTecnica>(
    {} as FichaTecnica,
  );
  const [necessidadeReprocessamento, setNecessidadeReprocessamento] =
    useState<FichaTecnicaNecessidadeReprocessamento>();
  const [
    bloquearConclusaoPorReprocessamento,
    setBloquearConclusaoPorReprocessamento,
  ] = useState(false);
  const [
    bloquearConclusaoPorReprocessamentoCfg,
    setBloquearConclusaoPorReprocessamentoCfg,
  ] = useState(false);
  const [orcamentos, setOrcamentos] = useState({} as Orcamento);

  async function carregarParametros() {
    const response = await api.get('/parametros');
    if (response.data) setParametros(response.data as Parametros);
  }

  async function carregaOrcamento() {
    const response = await api.get(`/orcamento/${orcamentoId}`);
    if (response.data) setOrcamentos(response.data as Orcamento);
  }

  useEffect(() => {
    // Comando para fazer um scroll to top ao acessar a aba de Adutora.
    window.scrollTo(0, 0);
    // document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    carregarParametros();
  }, []);

  useEffect(() => {
    if (orcamentoId) {
      carregaOrcamento();
    }
  }, [orcamentoId]);

  useEffect(() => {
    // Carrega variável NecessidadeReprocessamento
  }, [necessidadeReprocessamento]);

  async function necessidadeReprocessamentoData(
    item: FichaTecnicaNecessidadeReprocessamento,
  ) {
    if (fichaTecnicaId) {
      const retorno = await api.put(
        `/ficha-tecnica/${fichaTecnicaId}/necessidade-reprocessamento/dinamicaTela`,
        {
          reprocessarAdutora: item.reprocessarAdutora,
          reprocessarBombeamento: item.reprocessarBombeamento,
          reprocessarTrechos: item.reprocessarTrechos,
          reprocessarSuccao: item.reprocessarSuccao,
        },
      );
      setNecessidadeReprocessamento(
        retorno.data as FichaTecnicaNecessidadeReprocessamento,
      );
    }
  }

  async function atualizarMateriais() {
    const isOk = await triggerValidaRegras({
      fichaTecnicaId: Number(fichaTecnicaId ?? 0),
      atualizarMaterial: true,
      validarAgrupador: true,
      orcamentoVersaoId: orcamentoId,
      origem: `Configuração: ${orcamentoId}`,
      orcamento: orcamentos,
      origemAtualizacao: 'AdutoraBombeamento',
    });
    return isOk;
  }

  function handleSalvarRespostas() {
    Swal.fire({
      title: `Deseja marcar a etapa de Adutora e Bombeamento como concluída?`,
      text: `Será executado o processo para atualizar a lista de materiais. As alterações não salvas serão perdidas.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        const isOk = await atualizarMateriais();
        if (isOk) if (onSave) props.onSave();

        // if (onSave) props.onSave();
      }
    });
  }

  async function carregarFichaTecnica() {
    const response = await api.get(`/ficha-tecnica/${fichaTecnicaId}`);

    const ftBombeamento = response.data?.FichaTecnicaBombeamento ?? {};
    const ftTubulacao = response.data?.FichaTecnicaTubulacao ?? {};

    setFichaTecnicaBombeamento(ftBombeamento as FichaTecnicaBombeamento[]);
    setFichaTecnicaTubulacao(ftTubulacao as FichaTecnicaTubulacao);
    setFichaTecnica(response.data);
  }

  async function listaNecessidadeReprocessamento() {
    const response = await api.get(
      `/ficha-tecnica/${fichaTecnicaId}/necessidade-reprocessamento`,
    );

    const data = response.data as FichaTecnicaNecessidadeReprocessamento;
    setNecessidadeReprocessamento(data);
  }

  useEffect(() => {
    if (fichaTecnicaId) {
      carregarFichaTecnica();
      listaNecessidadeReprocessamento();
    }
  }, [fichaTecnicaId]);

  useEffect(() => {
    if (fichaTecnicaId) {
      listaNecessidadeReprocessamento();
    }
  }, [fichaTecnica, fichaTecnicaId]);

  useEffect(() => {
    if (necessidadeReprocessamento) {
      let bloquear = false;

      if (
        necessidadeReprocessamento.reprocessarAdutora === true ||
        necessidadeReprocessamento.reprocessarBombeamento === true ||
        necessidadeReprocessamento.reprocessarSuccao === true ||
        necessidadeReprocessamento.reprocessarTrechos === true
      )
        bloquear = true;

      setBloquearConclusaoPorReprocessamento(
        bloquear || bloquearConclusaoPorReprocessamentoCfg,
      );
    }
  }, [necessidadeReprocessamento, bloquearConclusaoPorReprocessamentoCfg]);

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col sm="12">
          <AdutoraBombeamentoContext.Provider
            value={{
              fichaTecnicaId: Number(fichaTecnicaId ?? 0),
              // ovidio
              vazaoPrevista,
              setVazaoPrevista,
              pressaoPrevista,
              setPressaoPrevista,
              setBloquearConclusaoPorReprocessamentoCfg,

              bomba,
              setBomba,
              quantidadeBomba,
              setQuantidadeBomba,
              fichaTecnicaBombeamento,
              setFichaTecnicaBombeamento,
              quantidadeVentosa,
              setQuantidadeVentosa,
              curvasToSave,
              setCurvasToSave,
              totalCurvasEncontradas,
              setTotalCurvasEncontradas,
              quantidadeModulos,
              setQuantidadeModulos,
              parametros,
              criarPrimeiroTrecho,
              setCriarPrimeiroTrecho,
              fichaTecnicaTubulacao,
              setFichaTecnicaTubulacao,
              podeCalcularAdutora,
              setPodeCalcularAdutora,
              orcamentoId,
              fichaTecnica,
              sessaoId: String(sessaoId),
              necessidadeReprocessamento,
              setNecessidadeReprocessamento,
              setBloquearConclusaoPorReprocessamento,
              necessidadeReprocessamentoData,
              listaNecessidadeReprocessamento,
              setFichaTecnica,
              setAtualizarDadosAdutora,
              atualizarDadosAdutora,
            }}
          >
            <Card className="shadow mt-5">
              <CardBody className="p-1">
                <Elevacao />
              </CardBody>
            </Card>

            <Card className="shadow mt-3">
              <CardBody className="p-1">
                <Adutora />
              </CardBody>
            </Card>

            <Card className="shadow mt-5">
              <CardBody className="p-1">
                <Bombeamento />
              </CardBody>
            </Card>

            <Card className="shadow mt-5">
              <CardBody className="p-1">
                <Eletrica />
              </CardBody>
            </Card>

            <Card className="shadow mt-5">
              <CardBody className="p-1">
                <Succao />
              </CardBody>
            </Card>
          </AdutoraBombeamentoContext.Provider>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={bloquearConclusaoPorReprocessamento ? '4' : '12'}>
          <Button
            size="sm"
            className="btn-icon btn-2"
            color="primary"
            type="button"
            disabled={bloquearConclusaoPorReprocessamento}
            onClick={handleSalvarRespostas}
          >
            <FaArrowCircleRight />
            <span className="btn-inner--text">
              Concluir Etapa e Gerar lista de materiais
            </span>
          </Button>
        </Col>
        <Col sm="6">
          <div
            hidden={
              !bloquearConclusaoPorReprocessamento ||
              !necessidadeReprocessamento
            }
          >
            <strong>ATENÇÃO:</strong> Todas as etapas precisam ser salvas e
            calculadas novamente para poder salvar os dados. Isso é uma{' '}
            <u>precaução</u> por conta de um reprocessamento na lista de lances.
            Itens com essa necessidade estão identificados com
            <Button
              className="btn-icon ml-1"
              color="warning"
              type="button"
              size="sm"
            >
              <FaInfo />
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
