import React, { useState, useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { Toast, ToastAsync } from 'components/Toast';
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  FormGroup,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaArrowLeft, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import api from 'services/api';

import LanceModel from '../../../models/ConfiguradorLance';
import TuboModel from '../../../models/ConfiguradorTubo';

type LocationState = {
  stateLance?: LanceModel;
  stateTubo?: TuboModel;
  stateTipo: string;
};

interface ListaAssociados {
  id: number;
  nome: string;
}

const ConfiguradorLancesTubos: React.FC = () => {
  const location = useLocation<LocationState>();
  const { stateLance, stateTubo, stateTipo } = location.state;

  const history = useHistory();
  const isLanceState = stateTipo === 'L';
  const codOrigem = isLanceState ? stateLance?.id : stateTubo?.id;

  const pageTitle = !isLanceState
    ? `Lances associados ao tubo ${stateTubo?.nome}`
    : `Tubos associados ao ${stateLance?.nome}`;

  const [lista, setLista] = useState([] as ListaAssociados[]);
  const [dropdown, setDropDown] = useState([] as ListaAssociados[]);
  const [itemSelecionado, setItemSelecionado] = useState({} as ListaAssociados);

  async function listarAssociacoes() {
    const response = isLanceState
      ? await api.get(`/integracao/lances/${codOrigem}/tubos`)
      : await api.get(`/integracao/tubos/${codOrigem}/lances`);

    setLista(response.data);
  }

  async function listarDropdown() {
    const response = !isLanceState
      ? await api.get(`/integracao/lances`)
      : await api.get(`/integracao/tubos`);

    setDropDown(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listarDropdown();
    listarAssociacoes();
  }, []);

  async function handleDelete(objId: number) {
    if (isLanceState) {
      await api.delete(`/integracao/lances/${codOrigem}/tubos/${objId}`);
    } else {
      await api.delete(`/integracao/tubos/${codOrigem}/lances/${objId}`);
    }
    listarAssociacoes();
  }

  async function handleSave() {
    if (!itemSelecionado?.id) {
      return;
    }

    const jaFoiAdicionado = lista.find(item => item.id === itemSelecionado?.id);

    if (jaFoiAdicionado) {
      return;
    }

    ToastAsync.fire({
      icon: 'info',
      title: 'Associando...',
    });

    if (isLanceState) {
      await api.post(
        `/integracao/lances/${codOrigem}/tubos/${itemSelecionado?.id}`,
      );
    } else {
      await api.post(
        `/integracao/tubos/${codOrigem}/lances/${itemSelecionado?.id}`,
      );
    }

    listarAssociacoes();

    setItemSelecionado({} as ListaAssociados);

    Toast.close();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="10">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: `${
                        isLanceState ? '/admin/lances-balancos' : '/admin/tubos'
                      }`,
                    });
                  }}
                >
                  <FaArrowLeft />
                  <span className="btn-inner--text">Voltar</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col>
            <InputGroup>
              <UncontrolledDropdown className="mb-6">
                <DropdownToggle caret>
                  {itemSelecionado.nome || (isLanceState ? 'Tubos' : 'Lances')}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem disabled>
                    {isLanceState ? 'Tubos' : 'Lances'}
                  </DropdownItem>
                  <DropdownItem divider />
                  {dropdown.map(item => {
                    return (
                      <DropdownItem
                        key={item.id}
                        value={item.id}
                        onClick={() => setItemSelecionado(item)}
                      >
                        {item.nome}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>

              <Button
                style={{ maxHeight: '42.8px' }}
                color="primary"
                onClick={handleSave}
              >
                <FaPlusCircle />
              </Button>
            </InputGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{pageTitle}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  {lista.map(item => (
                    <tr key={item.id}>
                      <td className="col">
                        <strong>{item.nome}</strong>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorLancesTubos;
