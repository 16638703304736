import { Row } from 'reactstrap';
import styled from 'styled-components';

export const PessoaContainer = styled(Row)`
  padding-left: 2.5rem;
  font-size: 0.9rem;
  font-weight: 600;
`;

export const Municipio = styled.label`
  white-space: nowrap;
  overflow: hidden;
  max-width: 35rem;
`;

export const Td = styled.td`
  width: 5%;
`;

export const Th = styled.th`
  background: #e9ecef;
  width: 5%;
`;

export const TdDescricao = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
`;
