import OrcamentoVersao from 'models/OrcamentoVersao';
import Proposta from 'models/Proposta';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import api from 'services/api';

const ObjetoCriptografia = ['NAVIGATE_TO_PROPOSTA', 'NAVIGATE_TO_CONFIGURACAO'];

/*

README


Sim, o processo está bem capenga. Ele precisaria de modificações maiores no processo e na tela de proposta
para evitar o consumo desenfreado de requisições http.

Quem sabe podemos colocar um backlog para melhoria de código

*/

interface AcessoExterno {
  tipo: string;
  arg1: string | null;
  arg2?: string | null;
  arg3?: string | null;
  arg4?: string | null;
}

export default function AcessoExterno(): JSX.Element {
  const history = useHistory();
  const { search } = useLocation();
  const tokenUsar = new URLSearchParams(search).get('token');
  const [isConcluido, setIsConcluido] = useState(false);

  async function functionNavigateToProposta(
    propostaId: number,
    propostaVersaoId: number,
  ) {
    try {
      setIsConcluido(false);
      const response = await api.get(`/proposta`);

      const propostas = response.data as Proposta[];

      let proposta = null;
      let propostaVersao = null;

      // eslint-disable-next-line no-restricted-syntax
      for await (const registro of propostas.filter(
        item => item.id === propostaId,
      )) {
        proposta = registro;
        propostaVersao = registro.PropostaVersao?.find(
          item => item.id === propostaVersaoId,
        );
      }

      if (proposta && proposta.id) {
        history.push({
          pathname: '/admin/proposta',
          state: { proposta, propostaVersao, acessarAprovacao: true },
        });
      }
    } finally {
      setIsConcluido(true);
    }
  }

  async function functionNavigateToConfiguracao(
    configuracaoId: number,
    goTo: string,
  ) {
    try {
      setIsConcluido(false);
      if (configuracaoId) {
        history.push({
          pathname: `/admin/etapas-orcamento`,
          state: { orcamentoVersaoId: configuracaoId, goTo },
        });
      }
    } finally {
      setIsConcluido(true);
    }
  }

  async function processarRequisicao() {
    const CONST_MENSAGEM_SUCESSO = 'Redirecionando para interface solicitada';
    toast.loading('Processando requisição do Token...');

    const retorno = await api.get(
      `/acesso-externo/validar-token?token=${tokenUsar}`,
      {},
    );

    const acessoExterno = retorno.data as AcessoExterno;

    if (acessoExterno.tipo === 'NAVIGATE_TO_PROPOSTA') {
      const arg1Converted = Number(acessoExterno.arg1);
      const arg2Converted = Number(acessoExterno.arg2 ?? 0);
      toast.success(CONST_MENSAGEM_SUCESSO);
      toast.dismiss();
      await functionNavigateToProposta(arg1Converted, arg2Converted);
    } else if (acessoExterno.tipo === 'NAVIGATE_TO_CONFIGURACAO') {
      const arg1Converted = Number(acessoExterno.arg1);
      const arg2Converted = String(acessoExterno.arg2);

      toast.success(CONST_MENSAGEM_SUCESSO);
      toast.dismiss();
      await functionNavigateToConfiguracao(arg1Converted, arg2Converted);
    } else {
      toast.error('Não foi possível completar a requisição');
      toast.dismiss();
      history.push({
        pathname: '/admin/index',
      });
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (tokenUsar) {
      processarRequisicao();
    } else {
      history.push({
        pathname: '/admin/index',
      });
    }
  }, [tokenUsar]);

  return <></>;
}
