import FichaTecnica from 'models/FichaTecnica';
import Orcamento from 'models/Orcamento';
import { Col, Table } from 'reactstrap';
import { Td } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
  orcamento: Orcamento;
}

function Cabecalho(props: Props): JSX.Element {
  const { fichaTecnica, orcamento } = props;
  const { pivoCentral, modeloPivo, tempoPorVolta, precipitacaoPorVolta } =
    fichaTecnica;

  const { pessoa } = orcamento;
  const nomeCliente = pessoa.razaoSocial;
  const localCliente = pessoa.municipioDescricao
    ? `${pessoa.municipioDescricao} - ${pessoa?.ufSigla || ''}`
    : '';

  const dadosBasicos = [
    { nome: 'Cliente', valor: nomeCliente },
    { nome: 'Endereço', valor: localCliente },
    { nome: 'Número do pivô', valor: pivoCentral },
    { nome: 'Modelo do pivô', valor: modeloPivo },
    {
      nome: 'Tempo por volta',
      valor: tempoPorVolta,
      unidade: 'hs',
    },
    {
      nome: 'Precipitação por volta',
      valor: precipitacaoPorVolta,
      unidade: 'mm',
    },
  ];

  return (
    <Table bordered responsive>
      {dadosBasicos.map(dado => (
        <tr key={dado.nome}>
          <Td>
            <strong>{dado.nome}</strong>
          </Td>
          <Td>
            {dado.valor} {dado?.unidade || ''}
          </Td>
        </tr>
      ))}
    </Table>
  );
}

export default Cabecalho;
