import { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  ButtonGroup,
} from 'reactstrap';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import { useLocation, useHistory } from 'react-router-dom';
import Orcamento from 'models/Orcamento';
import { Collapse, ListItem } from '@material-ui/core';
import { FaFileExcel, FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import OrcamentoMaterial from 'models/OrcamentoMaterial';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import api from 'services/api';
import FichaTecnicaType from 'models/FichaTecnica';
import Swal from 'sweetalert2';
import OrcamentoParte from 'models/OrcamentoParte';
import ParametrosModel from 'models/Parametros';
import ProdutoModel from 'models/Produto';

import exportToSheet from 'utils/exportToSheet';
import {
  ButtonsRightCol,
  ColLabelItem,
  LabelItem,
  MaterialListContainer,
  MaterialRow,
  LabelOrcamento,
  MaterialContainer,
} from './styles';

import DadosInformados from './components/organisms/DadosInformados';
import DadosCalculados from './components/organisms/DadosCalculados';
import DadosInformadosFotovoltaica from './components/organisms/DadosInformadosFotovoltaica';

type LocationState = {
  fichaTecnica: FichaTecnicaType;
  orcamento: Orcamento;
  orcamentoParteId: number;
  orcamentoParte: OrcamentoParte;
};

function OrcamentoResultado(): JSX.Element {
  const location = useLocation<LocationState>();
  const history = useHistory();

  const locationStateFichaTecnica = location.state?.fichaTecnica ?? null;
  const orcamento = location.state?.orcamento ?? null;
  const orcamentoParte = location.state?.orcamentoParte ?? null;
  const orcamentoParteId = orcamentoParte.id;

  const [fichaTecnica, setFichaTecnica] = useState(locationStateFichaTecnica);
  const [carregandoFichaTecnica, setCarregandoFichaTecnica] = useState(false);
  const [todosMateriaisExpandidos, setTodosMateriaisExpandidos] =
    useState(false);

  const [orcamentoMateriais, setOrcamentoMateriais] = useState(
    [] as OrcamentoMaterial[],
  );

  const [parametros, setParametros] = useState({} as ParametrosModel);
  const [categoriaProdutos, setCategoriaProdutos] = useState(
    {} as ProdutoModel,
  );

  const [isFotovoltaica, setIsFotovoltaica] = useState(false);
  const [isIrrigacao, setisIrrigacao] = useState(false);

  async function produtoCategoria() {
    const response = await api.get(`/produto/`);
    setCategoriaProdutos(response.data);
  }

  async function carregarParametros() {
    const response = await api.get('/parametros');
    setParametros(response.data);
  }

  async function carregaOrcamentoMateriais(): Promise<OrcamentoMaterial[]> {
    const response = await api.get(`/orcamento/lista-materiais`, {
      params: { orcamentoParteId },
    });

    setOrcamentoMateriais(response.data);

    return response.data;
  }

  async function carregaFichaTecnica() {
    try {
      setCarregandoFichaTecnica(true);
      const response = await api.get(
        `/ficha-tecnica/${locationStateFichaTecnica.id}`,
      );
      setFichaTecnica(response.data);
    } finally {
      setCarregandoFichaTecnica(false);
    }
  }

  useEffect(() => {
    carregaFichaTecnica();
    carregaOrcamentoMateriais();
  }, []);

  useEffect(() => {
    /* if (produtoSelecionado?.produtoId) {
      produtoCategoria();
    } */
  }, []);

  useEffect(() => {
    carregarParametros();
  }, []);

  useEffect(() => {
    if (fichaTecnica.id) {
      if (
        fichaTecnica.FichaTecnicaLance &&
        fichaTecnica.FichaTecnicaLance.length > 0
      ) {
        setIsFotovoltaica(false);
        setisIrrigacao(true);
      } else {
        setIsFotovoltaica(true);
        setisIrrigacao(false);
      }
    }
  }, [fichaTecnica]);

  function findSubMateriais(
    orcamentoMaterial: OrcamentoMaterial,
  ): OrcamentoMaterial[] {
    const subOrcamentoMateriais = orcamentoMateriais.filter(
      x => x.orcamentoMaterialId === orcamentoMaterial.id,
    );

    return subOrcamentoMateriais;
  }

  function handleExportToSheet() {
    function getMateriaisSubsOrdenados(): OrcamentoMaterial[] {
      const orcamentosMateriaisPrimeiroNivel = orcamentoMateriais.filter(
        orcamentoMaterial => orcamentoMaterial.orcamentoMaterialId === null,
      );
      const orcamentoMateriaisSubMateriaisOrdenados = [] as OrcamentoMaterial[];
      orcamentosMateriaisPrimeiroNivel.forEach(primeiroNivel => {
        orcamentoMateriaisSubMateriaisOrdenados.push(primeiroNivel);
        const subMateriais = orcamentoMateriais.filter(
          orcamentoMaterial =>
            orcamentoMaterial.orcamentoMaterialId === primeiroNivel.id,
        );
        orcamentoMateriaisSubMateriaisOrdenados.push(...subMateriais);
      });
      return orcamentoMateriaisSubMateriaisOrdenados;
    }

    const orcamentoMateriaisSubMateriaisOrdenados = getMateriaisSubsOrdenados();
    const dadosPlanilha = orcamentoMateriaisSubMateriaisOrdenados.map(
      orcamentoMaterial => {
        const { material, regraAplicacao, quantidade } = orcamentoMaterial;
        const { diteCodigo, descricao } = material;
        return {
          Código: diteCodigo,
          Descrição: descricao,
          Aplicação: regraAplicacao,
          Quantidade: quantidade,
        };
      },
    );
    const nomeArquivo = `Materiais - Configuração ${orcamento.id}`;
    exportToSheet(dadosPlanilha, nomeArquivo);
  }

  function renderMateriais() {
    if (!orcamentoMateriais.length) {
      return <></>;
    }

    function renderLista(orcamentoMaterial: OrcamentoMaterial, itemPai = true) {
      const isOpen = orcamentoMateriais.find(
        x => x === orcamentoMaterial,
      )?.isOpen;

      const subMateriais = findSubMateriais(orcamentoMaterial);

      function handleToggleAccordion() {
        setOrcamentoMateriais(
          orcamentoMateriais.map(x =>
            x.id === orcamentoMaterial.id ? { ...x, isOpen: !x.isOpen } : x,
          ),
        );
      }

      function renderExpand() {
        if (!subMateriais?.length) {
          return <></>;
        }

        return isOpen ? (
          <ExpandLess onClick={handleToggleAccordion} />
        ) : (
          <ExpandMore onClick={handleToggleAccordion} />
        );
      }

      return (
        <MaterialRow itemPai={itemPai} key={orcamentoMaterial.id}>
          <ListItem style={{ padding: 0 }}>
            <Col sm="1">
              <InputGroup size="sm">
                <span className="mb-0 text-xs">
                  {orcamentoMaterial.material.diteCodigo}
                </span>
              </InputGroup>
            </Col>

            <Col sm="8">
              <InputGroup size="sm">
                <span className="mb-0 text-xs">
                  {orcamentoMaterial.material.descricao}
                </span>
              </InputGroup>
            </Col>

            <Col sm="1">
              <InputGroup size="sm">
                <span className="mb-0 text-xs">
                  {orcamentoMaterial.regraAplicacao}
                </span>
              </InputGroup>
            </Col>

            <Col sm="1">
              <InputGroup size="sm" className="justify-content-end">
                <span className="mb-0 text-xs">
                  {orcamentoMaterial.quantidade}
                </span>
              </InputGroup>
            </Col>

            <Col sm="1">
              <InputGroup style={{ justifyContent: 'flex-end' }}>
                {renderExpand()}
              </InputGroup>
            </Col>
          </ListItem>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <MaterialListContainer>
              {subMateriais.map(subMaterial => renderLista(subMaterial, false))}
            </MaterialListContainer>
          </Collapse>
        </MaterialRow>
      );
    }

    async function expandeColapsaMateriais() {
      async function geraComposicao() {
        Swal.fire({
          icon: 'info',
          text: 'Gerando composição dos materiais...',
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        await api.post('/material/materiais-composicao', {
          orcamentoParteId,
        });

        Swal.close();
      }

      function existeMaterialPai(orcamentoMaterialId?: number | null): boolean {
        if (!orcamentoMaterialId) {
          return false;
        }

        return !!orcamentoMateriais.find(
          orcamentoMaterial => orcamentoMaterial.id === orcamentoMaterialId,
        );
      }

      let novoOrcamentoMateriais = orcamentoMateriais;

      if (!todosMateriaisExpandidos) {
        const existeComposicao = orcamentoMateriais.some(
          orcamentoMaterial =>
            orcamentoMaterial.materialId &&
            existeMaterialPai(orcamentoMaterial.orcamentoMaterialId),
        );

        if (!existeComposicao) {
          await geraComposicao();
          novoOrcamentoMateriais = await carregaOrcamentoMateriais();
        }
      }

      setTodosMateriaisExpandidos(!todosMateriaisExpandidos);

      setOrcamentoMateriais(
        novoOrcamentoMateriais.map(orcamentoMaterial => ({
          ...orcamentoMaterial,
          isOpen: !todosMateriaisExpandidos,
        })),
      );
    }

    return (
      <MaterialContainer>
        <Row>
          <Col xs="8" style={{ paddingLeft: '2rem', paddingTop: '1rem' }}>
            <h6 className="heading-small text-muted">Lista de materiais</h6>
          </Col>
          <ButtonsRightCol sm="4">
            <Button
              size="sm"
              className="btn-icon btn-2"
              style={{ backgroundColor: '#1a8c5b' }}
              color="success"
              type="button"
              onClick={handleExportToSheet}
            >
              <FaFileExcel />
              <span className="btn-inner--text">Exportar planilha</span>
            </Button>
          </ButtonsRightCol>
        </Row>
        <ListItem
          style={{
            backgroundColor: '#2f75b6',
            color: '#fff',
            borderRadius: '4px',
            marginTop: '0.5rem',
          }}
        >
          <ColLabelItem sm="1">
            <LabelItem>Código</LabelItem>
          </ColLabelItem>

          <ColLabelItem sm="8">
            <LabelItem>Descrição</LabelItem>
          </ColLabelItem>

          <ColLabelItem sm="1">
            <LabelItem>Aplicação</LabelItem>
          </ColLabelItem>

          <Col sm="1" className="pl-6">
            <LabelItem>Quantidade</LabelItem>
          </Col>
        </ListItem>
        <MaterialListContainer>
          {orcamentoMateriais
            .filter(x => x.orcamentoMaterialId === null)
            .map(orcamentoMaterial => renderLista(orcamentoMaterial))}
        </MaterialListContainer>
      </MaterialContainer>
    );
  }

  return (
    <>
      <UserSmallHeader />
      <Container fluid>
        <Row className="mt-2">
          <Col sm="8">
            <Row>
              <LabelOrcamento>
                {orcamento?.id ? `Configuração ${orcamento?.id}` : ''}
              </LabelOrcamento>
            </Row>
            <Row>
              <div className="mt--2">
                <h6 className="text-muted">
                  Ficha técnica #{fichaTecnica.id} atualizada em{' '}
                  {new Intl.DateTimeFormat('pt-BR', {
                    dateStyle: 'short',
                    timeStyle: 'medium',
                  }).format(new Date(fichaTecnica.updatedAt))}
                </h6>
              </div>
            </Row>
          </Col>

          <Col>
            <ButtonGroup className="float-right pt-2" role="group">
              <Button
                className="btn-icon btn-2"
                color="primary"
                type="button"
                outline
                size="sm"
                onClick={() => {
                  history.push({
                    pathname: '/admin/orcamento-precificacao',
                    state: {
                      fichaTecnica,
                      orcamentoId: orcamento.id,
                      orcamentoParteId,
                    },
                  });
                }}
              >
                Precificação
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Row>
          {isIrrigacao && (
            <>
              <DadosInformados fichaTecnica={fichaTecnica} />
              <DadosCalculados
                carregandoFichaTecnica={carregandoFichaTecnica}
                fichaTecnica={fichaTecnica}
                orcamento={orcamento}
                orcamentoParteId={orcamentoParteId}
              />
            </>
          )}
          {isFotovoltaica && (
            <DadosInformadosFotovoltaica
              fichaTecnica={fichaTecnica}
              orcamentoId={orcamento.id}
            />
          )}
        </Row>

        <Row className="mt-3">{renderMateriais()}</Row>
      </Container>
    </>
  );
}

export default OrcamentoResultado;
