import FichaTecnica from 'models/FichaTecnica';
import FichaTecnicaLance from 'models/FichaTecnicaLance';

import { Card, Table } from 'reactstrap';

import Quadro from '../../../../atoms/Quadro';
import { Td, Th } from '../../styles';
import { TrTotal, Label } from './styles';

interface Props {
  fichaTecnica: FichaTecnica;
  ocultaTotal?: boolean;
  ocultaBalanco?: boolean;
  tituloCustomizado?: string;
}

function LancesBalanco(props: Props): JSX.Element {
  const { fichaTecnica, ocultaBalanco, ocultaTotal, tituloCustomizado } = props;

  const fichaTecnicaLances =
    fichaTecnica.FichaTecnicaLance as FichaTecnicaLance[];

  const {
    balanco,
    balancoDiametroSiglaTubo,
    raioEfetivoSemBalancoSAF,
    raioEfetivoIrrigadoSAF,
    opcaoManualSelecionada,
  } = fichaTecnica;

  const TITULO =
    tituloCustomizado ||
    `CONFIGURAÇÃO DOS LANCES E BALANÇO${
      opcaoManualSelecionada ? ' (MANUAL)' : ''
    }`;

  return (
    <Card className="shadow mt-2">
      <Quadro titulo={TITULO}>
        <Table bordered responsive style={{ maxHeight: '11rem' }}>
          <tr>
            <Th>Quant.</Th>
            <Th>Lance</Th>
            <Th>Ø (pol.)</Th>
            <Th>L (m)</Th>
            <Th>Total (m)</Th>
          </tr>

          {fichaTecnicaLances?.map(lance => {
            const {
              id,
              quantidade,
              comprimento,
              tipoLance,
              diametroExterno,
              total,
            } = lance;

            return (
              <tr key={id}>
                <Td>{quantidade} </Td>
                <Td>{tipoLance}</Td>
                <Td>{diametroExterno}</Td>
                <Td>{comprimento} m </Td>
                <Td>
                  <Label className="float-right mr-1">{total} m</Label>
                </Td>
              </tr>
            );
          })}

          {!ocultaTotal && (
            <TrTotal>
              <Td className="text-right" colSpan={4}>
                <Label>Total até última torre:</Label>
              </Td>
              <Td className="text-right">
                <Label>{raioEfetivoSemBalancoSAF} m</Label>
              </Td>
            </TrTotal>
          )}

          {!ocultaBalanco && (
            <>
              <tr>
                <Td>Balanço</Td>
                <Td />
                <Td>{balancoDiametroSiglaTubo}</Td>
                <Td />
                <Td>
                  <Label className="float-right mr-1">{balanco} m</Label>
                </Td>
              </tr>

              <TrTotal>
                <Td className="text-right" colSpan={4}>
                  <Label>Total Lances + Balanço:</Label>
                </Td>
                <Td className="text-right">
                  <Label>{raioEfetivoIrrigadoSAF} m</Label>
                </Td>
              </TrTotal>
            </>
          )}
        </Table>

        <Table />
      </Quadro>
    </Card>
  );
}

export default LancesBalanco;
