import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import api from 'services/api';

import ModalLog from 'components/ModalLog';
import LigacaoPivo from '../../../models/ConfiguradorLigacaoPivo';
import TuboModel from '../../../models/ConfiguradorTubo';

const ConfiguradorLigacaoPivo: React.FC = () => {
  const [ligacaoPivo, setlLgacaoPivo] = useState([] as LigacaoPivo[]);
  // eslint-disable-next-line prettier/prettier
  const [ligacaoPivoEditing, setLgacaoPivoEditing] = useState({} as LigacaoPivo);
  const [tubos, setTubos] = useState([] as TuboModel[]);

  const [formModalState, setFormModalState] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  async function listaLigacaoPivo() {
    const response = await api.get('/perda-carga/ligacao-pivo/');

    setlLgacaoPivo(response.data);
  }

  async function listaTubos() {
    const response = await api.get('/integracao/tubos/lista-tubos');
    const tubosRetorno = response.data as TuboModel[];
    setTubos(tubosRetorno);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaLigacaoPivo();
    listaTubos();
  }, []);

  async function handleDelete(descontoId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/perda-carga/ligacao-pivo/${descontoId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        listaLigacaoPivo();
      }
    });
  }
  async function handleInsert() {
    setLgacaoPivoEditing({} as LigacaoPivo);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemPressao: LigacaoPivo) {
    setLgacaoPivoEditing(itemPressao);

    setFormModalState(!formModalState);
  }

  async function handleSaveDesconto() {
    // eslint-disable-next-line prettier/prettier
    const { seqTubos,
      dn,
      di,
      comprimento,
      fatorC1,
      fatorK } = ligacaoPivoEditing;

    if (!seqTubos || !dn || !di || !comprimento || !fatorC1 || !fatorK) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (ligacaoPivoEditing.id) {
      setIsSaving(true);
      await api.put(`/perda-carga/ligacao-pivo/${ligacaoPivoEditing.id}`, {
        id: ligacaoPivoEditing.id,
        seqTubos: ligacaoPivoEditing.seqTubos,
        dn: ligacaoPivoEditing.dn,
        di: ligacaoPivoEditing.di,
        comprimento: ligacaoPivoEditing.comprimento,
        fatorC1: ligacaoPivoEditing.fatorC1,
        fatorK: ligacaoPivoEditing.fatorK,
      });
    } else {
      setIsSaving(true);
      await api.post(`/perda-carga/ligacao-pivo/`, {
        seqTubos: ligacaoPivoEditing.seqTubos,
        dn: ligacaoPivoEditing.dn,
        di: ligacaoPivoEditing.di,
        comprimento: ligacaoPivoEditing.comprimento,
        fatorC1: ligacaoPivoEditing.fatorC1,
        fatorK: ligacaoPivoEditing.fatorK,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setLgacaoPivoEditing({} as LigacaoPivo);
    listaLigacaoPivo();
    setIsSaving(false);
  }

  return (
    <>
      <Header showCards={false} />
      <ModalLog
        nomeChave="desconto_id"
        valorChave=""
        titulo="Logs de ligacaoPivo e comissões"
        modalState={modalStatusLogVisivel}
        toggle={() => setModalStatusLogVisivel(false)}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{ligacaoPivoEditing.di}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <span>Tubo *</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tubo"
                            type="select"
                            value={ligacaoPivoEditing.seqTubos}
                            onChange={text =>
                              setLgacaoPivoEditing({
                                ...ligacaoPivoEditing,
                                seqTubos: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {tubos.map(tubo => (
                              <option value={tubo.id}>{tubo.nome}</option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>DN *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="DN"
                            value={ligacaoPivoEditing.dn}
                            type="number"
                            onChange={text =>
                              setLgacaoPivoEditing({
                                ...ligacaoPivoEditing,
                                dn: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>DI *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="DI"
                            value={ligacaoPivoEditing.di}
                            type="number"
                            onChange={text =>
                              setLgacaoPivoEditing({
                                ...ligacaoPivoEditing,
                                di: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Comprimento *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Comprimento"
                            value={ligacaoPivoEditing.comprimento}
                            type="number"
                            onChange={text =>
                              setLgacaoPivoEditing({
                                ...ligacaoPivoEditing,
                                comprimento: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Fator C1 *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fator C1"
                            value={ligacaoPivoEditing.fatorC1}
                            type="number"
                            onChange={text =>
                              setLgacaoPivoEditing({
                                ...ligacaoPivoEditing,
                                fatorC1: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Fator K *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fator K"
                            value={ligacaoPivoEditing.fatorK}
                            type="number"
                            onChange={text =>
                              setLgacaoPivoEditing({
                                ...ligacaoPivoEditing,
                                fatorK: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSaveDesconto}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Ligação pivô</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Tubo</th>
                    <th>DN</th>
                    <th>DI</th>
                    <th>Comprimento</th>
                    <th>Fator C1</th>
                    <th>Fator K</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {ligacaoPivo.map(itemPressao => {
                    return (
                      <tr key={itemPressao.id}>
                        <td className="py-2">
                          {
                            tubos.find(
                              (item: any) => item.id === itemPressao.seqTubos,
                            )?.nome
                          }
                        </td>
                        <td className="py-2">{itemPressao.dn}</td>
                        <td className="py-2">{itemPressao.di}</td>
                        <td className="py-2">{itemPressao.comprimento}</td>
                        <td className="py-2">{itemPressao.fatorC1}</td>
                        <td className="py-2">{itemPressao.fatorK}</td>
                        <td className="py-2">
                          <div className="float-right">
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              onClick={() => {
                                handleUpdate(itemPressao);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="danger"
                              type="button"
                              onClick={() => {
                                handleDelete(Number(itemPressao.id));
                              }}
                            >
                              <FaTimesCircle />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorLigacaoPivo;
