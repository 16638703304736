import styled from 'styled-components';
import { CardBody as CardBodyC } from 'reactstrap';

export const IdOrcamentoLabel = styled.label`
  color: #8898aa;
  font-size: 1.4rem;
  font-weight: 600;
`;

export const GridDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
`;

export const CardBodyPai = styled(CardBodyC)`
  overflow: auto;
  height: 45vh;
`;

export const CardBody = styled(CardBodyC)``;

export const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
