/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import api from 'services/api';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import modelConfiguradorPerfilAprovacao from 'models/ConfiguradorPerfilAprovacao';
import Perfil from 'models/Perfil';
import PropostaTipoAprovacao from 'models/PropostaTipoAprovacao';
import { PropostaMeioPagamento } from 'models/PropostaMeioPagamento';
import ProdutoModel from 'models/Produto';
import { PropostaStatus } from 'models/PropostaStatus';
import formatCurrency from 'utils/formatCurrency';

const ConfiguradorPerfilAprovacao: React.FC = () => {
  const [descontos, setDescontos] = useState(
    [] as modelConfiguradorPerfilAprovacao[],
  );
  const [descontoEditing, setDescontoEditing] = useState(
    {} as modelConfiguradorPerfilAprovacao,
  );
  const [listaPerfil, setListaPerfil] = useState([] as Perfil[]);
  const [listaProduto, setListaProduto] = useState([] as ProdutoModel[]);
  const [listaPropostaStatus, setListaPropostaStatus] = useState(
    [] as PropostaStatus[],
  );
  const [listaTipo, setListaTipo] = useState([] as PropostaTipoAprovacao[]);
  const [listaMeioPagamento, setListaMeioPagamento] = useState(
    [] as PropostaMeioPagamento[],
  );
  const [formModalState, setFormModalState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [tipoIdFiltrar, setTipoIdFiltrar] = useState(0);

  const [campoValorIsPercentual, setCampoValorIsPercentual] = useState(false);
  const [campoValorIsReal, setCampoValorIsReal] = useState(false);
  const [campoValorSimbolo, setCampoValorSimbolo] = useState('');

  async function carregarTipoAprovacao() {
    const response = await api.get('/proposta-perfil-aprovacao/tipos');
    setListaTipo(response.data);
  }

  async function carregarDesconto() {
    const response = await api.get('/proposta-perfil-aprovacao');

    setDescontos(response.data);
  }

  async function carregarPerfil() {
    const response = await api.get('/perfil');

    setListaPerfil(response.data);
  }

  async function carregarProdutos() {
    const response = await api.get('/produto');

    setListaProduto(response.data);
  }
  async function carregarStatus() {
    const response = await api.get('/proposta/status');

    setListaPropostaStatus(response.data);
  }

  async function carregarMeioPagamento() {
    const response = await api.get('/proposta/meio-pagamento');

    setListaMeioPagamento(response.data);
  }

  useEffect(() => {
    carregarTipoAprovacao();
    carregarDesconto();
    carregarPerfil();
    carregarProdutos();
    carregarMeioPagamento();
    carregarStatus();
  }, []);

  /* useEffect(() => {
    if (listaTipo && listaTipo.length) {
      setTipoIdFiltrar(1); // Enquanto não for habilitado outro tipo
    }
  }, [listaTipo]); */

  async function handleDelete(descontoId: number) {
    Swal.fire({
      title: `Deseja excluir o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/proposta-perfil-aprovacao/${descontoId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        carregarDesconto();
      }
    });
  }
  async function handleInsert() {
    setDescontoEditing({} as modelConfiguradorPerfilAprovacao);
    setFormModalState(!formModalState);
  }

  function handleUpdate(itemDesconto: modelConfiguradorPerfilAprovacao) {
    setDescontoEditing(itemDesconto);

    setFormModalState(!formModalState);
  }

  async function handleSaveDesconto() {
    const {
      perfilId,
      statusId,
      valorMinimo,
      tipoId,
      condicoes,
      meioPagamentoId,
    } = descontoEditing;

    if (!perfilId) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    const tipoFiltrado = listaTipo.find(item => item.id === tipoIdFiltrar);

    const usaCondicoes = tipoFiltrado?.usaCampoCondicoes === true ?? false;
    const usaMeioPagamento =
      tipoFiltrado?.usaCampoMeioPagamento === true ?? false;
    const usaValor = tipoFiltrado?.usaCampoValor === true ?? false;
    const usaStatus = tipoFiltrado?.usaCampoStatus === true ?? false;
    const validarCampos = tipoFiltrado?.validarAntesSalvar === true ?? false;

    let temCamposVazios = false;

    if (usaCondicoes && !condicoes) temCamposVazios = true;

    if (usaMeioPagamento && !meioPagamentoId) temCamposVazios = true;

    if (usaValor && !valorMinimo) temCamposVazios = true;

    if (usaStatus && !statusId) temCamposVazios = true;

    if (temCamposVazios) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (validarCampos) {
      // Valida o eval do condicoes
      const substituido =
        condicoes ??
        ''
          .replace('#PrimeiraParcela#', '0')
          .replace('#UltimaParcela#', '0')
          .split(';')
          .join(' && ')
          .split(' = ')
          .join(' === ')
          .split(' != ')
          .join(' !== ')
          .split(' <> ')
          .join(' !== ');

      let evalErro = false;
      try {
        // Testa o eval
        // eslint-disable-next-line no-eval
        eval(substituido);
      } catch (e: any) {
        evalErro = true;
      }

      if (evalErro) {
        toast.error(
          'O regramento informado está em um formato inválido, ocasionando erros em seu teste!',
        );
        return;
      }
    }

    setIsSaving(true);

    if (descontoEditing.id) {
      await api.put(`/proposta-perfil-aprovacao/${descontoEditing.id}`, {
        id: descontoEditing.id,
        perfilId: descontoEditing.perfilId,
        tipoId: descontoEditing.tipoId ?? tipoIdFiltrar,
        valorMinimo: descontoEditing.valorMinimo,
        condicoes: descontoEditing.condicoes ?? '',
        meioPagamentoId: descontoEditing.meioPagamentoId ?? null,
        produtoId: descontoEditing.produtoId ?? null,
        statusId: descontoEditing.statusId ?? null,
      });
    } else {
      await api.post(`/proposta-perfil-aprovacao/`, {
        perfilId: descontoEditing.perfilId,
        tipoId: descontoEditing.tipoId ?? tipoIdFiltrar,
        valorMinimo: descontoEditing.valorMinimo,
        condicoes: descontoEditing.condicoes ?? '',
        meioPagamentoId: descontoEditing.meioPagamentoId ?? null,
        produtoId: descontoEditing.produtoId ?? null,
        statusId: descontoEditing.statusId ?? null,
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setIsSaving(false);
    setDescontoEditing({} as modelConfiguradorPerfilAprovacao);
    carregarDesconto();
  }

  function configurarInterface() {
    const tipoFiltrado = listaTipo.find(item => item.id === tipoIdFiltrar);

    if (tipoFiltrado) {
      const isPercentual =
        tipoFiltrado?.campoValorIsPercentual === true ?? false;
      const isReal = tipoFiltrado?.campoValorIsReal === true ?? false;

      setCampoValorIsPercentual(isPercentual);
      setCampoValorIsReal(isReal);
      setCampoValorSimbolo(`${isPercentual ? ' (%)' : isReal ? ' (R$)' : ''}`);
    }
  }

  useEffect(() => {
    if (tipoIdFiltrar && listaTipo.length) {
      configurarInterface();
    }
  }, [tipoIdFiltrar, listaTipo]);

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  disabled={
                    !tipoIdFiltrar ||
                    !listaTipo.find(item => item.id === tipoIdFiltrar) ||
                    listaTipo.find(item => item.id === tipoIdFiltrar)
                      ?.permiteInserirMultiplos === false ||
                    (listaTipo.find(item => item.id === tipoIdFiltrar)
                      ?.maximoRegistros ?? 0) >= descontos.length
                  }
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup
                        className="mb-3"
                        hidden={
                          listaTipo.find(item => item.id === tipoIdFiltrar)
                            ?.usaCampoMeioPagamento === false
                        }
                      >
                        <span>Meio de Pagamento*:</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Meio de Pagamento"
                            type="select"
                            value={descontoEditing.meioPagamentoId}
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                meioPagamentoId: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {listaMeioPagamento.map(item => {
                              return (
                                <option value={item.id}>
                                  {item.descricao}
                                </option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup
                        className="mb-3"
                        hidden={
                          listaTipo.find(item => item.id === tipoIdFiltrar)
                            ?.usaCampoCondicoes === false
                        }
                      >
                        <span>Regramento*:</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Regramento"
                            value={descontoEditing.condicoes ?? ''}
                            type="text"
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                condicoes: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                        <small>
                          <i>
                            {`Variáveis: #PrimeiraParcela# e #UltimaParcela#, separadas por ; (não usar na última condição). `}
                            <br />
                            {`Operadores aceitos: =, >=, <= e !=.`}
                          </i>
                        </small>
                      </FormGroup>

                      <FormGroup
                        className="mb-3"
                        hidden={
                          listaTipo.find(item => item.id === tipoIdFiltrar)
                            ?.usaCampoValor === false
                        }
                      >
                        <span>Valor Mínimo {campoValorSimbolo}*:</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={`Valor Mínimo ${campoValorSimbolo}`}
                            value={descontoEditing.valorMinimo ?? 0}
                            type="number"
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                valorMinimo: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup
                        className="mb-3"
                        hidden={
                          listaTipo.find(item => item.id === tipoIdFiltrar)
                            ?.usaCampoStatus === false
                        }
                      >
                        <span>Status Aprovação Final:</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Status Aprovação Final"
                            type="select"
                            value={descontoEditing.statusId}
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                statusId: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {listaPropostaStatus.map(item => {
                              return (
                                <option
                                  value={item.id}
                                  disabled={!item.indicPermiteSelecao}
                                >
                                  {item.descricao}
                                </option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <span>Perfil Aprovador*:</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Perfil Aprovador"
                            type="select"
                            value={descontoEditing.perfilId}
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                perfilId: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {listaPerfil.map(item => {
                              return (
                                <option value={item.id}>{item.nome}</option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <span>Aprovador do Produto:</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Aprovador do Produto"
                            type="select"
                            value={descontoEditing.produtoId}
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                produtoId: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Todos...</option>
                            {listaProduto.map(item => {
                              return (
                                <option value={item.id}>{item.nome}</option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <span>Tipo Aprovação*:</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo Aprovação"
                            type="select"
                            disabled
                            value={descontoEditing.tipoId ?? tipoIdFiltrar}
                            onChange={text =>
                              setDescontoEditing({
                                ...descontoEditing,
                                tipoId: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Selecione...</option>
                            {listaTipo.map(item => {
                              return (
                                <option value={item.id}>
                                  {item.descricao}
                                </option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSaveDesconto}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Perfis de Aprovação por Tipo de Recurso
                </h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <small>
                      <strong>Tipo de Recurso de Aprovação:</strong>{' '}
                    </small>
                    <Input
                      placeholder="Tipo Aprovação"
                      type="select"
                      value={tipoIdFiltrar}
                      onChange={text =>
                        setTipoIdFiltrar(Number(text.target.value))
                      }
                    >
                      <option value="">Selecione...</option>
                      {listaTipo.map(item => {
                        return (
                          <option value={item.id}>{item.descricao}</option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col sm="12">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            hidden={
                              listaTipo.find(item => item.id === tipoIdFiltrar)
                                ?.usaCampoMeioPagamento === false
                            }
                          >
                            Meio de Pagamento
                          </th>
                          <th
                            hidden={
                              listaTipo.find(item => item.id === tipoIdFiltrar)
                                ?.usaCampoCondicoes === false
                            }
                          >
                            Regramento
                          </th>
                          <th
                            hidden={
                              listaTipo.find(item => item.id === tipoIdFiltrar)
                                ?.usaCampoValor === false
                            }
                          >
                            Valor Mínimo {campoValorSimbolo}
                          </th>
                          <th
                            hidden={
                              listaTipo.find(item => item.id === tipoIdFiltrar)
                                ?.usaCampoStatus === false
                            }
                          >
                            Status
                          </th>
                          <th>Perfil Aprovador</th>
                          <th>Aprovador do Produto</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {descontos
                          .filter(item => item.tipoId === tipoIdFiltrar)
                          .map(itemDesconto => (
                            <tr key={itemDesconto.id}>
                              <td
                                className="py-2"
                                hidden={
                                  listaTipo.find(
                                    item => item.id === tipoIdFiltrar,
                                  )?.usaCampoMeioPagamento === false
                                }
                              >
                                {itemDesconto?.PropostaMeioPagamento
                                  ?.descricao ?? ''}
                              </td>

                              <td
                                className="py-2"
                                hidden={
                                  listaTipo.find(
                                    item => item.id === tipoIdFiltrar,
                                  )?.usaCampoCondicoes === false
                                }
                              >
                                {itemDesconto?.condicoes ?? ''}
                              </td>

                              <td
                                className="py-2"
                                hidden={
                                  listaTipo.find(
                                    item => item.id === tipoIdFiltrar,
                                  )?.usaCampoValor === false
                                }
                              >
                                {campoValorIsPercentual
                                  ? `${arredondaDecimais(
                                      itemDesconto.valorMinimo ?? 0,
                                      2,
                                    )}%`
                                  : campoValorIsReal
                                  ? `${formatCurrency(
                                      itemDesconto.valorMinimo ?? 0,
                                      2,
                                    )}`
                                  : itemDesconto.valorMinimo}
                              </td>

                              <td
                                className="py-2"
                                hidden={
                                  listaTipo.find(
                                    item => item.id === tipoIdFiltrar,
                                  )?.usaCampoStatus === false
                                }
                              >
                                {itemDesconto?.PropostaStatus?.descricao ?? ''}
                              </td>

                              <td className="py-2">
                                {itemDesconto.Perfil.nome}
                              </td>
                              <td className="py-2">
                                {itemDesconto.Produto?.nome || ''}
                              </td>
                              <td className="py-2">
                                <div className="float-right">
                                  <Button
                                    size="sm"
                                    className="btn-icon"
                                    color="warning"
                                    type="button"
                                    onClick={() => {
                                      handleUpdate(itemDesconto);
                                    }}
                                  >
                                    <FaEdit />
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="btn-icon"
                                    color="danger"
                                    type="button"
                                    onClick={() => {
                                      handleDelete(itemDesconto.id);
                                    }}
                                    hidden={
                                      listaTipo.find(
                                        item => item.id === tipoIdFiltrar,
                                      )?.permiteInserirMultiplos === false
                                    }
                                  >
                                    <FaTimesCircle />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorPerfilAprovacao;
