import { Input, ListItem } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import Proposta from 'models/Proposta';
import {
  PropostaMaterial,
  PropostaNegocioOracle,
} from 'models/PropostaGNFOracle';
import { PropostaVersao } from 'models/PropostaVersao';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Button, Card, CardHeader, Col, Collapse, Row } from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';
import Atividade from './components/Atividade';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
}

interface GNFGerados {
  gnf: string;
  id: number;
}

function GerarGNF(): JSX.Element {
  const location = useLocation<LocationState>();
  const { propostaVersao: pVersao } = location.state;

  const [isLoading, setIsLoading] = useState(false);

  const [geradoOficial, setGeradoOficial] = useState(false);

  const [propostaNegocio, setPropostaNegocio] = useState<
    PropostaNegocioOracle[]
  >([]);

  const [gnfGerados, setGnfGerados] = useState<GNFGerados[]>([]);

  const [listaMateriaisInvalidos, setListaMateriaisInvalidos] = useState<
    PropostaMaterial[]
  >([]);

  async function getDadosGNF() {
    setIsLoading(true);
    const response = await api.get(`/proposta-negocio/${pVersao.id}`, {});
    setPropostaNegocio(response.data?.negocio || []);
    setGeradoOficial(response.data?.geradoNegocioOficial || false);
    setIsLoading(false);
  }

  async function getGNFGerados() {
    setIsLoading(true);
    const response = await api.get(
      `/proposta-negocio/${pVersao.id}/gnf-gerados`,
      {},
    );
    setGnfGerados(response.data || []);
    setIsLoading(false);
  }

  async function processarGnf(espelho: boolean) {
    setIsLoading(true);

    Swal.fire({
      icon: 'info',
      text: `Gerando GNF ${espelho ? 'Espelho' : 'Final'}...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    try {
      await api.post(
        `/proposta-negocio/${pVersao.id}${!espelho ? `/gerar` : ''}`,
        { listaMateriaisInvalidos, possuiInformacoesPendentes: false },
      );
      Swal.close();
    } finally {
      await getDadosGNF();
      setIsLoading(false);
    }
  }
  function handleProcessarGnfEspelho() {
    Swal.fire({
      title: `Deseja executar o processamento de um GNF Espelho?`,
      text: `O processo pode demorar um pouco. Será feita uma carga de informações ao SGE. Não será gerado o GNF final ainda.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await processarGnf(true);
      }
    });
  }

  function handleProcessarGnf() {
    Swal.fire({
      title: `Deseja executar o processamento para gerar GNF Final?`,
      text: `O processo pode demorar um pouco. Será feita uma carga de informações ao SGE.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await processarGnf(false);
      }
    });
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (pVersao.id && !isLoading) {
      getDadosGNF();
    }
  }, [pVersao]);

  useEffect(() => {
    if (geradoOficial === true) {
      getGNFGerados();
    }
  }, [geradoOficial]);

  function renderListaVersao(item: PropostaNegocioOracle, itemPai = true) {
    const isOpen = propostaNegocio.find(x => x === item)?.IS_OPEN;

    function handleToggleAccordion() {
      setPropostaNegocio(
        propostaNegocio.map(x =>
          x.NEGO_NUMERO_NEGOCIO === item.NEGO_NUMERO_NEGOCIO
            ? { ...x, IS_OPEN: !x.IS_OPEN }
            : x,
        ),
      );
    }

    function renderExpand() {
      return isOpen ? (
        <ExpandLess onClick={handleToggleAccordion} />
      ) : (
        <ExpandMore onClick={handleToggleAccordion} />
      );
    }

    return (
      <div key={`proposta_versao_${item.NEGO_NUMERO_NEGOCIO}`}>
        <ListItem style={{ borderBottom: '1px dashed #b1b1b1' }}>
          <Col sm="9">
            <h3 className="mb-0">
              {item.NEGO_NUMERO_NEGOCIO}
              {geradoOficial && item.NEGO_NUMERO_NEGOCIO_SGE
                ? ` - #${item.NEGO_NUMERO_NEGOCIO_SGE}`
                : ''}
            </h3>
          </Col>

          <Col sm="3">
            <div className="float-right">Atividades {renderExpand()}</div>
          </Col>
        </ListItem>
        <Collapse isOpen>
          {isOpen === true ? (
            <>
              <Atividade
                numeroNegocio={pVersao.id}
                propostaNegocioVersao={item}
              />
            </>
          ) : (
            <></>
          )}
        </Collapse>
      </div>
    );
  }

  return (
    <>
      <UserSmallHeader />
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">Geração GNF</h5>
        </CardHeader>

        <Card className="shadow pt-3 pb-2 px-2 mt-1 mb-0 mx-1">
          <Row>
            <Col sm="4">
              <Button
                className="btn-icon btn-2"
                color="primary"
                type="button"
                outline
                disabled={isLoading}
                hidden={geradoOficial}
                onClick={handleProcessarGnfEspelho}
              >
                Carregar Dados Espelho
              </Button>
            </Col>
            <Col sm="4">
              <Button
                className="btn-icon btn-2"
                color="success"
                type="button"
                outline
                hidden={geradoOficial}
                disabled={isLoading || propostaNegocio.length === 0}
                onClick={handleProcessarGnf}
              >
                Gerar GNF Final
              </Button>
            </Col>
          </Row>
          <Row hidden={!geradoOficial}>
            <Col sm="12">
              <h3 className="mt-4">GNFs Gerados - Dados Reais Gerados</h3>

              {isLoading ? (
                <tr>
                  <td colSpan={11}>
                    <FaSpinner className="spinner" /> Carregando...
                  </td>
                </tr>
              ) : (
                gnfGerados.map((item: GNFGerados) => {
                  return (
                    <tr>
                      <td>#{item.gnf}</td>
                    </tr>
                  );
                })
              )}
            </Col>
          </Row>
          <Row hidden={geradoOficial}>
            <Col sm="12">
              <h3 className="mt-4">
                GNFs Gerados{' '}
                {geradoOficial
                  ? '- Dados Reais Gerados'
                  : '- Dados de Espelho/Simulação'}
              </h3>

              {isLoading ? (
                <tr>
                  <td colSpan={11}>
                    <FaSpinner className="spinner" /> Carregando...
                  </td>
                </tr>
              ) : (
                propostaNegocio.map(item => renderListaVersao(item))
              )}
            </Col>
          </Row>
        </Card>
      </Card>
    </>
  );
}

export default GerarGNF;
