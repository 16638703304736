import React, { useState, useEffect, ReactNode } from 'react';
import toast from 'react-hot-toast';

import {
  Card,
  Container,
  Row,
  Label,
  CardHeader,
  Input,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import { FaCheckCircle } from 'react-icons/fa';

import Swal from 'sweetalert2';

import { useLocation } from 'react-router-dom';

import Header from 'components/Headers/Header.js';
import CustomTable from 'components/CustomTable';
import Perfil from 'models/Perfil';
import AcessoPrograma from 'models/AcessoPrograma';
import ProgramaModel from 'models/Programa';
import api from '../../../../services/api';

type LocationState = {
  perfisPrograma?: Perfil | null;
};

interface CustomTableList {
  id: string;
  texto: string;
}

interface CustomTableAcesso {
  id: boolean;
  texto: string;
}

const RelacaoProgramas: ReactNode = () => {
  const [acessoProgramas, setAcessoProgramas] = useState(
    [] as AcessoPrograma[],
  );
  const [acessos, setAcessos] = useState([] as any[]);
  const location = useLocation<LocationState>();
  const perfilPrograma = location.state?.perfisPrograma ?? null;
  const [programas, setProgramas] = useState([] as ProgramaModel[]);
  const [perfis, setPerfis] = useState<Perfil>();
  const [nomePerfil, setNomePerfil] = useState('');
  const [descricaoPerfil, setDescricaoPerfil] = useState('');

  const tipo: CustomTableAcesso[] = [
    {
      id: true,
      texto: 'Sim',
    },
    {
      id: false,
      texto: 'Não',
    },
  ];

  const opcoesProgramas: CustomTableList[] = [];

  const header: any[] = [
    {
      name: 'Programa',
      prop: 'programaPerfil',
      type: 'SELECT',
      searchable: true,
      sortable: true,
      editable: false,
      required: false,
      values: opcoesProgramas,
    },
    {
      name: 'Acesso Limitado',
      prop: 'acessoLimitado',
      type: 'SELECT',
      searchable: false,
      sortable: false,
      editable: true,
      required: true,
      values: tipo,
    },
  ];

  // eslint-disable-next-line array-callback-return
  programas.map(programa => {
    // eslint-disable-next-line prettier/prettier
    opcoesProgramas.push({ id: String(programa.id), texto: programa.nome });
  });

  async function listaProgramas() {
    const response = await api.get('/programa');
    setProgramas(response.data);
  }

  async function carregaPerfil() {
    const response = await api.get(`/perfil/${perfilPrograma?.id}`);

    setPerfis(response.data);

    setNomePerfil(response.data?.nome ?? '');
    setDescricaoPerfil(response.data?.descricao ?? '');
  }

  function listaAcessoPrograma() {
    const rows: any[] = [];
    const programasPerfil = acessoProgramas.filter(
      x => x.perfilId === perfilPrograma?.id,
    );

    if (!programasPerfil.length) {
      return <Label>Nenhum programa associado.</Label>;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const acessoPrograma of programasPerfil) {
      const { programa } = acessoPrograma;
      rows.push({
        id: acessoPrograma.id,
        nome: programa.nome,
        columns: [
          {
            prop: 'programaPerfil',
            value: {
              id: programa.id,
              texto: programa.nome ? programa.nome : '',
            },
            newValue: {
              id: programa.id,
              texto: programa.nome ? programa.nome : '',
            },
          },
          {
            prop: 'acessoLimitado',
            value: {
              id: acessoPrograma.acessoLimitado,
              texto:
                tipo.filter(
                  (it: any) =>
                    String(it.id) === String(acessoPrograma.acessoLimitado),
                )[0].texto ?? '',
            },
            newValue: {
              id: acessoPrograma.acessoLimitado,
              texto:
                tipo.filter(
                  (it: any) =>
                    String(it.id) === String(acessoPrograma.acessoLimitado),
                )[0].texto ?? '',
            },
          },
        ],
        isNew: false,
        isEditing: false,
      });
    }

    setAcessos(rows);
    return rows;
  }

  async function carregaAcessoPrograma() {
    const response = await api.get('/acesso/programa');
    setAcessoProgramas(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (perfilPrograma) {
      carregaPerfil();
    }
  }, [perfilPrograma]);

  useEffect(() => {
    carregaAcessoPrograma();
  }, []);

  useEffect(() => {
    listaProgramas();
  }, []);

  useEffect(() => {
    if (acessoProgramas) {
      listaAcessoPrograma();
    }
  }, [acessoProgramas]);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Programa?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/acesso-programa/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setAcessos(acessos.filter(item => item.id !== row.id));
        }
      });
    } else {
      setAcessos(acessos.filter(item => item.id !== row.id));
    }
  }

  async function handleSave(row: any) {
    if (row.isNew) {
      const retorno = await api.post(`/acesso-programa`, {
        // eslint-disable-next-line prettier/prettier
        programaId: row.columns.filter((col: any) => col.prop === 'programaPerfil')[0].newValue.id,
        perfilId: perfilPrograma?.id,
        acessoLimitado: row.columns.filter(
          (col: any) => col.prop === 'acessoLimitado',
        )[0].newValue.id,
      });

      const registroCriado = retorno.data;

      setAcessos(
        acessos.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.id = registroCriado.id;
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    } else {
      await api.put(`/acesso-programa/${row.id}`, {
        // eslint-disable-next-line prettier/prettier
        acessoLimitado: row.columns.filter(
          (col: any) => col.prop === 'acessoLimitado',
        )[0].newValue.id,
      });

      setAcessos(
        acessos.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
            // eslint-disable-next-line no-param-reassign
            item.isNew = false;
            item.columns.map((coluna: any) => {
              // eslint-disable-next-line no-param-reassign
              coluna.value = coluna.newValue;
              return coluna;
            });
          }
          return item;
        }),
      );

      toast.success('Registro salvo');
    }
  }

  async function handleAdicionaPerfil(idPerfil: number) {
    Swal.fire({
      icon: 'info',
      text: `Editando perfil...`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    await api.put(`/perfil/${idPerfil}`, {
      nome: nomePerfil,
      descricao: descricaoPerfil,
    });

    Swal.close();
    toast.success('Registro salvo');
  }

  function handleStartEditing(row: any) {
    setAcessos(
      acessos.map(item => {
        if (item.id === row.id) {
          // eslint-disable-next-line no-param-reassign
          item.isEditing = true;
        }
        return item;
      }),
    );
  }

  function handleStopEditing(row: any) {
    if (row.isNew) {
      setAcessos(acessos.filter(item => item.id !== row.id));
    } else {
      setAcessos(
        acessos.map(item => {
          if (item.id === row.id) {
            // eslint-disable-next-line no-param-reassign
            item.isEditing = false;
          }
          return item;
        }),
      );
    }
  }

  function handleAddRow() {
    const count = acessos.length + 1;

    setAcessos([
      {
        id: count,
        columns: [
          {
            prop: 'programaPerfil',
            value: { id: null, texto: null },
            newValue: { id: null, texto: null },
          },
          {
            prop: 'acessoLimitado',
            value: { id: null, texto: null },
            newValue: { id: null, texto: null },
          },
        ],
        isNew: true,
        isEditing: true,
      },
      ...acessos,
    ]);
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <FormGroup className="mb-4">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-tag" />
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  placeholder="Nome"
                  type="text"
                  value={nomePerfil}
                  onChange={text => setNomePerfil(String(text.target.value))}
                />

                <Input
                  placeholder="Descrição"
                  type="text"
                  value={descricaoPerfil}
                  onChange={text =>
                    setDescricaoPerfil(String(text.target.value))
                  }
                />

                <Button
                  className="btn-icon btn-2"
                  color="success"
                  type="button"
                  onClick={() =>
                    handleAdicionaPerfil(Number(perfilPrograma?.id))
                  }
                >
                  <FaCheckCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </div>
        </Row>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  {perfis?.nome}
                  <span className="mb-0 ml-3 text-sm"> | Programas</span>
                </h3>
              </CardHeader>
              <div style={{ minHeight: 700, width: '100%' }}>
                {/* CustomTable */}
                <CustomTable
                  enableSearchField
                  rows={acessos}
                  header={header}
                  onDelete={handleDelete}
                  onSave={handleSave}
                  onAddRow={handleAddRow}
                  onStartEditing={handleStartEditing}
                  onStopEditing={handleStopEditing}
                />
                {/* END CustomTable */}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RelacaoProgramas;
