import { useEffect, useState } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';

import api from 'services/api';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { LabelQuestao } from './styles';

interface Props {
  onSave?: any;
  configuracaoId: number;
  orcamentoParteId: number;
  permiteSalvar?: boolean;
  salvarAutomaticamente?: boolean;
}

interface FotovoltaicaRespostas {
  siglaUf?: string | null;
  municipioIrradiacaoId?: number | null;
  distribuidoraId?: number | null;
}

interface Distribuidora {
  id: number;
  distribuidora: string;
  religadorMT?: string;
  disjuntorMT?: number | null;
  fatorPFP?: number | null;
  icmsMaior1MW: boolean;
  icmsMenor1MW: boolean;
}

interface Municipio {
  id: number;
  municipio: string;
  ufSigla: string;
  anual?: number | null;
}

export default function FotovoltaicaInfoUsinaSimplificado(
  props: Props,
): JSX.Element {
  const {
    onSave,
    orcamentoParteId,
    configuracaoId,
    permiteSalvar,
    salvarAutomaticamente,
  } = props;

  const autoSave = salvarAutomaticamente ?? false;

  const [fotovoltaicaRespostas, setFotovoltaicaRespostas] =
    useState<FotovoltaicaRespostas>({} as FotovoltaicaRespostas);

  const [municipios, setMunicipios] = useState<Municipio[]>([]);
  const [estados, setEstados] = useState<string[]>([]);
  const [distribuidoras, setDistribuidoras] = useState<Distribuidora[]>([]);

  const [houveMudancaResposta, setHouveMudancaResposta] = useState(false);

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  useEffect(() => {
    // Comando para fazer um scroll to top ao acessar a aba de Adutora.
    window.scrollTo(0, 0);
    // document.documentElement.scrollTop = 0;
  }, []);

  async function listarMunicipios() {
    const response = await api.get(`/irradiacao-municipio`);

    const listaMunicipios = response.data as Municipio[];

    const listaUf = listaMunicipios.map(item => {
      return String(item.ufSigla);
    });

    setMunicipios(listaMunicipios);
    setEstados(Array.from(new Set(listaUf)));
  }

  async function listarDistribuidoras() {
    const response = await api.get(`/fotovoltaica-distribuidoras`);
    setDistribuidoras(response.data as Distribuidora[]);
  }

  useEffect(() => {
    listarMunicipios();
    listarDistribuidoras();
  }, []);

  async function listarRespotaFotovoltaica() {
    const response = await api.get(
      `/fotovoltaica/respostas/configuracao/${configuracaoId}`,
    );

    if (response.data) {
      const obj = response.data as FotovoltaicaRespostas;
      setFotovoltaicaRespostas({
        ...obj,
        siglaUf: response.data.fotovoltaicaMunicipio?.ufSigla || '',
      });
    }
  }

  useEffect(() => {
    if (municipios.length && distribuidoras.length) {
      listarRespotaFotovoltaica();
    }
  }, [municipios, distribuidoras]);

  function checkSave(respostas: FotovoltaicaRespostas) {
    async function salvar() {
      if (respostas.municipioIrradiacaoId && respostas.distribuidoraId) {
        setSwal(`Salvando respostas para "Informações Gerais da Usina"`);

        await api.put(`/fotovoltaica/respostas`, {
          orcamentoId: configuracaoId,
          distribuidoraId: respostas.distribuidoraId ?? null,
          municipioIrradiacaoId:
            fotovoltaicaRespostas.municipioIrradiacaoId ?? null,
        });

        setSwal('');
      }
    }

    if (autoSave) {
      salvar();
    }
  }

  return (
    <>
      <div className="mt--4">
        <Row className="mt-2">
          <Col sm="6">
            <span className="float-right">
              <LabelQuestao>Estado da UC de Instalação</LabelQuestao>
            </span>
          </Col>
          <Col sm="6">
            <FormGroup className="mb-3">
              <Select
                label="Estado"
                value={fotovoltaicaRespostas.siglaUf || ''}
                onChange={event => {
                  setFotovoltaicaRespostas({
                    ...fotovoltaicaRespostas,
                    municipioIrradiacaoId: null,
                    siglaUf: event.target.value
                      ? String(event.target.value)
                      : undefined,
                  } as FotovoltaicaRespostas);
                  setHouveMudancaResposta(true);
                }}
                style={{ width: '100%' }}
              >
                <MenuItem value={undefined}>Selecione...</MenuItem>
                {estados.map((estado, indx) => {
                  return (
                    <MenuItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={`estados_${estado}_${indx}`}
                      value={estado}
                      selected={fotovoltaicaRespostas.siglaUf === estado}
                    >
                      {estado}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col sm="6">
            <span className="float-right">
              <LabelQuestao>Município da UC de Instalação</LabelQuestao>
            </span>
          </Col>
          <Col sm="6">
            <FormGroup className="mb-3">
              <Select
                label="Município"
                value={fotovoltaicaRespostas.municipioIrradiacaoId || ''}
                required
                onChange={event => {
                  setFotovoltaicaRespostas({
                    ...fotovoltaicaRespostas,
                    municipioIrradiacaoId: event.target.value
                      ? Number(event.target.value)
                      : undefined,
                  } as FotovoltaicaRespostas);
                  setHouveMudancaResposta(true);
                  checkSave({
                    ...fotovoltaicaRespostas,
                    municipioIrradiacaoId: event.target.value
                      ? Number(event.target.value)
                      : undefined,
                  } as FotovoltaicaRespostas);
                }}
                style={{ width: '100%' }}
                disabled={!fotovoltaicaRespostas.siglaUf}
              >
                <MenuItem value={undefined}>Selecione...</MenuItem>
                {municipios
                  .filter(
                    item => item.ufSigla === fotovoltaicaRespostas.siglaUf,
                  )
                  .map((municipio, indx) => {
                    return (
                      <MenuItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={`municipio_${municipio.id}_${indx}`}
                        value={municipio.id}
                        selected={
                          fotovoltaicaRespostas.municipioIrradiacaoId ===
                          municipio.id
                        }
                      >
                        {municipio.municipio}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="6">
            <span className="float-right">
              <LabelQuestao>Distribuidora de Energia</LabelQuestao>
            </span>
          </Col>
          <Col sm="6">
            <FormGroup className="mb-3">
              <Select
                label="Distribuidora"
                value={fotovoltaicaRespostas.distribuidoraId || ''}
                required
                onChange={event => {
                  setFotovoltaicaRespostas({
                    ...fotovoltaicaRespostas,
                    distribuidoraId: event.target.value
                      ? Number(event.target.value)
                      : undefined,
                  } as FotovoltaicaRespostas);
                  setHouveMudancaResposta(true);
                  checkSave({
                    ...fotovoltaicaRespostas,
                    distribuidoraId: event.target.value
                      ? Number(event.target.value)
                      : undefined,
                  } as FotovoltaicaRespostas);
                }}
                style={{ width: '100%' }}
              >
                <MenuItem value={undefined}>Selecione...</MenuItem>
                {distribuidoras.map((distribuidora, indx) => {
                  return (
                    <MenuItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={`distribuidora_${distribuidora.id}_${indx}`}
                      value={distribuidora.id}
                      selected={
                        fotovoltaicaRespostas.distribuidoraId ===
                        distribuidora.id
                      }
                    >
                      {distribuidora.distribuidora}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
