import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import formataCnpjCpf from 'utils/formataCnpjCpf';
import { PropostaImpressaoContext } from '../..';
import { useAuth } from '../../../../../../../hooks/auth';
import { AceiteTexto, AssinaturaCol, Container, Label } from './styles';

export default function AceiteClausulas(): JSX.Element {
  const { usuario } = useAuth();
  const { proposta } = useContext(PropostaImpressaoContext);
  const { pessoa } = proposta;

  return (
    <Container>
      <AceiteTexto>
        O Cliente Declara ter lido e estar acordado com as Cláusulas e Condições
        de Venda e de Fornecimento, que são partes anexas integrantes desta
        Proposta Comercial.
      </AceiteTexto>

      <Row className="my-5">
        <Col style={{ marginBottom: '50px' }}>
          <Label>Atenciosamente,</Label>
        </Col>
      </Row>
      {/* Ajuste assinatura */}

      <Row>
        <AssinaturaCol>
          <div className="text-center">
            <Label>{proposta?.usuario.nome}</Label>
          </div>
          <div className="text-center">
            <Label>Representante /Ger. de Negócios Irrigação</Label>
          </div>
        </AssinaturaCol>
        <Col md="1" />
        <AssinaturaCol>
          <div className="text-center">
            <Label>{pessoa?.razaoSocial}</Label>
          </div>
          <div className="text-center" style={{ marginTop: '50px' }}>
            <Label>{formataCnpjCpf(pessoa?.documento)}</Label>
          </div>
        </AssinaturaCol>
      </Row>
    </Container>
  );
}
