import { Typography as TypographyMui } from '@material-ui/core';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* page-break-after: always; */
`;

export const Descricao = styled(Label)`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  color: #2a2a2a;
`;

export const Atividade = styled(Label)`
  font-size: 1.2rem;
  font-weight: bold;
  color: #2a2a2a;
  page-break-before: auto;
`;

export const HorizontalRule = styled.hr`
  margin-top: 1rem;
  background-color: #000;
  height: 0.5px;
`;

export const Item = styled(Label)`
  color: #000;
  font-weight: 400;
  margin: 0;
`;

export const Typography = styled(TypographyMui)`
  color: #000;
  text-align: center;
`;

export const DecricaoTotal = styled(Label)`
  color: #000;
`;

export const DecricaoTotalDestaque = styled(Label)`
  color: #222;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: bold;
`;
