import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .MuiAutocomplete-listbox {
    font-size: 13px;
  }

  .MuiInputBase-root {
    height: 43px !important;
  }

  .ant-select-selector {
    height: 43px !important;
    border-radius: 4px !important;
    padding-top: 5px !important;
}
`;

export default GlobalStyle;
