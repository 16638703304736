import { useContext, useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import api from 'services/api';

import { InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import {
  FaArrowCircleRight,
  FaBuilding,
  FaCheck,
  FaCheckCircle,
  FaPen,
  FaPlusCircle,
  FaTrash,
} from 'react-icons/fa';

import { arredondaDecimais } from 'utils/arredondaDecimais';
import Material from 'models/Material';
import triggerValidaRegras from 'services/triggerValidaRegras';
import Orcamento from 'models/Orcamento';
import {
  ColQuestao,
  ColQuestaoTitulo,
  DropdownItemLabeL,
  DropdownSelect,
  LabelQuestao,
  LabelQuestaoTitulo,
} from './styles';

interface Props {
  onSave?: any;
  onBloquearPartes?: any;
  configuracaoId: number;
  orcamentoParteId: number;
  setSessaoId?: any;
  setFichaTecnicaId?: any;
}

interface Fotovoltaica {
  id: number;
  orcamentoId: number;
  concessionaria?: string;
  numeroUC: string;
  grupoUC?: string;
  nomeTitularUC?: string;
  documentoTitularUC?: string;
  categoriaUC?: string;
  tipoLigacao?: string;
  tensaoUC?: number;
  demContratada?: number;
  ppUC?: number;
  tarifaPonderada?: number;
  consumoTotalPostoTarifario?: number;
  incluirHRESCalculo: boolean;
  indicaUcConexao: boolean;
  incluirCalculoUsina: boolean;
  FotovoltaicaUcConsumo: FotovoltaicaConsumo[];
}

interface FotovoltaicaTipoCarga {
  id: number;
  sigla: string;
  descricao?: string;
  incluirSomatorio: boolean;
  indicaMediaTotal: boolean;
}

interface FotovoltaicaConsumo {
  id: number;
  fotovoltaicaUcId: number;
  fotovoltaicaTipoCargaId: number;
  tarifa?: number;
  consumoJaneiro?: number;
  consumoFevereiro?: number;
  consumoMarco?: number;
  consumoAbril?: number;
  consumoMaio?: number;
  consumoJunho?: number;
  consumoJulho?: number;
  consumoAgosto?: number;
  consumoSetembro?: number;
  consumoOutubro?: number;
  consumoNovembro?: number;
  consumoDezembro?: number;
  consumoTotal?: number;
  tarifaPonderada?: number;
}

interface Distribuidora {
  id: number;
  distribuidora: string;
  religadorMT?: string;
  disjuntorMT?: number | null;
  fatorPFP?: number | null;
  icmsMaior1MW: boolean;
  icmsMenor1MW: boolean;
}

interface Municipio {
  id: number;
  municipio: string;
  ufSigla: string;
  anual?: number | null;
}

interface FotovoltaicaRespostas {
  siglaUf?: string | null;
  municipioIrradiacaoId?: number | null;
  distribuidoraId?: number | null;
}

interface GrupoTensao {
  tensao: number;
  unidade: string;
}

interface Grupo {
  grupo: string;
  limitaLigacaoUV: boolean;
  ligacaoUVPadrao?: string | null;
  informaDemandaContratada: boolean;
  incluirDEM: boolean;
  incluirHP: boolean;
  tensao: GrupoTensao[];
}

export default function FotovoltaicaCadastroUC(props: Props): JSX.Element {
  const {
    onSave,
    onBloquearPartes,
    orcamentoParteId,
    configuracaoId,
    setFichaTecnicaId,
    setSessaoId,
  } = props;

  const [listaFotovoltaica, setListaFotovoltaica] = useState<Fotovoltaica[]>(
    [],
  );

  const [listaFotovoltaicaConsumo, setListaFotovoltaicaConsumo] = useState<
    FotovoltaicaConsumo[]
  >([]);

  const [fotovoltaicaTipoCarga, setFotovoltaicaTipoCarga] = useState<
    FotovoltaicaTipoCarga[]
  >([]);

  const [municipios, setMunicipios] = useState<Municipio[]>([]);
  const [estados, setEstados] = useState<string[]>([]);

  const [distribuidoras, setDistribuidoras] = useState<Distribuidora[]>([]);

  const [salvandoConsumo, setSalvandoConsumo] = useState(false);
  const [salvandoUC, setSalvandoUC] = useState(false);
  const [salvandoChecks, setSalvandoChecks] = useState(false);

  const [fotovoltaicaEditing, setFotovoltaicaEditing] = useState(
    {} as Fotovoltaica,
  );

  const [valorTensaoUC, setValorTensaoUC] = useState(0);
  const [formModalState, setFormModalState] = useState(false);
  const [existemValoresManuais, setExistemValoresManuais] = useState(false);

  const orcamentoIdFiltrar = configuracaoId || 0;
  const [orcamentos, setOrcamentos] = useState({} as Orcamento);

  const listaGrupos: Grupo[] = [
    {
      grupo: 'A',
      limitaLigacaoUV: true,
      ligacaoUVPadrao: 'Trifásico',
      informaDemandaContratada: true,
      incluirDEM: true,
      incluirHP: true,
      tensao: [
        {
          tensao: 13.8,
          unidade: 'kV',
        },
        {
          tensao: 23.1,
          unidade: 'kV',
        },
        {
          tensao: 34.5,
          unidade: 'kV',
        },
      ],
    },
    {
      grupo: 'B',
      limitaLigacaoUV: false,
      ligacaoUVPadrao: '',
      informaDemandaContratada: false,
      incluirDEM: false,
      incluirHP: false,
      tensao: [
        {
          tensao: 127,
          unidade: 'V',
        },
        {
          tensao: 220,
          unidade: 'V',
        },
        {
          tensao: 380,
          unidade: 'V',
        },
      ],
    },
  ];

  useEffect(() => {
    // Comando para fazer um scroll to top ao acessar a aba de Adutora.
    window.scrollTo(0, 0);
    // document.documentElement.scrollTop = 0;
  }, []);

  const [grupoSelecionado, setGrupoSelecionado] = useState({} as Grupo);

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  async function listarFotovoltaica() {
    const response = await api.get(
      `/fotovoltaica?orcamentoId=${orcamentoIdFiltrar}`,
    );

    const consumo: FotovoltaicaConsumo[] = [];
    const registros: Fotovoltaica[] = response.data;

    // eslint-disable-next-line no-restricted-syntax
    for await (const item of registros) {
      if (item.FotovoltaicaUcConsumo && item.FotovoltaicaUcConsumo.length)
        consumo.push(...item.FotovoltaicaUcConsumo);
    }

    setListaFotovoltaica(response.data);
    setListaFotovoltaicaConsumo(consumo);
  }

  async function listarFotovoltaicaTipoCarga() {
    const response = await api.get(`/fotovoltaica-tipo-carga`);
    setFotovoltaicaTipoCarga(response.data);
  }

  async function carregaOrcamento() {
    const response = await api.get(`/orcamento/${configuracaoId}`);
    if (response.data) setOrcamentos(response.data as Orcamento);
  }

  async function desativarDemaisEtapas() {
    if (onBloquearPartes) props.onBloquearPartes();
  }

  useEffect(() => {
    listarFotovoltaicaTipoCarga();
  }, []);

  useEffect(() => {
    if (configuracaoId) {
      carregaOrcamento();
    }
  }, [configuracaoId]);

  useEffect(() => {
    if (orcamentoIdFiltrar && fotovoltaicaTipoCarga.length > 0) {
      listarFotovoltaica();
    }
  }, [fotovoltaicaTipoCarga, orcamentoIdFiltrar]);

  async function handleDeleteFotovoltaicaUC(id: number) {
    Swal.fire({
      title: `Deseja excluir o registro de fotovoltaica e suas informações de consumo?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/fotovoltaica/${id}?deleteAll=S`, {});
        Swal.close();
        toast.success('Registro excluído');
        desativarDemaisEtapas();
        listarFotovoltaica();
      }
    });
  }

  function handleGrupoChange(grupo: string, atualizar = true): void {
    const filtroGrupo = listaGrupos.filter(item => item.grupo === grupo);

    const grupoFiltrado = filtroGrupo[0] || ({} as Grupo);

    setGrupoSelecionado(grupoFiltrado);

    if (atualizar)
      setFotovoltaicaEditing({
        ...fotovoltaicaEditing,
        grupoUC: grupo,
        tipoLigacao:
          grupoFiltrado?.limitaLigacaoUV ?? false
            ? grupoFiltrado?.ligacaoUVPadrao || ''
            : fotovoltaicaEditing.tipoLigacao,
        demContratada:
          grupoFiltrado?.informaDemandaContratada ?? true
            ? fotovoltaicaEditing.demContratada
            : 0,
      });
  }

  async function handleEditFotovoltaicaUC(item: Fotovoltaica) {
    const filtroGrupo = listaGrupos.filter(
      grupo => grupo.grupo === item?.grupoUC ?? '',
    );

    const grupoFiltrado = filtroGrupo[0] || ({} as Grupo);

    setFotovoltaicaEditing({
      ...item,
      tipoLigacao:
        grupoFiltrado?.limitaLigacaoUV ?? false
          ? grupoFiltrado?.ligacaoUVPadrao || ''
          : item.tipoLigacao,
      demContratada:
        grupoFiltrado?.informaDemandaContratada ?? true
          ? item.demContratada
          : 0,
    });
    setGrupoSelecionado(grupoFiltrado);
    setFormModalState(!formModalState);
  }

  async function handleInsertFotovoltaicaUC() {
    setGrupoSelecionado({} as Grupo);
    setFotovoltaicaEditing({
      tipoLigacao: 'Trifásico',
    } as Fotovoltaica);
    setFormModalState(!formModalState);
  }

  function calcularTarifaPonderada(valor1: number, valor2: number) {
    if (!valor1 || !valor2) return 0;

    const valor = Number(valor1 / valor2);

    return arredondaDecimais(valor, 4);
  }

  async function calcularTotalFotovoltaica(fotovoltaicaId: number) {
    const consumo = listaFotovoltaicaConsumo.filter(
      reg => reg.fotovoltaicaUcId === fotovoltaicaId,
    );

    const varIncluirHRESCalculo = listaFotovoltaica.filter(
      item => item.id === fotovoltaicaId,
    )?.[0].incluirHRESCalculo;

    const consumoRowTotal = {
      fotovoltaicaUcId: fotovoltaicaId,
      consumoJaneiro: 0,
      consumoFevereiro: 0,
      consumoMarco: 0,
      consumoAbril: 0,
      consumoMaio: 0,
      consumoJunho: 0,
      consumoJulho: 0,
      consumoAgosto: 0,
      consumoSetembro: 0,
      consumoOutubro: 0,
      consumoNovembro: 0,
      consumoDezembro: 0,
      consumoTotal: 0,
      tarifaPonderada: 0,
    } as any;

    let tarifaTotal = 0;
    let tarifaDivisao = 0;

    // eslint-disable-next-line no-restricted-syntax
    for await (const tipo of fotovoltaicaTipoCarga) {
      const { id } = tipo;

      const consumoTipo = consumo.filter(
        valor => valor.fotovoltaicaTipoCargaId === id,
      );

      const consumoRow: FotovoltaicaConsumo = consumoTipo[0];

      const consumoTotal =
        Number(consumoRow?.consumoJaneiro || 0) +
        Number(consumoRow?.consumoFevereiro || 0) +
        Number(consumoRow?.consumoMarco || 0) +
        Number(consumoRow?.consumoAbril || 0) +
        Number(consumoRow?.consumoMaio || 0) +
        Number(consumoRow?.consumoJunho || 0) +
        Number(consumoRow?.consumoJulho || 0) +
        Number(consumoRow?.consumoAgosto || 0) +
        Number(consumoRow?.consumoSetembro || 0) +
        Number(consumoRow?.consumoOutubro || 0) +
        Number(consumoRow?.consumoNovembro || 0) +
        Number(consumoRow?.consumoDezembro || 0);

      if (tipo.incluirSomatorio) {
        consumoRowTotal.consumoTotal += Number(consumoTotal || 0);
        consumoRowTotal.consumoJaneiro += Number(
          consumoRow?.consumoJaneiro || 0,
        );
        consumoRowTotal.consumoFevereiro += Number(
          consumoRow?.consumoFevereiro || 0,
        );
        consumoRowTotal.consumoMarco += Number(consumoRow?.consumoMarco || 0);
        consumoRowTotal.consumoAbril += Number(consumoRow?.consumoAbril || 0);
        consumoRowTotal.consumoMaio += Number(consumoRow?.consumoMaio || 0);
        consumoRowTotal.consumoJunho += Number(consumoRow?.consumoJunho || 0);
        consumoRowTotal.consumoJulho += Number(consumoRow?.consumoJulho || 0);
        consumoRowTotal.consumoAgosto += Number(consumoRow?.consumoAgosto || 0);
        consumoRowTotal.consumoSetembro += Number(
          consumoRow?.consumoSetembro || 0,
        );
        consumoRowTotal.consumoOutubro += Number(
          consumoRow?.consumoOutubro || 0,
        );
        consumoRowTotal.consumoNovembro += Number(
          consumoRow?.consumoNovembro || 0,
        );
        consumoRowTotal.consumoDezembro += Number(
          consumoRow?.consumoDezembro || 0,
        );

        if (consumoRow?.tarifa && consumoTotal) {
          if (
            (tipo.sigla === 'HRES' && varIncluirHRESCalculo) ||
            tipo.sigla !== 'HRES'
          ) {
            tarifaTotal +=
              Number(consumoRow?.tarifa ?? 0) * Number(consumoTotal || 0);
            tarifaDivisao += Number(consumoTotal || 0);
          }
        }
      }
    }

    consumoRowTotal.tarifaPonderada = Number(
      calcularTarifaPonderada(tarifaTotal, tarifaDivisao),
    );

    return consumoRowTotal;
  }

  async function handleSalvarConsumoFotovoltaicaUC(
    id: number,
    tipoEditado?: any,
  ) {
    const fotovoltaicaAtualizar = listaFotovoltaicaConsumo.filter(
      item => item.fotovoltaicaUcId === id,
    );

    const totalSalvar = await calcularTotalFotovoltaica(id);

    const fotovoltaicaValores = listaFotovoltaica.filter(
      item => item.id === id,
    );

    try {
      setSalvandoConsumo(true);
      toast.loading('Atualizando dados consumo...');

      // eslint-disable-next-line no-restricted-syntax
      for await (const configuracao of fotovoltaicaAtualizar) {
        const consumoTotal =
          Number(configuracao.consumoJaneiro || 0) +
          Number(configuracao.consumoFevereiro || 0) +
          Number(configuracao.consumoMarco || 0) +
          Number(configuracao.consumoAbril || 0) +
          Number(configuracao.consumoMaio || 0) +
          Number(configuracao.consumoJunho || 0) +
          Number(configuracao.consumoJulho || 0) +
          Number(configuracao.consumoAgosto || 0) +
          Number(configuracao.consumoSetembro || 0) +
          Number(configuracao.consumoOutubro || 0) +
          Number(configuracao.consumoNovembro || 0) +
          Number(configuracao.consumoDezembro || 0);

        if (configuracao.id && configuracao.id !== 0)
          await api.put(`/fotovoltaica-consumo/${configuracao.id}`, {
            ...configuracao,
            consumoTotal,
          });
        else
          await api.post(`/fotovoltaica-consumo`, {
            ...configuracao,
            consumoTotal,
          });
      }

      const data = tipoEditado || fotovoltaicaValores?.[0];

      // dispara update pra salvar as informações na UC
      await api.put(`/fotovoltaica/${id}`, {
        ...data,
        tarifaPonderada: totalSalvar.tarifaPonderada,
        consumoTotalPostoTarifario: totalSalvar.consumoTotal,
        calcularPotenciaPico: true,
        orcamentoParteId: orcamentoParteId || null,
      });
      desativarDemaisEtapas();
    } finally {
      toast.dismiss();
      setSalvandoConsumo(false);
      toast.success('Alterações salvas');
      listarFotovoltaica();
    }
  }

  function handleChangeValueConsumo(
    fotovoltaicaId: number,
    fotovoltaicaConsumoId: number,
    name: string,
    value: number,
  ) {
    const fotovoltaicaUcConsumoAtualizar = listaFotovoltaicaConsumo.map(item =>
      item.fotovoltaicaUcId === fotovoltaicaId &&
      item.fotovoltaicaTipoCargaId === fotovoltaicaConsumoId
        ? {
            ...item,
            [name]: Number(value),
          }
        : item,
    );

    setListaFotovoltaicaConsumo(fotovoltaicaUcConsumoAtualizar);
  }

  async function handleSaveFotovoltaica() {
    const { numeroUC, tipoLigacao, grupoUC } = fotovoltaicaEditing;
    if (!numeroUC || !tipoLigacao || !grupoUC) {
      toast.error('Alguns campos não foram preenchidos!');
      return;
    }

    let retornoEdicao = null;
    try {
      setSalvandoUC(true);
      toast.loading('Salvando...');
      if (fotovoltaicaEditing.id) {
        const retorno = await api.put(
          `/fotovoltaica/${fotovoltaicaEditing.id}`,
          {
            numeroUC: fotovoltaicaEditing.numeroUC,
            grupoUC: fotovoltaicaEditing.grupoUC,
            nomeTitularUC: fotovoltaicaEditing.nomeTitularUC,
            documentoTitularUC: fotovoltaicaEditing.documentoTitularUC,
            categoriaUC: fotovoltaicaEditing.categoriaUC,
            orcamentoId: orcamentoIdFiltrar,
            demContratada: fotovoltaicaEditing.demContratada,
            tipoLigacao: fotovoltaicaEditing.tipoLigacao,
            ppUC: fotovoltaicaEditing.ppUC,
            tarifaPonderada: fotovoltaicaEditing.tarifaPonderada,
            tensaoUC: fotovoltaicaEditing.tensaoUC,
          },
        );

        retornoEdicao = retorno.data;
      } else {
        await api.post('/fotovoltaica/', {
          numeroUC: fotovoltaicaEditing.numeroUC,
          grupoUC: fotovoltaicaEditing.grupoUC,
          nomeTitularUC: fotovoltaicaEditing.nomeTitularUC,
          documentoTitularUC: fotovoltaicaEditing.documentoTitularUC,
          categoriaUC: fotovoltaicaEditing.categoriaUC,
          orcamentoId: orcamentoIdFiltrar,
          demContratada: fotovoltaicaEditing.demContratada,
          tipoLigacao: fotovoltaicaEditing.tipoLigacao,
          ppUC: fotovoltaicaEditing.ppUC,
          tarifaPonderada: fotovoltaicaEditing.tarifaPonderada,
          tensaoUC: fotovoltaicaEditing.tensaoUC,
        });
      }
      setFormModalState(false);
      desativarDemaisEtapas();
      toast.success('Registro salvo');
    } finally {
      toast.dismiss();

      if (fotovoltaicaEditing.id) {
        await handleSalvarConsumoFotovoltaicaUC(
          fotovoltaicaEditing.id,
          retornoEdicao,
        );
      }
      listarFotovoltaica();
      setSalvandoUC(false);
      setFotovoltaicaEditing({} as Fotovoltaica);
    }
  }

  async function handleSaveFotovoltaicaInline({
    id,
    incluirCalculoUsina,
    incluirHRESCalculo,
    indicaUcConexao,
  }: {
    id: number;
    incluirCalculoUsina?: boolean | undefined;
    incluirHRESCalculo?: boolean | undefined;
    indicaUcConexao?: boolean | undefined;
  }) {
    if (!id) {
      toast.error('Alguns campos não foram preenchidos!');
      return;
    }

    try {
      setSalvandoUC(true);
      setSalvandoChecks(true);
      toast.loading('Salvando...');
      await api.put(`/fotovoltaica/${id}/diversos`, {
        orcamentoId: orcamentoIdFiltrar,
        incluirCalculoUsina,
        incluirHRESCalculo,
        indicaUcConexao,
      });

      toast.dismiss();
      await handleSalvarConsumoFotovoltaicaUC(id);
      desativarDemaisEtapas();

      setFormModalState(false);
    } finally {
      toast.dismiss();
      listarFotovoltaica();
      setSalvandoUC(false);
      setSalvandoChecks(false);
    }
  }

  function renderFotovoltaicaConsumoTotal(item: FotovoltaicaConsumo) {
    if (!item) {
      return <></>;
    }
    const {
      consumoJaneiro,
      consumoFevereiro,
      consumoMarco,
      consumoAbril,
      consumoMaio,
      consumoJunho,
      consumoJulho,
      consumoAgosto,
      consumoSetembro,
      consumoOutubro,
      consumoNovembro,
      consumoDezembro,
      consumoTotal,
      tarifaPonderada,
    } = item;

    return (
      <tr
        style={{
          padding: 0,
          margin: 0,
          fontStyle: 'italic',
        }}
      >
        <td>Total Mensal</td>
        <td>{tarifaPonderada || 0}</td>
        <td>{consumoJaneiro || 0}</td>
        <td>{consumoFevereiro || 0}</td>
        <td>{consumoMarco || 0}</td>
        <td>{consumoAbril || 0}</td>
        <td>{consumoMaio || 0}</td>
        <td>{consumoJunho || 0}</td>
        <td>{consumoJulho || 0}</td>
        <td>{consumoAgosto || 0}</td>
        <td>{consumoSetembro || 0}</td>
        <td>{consumoOutubro || 0}</td>
        <td>{consumoNovembro || 0}</td>
        <td>{consumoDezembro || 0}</td>
        <td>{consumoTotal || 0}</td>
      </tr>
    );
  }

  function renderFotovoltaicaConsumoList(item: Fotovoltaica) {
    if (!item) {
      return <></>;
    }

    const grupoFiltrado = listaGrupos.filter(
      grupo => grupo.grupo === item.grupoUC,
    );

    const grupo = grupoFiltrado[0];

    const incluirDEM = grupo?.incluirDEM ?? true;
    const incluirHP = grupo?.incluirHP ?? true;

    const consumo = listaFotovoltaicaConsumo.filter(
      reg => reg.fotovoltaicaUcId === item.id,
    );

    const listaConsumoAtualizar: FotovoltaicaConsumo[] = [];

    const consumoRowTotal = {
      fotovoltaicaUcId: item.id,
      consumoJaneiro: 0,
      consumoFevereiro: 0,
      consumoMarco: 0,
      consumoAbril: 0,
      consumoMaio: 0,
      consumoJunho: 0,
      consumoJulho: 0,
      consumoAgosto: 0,
      consumoSetembro: 0,
      consumoOutubro: 0,
      consumoNovembro: 0,
      consumoDezembro: 0,
      consumoTotal: 0,
      tarifaPonderada: 0,
    } as any;

    let tarifaTotal = 0;
    let tarifaDivisao = 0;

    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Tarifa</th>
              <th>Jan</th>
              <th>Fev</th>
              <th>Mar</th>
              <th>Abr</th>
              <th>Mai</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Ago</th>
              <th>Set</th>
              <th>Out</th>
              <th>Nov</th>
              <th>Dez</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {fotovoltaicaTipoCarga.map(tipo => {
              const { id, sigla, descricao } = tipo;

              if (!incluirDEM && String(sigla).toUpperCase() === 'DEM')
                return <></>;

              if (!incluirHP && String(sigla).toUpperCase() === 'HP')
                return <></>;

              const consumoTipo = consumo.filter(
                valor => valor.fotovoltaicaTipoCargaId === id,
              );

              let consumoRow: FotovoltaicaConsumo = consumoTipo[0];

              if (!consumoTipo || !consumoTipo.length) {
                consumoRow = {
                  id: 0,
                  fotovoltaicaUcId: item.id,
                  fotovoltaicaTipoCargaId: id,
                  tarifa: 0,
                  consumoJaneiro: 0,
                  consumoFevereiro: 0,
                  consumoMarco: 0,
                  consumoAbril: 0,
                  consumoMaio: 0,
                  consumoJunho: 0,
                  consumoJulho: 0,
                  consumoAgosto: 0,
                  consumoSetembro: 0,
                  consumoOutubro: 0,
                  consumoNovembro: 0,
                  consumoDezembro: 0,
                  consumoTotal: 0,
                } as FotovoltaicaConsumo;

                setListaFotovoltaicaConsumo([
                  ...listaFotovoltaicaConsumo,
                  consumoRow,
                ]);

                listaConsumoAtualizar.push({ ...consumoRow });
              }

              let incluirValor = false;

              if (tipo.incluirSomatorio) incluirValor = true;

              /* if (
                (tipo.sigla === 'HRES' &&
                  item.incluirHRESCalculo &&
                  tipo.incluirSomatorio) ||
                (tipo.sigla !== 'HRES' && tipo.incluirSomatorio)
              )
                incluirValor = true; */

              let consumoTotal =
                Number(consumoRow?.consumoJaneiro || 0) +
                Number(consumoRow?.consumoFevereiro || 0) +
                Number(consumoRow?.consumoMarco || 0) +
                Number(consumoRow?.consumoAbril || 0) +
                Number(consumoRow?.consumoMaio || 0) +
                Number(consumoRow?.consumoJunho || 0) +
                Number(consumoRow?.consumoJulho || 0) +
                Number(consumoRow?.consumoAgosto || 0) +
                Number(consumoRow?.consumoSetembro || 0) +
                Number(consumoRow?.consumoOutubro || 0) +
                Number(consumoRow?.consumoNovembro || 0) +
                Number(consumoRow?.consumoDezembro || 0);

              if (incluirValor) {
                consumoRowTotal.consumoTotal += Number(consumoTotal || 0);
                consumoRowTotal.consumoJaneiro += Number(
                  consumoRow?.consumoJaneiro || 0,
                );
                consumoRowTotal.consumoFevereiro += Number(
                  consumoRow?.consumoFevereiro || 0,
                );
                consumoRowTotal.consumoMarco += Number(
                  consumoRow?.consumoMarco || 0,
                );
                consumoRowTotal.consumoAbril += Number(
                  consumoRow?.consumoAbril || 0,
                );
                consumoRowTotal.consumoMaio += Number(
                  consumoRow?.consumoMaio || 0,
                );
                consumoRowTotal.consumoJunho += Number(
                  consumoRow?.consumoJunho || 0,
                );
                consumoRowTotal.consumoJulho += Number(
                  consumoRow?.consumoJulho || 0,
                );
                consumoRowTotal.consumoAgosto += Number(
                  consumoRow?.consumoAgosto || 0,
                );
                consumoRowTotal.consumoSetembro += Number(
                  consumoRow?.consumoSetembro || 0,
                );
                consumoRowTotal.consumoOutubro += Number(
                  consumoRow?.consumoOutubro || 0,
                );
                consumoRowTotal.consumoNovembro += Number(
                  consumoRow?.consumoNovembro || 0,
                );
                consumoRowTotal.consumoDezembro += Number(
                  consumoRow?.consumoDezembro || 0,
                );

                if (consumoRow.tarifa && consumoTotal) {
                  tarifaTotal +=
                    Number(consumoRow.tarifa || 0) * Number(consumoTotal || 0);
                  tarifaDivisao += Number(consumoTotal || 0);
                }
              }

              // Se a flag for de média
              if (tipo.indicaMediaTotal) {
                consumoTotal = arredondaDecimais(consumoTotal / 12, 2);
              }

              const styleTd = {
                padding: 5,
              };

              return (
                <tr
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Tooltip title={descricao || 'Sem descrição'}>
                    <td className="pr-0">{sigla}</td>
                  </Tooltip>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      className="input-sm"
                      value={consumoRow?.tarifa || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'tarifa',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoJaneiro || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoJaneiro',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoFevereiro || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoFevereiro',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoMarco || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoMarco',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoAbril || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoAbril',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoMaio || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoMaio',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoJunho || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoJunho',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoJulho || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoJulho',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoAgosto || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoAgosto',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoSetembro || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoSetembro',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoOutubro || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoOutubro',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoNovembro || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoNovembro',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td style={styleTd}>
                    <Input
                      type="number"
                      value={consumoRow?.consumoDezembro || 0}
                      onChange={event =>
                        handleChangeValueConsumo(
                          item.id,
                          id,
                          'consumoDezembro',
                          Number(event.target.value),
                        )
                      }
                    />
                  </td>
                  <td>{consumoTotal || 0}</td>
                </tr>
              );
            })}

            {renderFotovoltaicaConsumoTotal({
              ...consumoRowTotal,
              tarifaPonderada: calcularTarifaPonderada(
                tarifaTotal,
                tarifaDivisao,
              ),
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  function renderFotovoltaicaList(lista: Fotovoltaica[]) {
    if (!lista) {
      return <></>;
    }

    return (
      <div>
        {lista.map(item => {
          const {
            id,
            numeroUC,
            grupoUC,
            nomeTitularUC,
            documentoTitularUC,
            categoriaUC,
            incluirCalculoUsina,
            incluirHRESCalculo,
            indicaUcConexao,
            ppUC,
          } = item;

          const styleConexao = indicaUcConexao
            ? {
                backgroundColor: '#f4f4f4',
                paddingTop: '5px',
                paddingLeft: '5px',
                paddingRight: '5px',
              }
            : {};

          return (
            <div style={styleConexao} className="mt-2">
              <Row>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <strong>UC:</strong> {numeroUC}
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <strong>Grupo:</strong> {grupoUC}
                </Col>
                <Col sm="4" style={{ fontSize: '14px' }}>
                  <strong>{nomeTitularUC}</strong> (
                  <small>{documentoTitularUC}</small>)
                </Col>
                <Col sm="4">
                  {' '}
                  <div
                    className="float-right"
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      className="btn-icon btn-2"
                      size="sm"
                      color="primary"
                      type="button"
                      disabled={salvandoConsumo}
                      onClick={() => {
                        handleSalvarConsumoFotovoltaicaUC(id);
                      }}
                    >
                      <FaCheck /> Salvar Consumo
                    </Button>
                    <Button
                      className="btn-icon btn-2"
                      size="sm"
                      color="primary"
                      type="button"
                      title="Abrir modal de edição"
                      onClick={() => {
                        handleEditFotovoltaicaUC(item);
                      }}
                    >
                      <FaPen />
                    </Button>
                    <Button
                      className="btn-icon btn-2"
                      size="sm"
                      color="danger"
                      type="button"
                      title="Excluir UC"
                      onClick={() => {
                        handleDeleteFotovoltaicaUC(id);
                      }}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <strong>Categoria UC:</strong> <small>{categoriaUC}</small>
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <FormGroup>
                    <strong
                      style={{
                        display: 'flex',
                        float: 'left',
                      }}
                    >
                      Status UC:
                    </strong>
                    <Tooltip
                      title="Informe se a UC esta ativa e deve ser incluída no calculo"
                      style={{
                        display: 'flex',
                        float: 'left',
                      }}
                    >
                      <label className="custom-toggle ml-2">
                        <input
                          defaultChecked
                          checked={incluirCalculoUsina}
                          type="checkbox"
                          disabled={salvandoChecks}
                          onChange={text =>
                            handleSaveFotovoltaicaInline({
                              id,
                              incluirCalculoUsina: text.target.checked,
                            })
                          }
                          style={{ marginTop: '10px' }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Não"
                          data-label-on="Sim"
                        />
                      </label>
                    </Tooltip>
                  </FormGroup>
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <FormGroup className="mb-3">
                    <strong
                      style={{
                        display: 'flex',
                        float: 'left',
                      }}
                    >
                      Inclui HRES UC:
                    </strong>
                    <Tooltip
                      title="Incluir no calculo os consumos de horários reservados"
                      style={{
                        display: 'flex',
                        float: 'left',
                      }}
                    >
                      <label className="custom-toggle ml-2">
                        <input
                          defaultChecked
                          checked={incluirHRESCalculo}
                          type="checkbox"
                          disabled={salvandoChecks}
                          onChange={text =>
                            handleSaveFotovoltaicaInline({
                              id,
                              incluirHRESCalculo: text.target.checked,
                            })
                          }
                          style={{ marginTop: '10px' }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Não"
                          data-label-on="Sim"
                        />
                      </label>
                    </Tooltip>
                  </FormGroup>
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <FormGroup className="mb-3">
                    <strong
                      style={{
                        display: 'flex',
                        float: 'left',
                      }}
                    >
                      UC conexão:
                    </strong>
                    <Tooltip
                      title="UC de instalação e conexão do Sistema FV"
                      style={{
                        display: 'flex',
                        float: 'left',
                      }}
                    >
                      <label className="custom-toggle ml-2">
                        <input
                          defaultChecked
                          checked={indicaUcConexao}
                          type="checkbox"
                          disabled={salvandoChecks}
                          onChange={text =>
                            handleSaveFotovoltaicaInline({
                              id,
                              indicaUcConexao: text.target.checked,
                            })
                          }
                          style={{ marginTop: '10px' }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Não"
                          data-label-on="Sim"
                        />
                      </label>
                    </Tooltip>
                  </FormGroup>
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <div
                    style={{
                      border: '1px solid #c2c2c2',
                      padding: '5px',
                      textAlign: 'center',
                      width: '70%',
                      backgroundColor: '#98d0e3',
                    }}
                  >
                    <strong>Pp UC:</strong> {ppUC ?? ''}
                  </div>
                </Col>
              </Row>
              <Row
                className="mt-3 mb-5"
                style={{ width: '100%', overflowX: 'scroll' }}
              >
                {renderFotovoltaicaConsumoList(item)}
              </Row>
            </div>
          );
        })}
      </div>
    );
  }

  async function validaRegras(fichaId: number): Promise<void> {
    await triggerValidaRegras({
      fichaTecnicaId: Number(fichaId ?? 0),
      atualizarMaterial: true,
      validarAgrupador: false,
      orcamentoVersaoId: configuracaoId,
      origem: `Configuração: ${configuracaoId}`,
      orcamento: orcamentos,
      origemAtualizacao: 'Fotovoltaica',
    });
  }

  async function executaCalculos() {
    async function executaCalculoSaidaFotovoltaica(fichaId: number) {
      setSwal('Executando processos finais...');
      await api.post('/integracao/calculo-final-fotovoltaica', {
        fichaTecnicaId: fichaId,
      });
    }

    async function executaCalculoInicialFotovoltaica() {
      setSwal('Gerando ficha técnica...');

      const response = await api.post(
        '/integracao/cadastro-inicial-fotovoltaica',
        {
          orcamentoId: configuracaoId,
          orcamentoVersaoId: configuracaoId,
        },
      );

      const responseSessao = response.data.sessao;
      setSessaoId(responseSessao);

      const novaFichaTecnica = response.data.fichaTecnica;
      setFichaTecnicaId(novaFichaTecnica.id);

      return response.data;
    }

    async function executaCalculoFotovoltaica(
      codSessao: string,
      fichaId: string,
    ) {
      setSwal('Calculando totais de UC...');
      await api.post('/fotovoltaica-totalizador', {
        orcamentoId: configuracaoId,
        orcamentoVersaoId: configuracaoId,
        sessao: codSessao,
        fichaTecnicaId: fichaId,
      });
    }

    async function executaCalculoFotovoltaicaViabilidade(
      codSessao: string,
      fichaId: string,
    ) {
      // Descontinuado daqui
      setSwal('Calculando Simulação de Viabilidade...');
      await api.post('/fotovoltaica-totalizador/viabilidade', {
        orcamentoId: configuracaoId,
        orcamentoVersaoId: configuracaoId,
        sessao: codSessao,
        fichaTecnicaId: fichaId,
      });
    }

    const retornoCalculo = await executaCalculoInicialFotovoltaica();
    const codSessao = retornoCalculo.sessao;
    const fichaId = retornoCalculo.fichaTecnica.id;
    await executaCalculoFotovoltaica(codSessao, fichaId);
    await validaRegras(fichaId);
    // await executaCalculoFotovoltaicaViabilidade(codSessao, fichaId);
    await executaCalculoSaidaFotovoltaica(fichaId);
    setSwal('');
  }

  function handleConcluirEtapa() {
    const ucConexao = listaFotovoltaica.filter(
      item => item.indicaUcConexao === true,
    );

    if (!ucConexao || ucConexao.length === 0) {
      Swal.fire({
        text: ` Nenhuma UC de Conexão foi definida. Por favor, defina a UC de Conexão para poder prosseguir.`,
        icon: 'error',
        title: 'Ops!',
      });
    } else {
      Swal.fire({
        title: `Deseja marcar a etapa de Cadastro de UCs como concluída?`,
        text: `As alterações não salvas serão perdidas, o processo de cálculo da Ficha Técnica será disparado. Caso algum valor manual tenha sido aplicado na aba de resultado, o mesmo será sobrescrito.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await executaCalculos();
          } finally {
            Swal.close();
            if (onSave) props.onSave();
          }
        }
      });
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0" style={{ width: '100%' }}>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Cadastro / Edição de UC</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <span>Numero da UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-tag" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Numero da UC"
                      value={fotovoltaicaEditing.numeroUC}
                      type="text"
                      onChange={text =>
                        setFotovoltaicaEditing({
                          ...fotovoltaicaEditing,
                          numeroUC: text.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <span>Grupo UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaBuilding />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Grupo UC"
                      type="select"
                      value={fotovoltaicaEditing.grupoUC}
                      onChange={event => handleGrupoChange(event.target.value)}
                    >
                      <option value="">Selecione...</option>
                      {listaGrupos.map((item: Grupo) => {
                        return <option value={item.grupo}>{item.grupo}</option>;
                      })}
                    </Input>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <span>Titular UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaBuilding />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Titular UC"
                      min="0"
                      value={fotovoltaicaEditing.nomeTitularUC ?? ''}
                      type="text"
                      onChange={text =>
                        setFotovoltaicaEditing({
                          ...fotovoltaicaEditing,
                          nomeTitularUC: String(text.target.value),
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <span>CPF/CNPJ Titular UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-tag" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="CPF/CNPJ Titular UC"
                      value={fotovoltaicaEditing.documentoTitularUC}
                      type="text"
                      onChange={text =>
                        setFotovoltaicaEditing({
                          ...fotovoltaicaEditing,
                          documentoTitularUC: text.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <span>Categoria UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-tag" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Categoria UC"
                      type="select"
                      value={fotovoltaicaEditing.categoriaUC}
                      onChange={text =>
                        setFotovoltaicaEditing({
                          ...fotovoltaicaEditing,
                          categoriaUC: text.target.value,
                        })
                      }
                    >
                      <option value="">Selecione...</option>
                      <option value="RURAL">RURAL</option>
                      <option value="RURAL IRRIGANTE">RURAL IRRIGANTE</option>
                      <option value="COMERCIAL">COMERCIAL</option>
                      <option value="INDUSTRIAL">INDUSTRIAL</option>
                    </Input>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <span>Tensão UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-tag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Tooltip title="Unidade de Medida (kV)">
                      <Input
                        placeholder="Tensão UC"
                        type="select"
                        value={fotovoltaicaEditing.tensaoUC}
                        onChange={text =>
                          setFotovoltaicaEditing({
                            ...fotovoltaicaEditing,
                            tensaoUC: Number(text.target.value),
                          })
                        }
                      >
                        <option value={0}>Selecione...</option>
                        {listaGrupos
                          .find(
                            item => item.grupo === fotovoltaicaEditing.grupoUC,
                          )
                          ?.tensao?.map((item: GrupoTensao) => {
                            return (
                              <option
                                value={item.tensao}
                              >{`${item.tensao} ${item.unidade}`}</option>
                            );
                          })}
                      </Input>
                    </Tooltip>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <span>Dem contratada UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-tag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Tooltip title="Unidade de Medida (kW) - Apenas para grupo de consumo A">
                      <Input
                        placeholder="Dem contratada UC"
                        value={fotovoltaicaEditing.demContratada}
                        type="number"
                        disabled={
                          !grupoSelecionado?.informaDemandaContratada ?? false
                        }
                        onChange={text =>
                          setFotovoltaicaEditing({
                            ...fotovoltaicaEditing,
                            demContratada: Number(text.target.value),
                          })
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <span>Tipo ligação UC</span>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-tag" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Tooltip title="Apenas informar se for para grupo de consumo B">
                      <Input
                        placeholder="Tipo ligação UC"
                        type="select"
                        value={fotovoltaicaEditing.tipoLigacao}
                        onChange={text =>
                          setFotovoltaicaEditing({
                            ...fotovoltaicaEditing,
                            tipoLigacao: text.target.value,
                          })
                        }
                        disabled={grupoSelecionado?.limitaLigacaoUV ?? false}
                      >
                        <option value="Trifásico" selected>
                          Trifásico
                        </option>
                        <option value="Bifásico">Bifásico</option>
                        <option value="Monofásico">Monofásico</option>
                      </Input>
                    </Tooltip>
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={salvandoUC}
                    onClick={handleSaveFotovoltaica}
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      <Card className="shadow mt-2" style={{ width: '100%' }}>
        <CardBody>
          <div className="mt--4">
            <Card className="shadow mt-5">
              <Row className="mt--3">
                <ColQuestaoTitulo>
                  <LabelQuestaoTitulo>Cadastro de UC</LabelQuestaoTitulo>
                </ColQuestaoTitulo>
              </Row>
              <CardBody>
                <Row>
                  <Col sm="12">
                    {existemValoresManuais && (
                      <Alert color="warning">
                        <strong>Atenção!</strong> Foram definidos valores
                        manuais na tela de Resultado &gt; Finalização Orçamento.
                        Mudanças na tela de UC vão precisar efetuar novamente os
                        cálculos de Totalizadores e etapas subsequentes. Os
                        valores manuais informados serão perdidos.
                      </Alert>
                    )}

                    <div style={{ float: 'right' }}>
                      <Button
                        size="sm"
                        color="primary"
                        className="btn-icon btn-2"
                        onClick={() => {
                          handleInsertFotovoltaicaUC();
                        }}
                      >
                        <FaPlusCircle />
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="mt-3">
                  {renderFotovoltaicaList(listaFotovoltaica)}
                </div>
                <Button
                  size="sm"
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleConcluirEtapa}
                >
                  <FaArrowCircleRight />
                  <span className="btn-inner--text">Concluir Etapa</span>
                </Button>
              </CardBody>
            </Card>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
