import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from 'services/api';

interface FotovoltaicaGrupos {
  id: number;
  fotovoltaicaTensaoId?: number;
  grupo?: string;
  limitaLigacaoUV: boolean;
  ligacaoUVPadrao: string;
  informaDemandaContratada: boolean;
  incluirDEM: boolean;
  incluirHP: boolean;
}

interface FotovoltaicaTensoes {
  id: number;
  tensao: number;
  unidade: string;
  fotovoltaicaGrupoId: number;
}

const CadastroFotovoltaicaGrupos: React.FC = () => {
  const [fotovoltaicaGrupos, setFotovoltaicaGrupos] = useState<
    FotovoltaicaGrupos[]
  >([]);
  // eslint-disable-next-line prettier/prettier
  const [fotovoltaicaGruposEditing, setFotovoltaicaGruposEditing] = useState({} as FotovoltaicaGrupos);
  const [fotovoltaicaTensoes, setFotovoltaicaTensoes] = useState<
    FotovoltaicaTensoes[]
  >([]);
  const [fotovoltaicaTensoesEditing, setFotovoltaicaTensoesEditing] = useState(
    {} as FotovoltaicaTensoes,
  );
  const [formModalState, setFormModalState] = useState(false);
  const [formModalStateTensao, setFormModalStateTensao] = useState(false);
  const [label, setLabel] = useState('');
  const [isDefault, setIsDefault] = useState(true);
  const [labelCategoria, setLabelCategoria] = useState('');

  async function carregaFotovoltaicaGrupos() {
    const response = await api.get('/fotovoltaica-grupos');
    if (response.data) {
      setFotovoltaicaGrupos(response.data as FotovoltaicaGrupos[]);
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaFotovoltaicaGrupos();
  }, []);

  async function carregaFotovoltaicaTensao() {
    const response = await api.get('/fotovoltaica-tensoes');
    setFotovoltaicaTensoes(response.data as FotovoltaicaTensoes[]);
  }

  useEffect(() => {
    carregaFotovoltaicaTensao();
  }, []);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/fotovoltaica-grupos/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setFotovoltaicaGrupos(
            fotovoltaicaGrupos.filter(item => item.id !== row.id),
          );
        }
      });
      carregaFotovoltaicaGrupos();
    } else {
      setFotovoltaicaGrupos(
        fotovoltaicaGrupos.filter(item => item.id !== row.id),
      );
    }
  }

  async function handleDeleteTensoes(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/fotovoltaica-tensoes/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setFotovoltaicaTensoes(
            fotovoltaicaTensoes.filter(item => item.id !== row.id),
          );
        }
      });
      carregaFotovoltaicaTensao();
    } else {
      setFotovoltaicaTensoes(
        fotovoltaicaTensoes.filter(item => item.id !== row.id),
      );
    }
  }

  async function handleInsertTensoes() {
    setFotovoltaicaTensoesEditing({} as FotovoltaicaTensoes);
    setFormModalStateTensao(!formModalStateTensao);
  }

  function handleUpdate(constante: FotovoltaicaGrupos) {
    setFotovoltaicaGruposEditing(constante);
    setFormModalState(!formModalState);
  }

  function handleUpdateTensao(tensao: FotovoltaicaTensoes) {
    setFotovoltaicaTensoesEditing(tensao);
    setFormModalStateTensao(!formModalStateTensao);
  }

  async function handleSaveFotovoltaicaGrupos() {
    // eslint-disable-next-line prettier/prettier
    const { ligacaoUVPadrao } = fotovoltaicaGruposEditing;

    if (!ligacaoUVPadrao) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (fotovoltaicaGruposEditing.id) {
      await api.put(`/fotovoltaica-grupos/${fotovoltaicaGruposEditing.id}`, {
        id: fotovoltaicaGruposEditing.id,
        fotovoltaicaTensoesId: fotovoltaicaGruposEditing.fotovoltaicaTensaoId,
        grupo: fotovoltaicaGruposEditing.grupo,
        limitaLigacaoUV: fotovoltaicaGruposEditing.limitaLigacaoUV,
        ligacaoUVPadrao: fotovoltaicaGruposEditing.ligacaoUVPadrao,
        informaDemandaContratada:
          fotovoltaicaGruposEditing.informaDemandaContratada,
        incluirDEM: fotovoltaicaGruposEditing.incluirDEM,
        incluirHP: fotovoltaicaGruposEditing.incluirHP,
      });
    }
    toast.success('Registro concluído');
    carregaFotovoltaicaGrupos();
    setFormModalState(false);
    setFotovoltaicaGruposEditing({} as FotovoltaicaGrupos);
  }

  async function handleSaveFotovoltaicaTensoes() {
    // eslint-disable-next-line prettier/prettier
    const { tensao, unidade } = fotovoltaicaTensoesEditing;

    if (!tensao || !unidade) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (fotovoltaicaTensoesEditing.id) {
      await api.put(`/fotovoltaica-tensoes/${fotovoltaicaTensoesEditing.id}`, {
        id: fotovoltaicaGruposEditing.id,
        fotovoltaicaGrupoId: fotovoltaicaTensoesEditing.fotovoltaicaGrupoId,
        unidade: fotovoltaicaTensoesEditing.unidade,
        tensao: fotovoltaicaTensoesEditing.tensao,
      });
    }
    toast.success('Registro concluído');
    carregaFotovoltaicaTensao();
    setFormModalStateTensao(false);
    setFotovoltaicaTensoesEditing({} as FotovoltaicaTensoes);
  }

  function renderFotovoltaicaList(lista: FotovoltaicaGrupos[]) {
    if (!lista) {
      return <></>;
    }

    return (
      <div>
        {fotovoltaicaGrupos.map(item => {
          const {
            id,
            grupo,
            limitaLigacaoUV,
            ligacaoUVPadrao,
            informaDemandaContratada,
            incluirDEM,
            incluirHP,
          } = item;

          const styleConexao = {
            backgroundColor: '#f4f4f4',
            paddingTop: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
          };

          const styleDiv = {
            backgroundColor: '#fff',
          };

          return (
            <div style={styleConexao} className="mt-2">
              <Row>
                <Col sm="1" style={{ fontSize: '14px' }}>
                  <strong>Grupo:</strong>
                  {grupo}
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <strong>Limita ligação UC:</strong>{' '}
                  {limitaLigacaoUV ? 'Sim' : 'Não'}
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <strong>Ligação UC padrão:</strong> {ligacaoUVPadrao}
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <strong>Informa demanda contratada:</strong>{' '}
                  {informaDemandaContratada ? 'Sim' : 'Não'}
                </Col>
                <Col sm="1" style={{ fontSize: '14px' }}>
                  <strong>Incluir DEM:</strong> {incluirDEM ? 'Sim' : 'Não'}
                </Col>
                <Col sm="1" style={{ fontSize: '14px' }}>
                  <strong>Incluir HP:</strong> {incluirHP ? 'Sim' : 'Não'}
                </Col>
                <Col sm="2" style={{ fontSize: '14px' }}>
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="primary"
                    type="button"
                    onClick={() => {
                      handleUpdate(item);
                    }}
                  >
                    Editar grupo
                  </Button>
                </Col>
              </Row>
              <div className="mt-2 ml-2" style={styleDiv}>
                <Table className="align-items-center table-flush" responsive>
                  <thead>
                    <tr>
                      <th>Tensão</th>
                      <th>Unidade</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fotovoltaicaTensoes
                      .filter(valor => valor.fotovoltaicaGrupoId === id)
                      .map(tensao => {
                        return (
                          <tr>
                            <td style={{ fontSize: '13px' }}>
                              <strong>{tensao.tensao}</strong>
                            </td>
                            <td style={{ fontSize: '13px' }}>
                              <strong>{tensao.unidade}</strong>
                            </td>
                            <td style={{ fontSize: '14px' }}>
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="warning"
                                type="button"
                                onClick={() => {
                                  handleUpdateTensao(tensao);
                                }}
                              >
                                <FaEdit />
                              </Button>
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="danger"
                                type="button"
                                onClick={() => {
                                  handleDeleteTensoes(item);
                                }}
                              >
                                <FaTimesCircle />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Grupos</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Grupo</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Grupo"
                            value={fotovoltaicaGruposEditing.grupo}
                            type="text"
                            onChange={text =>
                              setFotovoltaicaGruposEditing({
                                ...fotovoltaicaGruposEditing,
                                grupo: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Limita ligação UC</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Limita ligação UC"
                            type="select"
                            value={
                              fotovoltaicaGruposEditing.limitaLigacaoUV
                                ? 'S'
                                : 'N'
                            }
                            onChange={text =>
                              setFotovoltaicaGruposEditing({
                                ...fotovoltaicaGruposEditing,
                                limitaLigacaoUV: Boolean(
                                  text.target.value === 'S',
                                ),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Ligação UC padrão</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Ligação UC padrão"
                            value={fotovoltaicaGruposEditing.ligacaoUVPadrao}
                            type="text"
                            onChange={text =>
                              setFotovoltaicaGruposEditing({
                                ...fotovoltaicaGruposEditing,
                                ligacaoUVPadrao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Informa demanda contratada</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Informa demanda contratada"
                            type="select"
                            value={
                              fotovoltaicaGruposEditing.informaDemandaContratada
                                ? 'S'
                                : 'N'
                            }
                            onChange={text =>
                              setFotovoltaicaGruposEditing({
                                ...fotovoltaicaGruposEditing,
                                informaDemandaContratada: Boolean(
                                  text.target.value === 'S',
                                ),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Incluir DEM</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Incluir DEM"
                            type="select"
                            value={
                              fotovoltaicaGruposEditing.incluirDEM ? 'S' : 'N'
                            }
                            onChange={text =>
                              setFotovoltaicaGruposEditing({
                                ...fotovoltaicaGruposEditing,
                                incluirDEM: Boolean(text.target.value === 'S'),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Incluir HP</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Incluir HP"
                            type="select"
                            value={
                              fotovoltaicaGruposEditing.incluirHP ? 'S' : 'N'
                            }
                            onChange={text =>
                              setFotovoltaicaGruposEditing({
                                ...fotovoltaicaGruposEditing,
                                incluirHP: Boolean(text.target.value === 'S'),
                              })
                            }
                          >
                            <option value="S" selected>
                              Sim
                            </option>
                            <option value="N">Não</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveFotovoltaicaGrupos}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalStateTensao}
              toggle={() => setFormModalState(!formModalStateTensao)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Tensões</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Tensão</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tensão"
                            value={fotovoltaicaTensoesEditing.tensao}
                            type="number"
                            onChange={text =>
                              setFotovoltaicaTensoesEditing({
                                ...fotovoltaicaTensoesEditing,
                                tensao: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Unidade</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Unidade"
                            value={fotovoltaicaTensoesEditing.unidade}
                            type="text"
                            onChange={text =>
                              setFotovoltaicaTensoesEditing({
                                ...fotovoltaicaTensoesEditing,
                                unidade: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <span>Grupo</span>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Grupo"
                            type="select"
                            value={
                              fotovoltaicaTensoesEditing.fotovoltaicaGrupoId
                            }
                            onChange={text =>
                              setFotovoltaicaTensoesEditing({
                                ...fotovoltaicaTensoesEditing,
                                fotovoltaicaGrupoId: Number(text.target.value),
                              })
                            }
                          >
                            <option value={3} selected>
                              A
                            </option>
                            <option value={4}>B</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveFotovoltaicaTensoes}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Cadastro de Grupos</h3>
              </CardHeader>
              <CardBody>
                <div className="mt-3">
                  {renderFotovoltaicaList(fotovoltaicaGrupos)}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroFotovoltaicaGrupos;
