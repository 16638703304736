import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaArrowCircleUp,
} from 'react-icons/fa';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { arredondaDecimais } from 'utils/arredondaDecimais';

import Swal from 'sweetalert2';

import api from 'services/api';

import TubosAdutoraModel from '../../../models/ConfiguradorTubosAdutora';

const ConfiguradorTubosAdutora: React.FC = () => {
  const [tubosAdutora, setTubosAdutora] = useState([] as TubosAdutoraModel[]);
  // eslint-disable-next-line prettier/prettier
  const [tubosAdutoraEditing, setTubosAdutoraEditing] = useState({} as TubosAdutoraModel);

  const [formModalState, setFormModalState] = useState(false);

  const [mostrarInativos, setMostrarInativos] = useState(false);

  async function listaTubosAdutora() {
    const response = await api.get('/integracao/tubos-adutora');

    setTubosAdutora(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaTubosAdutora();
  }, []);

  async function handleAtiva(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja reativar o tubo?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/integracao/tubos-adutora/${row.id}`, {
            nome: row.nome,
            dn: row.dn,
            de: row.de,
            e: row.e,
            di: row.di,
            coeficiente: row.coeficiente,
            pn: row.pn,
            velocidadeMaxAgua: row.velocidadeMaxAgua,
            tamanho: row.tamanho,
            descontoMetros: row.descontoMetros,
            indicAtivo: 'S',
          });
          Swal.close();
          toast.success('Tubo Reativado');
          listaTubosAdutora();
        }
      });
    } else {
      listaTubosAdutora();
    }
  }

  async function handleDesativa(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja desativar o tubo?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/integracao/tubos-adutora/${row.id}`, {
            nome: row.nome,
            dn: row.dn,
            de: row.de,
            e: row.e,
            di: row.di,
            coeficiente: row.coeficiente,
            pn: row.pn,
            velocidadeMaxAgua: row.velocidadeMaxAgua,
            tamanho: row.tamanho,
            descontoMetros: row.descontoMetros,
            indicAtivo: 'N',
          });
          Swal.close();
          toast.success('Tubo desativado');
          listaTubosAdutora();
        }
      });
    } else {
      listaTubosAdutora();
    }
  }

  async function handleInsert() {
    setTubosAdutoraEditing({} as TubosAdutoraModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(Adutora: TubosAdutoraModel) {
    setTubosAdutoraEditing(Adutora);
    setFormModalState(!formModalState);
  }

  async function handleSaveTubosAdutora() {
    // eslint-disable-next-line prettier/prettier
    const { nome } = tubosAdutoraEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (tubosAdutoraEditing.id) {
      await api.put(`/integracao/tubos-adutora/${tubosAdutoraEditing.id}`, {
        nome: tubosAdutoraEditing.nome,
        dn: tubosAdutoraEditing.dn,
        de: tubosAdutoraEditing.de,
        e: tubosAdutoraEditing.e,
        di: tubosAdutoraEditing.di,
        coeficiente: tubosAdutoraEditing.coeficiente,
        pn: tubosAdutoraEditing.pn,
        velocidadeMaxAgua: tubosAdutoraEditing.velocidadeMaxAgua,
        tamanho: tubosAdutoraEditing.tamanho,
        descontoMetros: tubosAdutoraEditing.descontoMetros,
        indicAtivo: 'S',
      });
    } else {
      await api.post(`/integracao/tubos-adutora`, {
        nome: tubosAdutoraEditing.nome,
        dn: tubosAdutoraEditing.dn,
        de: tubosAdutoraEditing.de,
        e: tubosAdutoraEditing.e,
        di: tubosAdutoraEditing.di,
        coeficiente: tubosAdutoraEditing.coeficiente,
        pn: tubosAdutoraEditing.pn,
        velocidadeMaxAgua: tubosAdutoraEditing.velocidadeMaxAgua,
        tamanho: tubosAdutoraEditing.tamanho,
        descontoMetros: tubosAdutoraEditing.descontoMetros,
        indicAtivo: 'S',
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setTubosAdutoraEditing({} as TubosAdutoraModel);
    listaTubosAdutora();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Tubo {tubosAdutoraEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nome"
                            value={tubosAdutoraEditing.nome}
                            type="text"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>DN </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="DN"
                            value={tubosAdutoraEditing.dn}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                dn: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>DE </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="DE"
                            value={tubosAdutoraEditing.de}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                de: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Espessura </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Espessura"
                            value={tubosAdutoraEditing.e}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                e: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>DI </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="DI"
                            value={tubosAdutoraEditing.di}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                di: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Coeficiente </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Coeficiente"
                            value={tubosAdutoraEditing.coeficiente}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                coeficiente: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>PN </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="PN"
                            value={tubosAdutoraEditing.pn}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                pn: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Veloc. Max. Agua </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Veloc. Max. Agua"
                            value={tubosAdutoraEditing.velocidadeMaxAgua}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                velocidadeMaxAgua: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Tamanho </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tamanho"
                            value={tubosAdutoraEditing.tamanho}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                tamanho: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Desconto Metros </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Desconto Metros"
                            value={tubosAdutoraEditing.descontoMetros}
                            type="number"
                            onChange={text =>
                              setTubosAdutoraEditing({
                                ...tubosAdutoraEditing,
                                descontoMetros: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveTubosAdutora}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Tubos Adutora</h3>
              </CardHeader>
              <Row>
                <Col sm="9" />
                <Col sm="3">
                  <FormControlLabel
                    className="float-right"
                    control={
                      <Checkbox
                        checked={mostrarInativos}
                        onChange={event =>
                          setMostrarInativos(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Mostrar Inativos"
                  />
                </Col>
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>DN</th>
                    <th>DE</th>
                    <th>Espessura</th>
                    <th>DI</th>
                    <th>Coeficiente</th>
                    <th>PN</th>
                    <th>Veloc. Agua</th>
                    <th>Tamanho</th>
                    <th>Desc Metros</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {tubosAdutora
                    .filter(item =>
                      !mostrarInativos ? item.indicAtivo === 'S' : item,
                    )
                    .map(adutora => (
                      <tr key={adutora.id}>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>{adutora.nome}</strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>{arredondaDecimais(adutora.dn, 2)}</strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>{arredondaDecimais(adutora.de, 2)}</strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>{arredondaDecimais(adutora.e, 2)}</strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>{arredondaDecimais(adutora.di, 2)}</strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>
                            {arredondaDecimais(adutora.coeficiente, 2)}
                          </strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>{arredondaDecimais(adutora.pn, 2)}</strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>
                            {arredondaDecimais(adutora.velocidadeMaxAgua, 2)}
                          </strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>
                            {arredondaDecimais(adutora.tamanho, 2)}
                          </strong>
                        </td>
                        <td
                          className={
                            adutora.indicAtivo === 'S'
                              ? 'py-1'
                              : 'col text-muted'
                          }
                        >
                          <strong>
                            {arredondaDecimais(adutora.descontoMetros, 2)}
                          </strong>
                        </td>
                        <td>
                          {adutora.indicAtivo === 'S' ? (
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              onClick={() => {
                                handleUpdate(adutora);
                              }}
                            >
                              <FaEdit />
                            </Button>
                          ) : (
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="default"
                              type="button"
                              onClick={() => {
                                handleAtiva(adutora);
                              }}
                            >
                              <FaArrowCircleUp />
                            </Button>
                          )}
                          {adutora.indicAtivo === 'S' ? (
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="danger"
                              type="button"
                              onClick={() => {
                                handleDesativa(adutora);
                              }}
                            >
                              <FaTimesCircle />
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorTubosAdutora;
