import React, { createContext, useEffect, useState } from 'react';
import { CardHeader, Container, Row, Col, Input } from 'reactstrap';
import { Redirect, useLocation } from 'react-router-dom';

import api from 'services/api';

import Orcamento from 'models/Orcamento';

import Swal from 'sweetalert2';
import Pessoa from 'models/Pessoa';
import OrcamentoParte from 'models/OrcamentoParte';
import OpcoesLancesType from 'models/OpcoesLances';
import FichaTecnica from 'models/FichaTecnica';
import AutocompletePessoa from 'components/AutcompletePessoa';
import { OportunidadeCrm } from 'models/OportunidadeCrm';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import FuncaoEntrada from 'models/FuncaoEntrada';
import OrcamentoProduto from 'models/OrcamentoProduto';
import ProdutoModel from 'models/Produto';
import AutocompletePessoaERP from 'components/AutcompletePessoaERP';
import AutocompleteUsuarios from 'components/AutocompleteUsuarios';
import Usuario from 'models/Usuario';
import PessoaErp from 'models/PessoaErp';
import IndicadoresNegocio from 'models/IndicadoresNegocio';
import { IdOrcamentoLabel } from './styles';
import ProdutoListaImagem from './components/ProdutosListaImagem';

interface Produto {
  id: number;
  nome: string;
  produtoId?: number;
}

interface LocationState {
  pessoa?: Pessoa;
  orcamento?: Orcamento;
  codigoOportunidade?: string;
  codigoOportunidadeDomsgeAgrupador?: string;
  oportunidadeCrm?: OportunidadeCrm | null;
}

interface OrcamentoContextType {
  handleSelecionarProduto: (produto: Produto) => void;
  produtoSelecionado: Produto;
  fichaTecnica?: FichaTecnica;
  orcamentoVersaoId: number;
  fichaTecnicaId?: number;
}

type UsuarioLista = {
  codUsuario: string;
  descUsuario: string;
  ativo: boolean;
};

export const OrcamentoContext = createContext({} as OrcamentoContextType);

const CadastroOrcamento: React.FC = () => {
  const location = useLocation<LocationState>();
  const locationStateOrcamento = location.state?.orcamento ?? null;
  const locationStatePessoa = location.state?.pessoa ?? null;
  const codigoOportunidade = location.state?.codigoOportunidade ?? null;
  const oportunidadeCrm = location.state?.oportunidadeCrm ?? null;
  const [orcamentoGerado, setOrcamentoGerado] = useState({} as Orcamento);
  const [orcamentoVersaoId, setOrcamentoVersaoId] = useState(null || Number);

  const existeOrcamento = !!orcamentoGerado?.id;
  const [pessoaSelecionada, setPessoaSelecionada] = useState({} as Pessoa);
  const [pessoaErpSelecionada, setPessoaErpSelecionada] = useState(
    {} as PessoaErp,
  );
  const [usuarioSelecionado, setUsuarioSelecionado] = useState({} as Usuario);
  const [produtoSelecionado, setProdutoSelecionado] = useState({} as Produto);
  const [fichaTecnicaId, setFichaTecnicaId] = useState<number>();
  const isProdutoSelecionado = !!produtoSelecionado?.id;
  const mostrarListaProdutosImagem =
    !locationStateOrcamento && !isProdutoSelecionado;

  const [categoriaProdutos, setCategoriaProdutos] = useState(
    {} as ProdutoModel,
  );
  const [representateSelecionado, setRepresentanteSelecionado] =
    useState<string>();
  const [listaRepresentantes, setListaRepresentantes] = useState<
    IndicadoresNegocio[]
  >([]);
  async function returnOrcamentoProduto(): Promise<OrcamentoProduto> {
    const produto = await api.get(`/orcamento/versao/${orcamentoVersaoId}`, {
      params: { completo: true },
    });
    return produto.data.OrcamentoProduto[0];
  }

  const { data: queryOrcamentoProduto } = useQuery(
    `orcamento-produto-versaoId-${orcamentoVersaoId}`,
    async () => {
      if (!orcamentoVersaoId) {
        return undefined;
      }
      const orcamentoProduto = await returnOrcamentoProduto();
      return orcamentoProduto;
    },
  );

  useEffect(() => {
    if (queryOrcamentoProduto) {
      setProdutoSelecionado(queryOrcamentoProduto);
    }
  }, [queryOrcamentoProduto]);

  async function produtoCategoria() {
    const response = await api.get(`/produto/${produtoSelecionado?.produtoId}`);
    setCategoriaProdutos(response.data);
  }

  useEffect(() => {
    if (produtoSelecionado?.produtoId) {
      produtoCategoria();
    }
  }, [produtoSelecionado]);

  function setDadosOrcamento(orcamento: Orcamento) {
    setOrcamentoGerado(orcamento ?? {});

    setPessoaSelecionada(orcamento?.pessoa ?? {});
    if (
      String(orcamento?.representante) !== undefined ||
      String(orcamento?.representante) !== null
    ) {
      setRepresentanteSelecionado(String(orcamento?.representante));
    }
  }

  async function carregaOrcamento() {
    if (!locationStateOrcamento) {
      return;
    }
    toast.loading('Carregando...', { position: 'top-right' });
    setOrcamentoVersaoId(locationStateOrcamento.OrcamentoVersao[0].id);
    const orcamentoId = locationStateOrcamento.id;
    const response = await api.get(`/orcamento/${orcamentoId}`);
    toast.dismiss();
    const orcamento = response.data as Orcamento;
    setDadosOrcamento(orcamento);
  }

  useEffect(() => {
    carregaOrcamento();
  }, [locationStateOrcamento]);

  useEffect(() => {
    if (locationStatePessoa) {
      setPessoaSelecionada(locationStatePessoa);
    }
  }, [locationStatePessoa]);

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  async function geraOrcamentoProdutos(pProdutoSelecionado: Produto) {
    if (!pessoaSelecionada && !oportunidadeCrm?.RAZAO_SOCIAL) {
      Swal.fire({
        icon: 'warning',
        text: 'Informe um cliente',
      });

      return;
    }

    setSwal('Gerando configuração...');

    async function criaOrcamento() {
      const response = await api.post(`/orcamento`, {
        descricao: '',
        pessoaId: Number(pessoaSelecionada?.id ?? 0),
        codigoOportunidade,
        codigoOportunidadeDomsgeAgrupador: codigoOportunidade,
        oportunidadeCrm,
        representante: representateSelecionado,
      });

      setOrcamentoGerado(response.data);

      return response.data;
    }

    async function adicionaProdutoAoOrcamento(pOrcamento: Orcamento) {
      await api.post(
        `/orcamento/produto`,
        {
          nome: pProdutoSelecionado.nome,
          produtoId: pProdutoSelecionado.id,
          orcamentoVersaoId: pOrcamento.orcamentoVersaoId,
        },
        { params: { criarPartes: true } },
      );
    }

    const orcamento = await criaOrcamento();
    await adicionaProdutoAoOrcamento(orcamento);
    setOrcamentoVersaoId(orcamento.orcamentoVersaoId);
    setSwal();
  }

  async function getListaRepresentantes() {
    const response = await api.get('/integracao/indicadores-negocio');

    setListaRepresentantes(response.data);
  }

  useEffect(() => {
    getListaRepresentantes();
  }, []);

  function handleSelecionarProduto(pProdutoSelecionado: Produto) {
    geraOrcamentoProdutos(pProdutoSelecionado);
  }

  function ConfiguracaoDescricao() {
    return (
      <Row className="mt-2">
        <Col sm="10">
          <IdOrcamentoLabel>
            Geração de configuração
            {codigoOportunidade ? ` - GNF ${codigoOportunidade}` : ''}
          </IdOrcamentoLabel>
        </Col>
      </Row>
    );
  }

  function AutocompleteCliente() {
    return (
      <Row>
        <Col className="order-xl-2">
          <CardHeader className="bg-white border-3">
            <Row>
              <Col sm="12" xs="12">
                {oportunidadeCrm ? (
                  <h4>
                    {oportunidadeCrm.DPES_CODIGO} -{' '}
                    {oportunidadeCrm.RAZAO_SOCIAL}
                  </h4>
                ) : (
                  <AutocompletePessoa
                    onChange={(_, newValue) =>
                      setPessoaSelecionada(newValue ?? ({} as Pessoa))
                    }
                    pessoaSelecionada={pessoaSelecionada}
                    disabled
                  />
                )}
              </Col>
            </Row>
          </CardHeader>
        </Col>
      </Row>
    );
  }

  function AutocompleteResponsavel() {
    return (
      <Row>
        <Col className="order-xl-2">
          <CardHeader className="bg-white border-3">
            <Row>
              <Col sm="12" xs="12">
                <Input
                  type="select"
                  value={representateSelecionado}
                  onChange={event =>
                    setRepresentanteSelecionado(String(event.target.value))
                  }
                >
                  <option value="">Representante / Ger. Negócio...</option>
                  {listaRepresentantes.map(user => (
                    <option
                      key={user.codigoRepresentante}
                      value={`${user.razaoRepresentante} / ${
                        user.razaoGerente !== null ? user.razaoGerente : '--'
                      }`}
                    >
                      {`${user.razaoRepresentante} / ${
                        user.razaoGerente !== null ? user.razaoGerente : '--'
                      }`}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </CardHeader>
        </Col>
      </Row>
    );
  }

  if (orcamentoVersaoId)
    return (
      <Redirect
        to={{ pathname: 'etapas-orcamento', state: { orcamentoVersaoId } }}
      />
    );

  return (
    <>
      <UserSmallHeader />
      <Container fluid>
        <ConfiguracaoDescricao />
        <AutocompleteCliente />
        <AutocompleteResponsavel />

        <Row>
          <OrcamentoContext.Provider
            value={{
              handleSelecionarProduto,
              produtoSelecionado,
              orcamentoVersaoId,
              fichaTecnicaId,
            }}
          >
            {mostrarListaProdutosImagem && (
              <Col className="mb-5 mb-xl-0" xl="12">
                <ProdutoListaImagem />
              </Col>
            )}
          </OrcamentoContext.Provider>
        </Row>
      </Container>
    </>
  );
};

export default CadastroOrcamento;
