import {
  Container,
  ChildrenContainer,
  TituloContainer,
  Titulo,
  FootContainer,
} from './styles';

interface Props {
  titulo?: string;
  rodape?: string;
  children: any;
  noBorder?: boolean;
}

function Quadro({ titulo, rodape, children, noBorder }: Props): JSX.Element {
  return (
    <Container>
      {titulo && (
        <TituloContainer>
          <Titulo>{titulo}</Titulo>
        </TituloContainer>
      )}
      <ChildrenContainer noBorder={noBorder}>{children}</ChildrenContainer>
      {rodape && (
        <FootContainer>
          <Titulo>{rodape}</Titulo>
        </FootContainer>
      )}
    </Container>
  );
}

export default Quadro;
