import RegraAgrupador from 'models/RegraAgrupador';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import Orcamento from 'models/Orcamento';
import api from './api';

interface params {
  fichaTecnicaId: number;
  atualizarMaterial: boolean;
  validarAgrupador: boolean;
  orcamentoVersaoId: number;
  origem?: string;
  orcamento?: Orcamento;
  origemAtualizacao?: string;
}
export default async function triggerValidaRegras(
  {
    fichaTecnicaId,
    atualizarMaterial,
    validarAgrupador,
    orcamentoVersaoId,
    origem,
    orcamento,
    origemAtualizacao,
  }: params,
  pistaSeca = false,
): Promise<boolean> {
  let temVazios = false;
  let houveErro = false;
  try {
    Swal.fire({
      icon: 'info',
      text: `Validando regras e atualizando lista de materiais`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    const retorno = await api.post('/regra-item/valida-regras-novo', {
      fichaTecnicaId,
      atualizarMaterial,
      validarAgrupador,
      orcamentoVersaoId,
      pistaSeca,
      origemAtualizacao,
    });

    const listaVazios = retorno.data.regraAgrupadorVazios as RegraAgrupador[];
    const alertar = retorno.data.alertar ?? false;
    const bloquear = retorno.data.bloquear ?? false;

    const listaVaziosFiltrado = listaVazios.filter(
      item => item.validar === true,
    );

    let validar = false;

    if (listaVaziosFiltrado.length > 0) {
      temVazios = true;

      const listaUsar = listaVaziosFiltrado.map(
        (item: RegraAgrupador) =>
          `<div>${item.nome} ${
            item.descricao ? ` - ${item.descricao}` : ''
          }</div>`,
      );

      await Swal.fire({
        icon: 'warning',
        html: `
          <h1>Atenção</h1>
          <p>Um ou mais grupo de regras marcados para consistir, não encontraram materiais nessa varredura de regras:</p>
          <div style='text-align: left; height: 150px; overflow-y: scroll;'>
            ${listaUsar.join('')}
          </div>`,
        width: '650px',
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: `Reportar`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      });

      try {
        await api.post(`/integracao/fila-itens`, {
          assunto: 'Materiais não encontrados',
          descricao: `Grupos de regras sem materiais ${listaUsar.join(
            '',
          )}<p><strong>Origem: </strong>${origem}</p><p><strong>Cliente: </strong>${
            orcamento?.pessoa.razaoSocial
          }</p>`,
          reportar: alertar,
          bloquear,
          orcamentoVersaoId: orcamento?.orcamentoVersaoId,
          modalAjuda: false,
        });
        validar = true;
        toast.success('Solicitação gerada com sucesso!');
        Swal.close();
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: error?.response?.data?.message,
        });
      }

      /* if (result.isConfirmed) {
        try {
          await api.post(`/integracao/fila-itens`, {
            assunto: 'Materiais não encontrados',
            descricao: `Grupos de regras sem materiais ${listaUsar.join(
              '',
            )}<p><strong>Origem: </strong>${origem}</p><p><strong>Cliente: </strong>${
              orcamento?.pessoa.razaoSocial
            }</p>`,
            reportar: true,
            orcamentoVersaoId: orcamento?.orcamentoVersaoId,
          });
          validar = true;
          toast.success('Solicitação gerada com sucesso!');
          Swal.close();
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            text: error?.response?.data?.message,
          });
        }
      } else {
        try {
          await api.post(`/integracao/grava-log`, {
            reportar: true,
            orcamentoId: orcamento?.orcamentoVersaoId,
          });
          Swal.close();
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            text: error?.response?.data?.message,
          });
        }
      } */

      return true;
    }

    return true;
  } catch (exception: any) {
    houveErro = true;
    return false;
  } finally {
    if (!temVazios && !houveErro) Swal.close();
  }
}
