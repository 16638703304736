import { Col, Label } from 'reactstrap';
import styled from 'styled-components';

export const LabelOrcamento = styled(Label)`
  color: #8898aa;
  font-size: 1.4rem;
  font-weight: 600;
`;

export const Th = styled.th`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e9ecef;
`;

export const Td = styled.td`
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;

export const Descricao = styled.label`
  font-size: 0.75rem;
`;

interface TrProps {
  semPreco?: boolean;
}

export const Tr = styled.tr<TrProps>`
  background-color: ${props => (props.semPreco ? '#ffd5de' : 'unset')};
`;

export const ButtonsRightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;
