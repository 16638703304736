import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

interface Params {
  codigo?: string;
}

export default function RouterConfiguracao(): JSX.Element {
  const history = useHistory();
  const params = useParams<Params>();
  const codigoParam = params?.codigo;

  async function functionNavigateToConfiguracao() {
    if (codigoParam) {
      history.push({
        pathname: `/admin/etapas-orcamento`,
        state: { orcamentoVersaoId: Number(codigoParam), goTo: '' },
      });
    }
  }

  useEffect(() => {
    if (codigoParam) {
      functionNavigateToConfiguracao();
    }
  }, [codigoParam]);

  return <></>;
}
