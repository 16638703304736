import { Label } from 'reactstrap';
import styled from 'styled-components';
import { TextField as TextFieldC } from '@material-ui/core';

export const LabelOrcamento = styled(Label)`
  color: #8898aa;
  font-size: 1.8rem;
  font-weight: 600;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3px 10px 3px 3px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TextField = styled(TextFieldC).attrs(() => ({
  InputProps: {
    style: { fontSize: 14 },
  },
  fullWidth: true,
}))``;
