import { useContext, useEffect, useState } from 'react';

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

import api from 'services/api';
import { FaMoneyBillWave } from 'react-icons/fa';

import formatCurrency from 'utils/formatCurrency';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from 'react-query';
import Chart from 'react-apexcharts';
import { PropostaStatus } from 'models/PropostaStatus';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import ComponentProps from '../../models/component.props';
import { DashboardContext } from '../..';
import { getParamsFormatados } from '../../function.get-params-formatados';

export default function PropostaFunilEstagio(
  props: ComponentProps,
): JSX.Element {
  const { className, cacheTime } = props;

  const {
    dataFim,
    setFiltros,
    dataInicio,
    filtros,
    produtos,
    status,
    reloadAll,
    refreshFunilEstagio,
    setRefreshFunilEstagio,
  } = useContext(DashboardContext);

  const [valorTotal, setValorTotal] = useState(0);
  const [listaStatus, setListaStatus] = useState<PropostaStatus[]>(
    [] as PropostaStatus[],
  );
  const [dataReady, setDataReady] = useState(false);

  const [chartOptions, setChartOptions] = useState<any>({});

  const [funil, setFunil] = useState<any[]>([] as any[]);

  async function loadData() {
    const params = getParamsFormatados(filtros, dataInicio, dataFim);

    async function getDados() {
      const response = await api.get(
        `/dashboard/propostas-total-por-status?${params}`,
      );
      return response.data ?? [];
    }

    const cacheRetornoDados = await getDados();

    setFunil(cacheRetornoDados);
  }

  async function loadContent() {
    // {valor_total: 5743620.359999999, status: 'Gerada'}
    // {valor_total: 1985000, status: 'Em Aprovação'}
    // {valor_total: 614000, status: 'Aprovada'}

    setDataReady(false);

    const chart = {
      series: [
        {
          name: 'Valor',
          data: funil.map((item: any) =>
            arredondaDecimais(item.valor_total, 2),
          ),
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
            barHeight: '80%',
            isFunnel: true,
          },
        },
        /* colors: [
          '#F44F5E',
          '#E55A89',
          '#D863B1',
          '#CA6CD8',
          '#B57BED',
          '#8D95EB',
          '#62ACEA',
          '#4BC3E6',
        ], */
        colors: [
          ...funil.map((item: any) => {
            const statusBuscar = status?.find(
              item2 => item2.descricao === item.status,
            );

            return statusBuscar && statusBuscar.hexcolor
              ? `${statusBuscar.hexcolor}`
              : '#038cfc';
          }),
        ],
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            colors: ['#000'],
          },
          formatter(val: any, opt: any) {
            return `${
              opt.w.globals.labels[opt.dataPointIndex]
            }:  ${formatCurrency(val, 2)}`;
          },
          dropShadow: {
            enabled: true,
            color: '#fff',
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          show: false,
          align: 'middle',
        },
        tooltip: {
          y: {
            formatter(value: any) {
              return formatCurrency(value, 2);
            },
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          categories: funil.map((item: any) => item.status),
        },
        legend: {
          show: false,
        },
      },
    };

    setChartOptions(chart);

    setDataReady(true);
  }

  useEffect(() => {
    if (funil && status) loadContent();
  }, [funil, status]);

  useEffect(() => {
    if (refreshFunilEstagio) {
      loadData();
      setRefreshFunilEstagio(false);
    }
  }, [refreshFunilEstagio]);

  return (
    <>
      <Card className={`card-stats ${className} mb-xl-0`}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                Funil Proposta x Estágio
              </CardTitle>
              <span className="h2 font-weight-bold mb-4 mt-4">
                {!dataReady ? (
                  <CircularProgress
                    className="mt-2 ml-2"
                    color="inherit"
                    size={15}
                  />
                ) : (
                  <div className="chart">
                    <Chart
                      height={350}
                      options={chartOptions.options}
                      series={chartOptions.series}
                      type="bar"
                    />
                  </div>
                )}
              </span>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
