import styled from 'styled-components';
import { UncontrolledDropdown, DropdownToggle, Label } from 'reactstrap';

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  padding: 0.5rem;
`;

export const AcoesDiv = styled.div`
  float: right;
`;

export const DropdownTipoQuestao = styled(UncontrolledDropdown)`
  display: flex;
`;

export const DropdownToggleTipoQuestao = styled(DropdownToggle)`
  width: 100%;
  text-align: start;
`;

export const LabelStatusOrcamento = styled(Label)`
  font-size: 11;
  padding: 0.4rem;
  min-width: 5rem;
  text-align: center;
  margin: -1rem;
  border-radius: 6px;
  background-color: ${props => props.bgcolor};
  color: #fff;
`;
