import React, { useEffect, useState } from 'react';

import UserSmallHeader from 'components/Headers/UserSmallHeader';
import { useLocation } from 'react-router-dom';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import ResultadoIrrigacao from './components/ResultadoIrrigacao';
import './style.css';

interface LocationState {
  orcamentoVersaoId: number;
  tipoProduto: string;
}

const ConfiguracaoImpressao: React.FC = () => {
  const location = useLocation<LocationState>();
  const orcamentoVersaoId = location.state?.orcamentoVersaoId ?? null;

  // 'Irrigacao' : 'Fotovoltaica'
  const tipoProduto = location.state?.tipoProduto ?? '';

  // Identificar o tipo de produto.

  // Criar o cabeçalho que será compartilhado entre componentes

  // Criar um de-para para chamar componentes de impressão específicos

  // Deixar o consumo dos dados para dentro desses componentes. Centralizar aqui apenas o básico

  return (
    <>
      <div className="hide-on-print">
        <UserSmallHeader />
      </div>
      <div
        className="content-to-print body-print print-fv"
        style={{ width: '100%', padding: '5px', height: '100%' }}
      >
        {tipoProduto === 'Irrigacao' && (
          <ResultadoIrrigacao configuracaoId={orcamentoVersaoId} />
        )}
        {tipoProduto === 'Fotovoltaica' && (
          <div>Modelo para impressão não configurada para este produto</div>
        )}
      </div>
      <FloatingButton className="hide-on-print" onClick={() => window.print()}>
        <FaPrint />
      </FloatingButton>
    </>
  );
};

export default ConfiguracaoImpressao;
