import { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import './styles.css';
import api from 'services/api';
import { useQuery } from 'react-query';
import formatCurrency from 'utils/formatCurrency';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import ProdutoModel from 'models/Produto';
import { Fullscreen } from '@material-ui/icons';
import formatNumeros from 'utils/formatNumeros';
import ComponentProps from '../../models/component.props';
import { DashboardContext } from '../..';
import { getParamsFormatados } from '../../function.get-params-formatados';

declare let window: any;

window.google.load('visualization', '1', {
  packages: ['columnchart', 'corechart', 'table'],
});

interface PropostaMapa {
  id: number;
  orcamento_id: number;
  geodesico?: string;
  raio: number;
  valor_produto?: number;
  produto_id: number;
  status_id: number;
}

interface ConfiguracaoDetalheMapa {
  produtoId?: number;
  produtoNome?: string;
  clienteId?: number;
  clienteNome?: string;
  propostaId?: number;
  propostaVersao?: string;
  propostaStatus?: string;
  totalValor?: number;
  totalSigla?: string;
}

export default function PropostaMapaConfiguracoes(
  props: ComponentProps,
): JSX.Element {
  const { className, cacheTime } = props;

  const {
    dataFim,
    setFiltros,
    dataInicio,
    filtros,
    produtos,
    status,
    reloadAll,
    refreshMapa,
    setRefreshMapa,
  } = useContext(DashboardContext);

  const [listaMapa, setListaMapa] = useState<PropostaMapa[]>(
    [] as PropostaMapa[],
  );

  const [triggerUseEffect, setTriggerUseEffect] = useState(false);

  const [valorTotal, setValorTotal] = useState(0);
  const [quantidadeTotal, setQuantidadeTotal] = useState(0);

  const [valorTotalAbertas, setValorTotalAbertas] = useState(0);
  const [quantidadeTotalAbertas, setQuantidadeTotalAbertas] = useState(0);

  const [valorTotalAprovadas, setValorTotalAprovadas] = useState(0);
  const [quantidadeTotalAprovadas, setQuantidadeTotalAprovadas] = useState(0);

  const [propostaTotal, setPropostaTotal] = useState<any>({} as any);

  const [propostaAberta, setPropostaAberta] = useState<any>({} as any);

  const [propostaAprovada, setPropostaAprovada] = useState<any>({} as any);

  const [propostaMapa, setPropostaMapa] = useState<PropostaMapa[]>(
    [] as PropostaMapa[],
  );

  let infoWindow: any = null;

  if (google.maps) {
    infoWindow = new google.maps.InfoWindow();
  }

  const [dataReady, setDataReady] = useState(false);

  async function sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function loadData() {
    setDataReady(false);
    const params = getParamsFormatados(filtros, dataInicio, dataFim);

    async function getRetornoAbertas() {
      const response = await api.get(
        `/dashboard/propostas-total-abertas?${params}`,
      );
      return response.data ?? ({} as any);
    }

    async function getRetornoAprovadas() {
      const response = await api.get(
        `/dashboard/propostas-total-aprovadas?${params}`,
      );
      return response.data ?? ({} as any);
    }

    async function getRetornoMapa() {
      const response = await api.get(`/dashboard/propostas-mapa?${params}`);
      return response.data ?? [];
    }

    async function getRetornoTotal() {
      const response = await api.get(`/dashboard/propostas-total?${params}`);
      return response.data ?? ({} as any);
    }

    const cacheRetornoTotal = await getRetornoTotal();
    const cacheRetornoAbertas = await getRetornoAbertas();
    const cacheRetornoAprovadas = await getRetornoAprovadas();
    const cachePropostaMapa = await getRetornoMapa();

    setPropostaMapa(cachePropostaMapa);
    setPropostaAberta(cacheRetornoAbertas);
    setPropostaAprovada(cacheRetornoAprovadas);
    setPropostaTotal(cacheRetornoTotal);
  }

  async function renderMap(
    tipoFiltrar: number,
    fullScreen: boolean,
    currMap?: any,
  ) {
    const mapIsNew = !currMap;
    const isLoad = true;
    const isFullScreen = fullScreen;

    if (window && window.google && window.google.maps) {
      const map = new window.google.maps.Map(
        document.getElementById('mapId') as HTMLElement,
        {
          zoom: 5,
          location: { lat: -22, lng: -48 },
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER,
          },
          scaleControl: true,
          fullscreenControl: false, // Por enquanto, não usa Full Screen
          streetViewControl: false,
        },
      );

      const mapx = map;

      // Processo para deixar o full screen após filtrar um valor.
      // Seguir para outra atividade, depois volta aqui
      // eslint-disable-next-line no-inner-declarations
      /* function onBoundsChanged() {
        if (isLoad) {
          isLoad = false;
          if (isFullScreen) {
            console.log('disparar full screen');
            // Disparar aqui o processo para deixar em full screen
            mapx.getDiv().requestFullscreen();
          }
        } else {
          isFullScreen = !isFullScreen;
          console.log(isFullScreen);
        }
      }

      window.google.maps.event.addListener(
        mapx,
        'bounds_changed',
        onBoundsChanged,
      ); */

      // eslint-disable-next-line no-inner-declarations
      function createButtonControl(setarBorda: boolean) {
        const controlButton = document.createElement('button');
        controlButton.style.backgroundColor = '#fff';
        controlButton.style.border = '2px solid #fff';
        controlButton.style.borderRadius = '2px';
        controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlButton.style.color = 'rgb(25,25,25)';
        controlButton.style.cursor = 'pointer';
        controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlButton.style.fontSize = '12px';
        controlButton.style.lineHeight = '20px';
        controlButton.style.margin = '4px 0 16px';
        controlButton.style.padding = '0 5px';

        if (setarBorda) controlButton.style.border = '3px solid #851d15';

        controlButton.type = 'button';

        return controlButton;
      }

      // eslint-disable-next-line no-inner-declarations
      async function openModalInfoMapa(
        marker: any,
        infowindow: any,
        configuracaoId: number,
      ) {
        const retorno = await api.get(
          `/dashboard/detalhe-mapa-configuracao/${configuracaoId}`,
        );

        const detalhesConfiguracao = retorno.data as ConfiguracaoDetalheMapa;

        const contentString = `<div id="content">
          <div id="siteNotice">
          </div>
          <h1 id="firstHeading" class="mapTooltipHeader">Detalhes da Configuração #${configuracaoId}</h1>
          <div id="bodyContent" class="mapTooltipBody">
          <p>
          <strong>Proposta: </strong>${detalhesConfiguracao.propostaId} v${
          detalhesConfiguracao.propostaVersao
        } - ${detalhesConfiguracao.propostaStatus}<br />
          <strong>Cliente: </strong>${detalhesConfiguracao.clienteNome}<br />
          <strong>Total: </strong>${formatNumeros(
            detalhesConfiguracao.totalValor ?? 0,
          )} ${detalhesConfiguracao.totalSigla}<br />
          </p>
          </div>
          </div>`;

        infowindow.setContent(contentString);
      }

      // eslint-disable-next-line no-inner-declarations
      function buttonHtmlTemplate(
        titulo: string,
        valor: number,
        quantidade: number,
        isPercentual: boolean,
      ) {
        return `<div>
    <div className="col">
      <div><strong style="color: #525050;">${titulo}</strong></div>
      <div>
        <strong style="font-size: 20px;">${
          !isPercentual
            ? formatCurrency(arredondaDecimais(valor, 2))
            : `${valor}%`
        }</strong>
      </div>
      ${
        !isPercentual
          ? `<small>${quantidade} Propostas</small>`
          : '<small>&nbsp;</small>'
      }

    </div>
  </div>`;
      }

      // eslint-disable-next-line no-inner-declarations
      function createButton(
        tipo: number,
        titulo: string,
        valor: number,
        quantidade: number,
        tooltip: string,
      ) {
        const setarBorda = tipo === tipoFiltrar;

        const cb = createButtonControl(setarBorda);
        cb.innerHTML = buttonHtmlTemplate(titulo, valor, quantidade, false);
        cb.title = tooltip ?? titulo;

        cb.addEventListener('click', () => {
          renderMap(tipo, isFullScreen, mapx);
        });
        return cb;
      }

      // eslint-disable-next-line no-inner-declarations
      function createHtmlContent(text: string) {
        const controlHtml = document.createElement('div') as HTMLDivElement;

        controlHtml.style.backgroundColor = '#fff';
        controlHtml.style.border = '2px solid #fff';
        controlHtml.style.borderRadius = '2px';
        controlHtml.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlHtml.style.color = 'rgb(25,25,25)';
        controlHtml.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlHtml.style.fontSize = '12px';
        controlHtml.style.lineHeight = '20px';
        controlHtml.style.margin = '4px 0 16px';
        controlHtml.style.padding = '0 5px';

        controlHtml.innerHTML = `${text}`;

        return controlHtml;
      }

      // eslint-disable-next-line no-inner-declarations
      function handleOpenModal(marker: any, configuracaoId: number) {
        const contentString = `<div id="content">
    <h1 id="firstHeading" class="mapTooltipHeader">Carregando Informações...</h1>
    </div>`;

        /*    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    }); */

        infoWindow.setContent(contentString);

        infoWindow.open({
          anchor: marker,
        });

        openModalInfoMapa(marker, infoWindow, configuracaoId);
      }

      // eslint-disable-next-line no-inner-declarations
      function createDiv() {
        const novaDiv = document.createElement('div') as HTMLDivElement;
        novaDiv.style.margin = '1px';
        novaDiv.style.padding = '1px';
        return novaDiv;
      }

      // Ferramenta
      /*  const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            // google.maps.drawing.OverlayType.CIRCLE,
            // google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        circleOptions: {
          fillColor: '#00ff00',
          fillOpacity: 0.3,
          strokeWeight: 2,
          clickable: false,
          editable: false,
          zIndex: 1,
        },
        polylineOptions: {
          strokeColor: '#00f0ff',
          strokeWeight: 2,
          clickable: false,
          editable: !false,
          zIndex: 1,
        },
      }); */

      // drawingManager.setMap(mapx);

      // if (!mapIsNew) console.log(mapx);

      const markers: any[] = [];

      let listaUsar = listaMapa;

      if (tipoFiltrar === 2)
        listaUsar = listaMapa.filter(item => item.status_id !== 3);
      else if (tipoFiltrar === 3)
        listaUsar = listaMapa.filter(item => item.status_id === 3);

      // eslint-disable-next-line no-restricted-syntax
      for await (const marcador of listaUsar) {
        const lat = marcador.geodesico?.split(',')[0];
        const lng = marcador.geodesico?.split(',')?.[1] ?? null;

        const statusBuscar = status?.find(
          item => item.id === marcador.status_id,
        );
        const isFV = marcador.produto_id === 23;
        const isLatLngValid = true;
        /* if (isFV) {
          const geoRetorno = await new google.maps.Geocoder().geocode({
            address: marcador.geodesico,
          });

          const results = geoRetorno as any;
          if (results?.[0]) {
            const l1 = results?.[0]?.geometry.location.lat();
            const l2 = results?.[0]?.geometry.location.lng();

            if (l1 && l2) {
              isLatLngValid = true;
              lat = String(l1);
              lng = String(l2);
            } else {
              isLatLngValid = false;
            }
          }
        } */

        if (isLatLngValid) {
          const myLatlng = new google.maps.LatLng(Number(lat), Number(lng));

          const marker = new google.maps.Marker({
            position: myLatlng,
            title: `Detalhes da Configuração #${marcador.orcamento_id}`,
            icon: `https://maps.google.com/mapfiles/ms/icons/${
              statusBuscar && statusBuscar.nomeCor
                ? `${statusBuscar.nomeCor}`
                : 'blue'
            }-dot.png`,
          });

          const lista = new google.maps.Circle({
            fillColor: '#00ff00',
            fillOpacity: 0.3,
            strokeWeight: 0.5,
            clickable: false,
            editable: false,
            zIndex: 1,
            map: mapx,
            center: myLatlng,
            radius: marcador.raio ?? 0, // metros
          });

          marker.addListener('click', () => {
            handleOpenModal(marker, marcador.orcamento_id);
          });

          markers.push(marker);
          // marker.setMap(mapx);
        }
      }

      const totalAnoControl = createButton(
        1,
        'Total Ano',
        valorTotal,
        quantidadeTotal,
        '',
      );
      const totalAbertoControl = createButton(
        2,
        'Total Abertas',
        valorTotalAbertas,
        quantidadeTotalAbertas,
        '',
      );
      const totalAprovadaControl = createButton(
        3,
        'Total Aprovadas',
        valorTotalAprovadas,
        quantidadeTotalAprovadas,
        '',
      );

      /* loadControl.appendChild(
        createSVG(
          'M0 0h24v24H0z M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z M16 5l3 3 M9 7.07a7 7 0 0 0 1 13.93a7 7 0 0 0 6.929 -6',
          '24 24',
          '2px',
        ),
      ); */

      const totalAnoDiv = createDiv();
      const totalAbertoDiv = createDiv();
      const totalAprovadaDiv = createDiv();
      const taxaConversaoDiv = createDiv();
      const totalizadorDiv = createDiv();

      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(totalAnoDiv);
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(totalAbertoDiv);
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(
        totalAprovadaDiv,
      );
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(
        taxaConversaoDiv,
      );
      mapx.controls[google.maps.ControlPosition.TOP_RIGHT].push(totalizadorDiv);

      const taxaConversao = arredondaDecimais(
        (valorTotalAprovadas / valorTotal) * 100,
        2,
      );

      const taxaConversaoContent = buttonHtmlTemplate(
        'Taxa de Conversão',
        taxaConversao,
        0,
        true,
      );

      const dataProdutosMostrando = listaUsar.map((item: PropostaMapa) => {
        return item.produto_id;
      });

      const produtosMapa = [...new Set(dataProdutosMostrando)];

      const taxaConversaoControl = createHtmlContent(taxaConversaoContent);

      // com os produtos, posso filtrar e exibir os dados específicos.
      // Trazer em um endpoint a lista de produtos, com seu label de exibição e o tipo de valor
      // Assim, fica mais dinâmico. Podendo, talvez, adicionar no próprio cadastro de produto depois.
      // O que tiver valor zerado, não mostra. Se não houver nada, não mostra o totalizador.

      const totalizadorControl =
        createHtmlContent(`<div style="font-size: 15px; padding: 4px; ">
        ${produtosMapa
          .map((prod: number) => {
            const detProd = produtos?.find(
              (item: ProdutoModel) => item.id === prod,
            );

            if (detProd) {
              const totalSomado = arredondaDecimais(
                listaUsar
                  .filter((it: any) => it.produto_id === prod)
                  .reduce(
                    (total, val) => total + Number(val.valor_produto ?? 0),
                    0,
                  ),
                2,
              );

              return `<strong>${
                detProd.labelDashboard
              }:</strong> ${formatNumeros(totalSomado)} ${
                detProd.unidadeMedidaDashboard ?? ''
              }<br />`;
            }

            return ``;
          })
          .join('')}


      </div>`);

      totalAnoDiv.appendChild(totalAnoControl);
      totalAbertoDiv.appendChild(totalAbertoControl);
      totalAprovadaDiv.appendChild(totalAprovadaControl);
      taxaConversaoDiv.appendChild(taxaConversaoControl);
      totalizadorDiv.appendChild(totalizadorControl);

      mapx.setCenter({ lat: -22, lng: -48 });
      mapx.setZoom(5);

      mapx.addListener('click', () => {
        if (infoWindow) {
          infoWindow.close();
        }
      });

      // eslint-disable-next-line no-new

      const markerCluster = new MarkerClusterer({
        map: mapx,
        markers,
        // renderer,
      });

      // setGlobalMap(map);
    }
  }

  async function loadContent() {
    setDataReady(false);
    // Carregar os dados
    const lista = propostaMapa as PropostaMapa[];

    const listaUsar = lista; // .filter(item => item.geodesico?.length ?? 0 > 3);

    await sleep(3000);

    setListaMapa(listaUsar);

    setDataReady(true);
  }

  useEffect(() => {
    if (dataReady === true) {
      renderMap(1, false);
    } else {
      const divTeste = document.getElementById('mapId') as HTMLElement;
      divTeste.innerHTML = '';
    }
  }, [dataReady, listaMapa]);

  useEffect(() => {
    if (propostaTotal) {
      setValorTotal(propostaTotal.valor ?? 0);
      setQuantidadeTotal(propostaTotal.quantidade ?? 0);
    }
    if (propostaAberta) {
      setValorTotalAbertas(propostaAberta.valor ?? 0);
      setQuantidadeTotalAbertas(propostaAberta.quantidade ?? 0);
    }
    if (propostaAprovada) {
      setValorTotalAprovadas(propostaAprovada.valor ?? 0);
      setQuantidadeTotalAprovadas(propostaAprovada.quantidade ?? 0);
    }

    if (propostaMapa) loadContent();
  }, [propostaMapa, propostaTotal, propostaAberta, propostaAprovada]);

  useEffect(() => {
    if (refreshMapa) {
      loadData();
    }
    setRefreshMapa(false);
  }, [refreshMapa]);

  return (
    <>
      <Card
        className={`card-stats ${className} mb-xl-0`}
        style={{ padding: 0, border: '1px solid black' }}
      >
        <CardBody style={{ padding: 0 }}>
          <Row style={{ padding: 0 }}>
            <div className="col">
              <span className="h2 font-weight-bold mb-4 mt-4">
                {!dataReady && (
                  <CircularProgress
                    className="mt-2 ml-2"
                    color="inherit"
                    size={15}
                  />
                )}
                <div style={{ minHeight: '300px', width: '100%' }}>
                  <div id="mapId" style={{ height: '600px', fontSize: '20' }} />
                </div>
              </span>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
