/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
  CardBody,
  Input,
  InputGroup,
  FormGroup,
  Badge,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import api from 'services/api';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import toast from 'react-hot-toast';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { iColunas, iImagens, iReport } from 'models/TemplateReport';
import './style.css';
import Produto from 'models/Produto';
import Swal from 'sweetalert2';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type LocationState = {
  stateReport?: iReport;
  itemPai?: iReport;
  produtos?: Produto[];
};

const TemplateRelatorioEdicao: React.FC = () => {
  const location = useLocation<LocationState>();
  const { stateReport, produtos, itemPai } = location.state;

  const history = useHistory();

  const [relatorio, setRelatorio] = useState(stateReport);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRendering, setIsRendering] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [imagens, setImagens] = useState([] as iImagens[]);
  const [colunas, setColunas] = useState([] as iColunas[]);
  const [jsonContent, setJsonContent] = useState<string>('');
  const [formModalColunasState, setFormModalColunasState] = useState(false);
  const [formModalImagensState, setFormModalImagensState] = useState(false);
  const [proposta, setProposta] = useState<string>('');
  const [propostaVersao, setPropostaVersao] = useState<string>('');

  function handleJsonChange(newValue: string) {
    setJsonContent(newValue);
    try {
      JSON.parse(newValue);
      setHasError(false);
    } catch (err) {
      setHasError(true);
    }
  }

  function stringifyWithFunctions(obj: any) {
    return JSON.stringify(
      obj,
      function (key, value) {
        if (typeof value === 'function') {
          return value.toString();
        }
        return value;
      },
      2,
    );
  }

  function parseWithFunctions(jsonStr: any) {
    return JSON.parse(jsonStr, function (key, value) {
      // Verifica se a string tem o padrão de uma função
      if (
        typeof value === 'string' &&
        (value.trim().startsWith('function') ||
          value.trim().startsWith('(currentPage'))
      ) {
        // eslint-disable-next-line no-new-func
        return new Function(`return ${value}`)();
      }
      return value;
    });
  }

  async function buscarDados() {
    if (!relatorio) return;
    const response = await api.get(`/report/${relatorio.id}`);

    if (response.data && response.data?.conteudo) {
      try {
        setJsonContent(stringifyWithFunctions(response.data?.conteudo));
      } catch (e: any) {
        console.log(e);
        setJsonContent(JSON.stringify({}));
      }
    }
  }

  async function loadImages() {
    if (!relatorio) return;
    const response = await api.get(
      `/report/imagens?produtos=${relatorio.produtoId}`,
    );
    setImagens(response.data);
  }

  async function loadColumns() {
    if (!relatorio) return;
    const response = await api.get(
      `/report/colunas?produtos=${relatorio.produtoId}`,
    );

    const listaAux = [];

    if (
      relatorio.tipo === 'H' ||
      relatorio.tipo === 'B' ||
      relatorio.tipo === 'F'
    ) {
      listaAux.push({
        variavel: 'CurrentPage',
        produtoId: null,
      });
    }

    if (relatorio.tipo === 'H' || relatorio.tipo === 'F') {
      listaAux.push({
        variavel: 'TotalPages',
        produtoId: null,
      });
    }

    listaAux.push(...response.data);

    setColunas(listaAux);
  }

  async function loadData() {
    await loadImages();
    await loadColumns();
    setIsLoading(false);
  }

  useEffect(() => {
    if (colunas.length && imagens.length) {
      buscarDados();
    }
  }, [colunas, imagens]);

  useEffect(() => {
    if (isLoading) {
      loadData();
    }
  }, [isLoading]);

  async function validarPropostaVersao() {
    const response = await api.get(`/proposta/${proposta}/${propostaVersao}`);

    return response.data.id;
  }

  async function handleRenderIFrame(content: any, versaoId: number) {
    if (!relatorio) return;

    try {
      const response = await api.post(
        `/report/render-preview`,
        {
          conteudo: parseWithFunctions(content),
          propostaVersaoId: versaoId,
          produtosId: relatorio.produtoId,
          reportContentPaiId: relatorio.reportContentPaiId,
        },
        {
          responseType: 'blob',
        },
      );

      const pdfBlobUrl = URL.createObjectURL(response.data);
      const iframe = document.getElementById(
        'i-pdf-preview',
      ) as HTMLIFrameElement;
      iframe.src = pdfBlobUrl;
    } catch (error: any) {
      if (error.response && error.response.data instanceof Blob) {
        try {
          const errorText = await error.response.data.text();
          const errorJson = JSON.parse(errorText);
          const errorMessage = errorJson.message;

          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            text: errorMessage,
          });

          console.error('Error message:', errorMessage);
        } catch (e) {
          console.error('Error parsing the response blob', e);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: error.message,
        });
      }
    }
  }

  async function handlePreview() {
    if (hasError) return;

    let versaoId = 0;

    if (propostaVersao && proposta) {
      const registro = await validarPropostaVersao();

      if (registro) versaoId = registro;
    }

    setIsRendering(true);

    const toastId = toast.loading('Renderizando conteúdo...', {
      position: 'top-right',
    });
    try {
      await handleRenderIFrame(jsonContent, versaoId);
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsRendering(false);
      toast.dismiss(toastId);
    }
  }

  async function handleSaveJson() {
    if (hasError || !relatorio) return;
    setIsSaving(true);
    const toastId = toast.loading('Salvando conteúdo...', {
      position: 'top-right',
    });
    try {
      const convertToObject = parseWithFunctions(jsonContent);
      await api.put(`/report/${relatorio.id}/conteudo`, {
        conteudo: convertToObject,
      });

      toast.success('Registro salvo com sucesso');
    } catch (e: any) {
      console.log(e);
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }
  function truncarTexto(texto: string) {
    if (texto.length > 90) {
      return `${texto.slice(0, 87)}...`;
    }
    return texto;
  }

  if (!relatorio) return <></>;
  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="6">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: `${'/admin/template-relatorios'}`,
                    });
                  }}
                >
                  <FaArrowLeft />
                  <span className="btn-inner--text">Voltar</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalColunasState}
              toggle={() => setFormModalColunasState(!formModalColunasState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader>
                    <h2>Colunas disponíveis</h2>
                    <small>Clique nela para copiar sua variável de uso</small>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <div>
                      <h3>Uso Geral</h3>
                      <Row>
                        <Col sm="12">
                          {colunas
                            .filter(it => it.produtoId === null)
                            .map((item, index: number) => (
                              <Badge
                                key={`key_geral_${index}`}
                                color="primary"
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={(e: any) => {
                                  e.target.focus();
                                  navigator.clipboard.writeText(
                                    `#${item.variavel}#`,
                                  );

                                  toast.success(
                                    'Variável copiada para área de transferência',
                                  );
                                }}
                              >
                                {truncarTexto(item.variavel)}
                              </Badge>
                            ))}
                        </Col>
                      </Row>

                      {relatorio.produtoId.length > 0 && (
                        <>
                          {relatorio.produtoId.map(it => {
                            const produtoUsar = produtos?.find(
                              it2 => it2.id === it,
                            );

                            if (produtoUsar) {
                              return (
                                <>
                                  <h3 className="mt-2">{produtoUsar.nome}</h3>
                                  <Row key={`listar_${produtoUsar.id}`}>
                                    <Col sm="12">
                                      {colunas
                                        .filter(it3 => it3.produtoId === it)
                                        .map((item, index: number) => (
                                          <Badge
                                            key={`key_${it}_${index}`}
                                            color="primary"
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={(e: any) => {
                                              e.target.focus();
                                              navigator.clipboard.writeText(
                                                `#${item.variavel}#`,
                                              );

                                              toast.success(
                                                'Variável copiada para área de transferência',
                                              );
                                            }}
                                          >
                                            {truncarTexto(item.variavel)}
                                          </Badge>
                                        ))}
                                    </Col>
                                  </Row>
                                </>
                              );
                            }
                            return <></>;
                          })}
                        </>
                      )}
                    </div>

                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        disabled={isSaving}
                        onClick={() =>
                          setFormModalColunasState(!formModalColunasState)
                        }
                      >
                        Fechar
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              size="xl"
              isOpen={formModalImagensState}
              toggle={() => setFormModalImagensState(!formModalImagensState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader>
                    <h2>Imagens disponíveis</h2>
                    <small>Clique nela para copiar sua variável de uso</small>
                    <br />
                    <small
                      hidden={Number(relatorio.produtoId.length ?? 0) === 0}
                    >
                      Mostrando imagens disponíveis para o(s) produto(s):{' '}
                      {relatorio.produtoId
                        .map(it => {
                          return (
                            produtos?.find(it2 => it2.id === it)?.nome ?? ''
                          );
                        })
                        ?.join(', ')}
                    </small>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Row>
                      <Col sm="12" className="flex">
                        <ImageList
                          cols={6}
                          style={{
                            flexWrap: 'wrap',
                            transform: 'translateZ(0)',
                          }}
                        >
                          {imagens.map((item, index: number) => (
                            <ImageListItem
                              key={`key_${index}`}
                              onClick={(e: any) => {
                                e.target.focus();
                                navigator.clipboard.writeText(`
    {
      "image": "#IMAGEM[${item.variavel}]#",
      "width": 300,
      "alignment": "center"
    },`);

                                toast.success(
                                  'Variável copiada para área de transferência',
                                );
                              }}
                            >
                              <img
                                src={item.base64Small}
                                style={{
                                  cursor: 'pointer',
                                }}
                                alt={item.descricao}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </Col>
                    </Row>

                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        disabled={isSaving}
                        onClick={() =>
                          setFormModalImagensState(!formModalImagensState)
                        }
                      >
                        Fechar
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">
                      Editando <i>{relatorio.nome}</i> | Pertence ao Relatório{' '}
                      <i>{itemPai && `${itemPai.nome}`}</i>
                    </h3>
                    <small
                      hidden={Number(relatorio.produtoId.length ?? 0) === 0}
                    >
                      Template para o(s) produto(s):{' '}
                      {relatorio.produtoId
                        .map(it => {
                          return (
                            produtos?.find(it2 => it2.id === it)?.nome ?? ''
                          );
                        })
                        ?.join(', ')}
                    </small>
                  </Col>
                </Row>
              </CardHeader>

              <Row>
                <Col sm="6">
                  <Button
                    size="sm"
                    type="button"
                    className="btn-icon mr-1"
                    color="primary"
                    onClick={() =>
                      setFormModalImagensState(!formModalImagensState)
                    }
                    disabled={hasError || isSaving || isRendering || isLoading}
                  >
                    Imagens
                  </Button>
                  <Button
                    size="sm"
                    type="button"
                    className="btn-icon mr-1"
                    color="primary"
                    onClick={() =>
                      setFormModalColunasState(!formModalColunasState)
                    }
                    disabled={hasError || isSaving || isRendering || isLoading}
                  >
                    Colunas
                  </Button>

                  <Button
                    size="sm"
                    type="button"
                    className="btn-icon float-right mr-1"
                    color="primary"
                    onClick={handleSaveJson}
                    disabled={hasError || isSaving || isRendering || isLoading}
                  >
                    {isSaving ? `Salvando...` : 'Salvar'}
                  </Button>
                  <AceEditor
                    mode="json"
                    theme="monokai"
                    value={jsonContent}
                    onChange={handleJsonChange}
                    name="json-editor"
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{ useWorker: false }}
                    width="100%"
                    height="75vh"
                  />
                  <div hidden={!hasError} style={{ color: 'red' }}>
                    JSON Inválido
                  </div>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <InputGroup>
                      <Input
                        placeholder="Proposta"
                        type="text"
                        onChange={text => setProposta(text.target.value)}
                        className="form-control-sm"
                      />
                      <Input
                        placeholder="Versão da Proposta"
                        type="text"
                        onChange={text => setPropostaVersao(text.target.value)}
                        className="form-control-sm"
                      />

                      <Button
                        size="sm"
                        type="button"
                        className="btn-icon float-right"
                        color="primary"
                        onClick={handlePreview}
                        disabled={
                          (relatorio.tipo !== 'C' && relatorio.tipo !== 'P') ||
                          hasError ||
                          isSaving ||
                          isRendering ||
                          isLoading
                        }
                      >
                        Renderizar Conteúdo
                      </Button>
                    </InputGroup>
                  </FormGroup>

                  <iframe
                    id="i-pdf-preview"
                    style={{
                      width: '100%',
                      backgroundColor: '#FFFFFF',
                      height: '75vh',
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TemplateRelatorioEdicao;
