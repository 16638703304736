/* eslint-disable no-nested-ternary */
import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';

import { ProdutoProposta } from 'models/ProdutoProposta';
import './style.css';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import { CircularProgress } from '@material-ui/core';
import FotovoltaicaParcelas from '../GraficosTabelas/FotovoltaicaParcelas';
import FotovoltaicaGraficoCustoTotal from '../GraficosTabelas/FotovoltaicaGraficoCustoTotal';
import FotovoltaicaGraficoFluxoCaixa from '../GraficosTabelas/FotovoltaicaGraficoFluxoCaixa';
import imgCapa from '../../../../../assets/img/products/usina_fv/capa.png';
import imgCsf from '../../../../../assets/img/products/usina_fv/csf.png';
import imgPsf from '../../../../../assets/img/products/usina_fv/psf.png';
import LogoImg from '../assets/img/logo_fck.png';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  produtoProposta: ProdutoProposta;
}

interface PropostaImpressaoContext {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  isPropostaExterior: boolean;
  setIsPropostaExterior: (valor: boolean) => void;
  valorDolar: number;
  setValorDolar: (valor: number) => void;
  parcelas: PropostaVersaoParcelas[];
}

export const PropostaImpressaoContext = createContext(
  {} as PropostaImpressaoContext,
);

interface iParamsLista {
  label: string;
  valor: any;
}

interface TemplateReportTableProperties {
  columnName: string;
  columnProperty: string;
}

interface TemplateReportRequisites {
  propertyName: string;
  value: string;
}

interface TemplateReport {
  content: string;
  type: string;
  align: string | 'L';
  isBold: boolean | false;
  sharedLine: boolean | false;
  breakLineBefore: boolean | false;
  imageWidth?: number;
  imageLenght?: number;
  component?: string;
  objectToLoop?: string;
  tableProperties?: TemplateReportTableProperties[];
  templatePreRequisite?: TemplateReportRequisites[];
}

export default function FVImpressao({
  valores,
}: {
  valores: iParamsLista[];
}): JSX.Element {
  const location = useLocation<LocationState>();
  const { proposta, propostaVersao, produtoProposta } = location.state;

  const propostaVersaoId = propostaVersao.id;

  const [reportContent, setReportContent] = useState<TemplateReport[]>([]);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [atualizarGraficos, setAtualizarGraficos] = useState(false);

  async function buscarDadosRelatorio() {
    setIsLoadingReport(true);
    const response = await api.get(
      `/proposta-impressao/versao/${propostaVersao.id}`,
    );

    setReportContent(response.data as TemplateReport[]);
    setIsLoadingReport(false);
  }

  const components = [
    {
      id: 'FotovoltaicaParcelas',
      componente: FotovoltaicaParcelas,
      isFotovoltaica: true,
      isIrrigacao: false,
      isChart: false,
    },
    {
      id: 'FotovoltaicaGraficoCustoTotal',
      componente: FotovoltaicaGraficoCustoTotal,
      isFotovoltaica: true,
      isIrrigacao: false,
      isChart: true,
    },
    {
      id: 'FotovoltaicaGraficoFluxoCaixa',
      componente: FotovoltaicaGraficoFluxoCaixa,
      isFotovoltaica: true,
      isIrrigacao: false,
      isChart: true,
    },
  ];

  useEffect(() => {
    buscarDadosRelatorio();
  }, []);
  useEffect(() => {
    if (propostaVersaoId && propostaVersao) {
      //
    }
  }, [propostaVersao, propostaVersaoId]);

  return (
    <>
      <div style={{ pageBreakInside: 'auto', fontSize: '19px' }}>
        <Row>
          <Col sm="12" className="mt-1">
            <div
              style={{
                float: 'left',
                padding: '5px',
              }}
            >
              <img src={LogoImg} alt="Logo" width="65%" height="65%" />
            </div>
            <div style={{ width: '100%', float: 'left' }}>&nbsp;</div>
          </Col>
        </Row>
        {isLoadingReport ? (
          <Row>
            <Col sm="12" className="text-center mt-5">
              <CircularProgress />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm="12" className="mt-1">
              <div style={{ width: '100%' }}>&nbsp;</div>

              {reportContent.map(item => {
                const isTexto = item.type === 'T';
                const isTitulo = item.type === 'TI';
                const isImage = item.type === 'IMG';
                const isTable = item.type === 'TABLE';
                const isComponent = item.type === 'COMPONENT';

                const isBold = isTexto && item.isBold;

                const alignCenter = item.align === 'C';
                const alignRight = item.align === 'R';

                // eslint-disable-next-line no-nested-ternary
                const alignContent = alignCenter
                  ? 'center'
                  : alignRight
                  ? 'right'
                  : 'justify';

                if (isComponent) {
                  const componenteFiltrado = components.filter(
                    componente => componente.id === item.content,
                  );

                  const DynamicComponent =
                    componenteFiltrado && componenteFiltrado.length
                      ? componenteFiltrado[0].componente
                      : null;

                  const breakPage = item.breakLineBefore;

                  if (DynamicComponent) {
                    return (
                      <>
                        {breakPage && <div className="break-page" />}
                        <div
                          className="mt-2 component-print"
                          style={{
                            float: 'left',
                            width: item.sharedLine === true ? '50%' : '100%',
                            textAlign: alignContent,
                            padding: '5px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {atualizarGraficos ? (
                            <>Atualizando componente</>
                          ) : (
                            <DynamicComponent
                              propostaVersaoId={propostaVersao.id}
                              propostaVersao={propostaVersao}
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                }

                if (isTable) return <></>;

                const fontSize = isTitulo ? '30px' : '20px';
                const breakPage = item.breakLineBefore;

                const imgToUse =
                  item.content === 'img_capa'
                    ? imgCapa
                    : item.content === 'img_csf'
                    ? imgCsf
                    : item.content === 'img_psf'
                    ? imgPsf
                    : null;

                return (
                  <>
                    {breakPage && <div className="break-page" />}
                    <div
                      className={`mt-3 ${
                        isTitulo ? `class-titulo-size` : 'class-default-size'
                      }`}
                      style={{
                        width: item.sharedLine === true ? '50%' : '100%',
                        fontWeight: isBold ? 'bold' : 'normal',
                        textAlign: alignContent,
                        // border: '1px solid red',
                        fontSize,
                        float: 'left',
                        padding: '5px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {isImage && imgToUse ? (
                        <img src={imgToUse} alt="Imagem" width="80%" />
                      ) : (
                        item.content
                      )}
                    </div>
                    {item.sharedLine === false && <br />}
                  </>
                );
              })}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
