import { useEffect, useState } from 'react';

import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useDebouncedEffect from 'hooks/useDebouncedEffect';

import { FaSearch } from 'react-icons/fa';
import {
  Card,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import api from 'services/api';

interface Props {
  descricao?: string;
  children?: any;
  handleSelecionarItemDomsge: (itemDomsge: ItemDomsge | null) => void;
  hideTitle?: boolean;
  maxWidth?: string;
  noAutoFocus?: boolean;
  emprCodigo?: string | undefined;
  geral?: boolean;
}

interface Item {
  FABR_EMPR_CODIGO: string;
  FABR_CODIGO: string;
  DITE_DGPE_CODIGO: string;
  DITE_CODIGO: string;
  DESCRICAO: string;
}

interface ItemDomsge {
  DITE_CODIGO: string;
  FABR_CODIGO: string;
  DITE_DGPE_CODIGO: string;
  FABR_EMPR_CODIGO: string;
  DESCRICAO: string;
  QUANTIDADE?: number;
}

function AutocompleteItemDomsge({
  descricao,
  children,
  handleSelecionarItemDomsge,
  hideTitle,
  maxWidth,
  noAutoFocus,
  emprCodigo,
  geral,
}: Props): JSX.Element {
  const [loadingItens, setLoadingItens] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([] as Item[]);
  const [open, setOpen] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState<ItemDomsge | null>(
    options[0],
  );

  useDebouncedEffect(
    () => {
      let active = true;

      if (!open || loadingItens || inputValue.length < 3) {
        return undefined;
      }

      (async () => {
        try {
          setLoadingItens(true);

          // Modificado temporariamente. Será deixado dinâmico na sprint 44
          const codigoEmpresa = emprCodigo !== '' ? emprCodigo : '710';

          const response = await api.get(`/integracao/item`, {
            params: {
              search: inputValue,
              emprCodigo: codigoEmpresa ?? '',
              geral,
            },
          });

          if (active) {
            setOptions(response.data);
          }
        } finally {
          setLoadingItens(false);
        }
      })();

      return () => {
        active = false;
      };
    },
    1000,
    [inputValue],
  );

  useEffect(() => {
    if (!itemSelecionado) {
      setInputValue('');
    }

    setLoadingItens(false);
    setOpen(false);
    setOptions([]);
  }, [itemSelecionado]);

  return (
    <Card className="shadow mt-3 mb-1">
      <Col className="mt-4">
        <FormGroup className="mb-4">
          {!hideTitle && (
            <h5 className="mb-3">
              {descricao ? `${descricao} | ` : ''} Vincular item
            </h5>
          )}

          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
            </InputGroupAddon>

            <Autocomplete
              className="mt-1"
              style={{ maxWidth: maxWidth || '51vw' }}
              fullWidth
              size="small"
              open={open}
              options={options}
              loading={loadingItens}
              inputValue={inputValue}
              onOpen={() => {
                setOpen(true);
              }}
              getOptionSelected={(option: Item, value) =>
                option.DITE_CODIGO === value.DITE_CODIGO
              }
              getOptionLabel={option =>
                option?.DITE_CODIGO
                  ? `${option.DITE_CODIGO} - ${option.DESCRICAO}`
                  : ''
              }
              loadingText="Carregando..."
              noOptionsText="Nada encontrado"
              value={itemSelecionado}
              onChange={(_, itemDomsge) => {
                setItemSelecionado(itemDomsge);
                handleSelecionarItemDomsge(itemDomsge);
              }}
              onInputChange={(_, newInputValue) => {
                if (!newInputValue) return;

                setInputValue(newInputValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={input => input && !noAutoFocus && input.focus()}
                  size="small"
                  label="Pesquise um item..."
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 13 } }}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: 13 },
                    endAdornment: (
                      <>
                        {loadingItens ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            {children}
          </InputGroup>
        </FormGroup>
      </Col>
    </Card>
  );
}

export default AutocompleteItemDomsge;
