import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from 'services/api';

interface FotovoltaicaConstantes {
  id: number;
  constanteGrupoId: number;
  nome: string;
  valor: number;
  produtoCategoriaId: number;
}

interface ConstanteGrupo {
  id: number;
  nome: string;
  descricao: string;
}

interface RouteParams {
  fotovoltaica: string;
  irrigacao: string;
}

const CadastroFotovoltaicaConstantes: React.FC = () => {
  const [fotovoltaicaConstantes, setFotovoltaicaConstantes] = useState<
    FotovoltaicaConstantes[]
  >([]);
  // eslint-disable-next-line prettier/prettier
  const [fotovoltaicaConstantesEditing, setFotovoltaicaConstantesEditing] = useState({} as FotovoltaicaConstantes);
  const [constanteGrupo, setConstanteGrupo] = useState<ConstanteGrupo[]>([]);
  const [formModalState, setFormModalState] = useState(false);
  const [label, setLabel] = useState('');
  const [isDefault, setIsDefault] = useState(true);
  const [labelCategoria, setLabelCategoria] = useState('');

  // Parâmetros recebidos pela url e usados para filtrar as constantes por categoria de produto
  const { fotovoltaica, irrigacao } = useParams<RouteParams>();

  async function carregaFotovoltaicaConstantes() {
    if (fotovoltaica || irrigacao) {
      const categoria = fotovoltaica
        ? fotovoltaica.split(':')
        : irrigacao.split(':');

      setIsDefault((categoria[1] === '' || undefined) ?? false);

      setLabelCategoria(
        categoria[1] === 'fotovoltaica' ? 'Fotovoltaica' : 'Irrigação',
      );
      if (categoria[1] === 'fotovoltaica') {
        const response = await api.get('/constante/categoria/4');
        if (response.data) {
          setFotovoltaicaConstantes(response.data as FotovoltaicaConstantes[]);
        }
      } else if (categoria[1] === 'irrigacao') {
        const response = await api.get('/constante/categoria/1');
        if (response.data) {
          setFotovoltaicaConstantes(response.data as FotovoltaicaConstantes[]);
        }
      }
    } else {
      const response = await api.get('/constante');
      if (response.data) {
        setFotovoltaicaConstantes(response.data as FotovoltaicaConstantes[]);
      }
    }
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaFotovoltaicaConstantes();
  }, [fotovoltaica, irrigacao]);

  async function carregaConstantesGrupos() {
    const response = await api.get('/constante/grupo');
    setConstanteGrupo(response.data as ConstanteGrupo[]);
  }

  useEffect(() => {
    carregaConstantesGrupos();
  }, []);

  async function handleDelete(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja excluir o Registro?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.delete(`/constante/${row.id}`, {});
          Swal.close();
          toast.success('Registro excluído');
          setFotovoltaicaConstantes(
            fotovoltaicaConstantes.filter(item => item.id !== row.id),
          );
        }
      });
      carregaFotovoltaicaConstantes();
    } else {
      setFotovoltaicaConstantes(
        fotovoltaicaConstantes.filter(item => item.id !== row.id),
      );
    }
  }

  function handleUpdate(constante: FotovoltaicaConstantes) {
    setFotovoltaicaConstantesEditing(constante);
    setFormModalState(!formModalState);
  }

  async function handleSavefotovoltaicaConstantes() {
    // eslint-disable-next-line prettier/prettier
    const { nome } = fotovoltaicaConstantesEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (fotovoltaicaConstantesEditing.id) {
      await api.put(`/constante/${fotovoltaicaConstantesEditing.id}`, {
        id: fotovoltaicaConstantesEditing.id,
        constanteGrupoId: fotovoltaicaConstantesEditing.constanteGrupoId,
        nome: fotovoltaicaConstantesEditing.nome,
        valor: fotovoltaicaConstantesEditing.valor,
      });
    }
    toast.success('Registro concluído');
    carregaFotovoltaicaConstantes();
    setFormModalState(false);
    setFotovoltaicaConstantesEditing({} as FotovoltaicaConstantes);
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Cadastro/Edição de Constantes</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Valor</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Valor"
                            value={fotovoltaicaConstantesEditing.valor}
                            type="number"
                            onChange={text =>
                              setFotovoltaicaConstantesEditing({
                                ...fotovoltaicaConstantesEditing,
                                valor: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSavefotovoltaicaConstantes}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Cadastro de Constantes{' '}
                  {isDefault ? '' : `(${labelCategoria})`}
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Grupo</th>
                    <th>Nome</th>
                    <th>Valor</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {fotovoltaicaConstantes.map(constante => {
                    const grupo = constanteGrupo.filter(
                      cg => cg.id === constante.constanteGrupoId,
                    );
                    return (
                      <tr key={constante.id}>
                        <td className="py-1">{grupo?.[0]?.descricao ?? ''}</td>
                        <td className="py-1">{constante.nome}</td>
                        <td className="py-1">{constante.valor}</td>
                        <td>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="warning"
                            type="button"
                            onClick={() => {
                              handleUpdate(constante);
                            }}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(constante);
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CadastroFotovoltaicaConstantes;
