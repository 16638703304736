import React, { useState, useEffect } from 'react';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import { TextField } from '@material-ui/core';

import 'antd/dist/antd.css';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import api from 'services/api';
import {
  FaCheckCircle,
  FaPlusCircle,
  FaTimesCircle,
  FaFileImport,
  FaInfo,
} from 'react-icons/fa';
import Swal from 'sweetalert2';

import toast from 'react-hot-toast';
import UsuarioModel from '../../../models/Usuario';

const MigracaoOwner: React.FC = () => {
  const [listaUsuarios, setListaUsuarios] = useState<UsuarioModel[]>([]);
  const [usuarioOrigem, setUsuarioOrigem] = useState({} as UsuarioModel);
  const [usuarioDestino, setUsuarioDestino] = useState({} as UsuarioModel);
  const [numeroGnf, setNumeroGnf] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  async function CarregaUsuarios() {
    const response = await api.get(`/usuario`);
    const listaUsuariosResponse = response.data as UsuarioModel[];
    setListaUsuarios(listaUsuariosResponse);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    CarregaUsuarios();
  }, []);

  async function handleSave() {
    let configs = 0;
    let propostas = 0;
    if (!numeroGnf) {
      Swal.fire({
        title: `Gnf não informado. Todas as propostas e configurações do usuário de origem serão migradas para o usuário de destino. Deseja continuar?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          setIsSaving(true);
          try {
            const response = await api.put('/migracao-owner', {
              usuarioDestino: usuarioDestino.id,
              usuarioOrigem: usuarioOrigem.id,
              gnf: numeroGnf,
            });
            propostas = response.data.proposta;
            configs = response.data.configuracao;
          } catch (error: any) {
            toast.dismiss();
            toast.error('Ops! Algo de errado aconteceu!');
          } finally {
            toast.success('Registro concluído');
            Swal.fire({
              icon: 'info',
              title: 'Registros afetados!',
              html: `Propostas afetadas: ${propostas} </br>.
              Configurações afetadas: ${configs} </br>`,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 5000,
            });
            setIsSaving(false);
          }
        }
      });
      setIsSaving(false);
    } else {
      Swal.fire({
        title: `Todas as propostas e configurações do usuário de origem que estão atribuídas ao gnf informado serão migradas para o usuário de destino. Deseja continuar?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          setIsSaving(true);
          try {
            const response = await api.put('/migracao-owner', {
              usuarioDestino: usuarioDestino.id,
              usuarioOrigem: usuarioOrigem.id,
              gnf: numeroGnf,
            });
            propostas = response.data.proposta;
            configs = response.data.configuracao;
          } catch (error: any) {
            toast.dismiss();
            Swal.fire({
              icon: 'error',
              title: 'Ops!',
              text: error?.response?.data?.message,
            });
          } finally {
            toast.success('Registro concluído');
            Swal.fire({
              icon: 'info',
              title: 'Registros afetados!',
              html: `Propostas afetadas: ${propostas} </br>.
              Configurações afetadas: ${configs} </br>`,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 5000,
            });
            setIsSaving(false);
          }
        }
      });
    }
  }

  return (
    <>
      <UserSmallHeader />
      <CardHeader>
        <Row>
          <Col sm="12">
            <h4 className="mb--1">Migração de owner</h4>
            <small className="text-muted"> </small>
          </Col>
        </Row>
      </CardHeader>
      <Container
        className="mt-4 ml-2 mr-2"
        fluid
        style={{ backgroundColor: '#fff', border: '1px solid #ebe9e9' }}
      >
        <div className="card card-frame mt-2 mr-2">
          <div className="card-body">
            <span className="mr-2">
              <FaInfo />
            </span>
            Para efetuar a mudança de onwer de uma configuração, selecione o
            usuário de origem e o usuário das configurações e propostas. Quando
            não informado o Gnf, todas as configurações e propostas serão
            afetadas pela alteração.
          </div>
        </div>

        <CardBody>
          <Row>
            <Col className="collapse-brand" sm="3">
              <Input
                className="ml-2"
                type="select"
                value={usuarioOrigem.id !== 0 ? usuarioOrigem.id : ''}
                onChange={event =>
                  setUsuarioOrigem({
                    ...usuarioOrigem,
                    id: Number(event.target.value),
                  })
                }
              >
                <option value="">Usuário de origem</option>
                {listaUsuarios.map(atividade => (
                  <option value={atividade.id}>{atividade.nome}</option>
                ))}
              </Input>
            </Col>

            <Col className="collapse-brand" sm="3">
              <Input
                className="ml-2"
                type="select"
                value={usuarioDestino.id !== 0 ? usuarioDestino.id : ''}
                onChange={event =>
                  setUsuarioDestino({
                    ...usuarioDestino,
                    id: Number(event.target.value),
                  })
                }
              >
                <option value="">Usuário de destino</option>
                {listaUsuarios.map(atividade => (
                  <option value={atividade.id}>{atividade.nome}</option>
                ))}
              </Input>
            </Col>
            <Col sm="2">
              <TextField
                inputProps={{ style: { fontSize: 14 } }}
                style={{ width: '100%' }}
                size="small"
                variant="outlined"
                label="Gnf"
                type="text"
                value={numeroGnf ?? ''}
                onChange={text => setNumeroGnf(text.target.value)}
              />
            </Col>
            <Col className="collapse-brand" sm="2">
              <Button
                size="md"
                className="btn-icon"
                color="success"
                type="button"
                disabled={isSaving}
                onClick={handleSave}
              >
                {isSaving ? 'Migrando...' : 'Migrar'}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Container>
    </>
  );
};

export default MigracaoOwner;
