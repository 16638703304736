import { useEffect, useState } from 'react';
import api from 'services/api';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { useQuery } from 'react-query';
import ComponentProps from '../../models/component.props';
import './styles.css';

export default function NavegacaoRapida(props: ComponentProps): JSX.Element {
  const { className, cacheTime } = props;
  const [listaProposta, setListaProposta] = useState<any[]>([] as any[]);
  const [listaConfiguracao, setListaConfiguracao] = useState<any[]>(
    [] as any[],
  );
  const [dataReady, setDataReady] = useState(false);

  const { data: cacheRetornoConfiguracoes } = useQuery(
    `dashboard-ultimas-configuracoes`,
    async () => {
      const response = await api.get(`/dashboard/ultimas-configuracoes`);
      return response.data ?? [];
    },
    { staleTime: cacheTime || 240000 },
  );

  const { data: cacheRetornoPropostas } = useQuery(
    `dashboard-ultimas-propostas`,
    async () => {
      const response = await api.get(`/dashboard/ultimas-propostas`);
      return response.data ?? [];
    },
    { staleTime: cacheTime || 240000 },
  );

  async function loadContent() {
    setDataReady(false);

    setListaConfiguracao(cacheRetornoConfiguracoes);
    setListaProposta(cacheRetornoPropostas);

    setDataReady(true);
  }

  useEffect(() => {
    if (cacheRetornoPropostas && cacheRetornoPropostas) {
      loadContent();
    }
  }, [cacheRetornoConfiguracoes, cacheRetornoPropostas]);

  return (
    <>
      <Card className={`card-stats ${className} mb-xl-0`}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                Acesso Rápido
              </CardTitle>
              <span className="h2 font-weight-bold mb-4 mt-4">
                {!dataReady ? (
                  <CircularProgress
                    className="mt-2 ml-2"
                    color="inherit"
                    size={15}
                  />
                ) : (
                  <>
                    <div
                      className="div-acesso-rapido"
                      hidden={!listaProposta || !listaProposta.length}
                    >
                      <span>Propostas</span>
                      {listaProposta?.map(item => {
                        return (
                          <>
                            <br />
                            <a
                              key={`link_prop_${item.id}`}
                              className="link-acesso-rapido"
                              target="noreferer"
                              href={`/admin/proposta/${item.proposta_id}/${item.id}`}
                            >
                              <FaArrowAltCircleRight size={14} />
                              {` ${item.proposta_id} v${item.versao}`}
                            </a>
                          </>
                        );
                      })}
                    </div>
                    <div
                      className="div-acesso-rapido"
                      hidden={!listaConfiguracao || !listaConfiguracao.length}
                    >
                      <span>Configuração</span>
                      {listaConfiguracao?.map(item => {
                        return (
                          <>
                            <br />
                            <a
                              key={`link_config_${item.id}`}
                              className="link-acesso-rapido"
                              target="noreferer"
                              href={`/admin/configuracao/${item.id}`}
                            >
                              <FaArrowAltCircleRight size={14} />
                              {` ${item.id}`}
                            </a>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </span>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
