/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from 'react';
import api from 'services/api';
import { Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import './style.css';

export default function PDFViewer({
  propostaVersaoId,
  templateId,
}: {
  propostaVersaoId: number;
  templateId: number;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<Blob>();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  async function renderData() {
    if (!templateId || !propostaVersaoId) return;
    setIsLoading(true);

    const toastId = toast.loading('Carregando Relatório...');

    try {
      const response = await api.post(
        `/report/render`,
        {
          propostaVersaoId,
          templateId,
        },
        {
          responseType: 'blob',
        },
      );

      setPdfBlob(response.data);
    } catch (error: any) {
      if (error.response && error.response.data instanceof Blob) {
        try {
          const errorText = await error.response.data.text();
          const errorJson = JSON.parse(errorText);
          const errorMessage = errorJson.message;

          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            text: errorMessage,
          });

          console.error('Error message:', errorMessage);
        } catch (e) {
          console.error('Error parsing the response blob', e);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: error.message,
        });
      }
    } finally {
      toast.dismiss(toastId);
      setIsLoading(false);
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      setPdfUrl(url);

      return () => {
        URL.revokeObjectURL(url); // Limpeza do URL após o componente ser desmontado
      };
    }
  }, [pdfBlob]);

  useEffect(() => {
    renderData();
  }, []);

  if (!templateId) return <></>;
  return (
    <>
      <div className="pdf-container">
        {pdfUrl ? (
          <embed
            src={pdfUrl}
            type="application/pdf"
            width="100%"
            className="pdf-embed"
          />
        ) : (
          <p>Carregando Relatório...</p>
        )}
      </div>
    </>
  );
}
