import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import jwtDecode from 'jwt-decode';

import { useAuth } from '../hooks/auth';

interface Jwt {
  exp: number;
}

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentState;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { usuario } = useAuth();

  const token = localStorage.getItem('@PortalOrc:token');

  let tokenExpired = false;

  if (token) {
    const jwtDecoded = jwtDecode(token);
    const { exp } = jwtDecoded as Jwt;

    const expireAt = exp * 1000;

    tokenExpired = Date.now() >= expireAt;

    if (tokenExpired) {
      localStorage.removeItem('@PortalOrc:token');
      localStorage.removeItem('@PortalOrc:usuario');
    }
  }

  const isPrivateAndUsuarioAutenticado =
    isPrivate === (!!usuario && !!token && !tokenExpired);

  return (
    <ReactDOMRoute
      {...rest}
      render={props => {
        if (isPrivateAndUsuarioAutenticado) {
          return <Component {...props} />;
        }

        return (
          <Redirect to={{ pathname: isPrivate ? '/auth' : '/admin/index' }} />
        );
      }}
    />
  );
};

export default Route;
