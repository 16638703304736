/* eslint-disable import/prefer-default-export */
import { Card, Row } from 'reactstrap';
import styled from 'styled-components';

export const LabelSaida = styled.label`
  width: 100%;
  font-size: 0.7rem;
  margin: 0;
`;

export const LabelOpcao = styled.label`
  font-family: inherit;
  font-size: 1rem;
  margin: 1rem;
`;

export const CardOpcoes = styled(Card)`
  margin-bottom: 1rem;
  padding: 0.8rem;
`;

export const CardOpcao = styled(Card)`
  margin-bottom: 1rem;
`;

export const RowHeaderCardOpcoes = styled(Row)`
  margin: 0 5px 0 5px;
  justify-content: space-between;
`;
