import { FaCheckCircle, FaTrash } from 'react-icons/fa';
import { Button, Col, InputGroup } from 'reactstrap';

export default function UpdateDeleteInputGroup({
  onClickUpdate,
  onClickDelete,
  className,
  disabled,
  canEdit,
  canDelete,
}: {
  onClickUpdate?: () => void;
  onClickDelete?: () => void;
  className?: string | undefined;
  disabled?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
}): JSX.Element {
  return (
    <InputGroup className={className}>
      <Col className="p-0" xs="12">
        {canEdit && (
          <Button
            disabled={disabled}
            outline
            className="btn-icon"
            color="primary"
            type="button"
            size="sm"
            onClick={onClickUpdate}
          >
            <FaCheckCircle />
          </Button>
        )}
        {canDelete && (
          <Button
            disabled={disabled}
            outline
            className="btn-icon btn-2 ml-1"
            color="danger"
            type="button"
            size="sm"
            onClick={onClickDelete}
          >
            <FaTrash />
          </Button>
        )}
      </Col>
    </InputGroup>
  );
}
