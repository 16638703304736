import { AppBar as AppBarMui, makeStyles, Theme } from '@material-ui/core';
import { ReactChild } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AppBar({
  children,
}: {
  children: ReactChild;
}): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBarMui position="static" color="default">
        {children}
      </AppBarMui>
    </div>
  );
}
