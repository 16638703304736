import formatCurrency from 'utils/formatCurrency';

export default function Valor({
  label,
  valor,
  noFormat,
  unit,
  isDolar,
}: {
  label: string;
  valor: number | null;
  noFormat?: boolean;
  unit?: string;
  isDolar?: boolean;
}): JSX.Element {
  return (
    <tr>
      <td className="p-1">{label}:</td>
      <td className="p-1">
        <h5>
          <strong>
            {noFormat ? valor : formatCurrency(valor, 2, isDolar)} {unit}
          </strong>
        </h5>
      </td>
    </tr>
  );
}
