import React, { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UsuarioModel from '../models/Usuario';

import isValidEmail from '../utils/isValidEmail';

import api from '../services/api';

interface AuthState {
  token: string;
  usuario: UsuarioModel;
}

interface SignInCredenciais {
  credential?: string;
  email?: string;
  password: string;
}

interface AuthContextData {
  usuario: UsuarioModel;
  signIn(credenciais: SignInCredenciais): Promise<void>;
  signOut(): void;
  errorMessage: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@PortalOrc:token');
    const usuario = localStorage.getItem('@PortalOrc:usuario');

    if (token && usuario) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      return { token, usuario: JSON.parse(usuario) };
    }

    return {} as AuthState;
  });

  const [errorMessage, setErrorMessage] = useState<string>('');

  const history = useHistory();

  const signIn = useCallback(async ({ credential, password }) => {
    const isEmail = isValidEmail(credential);

    try {
      setErrorMessage('');

      const response = await api.post('/login', {
        email: isEmail ? credential : '',
        username: isEmail ? '' : credential,
        password,
      });

      const { token, usuario } = response.data;

      localStorage.setItem('@PortalOrc:token', token);
      localStorage.setItem('@PortalOrc:usuario', JSON.stringify(usuario));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setData({ token, usuario });

      const pathNameToNav = localStorage.getItem('pathNameToNav');
      const searchNameToNav = localStorage.getItem('searchNameToNav');

      if (pathNameToNav) {
        localStorage.removeItem('pathNameToNav');
        localStorage.removeItem('searchNameToNav');
        history.push({
          pathname: pathNameToNav,
          search: `${searchNameToNav || ''}`,
        });
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data.message);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@PortalOrc:token');
    localStorage.removeItem('@PortalOrc:usuario');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ usuario: data.usuario, signIn, signOut, errorMessage }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser utilizado com um AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
