import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaArrowCircleUp,
} from 'react-icons/fa';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Swal from 'sweetalert2';

import api from 'services/api';

import { Link } from 'react-router-dom';

import FabricanteModel from '../../../models/ConfiguradorFabricante';

const ConfiguradorFabricantes: React.FC = () => {
  const [fabricantes, setFabricantes] = useState([] as FabricanteModel[]);
  // eslint-disable-next-line prettier/prettier
  const [FabricanteEditing, setFabricanteEditing] = useState({} as FabricanteModel);

  const [formModalState, setFormModalState] = useState(false);

  const [mostrarInativos, setMostrarInativos] = useState(false);

  async function listaFabricantes() {
    const response = await api.get('/integracao/bombas/fabricantes');

    setFabricantes(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaFabricantes();
  }, []);

  async function handleAtiva(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja reativar o fabricante?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/integracao/bombas/fabricantes/${row.id}`, {
            nome: row.nome,
            urlFabricante: row.urlFabricante ?? '',
            indicAtivo: 'S',
          });
          Swal.close();
          toast.success('Fabricante Reativado');
          listaFabricantes();
        }
      });
    } else {
      listaFabricantes();
    }
  }

  async function handleDesativa(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja desativar o fabricante?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/integracao/bombas/fabricantes/${row.id}`, {
            nome: row.nome,
            urlFabricante: row.urlFabricante ?? '',
            indicAtivo: 'N',
          });
          Swal.close();
          toast.success('Fabricante desativado');
          listaFabricantes();
        }
      });
    } else {
      listaFabricantes();
    }
  }

  async function handleInsert() {
    setFabricanteEditing({} as FabricanteModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(Fabricante: FabricanteModel) {
    setFabricanteEditing(Fabricante);
    setFormModalState(!formModalState);
  }

  async function handleSaveFabricante() {
    // eslint-disable-next-line prettier/prettier
    const { nome, urlFabricante } = FabricanteEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (FabricanteEditing.id) {
      await api.put(`/integracao/bombas/fabricantes/${FabricanteEditing.id}`, {
        nome,
        urlFabricante: urlFabricante ?? '',
        indicAtivo: 'S',
      });
    } else {
      await api.post(`/integracao/bombas/fabricantes`, {
        nome,
        urlFabricante: urlFabricante ?? '',
        indicAtivo: 'S',
      });
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setFabricanteEditing({} as FabricanteModel);
    listaFabricantes();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Fabricante {FabricanteEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome do Fabricante *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="nome do Fabricante"
                            value={FabricanteEditing.nome}
                            type="text"
                            onChange={text =>
                              setFabricanteEditing({
                                ...FabricanteEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>URL do Fabricante </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="URL do Fabricante"
                            value={FabricanteEditing.urlFabricante}
                            type="text"
                            onChange={text =>
                              setFabricanteEditing({
                                ...FabricanteEditing,
                                urlFabricante: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveFabricante}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Fabricantes de Bombas</h3>
              </CardHeader>
              <Row>
                <Col sm="9" />
                <Col sm="3">
                  <FormControlLabel
                    className="float-right"
                    control={
                      <Checkbox
                        checked={mostrarInativos}
                        onChange={event =>
                          setMostrarInativos(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Mostrar Inativos"
                  />
                </Col>
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  {fabricantes
                    .filter(item =>
                      !mostrarInativos ? item.indicAtivo === 'S' : item,
                    )
                    .map(fabricante => (
                      <tr key={fabricante.id}>
                        <td
                          className={
                            fabricante.indicAtivo === 'S'
                              ? 'col'
                              : 'col text-muted'
                          }
                        >
                          <strong>{fabricante.nome}</strong>
                        </td>
                        <td>
                          {fabricante.indicAtivo === 'S' ? (
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="warning"
                              type="button"
                              onClick={() => {
                                handleUpdate(fabricante);
                              }}
                            >
                              <FaEdit />
                            </Button>
                          ) : (
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="default"
                              type="button"
                              onClick={() => {
                                handleAtiva(fabricante);
                              }}
                            >
                              <FaArrowCircleUp />
                            </Button>
                          )}
                          {fabricante.indicAtivo === 'S' ? (
                            <Button
                              size="sm"
                              className="btn-icon"
                              color="danger"
                              type="button"
                              onClick={() => {
                                handleDesativa(fabricante);
                              }}
                            >
                              <FaTimesCircle />
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorFabricantes;
