import { useContext, useEffect, useState } from 'react';
import api from 'services/api';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import formatCurrency from 'utils/formatCurrency';
import { CircularProgress } from '@material-ui/core';
import Chart from 'react-apexcharts';
import ComponentProps from '../../models/component.props';
import MesesAno from '../../models/component.meses.ano';
import { DashboardContext } from '../..';
import { getParamsFormatados } from '../../function.get-params-formatados';

export default function PropostaMesValor(props: ComponentProps): JSX.Element {
  const { className, cacheTime } = props;

  const { dataFim, dataInicio, filtros, refreshMesAno, setRefreshMesAno } =
    useContext(DashboardContext);

  const [propostaMesAno, setPropostaMesAno] = useState<any[]>([] as any[]);

  const [dataReady, setDataReady] = useState(false);

  const [chartOptions, setChartOptions] = useState<any>({});

  const mesesAno = MesesAno;

  async function loadData() {
    setDataReady(false);
    const params = getParamsFormatados(filtros, dataInicio, dataFim);

    async function getDados() {
      const response = await api.get(
        `/dashboard/propostas-total-mes-ano?${params}`,
      );
      return response.data ?? [];
    }

    const cacheRetornoDados = await getDados();

    setPropostaMesAno(cacheRetornoDados);
  }

  async function loadContent() {
    const dataI = new Date(`${dataInicio}T10:10:10`);
    const dataF = new Date(`${dataFim}T10:10:10`);

    let currYear = dataI.getFullYear();
    let currMonth = dataI.getMonth() + 1;

    const meses: any[] = [];

    const mesesData: any[] = [];

    while (
      Number(`${currYear}${String(currMonth).padStart(2, '0')}`) <=
      Number(
        `${dataF.getFullYear()}${String(dataF.getMonth() + 1).padStart(
          2,
          '0',
        )}`,
      )
    ) {
      // eslint-disable-next-line no-loop-func
      const mes = mesesAno.find(item => item.id === currMonth);

      // gera o próximo mês
      const proximaData = new Date(
        `${currYear}-${String(currMonth).padStart(2, '0')}-01T10:10:10`,
      );

      meses.push(`${mes?.sigla} ${currYear}`);

      mesesData.push(
        propostaMesAno.find(
          // eslint-disable-next-line no-loop-func
          (item: any) =>
            item.year_month ===
            `${currYear}-${String(currMonth).padStart(2, '0')}`,
        )?.valor_total ?? 0,
      );

      proximaData.setMonth(proximaData.getMonth() + 1);

      currMonth = proximaData.getMonth() + 1;
      currYear = proximaData.getFullYear();
    }

    const chart = {
      series: [
        {
          name: '',
          data: mesesData,
        },
      ],
      options: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,
        },
        labels: [...meses],
        xaxis: {
          labels: {
            formatter(value: any) {
              return value;
            },
          },
        },
        yaxis: {
          labels: {
            formatter(value: any) {
              return formatCurrency(value, 2);
            },
          },
        },
        tooltip: {
          y: {
            formatter(value: any) {
              return formatCurrency(value, 2);
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },
    };

    setChartOptions(chart);
    setDataReady(true);
  }

  useEffect(() => {
    if (refreshMesAno === true) {
      loadData();
    }
    setRefreshMesAno(false);
  }, [refreshMesAno]);

  useEffect(() => {
    if (propostaMesAno) loadContent();
  }, [propostaMesAno]);

  return (
    <>
      <Card className={`card-stats ${className} mb-xl-0`}>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                Proposta Mês x Valor
              </CardTitle>
              <span className="h2 font-weight-bold mb-4 mt-4">
                {!dataReady ? (
                  <CircularProgress
                    className="mt-2 ml-2"
                    color="inherit"
                    size={15}
                  />
                ) : (
                  <div className="chart">
                    <Chart
                      options={chartOptions.options}
                      series={chartOptions.series}
                      type="area"
                      height={350}
                    />
                  </div>
                )}
              </span>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
